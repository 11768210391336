import * as React                           from "react";
import GoogleMap                            from 'google-maps-react-markers';
// @ts-ignore
import Delete                               from 'baseui/icon/delete';
import { useStyletron }                     from "baseui";
import { useTranslation }                   from "react-i18next";
import { useLocation }                      from "../services";
import { CreateRequestForm }                from "../components/CreateRequestForm";
import { Layer }                            from "baseui/layer";
import { FloatingMarker }                   from "baseui/map-marker";
import { FLOATING_MARKER_SIZES }            from "baseui/map-marker";
import { FLOATING_MARKER_ANCHOR_POSITIONS } from "baseui/map-marker";
import { LocationPuck }   from 'baseui/map-marker';
import {
    useSearchNearbyFfSubscription,
    useFieldPartnerUpdatesLocationMutation,
    useCreateRequestMutation
}                         from "../generated/graphql";
import { useAppStore }    from "../store";
import { ParagraphSmall } from "baseui/typography";
import { StyledLink }     from "baseui/link";
import { useNavigate }    from "react-router-dom";
import { useLoaderData }  from "react-router-dom";
import { useEffect }      from "react";


export function HomePage() {
    const [css, theme] = useStyletron();
    const { t } = useTranslation();
    const loaderArgs = useLoaderData();
    const { coords } = useLocation();
    const mapRef = React.useRef(null)
    const navigate = useNavigate()
    const [mapReady, setMapReady] = React.useState(false)
    const [isBeforeRequest, setIsBeforeRequest] = React.useState(false)
    const [createRequest, { data: createRequestData }] = useCreateRequestMutation()
    const defaultProps = {
        center: {
            lat: 17.48848,
            lng: 78.37394,
        },
        zoom: 11
    };
    const onGoogleApiLoaded = ({ map, maps }) => {
        mapRef.current = map
        setMapReady(true)
    }
    // console.log({
    //     lat: (coords ? coords.latitude : defaultProps.center.lat),
    //     lng: (coords ? coords.longitude : defaultProps.center.lng),
    // })
    React.useEffect(() => {
        if (coords && coords.latitude && mapReady) {
            mapRef?.current?.panTo({
                lat: coords.latitude,
                lng: coords.longitude
            });
        }
    }, [coords, mapReady]);

    const [partnerCount, setPartnerCount] = React.useState(0);
    const { user } = useAppStore();

    const {
        data: ff_nearby,
        error: isError,
        loading: isLoading,
    } = useSearchNearbyFfSubscription({
        variables: {
            lat: coords?.latitude ?? 0,
            lng: coords?.longitude ?? 0,
            kms: 600,
        },
        shouldResubscribe: true,
        // onSubscriptionData: ({subscriptionData}) => {
        //   console.log({subscriptionData});
        // },
        onError: err => {
            console.log({ errSUbs: err, stack: err.stack });
        },
    });

    const partnersNearby = ff_nearby?.ff_nearby || [];
    // console.log({ partnersNearby });
    React.useEffect(() => {
        if (partnersNearby) {
            setPartnerCount(partnersNearby.length);
        }
    }, [partnersNearby, isError, isLoading]);

    const [fieldPartnerUpdatesLocation] =
        useFieldPartnerUpdatesLocationMutation();
    React.useEffect(() => {
        (async () => {

            if (user?.id && coords?.latitude) {
                // console.log({ coords });
                fieldPartnerUpdatesLocation({
                    variables: {
                        lat: coords?.latitude,
                        lng: coords?.longitude,
                        field_partner_user_id: user?.id,
                    },
                });
            }
        })();
    }, []);

    const { isFromCreateRequest } = loaderArgs as any;
    useEffect(() => {
        if (isFromCreateRequest) {
            setIsBeforeRequest(true);
        }
    }, [isFromCreateRequest])

    // Old Key
    // AIzaSyBaCU54OuDOSoCP3nA4jIRiF8ewLL3HhlM

    return (
        <div>
            <Layer>
                <div className={'home-body ' + css({
                    boxSizing: 'border-box',
                    width: '100vw',
                    top: '55px',
                    left: '0',
                    position: 'fixed',
                    height: isBeforeRequest && !createRequestData?.farmerCreatesServiceRequest?.data?.status ? '60vh' : '100vh',
                    [theme.mediaQuery.medium]: {
                        height: 'calc(100vh  - 60px)',
                    }
                })}>
                    <GoogleMap
                        apiKey="AIzaSyA1coenN4WRyVXSw_I_L_gTUxcz4wJ9KU4"
                        bootstrapURLKeys={{ key: "AIzaSyA1coenN4WRyVXSw_I_L_gTUxcz4wJ9KU4" }}
                        defaultCenter={defaultProps.center}
                        defaultZoom={defaultProps.zoom}
                        onGoogleApiLoaded={onGoogleApiLoaded}
                        options={{
                            center: {
                                lat: coords && coords.latitude ? coords.latitude : defaultProps.center.lat,
                                lng: coords && coords.longitude ? coords.longitude : defaultProps.center.lng
                            },
                            disableDefaultUI: true,
                            mapTypeControl: false,
                        }}
                    >
                        {coords ?
                            <LocationPuck
                                // @ts-ignore
                                lat={coords?.latitude}
                                lng={coords?.longitude}
                                heading={coords?.heading}
                                confidenceRadius={coords?.accuracy}
                                label={t('app:map-you-are-here', "You are here")}
                                anchor={FLOATING_MARKER_ANCHOR_POSITIONS.topLeft}
                                size={FLOATING_MARKER_SIZES.medium}
                            /> : null}
                        {
                            partnersNearby.map((partner) => {
                                return (
                                    <FloatingMarker
                                        key={partner?.user?.name || '0'}
                                        // @ts-ignore
                                        lat={partner.lat}
                                        lng={partner.lng}
                                        overrides={{
                                            OuterAnchor: { style: { backgroundColor: theme.colors.primary } },
                                            SecondaryLabel: {
                                                style: ({ $theme }) => ({
                                                    color: $theme.colors.contentTertiary,
                                                }),
                                            },
                                        }}
                                        label={partner?.user?.name || ''}
                                        secondaryLabel={`${Math.floor(partner?.distance_kms || 0)} km`}
                                        anchor={FLOATING_MARKER_ANCHOR_POSITIONS.topLeft}
                                        size={FLOATING_MARKER_SIZES.medium}
                                    />
                                )
                            })
                        }
                    </GoogleMap>
                </div>
            </Layer>
            <Layer>
                <div className={css({
                    top: 'calc(30vh )',
                    position: 'relative',
                    height: 'calc(100vh - 40vh )',
                    backgroundColor: !isBeforeRequest || createRequestData?.farmerCreatesServiceRequest?.data?.status ? 'transparent' : 'white',
                    borderTopRightRadius: '20px',
                    borderTopLeftRadius: '20px',
                    paddingTop: theme.sizing.scale600,
                    [theme.mediaQuery.medium]: {
                        height: "calc(100vh  - 140px)",
                        width: "400px", borderRadius: '12px',
                        position: "absolute",
                        top: "50%",
                        // left: "50%",
                        // backgroundColor:'transparent',
                        transform: "translateY( -50%)",
                        left: '20px',
                        overflow: 'auto'
                    },
                    [theme.mediaQuery.large]: {
                        height: "calc(100vh  - 150px)",
                        left: '50px',

                    }
                })}>
                    <CreateRequestForm isBeforeRequest={isBeforeRequest} setIsBeforeRequest={setIsBeforeRequest} createRequestData={createRequestData} createRequest={createRequest}/>
                </div>
            </Layer>
            <Layer>
                <div className={css({
                    [theme.mediaQuery.medium]: {
                        bottom: '0px',
                        position: 'fixed',
                        height: '55px',
                        width: '100vw',
                        backgroundColor: 'white',
                        justifyContent: 'center',
                        alignItems: 'center'

                    },
                    [theme.mediaQuery.small]: {
                        bottom: '0px',
                        position: 'fixed',
                        height: '55px',
                        width: '100vw',
                        backgroundColor: 'white',
                        justifyContent: 'center',
                        alignItems: 'center'

                    }
                })}>

                    <ParagraphSmall $style={{ textAlign: 'center' }}>© {new Date().getFullYear()} - {t('app:reserved-text', `All Rights Reserved`)} {` | Farmreach Technologies Private Limited | `}
                        <StyledLink href="/privacy" style={{
                            color: 'green',
                            textDecoration: 'none'
                        }}>{t('app:privacy-link-text', 'Privacy')}</StyledLink> {` | `}
                        <StyledLink href="/terms" style={{
                            color: 'green',
                            textDecoration: 'none'
                        }}>{t('app:terms-link-text', 'Terms & Conditions')}</StyledLink>
                    </ParagraphSmall>
                </div>
            </Layer>
        </div>
    )
}
