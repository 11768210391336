import React                             from 'react'
import ReactDOM                          from 'react-dom/client'
import { RouterProvider, }               from "react-router-dom";
import { Client as Styletron }           from 'styletron-engine-atomic';
import { Provider as StyletronProvider } from 'styletron-react';
import { BaseProvider }                  from 'baseui';
import { theme }                         from './theme';
import { router }                        from "./router";
import { useApolloSetup }                from "./api";
import { ApolloProvider }                from "@apollo/client";
import { I18nextProvider }               from 'react-i18next';
import { i18nInstance }                  from './i18n';
import * as Sentry                       from "@sentry/react";
import { singularSdk, SingularConfig }   from "singular-sdk";

function initSingular() {
    const config = new SingularConfig("farmreach_98419706", "083422ea038ba461c19ab88a073b30e6", "com.farminsta.app");
    singularSdk.init(config);
}

initSingular();
Sentry.init({
    dsn: "https://42a88f3230aa714a33eadac456e88e5c@o4505583450914816.ingest.sentry.io/4505849897549824",
    integrations: [
        new Sentry.BrowserTracing({
            // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
            tracePropagationTargets: [/^https:\/\/gql.farminsta\.com/],
        }),
        new Sentry.Replay(),
    ],
    // Performance Monitoring
    tracesSampleRate: 0.5, // Capture 100% of the transactions, reduce in production!
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const engine = new Styletron();

function MainApp() {
    const { gqlClient } = useApolloSetup();

    return (
        // <React.StrictMode>
        <I18nextProvider i18n={i18nInstance}>
            <StyletronProvider value={engine}>
                <BaseProvider theme={theme}>
                    <ApolloProvider client={gqlClient}>
                        <RouterProvider router={router}/>
                    </ApolloProvider>
                </BaseProvider>
            </StyletronProvider>
        </I18nextProvider>
        // </React.StrictMode >
    )
}

const MainWithSentry = Sentry.withProfiler(MainApp);

ReactDOM
    .createRoot(document.getElementById('root')!)
    .render(<MainWithSentry/>)
