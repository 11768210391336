import * as React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import { useStyletron } from "baseui";
import { IoSend } from "react-icons/io5";
import moment from "moment";
import { formatNumber } from "./function";
import { Drawer, SIZE as DRAWER_SIZE, ANCHOR } from "baseui/drawer";

import Chrome from '../assets/Chrome.png'
import Firefox from '../assets/firefox.png'
import Safari from '../assets/Safari.png'
import Farminsta from '../assets/Farminsta.webp'

export const ConfirmToNavigation = ({ isOpen, onClose,handleContueBroweser }) => {
    const [css, theme] = useStyletron();
    
    const handleNavigation = (event) => {

        // Prevent default navigation behavior
        event.preventDefault();
        window.open('https://app.farminsta.com/', '_blank');

    };
    const detectBrowser = () => {
        const userAgent = navigator.userAgent;
    
        if (userAgent.indexOf("Chrome") > -1) {
          return Chrome;
        } else if (userAgent.indexOf("Firefox") > -1) {
          return Firefox
        } else if (userAgent.indexOf("Safari") > -1) {
          return Safari;
        } else if (userAgent.indexOf("Edg") > -1) { 
          return "Edge";
        } else if (userAgent.indexOf("MSIE") > -1 || userAgent.indexOf("Trident/") > -1) {
          return "Internet Explorer";
        } else {
          return "Unknown";
        }
      };

    return (<Drawer
        isOpen={isOpen}
        autoFocus
        onClose={() => onClose(false)}
        size={DRAWER_SIZE.auto}
        anchor={ANCHOR.bottom}
        closeable={false}
        overrides={{
            DrawerContainer: {
                style: {
                    width: "320px", // Default width for larger screens
                    borderRadius: "10px",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    bottom: "0",
                    "@media screen and (max-width: 768px)": {
                        width: "100%", // Change width for screens <= 768px
                    },
                },
            },
        }}
    >

        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
        <span className='Navigation' style={{textAlign:'center',marginBottom:20}}>See this reel in</span>
            <div className="nav_cnt_" >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img className={css({ objectFit: 'cover', width: '40px', height: '40px', borderRadius: '100px', marginRight: '10px' })}
                        src={Farminsta} />
                    <span className='Navigation'>Farminsta App</span>
                </div>
                <button className="nav_btn" onClick={handleNavigation}>Open</button>
            </div>
            <div className="nav_cnt_" >
                <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                    <img className={css({ objectFit: 'cover', width: '40px', height: '40px', borderRadius: '100px', marginRight: '10px' })}
                        src={detectBrowser()} />
                    <span className='Navigation'>Browser</span>
                </div>
                <button className="nav_btn" onClick={ handleContueBroweser}>Continue</button>
            </div>

        </div>

    </Drawer>
    );
}