import { createBrowserRouter }                                  from "react-router-dom";
import { redirect }                                             from "react-router-dom";
import React                                                    from "react";
import { HomePage }                                             from "./pages/Home";
import { HomeLayout }                                           from "./layouts/HomeLayout";
import { useAppStore }                                          from "./store";
import { LanguageSelectPage, action as LanguageSelectorAction } from "./pages/LanguageSelect";
import { LoginPage }                                            from "./pages/Login";
import TermsPage                                                from "./pages/Terms";
import PrivacyPage                                              from "./pages/Privacy";
import { Reels }                                                from "./pages/Reels";
import { redirectDocument }                                     from "react-router-dom";

export const router = createBrowserRouter([
    {
        path: "/",
        Component: HomeLayout,
        children: [
            {
                path: '/',
                loader: ({ request }) => {
                    const locale = useAppStore.getState().locale;
                    // from=create-request
                    if (request.url.includes('from=create-request')) {
                        localStorage?.setItem('from=create-request', 'true');
                    }
                    if (!locale) {
                        return redirect('/lang');
                    }
                    const isFromCreateRequest = localStorage?.getItem('from=create-request') || false;
                    localStorage?.removeItem('from=create-request');
                    return {
                        isFromCreateRequest
                    };
                },
                Component: HomePage,
            },
            {
                path: '/login',
                loader: ({ request }) => {
                    const locale = useAppStore.getState().locale;
                    if (!locale) {
                        return redirect('/lang');
                    }
                    return {};
                },
                Component: LoginPage,
            },

        ]
    },
    {
        path: "/reels",
        loader: ({ request }) => {
            const query = new URLSearchParams(new URL(request.url).search);
            return {
                reelId: query.get('reelid'),
            }

            // const ua = navigator.userAgent
            // if (/android/i.test(ua)) {
            //     return "Android"
            // }
            // return redirectDocument('https://play.google.com/store/apps/details?id=com.farminsta.app');
        },
        Component: Reels,
    },
    {
        path: "/create-request",
        loader:({request}) => {
            return redirect('/?from=create-request');
        },
        Component: Reels,
    },
    {
        path: "/lang",
        action: LanguageSelectorAction,
        Component: LanguageSelectPage
    },
    {
        path: "/terms",

        Component: TermsPage
    },
    {
        path: "/privacy",

        Component: PrivacyPage
    },
]);
