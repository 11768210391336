import { useGeolocated } from "react-geolocated";
import { useEffect }     from "react";
import { useAppStore }   from "./store";

export function useLocation() {
    const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } = useGeolocated({
        positionOptions: {
            enableHighAccuracy: false,
        },
        suppressLocationOnMount: true,
        // watchPosition: true,
        userDecisionTimeout: 45000,
        onSuccess: (position: GeolocationPosition) => {
            const jsPosition = {
                timestamp: position.timestamp,
                coords: {
                    accuracy: position.coords.accuracy,
                    altitude: position.coords.altitude,
                    altitudeAccuracy: position.coords.altitudeAccuracy,
                    heading: position.coords.heading,
                    latitude: position.coords.latitude,
                    longitude: position.coords.longitude,
                    speed: position.coords.speed
                }
            };
            // console.log({ jsPosition });
            useAppStore.getState().setLastKnownLocation(jsPosition);
        },
        onError: (error: GeolocationPositionError) => {
            console.log({ geoError: error });
        }
    });
    // console.log({ inLocation: { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } });

    useEffect(() => {
        if (isGeolocationAvailable && isGeolocationEnabled) {
            getPosition();
        }
    }, [isGeolocationAvailable, isGeolocationEnabled])

    return {
        coords,
        isGeolocationAvailable,
        isGeolocationEnabled,
        getPosition,
    }
}
