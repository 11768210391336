import * as React         from "react";
import { Outlet }         from 'react-router-dom';
import { useNavigate }    from "react-router-dom";
import { Icon }           from "baseui/icon";
import { Overflow }       from "baseui/icon";
import { Delete }         from "baseui/icon";
import { ChevronRight }   from "baseui/icon";
import { useTranslation } from "react-i18next";
import { useStyletron }   from "baseui";
import { AppNavBar }      from "baseui/app-nav-bar";
import { NavItem }        from "baseui/app-nav-bar";
import { Layer }          from "baseui/layer";
import { useAppStore }    from "../store";

import FarminstaTitleImage             from '../assets/farminsta-title.png';
import StyledModal, { SuccessMessage } from "../components/StyledModal";

function SupportIcon() {
    return <Icon viewBox="0 0 24 24">
        <path xmlns="http://www.w3.org/2000/svg" opacity="1" d="M10.0376 5.31617L10.6866 6.4791C11.2723 7.52858 11.0372 8.90532 10.1147 9.8278C10.1147 9.8278 10.1147 9.8278 10.1147 9.8278C10.1146 9.82792 8.99588 10.9468 11.0245 12.9755C13.0525 15.0035 14.1714 13.8861 14.1722 13.8853C14.1722 13.8853 14.1722 13.8853 14.1722 13.8853C15.0947 12.9628 16.4714 12.7277 17.5209 13.3134L18.6838 13.9624C20.2686 14.8468 20.4557 17.0692 19.0628 18.4622C18.2258 19.2992 17.2004 19.9505 16.0669 19.9934C14.1588 20.0658 10.9183 19.5829 7.6677 16.3323C4.41713 13.0817 3.93421 9.84122 4.00655 7.93309C4.04952 6.7996 4.7008 5.77423 5.53781 4.93723C6.93076 3.54428 9.15317 3.73144 10.0376 5.31617Z" stroke="#1C274C" stroke-width="1.5" stroke-linecap="round"/>
    </Icon>
}

function ChangeLanguageIcon() {
    return <Icon viewBox="0 0 24 24">
        <path xmlns="http://www.w3.org/2000/svg" d="M21.05566,12h-2a1,1,0,0,0,0,2v2H17.8714a2.96481,2.96481,0,0,0,.18426-1A2.99955,2.99955,0,0,0,12.458,13.50049a.99992.99992,0,1,0,1.73242.999A1.0009,1.0009,0,0,1,15.05566,14a1,1,0,0,1,0,2,1,1,0,0,0,0,2,1,1,0,1,1,0,2,1.0009,1.0009,0,0,1-.86523-.49951.99992.99992,0,1,0-1.73242.999A2.99955,2.99955,0,0,0,18.05566,19a2.96481,2.96481,0,0,0-.18426-1h1.18426v3a1,1,0,0,0,2,0V14a1,1,0,1,0,0-2ZM9.08594,11.24268a.99963.99963,0,1,0,1.93945-.48536L9.26855,3.72754a2.28044,2.28044,0,0,0-4.4248,0L3.08594,10.75732a.99963.99963,0,1,0,1.93945.48536L5.58618,9H8.52545ZM6.0863,7l.6969-2.78711a.29222.29222,0,0,1,.5459,0L8.02563,7Zm7.96936,0h1a1.001,1.001,0,0,1,1,1V9a1,1,0,0,0,2,0V8a3.00328,3.00328,0,0,0-3-3h-1a1,1,0,0,0,0,2Zm-4,9h-1a1.001,1.001,0,0,1-1-1V14a1,1,0,0,0-2,0v1a3.00328,3.00328,0,0,0,3,3h1a1,1,0,0,0,0-2Z"/>
    </Icon>
}

export function HomeLayout() {

    const [isOpen, setIsOpen] = React.useState(false);
    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
    console.log({ isSuccess })
    const [css] = useStyletron();
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, setUser } = useAppStore();
 console.log({user})
    return (
        <div className={'home-page'}>
            <Layer>
                <div
                    className={css({
                        boxSizing: 'border-box',
                        width: '100vw',
                        position: 'fixed',
                        top: '0',
                        left: '0',
                    })}
                >
                    <AppNavBar
                        title={<img src={FarminstaTitleImage} className={css({
                            height: '30px',
                            position: 'relative',
                            top: '5px'
                        })}/>}
                        mainItems={[...[{
                            info: { id: 'home' },
                            icon: ChevronRight,
                            label: t('app:menu-home', { defaultValue: 'Home' }),
                            navExitIcon: Delete,
                        }, {
                            info: { id: 'support' },
                            icon: SupportIcon,
                            label: t('app:menu-contact-support', 'Contact Support'),
                            navExitIcon: Delete,
                        }, {
                            info: { id: 'lang' },
                            icon: ChangeLanguageIcon,
                            label: t('app:menu-change-language', 'Change Language'),
                            navExitIcon: Delete,
                        }], ...(!user ? [{
                            info: { id: 'login' },
                            icon: ChevronRight,
                            label: t('app:menu-login', { defaultValue: 'Login' }),
                        }] : [{
                            icon: Overflow, label: t('app:menu-logout', 'Logout'), info: { id: 'logout' }
                        }])]}
                        userItems={user ? [
                            { icon: Overflow, label: t('app:menu-logout', 'Logout'), info: { id: 'logout' } }
                        ] : []}
                        onMainItemSelect={(item: NavItem) => {
                            if (item.info.id === 'lang') {
                                navigate('/lang');
                            } else if (item.info.id === 'support') {
                                window.open('tel://+917799888645')
                            } else if (item.info.id === 'login') {
                                navigate('/login');
                            } else if (item.info.id === 'home') {
                                navigate('/');
                            } else if (item.info.id === 'logout') {
                                setIsOpen(true)
                                // setUser(null);
                                // navigate('/');
                            }
                        }}
                        onUserItemSelect={(item) => {
                            if (item.info.id === 'logout') {
                                setIsOpen(true)
                                // setUser(null);
                                // navigate('/');
                            }
                        }}
                        username={user?.name || t('app:menu-no-user-name', "Hello")}
                        usernameSubtitle={t('app:menu-no-user-subtitle', "Farmer")}
                        userImgUrl={user?.profile_image_url || "https://avatars.dicebear.com/api/avataaars/seed.svg"}
                    />
                </div>
            </Layer>
            {/*<div className={css({ height: '60px', backgroundColor: 'white' })}>*/}
            {/*    <HeaderNavigation>*/}
            {/*        <StyledNavigationList $align={ALIGN.left}>*/}
            {/*            <StyledNavigationItem>{t('Home')}</StyledNavigationItem>*/}
            {/*        </StyledNavigationList>*/}
            {/*        <StyledNavigationList $align={ALIGN.center}/>*/}
            {/*        /!*<StyledNavigationList $align={ALIGN.right}>*!/*/}
            {/*        /!*    <StyledNavigationItem>*!/*/}
            {/*        /!*        <StyledLink href="#basic-link1">*!/*/}
            {/*        /!*            Tab Link One*!/*/}
            {/*        /!*        </StyledLink>*!/*/}
            {/*        /!*    </StyledNavigationItem>*!/*/}
            {/*        /!*    <StyledNavigationItem>*!/*/}
            {/*        /!*        <StyledLink href="#basic-link2">*!/*/}
            {/*        /!*            Tab Link Two*!/*/}
            {/*        /!*        </StyledLink>*!/*/}
            {/*        /!*    </StyledNavigationItem>*!/*/}
            {/*        /!*</StyledNavigationList>*!/*/}
            {/*        <StyledNavigationList $align={ALIGN.right}>*/}
            {/*            <StyledNavigationItem $style={{ paddingRight: '10px' }}>*/}
            {/*                <Icon size={24} onClick={() => navigate('/lang')}>*/}
            {/*                    <path xmlns="http://www.w3.org/2000/svg" d="M21.05566,12h-2a1,1,0,0,0,0,2v2H17.8714a2.96481,2.96481,0,0,0,.18426-1A2.99955,2.99955,0,0,0,12.458,13.50049a.99992.99992,0,1,0,1.73242.999A1.0009,1.0009,0,0,1,15.05566,14a1,1,0,0,1,0,2,1,1,0,0,0,0,2,1,1,0,1,1,0,2,1.0009,1.0009,0,0,1-.86523-.49951.99992.99992,0,1,0-1.73242.999A2.99955,2.99955,0,0,0,18.05566,19a2.96481,2.96481,0,0,0-.18426-1h1.18426v3a1,1,0,0,0,2,0V14a1,1,0,1,0,0-2ZM9.08594,11.24268a.99963.99963,0,1,0,1.93945-.48536L9.26855,3.72754a2.28044,2.28044,0,0,0-4.4248,0L3.08594,10.75732a.99963.99963,0,1,0,1.93945.48536L5.58618,9H8.52545ZM6.0863,7l.6969-2.78711a.29222.29222,0,0,1,.5459,0L8.02563,7Zm7.96936,0h1a1.001,1.001,0,0,1,1,1V9a1,1,0,0,0,2,0V8a3.00328,3.00328,0,0,0-3-3h-1a1,1,0,0,0,0,2Zm-4,9h-1a1.001,1.001,0,0,1-1-1V14a1,1,0,0,0-2,0v1a3.00328,3.00328,0,0,0,3,3h1a1,1,0,0,0,0-2Z"/>*/}
            {/*                </Icon>*/}
            {/*            </StyledNavigationItem>*/}
            {/*        </StyledNavigationList>*/}
            {/*    </HeaderNavigation>*/}
            {/*</div>*/}
            <div>
                <Outlet />
                <StyledModal isOpen={isOpen} setIsOpen={setIsOpen} message={t('app:confirm-logout-text','Are you sure you want to log out ?')} setIsSuccess={setIsSuccess} />   
                <SuccessMessage  isSuccess={isSuccess} setIsSuccess={setIsSuccess} message={t('app:logout-success-message',"You have been logged out successfully. ")} />
            </div>
        </div>
    )
}
