import { FlexGrid }                     from "baseui/flex-grid";
import { FlexGridItem }                 from "baseui/flex-grid";
import { BlockProps }                   from "baseui/block";
import { Block }                        from "baseui/block";
import { HeaderNavigation }             from "baseui/header-navigation";
import { StyledNavigationList }         from "baseui/header-navigation";
import { StyledNavigationItem }         from "baseui/header-navigation";
import { ALIGN }                        from "baseui/header-navigation";
import { useAppStore }                  from "../store";
import { useSubmit }                    from "react-router-dom";
import { redirect }                     from "react-router-dom";
import { ActionFunction }               from "react-router-dom";
import { i18nInstance }                 from "../i18n";
import { useStyletron }                 from "baseui";
import { useTranslation }               from "react-i18next";
import { HeadingMedium, HeadingXSmall } from "baseui/typography";

import FarminstaTitleImage from '../assets/farminsta-title.png';

export const action: ActionFunction = async ({ params, request }) => {
    const input = await request.json();
    if (input.locale) {
        useAppStore.getState().setLocale(input.locale);
        i18nInstance.changeLanguage(input.locale)
    }
    return redirect('/');
}

export function LanguageSelectPage() {
    const [css, theme] = useStyletron();
    const { t } = useTranslation();
    const itemProps: BlockProps = {
        backgroundColor: 'mono300',
        height: 'scale2400',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    };
    let submit = useSubmit();
    const { locale, setLocale } = useAppStore();
    const locales = [
        {
            enName: 'English',
            name: 'English',
            id: 'en_in'
        },
        //  {
        //     enName: 'Hindi',
        //     name: 'हिन्दी',
        //     id: 'hi_in'
        // },
        {
            enName: 'Telugu',
            name: 'తెలుగు',
            id: 'te_in'
        },
        {
            enName: 'Tamil',
            name: 'தமிழ்',
            id: 'ta_in'
        }
    ];

    const selectLocale = (locale) => {
        submit({ locale: locale.id }, { method: "post", encType: "application/json" })
    }

    return (
        <div>
            <HeaderNavigation>
                <StyledNavigationList $align={ALIGN.left}>
                    <StyledNavigationItem>
                        <img src={FarminstaTitleImage} className={css({
                            height: '30px',
                            position: 'relative',
                            top: '5px'
                        })}/>
                    </StyledNavigationItem>
                </StyledNavigationList>
            </HeaderNavigation>
            <Block className={css({ padding: theme.sizing.scale600 })}>
                <HeadingXSmall className={css({ textAlign: 'center' })} margin={0}>{t('app:select-lang-title', 'Select your Language')}</HeadingXSmall>
                <FlexGrid
                    marginTop={'scale500'}
                    flexGridColumnCount={2}
                    flexGridColumnGap="scale800"
                    flexGridRowGap="scale800"
                >
                    {locales.map((locale) => {
                        return (<FlexGridItem {...itemProps} key={locale.id} onClick={() => selectLocale(locale)}>
                            <HeadingMedium $style={{ textAlign: 'center' }}>{locale.name}</HeadingMedium>
                        </FlexGridItem>)
                    })}
                </FlexGrid>
            </Block>
        </div>
    )
}
