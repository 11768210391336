import { Block }        from "baseui/block";
import { Content }      from "../components/ContentWrap";
import { useStyletron } from "baseui";


export default function TermsPage() {
    const [css, theme] = useStyletron()

    return (
        <Block paddingLeft={'100px'} paddingRight={'100px'}
               className={css({
                   [theme.mediaQuery.small]: {
                       paddingLeft: '20px',
                       paddingRight: '20px'
                   },
                   [theme.mediaQuery.medium]: {
                       paddingLeft: '100px',
                       paddingRight: '100px'

                   },
                   [theme.mediaQuery.large]: {
                       paddingLeft: '100px',
                       paddingRight: '200px'

                   }
               })}
        >
            <h2>
                Terms
            </h2>

            <Content title={'Terms Of Use For Information Dissemination'} paragraph={`This document is an electronic record in terms of the amended Information Technology Act, 2000 and rules and regulation made thereunder. This electronic record is generated by a computer system and does not require any physical or digital signatures. This document is published in accordance with the provisions of Rule 3 (1) of the Information Technology (Intermediaries Guidelines) Rules, 2011 that require publishing the Terms for access or usage of Farminsta’s (Referred as "Platforms") service via Farminsta Portal. This document meets the stipulations and conditions mentioned in Section 65B (2) of the Indian Evidence Act, 1872.`}/>
            <Content title={`YOUR ACCEPTANCE OF THIS AGREEMENT`} paragraph={`This is an agreement between you ("you" or "your") and Farmreach Technologies Private Limited, a company incorporated under the Companies Act 1956 with its registered office at 201, Plot 343, Road no 1/3, Mathrusri Nagar, Miyapur, Hyderabad - 500049 ("Farmreach Technologies Pvt Ltd" "we," or "our") that governs your use of the search services offered by Farmreach through its service / product website www.farminsta.com ("Website"), telephone search, SMS, WAP or any other medium using which Farmreach may provide the services (collectively "Platforms" ). When you access or use any of the Platforms you agree to be bound by these Terms and Conditions ("Terms").  Farminsta is a software product of the company Farmreach Technologies Pvt Ltd. The website "www.farmreach.org" will give the information about the company and the product and services Farmreach offer. For availing our services one shall need to visit our product / service website/software.`}/>
            <Content title={`CHANGES`} paragraph={`We may periodically change the Terms and the Site without notice, and you are responsible for checking these Terms periodically for revisions. All amended Terms become effective upon our posting to the Site, and any use of the site after such revisions have been posted signifies your consent to the changes.`}/>
            <Content title={`HOW YOU MAY USE OUR MATERIALS`} paragraph={`We use a diverse range of information, text, photographs, designs, graphics, images, sound and video recordings, animation, content, advertisement and other materials and effects (collectively "Materials") for the search services on the Platforms. We provide the Material through the Platforms FOR YOUR PERSONAL AND NON-COMMERCIAL USE ONLY.

While every attempt has been made to ascertain the authenticity of the Platforms content, Farmreach is not liable for any kind of damages, losses or action arising directly or indirectly, due to access and/or use of the content in the Platforms including but not limited to decisions based on the content in the Platforms which results in any loss of data, revenue, profits, property, infection by viruses etc.

Accordingly, you may view, use, copy, and distribute the Materials found on the Platforms for internal, non-commercial, informational purposes only. You are prohibited from data mining, scraping, crawling, or using any process or processes that send automated queries to Farmreach. You may not use the Platforms or any of them to compile a collection of listings, including a competing listing product or service. You may not use the Platforms or any Materials for any unsolicited commercial e-mail. Except as authorized in this paragraph, you are not being granted a license under any copyright, trademark, patent or other intellectual property right in the Materials or the products, services, processes or technology described therein. All such rights are retained by Farmreach, its subsidiaries, parent companies, and/or any third party owner of such rights.`}/>
            <Content title={`HOW YOU MAY USE OUR MARKS`} paragraph={`The Farmreach company names and logos and all related products and service names, design marks and slogans are trademarks and service marks owned by and used under license from Just Dial or it's wholly owned subsidiaries. All other trademarks and service marks herein are the property of their respective owners. All copies that you make of the Materials on any of the Platforms must bear any copyright, trademark or other proprietary notice located on the respective Platforms that pertains to the material being copied. You are not authorized to use any Farmreach name or mark in any advertising, publicity or in any other commercial manner without the prior written consent of Farmreach. Requests for authorization should be made to admin@farmreach.org

HOW WE MAY USE INFORMATION YOU PROVIDE TO US: Do not send us any confidential or proprietary information. Except for any personally identifiable information that we agree to keep confidential as provided in our Privacy Policy, any material, including, but not limited to any feedback, data, answers, questions, comments, suggestions, ideas or the like, which you send to us will be treated as being non-confidential and non-proprietary. We assume no obligation to protect confidential or proprietary information (other than personally identifiable information) from disclosure and will be free to reproduce, use, and distribute the information to others without restriction. We will also be free to use any ideas, concepts, know-how or techniques contained in information that you send us for any purpose whatsoever including but not limited to developing, manufacturing and marketing products and services incorporating such information.
`}/>
            <Content title={`REVIEWS, RATINGS & COMMENTS BY USERS:`} paragraph={`Since, Farmreach provides information directory services through various mediums (SMS, WAP, E-Mail, Website, APP and voice or phone), your ("Users") use any of the aforementioned medium to post Reviews, Ratings and Comments about the Farmreach services and also about the Advertiser's listed at Farmreach is subject to additional terms and conditions as mentioned herein.

You are solely responsible for the content of any transmissions you make to the Site or any transmissions you make to any mediums offered by Farmreach and any materials you add to the Site or add to any mediums offered by Farmreach, including but not limited to transmissions like your Reviews, Ratings & Comments posted by you(the "Communications"). Farmreach does not endorse or accept any of your Communication as representative of their (Farmreach) views. By transmitting any public Communication to the Site, you grant Farmreach an irrevocable, non-exclusive, worldwide, perpetual, unrestricted, royalty-free license (with the right to sublicense) to use, reproduce, distribute, publicly display, publicly perform, adapt, modify, edit, create derivative works from, incorporate into one or more compilations and reproduce and distribute such compilations, and otherwise exploit such Communications, in all Platforms now known or later developed.

You confirm and warrant that you have the right to grant these rights to Just Dial . You hereby waive and grant to Just Dial all rights including intellectual property rights and also "moral rights" in your Communications, posted at Farmreach through any of mediums of Farmreach. Farmreach is free to use all your Communications as per its requirements from time to time. You represent and warrant that you own or otherwise control all of the rights to the content that you post as Review, Rating or Comments; that the content is accurate; that use of the content you supply does not violate these Terms and will not cause injury to any person or entity. For removal of doubts it is clarified that, the reference to Communications would also mean to include the reviews, ratings and comments posted by the users. Also Farmreach reserves the right to mask or unmask your identity in respect of your Reviews, Ratings & Comments posted by you.

Farmreach has the right, but not the obligation to monitor and edit or remove any content posted by you as Review, Rating or Comments. Farmreach cannot review all Communications made on and through any of the mediums of Farmreach. However, Farmreach reserves the right, but has no obligation, to monitor and edit, modify or delete any Communications (or portions thereof) which Farmreach in its sole discretion deems inappropriate, offensive or contrary to any Farmreach policy, or that violate this terms:

Farmreach reserves the right not to upload or distribute to, or otherwise publish through the Site or Forums any Communication which

      is obscene, indecent, pornographic, profane, sexually explicit, threatening, or abusive;

       constitutes or contains false or misleading indications of origin or statements of fact;

       slanders, libels, defames, disparages, or otherwise violates the legal rights of any third party;

         causes injury of any kind to any person or entity;

          infringes or violates the intellectual property rights (including copyright, patent and trademark rights), contract rights, trade secrets, privacy or publicity rights or any other rights of any third party;

         violates any applicable laws, rules, or regulations;

         contains software viruses or any other malicious code designed to interrupt, destroy or limit the functionality of any computer software or hardware or telecommunications equipment;

         impersonates another person or entity, or that collects or uses any information about Site visitors.

         It is also clarified that, if there are any issues or claims due to your posts by way of Reviews, Ratings and Comments, then Farmreach reserves right to take appropriate legal action against you. Further, you shall indemnify and protect Just Dial against such claims or damages or any issues, due to your posting of such Reviews, Ratings and Comments Farmreach takes no responsibility and assumes no liability for any content posted by you or any third party on Farmreach site or on any mediums of Farmreach.

        You further acknowledge that conduct prohibited in connection with your use of the Forums includes, but is not limited to, breaching or attempting to breach the security of the Site or any of the mediums of Farmreach.`}/>
            <Content title={`PRIVACY POLICY:`} paragraph={`Farmreach is committed to protecting the privacy and confidentiality of any personal information that it may request and receive from its clients, business partners and other users of the Website. To read our privacy policy statement regarding such personal information please refer PRIVACY POLICY`}/>
            <Content title={`CONTENT DISCLAIMER:`} paragraph={`Farmreach communicates information provided and created by advertisers, content partners, software developers, publishers, marketing agents, employees, users, resellers and other third parties. While every attempt has been made to ascertain the authenticity of the content on the Platforms Farmreach has no control over content, the accuracy of such content, integrity or quality of such content and the information on our pages, and material on the Platforms may include technical inaccuracies or typographical errors, and we make no guarantees, nor can we be responsible for any such information, including its authenticity, currency, content, quality, copyright compliance or legality, or any other intellectual property rights compliance, or any resulting loss or damage. Further, we are not liable for any kind of damages, losses or action arising directly or indirectly due to any content, including any errors or omissions in any content, access and/or use of the content on the Platforms or any of them including but not limited to content based decisions resulting in loss of data, revenue, profits, property, infection by viruses etc.
         All of the data on products and promotions including but not limited to, the prices and the availability of any product or service or any feature thereof, is subject to change without notice by the party providing the product or promotion. You should use discretion while using the Platforms .

         Farmreach reserves the right, in its sole discretion and without any obligation, to make improvements to, or correct any error or omissions in, any portion of the Platforms. Where appropriate, we will endeavour to update information listed on the Website on a timely basis, but shall not be liable for any inaccuracies.
         
         All rights, title and interest including trademarks and copyrights in respect of the domain name and Platforms content hosted on the Platforms are reserved with Farmreach. Users are permitted to read, print or download text, data and/or graphics from the Website or any other Platforms for their personal use only. Unauthorized access, reproduction, redistribution, transmission and/or dealing with any information contained in the Platforms in any other manner, either in whole or in part, are strictly prohibited, failing which strict legal action will be initiated against such users.
         
         Links to external Internet sites may be provided within the content on Website or other Platforms as a convenience to users. The listing of an external site does not imply endorsement of the site by Farmreach or its affiliates. Farmreach does not make any representations regarding the availability and performance of its Platforms or any of the external websites to which we provide links. When you click on advertiser banners, sponsor links, or other external links from the Website or other Platforms, your browser automatically may direct you to a new browser window that is not hosted or controlled by Farmreach.
         
         Farmreach and its affiliates are not responsible for the content, functionality, authenticity or technological safety of these external sites. We reserve the right to disable links to or from third-party sites to any of our Platforms, although we are under no obligation to do so. This right to disable links includes links to or from advertisers, sponsors, and content partners that may use our Marks as part of a co-branding relationship.
         
         Some external links may produce information that some people find objectionable, inappropriate, or offensive. We are not responsible for the accuracy, relevancy, copyright compliance, legality, or decency of material contained in any externally linked websites. We do not fully screen or investigate business listing websites before or after including them in directory listings that become part of the Materials on our Platforms, and we make no representation and assume no responsibility concerning the content that third parties submit to become listed in any of these directories.
         
         All those sections in the Platforms that invite reader participation will contain views, opinion, suggestion, comments and other information provided by the general public, and Farmreach will at no point of time be responsible for the accuracy or correctness of such information. Farmreach reserves the absolute right to accept/reject information from readers and/or advertisements from advertisers and impose/relax Platforms access rules and regulations for any user(s).
         
         Farmreach also reserves the right to impose/change the access regulations of the Platforms , whether in terms of access fee, timings, equipment, access restrictions or otherwise, which shall be posted from time to time under these terms and conditions. It is the responsibility of users to refer to these terms and conditions each time they use the Platforms.
         
         While every attempt has been made to ascertain the authenticity of the content in the Platforms, Farmreach is not liable for any kind of damages, losses or action arising directly or indirectly, due to access and/or use of the content in the Platforms including but not limited to any decisions based on content in the Platforms resulting in loss of data, revenue, profits, property, infection by viruses etc.`}/>
            <Content title={`WARRANTY DISCLAIMER:`} paragraph={`Please remember that any provider of goods or services is entitled to register with Farmreach. Farmreach does not examine whether the advertisers are good, reputable or quality sellers of goods / service providers. You must satisfy yourself about all relevant aspects prior to availing of the terms of service. Farmreach has also not negotiated or discussed any terms of engagement with any of the advertisers. The same should be done by you. Purchasing of goods or availing of services from advertisers shall be at your own risk.

We do not investigate, represent or endorse the accuracy, legality, legitimacy, validity or reliability of any products, services, deals, coupons or other promotions or materials, including advice, ratings, and recommendations contained on, distributed through, or linked, downloaded or accessed from the Platforms.

References that we make to any names, marks, products or services of third parties or hypertext links to third party sites or information do not constitute or imply our endorsement, sponsorship or recommendation of the third party, of the quality of any product or service, advice, information or other materials displayed, purchased, or obtained by you as a result of an advertisement or any other information or offer in or in connection with the Platforms.

Any use of the Platforms, reliance upon any Materials, and any use of the Internet generally shall be at your sole risk. Farm reach disclaims any and all responsibility or liability for the accuracy, content, completeness, legality, reliability, or operability or availability of information or material displayed in the search results in the Platforms.

THE MATERIAL AND THE PLATFORMS USED TO PROVIDE THE MATERIAL (INCLUDING THE WEBSITE ) ARE PROVIDED "AS IS" AND "AS AVAILABLE" WITHOUT WARRANTY OF ANY KIND, EITHER EXPRESS OR IMPLIED OR STATUTORY, INCLUDING, BUT NOT LIMITED TO, THE IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT. JUST DIAL DISCLAIMS, TO THE FULLEST EXTENT PERMITTED UNDER LAW, ANY WARRANTIES REGARDING THE SECURITY, RELIABILITY, TIMELINESS, ACCURACY AND PERFORMANCE OF THE PLATFORMS AND MATERIALS. JUST DIAL DOES NOT WARRANT THAT ANY DEFECTS OR ERRORS WILL BE CORRECTED; OR THAT THE CONTENT IS FREE OF VIRUSES OR OTHER HARMFUL COMPONENTS.

FARMREACH DISCLAIMS ANY AND ALL WARRANTIES TO THE FULLEST EXTENT OF THE LAW, INCLUDING ANY WARRANTIES FOR ANY INFORMATION, GOODS, OR SERVICES, OBTAINED THROUGH, ADVERTISED OR RECEIVED THROUGH ANY LINKS PROVIDED BY OR THROUGH THE PLATFORMS SOME COUNTRIES OR OTHER JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF IMPLIED WARRANTIES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO YOU. YOU MAY ALSO HAVE OTHER RIGHTS THAT VARY FROM COUNTRY TO COUNTRY AND JURISDICTION TO JURISDICTION.
`}/>
            <Content title={`ADDITIONAL DISCLAIMER:`} paragraph={`Users using any of Farmreach service across the following mediums ie. through internet ie www.farminsta.com Website, Wapsite, SMS, phone or any other medium are bound by this additional disclaimer wherein they are cautioned to make proper enquiry before they (Users) rely, act upon or enter into any transaction (any kind or any sort of transaction including but not limited to monetary transaction ) with the Advertiser listed with Farmreach.

All the Users are cautioned that all and any information of whatsoever nature provided or received from the Advertiser/s is taken in good faith, without least suspecting the bonafides of the Advertiser/s and Just Dial does not confirm, does not acknowledge, or subscribe to the claims and representation made by the Advertiser/s listed with Farmreach. Further, Farmreach is not at all responsible for any act of Advertiser/s listed at Farmreach.`}/>
            <Content title={`LIMITATION OF LIABILITY:`} paragraph={`IN NO EVENT SHALL JUST DIAL BE LIABLE TO ANY USER ON ACCOUNT OF SUCH USER'S USE, MISUSE OR RELIANCE ON THE PLATFORMS FOR ANY DAMAGES WHATSOEVER, INCLUDING DIRECT, SPECIAL, PUNITIVE, INDIRECT, CONSEQUENTIAL OR INCIDENTAL DAMAGES OR DAMAGES FOR LOSS OF PROFITS, REVENUE, USE, OR DATA WHETHER BROUGHT IN WARRANTY, CONTRACT, INTELLECTUAL PROPERTY INFRINGEMENT, TORT (INCLUDING NEGLIGENCE) OR OTHER THEORY, EVEN IF JUST DIAL ARE AWARE OF OR HAVE BEEN ADVISED OF THE POSSIBILITY OF SUCH DAMAGE, ARISING OUT OF OR CONNECTED WITH THE USE (OR INABILITY TO USE) OR PERFORMANCE OF THE PLATFORMS, THE MATERIALS OR THE INTERNET GENERALLY, OR THE USE (OR INABILITY TO USE), RELIANCE UPON OR PERFORMANCE OF ANY MATERIAL CONTAINED IN OR ACCESSED FROM ANY PLATFORMS. JUST DIAL DOES NOT ASSUME ANY LEGAL LIABILITY OR RESPONSIBILITY FOR THE ACCURACY, COMPLETENESS, OR USEFULNESS OF ANY INFORMATION, APPARATUS, PRODUCT OR PROCESS DISCLOSED ON THE PLATFORMS OR OTHER MATERIAL ACCESSIBLE FROM THE PLATFORMS.

THE USER OF THE PLATFORMS ASSUMES ALL RESPONSIBILITY AND RISK FOR THE USE OF THIS PLATFORMS AND THE INTERNET GENERALLY. THE FOREGOING LIMITATIONS SHALL APPLY NOTWITHSTANDING ANY FAILURE OF THE ESSENTIAL PURPOSE OF ANY LIMITED REMEDY AND TO THE FULLEST EXTENT PERMITTED UNDER APPLICABLE LAW. SOME COUNTRIES DO NOT ALLOW THE EXCLUSION OR LIMITATION OF LIABILITY OF CONSEQUENTIAL OR INCIDENTAL DAMAGES, SO THE ABOVE EXCLUSIONS MAY NOT APPLY TO ALL USERS; IN SUCH COUNTRIES LIABILITY IS LIMITED TO THE FULLEST EXTENT PERMITTED BY LAW.
`}/>
            <Content title={`THIRD PARTY SITES:`} paragraph={`Your correspondence or business dealing with or participation in the sales promotions of advertisers or service providers found on or through the Platforms, including payment and delivery of related goods or services, and any other terms, conditions, and warranties or representations associated with such dealings, are solely between you and such advertisers or service providers. You assume all risks arising out of or resulting from your transaction of business over the Internet, and you agree that we are not responsible or liable for any loss or result of the presence of information about or links to such advertisers or service providers on the Platforms. You acknowledge and agree that we are not responsible or liable for the availability, accuracy, authenticity, copyright compliance, legality, decency or any other aspect of the content, advertising, products, services, or other materials on or available from such sites or resources. You acknowledge and agree that your use of these linked sites is subject to different terms of use than these Terms, and may be subject to different privacy practices than those set forth in the Privacy Policy governing the use of the Platforms . We do not assume any responsibility for review or enforcement of any local licensing requirements that may be applicable to businesses listed on the Platforms.`}/>
            <Content title={``} paragraph={`MONITORING OF MATERIALS TRANSMITTED BY YOU: Changes may be periodically incorporated into the Platforms. Farmreach may make improvements and/or changes in the products, services and/or programs described in these Platforms and the Materials at any time without notice. We are under no obligation to monitor the material residing on or transmitted to the Platforms . However, anyone using the Platforms agrees that Farmreach may monitor the Platforms contents periodically to (1) comply with any necessary laws, regulations or other governmental requests; (2) to operate the Platforms properly or to protect itself and its users. Farmreach reserves the right to modify, reject or eliminate any material residing on or transmitted to its Platforms that it, in its sole discretion, believes is unacceptable or in violation of the law or these Terms and Conditions.

DELETIONS FROM SERVICE: Farmreach will delete any materials at the request of the user who submitted the materials or at the request of an advertiser who has decided to "opt-out" of the addition of materials to its advertising, including, but not limited to ratings and reviews provided by third parties. Farmreach reserves the right to delete (or to refuse to post to public forums) any materials it deems detrimental to the system or is, or in the opinion of Farmreach, may be, defamatory, infringing or violate of applicable law. Farmreach reserves the right to exclude Material from the Platforms. Materials submitted to Farmreach for publication on the Platforms may be edited for length, clarity and/or consistency with Farmreach's editorial standards.
`}/>
            <Content title={`INDEMNIFICATION:`} paragraph={`You agree to indemnify and hold us and (as applicable) our parent, subsidiaries, affiliates, officers, directors, agents, and employees, harmless from any claim or demand, including reasonable attorneys' fees, made by any third party due to or arising out of your breach of these Terms, your violation of any law, or your violation of the rights of a third party, including the infringement by you of any intellectual property or other right of any person or entity. These obligations will survive any termination of the Terms.

Entire Agreement. These Terms constitutes the entire agreement between you and us with respect to the subject matter of these Terms and supersedes all prior written and all prior or contemporaneous oral communications regarding such subject matter. Accordingly, you should not rely on any representations or warranties that are not expressly set forth in these Terms. If any provision or provisions of these Terms shall be held to be invalid, illegal, unenforceable or in conflict with the law of any jurisdiction, the validity, legality and enforceability of the remaining provisions shall not in any way be affected or impaired. Except as provided in Section 1, these Terms may not be modified except by writing signed by you and us; provided, however, we may change these Terms from time to time, and such revised terms and conditions shall be effective with respect to any Advertising Products ordered after written notice of such revised terms to you or, if earlier, posting of such revised terms and conditions on our Website.

In relation to any complaints or concerns, you can email to us at "admin@farmreach.org".`}/>
        </Block>
    )
}
