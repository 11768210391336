import { InMemoryCache }       from "@apollo/client";
import { ApolloClient }        from "@apollo/client";
import { createHttpLink }      from "@apollo/client";
import { split }               from "@apollo/client";
import { CachePersistor }      from "apollo3-cache-persist";
import { useState }            from "react";
import { setContext }          from '@apollo/client/link/context';
import { GraphQLWsLink }       from '@apollo/client/link/subscriptions';
import { createClient }        from 'graphql-ws';
import { getMainDefinition }   from "@apollo/client/utilities";
// import { usePostHog }          from 'posthog-react-native'
import { LocalStorageWrapper } from "apollo3-cache-persist/src/storageWrappers/LocalStorageWrapper";
import gql                     from 'graphql-tag';


const SCHEMA_VERSION = "v1.2";
const storages: {
    user: NamespacedLocalStorage,
    app: NamespacedLocalStorage,
} = {
    // @ts-ignore
    user: null,
    // @ts-ignore
    app: null,
};

export class NamespacedLocalStorage {
    private ns: string;

    constructor(ns: string) {
        this.ns = ns;
    }

    getItem(key: string): string | null {
        return localStorage.getItem(`${this.ns}:${key}`);
    }

    setItem(key: string, value: string) {
        return localStorage.setItem(`${this.ns}:${key}`, value);
    }

    removeItem(key: string) {
        return localStorage.removeItem(`${this.ns}:${key}`);
    }
}

export function getStorages() {
    if (storages.user) {
        return storages;
    }
    const appStorage = new NamespacedLocalStorage("app-storage");
    const user_id = appStorage.getItem("user_id") || "guest";
    storages.app = appStorage;
    storages.user = new NamespacedLocalStorage(`user-${user_id}-storage`);
    //console.log({ getStorages: "fn", user_id })
    return storages;
}

export function setUserIdInAppStorage(user_id: string) {
    const appStorage = getStorages().app;
    appStorage.setItem("user_id", user_id);
    storages.user = new NamespacedLocalStorage(`user-${user_id}-storage`);
}

export const persistentToken = "eyJ0eXAiOiJKV1QiLCJhbGciOiJSUzI1NiJ9.eyJpc3MiOiJodHRwczovL2Zhcm1pbnN0YS1iYWNrZW5kLnRlc3QiLCJpYXQiOjE2ODcyNDIzMTQsImV4cCI6MTc3MzY0MjMxNCwibmJmIjoxNjg3MjQyMzE0LCJqdGkiOiJzb1RvclhmcHdYMU84Q3B0Iiwic3ViIjoiMCIsInBydiI6IjIzYmQ1Yzg5NDlmNjAwYWRiMzllNzAxYzQwMDg3MmRiN2E1OTc2ZjciLCJyb2xlcyI6WyJhbm9ueW1vdXMiXSwiY29tcGFueV9pZCI6IjAiLCJwaG9uZSI6IiJ9.A8Hg8AYhjSyk5_Y2yrx4SbeWDMdnVw5InWv2befi3KfPV9-P2xWtZiKJBHCYIM0Bgw-SNu6d-6MHKRPKdX8MwSrFxd_NmMjLaZP8_pLfU4OVL3XT3JMt_jGuKY5N8-dNEDY7r_xjl2YVCDD86dSwLpTf-FiSYQYKui607awixTXeZcCCdDzNc2Z5V_h33MBjs6P4Unr12rmBjUltbcI1I-QedWLKbxQoG5Jd5ZsbgD-VUrhK4-mkmkdN6XNhr9hqahbU6qEh2Z8FdEQe9WPPmrX-Igq3L8BJ3aQh_-dBFc4tkj7bsjI0k-uJXzq9dzatzyREan43w14F7DdAwiQR6LJELDqmLJeeQF_7vq0qiAz_eGcetQEG5FcVGLdE87tmWx19_rxYjFWRzJUPxK5n_DjVpRJg6M87elKrMHQFa4rq2uthXwqOxjzQQQgc0tK4970XaVVMvOHYZCKaneoxI4s6w6PMlVqAXxLl_3e3uc0uWUkWebBZKonZN8r6FEV7CRsnkXcbL4YFt1EO2N3lGHbV44jdnCEV4Ohx2bxvoeQuXZtVKNnzihQUycKRWINoV15ctRZEuECZy0I7BCuGSHQAAuXefhElfbGlb-j9pUnZ7YZYese_naAlSVJQ9dlGYlWAZZp-YwKzg3fi8eTMtNIbMXZZ68-IH5oJ_CNtDJk";

export function getBaseUrls(env = "local") {
    env = 'prod';
    return {
        prod: {
            https: "https://gql.farminsta.com/v1/graphql",
            // wss: "wss://fi-graphql.farminsta.com/v1/graphql"
            wss: "wss://gql.farminsta.com/v1/graphql",
        },
        local: {
            https: "http://192.168.29.122:9080/v1/graphql",
            wss: "ws://192.168.29.122:9080/v1/graphql"
        }
    }[env];
}

export function useApolloSetup() {
    // const posthog = usePostHog()

    const [data, setData] = useState<{
        gqlClient: ApolloClient<any> | null,
        cachePersistor: CachePersistor<any> | null,
        appStorage: NamespacedLocalStorage | null,
        userStorage: NamespacedLocalStorage | null,
        token: string | undefined | null
        setToken: (token: string) => void
    }>({
        gqlClient: new ApolloClient({
            cache: new InMemoryCache(),
            version: "1.0",
            name: "Farminsta App"
        }),
        cachePersistor: null,
        appStorage: null,
        userStorage: null,
        token: null,
        setToken: () => {
        }
    });

    if (data.appStorage) {
        return data;
    }
    // posthog.capture("Setting up local storages");

    const {
        user: userStorage,
        app: appStorage,
    } = getStorages();
    const cache = new InMemoryCache();

    // await before instantiating ApolloClient, else queries might run before the cache is persisted
    const cachePersistor = new CachePersistor({
        cache,
        storage: new LocalStorageWrapper(userStorage),
    });
    (async () => {
        // console.log("doing persistence");
        const currentVersion = appStorage.getItem("SCHEMA_VERSION_KEY");
        if (currentVersion === SCHEMA_VERSION) {
            await cachePersistor.restore();
        } else {
            // posthog.capture("Local schema changed")
            await cachePersistor.purge();
            // userStorage.setItem("GQL_TOKEN", "");
            // userStorage.getAllKeys().map(k => userStorage.delete(k));
            appStorage.setItem("SCHEMA_VERSION_KEY", SCHEMA_VERSION);
        }
        // console.log("starting to get token");
        let token = data.token || appStorage.getItem("GQL_TOKEN");
        const setToken = (newToken: string) => {
            appStorage.setItem("GQL_TOKEN", newToken);
            setData((prev) => ({ ...prev, token: newToken, gqlClient: null }))
        }
        if (!token) {
            // const anonToken = await fetch("https://admin.farminsta.com/api/anonymous-jwt");
            // const anonTokenJson = await anonToken.json();
            token = persistentToken;
            // // console.log({anonTokenJson})
            appStorage.setItem("GQL_TOKEN", token);
        }
        const authLink = setContext((_, { headers }) => {
            // get the authentication token from local storage if it exists
            const token1 = appStorage.getItem('GQL_TOKEN');
            // return the headers to the context so httpLink can read them
            // console.log({ token1 });
            return {
                headers: {
                    // ...(headers||{}),
                    SomeOther: "boring",
                    Authorization: `Bearer ${token1 || persistentToken}`,
                }
            }
        });
        const httpLink = createHttpLink({
            uri: getBaseUrls()?.https,
            headers: {
                Authorization: `Bearer ${token || persistentToken}`,
            }
        });
        // console.log({wsLink: getBaseUrls()?.wss || ""})
        const wsLink = new GraphQLWsLink(createClient({
            url: getBaseUrls()?.wss || "",
            connectionParams: () => {
                const token = appStorage.getItem('GQL_TOKEN');
                return {
                    headers: {
                        Authorization: `Bearer ${token || persistentToken}`,
                    }
                }
            }
        }));
        const splitLink = split(
            ({ query }) => {
                const definition = getMainDefinition(query);
                // console.log({ definition })
                return (
                    definition.kind === 'OperationDefinition' &&
                    definition.operation === 'subscription'
                );
            },
            wsLink,
            httpLink,
        );
        const gqlClient = new ApolloClient({
            link: authLink.concat(splitLink),
            cache,
            headers: {
                Authorization: `Bearer ${token || persistentToken}`,
            },
            name: "Farminsta GraphQL Client",
            version: "1.0"
        });

        setData({
            gqlClient,
            cachePersistor,
            appStorage,
            userStorage,
            token,
            setToken
        });
    })();

    return data
}


const fieldPartnerUpdatesLocation = gql`
    mutation userUpdatesDeviceInfo($userId:String!, $deviceToken:String, $deviceInstallationId:String!, $deviceType:String, $appVersion:String, $osVersion:String, $deviceModel:String, $deviceName:String, $appBuildNumber:String, $deviceBrand:String, $deviceManufacturer:String, $deviceOsVersion:String, $nativePushToken: String, $releaseChannel: String!, $runtimeVersion: String!) {
        userUpdatesHisDeviceInfo(
            command: {
                userId: $userId,
                deviceToken: $deviceToken,
                nativePushToken: $nativePushToken,
                deviceInstallationId: $deviceInstallationId,
                deviceType: $deviceType,
                appVersion: $appVersion,
                deviceOs: $osVersion,
                deviceModel: $deviceModel,
                deviceName: $deviceName,
                appBuildNumber: $appBuildNumber,
                deviceBrand: $deviceBrand,
                deviceManufacturer: $deviceManufacturer,
                deviceOsVersion: $deviceOsVersion,
                releaseChannel: $releaseChannel,
                runtimeVersion: $runtimeVersion,
            }
        ) {
            data {
                status
            }
            error
        }        
    }

    mutation fieldPartnerUpdatesLocation(
        $lat: Float!
        $lng: Float!
        $field_partner_user_id: String!
    ) {
        setFieldForceLocation(
            command: {
                field_partner_user_id: $field_partner_user_id
                lat: $lat
                lng: $lng
            }
        ) {
            data {
                status
            }
            error
        }
    }

    
mutation ffGoesOnline($userId:String!,$phoneNumber:String!) {
  ffWentOnline(command:{field_partner_user_id:$userId, phone_number:$phoneNumber}){
    data{
      status
    }
    error
  }
}


mutation ffGoesOffline($userId:String!,$phoneNumber:String!) {
  ffWentOffline(command:{field_partner_user_id:$userId, phone_number:$phoneNumber}){
    data{
      status
    }
    error
  }
}
`;
const nearbyRequests = gql`
    subscription searchNearbyRequests($lat: float8!, $lng: float8!, $kms: Int!) {
        requests_nearby: search_field_force_near_farmer(
            args: {lat: $lat, lng: $lng, distance_kms: $kms}
            where: {type: {_eq: "service_request"}}
        ) {
            id
            distance_kms
            service_request {
                master_crop {
                    name
                }
                farmer_user_id
                user {
                    name
                }
                date_of_sowing
                is_request_closed
                field_partner_responses {
                    id
                    fieldPartner: userByFieldPartnerUserId {
                        id
                        name
                        phone_number
                        email
                    }
                    messages: field_partner_response_messages {
                        id
                        message
                        assets
                    }
                }
            }
            lat
            lng
        }
    }
`;
const myRequestsGql = gql`
    subscription ffMyRequests($field_partner_user_id: bpchar!) {
        ffMyRequests: field_partner_responses(
            where: {field_partner_user_id: {_eq: $field_partner_user_id}}
        ) {
            id
            is_response_closed
            service_request {
                id
                is_recurring
                master_crop {
                    id
                    name
                }
                farmer_user_id
                farmer: user {
                    id
                    name
                    phone_number
                }
                date_of_sowing
                is_request_closed
                created_at
            }
            fieldPartner: userByFieldPartnerUserId {
                id
                name
                phone_number
                email
            }
            messages: field_partner_response_messages {
                id
                message
                assets
            }
            schedules: field_scheduled_visits {
                id
                scheduled_at
                is_farmer_accepted
                is_field_partner_accepted
                is_visit_done
            }
            events: field_partner_response_events {
                id
                event_name
                event_data
                created_at
            }
        }
    }
`;

const chatRequest = gql`
    subscription fprSub($fprId: bpchar!) {
        field_partner_responses(where: {id: {_eq: $fprId}}) {
            id
            field_partner_user_id
            field_partner_rating           
            farmer_user_id
            request_id
            created_at
            response_action
            farmer_rating
            farmer_feedback
            field_partner_rating
            field_partner_feedback
            is_response_closed
            service_request {
                id
                crop
                date_of_sowing
                is_request_closed
                assets
                lat
                lng

                farmer: user {
                    id
                    name
                    phone_number
                    profile_image_url

                    user_feedbacks_aggregate {
                        aggregate {
                            avg {
                                rating
                            }
                        }
                    }
                }
            }
            fieldPartner: userByFieldPartnerUserId {
                id
                name
                phone_number
                profile_image_url
                email
                user_feedbacks_aggregate {
                aggregate {
                    avg {
                        rating
                    }
                }
            }
                related_accounts: account_users {
                    id
                    account {
                        id
                        name
                    }
                }
            }
            messages: field_partner_response_messages {
                id
                message
                from_user_id
                to_user_id
                created_at
                assets
            }
            field_partner_rated_at
            farmer_rated_at
        }
    }
`;

const myRequest = gql`
    query farmerGetSingleRequest($requestId: bpchar!) {
        request: service_requests(where: {id: {_eq: $requestId}}) {
            id
            farmer_user_id
            crop
            crop_id
            is_request_closed
            is_recurring
            master_crop {
                id
                name
            }
            locations: last_known_locations {
                lat
                lng
            }
            field_partner_responses {
            farmer_feedback
                id
                field_partner_rating
                response_action
                fieldPartner: userByFieldPartnerUserId {
                    id
                    name
                    phone_number
                    email
                    profile_image_url
                    user_feedbacks_aggregate {
                aggregate {
                    avg {
                        rating
                    }
                }
            }
                    related_accounts: account_users {
                id
                account {
                    id
                    name
                }
            }
                   
                }
                messages: field_partner_response_messages {
                    id
                    message
                    from_user_id
                    to_user_id
                    assets
                }
            }
            date_of_sowing
            assets(path: "$")
        }
    }

    mutation sendMessage(
        $farmerUserId: String!
        $fieldPartnerUserId: String!
        $message: String!
        $requestId: String!
        $senderUserId: String!
    ) {
        sendMessageOnServiceRequest(
            command: {
                farmer_id: $farmerUserId
                field_partner_id: $fieldPartnerUserId
                message: $message
                requestId: $requestId
                sender_user_id: $senderUserId
            }
        ) {
            data {
                status
            }
            error
        }
    }
`;
const notification = gql`
query userNotifications($userId:bpchar!) {
    notifications(where:{notifiable_id:{_eq:$userId}}) {
    id 
    data
    created_at
    read_at
  }
}
`
const closeServiceRequestGql = gql`
    mutation fieldPartnerClosesRequest(
        $fieldPartnerUserId: String!
        $requestId: String!
    ) {
        fieldPartnerClosesRequest(
            command: {fieldPartnerUserId: $fieldPartnerUserId, requestId: $requestId}
        ) {
            data {
                status
            }
            error
        }
    }
    mutation fieldPartnerClosesRequestFeedback(
        $farmerUserId: String!
        $feedback: String!
        $fieldPartnerUserId: String!
        $requestId: String!
        $rating: String!
    ) {
        fieldPartnerRatesAndReviewsFarmer(
            command: {
                farmer_id: $farmerUserId
                feedback: $feedback
                field_partner_id: $fieldPartnerUserId
                id: $requestId
                rating: $rating
            }
        ) {
            data {
                status
            }
            error
        }
    }
`;
const closeFieldPartnerRequest = gql`
    mutation farmerClosesServiceRequestFeedback(
        $requestId: String!
        $farmerUserId: String!
        $feedback: String!
        $fieldPartnerUserId: String!
        $rating: Int!
    ) {
        farmerRatesAndReviewsServiceRequest(
            command: {
                feedback: $feedback
                rating: $rating
                farmerUserId: $farmerUserId
                fieldPartnerUserId: $fieldPartnerUserId
                requestId: $requestId
            }
        ) {
            data {
                status
            }
            error
        }
    }

mutation userUpdatesHisLocale($userId:String!, $localeName:String!) {
  userSetHisLocale(command:{userId:$userId, localeName:$localeName}) {
    data{
      status
    }
    error
  }
}


    mutation farmerClosesServiceRequest($requestId: String!) {
        farmerClosesServiceRequest(command: {id: $requestId}) {
            data {
                status
            }
            error
        }
    }
`;

const FfHomeGql = gql`
    query getMasterCrops {
        crops: master_crops {
            id
            name
        }
    }
    
    query farmerMyRequests($farmerUserId: bpchar!) {
        service_requests(where: {farmer_user_id: {_eq: $farmerUserId}}, order_by: {id: desc_nulls_last}) {
            id
            farmer_user_id
            is_recurring
            crop
            crop_id
            is_request_closed
            master_crop {
                id
                name
            }
            locations: last_known_locations {
                lat
                lng
            }
            field_partner_responses {
                id
                userByFieldPartnerUserId {
                    id
                    name
                    phone_number
                    email
                }
                field_partner_response_messages {
                    id
                    message
                    assets
                }
            }
            date_of_sowing
            assets(path: "$")
        }
    }
    
    mutation RequestOTP(
    $phoneNumber: String!
    $countryCode: String!
    $hashKey: String!
) {
    requestOtpCommand(
        command: {
            phoneNumber: $phoneNumber
            countryCode: $countryCode
            hashKey: $hashKey
        }
    ) {
        encrypted
        nonce
        otp
        phoneNumber
    }
}
mutation VerifyOTP(
    $encrypted: String!
    $nonce: String!
    $otp: String!
    $phone_number: String!
    $roleName: String!
) {
    verifyOtpCommand(
        command: {
            encrypted: $encrypted
            nonce: $nonce
            otp: $otp
            phoneNumber: $phone_number
            roleName: $roleName
        }
    ) {
        is_verified
        token
        user {
            id
            name
            roles
            phoneNumber
        }
    }
}

subscription searchNearbyFarmers($lat: float8!, $lng: float8!, $kms: Int!) {
    farmers_nearby: search_field_force_near_farmer(args: {lat: $lat, lng: $lng, distance_kms: $kms}, where: {type: {_eq: "service_request"}}, limit: 10) {
      id
     distance_kms
     lng
     lat
     service_request {
       id
       crop
       date_of_sowing
    }
  }
}


    subscription searchNearbyFF($lat: float8!, $lng: float8!, $kms: Int!) {
        ff_nearby: search_field_force_near_farmer(
            args:{
                lat:$lat, lng:$lng, distance_kms:$kms
            }
            where:{
                type:{_eq: "user"},

                role_name:{_eq:"fi-field-partner"}
                is_active:{_eq:true}
            }
        ){
            id
            distance_kms
            user {
                id
                name
                profile_image_url
                related_segments: segment_users {
                    segment: master_segment {
                        name
                    }
                }
                related_crops: crop_users {
                    crop: master_crop {
                        name
                    }
                }
            }
            lat
            lng
        }
    }
    
    mutation getCfData($type: String!) {
        cfUploadData(type:$type,expiresInSeconds:600)
    }
    query getFarmerPlots ($userId: bpchar!) {
        farmer_plots (where: {farmer_user_id: {_eq: $userId}}) {
            id
            crop_id
            crop_name
            date_of_sowing
        }
    }
    
    mutation setRoleOnUser($roleName: String!, $userId: String!) {
        setRoleCommand(command:{roleName: $roleName,userId: $userId}) {
            data {status}
            error
        }
    }
    
    subscription ffHomeNewRequests(
        $lat: float8!
        $lng: float8!
        $kms: Int!
        $fieldPartnerUserId: bpchar!
    ) {
        newRequests: search_field_force_near_farmer(
            args: {lat: $lat, lng: $lng, distance_kms: $kms},
            where: {
                type: {_eq: "service_request"}
                _not: {
                    service_request: {
                        field_partner_responses: {
                            field_partner_user_id: {_eq: $fieldPartnerUserId}
                        }
                    }
                }
            },
            order_by: {service_request: {created_at: desc}}
        ) {
            id
            distance_kms
            service_request {
                id
                farmer: user {
                    id
                    name
                    phone_number
                    profile_image_url
                    user_feedbacks_aggregate {
                            aggregate {
                                avg {
                                    rating
                                }
                            }
                        }
                }
                crop: master_crop {
                    id
                    name
                    image_url
                }
                assets
                date_of_sowing
                lat
                lng
                address
            }
        }
    }
    query FfHome(
        $lat: float8!
        $lng: float8!
        $kms: Int!
        $fieldPartnerUserId: bpchar!
    ) {
        ffMyRequests: service_requests(
            where: {
                field_partner_responses: {
                    field_partner_user_id: {_eq: $fieldPartnerUserId}
                }
            }
        ) {
            id
            farmer: user {
                id
                name
                phone_number
            }
            crop
            crop_id
            date_of_sowing
            lat
            lng
            field_partner_responses {
                id
                field_partner: userByFieldPartnerUserId {
                    id
                    name
                    phone_number
                }
            }
        }
    }
`;

const ffAcceptsRequest = gql`
    mutation FfRespondsToRequest(
        $serviceRequestId: String!
        $fieldPartnerUserId: String!
        $action: String!
    ) {
        fieldPartnerRespondsToRequest(
            command: {
                requestId: $serviceRequestId
                fieldPartnerUserId: $fieldPartnerUserId
                action: $action
            }
        ) {
            data {
                status
            }
            error
        }
    }

`;

const CropsSegments = gql`
    query userDetail($userId: bpchar!) {
        users_by_pk(id: $userId) {
            id
            name
  	        gender
            date_of_birth
            profile_image_url 
            email 
            phone_number
            last_known_locations {
            id
            is_active
             address
          }
            crop_users {
                id
                master_crop {
                    id
                    name
                }
            }
            segment_users {
                id
                master_segment {
                    id
                    name
                }
            }
        }
    }
`;
const UesrProfile = gql`
mutation userUpdates($userId: String!, $dateOfBirth: String,$name:String, $email: String, $gender: String, $phoneNumber: String, $imageUrl: String) {
    userUpdatesHisProfile(command: {dateOfBirth: $dateOfBirth,name:$name, email: $email, gender: $gender, phoneNumber: $phoneNumber, profileImageUrl: $imageUrl, userId: $userId}) {
    data {
      status
    }
    error
  }
}
`
const getMasters = gql`
    query getMasters($id: bpchar!) {
        master_crops {
            id
            name
        }
        master_segments {
            id
            name
        }

        users_by_pk(id: $id) {
            id
            name
            phone_number
            email
            crop_users {
                id
                master_crop {
                    id
                    name
                }
            }
            segment_users {
                id
                master_segment {
                    id
                    name
                }
            }
            user_feedbacks_aggregate {
                aggregate {
                    avg {
                        rating
                    }
                }
            }
            user_feedbacks {
                id
                feedback
                rating
                created_at
                service_request {
                    id
                    farmer: user {
                        id
                        name
                        phone_number
                        user_feedbacks_aggregate {
                            aggregate {
                                avg {
                                    rating
                                }
                            }
                        }
                    }
                }
            }
            related_accounts: account_users {
                id
                account {
                    id
                    name
                }
            }
        }
    }
`;
const Segments = gql`
    mutation setUserSegments($userId: String!, $segments: [String!]!) {
        fieldPartnerUpdatesHisSegments(
            command: {id: $userId, segments: $segments}
        ) {
            data {
                status
            }
            error
        }
    }
`;
const Crops = gql`
    mutation setUserCrops($userId: String!, $crops: [String!]!) {
        fieldPartnerUpdatesHisCrops(command: {id: $userId, crops: $crops}) {
            data {
                status
            }
            error
        }
    }

`;

const createRequestGql = gql`
    mutation createRequest($requestId: String!, $cropName: String!,$isRecurring:Boolean!, $lat: Float!, $lng: Float!, $dateOfSowing: String!, $farmerUserId: String!, $assetUrl: String, $createdDate: String!) {
        farmerCreatesServiceRequest(command: {
            requestId: $requestId,
            crop: $cropName ,
            lat: $lat,
            lng: $lng,
            dateOfSowing: $dateOfSowing,
            isRecurring:$isRecurring,
            external_id: "",
            external_source: "",
            farmerUserId: $farmerUserId,
            created_date: $createdDate,
            assets: [{
                url: $assetUrl
            }]
        }) {
            data{
                status
            }
            error
        }
    }
mutation farmerClosesResponse($requestId: String!, $fprId: String!) {
  farmerClosesResponse(command: {id: $requestId, fprId: $fprId}) {
    data {
      status
    }
    error
  }
}

mutation farmerCancelsResponse($requestId: String!, $farmerUserId: String!, $reason:String!, $cancelRecurring: Boolean!) {
  farmerCancelsServiceRequest(command: {requestId: $requestId, farmerUserId: $farmerUserId, reason: $reason, cancelRecurring: $cancelRecurring}) {
    data {
      status
    }
    error
  }
}
`;

const relatedReels = gql`
query reelsWithRelated($reelid:Int,$lat:float8,$lng:float8) {
  get_reel_with_related(args:{reelid:$reelid,lat:$lat,lng:$lng,max_distance:10000000} ) {
    id
    reels_user {
      id
      name
      profile_image_url
    }
    cloudflare_playback_url
    cloudflare_thumbnail_url
    title
    description
    reels_taggables {
      id: tag_id
      taggable_type
      taggables_tag {
        id
        name
      }
    }
  }
}
`;

const reelQueries = gql`
query reels($lat:float8,$lng:float8) {
  get_reels(args:{lat:$lat,lng:$lng,max_distance:10000000}, where:{visibility:{_eq:"external"}}, ) {
    id
    reels_user {
      id
      name
      profile_image_url
    }
    cloudflare_playback_url
    cloudflare_thumbnail_url
    title
    description
    reels_taggables {
      id: tag_id
      taggable_type
      taggables_tag {
        id
        name
      }
    }
  }
}
mutation userViewedReel($reelId: Int!, $userId: String) {
  userViewedReel(command: {reelId: $reelId, userId: $userId}) {
    data {
      status
    }
    error
  }
}
subscription reelReactions($reelId:bigint!) {
    reels(where: {id: {_eq: $reelId}}) {
      id
      reel_comments {
        id
        user_id
        user {
          id
          name
          profile_image_url
        }
        comment
        created_at
      }
      thumbnail_url
      cloudflare_id
        cloudflare_playback_url
        cloudflare_thumbnail_url
        title
        description
      likes
      shares
      views
      reactions_count
      reactions
    }
}


`;
const GET_REELS_GQL = gql`
query reelsFarmer($lat: float8, $lng: float8) {
    get_reels(
      args: {lat: $lat, lng: $lng, max_distance: 10000000}
      where: {visibility: {_eq: "external"}}
    ) {
      id
      reels_user {
        id
        name
        profile_image_url
      }
      cloudflare_playback_url
      cloudflare_thumbnail_url
      thumbnail_url
      title
      description
      reels_taggables {
        id: tag_id
        taggable_type
        taggables_tag {
          id
          name
        }
      }
    }
  }

  subscription reelReactionsFarmer($reelId: bigint!, $currentUserId: bpchar!) {
    reels(where: {id: {_eq: $reelId}}) {
      id
      reel_comments(order_by: {created_at: asc}) {
        id
        user_id
        user {
          id
          name
          profile_image_url
        }
        comment
        reports_aggregate {
          aggregate {
            count
          }
        }
        created_at
      }
      likes
      shares
      views
      reels_reel_interactions(where: {interaction_type: {_eq: "react"}}) {
        id
        interaction_type
        interaction_value
        reel_interactions_user {
          id
          name
          profile_image_url
        }
      }
      reels_user {
        id
        name
        profile_image_url
        account_users{
          id
          account{
            id
            name
          
          }
        }
        follower_count
        users_follows_to_aggregate(
          where: {from_user_id: {_eq: $currentUserId}}
        ) {
          aggregate {
            count
          }
        }
      }
      reactions_count
      reactions
      allow_comments
      allow_likes
      allow_reactions
      allow_shares
      allow_reports
    }
  }
  mutation createReelComment($userId: String!, $reelId: String!, $comment: String!) {
    createReelComment(command: {comment: $comment, userId: $userId, reelId: $reelId}) {
      data {
        status
      }
      error
    }
  }
  
  mutation userLikedReel($reelId: Int!, $userId: String!) {
    userLikedReel(command: {reelId: $reelId, userId: $userId}) {
      data {
        status
      }
      error
    }
  }
  
  mutation usersViewedReel($reelId: Int!, $userId: String!) {
    userViewedReel(command: {reelId: $reelId, userId: $userId}) {
      data {
        status
      }
      error
    }
  }
  
  mutation userSharedReel($reelId: Int!, $userId: String!) {
    userSharedReel(command: {reelId: $reelId, userId: $userId}) {
      data {
        status
      }
      error
    }
  }
  
  mutation userReactedReel($userId: String!, $reelId: Int!, $reaction: String!) {
    userReactedReel(command: {userId: $userId, reelId: $reelId, reaction: $reaction}) {
      data {
        status
      }
      error
    }
  }
  
 
  
  mutation userFollowsProfile($loggedInUserId: String!, $followUserId: String!) {
    userFollowsProfileCommand(command: {userId: $loggedInUserId, followUserId: $followUserId}) {
      data {
        status
      }
      error
    }
  }
  
`
