import * as React                                                   from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, ModalButton, } from 'baseui/modal';
import { useNavigate }                                              from 'react-router-dom';
import { useTranslation }                                           from 'react-i18next';
import { useAppStore }                                              from '../store';

export default ({ isOpen, setIsOpen, setIsSuccess, message, }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, setUser } = useAppStore();

    function close() {
        setIsOpen(false);
        setUser(null);
        navigate('/');
        setIsSuccess(true)
    }

    function cancel() {
        setIsOpen(false);
    }

    return (
        <React.Fragment>


            <Modal onClose={cancel} isOpen={isOpen}>
                <ModalHeader>{t('app:alert-text','Alert')}</ModalHeader>
                <ModalBody $as="p">
                    {message}
                </ModalBody>
                <ModalFooter>
                    <ModalButton onClick={cancel}>{t('app:cancel-btn-text','Cancel')}</ModalButton>
                    <ModalButton onClick={close}>{t('app:ok-btn-text','Ok')}</ModalButton>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export const SuccessMessage = ({ isSuccess, setIsSuccess, message, isRecurring = false }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, setUser } = useAppStore();
    console.log({ 'succes Log out called': open })

    function close() {
        setIsSuccess(false)
    }

    function cancel() {
        setIsSuccess(false)
    }

    return (
        <React.Fragment>


            <Modal onClose={cancel} isOpen={isSuccess}>
                {/* <ModalHeader>Alert</ModalHeader> */}
                <ModalBody $as="p">
                    {message}
                </ModalBody>
                <ModalFooter>
                    {/* <ModalButton onClick={cancel}>Cancel</ModalButton> */}

                    {!isRecurring ? <ModalButton onClick={close}>{t('app:ok-btn-text','Ok')}</ModalButton> :
                        <ModalButton onClick={() => window.open('https://play.google.com/store/apps/details?id=com.farminsta.app')}>{t('app:request-action-btn-text', "Download Farminsta")}</ModalButton>
                    } </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

export const FailedMessage = ({ isfaild, setIsfailed, message }) => {
    const navigate = useNavigate();
    const { t } = useTranslation();
    const { user, setUser } = useAppStore();

    // console.log({'succes Log out called':open})
    function close() {
        setIsfailed(false)

    }

    function cancel() {
        setIsfailed(false)
    }

    return (
        <React.Fragment>


            <Modal onClose={cancel} isOpen={isfaild}>
                {/* <ModalHeader>Alert</ModalHeader> */}
                <ModalBody $as="p">
                    {message}
                </ModalBody>
                <ModalFooter>
                    {/* <ModalButton onClick={cancel}>Cancel</ModalButton>
                */}
         <ModalButton onClick={close}>{t('app:ok-btn-text','ok')}</ModalButton> 
       </ModalFooter> 
      </Modal>
    </React.Fragment>
  );
};
