import * as React from "react";
import { useReelReactionsSubscription, useReelsFarmerQuery, useReelsWithRelatedQuery } from "../generated/graphql";
import { useLoaderData } from "react-router-dom";
import { useUserViewedReelMutation } from "../generated/graphql";
import { useEffect, useRef, useState } from "react";
import { useAppStore } from "../store";
import {
    Card,
    StyledBody,
    StyledAction
} from "baseui/card";
import { Button } from "baseui/button";
import VID1 from '../assets/vid1.mp4'
import Video, { MemoizedVideo } from "../components/Video";
import { useLocation } from "../services";
import RenderIfVisible from 'react-render-if-visible'
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import memoize from 'memoize-one';
import { Overflow } from "baseui/icon";
import { ConfirmToNavigation } from "../components/ConfirmwebOrappstore";
import {BrowserView, MobileView,isMobile} from 'react-device-detect';

const ESTIMATED_ITEM_HEIGHT = 200


const windowHeight = window.innerHeight

const createItemData = memoize((reels, currentIndex, height) => ({
    reels,
    currentIndex,
    height,
}));

export function Reels() {
    const { reelId } = useLoaderData() as any;
    const [confirmNavigation, setConfirmNavigation] = useState(true);

    const { data } = useReelReactionsSubscription({
        variables: {
            reelId
        }
    });
    const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } = useLocation();

    const { data: reelsExternal, error: reelsExternalError } = useReelsFarmerQuery({
        variables: {
            lat: coords?.latitude || 18.2,
            lng: coords?.longitude || 79.1,
            // limit:50

        },
        fetchPolicy: 'cache-and-network',
        skip: reelId
        // pollInterval: 2000
    });

    const { data: reelsInternal, error: reelsInternalError } = useReelsWithRelatedQuery({
        variables: {
            reelid: +reelId,
            lat: coords?.latitude || 18.2,
            lng: coords?.longitude || 79.1,
            // limit:50

        },
        fetchPolicy: 'cache-and-network',
        skip: !reelId
        // pollInterval: 2000
    });

    const reels = reelId ? (reelsInternal?.get_reel_with_related || []) : (reelsExternal?.get_reels || []);

    const [userViewedReel] = useUserViewedReelMutation();
   
    const reel = data && data.reels && data.reels[0] ? data.reels[0] : null;
    const [currentIndex, setCurrentIndex] = useState(null);
    const cloudflareId = reel?.cloudflare_id;
   
    const iframeUrl = `https://customer-ttiygcr3wriw1114.cloudflarestream.com/${cloudflareId}/iframe?preload=true&loop=true&autoplay=true&poster=https%3A%2F%2Fcustomer-ttiygcr3wriw1114.cloudflarestream.com%2F${cloudflareId}%2Fthumbnails%2Fthumbnail.jpg%3Ftime%3D%26height%3D600`;
    const { user } = useAppStore();
    useEffect(()=>{
        // console.log({isMobile})
        if(!isMobile  ){
            setCurrentIndex(0)
        }

    },[])
const handleContueBroweser=()=>{
    setCurrentIndex(0)
    setConfirmNavigation(false)  
   
}
    const reelsForDisplay = React.useMemo(() => {
        let _reelsForDisplay = [];
        if (reelId && reels?.length) {
            const currentReel = reels?.filter(r => r.id == reelId)[0];
            const uniqueReels = reels?.filter(r => r.id != reelId);
            _reelsForDisplay = [currentReel, ...uniqueReels,{}]
            // console.log({reelId,currentReel})
            // console.log({"uniquejfb":uniqueReels.length,_reelsForDisplay:_reelsForDisplay.length,reels:reels.length})
        } else {
            _reelsForDisplay = reels;
        }
        // _reelsForDisplay = _reelsForDisplay?.slice(0, 100);
        // console.log({ _reelsForDisplay });
        return _reelsForDisplay;
    }, [reels, reelId]);

    const listRef = useRef();
    const listInnerRef = useRef();

    const __scrollProps = useRef({
        __scrollDirection: "",
        __scrollOffset: 0,
        __visibleStartIndex: 0,
        __visibleStopIndex: 0,
        __height: 0,
    });
    const listData = createItemData(reelsForDisplay, currentIndex, __scrollProps?.current?.__height)

    // useEffect(() => {

    //     // if (listInnerRef.current) {
    //     //     listInnerRef.current.styles
    //     // }

    //     function onScrollEnd(event) {
    //         // console.log({"on": "onScrollEnd", event,
    //         //     __scrollDirection: __scrollProps?.current?.__scrollDirection,
    //         //     __scrollOffset: __scrollProps?.current?.__scrollOffset,
    //         //     __visibleStopIndex: __scrollProps?.current?.__visibleStopIndex,
    //         //     __visibleStartIndex: __scrollProps?.current?.__visibleStartIndex
    //         // });
    //         if (__scrollProps?.current?.__scrollOffset % __scrollProps?.current?.__height > (__scrollProps?.current?.__height / 2)) {
    //             // listRef?.current?.scrollToItem(__scrollProps?.current?.__visibleStopIndex, 'center');
    //             // setCurrentIndex(__scrollProps && __scrollProps.current ? __scrollProps.current.__visibleStopIndex : undefined);
    //         } else {
    //             // listRef?.current?.scrollToItem(__scrollProps?.current?.__visibleStartIndex, 'center');
    //             // setCurrentIndex(__scrollProps?.current?.__visibleStartIndex);
    //         }
    //     }

    //     // console.log({listInnerRef});
    //     // listInnerRef?.current?.addEventListener("scrollend", onScrollEnd);
    //     //
    //     // return () => {
    //     //     // console.log("removing scrollend");
    //     //     listInnerRef?.current?.removeEventListener('scrollend', onScrollEnd);
    //     // }
    // }, [listInnerRef?.current, __scrollProps?.current]);

    return (<>
        <div
            className="reels-container"
        >
            <AutoSizer>
                {({ height: fullHeight, width }) => {
                    const height =fullHeight - 60;
                    __scrollProps.current.__height = height;

                    return (<FixedSizeList
                        outerRef={listInnerRef}
                        style={{
                            'scrollSnapType': 'y mandatory',
                            // height: fullHeight,
                        }}
                        ref={listRef}
                        itemData={listData}
                        itemSize={height}
                        itemCount={reelsForDisplay?.length || 0}
                        onItemsRendered={({ visibleStartIndex, visibleStopIndex }) => {
                            __scrollProps.current.__visibleStartIndex = visibleStartIndex;
                            __scrollProps.current.__visibleStopIndex = visibleStopIndex;

                            // console.log({visibleStartIndex, visibleStopIndex});
                        }}
                        onScroll={({ scrollDirection, scrollOffset }) => {
                            // console.log({scrollDirection, scrollOffset});
                            __scrollProps.current.__scrollDirection = scrollDirection;
                            __scrollProps.current.__scrollOffset = scrollOffset;
                            console.log({ scrollOffset, height });
                            setCurrentIndex(Math.floor((scrollOffset + 100) / height));
                        }}
                        height={height}
                        width={width}
                    >
                        {MemoizedVideo}
                    </FixedSizeList>)
                }}
            </AutoSizer>
            <MobileView>
            <ConfirmToNavigation isOpen={confirmNavigation} onClose={setConfirmNavigation}  handleContueBroweser={handleContueBroweser} />
            </MobileView>
        </div>
    </>)
}
