import { Block }                                                           from "baseui/block";
import { useState }                                                        from "react";
import { PhoneInput, COUNTRIES }                                           from "baseui/phone-input";
import { FormControl }                                                     from "baseui/form-control";
import { HeadingMedium, ParagraphSmall }                                   from "baseui/typography";
import { Button }                                                          from "baseui/button";
import { useStyletron }                                                    from "baseui";
import { RequestOtpMutation, useRequestOtpMutation, useVerifyOtpMutation } from "../generated/graphql";
import { useAppStore }                                                     from "../store";
import { useApolloSetup }                                                  from "../api";
import { PinCode }                                                         from "baseui/pin-code";
import { useNavigate, useSearchParams }                                    from "react-router-dom";
import { useTranslation }                                                  from "react-i18next";

import loginmapfprl                      from '../assets/loginmapfprl.png'
import { FailedMessage, SuccessMessage } from "../components/StyledModal";

export function LoginPage({fromReelsPopup=false}) {
    const [css, theme] = useStyletron();
    const [requestOtp] = useRequestOtpMutation();
    const [isSuccess, setIsSuccess] = useState<boolean>(false);
    const [isfaild, setIsfailed] = useState(false)
    const { t } = useTranslation();
    const [verifyOtp] = useVerifyOtpMutation();
    const { user, setUser } = useAppStore();
    const [country, setCountry] = useState(COUNTRIES.IN);
    const [text, setText] = useState("");
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [requestOtpData, setRequestOtpData] = useState<RequestOtpMutation>(null);
    const [errors, setErrors] = useState({ phone: null, otp: null });
    const { setToken } = useApolloSetup();
    const [isButtonLoading, setButtonLoading] = useState(false);
    const navigate = useNavigate();
    let [searchParams, setSearchParams] = useSearchParams();
    console.log({ searchParams: new Set(searchParams.entries()) });
    const onPhoneSubmit = () => {
        if(text){
        setButtonLoading(true);
        requestOtp({
            variables: {
                phoneNumber: text,
                countryCode: country.dialCode,
                hashKey: 'WEB',
            }
        }).then(resOtp => {
            if (resOtp?.errors?.length > 0) {
                setErrors({ otp: null, phone: resOtp.errors[0].message });
            } else {
                setRequestOtpData(resOtp.data);
            }
        }).finally(() => {
            setButtonLoading(false);
        })
    }
    else{
      alert(t('app:login-check-number-text',"Please check your mobile number"))  
    }
    }

    const onOTPSubmit = () => {
        setButtonLoading(true);
        verifyOtp({
            variables: {
                phone_number: requestOtpData.requestOtpCommand.phoneNumber,
                encrypted: requestOtpData.requestOtpCommand.encrypted,
                nonce: requestOtpData.requestOtpCommand.nonce,
                roleName: 'fi-farmer',
                otp: otp.join(''),
            }
        }).then(resVerify => {
            if (resVerify?.data?.verifyOtpCommand?.is_verified) {
                const user = resVerify.data.verifyOtpCommand.user;
                const token = resVerify.data.verifyOtpCommand.token;
                setUser(user);
                setToken(token);
                setIsSuccess(true)
                if (searchParams.has('create_request')) {
                    navigate('/?create_request=true');
                } else {
                    navigate('/');
                }
            } else {
                setIsfailed(true)
                setErrors({ phone: null, otp: t('errors:login-invalid-otp', 'Invalid OTP') })
            }
        }).finally(() => {
            setButtonLoading(false);
        })
    };

    return (
        <Block flexDirection={'row'} justifyContent={"center"} alignItems={"center"} display={"flex"} width={'100vw'} height={'100vh'} paddingTop={'20px'}>
          {!fromReelsPopup &&  <Block className={css({
                [theme.mediaQuery.small]: {
                    width: '100%',
                    height: '100%',
                },
                [theme.mediaQuery.medium]: {
                    height: "75vh",
                    marginRight: '20px',
                    paddingTop: '7px',
                    width: '60vw',
                    marginLeft: '10px',
                }
            })}>
                <img className={css({ objectFit: 'cover', width: '100%', height: '100%', borderRadius: '10px', })}
                     src={loginmapfprl}/>
            </Block>}
            <Block padding={theme.sizing.scale600} className={css({
                padding: theme.sizing.scale600,
                [theme.mediaQuery.small]: {
                    position: "absolute",
                    backgroundColor: '#fff',
                    bottom: '-30px',
                    width: '90%',
                    height: '50%',
                    borderWidth: '1px',
                    borderColor: 'rgb(238, 238, 238)',
                    borderStyle: 'solid',
                    borderRadius: '10px',
                },
                [theme.mediaQuery.medium]: {
                    position: 'relative',
                    borderWidth: '1px',
                    bottom: 0,
                    borderColor: 'rgb(238, 238, 238)',
                    borderStyle: 'solid',
                    borderRadius: '10px',
                    flex: 1,
                    height: "70vh",
                    marginRight: '20px',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',

    // left: "50%",
    // backgroundColor:'transparent',
    // transform: "translateY( -50%)",
                            }
                      })}>
                <HeadingMedium>{!requestOtpData ?  t('app:login-text','Login'):t('app:verification-text',"Verification")}</HeadingMedium>
                {!requestOtpData ?
                    <FormControl label={t('app:login-phone-label', "Phone number")}
                                 error={errors.phone}
                                 caption={t('app:login-phone-caption', "An OTP will be sent to this phone number")}>
                        <PhoneInput
                            country={country}
                            // @ts-ignore
                            name="phone"
                            // @ts-ignore
                            onCountryChange={({ option }) => setCountry(option)}
                            text={text}
                            onTextChange={e => setText(e.currentTarget.value)}
                        />
                    </FormControl>
                    : <FormControl
                        caption={t('app:login-otp-caption', "Enter the OTP your received.")}
                        error={errors.otp}>
                        <>
                            <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                <ParagraphSmall>{t('app:login-otp-label', "OTP")}</ParagraphSmall>
                                <button
                                    // @ts-ignore
                                    onClick={() => {
                                        setRequestOtpData(null)
                                    }} style={{
                                    color: 'green',
                                    textDecoration: 'none',
                                    backgroundColor: 'transparent',
                                    border: 'none',
                                    cursor: 'pointer',
                                    fontSize: 16,
                                    fontWeight: 'bold'
                                }}>{t('app:login-Change-number-label', "Change Number")}</button>
                            </div>

                            <PinCode
                                values={otp}
                                onChange={({ values }) => setOtp(values)}
                            />
                        </>
                    </FormControl>
                }
                <FormControl>
                    {
                        !requestOtpData ?
                            <Button isLoading={isButtonLoading} onClick={onPhoneSubmit}>{t('app:login-send-otp-btn', 'Send OTP')}</Button> :
                            <Button isLoading={isButtonLoading} onClick={onOTPSubmit}>{t('app:login-verify-otp-btn', 'Verify OTP')}</Button>
                    }
                </FormControl>
            </Block>
            <SuccessMessage isSuccess={isSuccess} setIsSuccess={setIsSuccess} message={'Welcome back to farminsta'} />
            <FailedMessage isfaild={isfaild} setIsfailed={setIsfailed} message={t('app:otp-failed-modal-text','OTP verification failed. Please try again')} />
        </Block>
    )
}
