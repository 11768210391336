import * as React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import { useStyletron } from "baseui";
import { IoSend } from "react-icons/io5";
import moment from "moment";
import { formatNumber } from "./function";
import { Drawer, SIZE as DRAWER_SIZE, ANCHOR } from "baseui/drawer";

export const More = ({ isOpen, onClose, reel, reelUser }) => {
    const [css, theme] = useStyletron();
    const { id, title, reels_taggables, description, my_likes, } = reel;
    const [link] = description?.match(/(https?:\/\/[^\s/$.?#]+[^\s]*)/g) || [''];
    const description1 = description?.replace(link, "") || '';
    // console.log({"currentUser":reelUser})
    return (<Drawer
        isOpen={isOpen}
        autoFocus
        onClose={() => onClose(false)}
        size={DRAWER_SIZE.auto}
        anchor={ANCHOR.bottom}
        overrides={{
            DrawerContainer: {
                style: {
                    width: "320px", // Default width for larger screens
                    borderRadius: "10px",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    bottom: "0",
                    "@media screen and (max-width: 768px)": {
                        width: "100%", // Change width for screens <= 768px
                    },
                },
            },
        }}
    >
        <div className="cmt-ftr" style={{marginBottom:'10px'}}>
            <div style={{ marginBottom: "10px" }}>
                <div className="CommentContainer" >
                    <img className={css({ objectFit: 'cover', width: '40px', height: '40px', borderRadius: '100px', marginRight: '10px' })}
                        src={"https://via.placeholder.com/60x60"} />
                    <div className="nTC">
                        <div className="dscrp-user">
                            <span className="username">{reelUser?.account_users[0]?.account?.name || reelUser?.name}</span>
                            <span className="admin_text">{formatNumber(reelUser?.follower_count)} Followers</span>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <div className="more_sub_cnt">
            {title && (<span className="username" style={{ display: "block" }}>{title}
            </span>
            )}
            {!description1 && (<span
                style={{
                    color: '#043B53',
                    fontFamily: 'FONTS_OVERPASS_TEXT_REGULAR',
                    fontSize: '14px',
                    // marginLeft: '5px',
                }}
            >{description1}</span>
            )}
            {reels_taggables && (
                <div style={{ display: 'flex', flexDirection: 'row',flexWrap:'wrap' }}>
                    {reels_taggables?.map((tag) => (
                        <span
                            key={tag?.taggables_tag?.id}
                            style={{
                                fontFamily: 'FONTS_OVERPASS_TEXT_REGULAR',
                                fontSize: '12px',
                            }}
                        > {tag?.taggables_tag?.name?.en.startsWith("#") ?
                        tag?.taggables_tag?.name?.en :
                       
                        `#${tag?.taggables_tag?.name?.en}`
                    }</span>
                    ))}
                </div>
            )}
            {link && (
                <span
                    style={{
                        color: 'green',
                        fontFamily: 'FONTS_OVERPASS_TEXT_BOLD',
                        fontSize: '14px',
                        overflow:'hidden',
                        width:'70px !important',
                        flexWrap:'wrap'

                    }}
                    onClick={() => {
                        window.open(`${link}`, '_blank');
                    }}
                >{link}
                </span>
            )}
        </div>
    </Drawer>
    );
}