import * as React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import { useStyletron } from "baseui";
import { IoSend } from "react-icons/io5";
import moment from "moment";
import { Drawer, SIZE as DRAWER_SIZE, ANCHOR } from "baseui/drawer";
import farminstalogo from '../assets/farmerprofile.png'
// const ITEMS = Array.from({length: 40}, () => ({
//     title: 'David Smith',
//     comment: 'Senior Engineering Managerdffkdsg rdfdf',
//     time: '14 H',
//     imgUrl: 'https://via.placeholder.com/60x60',
// }));
const formatDateTime = (dateStr) => {
    const dt = moment.utc(dateStr).local().fromNow()
    return dt;

}
export const CommentModal = ({ isOpen, onClose, reelsComments, type, setComment, handleComment, comment }) => {
    const [css, theme] = useStyletron();

    return (<Drawer
        isOpen={isOpen}
        autoFocus
        onClose={() => onClose(false)}
        size={SIZE.auto}
        anchor={ANCHOR.bottom}
        overrides={{
            DrawerContainer: {
                style: {
                    width: "320px", // Default width for larger screens
                    borderRadius: "10px",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    bottom: "0",
                    "@media screen and (max-width: 768px)": {
                        width: "100%", // Change width for screens <= 768px
                    },
                },
            },
        }}
    >
        <div style={{ fontSize: "18px", fontWeight: 'bold', marginBottom: '10px', }}>
            {type}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, overflow: 'scroll', marginBottom: "10px" }}>

            {
                reelsComments?.map((cmt) => {
                    // console.log({"fdfg":cmt.user.name})
                    return (<div key={`${cmt.id}`} style={{ marginBottom: "10px" }}>
                        <div className="CommentContainer">
                            <img className={css({
                                objectFit: 'cover',
                                width: '25px',
                                height: '25px',
                                borderRadius: '100px',
                                marginRight: '10px'
                            })}
                                src={cmt.user.profile_image_url ? cmt.user.profile_image_url:farminstalogo} />
                            <div className="nTC">
                                <div>
                                    <span className="username"  style={{color:'black !important' }}>{cmt.user.name}</span><span
                                        style={{ marginLeft: '5px', fontSize: "10px" }}>{formatDateTime(cmt.created_at)}</span>
                                </div>

                                <span className="acion-text">{cmt.comment}</span>

                            </div>
                        </div>


                    </div>)
                })
            }
            {reelsComments?.length === 0 && <span style={{ fontSize: "18px", fontWeight: 'bold', marginBottom: '10px', color: "grey", textAlign: 'center', margin: '30px', }}>
                No Comments Yet
            </span>}
        </div>
        <div className="comment-div_" style={{ background: '#E2E0E1', width: '100%', height: '35px', }}>
            <input placeholder="Comment..." className="comment" style={{ background: '#E2E0E1' }}
                onChange={(e) => setComment(e.target.value)} value={comment} />
            <IoSend onClick={handleComment} color="green" size={20} />
        </div>
    </Drawer>);
}