import * as React from "react";
import {
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    ModalButton,
    SIZE,
    ROLE
} from "baseui/modal";

import { useStyletron } from "baseui";
import { Input, MaskedInput } from "baseui/input";
import { Button, SIZE as BUTTON_SIZE } from "baseui/button";
import { Alert, ArrowRight } from "baseui/icon";
import { COUNTRIES, PhoneInput, SIZE as PHONE_SIZE } from "baseui/phone-input";
import { PinCode } from "baseui/pin-code";
import { useRequestOtpMutation, useUserDetailQuery, useUserUpdatesMutation, useVerifyOtpMutation } from "../generated/graphql";
import { useTranslation } from "react-i18next";
import { useAppStore } from "../store";
import { useApolloSetup } from "../api";
import { useSearchParams } from "react-router-dom";
import { HeadingMedium, HeadingSmall, ParagraphSmall } from "baseui/typography";
import { FormControl } from "baseui/form-control";
import FarminstaTitleImage from '../assets/farminsta-title.png';
import { Drawer, ANCHOR, SIZE as Drawer_Size } from "baseui/drawer";
import {isMobile,isAndroid} from 'react-device-detect';

export const UnAuthUser = ({ isOpen, onClose, reelsComments, type, user }) => {
    const [css, theme] = useStyletron();
    const [mobile, setMobile] = React.useState()
    const [country, setCountry] = React.useState(COUNTRIES.IN);
    const [isSuccess, setIsSuccess] = React.useState<boolean>(false);
    const [isfaild, setIsfailed] = React.useState(false)
    const { t } = useTranslation();
    const [isButtonLoading, setButtonLoading] = React.useState(false);
    let [searchParams, setSearchParams] = useSearchParams();
    const [requestOtpData, setRequestOtpData] = React.useState(null);
    const [userName, setUserName] = React.useState(null);
    const [errors, setErrors] = React.useState({ phone: null, otp: null });

    const [requestOtp] = useRequestOtpMutation();
    const { setUser } = useAppStore();
    const { setToken } = useApolloSetup();
    const [otp, setOtp] = React.useState()
    const [verifyOtp] = useVerifyOtpMutation();
    const [userUpdates] = useUserUpdatesMutation()
    const { data: userDetail, error, observable: userDetailObservable } = useUserDetailQuery({
        variables: {
            userId: user?.id,
        },

    })
    const onPhoneSubmit = () => {
        if (mobile && userName) {
            setButtonLoading(true);
            // console.log({ phoneNumber: mobile,
            //     countryCode: country.dialCode,
            //     hashKey: 'WEB',}) 
            requestOtp({
                variables: {
                    phoneNumber: mobile,
                    countryCode: country.dialCode,
                    hashKey: 'WEB',
                }
            }).then(resOtp => {
                if (resOtp?.errors?.length > 0) {
                    setErrors({ otp: null, phone: resOtp.errors[0].message });
                } else {
                    setRequestOtpData(resOtp.data);
                }
            }).catch((error) => {
                console.log({ 'sentmnumberError': error })
            })
                .finally(() => {
                    setButtonLoading(false);
                })
        }
        else {
            if (!userName && !mobile ){
                alert(t('app:login-name-number-alert', "Please provide a name and number to proceed"))
            }
            else if (!userName ){
                alert(t('app:login-name-number-alert', "Please provide a name , it cannot be left empty."))
            }
            else{
                alert(t('app:login-check-number-text', "Please check your mobile number"))
            }
           
        }
    }
    const handleName = (_user) => {
        try {
            const resp = userUpdates({
                variables: {
                    userId: _user?.id,
                    name: userName, // || userDetail?.users_by_pk?.name,
                    // dateOfBirth: userDetail?.users_by_pk?.date_of_birth,
                    // email: userDetail?.users_by_pk.email,
                    // gender: userDetail?.users_by_pk.gender,
                    // phoneNumber: userDetail?.users_by_pk.phone_number,
                    // imageUrl: userDetail?.users_by_pk.profile_image_url,
                },
            })
            if (resp?.data?.userUpdatesHisProfile) {
                onClose(false)
                alert("Successfully Logged in")
            }
            // console.log({ resp:resp?.data?.userUpdatesHisProfile })
            //   userDetailObservable.refetch();
            onClose(false)
        } catch (e) {
            console.log({ onUserUpdateException: e })

        }

    };

    const onOTPSubmit = () => {
        setButtonLoading(true);
        verifyOtp({
            variables: {
                phone_number: requestOtpData.requestOtpCommand.phoneNumber,
                encrypted: requestOtpData.requestOtpCommand.encrypted,
                nonce: requestOtpData.requestOtpCommand.nonce,
                roleName: 'fi-farmer',
                otp: otp.join(''),
            }
        }).then(resVerify => {
            console.log({ resVerify })
            if (resVerify?.data?.verifyOtpCommand?.is_verified) {
                const user = resVerify.data.verifyOtpCommand.user;
                const token = resVerify.data.verifyOtpCommand.token;
                setUser(user);
                setToken(token);
                setIsSuccess(true)
                // setTimeout( () => {
                handleName(user);
                // }, 3000)
            } else {
                setIsfailed(true)
                setErrors({ phone: null, otp: t('errors:login-invalid-otp', 'Invalid OTP') })
            }
        }).finally(() => {
            setButtonLoading(false);
        })
    };


    return (<Drawer
        isOpen={isOpen}
        autoFocus
        onClose={() => onClose(false)}
        size={Drawer_Size.auto}
        anchor={ANCHOR.bottom}
        overrides={{
            DrawerContainer: {
                style: {
                    width: "320px", // Default width for larger screens
                    borderRadius: "10px",
                    position: "absolute",
                    left: "50%",
                    transform: "translateX(-50%)",
                    bottom: "0px",
                    overFlow:'auto',
                    paddingBottom:(window.innerHeight !== window.outerHeight ) && isMobile ?"250px":'0px',
                    "@media screen and (max-width: 768px)": {
                        width: "100%", // Change width for screens <= 768px
                    },
                },
            },
        }}
    >

        <div className="auth-scr" >
            <FormControl label={'Full Name *'} >
                <Input disabled={requestOtpData} size={"compact"} placeholder="Enter Full Name" value={userName} autoFocus={true} onChange={(e) => setUserName(e.target.value)} />
            </FormControl>
            {!requestOtpData ? <FormControl label={t('app:login-phone-label', "Phone number")}
                error={errors.phone}
            // caption={t('app:login-phone-caption', "An OTP will be sent to this phone number")}
            >
                <p>
                    <PhoneInput
                        overrides={{
                            CountrySelectContainer: {
                                style: { display: 'none' }
                            }
                        }}
                        country={country}
                        // @ts-ignore
                        name="phone"
                        // @ts-ignore
                        size={"compact"}
                        onCountryChange={({ option }) => setCountry(option)}
                        text={mobile}
                        onTextChange={e => setMobile(e.currentTarget.value)}
                    />
                </p>
            </FormControl> : <FormControl
                caption={t('app:login-otp-caption', "Enter the OTP your received.")}
                error={errors.otp}>
                <>
                    <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                        <ParagraphSmall>{t('app:login-otp-label', "OTP")}</ParagraphSmall>
                        <button
                            // @ts-ignore
                            onClick={() => {
                                setRequestOtpData(null)
                            }} style={{
                                color: 'green',
                                textDecoration: 'none',
                                backgroundColor: 'transparent',
                                border: 'none',
                                cursor: 'pointer',
                                fontSize: 14,
                                marginTop: "10px",
                                fontWeight: 'bold'
                            }}>{t('app:login-Change-number-label', "Change")}</button>
                    </div>

                    <PinCode
                        values={otp}
                        onChange={({ values }) => setOtp(values)}
                    />
                </>
            </FormControl>}
            {
                !requestOtpData ?
                    <Button isLoading={isButtonLoading} size={BUTTON_SIZE.compact} onClick={onPhoneSubmit}>{t('app:login-Next-otp-btn', 'Next')}</Button> :
                    <Button isLoading={isButtonLoading} onClick={onOTPSubmit}>{t('app:login-Next-otp-btn', 'Confirm')}</Button>
            }

        </div>


    </Drawer>)
}