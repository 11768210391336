import React, { useRef, useState, useEffect, useMemo } from "react";
import { RiShareForwardFill } from "react-icons/ri";
import { BsEyeFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import { TfiMoreAlt } from "react-icons/tfi";
import { IoSend } from "react-icons/io5";
import ReactPlayer from 'react-player'
import { useNavigate } from "react-router-dom";
import { areEqual } from "react-window";
import { RWebShare } from "react-web-share";
import {
    useCreateReelCommentMutation,
    useReelReactionsFarmerSubscription,
    useUserFollowsProfileMutation,
    useUserReactedReelMutation,
    useUserSharedReelMutation,
    useUserViewedReelMutation
} from "../generated/graphql";
import { useAppStore } from "../store";
import { CommentModal } from "./CommentModal";
import { ReactionModal } from "./Reaction";
import { More } from "./ReelDescription";
import "./Video.css";
import "./downloadapp.css";
import downloadSvg from '../assets/undraw_mobile_app_re_catg.svg'
import { UnAuthUser } from "./UnAuth";
import excellent from '../assets/Smiling.png'
import good from '../assets/Neutral.png'
import bad from '../assets/Angry.png'
import { RxCross2 } from "react-icons/rx";
import farminstalogo from '../assets/FarminstLogowhite.png'
import { BiSolidLike } from "react-icons/bi";
import { BiSolidDislike } from "react-icons/bi";
import { BiSolidCommentDetail } from "react-icons/bi";



export default function Video({ data: { reels, currentIndex, height }, index, style }) {
    // const [play, setPlay] = useState(false);
    const reel = reels[index];
    // console.log({ reels, index, currentIndex });
    const play = index === currentIndex;
    const vidRef = useRef();
    const { user, setUser } = useAppStore()
    const [isOpen, setOpen] = useState(false);
    const [isMoreOpen, setIsMoreOpen] = useState(false);
    const [comment, setComment] = useState("");
    const [isReactionOpen, setReactionOpen] = useState(false)
    const [isAuthUser, setIsAuthUser] = useState(false)
    const [add, setAdd] = useState(true)
    const navigate = useNavigate();
    // const [screenHeight, setScreenHeight] = useState(window.innerHeight);

    // useEffect(() => {
    //     // console.log({ mount: reel?.id, index, currentIndex });
    //     // const handleResize = () => {
    //     //     setScreenHeight(window.innerHeight);
    //     // };
    //     // window.addEventListener('resize', handleResize);
    //     return () => {
    //         console.log({ unmount: reel.id, index, currentIndex });
    //         // window.removeEventListener('resize', handleResize);
    //     };
    // }, [reel, index, currentIndex]);

    const { data: reelsReactions, error } = useReelReactionsFarmerSubscription({
        variables: {
            reelId: reel?.id,
            currentUserId: user?.id || ""
        }
    })

    const reactions = reelsReactions?.reels[0]

    //Mutations 

    const [createReelComment] = useCreateReelCommentMutation()
    const [userFollowsProfileCommand] = useUserFollowsProfileMutation()
    const [userReactedReel] = useUserReactedReelMutation()
    const [userSharedReel] = useUserSharedReelMutation()
    const [userViewedReel] = useUserViewedReelMutation();
    // useEffect(() => {
    //     if (index === currentIndex) {
    //         // setPlay(true)
    //         console.log('playing', reel.id, currentIndex)
    //     } else {
    //         // setPlay(false)
    //         console.log('stop playing', reel.id, currentIndex)
    //     }
    //
    // }, [reel, index, currentIndex]);

    function formatNumber(num) {
        const formatter = new Intl.NumberFormat('en-US', {
            notation: 'compact',
            compactDisplay: 'short'
        });
        return formatter.format(num);
    }

    const handleshare = () => {
        if (user) {
            userSharedReel({
                variables: {
                    reelId: reel?.id,
                    userId: user?.id,
                },
            });
        } else {
            setIsAuthUser(true)
        }

    }

    const handleComment = () => {

        if (user) {
            if (comment) {
                createReelComment(
                    {
                        variables: {
                            userId: user?.id,
                            reelId: `${reel.id}`,
                            comment: comment
                        }
                    }).then((res) => {
                        console.log({ "result": res })
                    })
                    .catch((err) => {
                        console.log({ "Comment": err })
                    })
                setComment('')
            } else {
                alert('Comment should not be empty')
            }

        } else {
            setComment('')
            setIsAuthUser(true)
        }

    }
    useEffect(() => {
        if (reel?.id) {
            userViewedReel({
                variables: {
                    reelId: reel?.id,
                    userId: user?.id
                },
            })
        }
    }, [reel])

    const handleFollow = () => {
        if (user) {
            userFollowsProfileCommand({
                variables: {
                    loggedInUserId: user?.id,
                    followUserId: reel?.reels_user?.id
                }
            })

        } else {
            setIsAuthUser(true)
        }
    }


    function handleLike(reactionType) {
        if (user) {
            userReactedReel({
                variables: {
                    reelId: reel?.id,
                    userId: user?.id,
                    reaction: reactionType
                },
            });
        } else {
            setIsAuthUser(true)
        }

    }


    const reactionCount = React.useMemo(() => {

        const excellentList = reactions?.reels_reel_interactions?.filter(interaction => interaction?.interaction_value === "Excellent");
        const goodList = reactions?.reels_reel_interactions?.filter(interaction => interaction?.interaction_value === "Good");
        const badList = reactions?.reels_reel_interactions?.filter(interaction => interaction?.interaction_value === "Bad");

        return { excellent: excellentList?.length || 0, good: goodList?.length || 0, bad: badList?.length || 0 }
    }, [reactions, reel.id])
    const isUserReacted = useMemo(() => {
        const userReacted = reactions?.reels_reel_interactions?.filter((interaction) => interaction?.reel_interactions_user?.id === user?.id) || [];
        return userReacted;
    }, [reactions, reel.id])

    const downloadAppForMore = () => {
        return (<div className="mall-card">
            <img src={downloadSvg} alt="Mall illustration" style={{ height: '200px' }} />
            <div className="mall-card-header">
                <h3>For more agri informative reels download Farminsta
                </h3>
                {/* <h4>Find Your Style at MegaMall</h4> */}
            </div>
            <div className="mall-card-body">
                <button className="download-button" onClick={() => window.open('https://farmreach.sng.link/D9igs/p94i?pcn=&_smtype=3')}>Download Now </button>
            </div>
        </div>)
    }

    return (
        <div className="reel " style={style}>
            {reel.cloudflare_playback_url ? <div className="react-player-container box-shadow" style={{ height: height, backgroundColor: '#fff' }}>
                <ReactPlayer
                    // onClick={onVideoClick}
                    key={index}
                    className="video-player"
                    // height={screenHeight-100}
                    width={window.innerWidth}
                    playsinline={true}
                    ref={vidRef}
                    playing={play}
                    url={reel.cloudflare_playback_url}
                    // light={reel.thumbnail_url || reel.cloudflare_thumbnail_url}
                    loop={true}
                    controls={true}


                />

                <div className="center downloadLogo" onClick={() => window.open('https://farmreach.sng.link/D9igs/p94i?pcn=&_smtype=3')}>
                    <img src={farminstalogo} alt='Farminsta' className="logo" />
                </div>
                <div className="center  about_reel"  >
                    {/* <img src={farminstalogo} alt='excellent' className="logo" /> */}

                    <span className="reel_owner" ><FaUser size={20} color="#fff" className="text-shadow" /></span>
                    {/* <span className="acion-text">{reactions?.reel_comments?.length||0}</span>*/}

                    <span className="company_Name">{reactions?.reels_user?.account_users[0]?.account?.name || reactions?.reels_user?.name}</span>
                    <div onClick={handleFollow} style={{ cursor: 'pointer' }}>
                        <span
                            className={` follow `}
                            style={{
                                borderRadius: 2
                            }}>{reactions?.reels_user?.users_follows_to_aggregate?.aggregate?.count ? "Following" : "Follow"}</span>
                    </div>
                    <div style={{ width: '80%' }}>
                        {reel?.title && (<span className="company_Name reel_title" style={{ display: "#fff !important" }}>{reel?.title}
                        </span>
                        )}
                        {/* {add && <div className="glass-background">
                            <RxCross2 size={18} color='#fff' className="cross"  onClick={()=>setAdd(false)}/>
                            <p className="glass_text">For more agri informative reels install farminsta</p>
                            <button className="glass-cnt-inside-btn glass_text">Download</button>
                        </div>} */}
                    </div>

                </div>

                <div className="action-buttons" style={{ zIndex: 999 }}>


                    <div className="center" style={{ cursor: 'pointer',  userSelect: "none" }}>
                        <span className="action_cnt" onClick={() => handleLike('Excellent')}><BiSolidLike size={24} color={isUserReacted.length > 0 ? isUserReacted[0].interaction_value == 'Excellent' ? 'green' : '#fff' : "#fff"} className="text-shadow" /></span>
                        <span className="acion-text text-shadow">{formatNumber(reactionCount?.excellent || 0)}</span>
                    </div>
                    <div className="center">
                        <span className="action_cnt" onClick={() => handleLike('Bad')} style={{ cursor: 'pointer' }}><BiSolidDislike
                            size={24} color={isUserReacted.length > 0 ? isUserReacted[0].interaction_value == 'Bad' ? 'green' : '#fff' : "#fff"} className="text-shadow" /></span>
                        <span className="acion-text text-shadow">{formatNumber(reactionCount?.bad || 0)}</span>
                    </div>
                    {user ? <RWebShare
                        data={{
                            text: reel.description,
                            url: `https://reels.farminsta.com/reels?reelid=${reel?.id}`,
                            title: reel.title
                        }}
                        onClick={handleshare}
                    >
                        <div className="center" style={{ cursor: 'pointer' }}>
                            <span className="action_cnt"><RiShareForwardFill size={24} color="#fff" style={{ cursor: 'pointer' }}
                                className="text-shadow " /></span>
                            <span className="acion-text text-shadow"
                                onClick={handleshare}>{formatNumber(reactions?.shares || 0)}</span>
                        </div>
                    </RWebShare>
                        : <div className="center" style={{ cursor: 'pointer' }}>
                            <span className="action_cnt" onClick={handleshare}><RiShareForwardFill size={24} color="#fff" style={{ cursor: 'pointer' }}
                                className="text-shadow " /></span>
                            <span className="acion-text text-shadow"
                            >{formatNumber(reactions?.shares || 0)}</span>
                        </div>}
                    <div className="center">
                        <span className="action_cnt" onClick={() => setOpen(true)}><BiSolidCommentDetail size={24} color="#fff" className="text-shadow" /></span>
                        <span className="acion-text text-shadow">{reactions?.reel_comments?.length || 0}</span>
                    </div>
                    {/* <div className="center">
                        <span className="action_cnt"><BsEyeFill size={24} color="#fff" className="text-shadow" /></span>
                        <span className="acion-text text-shadow">{formatNumber(reactions?.views || 0)}</span>
                    </div> */}
                    <div className="center" style={{ marginBottom: 0, cursor: 'pointer' }} onClick={() => setIsMoreOpen(true)}>
                        <span className="action_cnt"><TfiMoreAlt size={24} color="#fff" className="text-shadow" /></span>
                        {/* <span className="acion-text text-shadow">More</span> */}
                    </div>
                </div>

            </div>
                : downloadAppForMore()}

            {isAuthUser ?
                <UnAuthUser isOpen={isAuthUser} onClose={setIsAuthUser} reelsComments={reactions?.reel_comments}
                    type={'Login'} user={user} /> : null}
            {isOpen ? <CommentModal isOpen={isOpen} onClose={setOpen} reelsComments={reactions?.reel_comments}
                type={'Comments'} handleComment={handleComment} setComment={setComment}
                comment={comment} /> : null}
            {isMoreOpen ?
                <More isOpen={isMoreOpen} onClose={setIsMoreOpen} reel={reel} reelUser={reactions?.reels_user} />
                : null}
            {isReactionOpen ? <ReactionModal isOpen={isReactionOpen} onClose={setReactionOpen}
                reactions={reactions?.reels_reel_interactions} type={'Reactions'} /> : null}
        </div>
    );
}

export const MemoizedVideo = React.memo(Video, areEqual);
