import { Block } from "baseui/block";

export default function PrivacyPage() {

    return (
        <Block style={{ paddingLeft: '100px', flexWrap: 'wrap' }}>
            <h3> PRIVACY POLICY</h3>
            <pre>
             {`Farmreach Technologies
               343, Mathrusri Nagar, Hafeezpet,
               Hyderabad - 500 049
               Tel: 040-3588-2066`}
             </pre>
            <pre>
           {`
           
Farminsta is a software product of the company Farmreach Technologies Pvt Ltd. The website "www.farmreach.org"
will give the information about the company and the product and services Farmreach offer. For availing our
services one shall need to visit our product / service website/software. In the course of registering for and
availing various services we provide from time to time through our website www.farminsta.com ("Website",
telephone search, SMS and WAP) or any other medium in which Farminsta may provide services (collectively
referred to as "Media") you may be required to give your name, residence address, workplace address, email
address, date of birth, educational qualifications and similar Personal Information ("Personal Information").
The Personal Information is used for three general purposes: to customize the content you see, to fulfill your
requests for certain services, and to contact you about our services via including but not limited to email's,
sms’s and other means of communication. Unless otherwise stated explicitly, this Policy applies to Personal
Information as disclosed on any of the Media.

We are committed to protecting the privacy and confidentiality of all Personal Information that you may share
as a user of Media In furtherance thereof, we have this policy to demonstrate our good faith.

This policy does not apply to the practices of organizations that we do not own or to people that we do not
employ or manage.

Personal Information will be kept confidential and will be used for our research, marketing, and strategic
client analysis objectives and other internal business purposes only. We do not sell or rent Personal
Information except that in case you are a customer of our search services through any of the Media, your
Personal Information shall be shared with our companies/service providers and you shall be deemed to have
given consent to the same. Further, the companies/service providers who are listed with us, may call you,
based on the query or enquiry that you make with us, enquiring about any

Product / Service or

Product / Service of any companies/service providers or

Product / Service of any particular companies/service providers

We will share Personal Information only under one or more of the following circumstances: - If we have your
consent or deemed consent to do so - If we are compelled by law (including court orders) to do so

In furtherance of the confidentiality with which we treat Personal Information we have put in place
appropriate physical, electronic, and managerial procedures to safeguard and secure the information we collect
online.

We give you the ability to edit your account information and preferences at any time, including whether you
want us to contact you about new services. To protect your privacy and security, we will also take reasonable
steps to verify your identity before granting access or making corrections.

You acknowledge that you are disclosing Personal Information voluntarily. Prior to the completion of any
registration process on our website or prior to availing of any services offered on our website if you wish
not to disclose any Personal Information you may refrain from doing so; however if you don't provide
information that is requested, it is possible that the registration process would be incomplete and/or you
would not be able to avail of the our services. Information which can be personally identifiable information
or/and financial information obtained from the user to avail one of the service on Justdial platform would be
available across Justdial for availing any of the service provided by Justdial.

If you are our corporate customer, it is possible that we have entered into a contract with you for
non-disclosure of confidential information. This Policy shall not affect such a contract in any manner.

If you have questions or concerns about these privacy policies; please send us an email at admin@farmreach.org

Privacy Notice:

Farminsta is committed to protecting the privacy and confidentiality of all Personal Information that you may
share as a user of Media. In furtherance thereof, we have this policy to demonstrate our good faith.

This Privacy Policy is incorporated into and is subject to the Farminsta Terms of Service. Your use of the
Farminsta Site, Farminsta Software and the Farminsta Service and any personal information you provide remains
subject to the terms of this Privacy Policy and our Terms of Service.

What Does This Privacy Policy Cover ? This Privacy Policy is part of Farminsta's Terms of Service and covers
the treatment of user information, including personally identifying information, obtained by Farminsta,
including information obtained when you access the Farminsta Site, use the Farminsta Service or any other
software provided by Farminsta.

The Information Farminsta Collects:

Farminsta may obtain the following types of information from or concerning you or your mobile phone device,
which may include information that can be used to identify you as specified below ("Personally Identifying
Information") :

"Personal Identifying Information" (PI) - means any information relating to an identified or identifiable
natural person including common identifiers such as a name, an identification number, location data, an online
identifier or one or more factors specific to the physical, physiological, genetic, mental, economic, cultural
or social identity of that natural person and any other information that is so categorized by applicable laws.
We collect information about you and/or your usage to provide better services and offerings. The Personal
Information that we collect, and how we collect it, depends upon how you interact with us. We collect the
following categories of Personal Information in the following ways:


name, email address, password, country, city, contact number and company/organization that you are associated
with, when the you sign up for alerts on the Platform;

information that one would usually include in a resume, including name, contact details including e-mail
address and mobile number, work experience, educational qualifications, data relating to your current and past
remuneration or salary, a copy of your resume, etc. when you register on the Platform;

information about the services that you use and how you use them, including log information and location
information, when you are a user of the services through the Platform;

we may collect your Personal Information such as name, age, contact details, preferences, etc. through surveys
and forms, when you choose to participate in these surveys etc.;

we may also collect information relating to your caste and information about whether you are eligible for any
affirmative action programmes or policies, if you opt to provide such information;

when you communicate with Farminsta or use the Platform to communicate with other Users, we collect
information about your communication and any information you choose to provide;

when you visit the Platform, we use cookies to automatically collect, store and use technical information
about your system and interaction with our Platform;

when you use a device to access the Platform, Farminsta may collect technical or other related information
from the device as well as the device location;

To the extent permitted by law, Farminsta may record and monitor your communications with us to ensure
compliance with our legal and regulatory obligations and our internal policies. This may include the recording
of telephone conversations;

If you choose to sign in with your social media account to access the Platform, or otherwise connect your
social media account with the services of the Platform, you consent to our collection, storage, and use, in
accordance with this Privacy Policy, of the information that you make available to us through the social media
interface. Please see your social media provider's privacy policy and help centre for more information about
how they share information when you choose to connect your account.

You provide certain Personally Identifiable Information, such as your mobile phone number, and mobile device
information to Farminsta when choosing to participate in various uses of the Farminsta Service, such as
registering as a user, and viewing ratings of contacts in your address book. In order to provide the Farminsta
Service, Farminsta will periodically access your address book or contact list on your mobile phone to locate
the mobile phone numbers that have been used to avail Farminsta services and to provide ratings of
establishments we have received from users of these mobile numbers. Farminsta may access your location
information to provide search results based on your geo location. This allows the software to provide relevant
search results. Location data gathered from a mobile phone is used only to provide search results and is not
used in any other manner whatsoever.

For some of the service offerings, a user may have to part with financial data which is required to execute a
particular service request for example booking paid product or service. A user may not part with the
information for which the service offering won't be available.

The Information Farminsta Does Not Collect:

Farminsta does not collect emails, addresses or other contact information from its users' mobile address book
or contact lists other than name and mobile phone numbers.

The Way Farminsta Uses Information:

If you submit Personally Identifiable Information to us through the Farminsta Site, or Farminsta Service, then
we use your personal information to operate, maintain, and provide to you the features and functionality of
the Farminsta Site and Farminsta Service. In particular, your mobile phone number is essential to your use of
the Farminsta Service and will be retained. Your name (as it is saved in other user's mobile phone address
book or contact list) may be displayed to other users if they tag your mobile number and access your ratings
in the App.

Where are the ratings shown ? Ratings are shown in the following ways on Farminsta :

In SMS when user asks for information by calling the Farminsta Service

Ratings are displayed on various places on the website, mobile site and mobile applications in pages such as
the overall ratings count page, search results pages, company details pages etc. User information such as
mobile phone number is masked and personally identifiable information is not displayed unless user is
registered with Farminsta agrees to share his information with other users.

How Farminsta uses your information:


We will only use your personal data in a fair and reasonable manner, and where we have a lawful reason to do
so.


Our use of your personal data depends on the purpose for which you interact with us. We may process your
Personal Information for the following purposes:


Providing our services and products to you including to send you job alerts, calendar alerts, relevant search
results, recommended jobs and/or candidates (as the case maybe), and other social media communication
facilities;

Protecting our Users and providing you with customer support;

We use information collected from cookies and other technologies, to improve your user experience and the
overall quality of our services (for more information please refer to paragraph 4 below). When showing you
tailored ads, we will not associate an identifier from cookies or similar technologies with sensitive
categories, such as those based on race, religion, sexual orientation or health.

Improving the Platform and its content to provide better features and services.

Conducting market research and surveys with the aim of improving our products and services.

Sending you information about our products and services for marketing purposes and promotions;

Preventing, detecting, investigating and taking action against crimes (including but not limited to fraud and
other financial crimes), any other illegal activities, suspected fraud, or violations of IEIL’s Terms of Use
in any jurisdiction

To the extent required for identity verification, government sanctions screening and due diligence checks.

Establishing, exercising or defending legal rights in connection with legal proceedings (including any
prospective legal proceedings) and seeking professional or legal advice in relation to such legal proceedings.



We may also use the information we collect for following purposes, including:

To provide, personalise, maintain and improve our products and services, such as to enable farm visits and
other services, enable features to personalise your Farminsta account;

To carry out our obligations arising from any contracts entered into between user and Farminsta and to provide
user with the relevant information and services;

To administer and enhance the security of our Farminsta Platform and for internal operations, including
troubleshooting, data analysis, testing, research, statistical and survey purposes;

To provide you with information about services we consider similar to those that you are already using, or
have enquired about, or may interest you. If you are a registered user, we will contact you by electronic
means (e-mail or SMS or telephone) with information about these services;

To understand our users (what they do on our Services, what features they like, how they use them, etc.),
improve the content and features of our Services (such as by personalizing content to your interests), process
and complete your transactions, make special offers, provide customer support, process and respond to your
queries;

To generate and review reports and data about, and to conduct research on, our user base and Service usage
patterns;

To allow you to participate in interactive features of our Services, if any; or

To measure or understand the effectiveness of advertising we serve to you and others, and to deliver relevant
advertising to you.

If you are a company or service provider, to track the progress of product / service delivery or status of the
requests placed by our customers.

to carry out academic research with academic partners.

We may combine the information that we receive from third parties with the information you give to us and
information we collect about you for the purposes set out above. Further, we may anonymize and/or de-identify
information collected from you through the Services or via other means, including via the use of third-party
web analytic tools. As a result, our use and disclosure of aggregated and/or de-identified information is not
restricted by this Policy, and it may be used and disclosed to others without limitation.



We analyse the log files of our Farminsta Platform that may contain Internet Protocol (IP) addresses, browser
type and language, Internet service provider (ISP), referring, app crashes, page viewed and exit websites and
applications, operating system, date/time stamp, and clickstream data. This helps us to administer the
website, to learn about user behaviour on the site, to improve our product and services, and to gather
demographic information about our user base as a whole.

When Farminsta Discloses Information:

Other users of the Farminsta Service may see your ratings submissions in a way that is consistent with the use
of the Farminsta Service. For example, a rating of "ABC Service Provider" on Farminsta will be available to
every user of the Farminsta Service who is accessible within serviceable area.

We do not sell or share your Personally Identifiable Information (such as mobile phone number) with other
third-party companies for their commercial or marketing use without your consent or except as part of a
specific program or feature for which you will have the ability to opt-in or opt-out. We may share your
Personally Identifiable Information with third party service providers to the extent that it is reasonably
necessary to perform, improve or maintain the Farminsta Service.

Your Choices:

You may, of course, decline to submit Personally Identifiable Information through the Farminsta Service, in
which case Farminsta may not be able to provide certain services to you. If you do not agree with our Privacy
Policy or Terms of Service, please delete your account and uninstall the Farminsta mobile application; your
continued usage of the Farminsta Service will signify your assent to and acceptance of our Privacy Policy and
Terms of Service. To protect your privacy and security, we take reasonable steps (such as SMS authentication
in certain cases) to verify your identity before registering your mobile phone number and granting you access
to the Farminsta Service. Please contact Farminsta via email to admin@farmreach.org or available web forms
with any questions or comments about this Privacy Policy, your personal information, your consent, or your
opt-in or opt-out choices.

Third-party Advertisers, Links to Other Sites:

Farminsta is currently ad-free and we hope to keep it that way forever. We have no intention to introduce
advertisement into the product, but if we ever do, we will update this section.

Our Commitment to Data Security:

Farminsta uses commercially reasonable physical, managerial, and technical safeguards to preserve the
integrity and security of your personal information. We cannot, however, ensure or warrant the security of any
information you transmit to Farminsta and you do so at your own risk.

Consent To Push Notification:

You agree and confirm that any review, rating and comments, including any other content or data therein, that
you submit/post on Farminsta platforms viz. WEB, WAP, APP & Phone etc. such details as per Farminsta's
discretion will be shared with the users of Farminsta's services.

Policy Changes


We may occasionally update this Policy and such changes will be posted on this page. If we make any
significant changes to this Policy we will endeavour to provide you with reasonable notice of such changes,
such as via prominent notice on the Farminsta Platform or to your email address on record and where required
by applicable law, we will obtain your consent. To the extent permitted under the applicable law, your
continued use of our Services after we publish or send a notice about our changes to this Policy shall
constitute your consent to the updated Policy.

Designated Compliance Officer

The following can be contacted for any compliance issues - Mr. Nidheeshdas Thavorath
Address: Farmreach Technologies Pvt Ltd, No - 201, Plot 343, Road no - 1/3, Mathrusri nagar, Miyapur, Hyderabad - 500049
Email: admin@farmreach.org


Further, please note that the Farminsta Platform stores your data with the cloud platform of Amazon Web
Services provided by Amazon Web Services, Inc., which may store this data on their servers located outside of
India. Amazon Web Services has security measures in place to protect the loss, misuse and alteration of the
information, details of which are available at https://aws.amazon.com/. The privacy policy adopted by Amazon
Web Services are detailed in https://aws.amazon.com/privacy. In the event you have questions or concerns about
the security measures adopted by Amazon Web Services, you can contact their data protection / privacy team /
legal team or designated the grievance officer at these organisations, whose contact details are available in
its privacy policy, or can also write to our Grievance Officer at the address provided above.
`}
          </pre>
        </Block>)
}
