import moment                         from 'moment'
import { create, }                    from 'zustand'
import { devtools, persist, combine } from 'zustand/middleware'

const myMiddlewares = (f) => devtools(persist(f, { name: 'bearStore' }))

interface User {
    id: string
    name: string
}

interface AppState {
    user: User
    setUser: (user: User) => void
}

export const useAppStore = create((persist(combine(
        {
            user: null,
            locale: null,
            last_known_location: null,
            new_request_form: {
                date_of_sowing: moment().format('YYYY-MM-DD'),
                crop_id: null,
                image_url: null,
                ready_to_submit: false
            },
        },
        ((set) => ({
            setUser: (user) => set((state) => ({ user })),
            setLocale: (locale) => set((state) => ({ locale })),
            setLastKnownLocation: (last_known_location) => set((state) => ({ last_known_location })),
            updateNewRequestForm: (new_request_form) => set((state) => ({ new_request_form })),
        }))), { name: 'bearStore' })
))
