import { ParagraphSmall } from "baseui/typography"

export function Content({ title, paragraph }) {
    return (<>

            <h4 style={{ fontFamily: "ui-monospace,monospace", fontSize: "1em" }}>{title} </h4>
            <ParagraphSmall style={{
                fontFamily: "ui-monospace,monospace",
                fontSize: "1em"
            }}>{paragraph}</ParagraphSmall>

        </>
    )
}
