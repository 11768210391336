import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  bigint: { input: any; output: any; }
  bpchar: { input: any; output: any; }
  date: { input: any; output: any; }
  float8: { input: any; output: any; }
  geography: { input: any; output: any; }
  geometry: { input: any; output: any; }
  json: { input: any; output: any; }
  jsonb: { input: any; output: any; }
  name: { input: any; output: any; }
  smallint: { input: any; output: any; }
  timestamp: { input: any; output: any; }
  timestamptz: { input: any; output: any; }
  uuid: { input: any; output: any; }
};

export type AssetInput = {
  url?: InputMaybe<Scalars['String']['input']>;
};

/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type Boolean_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Boolean']['input']>;
  _gt?: InputMaybe<Scalars['Boolean']['input']>;
  _gte?: InputMaybe<Scalars['Boolean']['input']>;
  _in?: InputMaybe<Array<Scalars['Boolean']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Boolean']['input']>;
  _lte?: InputMaybe<Scalars['Boolean']['input']>;
  _neq?: InputMaybe<Scalars['Boolean']['input']>;
  _nin?: InputMaybe<Array<Scalars['Boolean']['input']>>;
};

export type CommandOutputDataWithValues = DefaultCommandOutputDataInterface & {
  __typename?: 'CommandOutputDataWithValues';
  status: Scalars['String']['output'];
  values?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type CreateServiceRequestCommandInput = {
  assets?: InputMaybe<Array<InputMaybe<AssetInput>>>;
  channel?: InputMaybe<Scalars['String']['input']>;
  created_date?: InputMaybe<Scalars['String']['input']>;
  crop: Scalars['String']['input'];
  dateOfSowing: Scalars['String']['input'];
  external_id?: InputMaybe<Scalars['String']['input']>;
  external_source?: InputMaybe<Scalars['String']['input']>;
  farmerUserId: Scalars['String']['input'];
  isRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  requestId: Scalars['String']['input'];
};

export type DefaultCommandOutput = {
  __typename?: 'DefaultCommandOutput';
  data: DefaultCommandOutputDataInterface;
  error?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
};

export type DefaultCommandOutputData = DefaultCommandOutputDataInterface & {
  __typename?: 'DefaultCommandOutputData';
  status: Scalars['String']['output'];
};

export type DefaultCommandOutputDataInterface = {
  status: Scalars['String']['output'];
};

export type DeleteTestRequestsCommandInput = {
  phoneNumbers: Array<Scalars['String']['input']>;
};

export type FarmerAcceptsVisitScheduleCommandInput = {
  farmerUserId: Scalars['String']['input'];
  fieldPartnerUserId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
  visitScheduleId: Scalars['String']['input'];
};

export type FarmerCancelsServiceRequestCommandInput = {
  cancelRecurring?: InputMaybe<Scalars['Boolean']['input']>;
  farmerUserId: Scalars['String']['input'];
  reason?: InputMaybe<Scalars['String']['input']>;
  requestId: Scalars['String']['input'];
};

export type FarmerClosesRequestCommandInput = {
  closed_at?: InputMaybe<Scalars['String']['input']>;
  id: Scalars['String']['input'];
};

export type FarmerClosesResponseCommandInput = {
  fprId: Scalars['String']['input'];
  id: Scalars['String']['input'];
};

export type FarmerGivesFeedbackToFieldPartnerCommandInput = {
  farmerUserId: Scalars['String']['input'];
  feedback: Scalars['String']['input'];
  fieldPartnerUserId: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
  requestId: Scalars['String']['input'];
};

export type FieldPartnerCancelsAVisitCommandInput = {
  field_partner_id: Scalars['String']['input'];
  id: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  scheduled_visit_id: Scalars['String']['input'];
};

export type FieldPartnerCloseRequestCommandInput = {
  fieldPartnerUserId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
};

export type FieldPartnerGivesFeedbackToFarmerCommandInput = {
  farmer_id: Scalars['String']['input'];
  feedback: Scalars['String']['input'];
  field_partner_id: Scalars['String']['input'];
  id: Scalars['String']['input'];
  rating: Scalars['String']['input'];
};

export type FieldPartnerGoesOfflineInput = {
  field_partner_user_id: Scalars['String']['input'];
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

export type FieldPartnerGoesOnlineInput = {
  field_partner_user_id: Scalars['String']['input'];
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

export type FieldPartnerRespondsToServiceRequestCommandInput = {
  action: Scalars['String']['input'];
  fieldPartnerUserId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
};

export type FieldPartnerSchedulesAVisitCommandInput = {
  assets?: InputMaybe<Array<InputMaybe<AssetInput>>>;
  field_partner_id: Scalars['String']['input'];
  id: Scalars['String']['input'];
  scheduled_visit_date: Scalars['String']['input'];
  scheduled_visit_id: Scalars['String']['input'];
  scheduled_visit_location: Scalars['String']['input'];
  scheduled_visit_notes: Scalars['String']['input'];
  scheduled_visit_time: Scalars['String']['input'];
};

export type FieldPartnerUpdatesHisCropsCommandInput = {
  crops?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  id: Scalars['String']['input'];
};

export type FieldPartnerUpdatesHisLocationInput = {
  field_partner_user_id: Scalars['String']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  phone_number?: InputMaybe<Scalars['String']['input']>;
};

export type FieldPartnerUpdatesHisSegmentsCommandInput = {
  id: Scalars['String']['input'];
  segments?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
};

/** Boolean expression to compare columns of type "Float". All fields are combined with logical 'AND'. */
export type Float_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Float']['input']>;
  _gt?: InputMaybe<Scalars['Float']['input']>;
  _gte?: InputMaybe<Scalars['Float']['input']>;
  _in?: InputMaybe<Array<Scalars['Float']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Float']['input']>;
  _lte?: InputMaybe<Scalars['Float']['input']>;
  _neq?: InputMaybe<Scalars['Float']['input']>;
  _nin?: InputMaybe<Array<Scalars['Float']['input']>>;
};

export type GetStateLangFromLocationQueryInput = {
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
};

export type GetStateLangQueryResponse = {
  __typename?: 'GetStateLangQueryResponse';
  lang: Scalars['String']['output'];
  state: Scalars['String']['output'];
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type Int_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['Int']['input']>;
  _gt?: InputMaybe<Scalars['Int']['input']>;
  _gte?: InputMaybe<Scalars['Int']['input']>;
  _in?: InputMaybe<Array<Scalars['Int']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['Int']['input']>;
  _lte?: InputMaybe<Scalars['Int']['input']>;
  _neq?: InputMaybe<Scalars['Int']['input']>;
  _nin?: InputMaybe<Array<Scalars['Int']['input']>>;
};

export type RequestOtpCommandInput = {
  countryCode: Scalars['String']['input'];
  hashKey: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
};

export type RequestOtpOutput = {
  __typename?: 'RequestOTPOutput';
  encrypted: Scalars['String']['output'];
  nonce: Scalars['String']['output'];
  otp: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
};

export type SendMessageOnServiceRequestCommandInput = {
  assets?: InputMaybe<Array<InputMaybe<AssetInput>>>;
  farmer_id: Scalars['String']['input'];
  field_partner_id: Scalars['String']['input'];
  message: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
  sender_user_id: Scalars['String']['input'];
};

export type SetRoleCommandInput = {
  roleName: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type SetUserLocaleCommandInput = {
  localeName: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type String_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['String']['input']>;
  _gt?: InputMaybe<Scalars['String']['input']>;
  _gte?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['String']['input']>;
  _in?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['String']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['String']['input']>;
  _lt?: InputMaybe<Scalars['String']['input']>;
  _lte?: InputMaybe<Scalars['String']['input']>;
  _neq?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['String']['input']>;
  _nin?: InputMaybe<Array<Scalars['String']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['String']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['String']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['String']['input']>;
};

export type User = {
  __typename?: 'User';
  contentLanguages: Array<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  last_known_location?: Maybe<UserLastKnownLocation>;
  locale?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phoneNumber: Scalars['String']['output'];
  profile_image_url?: Maybe<Scalars['String']['output']>;
  roles: Array<Scalars['String']['output']>;
};

export type UserCreatesNewReelCategoryInput = {
  categoryId: Scalars['String']['input'];
  name: Scalars['String']['input'];
  parentId: Scalars['Int']['input'];
};

export type UserCreatesReelCommandInput = {
  allow_comments?: InputMaybe<Scalars['Boolean']['input']>;
  allow_downloads?: InputMaybe<Scalars['Boolean']['input']>;
  allow_likes?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reactions?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reports?: InputMaybe<Scalars['Boolean']['input']>;
  allow_shares?: InputMaybe<Scalars['Boolean']['input']>;
  category_id?: InputMaybe<Scalars['Int']['input']>;
  crop_id?: InputMaybe<Scalars['String']['input']>;
  description: Scalars['String']['input'];
  google_places_id?: InputMaybe<Scalars['String']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['Float']['input']>;
  lng?: InputMaybe<Scalars['Float']['input']>;
  tags: Scalars['String']['input'];
  targeted_area?: InputMaybe<Scalars['String']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title: Scalars['String']['input'];
  url: Scalars['String']['input'];
  userId: Scalars['String']['input'];
  visibility?: InputMaybe<Scalars['String']['input']>;
};

export type UserCreatesReelCommentInput = {
  comment: Scalars['String']['input'];
  reelId: Scalars['String']['input'];
  userId: Scalars['String']['input'];
};

export type UserFollowsProfileCommandInput = {
  followUserId?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserLastKnownLocation = {
  __typename?: 'UserLastKnownLocation';
  address: Scalars['String']['output'];
  is_active: Scalars['Boolean']['output'];
  lat: Scalars['Float']['output'];
  lng: Scalars['Float']['output'];
};

export type UserLikedReelInput = {
  reelId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type UserReactedReelInput = {
  reaction: Scalars['String']['input'];
  reelId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type UserReportsCommentCommandInput = {
  commentId: Scalars['Int']['input'];
  feedback?: InputMaybe<Scalars['String']['input']>;
  reelId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type UserReportsReelCommandInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  reelId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type UserRequestsAccountDeletionCommandInput = {
  feedback?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserSharedReelInput = {
  reelId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
};

export type UserUpdatesContentLanguagesInput = {
  contentLanguages?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  userId: Scalars['String']['input'];
};

export type UserUpdatesHisDeviceInfoInput = {
  appBuildNumber?: InputMaybe<Scalars['String']['input']>;
  appVersion?: InputMaybe<Scalars['String']['input']>;
  deviceBrand?: InputMaybe<Scalars['String']['input']>;
  deviceInstallationId: Scalars['String']['input'];
  deviceManufacturer?: InputMaybe<Scalars['String']['input']>;
  deviceModel?: InputMaybe<Scalars['String']['input']>;
  deviceName?: InputMaybe<Scalars['String']['input']>;
  deviceOs?: InputMaybe<Scalars['String']['input']>;
  deviceOsVersion?: InputMaybe<Scalars['String']['input']>;
  deviceToken?: InputMaybe<Scalars['String']['input']>;
  deviceType?: InputMaybe<Scalars['String']['input']>;
  nativePushToken?: InputMaybe<Scalars['String']['input']>;
  releaseChannel?: InputMaybe<Scalars['String']['input']>;
  runtimeVersion?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserUpdatesHisProfileCommandInput = {
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  profileImageUrl?: InputMaybe<Scalars['String']['input']>;
  userId: Scalars['String']['input'];
};

export type UserViewedReelInput = {
  reelId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
};

export type VerifyOtpCommandInput = {
  encrypted: Scalars['String']['input'];
  nonce: Scalars['String']['input'];
  otp: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
  roleName: Scalars['String']['input'];
};

export type VerifyOtpOutput = {
  __typename?: 'VerifyOTPOutput';
  is_verified: Scalars['Boolean']['output'];
  token?: Maybe<Scalars['String']['output']>;
  user?: Maybe<User>;
  userId?: Maybe<Scalars['String']['output']>;
};

/** columns and relationships of "account_crop" */
export type Account_Crop = {
  __typename?: 'account_crop';
  /** An object relationship */
  account: Accounts;
  account_id: Scalars['bpchar']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id: Scalars['bpchar']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  master_crop: Master_Crops;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "account_crop" */
export type Account_Crop_Aggregate = {
  __typename?: 'account_crop_aggregate';
  aggregate?: Maybe<Account_Crop_Aggregate_Fields>;
  nodes: Array<Account_Crop>;
};

export type Account_Crop_Aggregate_Bool_Exp = {
  count?: InputMaybe<Account_Crop_Aggregate_Bool_Exp_Count>;
};

export type Account_Crop_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Account_Crop_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Account_Crop_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "account_crop" */
export type Account_Crop_Aggregate_Fields = {
  __typename?: 'account_crop_aggregate_fields';
  avg?: Maybe<Account_Crop_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Account_Crop_Max_Fields>;
  min?: Maybe<Account_Crop_Min_Fields>;
  stddev?: Maybe<Account_Crop_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Crop_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Crop_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Crop_Sum_Fields>;
  var_pop?: Maybe<Account_Crop_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Crop_Var_Samp_Fields>;
  variance?: Maybe<Account_Crop_Variance_Fields>;
};


/** aggregate fields of "account_crop" */
export type Account_Crop_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Crop_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "account_crop" */
export type Account_Crop_Aggregate_Order_By = {
  avg?: InputMaybe<Account_Crop_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Account_Crop_Max_Order_By>;
  min?: InputMaybe<Account_Crop_Min_Order_By>;
  stddev?: InputMaybe<Account_Crop_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Account_Crop_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Account_Crop_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Account_Crop_Sum_Order_By>;
  var_pop?: InputMaybe<Account_Crop_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Account_Crop_Var_Samp_Order_By>;
  variance?: InputMaybe<Account_Crop_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "account_crop" */
export type Account_Crop_Arr_Rel_Insert_Input = {
  data: Array<Account_Crop_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_Crop_On_Conflict>;
};

/** aggregate avg on columns */
export type Account_Crop_Avg_Fields = {
  __typename?: 'account_crop_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "account_crop" */
export type Account_Crop_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "account_crop". All fields are combined with a logical 'AND'. */
export type Account_Crop_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Crop_Bool_Exp>>;
  _not?: InputMaybe<Account_Crop_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Crop_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crop_id?: InputMaybe<Bpchar_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  master_crop?: InputMaybe<Master_Crops_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_crop" */
export enum Account_Crop_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountCropPkey = 'account_crop_pkey'
}

/** input type for incrementing numeric columns in table "account_crop" */
export type Account_Crop_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "account_crop" */
export type Account_Crop_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  master_crop?: InputMaybe<Master_Crops_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Account_Crop_Max_Fields = {
  __typename?: 'account_crop_max_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id?: Maybe<Scalars['bpchar']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "account_crop" */
export type Account_Crop_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Crop_Min_Fields = {
  __typename?: 'account_crop_min_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id?: Maybe<Scalars['bpchar']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "account_crop" */
export type Account_Crop_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "account_crop" */
export type Account_Crop_Mutation_Response = {
  __typename?: 'account_crop_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Crop>;
};

/** on_conflict condition type for table "account_crop" */
export type Account_Crop_On_Conflict = {
  constraint: Account_Crop_Constraint;
  update_columns?: Array<Account_Crop_Update_Column>;
  where?: InputMaybe<Account_Crop_Bool_Exp>;
};

/** Ordering options when selecting data from "account_crop". */
export type Account_Crop_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  master_crop?: InputMaybe<Master_Crops_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account_crop */
export type Account_Crop_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "account_crop" */
export enum Account_Crop_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account_crop" */
export type Account_Crop_Set_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Account_Crop_Stddev_Fields = {
  __typename?: 'account_crop_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "account_crop" */
export type Account_Crop_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Account_Crop_Stddev_Pop_Fields = {
  __typename?: 'account_crop_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "account_crop" */
export type Account_Crop_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Account_Crop_Stddev_Samp_Fields = {
  __typename?: 'account_crop_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "account_crop" */
export type Account_Crop_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "account_crop" */
export type Account_Crop_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Crop_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Crop_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Account_Crop_Sum_Fields = {
  __typename?: 'account_crop_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "account_crop" */
export type Account_Crop_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "account_crop" */
export enum Account_Crop_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Account_Crop_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Account_Crop_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Crop_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Crop_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Account_Crop_Var_Pop_Fields = {
  __typename?: 'account_crop_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "account_crop" */
export type Account_Crop_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Account_Crop_Var_Samp_Fields = {
  __typename?: 'account_crop_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "account_crop" */
export type Account_Crop_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Account_Crop_Variance_Fields = {
  __typename?: 'account_crop_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "account_crop" */
export type Account_Crop_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "account_segment" */
export type Account_Segment = {
  __typename?: 'account_segment';
  /** An object relationship */
  account: Accounts;
  account_id: Scalars['bpchar']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  master_segment: Master_Segments;
  segment_id: Scalars['bpchar']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "account_segment" */
export type Account_Segment_Aggregate = {
  __typename?: 'account_segment_aggregate';
  aggregate?: Maybe<Account_Segment_Aggregate_Fields>;
  nodes: Array<Account_Segment>;
};

export type Account_Segment_Aggregate_Bool_Exp = {
  count?: InputMaybe<Account_Segment_Aggregate_Bool_Exp_Count>;
};

export type Account_Segment_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Account_Segment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Account_Segment_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "account_segment" */
export type Account_Segment_Aggregate_Fields = {
  __typename?: 'account_segment_aggregate_fields';
  avg?: Maybe<Account_Segment_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Account_Segment_Max_Fields>;
  min?: Maybe<Account_Segment_Min_Fields>;
  stddev?: Maybe<Account_Segment_Stddev_Fields>;
  stddev_pop?: Maybe<Account_Segment_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_Segment_Stddev_Samp_Fields>;
  sum?: Maybe<Account_Segment_Sum_Fields>;
  var_pop?: Maybe<Account_Segment_Var_Pop_Fields>;
  var_samp?: Maybe<Account_Segment_Var_Samp_Fields>;
  variance?: Maybe<Account_Segment_Variance_Fields>;
};


/** aggregate fields of "account_segment" */
export type Account_Segment_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_Segment_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "account_segment" */
export type Account_Segment_Aggregate_Order_By = {
  avg?: InputMaybe<Account_Segment_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Account_Segment_Max_Order_By>;
  min?: InputMaybe<Account_Segment_Min_Order_By>;
  stddev?: InputMaybe<Account_Segment_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Account_Segment_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Account_Segment_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Account_Segment_Sum_Order_By>;
  var_pop?: InputMaybe<Account_Segment_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Account_Segment_Var_Samp_Order_By>;
  variance?: InputMaybe<Account_Segment_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "account_segment" */
export type Account_Segment_Arr_Rel_Insert_Input = {
  data: Array<Account_Segment_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_Segment_On_Conflict>;
};

/** aggregate avg on columns */
export type Account_Segment_Avg_Fields = {
  __typename?: 'account_segment_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "account_segment" */
export type Account_Segment_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "account_segment". All fields are combined with a logical 'AND'. */
export type Account_Segment_Bool_Exp = {
  _and?: InputMaybe<Array<Account_Segment_Bool_Exp>>;
  _not?: InputMaybe<Account_Segment_Bool_Exp>;
  _or?: InputMaybe<Array<Account_Segment_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  master_segment?: InputMaybe<Master_Segments_Bool_Exp>;
  segment_id?: InputMaybe<Bpchar_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_segment" */
export enum Account_Segment_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountSegmentPkey = 'account_segment_pkey'
}

/** input type for incrementing numeric columns in table "account_segment" */
export type Account_Segment_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "account_segment" */
export type Account_Segment_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  master_segment?: InputMaybe<Master_Segments_Obj_Rel_Insert_Input>;
  segment_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Account_Segment_Max_Fields = {
  __typename?: 'account_segment_max_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  segment_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "account_segment" */
export type Account_Segment_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Account_Segment_Min_Fields = {
  __typename?: 'account_segment_min_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  segment_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "account_segment" */
export type Account_Segment_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "account_segment" */
export type Account_Segment_Mutation_Response = {
  __typename?: 'account_segment_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_Segment>;
};

/** on_conflict condition type for table "account_segment" */
export type Account_Segment_On_Conflict = {
  constraint: Account_Segment_Constraint;
  update_columns?: Array<Account_Segment_Update_Column>;
  where?: InputMaybe<Account_Segment_Bool_Exp>;
};

/** Ordering options when selecting data from "account_segment". */
export type Account_Segment_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  master_segment?: InputMaybe<Master_Segments_Order_By>;
  segment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account_segment */
export type Account_Segment_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "account_segment" */
export enum Account_Segment_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "account_segment" */
export type Account_Segment_Set_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  segment_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Account_Segment_Stddev_Fields = {
  __typename?: 'account_segment_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "account_segment" */
export type Account_Segment_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Account_Segment_Stddev_Pop_Fields = {
  __typename?: 'account_segment_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "account_segment" */
export type Account_Segment_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Account_Segment_Stddev_Samp_Fields = {
  __typename?: 'account_segment_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "account_segment" */
export type Account_Segment_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "account_segment" */
export type Account_Segment_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_Segment_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_Segment_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  segment_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Account_Segment_Sum_Fields = {
  __typename?: 'account_segment_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "account_segment" */
export type Account_Segment_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "account_segment" */
export enum Account_Segment_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Account_Segment_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Account_Segment_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_Segment_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_Segment_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Account_Segment_Var_Pop_Fields = {
  __typename?: 'account_segment_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "account_segment" */
export type Account_Segment_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Account_Segment_Var_Samp_Fields = {
  __typename?: 'account_segment_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "account_segment" */
export type Account_Segment_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Account_Segment_Variance_Fields = {
  __typename?: 'account_segment_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "account_segment" */
export type Account_Segment_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "account_user" */
export type Account_User = {
  __typename?: 'account_user';
  /** An object relationship */
  account: Accounts;
  account_id: Scalars['bpchar']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['bigint']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['bpchar']['output'];
};

/** aggregated selection of "account_user" */
export type Account_User_Aggregate = {
  __typename?: 'account_user_aggregate';
  aggregate?: Maybe<Account_User_Aggregate_Fields>;
  nodes: Array<Account_User>;
};

export type Account_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<Account_User_Aggregate_Bool_Exp_Count>;
};

export type Account_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Account_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Account_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "account_user" */
export type Account_User_Aggregate_Fields = {
  __typename?: 'account_user_aggregate_fields';
  avg?: Maybe<Account_User_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Account_User_Max_Fields>;
  min?: Maybe<Account_User_Min_Fields>;
  stddev?: Maybe<Account_User_Stddev_Fields>;
  stddev_pop?: Maybe<Account_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Account_User_Stddev_Samp_Fields>;
  sum?: Maybe<Account_User_Sum_Fields>;
  var_pop?: Maybe<Account_User_Var_Pop_Fields>;
  var_samp?: Maybe<Account_User_Var_Samp_Fields>;
  variance?: Maybe<Account_User_Variance_Fields>;
};


/** aggregate fields of "account_user" */
export type Account_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Account_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "account_user" */
export type Account_User_Aggregate_Order_By = {
  avg?: InputMaybe<Account_User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Account_User_Max_Order_By>;
  min?: InputMaybe<Account_User_Min_Order_By>;
  stddev?: InputMaybe<Account_User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Account_User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Account_User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Account_User_Sum_Order_By>;
  var_pop?: InputMaybe<Account_User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Account_User_Var_Samp_Order_By>;
  variance?: InputMaybe<Account_User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "account_user" */
export type Account_User_Arr_Rel_Insert_Input = {
  data: Array<Account_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Account_User_On_Conflict>;
};

/** aggregate avg on columns */
export type Account_User_Avg_Fields = {
  __typename?: 'account_user_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "account_user" */
export type Account_User_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "account_user". All fields are combined with a logical 'AND'. */
export type Account_User_Bool_Exp = {
  _and?: InputMaybe<Array<Account_User_Bool_Exp>>;
  _not?: InputMaybe<Account_User_Bool_Exp>;
  _or?: InputMaybe<Array<Account_User_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "account_user" */
export enum Account_User_Constraint {
  /** unique or primary key constraint on columns "user_id", "account_id" */
  AccountUserAccountIdIdx = 'account_user_account_id_idx',
  /** unique or primary key constraint on columns "id" */
  AccountUserPkey = 'account_user_pkey'
}

/** input type for incrementing numeric columns in table "account_user" */
export type Account_User_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "account_user" */
export type Account_User_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type Account_User_Max_Fields = {
  __typename?: 'account_user_max_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by max() on columns of table "account_user" */
export type Account_User_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Account_User_Min_Fields = {
  __typename?: 'account_user_min_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by min() on columns of table "account_user" */
export type Account_User_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "account_user" */
export type Account_User_Mutation_Response = {
  __typename?: 'account_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Account_User>;
};

/** on_conflict condition type for table "account_user" */
export type Account_User_On_Conflict = {
  constraint: Account_User_Constraint;
  update_columns?: Array<Account_User_Update_Column>;
  where?: InputMaybe<Account_User_Bool_Exp>;
};

/** Ordering options when selecting data from "account_user". */
export type Account_User_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: account_user */
export type Account_User_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "account_user" */
export enum Account_User_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "account_user" */
export type Account_User_Set_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type Account_User_Stddev_Fields = {
  __typename?: 'account_user_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "account_user" */
export type Account_User_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Account_User_Stddev_Pop_Fields = {
  __typename?: 'account_user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "account_user" */
export type Account_User_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Account_User_Stddev_Samp_Fields = {
  __typename?: 'account_user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "account_user" */
export type Account_User_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "account_user" */
export type Account_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Account_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Account_User_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type Account_User_Sum_Fields = {
  __typename?: 'account_user_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "account_user" */
export type Account_User_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "account_user" */
export enum Account_User_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Account_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Account_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Account_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Account_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Account_User_Var_Pop_Fields = {
  __typename?: 'account_user_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "account_user" */
export type Account_User_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Account_User_Var_Samp_Fields = {
  __typename?: 'account_user_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "account_user" */
export type Account_User_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Account_User_Variance_Fields = {
  __typename?: 'account_user_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "account_user" */
export type Account_User_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "accounts" */
export type Accounts = {
  __typename?: 'accounts';
  /** An array relationship */
  account_crops: Array<Account_Crop>;
  /** An aggregate relationship */
  account_crops_aggregate: Account_Crop_Aggregate;
  /** An array relationship */
  account_segments: Array<Account_Segment>;
  /** An aggregate relationship */
  account_segments_aggregate: Account_Segment_Aggregate;
  /** An array relationship */
  account_users: Array<Account_User>;
  /** An aggregate relationship */
  account_users_aggregate: Account_User_Aggregate;
  admin_user_id: Scalars['bpchar']['output'];
  /** An object relationship */
  company: Companies;
  company_id: Scalars['bpchar']['output'];
  company_verified_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  field_partner_response_events: Array<Field_Partner_Response_Events>;
  /** An aggregate relationship */
  field_partner_response_events_aggregate: Field_Partner_Response_Events_Aggregate;
  /** An array relationship */
  field_partner_response_messages: Array<Field_Partner_Response_Messages>;
  /** An aggregate relationship */
  field_partner_response_messages_aggregate: Field_Partner_Response_Messages_Aggregate;
  /** An array relationship */
  field_partner_responses: Array<Field_Partner_Responses>;
  /** An aggregate relationship */
  field_partner_responses_aggregate: Field_Partner_Responses_Aggregate;
  /** An array relationship */
  field_scheduled_visits: Array<Field_Scheduled_Visits>;
  /** An aggregate relationship */
  field_scheduled_visits_aggregate: Field_Scheduled_Visits_Aggregate;
  id: Scalars['bpchar']['output'];
  /** An array relationship */
  lastKnownLocationsByUserAccountId: Array<Last_Known_Locations>;
  /** An aggregate relationship */
  lastKnownLocationsByUserAccountId_aggregate: Last_Known_Locations_Aggregate;
  name: Scalars['String']['output'];
  pandoforce_account_id?: Maybe<Scalars['bigint']['output']>;
  pandoforce_company_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  service_request_dashboards: Array<Service_Request_Dashboard>;
  /** An aggregate relationship */
  service_request_dashboards_aggregate: Service_Request_Dashboard_Aggregate;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user: Users;
};


/** columns and relationships of "accounts" */
export type AccountsAccount_CropsArgs = {
  distinct_on?: InputMaybe<Array<Account_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Crop_Order_By>>;
  where?: InputMaybe<Account_Crop_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccount_Crops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Crop_Order_By>>;
  where?: InputMaybe<Account_Crop_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccount_SegmentsArgs = {
  distinct_on?: InputMaybe<Array<Account_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Segment_Order_By>>;
  where?: InputMaybe<Account_Segment_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccount_Segments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Segment_Order_By>>;
  where?: InputMaybe<Account_Segment_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccount_UsersArgs = {
  distinct_on?: InputMaybe<Array<Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_User_Order_By>>;
  where?: InputMaybe<Account_User_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsAccount_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_User_Order_By>>;
  where?: InputMaybe<Account_User_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsField_Partner_Response_EventsArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsField_Partner_Response_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsField_Partner_Response_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsField_Partner_Response_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsField_Partner_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsField_Partner_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsField_Scheduled_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsField_Scheduled_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsLastKnownLocationsByUserAccountIdArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsLastKnownLocationsByUserAccountId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsService_Request_DashboardsArgs = {
  distinct_on?: InputMaybe<Array<Service_Request_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Request_Dashboard_Order_By>>;
  where?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
};


/** columns and relationships of "accounts" */
export type AccountsService_Request_Dashboards_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Request_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Request_Dashboard_Order_By>>;
  where?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
};

/** aggregated selection of "accounts" */
export type Accounts_Aggregate = {
  __typename?: 'accounts_aggregate';
  aggregate?: Maybe<Accounts_Aggregate_Fields>;
  nodes: Array<Accounts>;
};

export type Accounts_Aggregate_Bool_Exp = {
  count?: InputMaybe<Accounts_Aggregate_Bool_Exp_Count>;
};

export type Accounts_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Accounts_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "accounts" */
export type Accounts_Aggregate_Fields = {
  __typename?: 'accounts_aggregate_fields';
  avg?: Maybe<Accounts_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Accounts_Max_Fields>;
  min?: Maybe<Accounts_Min_Fields>;
  stddev?: Maybe<Accounts_Stddev_Fields>;
  stddev_pop?: Maybe<Accounts_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Accounts_Stddev_Samp_Fields>;
  sum?: Maybe<Accounts_Sum_Fields>;
  var_pop?: Maybe<Accounts_Var_Pop_Fields>;
  var_samp?: Maybe<Accounts_Var_Samp_Fields>;
  variance?: Maybe<Accounts_Variance_Fields>;
};


/** aggregate fields of "accounts" */
export type Accounts_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Accounts_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "accounts" */
export type Accounts_Aggregate_Order_By = {
  avg?: InputMaybe<Accounts_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Accounts_Max_Order_By>;
  min?: InputMaybe<Accounts_Min_Order_By>;
  stddev?: InputMaybe<Accounts_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Accounts_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Accounts_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Accounts_Sum_Order_By>;
  var_pop?: InputMaybe<Accounts_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Accounts_Var_Samp_Order_By>;
  variance?: InputMaybe<Accounts_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "accounts" */
export type Accounts_Arr_Rel_Insert_Input = {
  data: Array<Accounts_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** aggregate avg on columns */
export type Accounts_Avg_Fields = {
  __typename?: 'accounts_avg_fields';
  pandoforce_account_id?: Maybe<Scalars['Float']['output']>;
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "accounts" */
export type Accounts_Avg_Order_By = {
  pandoforce_account_id?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "accounts". All fields are combined with a logical 'AND'. */
export type Accounts_Bool_Exp = {
  _and?: InputMaybe<Array<Accounts_Bool_Exp>>;
  _not?: InputMaybe<Accounts_Bool_Exp>;
  _or?: InputMaybe<Array<Accounts_Bool_Exp>>;
  account_crops?: InputMaybe<Account_Crop_Bool_Exp>;
  account_crops_aggregate?: InputMaybe<Account_Crop_Aggregate_Bool_Exp>;
  account_segments?: InputMaybe<Account_Segment_Bool_Exp>;
  account_segments_aggregate?: InputMaybe<Account_Segment_Aggregate_Bool_Exp>;
  account_users?: InputMaybe<Account_User_Bool_Exp>;
  account_users_aggregate?: InputMaybe<Account_User_Aggregate_Bool_Exp>;
  admin_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  company?: InputMaybe<Companies_Bool_Exp>;
  company_id?: InputMaybe<Bpchar_Comparison_Exp>;
  company_verified_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  field_partner_response_events?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
  field_partner_response_events_aggregate?: InputMaybe<Field_Partner_Response_Events_Aggregate_Bool_Exp>;
  field_partner_response_messages?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
  field_partner_response_messages_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Bool_Exp>;
  field_partner_responses?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
  field_partner_responses_aggregate?: InputMaybe<Field_Partner_Responses_Aggregate_Bool_Exp>;
  field_scheduled_visits?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
  field_scheduled_visits_aggregate?: InputMaybe<Field_Scheduled_Visits_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  lastKnownLocationsByUserAccountId?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  lastKnownLocationsByUserAccountId_aggregate?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pandoforce_account_id?: InputMaybe<Bigint_Comparison_Exp>;
  pandoforce_company_id?: InputMaybe<Bigint_Comparison_Exp>;
  service_request_dashboards?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
  service_request_dashboards_aggregate?: InputMaybe<Service_Request_Dashboard_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "accounts" */
export enum Accounts_Constraint {
  /** unique or primary key constraint on columns "id" */
  AccountsPkey = 'accounts_pkey'
}

/** input type for incrementing numeric columns in table "accounts" */
export type Accounts_Inc_Input = {
  pandoforce_account_id?: InputMaybe<Scalars['bigint']['input']>;
  pandoforce_company_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "accounts" */
export type Accounts_Insert_Input = {
  account_crops?: InputMaybe<Account_Crop_Arr_Rel_Insert_Input>;
  account_segments?: InputMaybe<Account_Segment_Arr_Rel_Insert_Input>;
  account_users?: InputMaybe<Account_User_Arr_Rel_Insert_Input>;
  admin_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  company?: InputMaybe<Companies_Obj_Rel_Insert_Input>;
  company_id?: InputMaybe<Scalars['bpchar']['input']>;
  company_verified_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  field_partner_response_events?: InputMaybe<Field_Partner_Response_Events_Arr_Rel_Insert_Input>;
  field_partner_response_messages?: InputMaybe<Field_Partner_Response_Messages_Arr_Rel_Insert_Input>;
  field_partner_responses?: InputMaybe<Field_Partner_Responses_Arr_Rel_Insert_Input>;
  field_scheduled_visits?: InputMaybe<Field_Scheduled_Visits_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  lastKnownLocationsByUserAccountId?: InputMaybe<Last_Known_Locations_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  pandoforce_account_id?: InputMaybe<Scalars['bigint']['input']>;
  pandoforce_company_id?: InputMaybe<Scalars['bigint']['input']>;
  service_request_dashboards?: InputMaybe<Service_Request_Dashboard_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Accounts_Max_Fields = {
  __typename?: 'accounts_max_fields';
  admin_user_id?: Maybe<Scalars['bpchar']['output']>;
  company_id?: Maybe<Scalars['bpchar']['output']>;
  company_verified_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pandoforce_account_id?: Maybe<Scalars['bigint']['output']>;
  pandoforce_company_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "accounts" */
export type Accounts_Max_Order_By = {
  admin_user_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_verified_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pandoforce_account_id?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Accounts_Min_Fields = {
  __typename?: 'accounts_min_fields';
  admin_user_id?: Maybe<Scalars['bpchar']['output']>;
  company_id?: Maybe<Scalars['bpchar']['output']>;
  company_verified_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pandoforce_account_id?: Maybe<Scalars['bigint']['output']>;
  pandoforce_company_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "accounts" */
export type Accounts_Min_Order_By = {
  admin_user_id?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_verified_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pandoforce_account_id?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "accounts" */
export type Accounts_Mutation_Response = {
  __typename?: 'accounts_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Accounts>;
};

/** input type for inserting object relation for remote table "accounts" */
export type Accounts_Obj_Rel_Insert_Input = {
  data: Accounts_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};

/** on_conflict condition type for table "accounts" */
export type Accounts_On_Conflict = {
  constraint: Accounts_Constraint;
  update_columns?: Array<Accounts_Update_Column>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** Ordering options when selecting data from "accounts". */
export type Accounts_Order_By = {
  account_crops_aggregate?: InputMaybe<Account_Crop_Aggregate_Order_By>;
  account_segments_aggregate?: InputMaybe<Account_Segment_Aggregate_Order_By>;
  account_users_aggregate?: InputMaybe<Account_User_Aggregate_Order_By>;
  admin_user_id?: InputMaybe<Order_By>;
  company?: InputMaybe<Companies_Order_By>;
  company_id?: InputMaybe<Order_By>;
  company_verified_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  field_partner_response_events_aggregate?: InputMaybe<Field_Partner_Response_Events_Aggregate_Order_By>;
  field_partner_response_messages_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Order_By>;
  field_partner_responses_aggregate?: InputMaybe<Field_Partner_Responses_Aggregate_Order_By>;
  field_scheduled_visits_aggregate?: InputMaybe<Field_Scheduled_Visits_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  lastKnownLocationsByUserAccountId_aggregate?: InputMaybe<Last_Known_Locations_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  pandoforce_account_id?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
  service_request_dashboards_aggregate?: InputMaybe<Service_Request_Dashboard_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: accounts */
export type Accounts_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "accounts" */
export enum Accounts_Select_Column {
  /** column name */
  AdminUserId = 'admin_user_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyVerifiedAt = 'company_verified_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PandoforceAccountId = 'pandoforce_account_id',
  /** column name */
  PandoforceCompanyId = 'pandoforce_company_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "accounts" */
export type Accounts_Set_Input = {
  admin_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  company_id?: InputMaybe<Scalars['bpchar']['input']>;
  company_verified_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pandoforce_account_id?: InputMaybe<Scalars['bigint']['input']>;
  pandoforce_company_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Accounts_Stddev_Fields = {
  __typename?: 'accounts_stddev_fields';
  pandoforce_account_id?: Maybe<Scalars['Float']['output']>;
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "accounts" */
export type Accounts_Stddev_Order_By = {
  pandoforce_account_id?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Accounts_Stddev_Pop_Fields = {
  __typename?: 'accounts_stddev_pop_fields';
  pandoforce_account_id?: Maybe<Scalars['Float']['output']>;
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "accounts" */
export type Accounts_Stddev_Pop_Order_By = {
  pandoforce_account_id?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Accounts_Stddev_Samp_Fields = {
  __typename?: 'accounts_stddev_samp_fields';
  pandoforce_account_id?: Maybe<Scalars['Float']['output']>;
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "accounts" */
export type Accounts_Stddev_Samp_Order_By = {
  pandoforce_account_id?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "accounts" */
export type Accounts_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Accounts_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Accounts_Stream_Cursor_Value_Input = {
  admin_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  company_id?: InputMaybe<Scalars['bpchar']['input']>;
  company_verified_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pandoforce_account_id?: InputMaybe<Scalars['bigint']['input']>;
  pandoforce_company_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Accounts_Sum_Fields = {
  __typename?: 'accounts_sum_fields';
  pandoforce_account_id?: Maybe<Scalars['bigint']['output']>;
  pandoforce_company_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "accounts" */
export type Accounts_Sum_Order_By = {
  pandoforce_account_id?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** update columns of table "accounts" */
export enum Accounts_Update_Column {
  /** column name */
  AdminUserId = 'admin_user_id',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CompanyVerifiedAt = 'company_verified_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PandoforceAccountId = 'pandoforce_account_id',
  /** column name */
  PandoforceCompanyId = 'pandoforce_company_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Accounts_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Accounts_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Accounts_Set_Input>;
  /** filter the rows which have to be updated */
  where: Accounts_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Accounts_Var_Pop_Fields = {
  __typename?: 'accounts_var_pop_fields';
  pandoforce_account_id?: Maybe<Scalars['Float']['output']>;
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "accounts" */
export type Accounts_Var_Pop_Order_By = {
  pandoforce_account_id?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Accounts_Var_Samp_Fields = {
  __typename?: 'accounts_var_samp_fields';
  pandoforce_account_id?: Maybe<Scalars['Float']['output']>;
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "accounts" */
export type Accounts_Var_Samp_Order_By = {
  pandoforce_account_id?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Accounts_Variance_Fields = {
  __typename?: 'accounts_variance_fields';
  pandoforce_account_id?: Maybe<Scalars['Float']['output']>;
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "accounts" */
export type Accounts_Variance_Order_By = {
  pandoforce_account_id?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "action_events" */
export type Action_Events = {
  __typename?: 'action_events';
  actionable_id: Scalars['String']['output'];
  actionable_type: Scalars['String']['output'];
  batch_id: Scalars['bpchar']['output'];
  changes?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  exception: Scalars['String']['output'];
  fields: Scalars['String']['output'];
  id: Scalars['bigint']['output'];
  model_id?: Maybe<Scalars['String']['output']>;
  model_type: Scalars['String']['output'];
  name: Scalars['String']['output'];
  original?: Maybe<Scalars['String']['output']>;
  status: Scalars['String']['output'];
  target_id: Scalars['String']['output'];
  target_type: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id: Scalars['bpchar']['output'];
};

/** aggregated selection of "action_events" */
export type Action_Events_Aggregate = {
  __typename?: 'action_events_aggregate';
  aggregate?: Maybe<Action_Events_Aggregate_Fields>;
  nodes: Array<Action_Events>;
};

/** aggregate fields of "action_events" */
export type Action_Events_Aggregate_Fields = {
  __typename?: 'action_events_aggregate_fields';
  avg?: Maybe<Action_Events_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Action_Events_Max_Fields>;
  min?: Maybe<Action_Events_Min_Fields>;
  stddev?: Maybe<Action_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Action_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Action_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Action_Events_Sum_Fields>;
  var_pop?: Maybe<Action_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Action_Events_Var_Samp_Fields>;
  variance?: Maybe<Action_Events_Variance_Fields>;
};


/** aggregate fields of "action_events" */
export type Action_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Action_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Action_Events_Avg_Fields = {
  __typename?: 'action_events_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "action_events". All fields are combined with a logical 'AND'. */
export type Action_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Action_Events_Bool_Exp>>;
  _not?: InputMaybe<Action_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Action_Events_Bool_Exp>>;
  actionable_id?: InputMaybe<String_Comparison_Exp>;
  actionable_type?: InputMaybe<String_Comparison_Exp>;
  batch_id?: InputMaybe<Bpchar_Comparison_Exp>;
  changes?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  exception?: InputMaybe<String_Comparison_Exp>;
  fields?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  model_id?: InputMaybe<String_Comparison_Exp>;
  model_type?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  original?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  target_id?: InputMaybe<String_Comparison_Exp>;
  target_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "action_events" */
export enum Action_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  ActionEventsPkey = 'action_events_pkey'
}

/** input type for incrementing numeric columns in table "action_events" */
export type Action_Events_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "action_events" */
export type Action_Events_Insert_Input = {
  actionable_id?: InputMaybe<Scalars['String']['input']>;
  actionable_type?: InputMaybe<Scalars['String']['input']>;
  batch_id?: InputMaybe<Scalars['bpchar']['input']>;
  changes?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  exception?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  model_id?: InputMaybe<Scalars['String']['input']>;
  model_type?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  original?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  target_id?: InputMaybe<Scalars['String']['input']>;
  target_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type Action_Events_Max_Fields = {
  __typename?: 'action_events_max_fields';
  actionable_id?: Maybe<Scalars['String']['output']>;
  actionable_type?: Maybe<Scalars['String']['output']>;
  batch_id?: Maybe<Scalars['bpchar']['output']>;
  changes?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  exception?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  model_id?: Maybe<Scalars['String']['output']>;
  model_type?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  original?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  target_id?: Maybe<Scalars['String']['output']>;
  target_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type Action_Events_Min_Fields = {
  __typename?: 'action_events_min_fields';
  actionable_id?: Maybe<Scalars['String']['output']>;
  actionable_type?: Maybe<Scalars['String']['output']>;
  batch_id?: Maybe<Scalars['bpchar']['output']>;
  changes?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  exception?: Maybe<Scalars['String']['output']>;
  fields?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  model_id?: Maybe<Scalars['String']['output']>;
  model_type?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  original?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  target_id?: Maybe<Scalars['String']['output']>;
  target_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "action_events" */
export type Action_Events_Mutation_Response = {
  __typename?: 'action_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Action_Events>;
};

/** on_conflict condition type for table "action_events" */
export type Action_Events_On_Conflict = {
  constraint: Action_Events_Constraint;
  update_columns?: Array<Action_Events_Update_Column>;
  where?: InputMaybe<Action_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "action_events". */
export type Action_Events_Order_By = {
  actionable_id?: InputMaybe<Order_By>;
  actionable_type?: InputMaybe<Order_By>;
  batch_id?: InputMaybe<Order_By>;
  changes?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  exception?: InputMaybe<Order_By>;
  fields?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_id?: InputMaybe<Order_By>;
  model_type?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  original?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  target_id?: InputMaybe<Order_By>;
  target_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: action_events */
export type Action_Events_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "action_events" */
export enum Action_Events_Select_Column {
  /** column name */
  ActionableId = 'actionable_id',
  /** column name */
  ActionableType = 'actionable_type',
  /** column name */
  BatchId = 'batch_id',
  /** column name */
  Changes = 'changes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Exception = 'exception',
  /** column name */
  Fields = 'fields',
  /** column name */
  Id = 'id',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  ModelType = 'model_type',
  /** column name */
  Name = 'name',
  /** column name */
  Original = 'original',
  /** column name */
  Status = 'status',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "action_events" */
export type Action_Events_Set_Input = {
  actionable_id?: InputMaybe<Scalars['String']['input']>;
  actionable_type?: InputMaybe<Scalars['String']['input']>;
  batch_id?: InputMaybe<Scalars['bpchar']['input']>;
  changes?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  exception?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  model_id?: InputMaybe<Scalars['String']['input']>;
  model_type?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  original?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  target_id?: InputMaybe<Scalars['String']['input']>;
  target_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type Action_Events_Stddev_Fields = {
  __typename?: 'action_events_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Action_Events_Stddev_Pop_Fields = {
  __typename?: 'action_events_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Action_Events_Stddev_Samp_Fields = {
  __typename?: 'action_events_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "action_events" */
export type Action_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Action_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Action_Events_Stream_Cursor_Value_Input = {
  actionable_id?: InputMaybe<Scalars['String']['input']>;
  actionable_type?: InputMaybe<Scalars['String']['input']>;
  batch_id?: InputMaybe<Scalars['bpchar']['input']>;
  changes?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  exception?: InputMaybe<Scalars['String']['input']>;
  fields?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  model_id?: InputMaybe<Scalars['String']['input']>;
  model_type?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  original?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  target_id?: InputMaybe<Scalars['String']['input']>;
  target_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type Action_Events_Sum_Fields = {
  __typename?: 'action_events_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "action_events" */
export enum Action_Events_Update_Column {
  /** column name */
  ActionableId = 'actionable_id',
  /** column name */
  ActionableType = 'actionable_type',
  /** column name */
  BatchId = 'batch_id',
  /** column name */
  Changes = 'changes',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Exception = 'exception',
  /** column name */
  Fields = 'fields',
  /** column name */
  Id = 'id',
  /** column name */
  ModelId = 'model_id',
  /** column name */
  ModelType = 'model_type',
  /** column name */
  Name = 'name',
  /** column name */
  Original = 'original',
  /** column name */
  Status = 'status',
  /** column name */
  TargetId = 'target_id',
  /** column name */
  TargetType = 'target_type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Action_Events_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Action_Events_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Action_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Action_Events_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Action_Events_Var_Pop_Fields = {
  __typename?: 'action_events_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Action_Events_Var_Samp_Fields = {
  __typename?: 'action_events_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Action_Events_Variance_Fields = {
  __typename?: 'action_events_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type Bigint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bigint']['input']>;
  _gt?: InputMaybe<Scalars['bigint']['input']>;
  _gte?: InputMaybe<Scalars['bigint']['input']>;
  _in?: InputMaybe<Array<Scalars['bigint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['bigint']['input']>;
  _lte?: InputMaybe<Scalars['bigint']['input']>;
  _neq?: InputMaybe<Scalars['bigint']['input']>;
  _nin?: InputMaybe<Array<Scalars['bigint']['input']>>;
};

/** Boolean expression to compare columns of type "bpchar". All fields are combined with logical 'AND'. */
export type Bpchar_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['bpchar']['input']>;
  _gt?: InputMaybe<Scalars['bpchar']['input']>;
  _gte?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: InputMaybe<Scalars['bpchar']['input']>;
  _in?: InputMaybe<Array<Scalars['bpchar']['input']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: InputMaybe<Scalars['bpchar']['input']>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  /** does the column match the given pattern */
  _like?: InputMaybe<Scalars['bpchar']['input']>;
  _lt?: InputMaybe<Scalars['bpchar']['input']>;
  _lte?: InputMaybe<Scalars['bpchar']['input']>;
  _neq?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: InputMaybe<Scalars['bpchar']['input']>;
  _nin?: InputMaybe<Array<Scalars['bpchar']['input']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given pattern */
  _nlike?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: InputMaybe<Scalars['bpchar']['input']>;
  /** does the column match the given SQL regular expression */
  _similar?: InputMaybe<Scalars['bpchar']['input']>;
};

/** columns and relationships of "companies" */
export type Companies = {
  __typename?: 'companies';
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  admin_user_id: Scalars['bpchar']['output'];
  company_verified_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bpchar']['output'];
  name: Scalars['String']['output'];
  pandoforce_company_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user: Users;
};


/** columns and relationships of "companies" */
export type CompaniesAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "companies" */
export type CompaniesAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};

/** aggregated selection of "companies" */
export type Companies_Aggregate = {
  __typename?: 'companies_aggregate';
  aggregate?: Maybe<Companies_Aggregate_Fields>;
  nodes: Array<Companies>;
};

export type Companies_Aggregate_Bool_Exp = {
  count?: InputMaybe<Companies_Aggregate_Bool_Exp_Count>;
};

export type Companies_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Companies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Companies_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "companies" */
export type Companies_Aggregate_Fields = {
  __typename?: 'companies_aggregate_fields';
  avg?: Maybe<Companies_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Companies_Max_Fields>;
  min?: Maybe<Companies_Min_Fields>;
  stddev?: Maybe<Companies_Stddev_Fields>;
  stddev_pop?: Maybe<Companies_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Companies_Stddev_Samp_Fields>;
  sum?: Maybe<Companies_Sum_Fields>;
  var_pop?: Maybe<Companies_Var_Pop_Fields>;
  var_samp?: Maybe<Companies_Var_Samp_Fields>;
  variance?: Maybe<Companies_Variance_Fields>;
};


/** aggregate fields of "companies" */
export type Companies_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Companies_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "companies" */
export type Companies_Aggregate_Order_By = {
  avg?: InputMaybe<Companies_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Companies_Max_Order_By>;
  min?: InputMaybe<Companies_Min_Order_By>;
  stddev?: InputMaybe<Companies_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Companies_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Companies_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Companies_Sum_Order_By>;
  var_pop?: InputMaybe<Companies_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Companies_Var_Samp_Order_By>;
  variance?: InputMaybe<Companies_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "companies" */
export type Companies_Arr_Rel_Insert_Input = {
  data: Array<Companies_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Companies_On_Conflict>;
};

/** aggregate avg on columns */
export type Companies_Avg_Fields = {
  __typename?: 'companies_avg_fields';
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "companies" */
export type Companies_Avg_Order_By = {
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "companies". All fields are combined with a logical 'AND'. */
export type Companies_Bool_Exp = {
  _and?: InputMaybe<Array<Companies_Bool_Exp>>;
  _not?: InputMaybe<Companies_Bool_Exp>;
  _or?: InputMaybe<Array<Companies_Bool_Exp>>;
  accounts?: InputMaybe<Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  admin_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  company_verified_at?: InputMaybe<Timestamp_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  pandoforce_company_id?: InputMaybe<Bigint_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "companies" */
export enum Companies_Constraint {
  /** unique or primary key constraint on columns "id" */
  CompaniesPkey = 'companies_pkey'
}

/** input type for incrementing numeric columns in table "companies" */
export type Companies_Inc_Input = {
  pandoforce_company_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "companies" */
export type Companies_Insert_Input = {
  accounts?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  admin_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  company_verified_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pandoforce_company_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Companies_Max_Fields = {
  __typename?: 'companies_max_fields';
  admin_user_id?: Maybe<Scalars['bpchar']['output']>;
  company_verified_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pandoforce_company_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "companies" */
export type Companies_Max_Order_By = {
  admin_user_id?: InputMaybe<Order_By>;
  company_verified_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Companies_Min_Fields = {
  __typename?: 'companies_min_fields';
  admin_user_id?: Maybe<Scalars['bpchar']['output']>;
  company_verified_at?: Maybe<Scalars['timestamp']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  pandoforce_company_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "companies" */
export type Companies_Min_Order_By = {
  admin_user_id?: InputMaybe<Order_By>;
  company_verified_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "companies" */
export type Companies_Mutation_Response = {
  __typename?: 'companies_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Companies>;
};

/** input type for inserting object relation for remote table "companies" */
export type Companies_Obj_Rel_Insert_Input = {
  data: Companies_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Companies_On_Conflict>;
};

/** on_conflict condition type for table "companies" */
export type Companies_On_Conflict = {
  constraint: Companies_Constraint;
  update_columns?: Array<Companies_Update_Column>;
  where?: InputMaybe<Companies_Bool_Exp>;
};

/** Ordering options when selecting data from "companies". */
export type Companies_Order_By = {
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  admin_user_id?: InputMaybe<Order_By>;
  company_verified_at?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  pandoforce_company_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: companies */
export type Companies_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "companies" */
export enum Companies_Select_Column {
  /** column name */
  AdminUserId = 'admin_user_id',
  /** column name */
  CompanyVerifiedAt = 'company_verified_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PandoforceCompanyId = 'pandoforce_company_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "companies" */
export type Companies_Set_Input = {
  admin_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  company_verified_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pandoforce_company_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Companies_Stddev_Fields = {
  __typename?: 'companies_stddev_fields';
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "companies" */
export type Companies_Stddev_Order_By = {
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Companies_Stddev_Pop_Fields = {
  __typename?: 'companies_stddev_pop_fields';
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "companies" */
export type Companies_Stddev_Pop_Order_By = {
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Companies_Stddev_Samp_Fields = {
  __typename?: 'companies_stddev_samp_fields';
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "companies" */
export type Companies_Stddev_Samp_Order_By = {
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "companies" */
export type Companies_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Companies_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Companies_Stream_Cursor_Value_Input = {
  admin_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  company_verified_at?: InputMaybe<Scalars['timestamp']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  pandoforce_company_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Companies_Sum_Fields = {
  __typename?: 'companies_sum_fields';
  pandoforce_company_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "companies" */
export type Companies_Sum_Order_By = {
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** update columns of table "companies" */
export enum Companies_Update_Column {
  /** column name */
  AdminUserId = 'admin_user_id',
  /** column name */
  CompanyVerifiedAt = 'company_verified_at',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  PandoforceCompanyId = 'pandoforce_company_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Companies_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Companies_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Companies_Set_Input>;
  /** filter the rows which have to be updated */
  where: Companies_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Companies_Var_Pop_Fields = {
  __typename?: 'companies_var_pop_fields';
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "companies" */
export type Companies_Var_Pop_Order_By = {
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Companies_Var_Samp_Fields = {
  __typename?: 'companies_var_samp_fields';
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "companies" */
export type Companies_Var_Samp_Order_By = {
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Companies_Variance_Fields = {
  __typename?: 'companies_variance_fields';
  pandoforce_company_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "companies" */
export type Companies_Variance_Order_By = {
  pandoforce_company_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "crop_user" */
export type Crop_User = {
  __typename?: 'crop_user';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id: Scalars['bpchar']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  master_crop: Master_Crops;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['bpchar']['output'];
};

/** aggregated selection of "crop_user" */
export type Crop_User_Aggregate = {
  __typename?: 'crop_user_aggregate';
  aggregate?: Maybe<Crop_User_Aggregate_Fields>;
  nodes: Array<Crop_User>;
};

export type Crop_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<Crop_User_Aggregate_Bool_Exp_Count>;
};

export type Crop_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Crop_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Crop_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "crop_user" */
export type Crop_User_Aggregate_Fields = {
  __typename?: 'crop_user_aggregate_fields';
  avg?: Maybe<Crop_User_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Crop_User_Max_Fields>;
  min?: Maybe<Crop_User_Min_Fields>;
  stddev?: Maybe<Crop_User_Stddev_Fields>;
  stddev_pop?: Maybe<Crop_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Crop_User_Stddev_Samp_Fields>;
  sum?: Maybe<Crop_User_Sum_Fields>;
  var_pop?: Maybe<Crop_User_Var_Pop_Fields>;
  var_samp?: Maybe<Crop_User_Var_Samp_Fields>;
  variance?: Maybe<Crop_User_Variance_Fields>;
};


/** aggregate fields of "crop_user" */
export type Crop_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Crop_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "crop_user" */
export type Crop_User_Aggregate_Order_By = {
  avg?: InputMaybe<Crop_User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Crop_User_Max_Order_By>;
  min?: InputMaybe<Crop_User_Min_Order_By>;
  stddev?: InputMaybe<Crop_User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Crop_User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Crop_User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Crop_User_Sum_Order_By>;
  var_pop?: InputMaybe<Crop_User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Crop_User_Var_Samp_Order_By>;
  variance?: InputMaybe<Crop_User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "crop_user" */
export type Crop_User_Arr_Rel_Insert_Input = {
  data: Array<Crop_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Crop_User_On_Conflict>;
};

/** aggregate avg on columns */
export type Crop_User_Avg_Fields = {
  __typename?: 'crop_user_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "crop_user" */
export type Crop_User_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "crop_user". All fields are combined with a logical 'AND'. */
export type Crop_User_Bool_Exp = {
  _and?: InputMaybe<Array<Crop_User_Bool_Exp>>;
  _not?: InputMaybe<Crop_User_Bool_Exp>;
  _or?: InputMaybe<Array<Crop_User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crop_id?: InputMaybe<Bpchar_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  master_crop?: InputMaybe<Master_Crops_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "crop_user" */
export enum Crop_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  CropUserPkey = 'crop_user_pkey'
}

/** input type for incrementing numeric columns in table "crop_user" */
export type Crop_User_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "crop_user" */
export type Crop_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  master_crop?: InputMaybe<Master_Crops_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type Crop_User_Max_Fields = {
  __typename?: 'crop_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id?: Maybe<Scalars['bpchar']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by max() on columns of table "crop_user" */
export type Crop_User_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Crop_User_Min_Fields = {
  __typename?: 'crop_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id?: Maybe<Scalars['bpchar']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by min() on columns of table "crop_user" */
export type Crop_User_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "crop_user" */
export type Crop_User_Mutation_Response = {
  __typename?: 'crop_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Crop_User>;
};

/** on_conflict condition type for table "crop_user" */
export type Crop_User_On_Conflict = {
  constraint: Crop_User_Constraint;
  update_columns?: Array<Crop_User_Update_Column>;
  where?: InputMaybe<Crop_User_Bool_Exp>;
};

/** Ordering options when selecting data from "crop_user". */
export type Crop_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  master_crop?: InputMaybe<Master_Crops_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: crop_user */
export type Crop_User_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "crop_user" */
export enum Crop_User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "crop_user" */
export type Crop_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type Crop_User_Stddev_Fields = {
  __typename?: 'crop_user_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "crop_user" */
export type Crop_User_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Crop_User_Stddev_Pop_Fields = {
  __typename?: 'crop_user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "crop_user" */
export type Crop_User_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Crop_User_Stddev_Samp_Fields = {
  __typename?: 'crop_user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "crop_user" */
export type Crop_User_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "crop_user" */
export type Crop_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Crop_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Crop_User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type Crop_User_Sum_Fields = {
  __typename?: 'crop_user_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "crop_user" */
export type Crop_User_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "crop_user" */
export enum Crop_User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Crop_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Crop_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Crop_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Crop_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Crop_User_Var_Pop_Fields = {
  __typename?: 'crop_user_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "crop_user" */
export type Crop_User_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Crop_User_Var_Samp_Fields = {
  __typename?: 'crop_user_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "crop_user" */
export type Crop_User_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Crop_User_Variance_Fields = {
  __typename?: 'crop_user_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "crop_user" */
export type Crop_User_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** ordering argument of a cursor */
export enum Cursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** Boolean expression to compare columns of type "date". All fields are combined with logical 'AND'. */
export type Date_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['date']['input']>;
  _gt?: InputMaybe<Scalars['date']['input']>;
  _gte?: InputMaybe<Scalars['date']['input']>;
  _in?: InputMaybe<Array<Scalars['date']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['date']['input']>;
  _lte?: InputMaybe<Scalars['date']['input']>;
  _neq?: InputMaybe<Scalars['date']['input']>;
  _nin?: InputMaybe<Array<Scalars['date']['input']>>;
};

/** columns and relationships of "failed_jobs" */
export type Failed_Jobs = {
  __typename?: 'failed_jobs';
  connection: Scalars['String']['output'];
  exception: Scalars['String']['output'];
  failed_at: Scalars['timestamp']['output'];
  id: Scalars['bigint']['output'];
  payload: Scalars['String']['output'];
  queue: Scalars['String']['output'];
  uuid: Scalars['String']['output'];
};

/** aggregated selection of "failed_jobs" */
export type Failed_Jobs_Aggregate = {
  __typename?: 'failed_jobs_aggregate';
  aggregate?: Maybe<Failed_Jobs_Aggregate_Fields>;
  nodes: Array<Failed_Jobs>;
};

/** aggregate fields of "failed_jobs" */
export type Failed_Jobs_Aggregate_Fields = {
  __typename?: 'failed_jobs_aggregate_fields';
  avg?: Maybe<Failed_Jobs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Failed_Jobs_Max_Fields>;
  min?: Maybe<Failed_Jobs_Min_Fields>;
  stddev?: Maybe<Failed_Jobs_Stddev_Fields>;
  stddev_pop?: Maybe<Failed_Jobs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Failed_Jobs_Stddev_Samp_Fields>;
  sum?: Maybe<Failed_Jobs_Sum_Fields>;
  var_pop?: Maybe<Failed_Jobs_Var_Pop_Fields>;
  var_samp?: Maybe<Failed_Jobs_Var_Samp_Fields>;
  variance?: Maybe<Failed_Jobs_Variance_Fields>;
};


/** aggregate fields of "failed_jobs" */
export type Failed_Jobs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Failed_Jobs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Failed_Jobs_Avg_Fields = {
  __typename?: 'failed_jobs_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "failed_jobs". All fields are combined with a logical 'AND'. */
export type Failed_Jobs_Bool_Exp = {
  _and?: InputMaybe<Array<Failed_Jobs_Bool_Exp>>;
  _not?: InputMaybe<Failed_Jobs_Bool_Exp>;
  _or?: InputMaybe<Array<Failed_Jobs_Bool_Exp>>;
  connection?: InputMaybe<String_Comparison_Exp>;
  exception?: InputMaybe<String_Comparison_Exp>;
  failed_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  payload?: InputMaybe<String_Comparison_Exp>;
  queue?: InputMaybe<String_Comparison_Exp>;
  uuid?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "failed_jobs" */
export enum Failed_Jobs_Constraint {
  /** unique or primary key constraint on columns "id" */
  FailedJobsPkey = 'failed_jobs_pkey',
  /** unique or primary key constraint on columns "uuid" */
  FailedJobsUuidUnique = 'failed_jobs_uuid_unique'
}

/** input type for incrementing numeric columns in table "failed_jobs" */
export type Failed_Jobs_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "failed_jobs" */
export type Failed_Jobs_Insert_Input = {
  connection?: InputMaybe<Scalars['String']['input']>;
  exception?: InputMaybe<Scalars['String']['input']>;
  failed_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  queue?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Failed_Jobs_Max_Fields = {
  __typename?: 'failed_jobs_max_fields';
  connection?: Maybe<Scalars['String']['output']>;
  exception?: Maybe<Scalars['String']['output']>;
  failed_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  queue?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Failed_Jobs_Min_Fields = {
  __typename?: 'failed_jobs_min_fields';
  connection?: Maybe<Scalars['String']['output']>;
  exception?: Maybe<Scalars['String']['output']>;
  failed_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  payload?: Maybe<Scalars['String']['output']>;
  queue?: Maybe<Scalars['String']['output']>;
  uuid?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "failed_jobs" */
export type Failed_Jobs_Mutation_Response = {
  __typename?: 'failed_jobs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Failed_Jobs>;
};

/** on_conflict condition type for table "failed_jobs" */
export type Failed_Jobs_On_Conflict = {
  constraint: Failed_Jobs_Constraint;
  update_columns?: Array<Failed_Jobs_Update_Column>;
  where?: InputMaybe<Failed_Jobs_Bool_Exp>;
};

/** Ordering options when selecting data from "failed_jobs". */
export type Failed_Jobs_Order_By = {
  connection?: InputMaybe<Order_By>;
  exception?: InputMaybe<Order_By>;
  failed_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  payload?: InputMaybe<Order_By>;
  queue?: InputMaybe<Order_By>;
  uuid?: InputMaybe<Order_By>;
};

/** primary key columns input for table: failed_jobs */
export type Failed_Jobs_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "failed_jobs" */
export enum Failed_Jobs_Select_Column {
  /** column name */
  Connection = 'connection',
  /** column name */
  Exception = 'exception',
  /** column name */
  FailedAt = 'failed_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Queue = 'queue',
  /** column name */
  Uuid = 'uuid'
}

/** input type for updating data in table "failed_jobs" */
export type Failed_Jobs_Set_Input = {
  connection?: InputMaybe<Scalars['String']['input']>;
  exception?: InputMaybe<Scalars['String']['input']>;
  failed_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  queue?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Failed_Jobs_Stddev_Fields = {
  __typename?: 'failed_jobs_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Failed_Jobs_Stddev_Pop_Fields = {
  __typename?: 'failed_jobs_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Failed_Jobs_Stddev_Samp_Fields = {
  __typename?: 'failed_jobs_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "failed_jobs" */
export type Failed_Jobs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Failed_Jobs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Failed_Jobs_Stream_Cursor_Value_Input = {
  connection?: InputMaybe<Scalars['String']['input']>;
  exception?: InputMaybe<Scalars['String']['input']>;
  failed_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  payload?: InputMaybe<Scalars['String']['input']>;
  queue?: InputMaybe<Scalars['String']['input']>;
  uuid?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Failed_Jobs_Sum_Fields = {
  __typename?: 'failed_jobs_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "failed_jobs" */
export enum Failed_Jobs_Update_Column {
  /** column name */
  Connection = 'connection',
  /** column name */
  Exception = 'exception',
  /** column name */
  FailedAt = 'failed_at',
  /** column name */
  Id = 'id',
  /** column name */
  Payload = 'payload',
  /** column name */
  Queue = 'queue',
  /** column name */
  Uuid = 'uuid'
}

export type Failed_Jobs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Failed_Jobs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Failed_Jobs_Set_Input>;
  /** filter the rows which have to be updated */
  where: Failed_Jobs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Failed_Jobs_Var_Pop_Fields = {
  __typename?: 'failed_jobs_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Failed_Jobs_Var_Samp_Fields = {
  __typename?: 'failed_jobs_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Failed_Jobs_Variance_Fields = {
  __typename?: 'failed_jobs_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "farmer_nearby_field_force" */
export type Farmer_Nearby_Field_Force = {
  __typename?: 'farmer_nearby_field_force';
  id: Scalars['bpchar']['output'];
  location?: Maybe<Scalars['geography']['output']>;
  nearby_field_force: Scalars['json']['output'];
};


/** columns and relationships of "farmer_nearby_field_force" */
export type Farmer_Nearby_Field_ForceNearby_Field_ForceArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "farmer_nearby_field_force" */
export type Farmer_Nearby_Field_Force_Aggregate = {
  __typename?: 'farmer_nearby_field_force_aggregate';
  aggregate?: Maybe<Farmer_Nearby_Field_Force_Aggregate_Fields>;
  nodes: Array<Farmer_Nearby_Field_Force>;
};

/** aggregate fields of "farmer_nearby_field_force" */
export type Farmer_Nearby_Field_Force_Aggregate_Fields = {
  __typename?: 'farmer_nearby_field_force_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Farmer_Nearby_Field_Force_Max_Fields>;
  min?: Maybe<Farmer_Nearby_Field_Force_Min_Fields>;
};


/** aggregate fields of "farmer_nearby_field_force" */
export type Farmer_Nearby_Field_Force_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Farmer_Nearby_Field_Force_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "farmer_nearby_field_force". All fields are combined with a logical 'AND'. */
export type Farmer_Nearby_Field_Force_Bool_Exp = {
  _and?: InputMaybe<Array<Farmer_Nearby_Field_Force_Bool_Exp>>;
  _not?: InputMaybe<Farmer_Nearby_Field_Force_Bool_Exp>;
  _or?: InputMaybe<Array<Farmer_Nearby_Field_Force_Bool_Exp>>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  location?: InputMaybe<Geography_Comparison_Exp>;
  nearby_field_force?: InputMaybe<Json_Comparison_Exp>;
};

/** unique or primary key constraints on table "farmer_nearby_field_force" */
export enum Farmer_Nearby_Field_Force_Constraint {
  /** unique or primary key constraint on columns "id" */
  FarmerNearbyFieldForcePkey = 'farmer_nearby_field_force_pkey'
}

/** input type for inserting data into table "farmer_nearby_field_force" */
export type Farmer_Nearby_Field_Force_Insert_Input = {
  id?: InputMaybe<Scalars['bpchar']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  nearby_field_force?: InputMaybe<Scalars['json']['input']>;
};

/** aggregate max on columns */
export type Farmer_Nearby_Field_Force_Max_Fields = {
  __typename?: 'farmer_nearby_field_force_max_fields';
  id?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type Farmer_Nearby_Field_Force_Min_Fields = {
  __typename?: 'farmer_nearby_field_force_min_fields';
  id?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "farmer_nearby_field_force" */
export type Farmer_Nearby_Field_Force_Mutation_Response = {
  __typename?: 'farmer_nearby_field_force_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Farmer_Nearby_Field_Force>;
};

/** on_conflict condition type for table "farmer_nearby_field_force" */
export type Farmer_Nearby_Field_Force_On_Conflict = {
  constraint: Farmer_Nearby_Field_Force_Constraint;
  update_columns?: Array<Farmer_Nearby_Field_Force_Update_Column>;
  where?: InputMaybe<Farmer_Nearby_Field_Force_Bool_Exp>;
};

/** Ordering options when selecting data from "farmer_nearby_field_force". */
export type Farmer_Nearby_Field_Force_Order_By = {
  id?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  nearby_field_force?: InputMaybe<Order_By>;
};

/** primary key columns input for table: farmer_nearby_field_force */
export type Farmer_Nearby_Field_Force_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "farmer_nearby_field_force" */
export enum Farmer_Nearby_Field_Force_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  NearbyFieldForce = 'nearby_field_force'
}

/** input type for updating data in table "farmer_nearby_field_force" */
export type Farmer_Nearby_Field_Force_Set_Input = {
  id?: InputMaybe<Scalars['bpchar']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  nearby_field_force?: InputMaybe<Scalars['json']['input']>;
};

/** Streaming cursor of the table "farmer_nearby_field_force" */
export type Farmer_Nearby_Field_Force_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Farmer_Nearby_Field_Force_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Farmer_Nearby_Field_Force_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bpchar']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  nearby_field_force?: InputMaybe<Scalars['json']['input']>;
};

/** update columns of table "farmer_nearby_field_force" */
export enum Farmer_Nearby_Field_Force_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Location = 'location',
  /** column name */
  NearbyFieldForce = 'nearby_field_force'
}

export type Farmer_Nearby_Field_Force_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Farmer_Nearby_Field_Force_Set_Input>;
  /** filter the rows which have to be updated */
  where: Farmer_Nearby_Field_Force_Bool_Exp;
};

/** columns and relationships of "farmer_plots" */
export type Farmer_Plots = {
  __typename?: 'farmer_plots';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id: Scalars['bpchar']['output'];
  crop_name: Scalars['String']['output'];
  date_of_sowing: Scalars['date']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_user_id: Scalars['bpchar']['output'];
  id: Scalars['bpchar']['output'];
  image_url?: Maybe<Scalars['String']['output']>;
  lat: Scalars['float8']['output'];
  lng: Scalars['float8']['output'];
  /** An object relationship */
  master_crop: Master_Crops;
  polygon_coordinates?: Maybe<Scalars['geography']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "farmer_plots" */
export type Farmer_Plots_Aggregate = {
  __typename?: 'farmer_plots_aggregate';
  aggregate?: Maybe<Farmer_Plots_Aggregate_Fields>;
  nodes: Array<Farmer_Plots>;
};

export type Farmer_Plots_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Farmer_Plots_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Farmer_Plots_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Farmer_Plots_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Farmer_Plots_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Farmer_Plots_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Farmer_Plots_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Farmer_Plots_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Farmer_Plots_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Farmer_Plots_Aggregate_Bool_Exp_Var_Samp>;
};

export type Farmer_Plots_Aggregate_Bool_Exp_Avg = {
  arguments: Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Farmer_Plots_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Farmer_Plots_Aggregate_Bool_Exp_Corr = {
  arguments: Farmer_Plots_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Farmer_Plots_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Farmer_Plots_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Farmer_Plots_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Farmer_Plots_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Farmer_Plots_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Farmer_Plots_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Farmer_Plots_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Farmer_Plots_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Farmer_Plots_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Farmer_Plots_Aggregate_Bool_Exp_Max = {
  arguments: Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Farmer_Plots_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Farmer_Plots_Aggregate_Bool_Exp_Min = {
  arguments: Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Farmer_Plots_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Farmer_Plots_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Farmer_Plots_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Farmer_Plots_Aggregate_Bool_Exp_Sum = {
  arguments: Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Farmer_Plots_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Farmer_Plots_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Farmer_Plots_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "farmer_plots" */
export type Farmer_Plots_Aggregate_Fields = {
  __typename?: 'farmer_plots_aggregate_fields';
  avg?: Maybe<Farmer_Plots_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Farmer_Plots_Max_Fields>;
  min?: Maybe<Farmer_Plots_Min_Fields>;
  stddev?: Maybe<Farmer_Plots_Stddev_Fields>;
  stddev_pop?: Maybe<Farmer_Plots_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Farmer_Plots_Stddev_Samp_Fields>;
  sum?: Maybe<Farmer_Plots_Sum_Fields>;
  var_pop?: Maybe<Farmer_Plots_Var_Pop_Fields>;
  var_samp?: Maybe<Farmer_Plots_Var_Samp_Fields>;
  variance?: Maybe<Farmer_Plots_Variance_Fields>;
};


/** aggregate fields of "farmer_plots" */
export type Farmer_Plots_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Farmer_Plots_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "farmer_plots" */
export type Farmer_Plots_Aggregate_Order_By = {
  avg?: InputMaybe<Farmer_Plots_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Farmer_Plots_Max_Order_By>;
  min?: InputMaybe<Farmer_Plots_Min_Order_By>;
  stddev?: InputMaybe<Farmer_Plots_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Farmer_Plots_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Farmer_Plots_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Farmer_Plots_Sum_Order_By>;
  var_pop?: InputMaybe<Farmer_Plots_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Farmer_Plots_Var_Samp_Order_By>;
  variance?: InputMaybe<Farmer_Plots_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "farmer_plots" */
export type Farmer_Plots_Arr_Rel_Insert_Input = {
  data: Array<Farmer_Plots_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Farmer_Plots_On_Conflict>;
};

/** aggregate avg on columns */
export type Farmer_Plots_Avg_Fields = {
  __typename?: 'farmer_plots_avg_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "farmer_plots" */
export type Farmer_Plots_Avg_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "farmer_plots". All fields are combined with a logical 'AND'. */
export type Farmer_Plots_Bool_Exp = {
  _and?: InputMaybe<Array<Farmer_Plots_Bool_Exp>>;
  _not?: InputMaybe<Farmer_Plots_Bool_Exp>;
  _or?: InputMaybe<Array<Farmer_Plots_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crop_id?: InputMaybe<Bpchar_Comparison_Exp>;
  crop_name?: InputMaybe<String_Comparison_Exp>;
  date_of_sowing?: InputMaybe<Date_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  farmer_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  lat?: InputMaybe<Float8_Comparison_Exp>;
  lng?: InputMaybe<Float8_Comparison_Exp>;
  master_crop?: InputMaybe<Master_Crops_Bool_Exp>;
  polygon_coordinates?: InputMaybe<Geography_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "farmer_plots" */
export enum Farmer_Plots_Constraint {
  /** unique or primary key constraint on columns "id" */
  FarmerPlotsPkey = 'farmer_plots_pkey'
}

/** input type for incrementing numeric columns in table "farmer_plots" */
export type Farmer_Plots_Inc_Input = {
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "farmer_plots" */
export type Farmer_Plots_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  crop_name?: InputMaybe<Scalars['String']['input']>;
  date_of_sowing?: InputMaybe<Scalars['date']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  master_crop?: InputMaybe<Master_Crops_Obj_Rel_Insert_Input>;
  polygon_coordinates?: InputMaybe<Scalars['geography']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Farmer_Plots_Max_Fields = {
  __typename?: 'farmer_plots_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id?: Maybe<Scalars['bpchar']['output']>;
  crop_name?: Maybe<Scalars['String']['output']>;
  date_of_sowing?: Maybe<Scalars['date']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "farmer_plots" */
export type Farmer_Plots_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  crop_name?: InputMaybe<Order_By>;
  date_of_sowing?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Farmer_Plots_Min_Fields = {
  __typename?: 'farmer_plots_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id?: Maybe<Scalars['bpchar']['output']>;
  crop_name?: Maybe<Scalars['String']['output']>;
  date_of_sowing?: Maybe<Scalars['date']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "farmer_plots" */
export type Farmer_Plots_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  crop_name?: InputMaybe<Order_By>;
  date_of_sowing?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "farmer_plots" */
export type Farmer_Plots_Mutation_Response = {
  __typename?: 'farmer_plots_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Farmer_Plots>;
};

/** on_conflict condition type for table "farmer_plots" */
export type Farmer_Plots_On_Conflict = {
  constraint: Farmer_Plots_Constraint;
  update_columns?: Array<Farmer_Plots_Update_Column>;
  where?: InputMaybe<Farmer_Plots_Bool_Exp>;
};

/** Ordering options when selecting data from "farmer_plots". */
export type Farmer_Plots_Order_By = {
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  crop_name?: InputMaybe<Order_By>;
  date_of_sowing?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  master_crop?: InputMaybe<Master_Crops_Order_By>;
  polygon_coordinates?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: farmer_plots */
export type Farmer_Plots_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "farmer_plots" */
export enum Farmer_Plots_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  CropName = 'crop_name',
  /** column name */
  DateOfSowing = 'date_of_sowing',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  PolygonCoordinates = 'polygon_coordinates',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "farmer_plots_aggregate_bool_exp_avg_arguments_columns" columns of table "farmer_plots" */
export enum Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "farmer_plots_aggregate_bool_exp_corr_arguments_columns" columns of table "farmer_plots" */
export enum Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "farmer_plots_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "farmer_plots" */
export enum Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "farmer_plots_aggregate_bool_exp_max_arguments_columns" columns of table "farmer_plots" */
export enum Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "farmer_plots_aggregate_bool_exp_min_arguments_columns" columns of table "farmer_plots" */
export enum Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "farmer_plots_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "farmer_plots" */
export enum Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "farmer_plots_aggregate_bool_exp_sum_arguments_columns" columns of table "farmer_plots" */
export enum Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "farmer_plots_aggregate_bool_exp_var_samp_arguments_columns" columns of table "farmer_plots" */
export enum Farmer_Plots_Select_Column_Farmer_Plots_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** input type for updating data in table "farmer_plots" */
export type Farmer_Plots_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  crop_name?: InputMaybe<Scalars['String']['input']>;
  date_of_sowing?: InputMaybe<Scalars['date']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  polygon_coordinates?: InputMaybe<Scalars['geography']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Farmer_Plots_Stddev_Fields = {
  __typename?: 'farmer_plots_stddev_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "farmer_plots" */
export type Farmer_Plots_Stddev_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Farmer_Plots_Stddev_Pop_Fields = {
  __typename?: 'farmer_plots_stddev_pop_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "farmer_plots" */
export type Farmer_Plots_Stddev_Pop_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Farmer_Plots_Stddev_Samp_Fields = {
  __typename?: 'farmer_plots_stddev_samp_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "farmer_plots" */
export type Farmer_Plots_Stddev_Samp_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "farmer_plots" */
export type Farmer_Plots_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Farmer_Plots_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Farmer_Plots_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  crop_name?: InputMaybe<Scalars['String']['input']>;
  date_of_sowing?: InputMaybe<Scalars['date']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  polygon_coordinates?: InputMaybe<Scalars['geography']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Farmer_Plots_Sum_Fields = {
  __typename?: 'farmer_plots_sum_fields';
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "farmer_plots" */
export type Farmer_Plots_Sum_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** update columns of table "farmer_plots" */
export enum Farmer_Plots_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  CropName = 'crop_name',
  /** column name */
  DateOfSowing = 'date_of_sowing',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  PolygonCoordinates = 'polygon_coordinates',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Farmer_Plots_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Farmer_Plots_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Farmer_Plots_Set_Input>;
  /** filter the rows which have to be updated */
  where: Farmer_Plots_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Farmer_Plots_Var_Pop_Fields = {
  __typename?: 'farmer_plots_var_pop_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "farmer_plots" */
export type Farmer_Plots_Var_Pop_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Farmer_Plots_Var_Samp_Fields = {
  __typename?: 'farmer_plots_var_samp_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "farmer_plots" */
export type Farmer_Plots_Var_Samp_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Farmer_Plots_Variance_Fields = {
  __typename?: 'farmer_plots_variance_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "farmer_plots" */
export type Farmer_Plots_Variance_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** columns and relationships of "field_partner_response_events" */
export type Field_Partner_Response_Events = {
  __typename?: 'field_partner_response_events';
  /** An object relationship */
  account: Accounts;
  account_id: Scalars['bpchar']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  event_at: Scalars['timestamptz']['output'];
  event_data: Scalars['jsonb']['output'];
  event_name: Scalars['String']['output'];
  event_type: Scalars['String']['output'];
  farmer_user_id: Scalars['bpchar']['output'];
  /** An object relationship */
  field_partner_response: Field_Partner_Responses;
  field_partner_user_id: Scalars['bpchar']['output'];
  fpr_id: Scalars['bpchar']['output'];
  id: Scalars['bpchar']['output'];
  request_id: Scalars['bpchar']['output'];
  /** An object relationship */
  service_request: Service_Requests;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByFieldPartnerUserId: Users;
};


/** columns and relationships of "field_partner_response_events" */
export type Field_Partner_Response_EventsEvent_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "field_partner_response_events" */
export type Field_Partner_Response_Events_Aggregate = {
  __typename?: 'field_partner_response_events_aggregate';
  aggregate?: Maybe<Field_Partner_Response_Events_Aggregate_Fields>;
  nodes: Array<Field_Partner_Response_Events>;
};

export type Field_Partner_Response_Events_Aggregate_Bool_Exp = {
  count?: InputMaybe<Field_Partner_Response_Events_Aggregate_Bool_Exp_Count>;
};

export type Field_Partner_Response_Events_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "field_partner_response_events" */
export type Field_Partner_Response_Events_Aggregate_Fields = {
  __typename?: 'field_partner_response_events_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Field_Partner_Response_Events_Max_Fields>;
  min?: Maybe<Field_Partner_Response_Events_Min_Fields>;
};


/** aggregate fields of "field_partner_response_events" */
export type Field_Partner_Response_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "field_partner_response_events" */
export type Field_Partner_Response_Events_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Field_Partner_Response_Events_Max_Order_By>;
  min?: InputMaybe<Field_Partner_Response_Events_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Field_Partner_Response_Events_Append_Input = {
  event_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "field_partner_response_events" */
export type Field_Partner_Response_Events_Arr_Rel_Insert_Input = {
  data: Array<Field_Partner_Response_Events_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Partner_Response_Events_On_Conflict>;
};

/** Boolean expression to filter rows from the table "field_partner_response_events". All fields are combined with a logical 'AND'. */
export type Field_Partner_Response_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Field_Partner_Response_Events_Bool_Exp>>;
  _not?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Partner_Response_Events_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_data?: InputMaybe<Jsonb_Comparison_Exp>;
  event_name?: InputMaybe<String_Comparison_Exp>;
  event_type?: InputMaybe<String_Comparison_Exp>;
  farmer_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  field_partner_response?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
  field_partner_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  fpr_id?: InputMaybe<Bpchar_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  request_id?: InputMaybe<Bpchar_Comparison_Exp>;
  service_request?: InputMaybe<Service_Requests_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByFieldPartnerUserId?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "field_partner_response_events" */
export enum Field_Partner_Response_Events_Constraint {
  /** unique or primary key constraint on columns "id" */
  FieldPartnerResponseEventsPkey = 'field_partner_response_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Field_Partner_Response_Events_Delete_At_Path_Input = {
  event_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Field_Partner_Response_Events_Delete_Elem_Input = {
  event_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Field_Partner_Response_Events_Delete_Key_Input = {
  event_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "field_partner_response_events" */
export type Field_Partner_Response_Events_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_data?: InputMaybe<Scalars['jsonb']['input']>;
  event_name?: InputMaybe<Scalars['String']['input']>;
  event_type?: InputMaybe<Scalars['String']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_response?: InputMaybe<Field_Partner_Responses_Obj_Rel_Insert_Input>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  fpr_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  service_request?: InputMaybe<Service_Requests_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByFieldPartnerUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Field_Partner_Response_Events_Max_Fields = {
  __typename?: 'field_partner_response_events_max_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  event_at?: Maybe<Scalars['timestamptz']['output']>;
  event_name?: Maybe<Scalars['String']['output']>;
  event_type?: Maybe<Scalars['String']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  field_partner_user_id?: Maybe<Scalars['bpchar']['output']>;
  fpr_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  request_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "field_partner_response_events" */
export type Field_Partner_Response_Events_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  event_at?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  fpr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Partner_Response_Events_Min_Fields = {
  __typename?: 'field_partner_response_events_min_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  event_at?: Maybe<Scalars['timestamptz']['output']>;
  event_name?: Maybe<Scalars['String']['output']>;
  event_type?: Maybe<Scalars['String']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  field_partner_user_id?: Maybe<Scalars['bpchar']['output']>;
  fpr_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  request_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "field_partner_response_events" */
export type Field_Partner_Response_Events_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  event_at?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  fpr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "field_partner_response_events" */
export type Field_Partner_Response_Events_Mutation_Response = {
  __typename?: 'field_partner_response_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Partner_Response_Events>;
};

/** on_conflict condition type for table "field_partner_response_events" */
export type Field_Partner_Response_Events_On_Conflict = {
  constraint: Field_Partner_Response_Events_Constraint;
  update_columns?: Array<Field_Partner_Response_Events_Update_Column>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "field_partner_response_events". */
export type Field_Partner_Response_Events_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  event_at?: InputMaybe<Order_By>;
  event_data?: InputMaybe<Order_By>;
  event_name?: InputMaybe<Order_By>;
  event_type?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_response?: InputMaybe<Field_Partner_Responses_Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  fpr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  service_request?: InputMaybe<Service_Requests_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByFieldPartnerUserId?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: field_partner_response_events */
export type Field_Partner_Response_Events_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Field_Partner_Response_Events_Prepend_Input = {
  event_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "field_partner_response_events" */
export enum Field_Partner_Response_Events_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventAt = 'event_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  EventName = 'event_name',
  /** column name */
  EventType = 'event_type',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  FieldPartnerUserId = 'field_partner_user_id',
  /** column name */
  FprId = 'fpr_id',
  /** column name */
  Id = 'id',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "field_partner_response_events" */
export type Field_Partner_Response_Events_Set_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_data?: InputMaybe<Scalars['jsonb']['input']>;
  event_name?: InputMaybe<Scalars['String']['input']>;
  event_type?: InputMaybe<Scalars['String']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  fpr_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "field_partner_response_events" */
export type Field_Partner_Response_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Partner_Response_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Partner_Response_Events_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_data?: InputMaybe<Scalars['jsonb']['input']>;
  event_name?: InputMaybe<Scalars['String']['input']>;
  event_type?: InputMaybe<Scalars['String']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  fpr_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "field_partner_response_events" */
export enum Field_Partner_Response_Events_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventAt = 'event_at',
  /** column name */
  EventData = 'event_data',
  /** column name */
  EventName = 'event_name',
  /** column name */
  EventType = 'event_type',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  FieldPartnerUserId = 'field_partner_user_id',
  /** column name */
  FprId = 'fpr_id',
  /** column name */
  Id = 'id',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Field_Partner_Response_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Field_Partner_Response_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Field_Partner_Response_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Field_Partner_Response_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Field_Partner_Response_Events_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Field_Partner_Response_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Field_Partner_Response_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Field_Partner_Response_Events_Bool_Exp;
};

/** columns and relationships of "field_partner_response_messages" */
export type Field_Partner_Response_Messages = {
  __typename?: 'field_partner_response_messages';
  /** An object relationship */
  account: Accounts;
  account_id: Scalars['bpchar']['output'];
  assets: Scalars['jsonb']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  event_at: Scalars['timestamptz']['output'];
  farmer_user_id: Scalars['bpchar']['output'];
  /** An object relationship */
  field_partner_response: Field_Partner_Responses;
  field_partner_user_id: Scalars['bpchar']['output'];
  fpr_id: Scalars['bpchar']['output'];
  from_user_id: Scalars['bpchar']['output'];
  id: Scalars['bpchar']['output'];
  is_read: Scalars['Boolean']['output'];
  message: Scalars['String']['output'];
  request_id: Scalars['bpchar']['output'];
  /** An object relationship */
  service_request: Service_Requests;
  to_user_id: Scalars['bpchar']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByFieldPartnerUserId: Users;
  /** An object relationship */
  userByFromUserId: Users;
  /** An object relationship */
  userByToUserId: Users;
};


/** columns and relationships of "field_partner_response_messages" */
export type Field_Partner_Response_MessagesAssetsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "field_partner_response_messages" */
export type Field_Partner_Response_Messages_Aggregate = {
  __typename?: 'field_partner_response_messages_aggregate';
  aggregate?: Maybe<Field_Partner_Response_Messages_Aggregate_Fields>;
  nodes: Array<Field_Partner_Response_Messages>;
};

export type Field_Partner_Response_Messages_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Bool_Exp_Count>;
};

export type Field_Partner_Response_Messages_Aggregate_Bool_Exp_Bool_And = {
  arguments: Field_Partner_Response_Messages_Select_Column_Field_Partner_Response_Messages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Field_Partner_Response_Messages_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Field_Partner_Response_Messages_Select_Column_Field_Partner_Response_Messages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Field_Partner_Response_Messages_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "field_partner_response_messages" */
export type Field_Partner_Response_Messages_Aggregate_Fields = {
  __typename?: 'field_partner_response_messages_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Field_Partner_Response_Messages_Max_Fields>;
  min?: Maybe<Field_Partner_Response_Messages_Min_Fields>;
};


/** aggregate fields of "field_partner_response_messages" */
export type Field_Partner_Response_Messages_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "field_partner_response_messages" */
export type Field_Partner_Response_Messages_Aggregate_Order_By = {
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Field_Partner_Response_Messages_Max_Order_By>;
  min?: InputMaybe<Field_Partner_Response_Messages_Min_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Field_Partner_Response_Messages_Append_Input = {
  assets?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "field_partner_response_messages" */
export type Field_Partner_Response_Messages_Arr_Rel_Insert_Input = {
  data: Array<Field_Partner_Response_Messages_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Partner_Response_Messages_On_Conflict>;
};

/** Boolean expression to filter rows from the table "field_partner_response_messages". All fields are combined with a logical 'AND'. */
export type Field_Partner_Response_Messages_Bool_Exp = {
  _and?: InputMaybe<Array<Field_Partner_Response_Messages_Bool_Exp>>;
  _not?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Partner_Response_Messages_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  assets?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  event_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  farmer_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  field_partner_response?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
  field_partner_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  fpr_id?: InputMaybe<Bpchar_Comparison_Exp>;
  from_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  is_read?: InputMaybe<Boolean_Comparison_Exp>;
  message?: InputMaybe<String_Comparison_Exp>;
  request_id?: InputMaybe<Bpchar_Comparison_Exp>;
  service_request?: InputMaybe<Service_Requests_Bool_Exp>;
  to_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByFieldPartnerUserId?: InputMaybe<Users_Bool_Exp>;
  userByFromUserId?: InputMaybe<Users_Bool_Exp>;
  userByToUserId?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "field_partner_response_messages" */
export enum Field_Partner_Response_Messages_Constraint {
  /** unique or primary key constraint on columns "id" */
  FieldPartnerResponseMessagesPkey = 'field_partner_response_messages_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Field_Partner_Response_Messages_Delete_At_Path_Input = {
  assets?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Field_Partner_Response_Messages_Delete_Elem_Input = {
  assets?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Field_Partner_Response_Messages_Delete_Key_Input = {
  assets?: InputMaybe<Scalars['String']['input']>;
};

/** input type for inserting data into table "field_partner_response_messages" */
export type Field_Partner_Response_Messages_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  assets?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_response?: InputMaybe<Field_Partner_Responses_Obj_Rel_Insert_Input>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  fpr_id?: InputMaybe<Scalars['bpchar']['input']>;
  from_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_read?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  service_request?: InputMaybe<Service_Requests_Obj_Rel_Insert_Input>;
  to_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByFieldPartnerUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByFromUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByToUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Field_Partner_Response_Messages_Max_Fields = {
  __typename?: 'field_partner_response_messages_max_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  event_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  field_partner_user_id?: Maybe<Scalars['bpchar']['output']>;
  fpr_id?: Maybe<Scalars['bpchar']['output']>;
  from_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  request_id?: Maybe<Scalars['bpchar']['output']>;
  to_user_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "field_partner_response_messages" */
export type Field_Partner_Response_Messages_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  event_at?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  fpr_id?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Partner_Response_Messages_Min_Fields = {
  __typename?: 'field_partner_response_messages_min_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  event_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  field_partner_user_id?: Maybe<Scalars['bpchar']['output']>;
  fpr_id?: Maybe<Scalars['bpchar']['output']>;
  from_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  message?: Maybe<Scalars['String']['output']>;
  request_id?: Maybe<Scalars['bpchar']['output']>;
  to_user_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "field_partner_response_messages" */
export type Field_Partner_Response_Messages_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  event_at?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  fpr_id?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "field_partner_response_messages" */
export type Field_Partner_Response_Messages_Mutation_Response = {
  __typename?: 'field_partner_response_messages_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Partner_Response_Messages>;
};

/** on_conflict condition type for table "field_partner_response_messages" */
export type Field_Partner_Response_Messages_On_Conflict = {
  constraint: Field_Partner_Response_Messages_Constraint;
  update_columns?: Array<Field_Partner_Response_Messages_Update_Column>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};

/** Ordering options when selecting data from "field_partner_response_messages". */
export type Field_Partner_Response_Messages_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  assets?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  event_at?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_response?: InputMaybe<Field_Partner_Responses_Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  fpr_id?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_read?: InputMaybe<Order_By>;
  message?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  service_request?: InputMaybe<Service_Requests_Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByFieldPartnerUserId?: InputMaybe<Users_Order_By>;
  userByFromUserId?: InputMaybe<Users_Order_By>;
  userByToUserId?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: field_partner_response_messages */
export type Field_Partner_Response_Messages_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Field_Partner_Response_Messages_Prepend_Input = {
  assets?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "field_partner_response_messages" */
export enum Field_Partner_Response_Messages_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Assets = 'assets',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventAt = 'event_at',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  FieldPartnerUserId = 'field_partner_user_id',
  /** column name */
  FprId = 'fpr_id',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  Message = 'message',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "field_partner_response_messages_aggregate_bool_exp_bool_and_arguments_columns" columns of table "field_partner_response_messages" */
export enum Field_Partner_Response_Messages_Select_Column_Field_Partner_Response_Messages_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsRead = 'is_read'
}

/** select "field_partner_response_messages_aggregate_bool_exp_bool_or_arguments_columns" columns of table "field_partner_response_messages" */
export enum Field_Partner_Response_Messages_Select_Column_Field_Partner_Response_Messages_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsRead = 'is_read'
}

/** input type for updating data in table "field_partner_response_messages" */
export type Field_Partner_Response_Messages_Set_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  assets?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  fpr_id?: InputMaybe<Scalars['bpchar']['input']>;
  from_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_read?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  to_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** Streaming cursor of the table "field_partner_response_messages" */
export type Field_Partner_Response_Messages_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Partner_Response_Messages_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Partner_Response_Messages_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  assets?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  event_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  fpr_id?: InputMaybe<Scalars['bpchar']['input']>;
  from_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_read?: InputMaybe<Scalars['Boolean']['input']>;
  message?: InputMaybe<Scalars['String']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  to_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** update columns of table "field_partner_response_messages" */
export enum Field_Partner_Response_Messages_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Assets = 'assets',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  EventAt = 'event_at',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  FieldPartnerUserId = 'field_partner_user_id',
  /** column name */
  FprId = 'fpr_id',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsRead = 'is_read',
  /** column name */
  Message = 'message',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Field_Partner_Response_Messages_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Field_Partner_Response_Messages_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Field_Partner_Response_Messages_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Field_Partner_Response_Messages_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Field_Partner_Response_Messages_Delete_Key_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Field_Partner_Response_Messages_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Field_Partner_Response_Messages_Set_Input>;
  /** filter the rows which have to be updated */
  where: Field_Partner_Response_Messages_Bool_Exp;
};

/** columns and relationships of "field_partner_responses" */
export type Field_Partner_Responses = {
  __typename?: 'field_partner_responses';
  /** An object relationship */
  account: Accounts;
  account_id: Scalars['bpchar']['output'];
  closed_at?: Maybe<Scalars['timestamptz']['output']>;
  closed_by?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_feedback?: Maybe<Scalars['String']['output']>;
  farmer_rated_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_rating?: Maybe<Scalars['Int']['output']>;
  farmer_user_id: Scalars['bpchar']['output'];
  field_partner_feedback?: Maybe<Scalars['String']['output']>;
  field_partner_rated_at?: Maybe<Scalars['timestamptz']['output']>;
  field_partner_rating?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  field_partner_response_events: Array<Field_Partner_Response_Events>;
  /** An aggregate relationship */
  field_partner_response_events_aggregate: Field_Partner_Response_Events_Aggregate;
  /** An array relationship */
  field_partner_response_messages: Array<Field_Partner_Response_Messages>;
  /** An aggregate relationship */
  field_partner_response_messages_aggregate: Field_Partner_Response_Messages_Aggregate;
  field_partner_user_id: Scalars['bpchar']['output'];
  /** An array relationship */
  field_scheduled_visits: Array<Field_Scheduled_Visits>;
  /** An aggregate relationship */
  field_scheduled_visits_aggregate: Field_Scheduled_Visits_Aggregate;
  id: Scalars['bpchar']['output'];
  is_response_closed: Scalars['Boolean']['output'];
  request_id: Scalars['bpchar']['output'];
  response_action?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  service_request: Service_Requests;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByFieldPartnerUserId: Users;
};


/** columns and relationships of "field_partner_responses" */
export type Field_Partner_ResponsesField_Partner_Response_EventsArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


/** columns and relationships of "field_partner_responses" */
export type Field_Partner_ResponsesField_Partner_Response_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


/** columns and relationships of "field_partner_responses" */
export type Field_Partner_ResponsesField_Partner_Response_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "field_partner_responses" */
export type Field_Partner_ResponsesField_Partner_Response_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "field_partner_responses" */
export type Field_Partner_ResponsesField_Scheduled_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


/** columns and relationships of "field_partner_responses" */
export type Field_Partner_ResponsesField_Scheduled_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};

/** aggregated selection of "field_partner_responses" */
export type Field_Partner_Responses_Aggregate = {
  __typename?: 'field_partner_responses_aggregate';
  aggregate?: Maybe<Field_Partner_Responses_Aggregate_Fields>;
  nodes: Array<Field_Partner_Responses>;
};

export type Field_Partner_Responses_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Field_Partner_Responses_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Field_Partner_Responses_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Field_Partner_Responses_Aggregate_Bool_Exp_Count>;
};

export type Field_Partner_Responses_Aggregate_Bool_Exp_Bool_And = {
  arguments: Field_Partner_Responses_Select_Column_Field_Partner_Responses_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Field_Partner_Responses_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Field_Partner_Responses_Select_Column_Field_Partner_Responses_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Field_Partner_Responses_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "field_partner_responses" */
export type Field_Partner_Responses_Aggregate_Fields = {
  __typename?: 'field_partner_responses_aggregate_fields';
  avg?: Maybe<Field_Partner_Responses_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Field_Partner_Responses_Max_Fields>;
  min?: Maybe<Field_Partner_Responses_Min_Fields>;
  stddev?: Maybe<Field_Partner_Responses_Stddev_Fields>;
  stddev_pop?: Maybe<Field_Partner_Responses_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Field_Partner_Responses_Stddev_Samp_Fields>;
  sum?: Maybe<Field_Partner_Responses_Sum_Fields>;
  var_pop?: Maybe<Field_Partner_Responses_Var_Pop_Fields>;
  var_samp?: Maybe<Field_Partner_Responses_Var_Samp_Fields>;
  variance?: Maybe<Field_Partner_Responses_Variance_Fields>;
};


/** aggregate fields of "field_partner_responses" */
export type Field_Partner_Responses_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "field_partner_responses" */
export type Field_Partner_Responses_Aggregate_Order_By = {
  avg?: InputMaybe<Field_Partner_Responses_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Field_Partner_Responses_Max_Order_By>;
  min?: InputMaybe<Field_Partner_Responses_Min_Order_By>;
  stddev?: InputMaybe<Field_Partner_Responses_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Field_Partner_Responses_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Field_Partner_Responses_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Field_Partner_Responses_Sum_Order_By>;
  var_pop?: InputMaybe<Field_Partner_Responses_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Field_Partner_Responses_Var_Samp_Order_By>;
  variance?: InputMaybe<Field_Partner_Responses_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "field_partner_responses" */
export type Field_Partner_Responses_Arr_Rel_Insert_Input = {
  data: Array<Field_Partner_Responses_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Partner_Responses_On_Conflict>;
};

/** aggregate avg on columns */
export type Field_Partner_Responses_Avg_Fields = {
  __typename?: 'field_partner_responses_avg_fields';
  farmer_rating?: Maybe<Scalars['Float']['output']>;
  field_partner_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "field_partner_responses" */
export type Field_Partner_Responses_Avg_Order_By = {
  farmer_rating?: InputMaybe<Order_By>;
  field_partner_rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "field_partner_responses". All fields are combined with a logical 'AND'. */
export type Field_Partner_Responses_Bool_Exp = {
  _and?: InputMaybe<Array<Field_Partner_Responses_Bool_Exp>>;
  _not?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Partner_Responses_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  closed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  closed_by?: InputMaybe<Bpchar_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  farmer_feedback?: InputMaybe<String_Comparison_Exp>;
  farmer_rated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  farmer_rating?: InputMaybe<Int_Comparison_Exp>;
  farmer_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  field_partner_feedback?: InputMaybe<String_Comparison_Exp>;
  field_partner_rated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  field_partner_rating?: InputMaybe<Int_Comparison_Exp>;
  field_partner_response_events?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
  field_partner_response_events_aggregate?: InputMaybe<Field_Partner_Response_Events_Aggregate_Bool_Exp>;
  field_partner_response_messages?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
  field_partner_response_messages_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Bool_Exp>;
  field_partner_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  field_scheduled_visits?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
  field_scheduled_visits_aggregate?: InputMaybe<Field_Scheduled_Visits_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  is_response_closed?: InputMaybe<Boolean_Comparison_Exp>;
  request_id?: InputMaybe<Bpchar_Comparison_Exp>;
  response_action?: InputMaybe<String_Comparison_Exp>;
  service_request?: InputMaybe<Service_Requests_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByFieldPartnerUserId?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "field_partner_responses" */
export enum Field_Partner_Responses_Constraint {
  /** unique or primary key constraint on columns "id" */
  FieldPartnerResponsesPkey = 'field_partner_responses_pkey'
}

/** input type for incrementing numeric columns in table "field_partner_responses" */
export type Field_Partner_Responses_Inc_Input = {
  farmer_rating?: InputMaybe<Scalars['Int']['input']>;
  field_partner_rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "field_partner_responses" */
export type Field_Partner_Responses_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  closed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  closed_by?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_feedback?: InputMaybe<Scalars['String']['input']>;
  farmer_rated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_rating?: InputMaybe<Scalars['Int']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_feedback?: InputMaybe<Scalars['String']['input']>;
  field_partner_rated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_partner_rating?: InputMaybe<Scalars['Int']['input']>;
  field_partner_response_events?: InputMaybe<Field_Partner_Response_Events_Arr_Rel_Insert_Input>;
  field_partner_response_messages?: InputMaybe<Field_Partner_Response_Messages_Arr_Rel_Insert_Input>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_scheduled_visits?: InputMaybe<Field_Scheduled_Visits_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_response_closed?: InputMaybe<Scalars['Boolean']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  response_action?: InputMaybe<Scalars['String']['input']>;
  service_request?: InputMaybe<Service_Requests_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByFieldPartnerUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Field_Partner_Responses_Max_Fields = {
  __typename?: 'field_partner_responses_max_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  closed_at?: Maybe<Scalars['timestamptz']['output']>;
  closed_by?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_feedback?: Maybe<Scalars['String']['output']>;
  farmer_rated_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_rating?: Maybe<Scalars['Int']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  field_partner_feedback?: Maybe<Scalars['String']['output']>;
  field_partner_rated_at?: Maybe<Scalars['timestamptz']['output']>;
  field_partner_rating?: Maybe<Scalars['Int']['output']>;
  field_partner_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  request_id?: Maybe<Scalars['bpchar']['output']>;
  response_action?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "field_partner_responses" */
export type Field_Partner_Responses_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  closed_at?: InputMaybe<Order_By>;
  closed_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_feedback?: InputMaybe<Order_By>;
  farmer_rated_at?: InputMaybe<Order_By>;
  farmer_rating?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_feedback?: InputMaybe<Order_By>;
  field_partner_rated_at?: InputMaybe<Order_By>;
  field_partner_rating?: InputMaybe<Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  response_action?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Partner_Responses_Min_Fields = {
  __typename?: 'field_partner_responses_min_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  closed_at?: Maybe<Scalars['timestamptz']['output']>;
  closed_by?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_feedback?: Maybe<Scalars['String']['output']>;
  farmer_rated_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_rating?: Maybe<Scalars['Int']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  field_partner_feedback?: Maybe<Scalars['String']['output']>;
  field_partner_rated_at?: Maybe<Scalars['timestamptz']['output']>;
  field_partner_rating?: Maybe<Scalars['Int']['output']>;
  field_partner_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  request_id?: Maybe<Scalars['bpchar']['output']>;
  response_action?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "field_partner_responses" */
export type Field_Partner_Responses_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  closed_at?: InputMaybe<Order_By>;
  closed_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_feedback?: InputMaybe<Order_By>;
  farmer_rated_at?: InputMaybe<Order_By>;
  farmer_rating?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_feedback?: InputMaybe<Order_By>;
  field_partner_rated_at?: InputMaybe<Order_By>;
  field_partner_rating?: InputMaybe<Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  response_action?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "field_partner_responses" */
export type Field_Partner_Responses_Mutation_Response = {
  __typename?: 'field_partner_responses_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Partner_Responses>;
};

/** input type for inserting object relation for remote table "field_partner_responses" */
export type Field_Partner_Responses_Obj_Rel_Insert_Input = {
  data: Field_Partner_Responses_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Partner_Responses_On_Conflict>;
};

/** on_conflict condition type for table "field_partner_responses" */
export type Field_Partner_Responses_On_Conflict = {
  constraint: Field_Partner_Responses_Constraint;
  update_columns?: Array<Field_Partner_Responses_Update_Column>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};

/** Ordering options when selecting data from "field_partner_responses". */
export type Field_Partner_Responses_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  closed_at?: InputMaybe<Order_By>;
  closed_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_feedback?: InputMaybe<Order_By>;
  farmer_rated_at?: InputMaybe<Order_By>;
  farmer_rating?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_feedback?: InputMaybe<Order_By>;
  field_partner_rated_at?: InputMaybe<Order_By>;
  field_partner_rating?: InputMaybe<Order_By>;
  field_partner_response_events_aggregate?: InputMaybe<Field_Partner_Response_Events_Aggregate_Order_By>;
  field_partner_response_messages_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  field_scheduled_visits_aggregate?: InputMaybe<Field_Scheduled_Visits_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_response_closed?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  response_action?: InputMaybe<Order_By>;
  service_request?: InputMaybe<Service_Requests_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByFieldPartnerUserId?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: field_partner_responses */
export type Field_Partner_Responses_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "field_partner_responses" */
export enum Field_Partner_Responses_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  ClosedBy = 'closed_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerFeedback = 'farmer_feedback',
  /** column name */
  FarmerRatedAt = 'farmer_rated_at',
  /** column name */
  FarmerRating = 'farmer_rating',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  FieldPartnerFeedback = 'field_partner_feedback',
  /** column name */
  FieldPartnerRatedAt = 'field_partner_rated_at',
  /** column name */
  FieldPartnerRating = 'field_partner_rating',
  /** column name */
  FieldPartnerUserId = 'field_partner_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsResponseClosed = 'is_response_closed',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  ResponseAction = 'response_action',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "field_partner_responses_aggregate_bool_exp_bool_and_arguments_columns" columns of table "field_partner_responses" */
export enum Field_Partner_Responses_Select_Column_Field_Partner_Responses_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsResponseClosed = 'is_response_closed'
}

/** select "field_partner_responses_aggregate_bool_exp_bool_or_arguments_columns" columns of table "field_partner_responses" */
export enum Field_Partner_Responses_Select_Column_Field_Partner_Responses_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsResponseClosed = 'is_response_closed'
}

/** input type for updating data in table "field_partner_responses" */
export type Field_Partner_Responses_Set_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  closed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  closed_by?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_feedback?: InputMaybe<Scalars['String']['input']>;
  farmer_rated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_rating?: InputMaybe<Scalars['Int']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_feedback?: InputMaybe<Scalars['String']['input']>;
  field_partner_rated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_partner_rating?: InputMaybe<Scalars['Int']['input']>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_response_closed?: InputMaybe<Scalars['Boolean']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  response_action?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Field_Partner_Responses_Stddev_Fields = {
  __typename?: 'field_partner_responses_stddev_fields';
  farmer_rating?: Maybe<Scalars['Float']['output']>;
  field_partner_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "field_partner_responses" */
export type Field_Partner_Responses_Stddev_Order_By = {
  farmer_rating?: InputMaybe<Order_By>;
  field_partner_rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Field_Partner_Responses_Stddev_Pop_Fields = {
  __typename?: 'field_partner_responses_stddev_pop_fields';
  farmer_rating?: Maybe<Scalars['Float']['output']>;
  field_partner_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "field_partner_responses" */
export type Field_Partner_Responses_Stddev_Pop_Order_By = {
  farmer_rating?: InputMaybe<Order_By>;
  field_partner_rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Field_Partner_Responses_Stddev_Samp_Fields = {
  __typename?: 'field_partner_responses_stddev_samp_fields';
  farmer_rating?: Maybe<Scalars['Float']['output']>;
  field_partner_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "field_partner_responses" */
export type Field_Partner_Responses_Stddev_Samp_Order_By = {
  farmer_rating?: InputMaybe<Order_By>;
  field_partner_rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "field_partner_responses" */
export type Field_Partner_Responses_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Partner_Responses_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Partner_Responses_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  closed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  closed_by?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_feedback?: InputMaybe<Scalars['String']['input']>;
  farmer_rated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_rating?: InputMaybe<Scalars['Int']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_feedback?: InputMaybe<Scalars['String']['input']>;
  field_partner_rated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  field_partner_rating?: InputMaybe<Scalars['Int']['input']>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_response_closed?: InputMaybe<Scalars['Boolean']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  response_action?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Field_Partner_Responses_Sum_Fields = {
  __typename?: 'field_partner_responses_sum_fields';
  farmer_rating?: Maybe<Scalars['Int']['output']>;
  field_partner_rating?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "field_partner_responses" */
export type Field_Partner_Responses_Sum_Order_By = {
  farmer_rating?: InputMaybe<Order_By>;
  field_partner_rating?: InputMaybe<Order_By>;
};

/** update columns of table "field_partner_responses" */
export enum Field_Partner_Responses_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  ClosedBy = 'closed_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerFeedback = 'farmer_feedback',
  /** column name */
  FarmerRatedAt = 'farmer_rated_at',
  /** column name */
  FarmerRating = 'farmer_rating',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  FieldPartnerFeedback = 'field_partner_feedback',
  /** column name */
  FieldPartnerRatedAt = 'field_partner_rated_at',
  /** column name */
  FieldPartnerRating = 'field_partner_rating',
  /** column name */
  FieldPartnerUserId = 'field_partner_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsResponseClosed = 'is_response_closed',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  ResponseAction = 'response_action',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Field_Partner_Responses_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Field_Partner_Responses_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Field_Partner_Responses_Set_Input>;
  /** filter the rows which have to be updated */
  where: Field_Partner_Responses_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Field_Partner_Responses_Var_Pop_Fields = {
  __typename?: 'field_partner_responses_var_pop_fields';
  farmer_rating?: Maybe<Scalars['Float']['output']>;
  field_partner_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "field_partner_responses" */
export type Field_Partner_Responses_Var_Pop_Order_By = {
  farmer_rating?: InputMaybe<Order_By>;
  field_partner_rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Field_Partner_Responses_Var_Samp_Fields = {
  __typename?: 'field_partner_responses_var_samp_fields';
  farmer_rating?: Maybe<Scalars['Float']['output']>;
  field_partner_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "field_partner_responses" */
export type Field_Partner_Responses_Var_Samp_Order_By = {
  farmer_rating?: InputMaybe<Order_By>;
  field_partner_rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Field_Partner_Responses_Variance_Fields = {
  __typename?: 'field_partner_responses_variance_fields';
  farmer_rating?: Maybe<Scalars['Float']['output']>;
  field_partner_rating?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "field_partner_responses" */
export type Field_Partner_Responses_Variance_Order_By = {
  farmer_rating?: InputMaybe<Order_By>;
  field_partner_rating?: InputMaybe<Order_By>;
};

/** columns and relationships of "field_scheduled_visits" */
export type Field_Scheduled_Visits = {
  __typename?: 'field_scheduled_visits';
  /** An object relationship */
  account: Accounts;
  account_id: Scalars['bpchar']['output'];
  assets: Scalars['jsonb']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id: Scalars['bigint']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_user_id: Scalars['bpchar']['output'];
  /** An object relationship */
  field_partner_response: Field_Partner_Responses;
  field_partner_user_id: Scalars['bpchar']['output'];
  fpr_id: Scalars['bpchar']['output'];
  id: Scalars['bpchar']['output'];
  is_farmer_accepted: Scalars['Boolean']['output'];
  is_field_partner_accepted: Scalars['Boolean']['output'];
  is_visit_done: Scalars['Boolean']['output'];
  request_id: Scalars['bpchar']['output'];
  scheduled_at: Scalars['timestamptz']['output'];
  /** An object relationship */
  service_request: Service_Requests;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  /** An object relationship */
  userByFieldPartnerUserId: Users;
};


/** columns and relationships of "field_scheduled_visits" */
export type Field_Scheduled_VisitsAssetsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "field_scheduled_visits" */
export type Field_Scheduled_Visits_Aggregate = {
  __typename?: 'field_scheduled_visits_aggregate';
  aggregate?: Maybe<Field_Scheduled_Visits_Aggregate_Fields>;
  nodes: Array<Field_Scheduled_Visits>;
};

export type Field_Scheduled_Visits_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Field_Scheduled_Visits_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Field_Scheduled_Visits_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Field_Scheduled_Visits_Aggregate_Bool_Exp_Count>;
};

export type Field_Scheduled_Visits_Aggregate_Bool_Exp_Bool_And = {
  arguments: Field_Scheduled_Visits_Select_Column_Field_Scheduled_Visits_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Field_Scheduled_Visits_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Field_Scheduled_Visits_Select_Column_Field_Scheduled_Visits_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Field_Scheduled_Visits_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "field_scheduled_visits" */
export type Field_Scheduled_Visits_Aggregate_Fields = {
  __typename?: 'field_scheduled_visits_aggregate_fields';
  avg?: Maybe<Field_Scheduled_Visits_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Field_Scheduled_Visits_Max_Fields>;
  min?: Maybe<Field_Scheduled_Visits_Min_Fields>;
  stddev?: Maybe<Field_Scheduled_Visits_Stddev_Fields>;
  stddev_pop?: Maybe<Field_Scheduled_Visits_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Field_Scheduled_Visits_Stddev_Samp_Fields>;
  sum?: Maybe<Field_Scheduled_Visits_Sum_Fields>;
  var_pop?: Maybe<Field_Scheduled_Visits_Var_Pop_Fields>;
  var_samp?: Maybe<Field_Scheduled_Visits_Var_Samp_Fields>;
  variance?: Maybe<Field_Scheduled_Visits_Variance_Fields>;
};


/** aggregate fields of "field_scheduled_visits" */
export type Field_Scheduled_Visits_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Aggregate_Order_By = {
  avg?: InputMaybe<Field_Scheduled_Visits_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Field_Scheduled_Visits_Max_Order_By>;
  min?: InputMaybe<Field_Scheduled_Visits_Min_Order_By>;
  stddev?: InputMaybe<Field_Scheduled_Visits_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Field_Scheduled_Visits_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Field_Scheduled_Visits_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Field_Scheduled_Visits_Sum_Order_By>;
  var_pop?: InputMaybe<Field_Scheduled_Visits_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Field_Scheduled_Visits_Var_Samp_Order_By>;
  variance?: InputMaybe<Field_Scheduled_Visits_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Field_Scheduled_Visits_Append_Input = {
  assets?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Arr_Rel_Insert_Input = {
  data: Array<Field_Scheduled_Visits_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Field_Scheduled_Visits_On_Conflict>;
};

/** aggregate avg on columns */
export type Field_Scheduled_Visits_Avg_Fields = {
  __typename?: 'field_scheduled_visits_avg_fields';
  created_by_user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Avg_Order_By = {
  created_by_user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "field_scheduled_visits". All fields are combined with a logical 'AND'. */
export type Field_Scheduled_Visits_Bool_Exp = {
  _and?: InputMaybe<Array<Field_Scheduled_Visits_Bool_Exp>>;
  _not?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
  _or?: InputMaybe<Array<Field_Scheduled_Visits_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  assets?: InputMaybe<Jsonb_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  created_by_user_id?: InputMaybe<Bigint_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  farmer_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  field_partner_response?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
  field_partner_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  fpr_id?: InputMaybe<Bpchar_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  is_farmer_accepted?: InputMaybe<Boolean_Comparison_Exp>;
  is_field_partner_accepted?: InputMaybe<Boolean_Comparison_Exp>;
  is_visit_done?: InputMaybe<Boolean_Comparison_Exp>;
  request_id?: InputMaybe<Bpchar_Comparison_Exp>;
  scheduled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  service_request?: InputMaybe<Service_Requests_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByFieldPartnerUserId?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "field_scheduled_visits" */
export enum Field_Scheduled_Visits_Constraint {
  /** unique or primary key constraint on columns "id" */
  FieldScheduledVisitsPkey = 'field_scheduled_visits_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Field_Scheduled_Visits_Delete_At_Path_Input = {
  assets?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Field_Scheduled_Visits_Delete_Elem_Input = {
  assets?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Field_Scheduled_Visits_Delete_Key_Input = {
  assets?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Inc_Input = {
  created_by_user_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  assets?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['bigint']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_response?: InputMaybe<Field_Partner_Responses_Obj_Rel_Insert_Input>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  fpr_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_farmer_accepted?: InputMaybe<Scalars['Boolean']['input']>;
  is_field_partner_accepted?: InputMaybe<Scalars['Boolean']['input']>;
  is_visit_done?: InputMaybe<Scalars['Boolean']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  service_request?: InputMaybe<Service_Requests_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByFieldPartnerUserId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Field_Scheduled_Visits_Max_Fields = {
  __typename?: 'field_scheduled_visits_max_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['bigint']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  field_partner_user_id?: Maybe<Scalars['bpchar']['output']>;
  fpr_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  request_id?: Maybe<Scalars['bpchar']['output']>;
  scheduled_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  fpr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Field_Scheduled_Visits_Min_Fields = {
  __typename?: 'field_scheduled_visits_min_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  created_by_user_id?: Maybe<Scalars['bigint']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  field_partner_user_id?: Maybe<Scalars['bpchar']['output']>;
  fpr_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  request_id?: Maybe<Scalars['bpchar']['output']>;
  scheduled_at?: Maybe<Scalars['timestamptz']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  fpr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Mutation_Response = {
  __typename?: 'field_scheduled_visits_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Field_Scheduled_Visits>;
};

/** on_conflict condition type for table "field_scheduled_visits" */
export type Field_Scheduled_Visits_On_Conflict = {
  constraint: Field_Scheduled_Visits_Constraint;
  update_columns?: Array<Field_Scheduled_Visits_Update_Column>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};

/** Ordering options when selecting data from "field_scheduled_visits". */
export type Field_Scheduled_Visits_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  assets?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  created_by_user_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_response?: InputMaybe<Field_Partner_Responses_Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  fpr_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_farmer_accepted?: InputMaybe<Order_By>;
  is_field_partner_accepted?: InputMaybe<Order_By>;
  is_visit_done?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  scheduled_at?: InputMaybe<Order_By>;
  service_request?: InputMaybe<Service_Requests_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByFieldPartnerUserId?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: field_scheduled_visits */
export type Field_Scheduled_Visits_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Field_Scheduled_Visits_Prepend_Input = {
  assets?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "field_scheduled_visits" */
export enum Field_Scheduled_Visits_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Assets = 'assets',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  FieldPartnerUserId = 'field_partner_user_id',
  /** column name */
  FprId = 'fpr_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsFarmerAccepted = 'is_farmer_accepted',
  /** column name */
  IsFieldPartnerAccepted = 'is_field_partner_accepted',
  /** column name */
  IsVisitDone = 'is_visit_done',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "field_scheduled_visits_aggregate_bool_exp_bool_and_arguments_columns" columns of table "field_scheduled_visits" */
export enum Field_Scheduled_Visits_Select_Column_Field_Scheduled_Visits_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsFarmerAccepted = 'is_farmer_accepted',
  /** column name */
  IsFieldPartnerAccepted = 'is_field_partner_accepted',
  /** column name */
  IsVisitDone = 'is_visit_done'
}

/** select "field_scheduled_visits_aggregate_bool_exp_bool_or_arguments_columns" columns of table "field_scheduled_visits" */
export enum Field_Scheduled_Visits_Select_Column_Field_Scheduled_Visits_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsFarmerAccepted = 'is_farmer_accepted',
  /** column name */
  IsFieldPartnerAccepted = 'is_field_partner_accepted',
  /** column name */
  IsVisitDone = 'is_visit_done'
}

/** input type for updating data in table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Set_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  assets?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['bigint']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  fpr_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_farmer_accepted?: InputMaybe<Scalars['Boolean']['input']>;
  is_field_partner_accepted?: InputMaybe<Scalars['Boolean']['input']>;
  is_visit_done?: InputMaybe<Scalars['Boolean']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Field_Scheduled_Visits_Stddev_Fields = {
  __typename?: 'field_scheduled_visits_stddev_fields';
  created_by_user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Stddev_Order_By = {
  created_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Field_Scheduled_Visits_Stddev_Pop_Fields = {
  __typename?: 'field_scheduled_visits_stddev_pop_fields';
  created_by_user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Stddev_Pop_Order_By = {
  created_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Field_Scheduled_Visits_Stddev_Samp_Fields = {
  __typename?: 'field_scheduled_visits_stddev_samp_fields';
  created_by_user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Stddev_Samp_Order_By = {
  created_by_user_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Field_Scheduled_Visits_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Field_Scheduled_Visits_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  assets?: InputMaybe<Scalars['jsonb']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  created_by_user_id?: InputMaybe<Scalars['bigint']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  fpr_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_farmer_accepted?: InputMaybe<Scalars['Boolean']['input']>;
  is_field_partner_accepted?: InputMaybe<Scalars['Boolean']['input']>;
  is_visit_done?: InputMaybe<Scalars['Boolean']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  scheduled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Field_Scheduled_Visits_Sum_Fields = {
  __typename?: 'field_scheduled_visits_sum_fields';
  created_by_user_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Sum_Order_By = {
  created_by_user_id?: InputMaybe<Order_By>;
};

/** update columns of table "field_scheduled_visits" */
export enum Field_Scheduled_Visits_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  Assets = 'assets',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CreatedByUserId = 'created_by_user_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  FieldPartnerUserId = 'field_partner_user_id',
  /** column name */
  FprId = 'fpr_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsFarmerAccepted = 'is_farmer_accepted',
  /** column name */
  IsFieldPartnerAccepted = 'is_field_partner_accepted',
  /** column name */
  IsVisitDone = 'is_visit_done',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  ScheduledAt = 'scheduled_at',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Field_Scheduled_Visits_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Field_Scheduled_Visits_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Field_Scheduled_Visits_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Field_Scheduled_Visits_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Field_Scheduled_Visits_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Field_Scheduled_Visits_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Field_Scheduled_Visits_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Field_Scheduled_Visits_Set_Input>;
  /** filter the rows which have to be updated */
  where: Field_Scheduled_Visits_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Field_Scheduled_Visits_Var_Pop_Fields = {
  __typename?: 'field_scheduled_visits_var_pop_fields';
  created_by_user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Var_Pop_Order_By = {
  created_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Field_Scheduled_Visits_Var_Samp_Fields = {
  __typename?: 'field_scheduled_visits_var_samp_fields';
  created_by_user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Var_Samp_Order_By = {
  created_by_user_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Field_Scheduled_Visits_Variance_Fields = {
  __typename?: 'field_scheduled_visits_variance_fields';
  created_by_user_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "field_scheduled_visits" */
export type Field_Scheduled_Visits_Variance_Order_By = {
  created_by_user_id?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "float8". All fields are combined with logical 'AND'. */
export type Float8_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['float8']['input']>;
  _gt?: InputMaybe<Scalars['float8']['input']>;
  _gte?: InputMaybe<Scalars['float8']['input']>;
  _in?: InputMaybe<Array<Scalars['float8']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['float8']['input']>;
  _lte?: InputMaybe<Scalars['float8']['input']>;
  _neq?: InputMaybe<Scalars['float8']['input']>;
  _nin?: InputMaybe<Array<Scalars['float8']['input']>>;
};

/** columns and relationships of "follows" */
export type Follows = {
  __typename?: 'follows';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  from_user_id: Scalars['bpchar']['output'];
  id: Scalars['bigint']['output'];
  to_user_id: Scalars['bpchar']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "follows" */
export type Follows_Aggregate = {
  __typename?: 'follows_aggregate';
  aggregate?: Maybe<Follows_Aggregate_Fields>;
  nodes: Array<Follows>;
};

export type Follows_Aggregate_Bool_Exp = {
  count?: InputMaybe<Follows_Aggregate_Bool_Exp_Count>;
};

export type Follows_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Follows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Follows_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "follows" */
export type Follows_Aggregate_Fields = {
  __typename?: 'follows_aggregate_fields';
  avg?: Maybe<Follows_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Follows_Max_Fields>;
  min?: Maybe<Follows_Min_Fields>;
  stddev?: Maybe<Follows_Stddev_Fields>;
  stddev_pop?: Maybe<Follows_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Follows_Stddev_Samp_Fields>;
  sum?: Maybe<Follows_Sum_Fields>;
  var_pop?: Maybe<Follows_Var_Pop_Fields>;
  var_samp?: Maybe<Follows_Var_Samp_Fields>;
  variance?: Maybe<Follows_Variance_Fields>;
};


/** aggregate fields of "follows" */
export type Follows_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Follows_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "follows" */
export type Follows_Aggregate_Order_By = {
  avg?: InputMaybe<Follows_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Follows_Max_Order_By>;
  min?: InputMaybe<Follows_Min_Order_By>;
  stddev?: InputMaybe<Follows_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Follows_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Follows_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Follows_Sum_Order_By>;
  var_pop?: InputMaybe<Follows_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Follows_Var_Samp_Order_By>;
  variance?: InputMaybe<Follows_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "follows" */
export type Follows_Arr_Rel_Insert_Input = {
  data: Array<Follows_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Follows_On_Conflict>;
};

/** aggregate avg on columns */
export type Follows_Avg_Fields = {
  __typename?: 'follows_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "follows" */
export type Follows_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "follows". All fields are combined with a logical 'AND'. */
export type Follows_Bool_Exp = {
  _and?: InputMaybe<Array<Follows_Bool_Exp>>;
  _not?: InputMaybe<Follows_Bool_Exp>;
  _or?: InputMaybe<Array<Follows_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  from_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  to_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "follows" */
export enum Follows_Constraint {
  /** unique or primary key constraint on columns "from_user_id", "to_user_id" */
  FollowsFromUserIdToUserIdUnique = 'follows_from_user_id_to_user_id_unique',
  /** unique or primary key constraint on columns "id" */
  FollowsPkey = 'follows_pkey'
}

/** input type for incrementing numeric columns in table "follows" */
export type Follows_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "follows" */
export type Follows_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  from_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  to_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Follows_Max_Fields = {
  __typename?: 'follows_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  from_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  to_user_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "follows" */
export type Follows_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Follows_Min_Fields = {
  __typename?: 'follows_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  from_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  to_user_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "follows" */
export type Follows_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "follows" */
export type Follows_Mutation_Response = {
  __typename?: 'follows_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Follows>;
};

/** on_conflict condition type for table "follows" */
export type Follows_On_Conflict = {
  constraint: Follows_Constraint;
  update_columns?: Array<Follows_Update_Column>;
  where?: InputMaybe<Follows_Bool_Exp>;
};

/** Ordering options when selecting data from "follows". */
export type Follows_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  to_user_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: follows */
export type Follows_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "follows" */
export enum Follows_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "follows" */
export type Follows_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  from_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  to_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Follows_Stddev_Fields = {
  __typename?: 'follows_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "follows" */
export type Follows_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Follows_Stddev_Pop_Fields = {
  __typename?: 'follows_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "follows" */
export type Follows_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Follows_Stddev_Samp_Fields = {
  __typename?: 'follows_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "follows" */
export type Follows_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "follows" */
export type Follows_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Follows_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Follows_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  from_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  to_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Follows_Sum_Fields = {
  __typename?: 'follows_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "follows" */
export type Follows_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "follows" */
export enum Follows_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  ToUserId = 'to_user_id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Follows_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Follows_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Follows_Set_Input>;
  /** filter the rows which have to be updated */
  where: Follows_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Follows_Var_Pop_Fields = {
  __typename?: 'follows_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "follows" */
export type Follows_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Follows_Var_Samp_Fields = {
  __typename?: 'follows_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "follows" */
export type Follows_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Follows_Variance_Fields = {
  __typename?: 'follows_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "follows" */
export type Follows_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

export type Geography_Cast_Exp = {
  geometry?: InputMaybe<Geometry_Comparison_Exp>;
};

/** columns and relationships of "geography_columns" */
export type Geography_Columns = {
  __typename?: 'geography_columns';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_geography_column?: Maybe<Scalars['name']['output']>;
  f_table_catalog?: Maybe<Scalars['name']['output']>;
  f_table_name?: Maybe<Scalars['name']['output']>;
  f_table_schema?: Maybe<Scalars['name']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "geography_columns" */
export type Geography_Columns_Aggregate = {
  __typename?: 'geography_columns_aggregate';
  aggregate?: Maybe<Geography_Columns_Aggregate_Fields>;
  nodes: Array<Geography_Columns>;
};

/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_Fields = {
  __typename?: 'geography_columns_aggregate_fields';
  avg?: Maybe<Geography_Columns_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Geography_Columns_Max_Fields>;
  min?: Maybe<Geography_Columns_Min_Fields>;
  stddev?: Maybe<Geography_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geography_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geography_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geography_Columns_Sum_Fields>;
  var_pop?: Maybe<Geography_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geography_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geography_Columns_Variance_Fields>;
};


/** aggregate fields of "geography_columns" */
export type Geography_Columns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Geography_Columns_Avg_Fields = {
  __typename?: 'geography_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "geography_columns". All fields are combined with a logical 'AND'. */
export type Geography_Columns_Bool_Exp = {
  _and?: InputMaybe<Array<Geography_Columns_Bool_Exp>>;
  _not?: InputMaybe<Geography_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Geography_Columns_Bool_Exp>>;
  coord_dimension?: InputMaybe<Int_Comparison_Exp>;
  f_geography_column?: InputMaybe<Name_Comparison_Exp>;
  f_table_catalog?: InputMaybe<Name_Comparison_Exp>;
  f_table_name?: InputMaybe<Name_Comparison_Exp>;
  f_table_schema?: InputMaybe<Name_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** aggregate max on columns */
export type Geography_Columns_Max_Fields = {
  __typename?: 'geography_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Geography_Columns_Min_Fields = {
  __typename?: 'geography_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** Ordering options when selecting data from "geography_columns". */
export type Geography_Columns_Order_By = {
  coord_dimension?: InputMaybe<Order_By>;
  f_geography_column?: InputMaybe<Order_By>;
  f_table_catalog?: InputMaybe<Order_By>;
  f_table_name?: InputMaybe<Order_By>;
  f_table_schema?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "geography_columns" */
export enum Geography_Columns_Select_Column {
  /** column name */
  CoordDimension = 'coord_dimension',
  /** column name */
  FGeographyColumn = 'f_geography_column',
  /** column name */
  FTableCatalog = 'f_table_catalog',
  /** column name */
  FTableName = 'f_table_name',
  /** column name */
  FTableSchema = 'f_table_schema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type Geography_Columns_Stddev_Fields = {
  __typename?: 'geography_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Geography_Columns_Stddev_Pop_Fields = {
  __typename?: 'geography_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Geography_Columns_Stddev_Samp_Fields = {
  __typename?: 'geography_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "geography_columns" */
export type Geography_Columns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geography_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geography_Columns_Stream_Cursor_Value_Input = {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geography_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['name']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Geography_Columns_Sum_Fields = {
  __typename?: 'geography_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
};

/** aggregate var_pop on columns */
export type Geography_Columns_Var_Pop_Fields = {
  __typename?: 'geography_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Geography_Columns_Var_Samp_Fields = {
  __typename?: 'geography_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Geography_Columns_Variance_Fields = {
  __typename?: 'geography_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "geography". All fields are combined with logical 'AND'. */
export type Geography_Comparison_Exp = {
  _cast?: InputMaybe<Geography_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geography']['input']>;
  _gt?: InputMaybe<Scalars['geography']['input']>;
  _gte?: InputMaybe<Scalars['geography']['input']>;
  _in?: InputMaybe<Array<Scalars['geography']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['geography']['input']>;
  _lte?: InputMaybe<Scalars['geography']['input']>;
  _neq?: InputMaybe<Scalars['geography']['input']>;
  _nin?: InputMaybe<Array<Scalars['geography']['input']>>;
  /** is the column within a given distance from the given geography value */
  _st_d_within?: InputMaybe<St_D_Within_Geography_Input>;
  /** does the column spatially intersect the given geography value */
  _st_intersects?: InputMaybe<Scalars['geography']['input']>;
};

export type Geometry_Cast_Exp = {
  geography?: InputMaybe<Geography_Comparison_Exp>;
};

/** columns and relationships of "geometry_columns" */
export type Geometry_Columns = {
  __typename?: 'geometry_columns';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_geometry_column?: Maybe<Scalars['name']['output']>;
  f_table_catalog?: Maybe<Scalars['String']['output']>;
  f_table_name?: Maybe<Scalars['name']['output']>;
  f_table_schema?: Maybe<Scalars['name']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "geometry_columns" */
export type Geometry_Columns_Aggregate = {
  __typename?: 'geometry_columns_aggregate';
  aggregate?: Maybe<Geometry_Columns_Aggregate_Fields>;
  nodes: Array<Geometry_Columns>;
};

/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_Fields = {
  __typename?: 'geometry_columns_aggregate_fields';
  avg?: Maybe<Geometry_Columns_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Geometry_Columns_Max_Fields>;
  min?: Maybe<Geometry_Columns_Min_Fields>;
  stddev?: Maybe<Geometry_Columns_Stddev_Fields>;
  stddev_pop?: Maybe<Geometry_Columns_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Geometry_Columns_Stddev_Samp_Fields>;
  sum?: Maybe<Geometry_Columns_Sum_Fields>;
  var_pop?: Maybe<Geometry_Columns_Var_Pop_Fields>;
  var_samp?: Maybe<Geometry_Columns_Var_Samp_Fields>;
  variance?: Maybe<Geometry_Columns_Variance_Fields>;
};


/** aggregate fields of "geometry_columns" */
export type Geometry_Columns_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Geometry_Columns_Avg_Fields = {
  __typename?: 'geometry_columns_avg_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "geometry_columns". All fields are combined with a logical 'AND'. */
export type Geometry_Columns_Bool_Exp = {
  _and?: InputMaybe<Array<Geometry_Columns_Bool_Exp>>;
  _not?: InputMaybe<Geometry_Columns_Bool_Exp>;
  _or?: InputMaybe<Array<Geometry_Columns_Bool_Exp>>;
  coord_dimension?: InputMaybe<Int_Comparison_Exp>;
  f_geometry_column?: InputMaybe<Name_Comparison_Exp>;
  f_table_catalog?: InputMaybe<String_Comparison_Exp>;
  f_table_name?: InputMaybe<Name_Comparison_Exp>;
  f_table_schema?: InputMaybe<Name_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "geometry_columns" */
export type Geometry_Columns_Inc_Input = {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "geometry_columns" */
export type Geometry_Columns_Insert_Input = {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geometry_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['String']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Geometry_Columns_Max_Fields = {
  __typename?: 'geometry_columns_max_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_table_catalog?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Geometry_Columns_Min_Fields = {
  __typename?: 'geometry_columns_min_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  f_table_catalog?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "geometry_columns" */
export type Geometry_Columns_Mutation_Response = {
  __typename?: 'geometry_columns_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Geometry_Columns>;
};

/** Ordering options when selecting data from "geometry_columns". */
export type Geometry_Columns_Order_By = {
  coord_dimension?: InputMaybe<Order_By>;
  f_geometry_column?: InputMaybe<Order_By>;
  f_table_catalog?: InputMaybe<Order_By>;
  f_table_name?: InputMaybe<Order_By>;
  f_table_schema?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
};

/** select columns of table "geometry_columns" */
export enum Geometry_Columns_Select_Column {
  /** column name */
  CoordDimension = 'coord_dimension',
  /** column name */
  FGeometryColumn = 'f_geometry_column',
  /** column name */
  FTableCatalog = 'f_table_catalog',
  /** column name */
  FTableName = 'f_table_name',
  /** column name */
  FTableSchema = 'f_table_schema',
  /** column name */
  Srid = 'srid',
  /** column name */
  Type = 'type'
}

/** input type for updating data in table "geometry_columns" */
export type Geometry_Columns_Set_Input = {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geometry_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['String']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Geometry_Columns_Stddev_Fields = {
  __typename?: 'geometry_columns_stddev_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Geometry_Columns_Stddev_Pop_Fields = {
  __typename?: 'geometry_columns_stddev_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Geometry_Columns_Stddev_Samp_Fields = {
  __typename?: 'geometry_columns_stddev_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "geometry_columns" */
export type Geometry_Columns_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Geometry_Columns_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Geometry_Columns_Stream_Cursor_Value_Input = {
  coord_dimension?: InputMaybe<Scalars['Int']['input']>;
  f_geometry_column?: InputMaybe<Scalars['name']['input']>;
  f_table_catalog?: InputMaybe<Scalars['String']['input']>;
  f_table_name?: InputMaybe<Scalars['name']['input']>;
  f_table_schema?: InputMaybe<Scalars['name']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Geometry_Columns_Sum_Fields = {
  __typename?: 'geometry_columns_sum_fields';
  coord_dimension?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
};

export type Geometry_Columns_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Geometry_Columns_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Geometry_Columns_Set_Input>;
  /** filter the rows which have to be updated */
  where: Geometry_Columns_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Geometry_Columns_Var_Pop_Fields = {
  __typename?: 'geometry_columns_var_pop_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Geometry_Columns_Var_Samp_Fields = {
  __typename?: 'geometry_columns_var_samp_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Geometry_Columns_Variance_Fields = {
  __typename?: 'geometry_columns_variance_fields';
  coord_dimension?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "geometry". All fields are combined with logical 'AND'. */
export type Geometry_Comparison_Exp = {
  _cast?: InputMaybe<Geometry_Cast_Exp>;
  _eq?: InputMaybe<Scalars['geometry']['input']>;
  _gt?: InputMaybe<Scalars['geometry']['input']>;
  _gte?: InputMaybe<Scalars['geometry']['input']>;
  _in?: InputMaybe<Array<Scalars['geometry']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['geometry']['input']>;
  _lte?: InputMaybe<Scalars['geometry']['input']>;
  _neq?: InputMaybe<Scalars['geometry']['input']>;
  _nin?: InputMaybe<Array<Scalars['geometry']['input']>>;
  /** is the column within a given 3D distance from the given geometry value */
  _st_3d_d_within?: InputMaybe<St_D_Within_Input>;
  /** does the column spatially intersect the given geometry value in 3D */
  _st_3d_intersects?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column contain the given geometry value */
  _st_contains?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column cross the given geometry value */
  _st_crosses?: InputMaybe<Scalars['geometry']['input']>;
  /** is the column within a given distance from the given geometry value */
  _st_d_within?: InputMaybe<St_D_Within_Input>;
  /** is the column equal to given geometry value (directionality is ignored) */
  _st_equals?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column spatially intersect the given geometry value */
  _st_intersects?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column 'spatially overlap' (intersect but not completely contain) the given geometry value */
  _st_overlaps?: InputMaybe<Scalars['geometry']['input']>;
  /** does the column have atleast one point in common with the given geometry value */
  _st_touches?: InputMaybe<Scalars['geometry']['input']>;
  /** is the column contained in the given geometry value */
  _st_within?: InputMaybe<Scalars['geometry']['input']>;
};

export type Get_Reel_With_Related_Args = {
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  max_distance?: InputMaybe<Scalars['Int']['input']>;
  reelid?: InputMaybe<Scalars['Int']['input']>;
};

export type Get_Reels_Args = {
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  max_distance?: InputMaybe<Scalars['Int']['input']>;
};

/** Boolean expression to compare columns of type "json". All fields are combined with logical 'AND'. */
export type Json_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['json']['input']>;
  _gt?: InputMaybe<Scalars['json']['input']>;
  _gte?: InputMaybe<Scalars['json']['input']>;
  _in?: InputMaybe<Array<Scalars['json']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['json']['input']>;
  _lte?: InputMaybe<Scalars['json']['input']>;
  _neq?: InputMaybe<Scalars['json']['input']>;
  _nin?: InputMaybe<Array<Scalars['json']['input']>>;
};

export type Jsonb_Cast_Exp = {
  String?: InputMaybe<String_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type Jsonb_Comparison_Exp = {
  _cast?: InputMaybe<Jsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the column contain the given json value at the top level */
  _contains?: InputMaybe<Scalars['jsonb']['input']>;
  _eq?: InputMaybe<Scalars['jsonb']['input']>;
  _gt?: InputMaybe<Scalars['jsonb']['input']>;
  _gte?: InputMaybe<Scalars['jsonb']['input']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: InputMaybe<Scalars['String']['input']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: InputMaybe<Array<Scalars['String']['input']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: InputMaybe<Array<Scalars['String']['input']>>;
  _in?: InputMaybe<Array<Scalars['jsonb']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['jsonb']['input']>;
  _lte?: InputMaybe<Scalars['jsonb']['input']>;
  _neq?: InputMaybe<Scalars['jsonb']['input']>;
  _nin?: InputMaybe<Array<Scalars['jsonb']['input']>>;
};

/** columns and relationships of "last_known_locations" */
export type Last_Known_Locations = {
  __typename?: 'last_known_locations';
  /** An object relationship */
  accountByUserAccountId?: Maybe<Accounts>;
  address?: Maybe<Scalars['String']['output']>;
  address_components?: Maybe<Scalars['json']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  distance_kms?: Maybe<Scalars['Float']['output']>;
  id: Scalars['bpchar']['output'];
  is_active?: Maybe<Scalars['Boolean']['output']>;
  lat: Scalars['float8']['output'];
  lng: Scalars['float8']['output'];
  location?: Maybe<Scalars['geography']['output']>;
  request_account_ids?: Maybe<Scalars['jsonb']['output']>;
  request_created_at?: Maybe<Scalars['timestamptz']['output']>;
  request_farmer_id?: Maybe<Scalars['bpchar']['output']>;
  request_field_force_ids?: Maybe<Scalars['jsonb']['output']>;
  request_id?: Maybe<Scalars['bpchar']['output']>;
  role_name?: Maybe<Scalars['String']['output']>;
  /** An object relationship */
  service_request?: Maybe<Service_Requests>;
  type: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user?: Maybe<Users>;
  /** An object relationship */
  userByRequestFarmerId?: Maybe<Users>;
  user_account_id?: Maybe<Scalars['bpchar']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};


/** columns and relationships of "last_known_locations" */
export type Last_Known_LocationsAddress_ComponentsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "last_known_locations" */
export type Last_Known_LocationsRequest_Account_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "last_known_locations" */
export type Last_Known_LocationsRequest_Field_Force_IdsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "last_known_locations" */
export type Last_Known_Locations_Aggregate = {
  __typename?: 'last_known_locations_aggregate';
  aggregate?: Maybe<Last_Known_Locations_Aggregate_Fields>;
  nodes: Array<Last_Known_Locations>;
};

export type Last_Known_Locations_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp_Var_Samp>;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Avg = {
  arguments: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Bool_And = {
  arguments: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Corr = {
  arguments: Last_Known_Locations_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Last_Known_Locations_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Max = {
  arguments: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Min = {
  arguments: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Sum = {
  arguments: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Last_Known_Locations_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "last_known_locations" */
export type Last_Known_Locations_Aggregate_Fields = {
  __typename?: 'last_known_locations_aggregate_fields';
  avg?: Maybe<Last_Known_Locations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Last_Known_Locations_Max_Fields>;
  min?: Maybe<Last_Known_Locations_Min_Fields>;
  stddev?: Maybe<Last_Known_Locations_Stddev_Fields>;
  stddev_pop?: Maybe<Last_Known_Locations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Last_Known_Locations_Stddev_Samp_Fields>;
  sum?: Maybe<Last_Known_Locations_Sum_Fields>;
  var_pop?: Maybe<Last_Known_Locations_Var_Pop_Fields>;
  var_samp?: Maybe<Last_Known_Locations_Var_Samp_Fields>;
  variance?: Maybe<Last_Known_Locations_Variance_Fields>;
};


/** aggregate fields of "last_known_locations" */
export type Last_Known_Locations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "last_known_locations" */
export type Last_Known_Locations_Aggregate_Order_By = {
  avg?: InputMaybe<Last_Known_Locations_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Last_Known_Locations_Max_Order_By>;
  min?: InputMaybe<Last_Known_Locations_Min_Order_By>;
  stddev?: InputMaybe<Last_Known_Locations_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Last_Known_Locations_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Last_Known_Locations_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Last_Known_Locations_Sum_Order_By>;
  var_pop?: InputMaybe<Last_Known_Locations_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Last_Known_Locations_Var_Samp_Order_By>;
  variance?: InputMaybe<Last_Known_Locations_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Last_Known_Locations_Append_Input = {
  request_account_ids?: InputMaybe<Scalars['jsonb']['input']>;
  request_field_force_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "last_known_locations" */
export type Last_Known_Locations_Arr_Rel_Insert_Input = {
  data: Array<Last_Known_Locations_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Last_Known_Locations_On_Conflict>;
};

/** aggregate avg on columns */
export type Last_Known_Locations_Avg_Fields = {
  __typename?: 'last_known_locations_avg_fields';
  distance_kms?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "last_known_locations" */
export type Last_Known_Locations_Avg_Order_By = {
  distance_kms?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "last_known_locations". All fields are combined with a logical 'AND'. */
export type Last_Known_Locations_Bool_Exp = {
  _and?: InputMaybe<Array<Last_Known_Locations_Bool_Exp>>;
  _not?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  _or?: InputMaybe<Array<Last_Known_Locations_Bool_Exp>>;
  accountByUserAccountId?: InputMaybe<Accounts_Bool_Exp>;
  address?: InputMaybe<String_Comparison_Exp>;
  address_components?: InputMaybe<Json_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  distance_kms?: InputMaybe<Float_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  is_active?: InputMaybe<Boolean_Comparison_Exp>;
  lat?: InputMaybe<Float8_Comparison_Exp>;
  lng?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Geography_Comparison_Exp>;
  request_account_ids?: InputMaybe<Jsonb_Comparison_Exp>;
  request_created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  request_farmer_id?: InputMaybe<Bpchar_Comparison_Exp>;
  request_field_force_ids?: InputMaybe<Jsonb_Comparison_Exp>;
  request_id?: InputMaybe<Bpchar_Comparison_Exp>;
  role_name?: InputMaybe<String_Comparison_Exp>;
  service_request?: InputMaybe<Service_Requests_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  userByRequestFarmerId?: InputMaybe<Users_Bool_Exp>;
  user_account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "last_known_locations" */
export enum Last_Known_Locations_Constraint {
  /** unique or primary key constraint on columns "id" */
  LastKnownLocationsPkey = 'last_known_locations_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Last_Known_Locations_Delete_At_Path_Input = {
  request_account_ids?: InputMaybe<Array<Scalars['String']['input']>>;
  request_field_force_ids?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Last_Known_Locations_Delete_Elem_Input = {
  request_account_ids?: InputMaybe<Scalars['Int']['input']>;
  request_field_force_ids?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Last_Known_Locations_Delete_Key_Input = {
  request_account_ids?: InputMaybe<Scalars['String']['input']>;
  request_field_force_ids?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "last_known_locations" */
export type Last_Known_Locations_Inc_Input = {
  distance_kms?: InputMaybe<Scalars['Float']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "last_known_locations" */
export type Last_Known_Locations_Insert_Input = {
  accountByUserAccountId?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  address?: InputMaybe<Scalars['String']['input']>;
  address_components?: InputMaybe<Scalars['json']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  distance_kms?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  request_account_ids?: InputMaybe<Scalars['jsonb']['input']>;
  request_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  request_farmer_id?: InputMaybe<Scalars['bpchar']['input']>;
  request_field_force_ids?: InputMaybe<Scalars['jsonb']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  role_name?: InputMaybe<Scalars['String']['input']>;
  service_request?: InputMaybe<Service_Requests_Obj_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  userByRequestFarmerId?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_account_id?: InputMaybe<Scalars['bpchar']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type Last_Known_Locations_Max_Fields = {
  __typename?: 'last_known_locations_max_fields';
  address?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  distance_kms?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  request_created_at?: Maybe<Scalars['timestamptz']['output']>;
  request_farmer_id?: Maybe<Scalars['bpchar']['output']>;
  request_id?: Maybe<Scalars['bpchar']['output']>;
  role_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_account_id?: Maybe<Scalars['bpchar']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by max() on columns of table "last_known_locations" */
export type Last_Known_Locations_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  distance_kms?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  request_created_at?: InputMaybe<Order_By>;
  request_farmer_id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  role_name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_account_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Last_Known_Locations_Min_Fields = {
  __typename?: 'last_known_locations_min_fields';
  address?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  distance_kms?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  request_created_at?: Maybe<Scalars['timestamptz']['output']>;
  request_farmer_id?: Maybe<Scalars['bpchar']['output']>;
  request_id?: Maybe<Scalars['bpchar']['output']>;
  role_name?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_account_id?: Maybe<Scalars['bpchar']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by min() on columns of table "last_known_locations" */
export type Last_Known_Locations_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  distance_kms?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  request_created_at?: InputMaybe<Order_By>;
  request_farmer_id?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  role_name?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_account_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "last_known_locations" */
export type Last_Known_Locations_Mutation_Response = {
  __typename?: 'last_known_locations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Last_Known_Locations>;
};

/** on_conflict condition type for table "last_known_locations" */
export type Last_Known_Locations_On_Conflict = {
  constraint: Last_Known_Locations_Constraint;
  update_columns?: Array<Last_Known_Locations_Update_Column>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};

/** Ordering options when selecting data from "last_known_locations". */
export type Last_Known_Locations_Order_By = {
  accountByUserAccountId?: InputMaybe<Accounts_Order_By>;
  address?: InputMaybe<Order_By>;
  address_components?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  distance_kms?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_active?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  request_account_ids?: InputMaybe<Order_By>;
  request_created_at?: InputMaybe<Order_By>;
  request_farmer_id?: InputMaybe<Order_By>;
  request_field_force_ids?: InputMaybe<Order_By>;
  request_id?: InputMaybe<Order_By>;
  role_name?: InputMaybe<Order_By>;
  service_request?: InputMaybe<Service_Requests_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  userByRequestFarmerId?: InputMaybe<Users_Order_By>;
  user_account_id?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: last_known_locations */
export type Last_Known_Locations_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Last_Known_Locations_Prepend_Input = {
  request_account_ids?: InputMaybe<Scalars['jsonb']['input']>;
  request_field_force_ids?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "last_known_locations" */
export enum Last_Known_Locations_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressComponents = 'address_components',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DistanceKms = 'distance_kms',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Location = 'location',
  /** column name */
  RequestAccountIds = 'request_account_ids',
  /** column name */
  RequestCreatedAt = 'request_created_at',
  /** column name */
  RequestFarmerId = 'request_farmer_id',
  /** column name */
  RequestFieldForceIds = 'request_field_force_ids',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  RoleName = 'role_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserAccountId = 'user_account_id',
  /** column name */
  UserId = 'user_id'
}

/** select "last_known_locations_aggregate_bool_exp_avg_arguments_columns" columns of table "last_known_locations" */
export enum Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "last_known_locations_aggregate_bool_exp_bool_and_arguments_columns" columns of table "last_known_locations" */
export enum Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsActive = 'is_active'
}

/** select "last_known_locations_aggregate_bool_exp_bool_or_arguments_columns" columns of table "last_known_locations" */
export enum Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsActive = 'is_active'
}

/** select "last_known_locations_aggregate_bool_exp_corr_arguments_columns" columns of table "last_known_locations" */
export enum Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "last_known_locations_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "last_known_locations" */
export enum Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "last_known_locations_aggregate_bool_exp_max_arguments_columns" columns of table "last_known_locations" */
export enum Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "last_known_locations_aggregate_bool_exp_min_arguments_columns" columns of table "last_known_locations" */
export enum Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "last_known_locations_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "last_known_locations" */
export enum Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "last_known_locations_aggregate_bool_exp_sum_arguments_columns" columns of table "last_known_locations" */
export enum Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "last_known_locations_aggregate_bool_exp_var_samp_arguments_columns" columns of table "last_known_locations" */
export enum Last_Known_Locations_Select_Column_Last_Known_Locations_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** input type for updating data in table "last_known_locations" */
export type Last_Known_Locations_Set_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_components?: InputMaybe<Scalars['json']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  distance_kms?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  request_account_ids?: InputMaybe<Scalars['jsonb']['input']>;
  request_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  request_farmer_id?: InputMaybe<Scalars['bpchar']['input']>;
  request_field_force_ids?: InputMaybe<Scalars['jsonb']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  role_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_account_id?: InputMaybe<Scalars['bpchar']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type Last_Known_Locations_Stddev_Fields = {
  __typename?: 'last_known_locations_stddev_fields';
  distance_kms?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "last_known_locations" */
export type Last_Known_Locations_Stddev_Order_By = {
  distance_kms?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Last_Known_Locations_Stddev_Pop_Fields = {
  __typename?: 'last_known_locations_stddev_pop_fields';
  distance_kms?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "last_known_locations" */
export type Last_Known_Locations_Stddev_Pop_Order_By = {
  distance_kms?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Last_Known_Locations_Stddev_Samp_Fields = {
  __typename?: 'last_known_locations_stddev_samp_fields';
  distance_kms?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "last_known_locations" */
export type Last_Known_Locations_Stddev_Samp_Order_By = {
  distance_kms?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "last_known_locations" */
export type Last_Known_Locations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Last_Known_Locations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Last_Known_Locations_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_components?: InputMaybe<Scalars['json']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  distance_kms?: InputMaybe<Scalars['Float']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_active?: InputMaybe<Scalars['Boolean']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  request_account_ids?: InputMaybe<Scalars['jsonb']['input']>;
  request_created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  request_farmer_id?: InputMaybe<Scalars['bpchar']['input']>;
  request_field_force_ids?: InputMaybe<Scalars['jsonb']['input']>;
  request_id?: InputMaybe<Scalars['bpchar']['input']>;
  role_name?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_account_id?: InputMaybe<Scalars['bpchar']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type Last_Known_Locations_Sum_Fields = {
  __typename?: 'last_known_locations_sum_fields';
  distance_kms?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "last_known_locations" */
export type Last_Known_Locations_Sum_Order_By = {
  distance_kms?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** update columns of table "last_known_locations" */
export enum Last_Known_Locations_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressComponents = 'address_components',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DistanceKms = 'distance_kms',
  /** column name */
  Id = 'id',
  /** column name */
  IsActive = 'is_active',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Location = 'location',
  /** column name */
  RequestAccountIds = 'request_account_ids',
  /** column name */
  RequestCreatedAt = 'request_created_at',
  /** column name */
  RequestFarmerId = 'request_farmer_id',
  /** column name */
  RequestFieldForceIds = 'request_field_force_ids',
  /** column name */
  RequestId = 'request_id',
  /** column name */
  RoleName = 'role_name',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserAccountId = 'user_account_id',
  /** column name */
  UserId = 'user_id'
}

export type Last_Known_Locations_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Last_Known_Locations_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Last_Known_Locations_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Last_Known_Locations_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Last_Known_Locations_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Last_Known_Locations_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Last_Known_Locations_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Last_Known_Locations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Last_Known_Locations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Last_Known_Locations_Var_Pop_Fields = {
  __typename?: 'last_known_locations_var_pop_fields';
  distance_kms?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "last_known_locations" */
export type Last_Known_Locations_Var_Pop_Order_By = {
  distance_kms?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Last_Known_Locations_Var_Samp_Fields = {
  __typename?: 'last_known_locations_var_samp_fields';
  distance_kms?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "last_known_locations" */
export type Last_Known_Locations_Var_Samp_Order_By = {
  distance_kms?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Last_Known_Locations_Variance_Fields = {
  __typename?: 'last_known_locations_variance_fields';
  distance_kms?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "last_known_locations" */
export type Last_Known_Locations_Variance_Order_By = {
  distance_kms?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** columns and relationships of "master_crops" */
export type Master_Crops = {
  __typename?: 'master_crops';
  /** An array relationship */
  account_crops: Array<Account_Crop>;
  /** An aggregate relationship */
  account_crops_aggregate: Account_Crop_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  crop_users: Array<Crop_User>;
  /** An aggregate relationship */
  crop_users_aggregate: Crop_User_Aggregate;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  display_order: Scalars['Int']['output'];
  /** An array relationship */
  farmer_plots: Array<Farmer_Plots>;
  /** An aggregate relationship */
  farmer_plots_aggregate: Farmer_Plots_Aggregate;
  id: Scalars['bpchar']['output'];
  image_url?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  /** An array relationship */
  service_requests: Array<Service_Requests>;
  /** An aggregate relationship */
  service_requests_aggregate: Service_Requests_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "master_crops" */
export type Master_CropsAccount_CropsArgs = {
  distinct_on?: InputMaybe<Array<Account_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Crop_Order_By>>;
  where?: InputMaybe<Account_Crop_Bool_Exp>;
};


/** columns and relationships of "master_crops" */
export type Master_CropsAccount_Crops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Crop_Order_By>>;
  where?: InputMaybe<Account_Crop_Bool_Exp>;
};


/** columns and relationships of "master_crops" */
export type Master_CropsCrop_UsersArgs = {
  distinct_on?: InputMaybe<Array<Crop_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Crop_User_Order_By>>;
  where?: InputMaybe<Crop_User_Bool_Exp>;
};


/** columns and relationships of "master_crops" */
export type Master_CropsCrop_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Crop_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Crop_User_Order_By>>;
  where?: InputMaybe<Crop_User_Bool_Exp>;
};


/** columns and relationships of "master_crops" */
export type Master_CropsFarmer_PlotsArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Plots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Plots_Order_By>>;
  where?: InputMaybe<Farmer_Plots_Bool_Exp>;
};


/** columns and relationships of "master_crops" */
export type Master_CropsFarmer_Plots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Plots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Plots_Order_By>>;
  where?: InputMaybe<Farmer_Plots_Bool_Exp>;
};


/** columns and relationships of "master_crops" */
export type Master_CropsService_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Service_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Requests_Order_By>>;
  where?: InputMaybe<Service_Requests_Bool_Exp>;
};


/** columns and relationships of "master_crops" */
export type Master_CropsService_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Requests_Order_By>>;
  where?: InputMaybe<Service_Requests_Bool_Exp>;
};

/** aggregated selection of "master_crops" */
export type Master_Crops_Aggregate = {
  __typename?: 'master_crops_aggregate';
  aggregate?: Maybe<Master_Crops_Aggregate_Fields>;
  nodes: Array<Master_Crops>;
};

/** aggregate fields of "master_crops" */
export type Master_Crops_Aggregate_Fields = {
  __typename?: 'master_crops_aggregate_fields';
  avg?: Maybe<Master_Crops_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Master_Crops_Max_Fields>;
  min?: Maybe<Master_Crops_Min_Fields>;
  stddev?: Maybe<Master_Crops_Stddev_Fields>;
  stddev_pop?: Maybe<Master_Crops_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Master_Crops_Stddev_Samp_Fields>;
  sum?: Maybe<Master_Crops_Sum_Fields>;
  var_pop?: Maybe<Master_Crops_Var_Pop_Fields>;
  var_samp?: Maybe<Master_Crops_Var_Samp_Fields>;
  variance?: Maybe<Master_Crops_Variance_Fields>;
};


/** aggregate fields of "master_crops" */
export type Master_Crops_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Crops_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Master_Crops_Avg_Fields = {
  __typename?: 'master_crops_avg_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "master_crops". All fields are combined with a logical 'AND'. */
export type Master_Crops_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Crops_Bool_Exp>>;
  _not?: InputMaybe<Master_Crops_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Crops_Bool_Exp>>;
  account_crops?: InputMaybe<Account_Crop_Bool_Exp>;
  account_crops_aggregate?: InputMaybe<Account_Crop_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crop_users?: InputMaybe<Crop_User_Bool_Exp>;
  crop_users_aggregate?: InputMaybe<Crop_User_Aggregate_Bool_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  display_order?: InputMaybe<Int_Comparison_Exp>;
  farmer_plots?: InputMaybe<Farmer_Plots_Bool_Exp>;
  farmer_plots_aggregate?: InputMaybe<Farmer_Plots_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  image_url?: InputMaybe<String_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  service_requests?: InputMaybe<Service_Requests_Bool_Exp>;
  service_requests_aggregate?: InputMaybe<Service_Requests_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "master_crops" */
export enum Master_Crops_Constraint {
  /** unique or primary key constraint on columns "name" */
  MasterCropsNameIdx = 'master_crops_name_idx',
  /** unique or primary key constraint on columns "id" */
  MasterCropsPkey = 'master_crops_pkey'
}

/** input type for incrementing numeric columns in table "master_crops" */
export type Master_Crops_Inc_Input = {
  display_order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "master_crops" */
export type Master_Crops_Insert_Input = {
  account_crops?: InputMaybe<Account_Crop_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_users?: InputMaybe<Crop_User_Arr_Rel_Insert_Input>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_order?: InputMaybe<Scalars['Int']['input']>;
  farmer_plots?: InputMaybe<Farmer_Plots_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  service_requests?: InputMaybe<Service_Requests_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Master_Crops_Max_Fields = {
  __typename?: 'master_crops_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  display_order?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Master_Crops_Min_Fields = {
  __typename?: 'master_crops_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  display_order?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  image_url?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "master_crops" */
export type Master_Crops_Mutation_Response = {
  __typename?: 'master_crops_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Crops>;
};

/** input type for inserting object relation for remote table "master_crops" */
export type Master_Crops_Obj_Rel_Insert_Input = {
  data: Master_Crops_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Crops_On_Conflict>;
};

/** on_conflict condition type for table "master_crops" */
export type Master_Crops_On_Conflict = {
  constraint: Master_Crops_Constraint;
  update_columns?: Array<Master_Crops_Update_Column>;
  where?: InputMaybe<Master_Crops_Bool_Exp>;
};

/** Ordering options when selecting data from "master_crops". */
export type Master_Crops_Order_By = {
  account_crops_aggregate?: InputMaybe<Account_Crop_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  crop_users_aggregate?: InputMaybe<Crop_User_Aggregate_Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  display_order?: InputMaybe<Order_By>;
  farmer_plots_aggregate?: InputMaybe<Farmer_Plots_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  image_url?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  service_requests_aggregate?: InputMaybe<Service_Requests_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: master_crops */
export type Master_Crops_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "master_crops" */
export enum Master_Crops_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "master_crops" */
export type Master_Crops_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_order?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Master_Crops_Stddev_Fields = {
  __typename?: 'master_crops_stddev_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Master_Crops_Stddev_Pop_Fields = {
  __typename?: 'master_crops_stddev_pop_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Master_Crops_Stddev_Samp_Fields = {
  __typename?: 'master_crops_stddev_samp_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "master_crops" */
export type Master_Crops_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Crops_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Crops_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_order?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  image_url?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Master_Crops_Sum_Fields = {
  __typename?: 'master_crops_sum_fields';
  display_order?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "master_crops" */
export enum Master_Crops_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Id = 'id',
  /** column name */
  ImageUrl = 'image_url',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Master_Crops_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Master_Crops_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Crops_Set_Input>;
  /** filter the rows which have to be updated */
  where: Master_Crops_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Master_Crops_Var_Pop_Fields = {
  __typename?: 'master_crops_var_pop_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Master_Crops_Var_Samp_Fields = {
  __typename?: 'master_crops_var_samp_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Master_Crops_Variance_Fields = {
  __typename?: 'master_crops_variance_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "master_segments" */
export type Master_Segments = {
  __typename?: 'master_segments';
  /** An array relationship */
  account_segments: Array<Account_Segment>;
  /** An aggregate relationship */
  account_segments_aggregate: Account_Segment_Aggregate;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  display_order: Scalars['Int']['output'];
  id: Scalars['bpchar']['output'];
  name: Scalars['String']['output'];
  /** An array relationship */
  segment_users: Array<Segment_User>;
  /** An aggregate relationship */
  segment_users_aggregate: Segment_User_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};


/** columns and relationships of "master_segments" */
export type Master_SegmentsAccount_SegmentsArgs = {
  distinct_on?: InputMaybe<Array<Account_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Segment_Order_By>>;
  where?: InputMaybe<Account_Segment_Bool_Exp>;
};


/** columns and relationships of "master_segments" */
export type Master_SegmentsAccount_Segments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Segment_Order_By>>;
  where?: InputMaybe<Account_Segment_Bool_Exp>;
};


/** columns and relationships of "master_segments" */
export type Master_SegmentsSegment_UsersArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Segment_User_Order_By>>;
  where?: InputMaybe<Segment_User_Bool_Exp>;
};


/** columns and relationships of "master_segments" */
export type Master_SegmentsSegment_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Segment_User_Order_By>>;
  where?: InputMaybe<Segment_User_Bool_Exp>;
};

/** aggregated selection of "master_segments" */
export type Master_Segments_Aggregate = {
  __typename?: 'master_segments_aggregate';
  aggregate?: Maybe<Master_Segments_Aggregate_Fields>;
  nodes: Array<Master_Segments>;
};

/** aggregate fields of "master_segments" */
export type Master_Segments_Aggregate_Fields = {
  __typename?: 'master_segments_aggregate_fields';
  avg?: Maybe<Master_Segments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Master_Segments_Max_Fields>;
  min?: Maybe<Master_Segments_Min_Fields>;
  stddev?: Maybe<Master_Segments_Stddev_Fields>;
  stddev_pop?: Maybe<Master_Segments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Master_Segments_Stddev_Samp_Fields>;
  sum?: Maybe<Master_Segments_Sum_Fields>;
  var_pop?: Maybe<Master_Segments_Var_Pop_Fields>;
  var_samp?: Maybe<Master_Segments_Var_Samp_Fields>;
  variance?: Maybe<Master_Segments_Variance_Fields>;
};


/** aggregate fields of "master_segments" */
export type Master_Segments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Master_Segments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Master_Segments_Avg_Fields = {
  __typename?: 'master_segments_avg_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "master_segments". All fields are combined with a logical 'AND'. */
export type Master_Segments_Bool_Exp = {
  _and?: InputMaybe<Array<Master_Segments_Bool_Exp>>;
  _not?: InputMaybe<Master_Segments_Bool_Exp>;
  _or?: InputMaybe<Array<Master_Segments_Bool_Exp>>;
  account_segments?: InputMaybe<Account_Segment_Bool_Exp>;
  account_segments_aggregate?: InputMaybe<Account_Segment_Aggregate_Bool_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  display_order?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  segment_users?: InputMaybe<Segment_User_Bool_Exp>;
  segment_users_aggregate?: InputMaybe<Segment_User_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "master_segments" */
export enum Master_Segments_Constraint {
  /** unique or primary key constraint on columns "id" */
  MasterSegmentsPkey = 'master_segments_pkey'
}

/** input type for incrementing numeric columns in table "master_segments" */
export type Master_Segments_Inc_Input = {
  display_order?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "master_segments" */
export type Master_Segments_Insert_Input = {
  account_segments?: InputMaybe<Account_Segment_Arr_Rel_Insert_Input>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_order?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  segment_users?: InputMaybe<Segment_User_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Master_Segments_Max_Fields = {
  __typename?: 'master_segments_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  display_order?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Master_Segments_Min_Fields = {
  __typename?: 'master_segments_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  display_order?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "master_segments" */
export type Master_Segments_Mutation_Response = {
  __typename?: 'master_segments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Master_Segments>;
};

/** input type for inserting object relation for remote table "master_segments" */
export type Master_Segments_Obj_Rel_Insert_Input = {
  data: Master_Segments_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Master_Segments_On_Conflict>;
};

/** on_conflict condition type for table "master_segments" */
export type Master_Segments_On_Conflict = {
  constraint: Master_Segments_Constraint;
  update_columns?: Array<Master_Segments_Update_Column>;
  where?: InputMaybe<Master_Segments_Bool_Exp>;
};

/** Ordering options when selecting data from "master_segments". */
export type Master_Segments_Order_By = {
  account_segments_aggregate?: InputMaybe<Account_Segment_Aggregate_Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  display_order?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  segment_users_aggregate?: InputMaybe<Segment_User_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: master_segments */
export type Master_Segments_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "master_segments" */
export enum Master_Segments_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "master_segments" */
export type Master_Segments_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_order?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Master_Segments_Stddev_Fields = {
  __typename?: 'master_segments_stddev_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Master_Segments_Stddev_Pop_Fields = {
  __typename?: 'master_segments_stddev_pop_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Master_Segments_Stddev_Samp_Fields = {
  __typename?: 'master_segments_stddev_samp_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "master_segments" */
export type Master_Segments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Master_Segments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Master_Segments_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  display_order?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Master_Segments_Sum_Fields = {
  __typename?: 'master_segments_sum_fields';
  display_order?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "master_segments" */
export enum Master_Segments_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DisplayOrder = 'display_order',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Master_Segments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Master_Segments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Master_Segments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Master_Segments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Master_Segments_Var_Pop_Fields = {
  __typename?: 'master_segments_var_pop_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Master_Segments_Var_Samp_Fields = {
  __typename?: 'master_segments_var_samp_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Master_Segments_Variance_Fields = {
  __typename?: 'master_segments_variance_fields';
  display_order?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "migrations" */
export type Migrations = {
  __typename?: 'migrations';
  batch: Scalars['Int']['output'];
  id: Scalars['Int']['output'];
  migration: Scalars['String']['output'];
};

/** aggregated selection of "migrations" */
export type Migrations_Aggregate = {
  __typename?: 'migrations_aggregate';
  aggregate?: Maybe<Migrations_Aggregate_Fields>;
  nodes: Array<Migrations>;
};

/** aggregate fields of "migrations" */
export type Migrations_Aggregate_Fields = {
  __typename?: 'migrations_aggregate_fields';
  avg?: Maybe<Migrations_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Migrations_Max_Fields>;
  min?: Maybe<Migrations_Min_Fields>;
  stddev?: Maybe<Migrations_Stddev_Fields>;
  stddev_pop?: Maybe<Migrations_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Migrations_Stddev_Samp_Fields>;
  sum?: Maybe<Migrations_Sum_Fields>;
  var_pop?: Maybe<Migrations_Var_Pop_Fields>;
  var_samp?: Maybe<Migrations_Var_Samp_Fields>;
  variance?: Maybe<Migrations_Variance_Fields>;
};


/** aggregate fields of "migrations" */
export type Migrations_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Migrations_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Migrations_Avg_Fields = {
  __typename?: 'migrations_avg_fields';
  batch?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "migrations". All fields are combined with a logical 'AND'. */
export type Migrations_Bool_Exp = {
  _and?: InputMaybe<Array<Migrations_Bool_Exp>>;
  _not?: InputMaybe<Migrations_Bool_Exp>;
  _or?: InputMaybe<Array<Migrations_Bool_Exp>>;
  batch?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  migration?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "migrations" */
export enum Migrations_Constraint {
  /** unique or primary key constraint on columns "id" */
  MigrationsPkey = 'migrations_pkey'
}

/** input type for incrementing numeric columns in table "migrations" */
export type Migrations_Inc_Input = {
  batch?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "migrations" */
export type Migrations_Insert_Input = {
  batch?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  migration?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Migrations_Max_Fields = {
  __typename?: 'migrations_max_fields';
  batch?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  migration?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Migrations_Min_Fields = {
  __typename?: 'migrations_min_fields';
  batch?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  migration?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "migrations" */
export type Migrations_Mutation_Response = {
  __typename?: 'migrations_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Migrations>;
};

/** on_conflict condition type for table "migrations" */
export type Migrations_On_Conflict = {
  constraint: Migrations_Constraint;
  update_columns?: Array<Migrations_Update_Column>;
  where?: InputMaybe<Migrations_Bool_Exp>;
};

/** Ordering options when selecting data from "migrations". */
export type Migrations_Order_By = {
  batch?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  migration?: InputMaybe<Order_By>;
};

/** primary key columns input for table: migrations */
export type Migrations_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "migrations" */
export enum Migrations_Select_Column {
  /** column name */
  Batch = 'batch',
  /** column name */
  Id = 'id',
  /** column name */
  Migration = 'migration'
}

/** input type for updating data in table "migrations" */
export type Migrations_Set_Input = {
  batch?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  migration?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Migrations_Stddev_Fields = {
  __typename?: 'migrations_stddev_fields';
  batch?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Migrations_Stddev_Pop_Fields = {
  __typename?: 'migrations_stddev_pop_fields';
  batch?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Migrations_Stddev_Samp_Fields = {
  __typename?: 'migrations_stddev_samp_fields';
  batch?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "migrations" */
export type Migrations_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Migrations_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Migrations_Stream_Cursor_Value_Input = {
  batch?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  migration?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Migrations_Sum_Fields = {
  __typename?: 'migrations_sum_fields';
  batch?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "migrations" */
export enum Migrations_Update_Column {
  /** column name */
  Batch = 'batch',
  /** column name */
  Id = 'id',
  /** column name */
  Migration = 'migration'
}

export type Migrations_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Migrations_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Migrations_Set_Input>;
  /** filter the rows which have to be updated */
  where: Migrations_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Migrations_Var_Pop_Fields = {
  __typename?: 'migrations_var_pop_fields';
  batch?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Migrations_Var_Samp_Fields = {
  __typename?: 'migrations_var_samp_fields';
  batch?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Migrations_Variance_Fields = {
  __typename?: 'migrations_variance_fields';
  batch?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "model_has_permissions" */
export type Model_Has_Permissions = {
  __typename?: 'model_has_permissions';
  model_id: Scalars['String']['output'];
  model_type: Scalars['String']['output'];
  /** An object relationship */
  permission: Permissions;
  permission_id: Scalars['bigint']['output'];
};

/** aggregated selection of "model_has_permissions" */
export type Model_Has_Permissions_Aggregate = {
  __typename?: 'model_has_permissions_aggregate';
  aggregate?: Maybe<Model_Has_Permissions_Aggregate_Fields>;
  nodes: Array<Model_Has_Permissions>;
};

export type Model_Has_Permissions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Model_Has_Permissions_Aggregate_Bool_Exp_Count>;
};

export type Model_Has_Permissions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Model_Has_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Model_Has_Permissions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "model_has_permissions" */
export type Model_Has_Permissions_Aggregate_Fields = {
  __typename?: 'model_has_permissions_aggregate_fields';
  avg?: Maybe<Model_Has_Permissions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Model_Has_Permissions_Max_Fields>;
  min?: Maybe<Model_Has_Permissions_Min_Fields>;
  stddev?: Maybe<Model_Has_Permissions_Stddev_Fields>;
  stddev_pop?: Maybe<Model_Has_Permissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Model_Has_Permissions_Stddev_Samp_Fields>;
  sum?: Maybe<Model_Has_Permissions_Sum_Fields>;
  var_pop?: Maybe<Model_Has_Permissions_Var_Pop_Fields>;
  var_samp?: Maybe<Model_Has_Permissions_Var_Samp_Fields>;
  variance?: Maybe<Model_Has_Permissions_Variance_Fields>;
};


/** aggregate fields of "model_has_permissions" */
export type Model_Has_Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Model_Has_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "model_has_permissions" */
export type Model_Has_Permissions_Aggregate_Order_By = {
  avg?: InputMaybe<Model_Has_Permissions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Model_Has_Permissions_Max_Order_By>;
  min?: InputMaybe<Model_Has_Permissions_Min_Order_By>;
  stddev?: InputMaybe<Model_Has_Permissions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Model_Has_Permissions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Model_Has_Permissions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Model_Has_Permissions_Sum_Order_By>;
  var_pop?: InputMaybe<Model_Has_Permissions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Model_Has_Permissions_Var_Samp_Order_By>;
  variance?: InputMaybe<Model_Has_Permissions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "model_has_permissions" */
export type Model_Has_Permissions_Arr_Rel_Insert_Input = {
  data: Array<Model_Has_Permissions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Model_Has_Permissions_On_Conflict>;
};

/** aggregate avg on columns */
export type Model_Has_Permissions_Avg_Fields = {
  __typename?: 'model_has_permissions_avg_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "model_has_permissions" */
export type Model_Has_Permissions_Avg_Order_By = {
  permission_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "model_has_permissions". All fields are combined with a logical 'AND'. */
export type Model_Has_Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Model_Has_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Model_Has_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Model_Has_Permissions_Bool_Exp>>;
  model_id?: InputMaybe<String_Comparison_Exp>;
  model_type?: InputMaybe<String_Comparison_Exp>;
  permission?: InputMaybe<Permissions_Bool_Exp>;
  permission_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "model_has_permissions" */
export enum Model_Has_Permissions_Constraint {
  /** unique or primary key constraint on columns "model_id", "permission_id", "model_type" */
  ModelHasPermissionsPkey = 'model_has_permissions_pkey'
}

/** input type for incrementing numeric columns in table "model_has_permissions" */
export type Model_Has_Permissions_Inc_Input = {
  permission_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "model_has_permissions" */
export type Model_Has_Permissions_Insert_Input = {
  model_id?: InputMaybe<Scalars['String']['input']>;
  model_type?: InputMaybe<Scalars['String']['input']>;
  permission?: InputMaybe<Permissions_Obj_Rel_Insert_Input>;
  permission_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Model_Has_Permissions_Max_Fields = {
  __typename?: 'model_has_permissions_max_fields';
  model_id?: Maybe<Scalars['String']['output']>;
  model_type?: Maybe<Scalars['String']['output']>;
  permission_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "model_has_permissions" */
export type Model_Has_Permissions_Max_Order_By = {
  model_id?: InputMaybe<Order_By>;
  model_type?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Model_Has_Permissions_Min_Fields = {
  __typename?: 'model_has_permissions_min_fields';
  model_id?: Maybe<Scalars['String']['output']>;
  model_type?: Maybe<Scalars['String']['output']>;
  permission_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "model_has_permissions" */
export type Model_Has_Permissions_Min_Order_By = {
  model_id?: InputMaybe<Order_By>;
  model_type?: InputMaybe<Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "model_has_permissions" */
export type Model_Has_Permissions_Mutation_Response = {
  __typename?: 'model_has_permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Model_Has_Permissions>;
};

/** on_conflict condition type for table "model_has_permissions" */
export type Model_Has_Permissions_On_Conflict = {
  constraint: Model_Has_Permissions_Constraint;
  update_columns?: Array<Model_Has_Permissions_Update_Column>;
  where?: InputMaybe<Model_Has_Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "model_has_permissions". */
export type Model_Has_Permissions_Order_By = {
  model_id?: InputMaybe<Order_By>;
  model_type?: InputMaybe<Order_By>;
  permission?: InputMaybe<Permissions_Order_By>;
  permission_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: model_has_permissions */
export type Model_Has_Permissions_Pk_Columns_Input = {
  model_id: Scalars['String']['input'];
  model_type: Scalars['String']['input'];
  permission_id: Scalars['bigint']['input'];
};

/** select columns of table "model_has_permissions" */
export enum Model_Has_Permissions_Select_Column {
  /** column name */
  ModelId = 'model_id',
  /** column name */
  ModelType = 'model_type',
  /** column name */
  PermissionId = 'permission_id'
}

/** input type for updating data in table "model_has_permissions" */
export type Model_Has_Permissions_Set_Input = {
  model_id?: InputMaybe<Scalars['String']['input']>;
  model_type?: InputMaybe<Scalars['String']['input']>;
  permission_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Model_Has_Permissions_Stddev_Fields = {
  __typename?: 'model_has_permissions_stddev_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "model_has_permissions" */
export type Model_Has_Permissions_Stddev_Order_By = {
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Model_Has_Permissions_Stddev_Pop_Fields = {
  __typename?: 'model_has_permissions_stddev_pop_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "model_has_permissions" */
export type Model_Has_Permissions_Stddev_Pop_Order_By = {
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Model_Has_Permissions_Stddev_Samp_Fields = {
  __typename?: 'model_has_permissions_stddev_samp_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "model_has_permissions" */
export type Model_Has_Permissions_Stddev_Samp_Order_By = {
  permission_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "model_has_permissions" */
export type Model_Has_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Model_Has_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Model_Has_Permissions_Stream_Cursor_Value_Input = {
  model_id?: InputMaybe<Scalars['String']['input']>;
  model_type?: InputMaybe<Scalars['String']['input']>;
  permission_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Model_Has_Permissions_Sum_Fields = {
  __typename?: 'model_has_permissions_sum_fields';
  permission_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "model_has_permissions" */
export type Model_Has_Permissions_Sum_Order_By = {
  permission_id?: InputMaybe<Order_By>;
};

/** update columns of table "model_has_permissions" */
export enum Model_Has_Permissions_Update_Column {
  /** column name */
  ModelId = 'model_id',
  /** column name */
  ModelType = 'model_type',
  /** column name */
  PermissionId = 'permission_id'
}

export type Model_Has_Permissions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Model_Has_Permissions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Model_Has_Permissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Model_Has_Permissions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Model_Has_Permissions_Var_Pop_Fields = {
  __typename?: 'model_has_permissions_var_pop_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "model_has_permissions" */
export type Model_Has_Permissions_Var_Pop_Order_By = {
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Model_Has_Permissions_Var_Samp_Fields = {
  __typename?: 'model_has_permissions_var_samp_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "model_has_permissions" */
export type Model_Has_Permissions_Var_Samp_Order_By = {
  permission_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Model_Has_Permissions_Variance_Fields = {
  __typename?: 'model_has_permissions_variance_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "model_has_permissions" */
export type Model_Has_Permissions_Variance_Order_By = {
  permission_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "model_has_roles" */
export type Model_Has_Roles = {
  __typename?: 'model_has_roles';
  model_id: Scalars['String']['output'];
  model_type: Scalars['String']['output'];
  /** An object relationship */
  role: Roles;
  role_id: Scalars['bigint']['output'];
  /** An object relationship */
  user: Users;
};

/** aggregated selection of "model_has_roles" */
export type Model_Has_Roles_Aggregate = {
  __typename?: 'model_has_roles_aggregate';
  aggregate?: Maybe<Model_Has_Roles_Aggregate_Fields>;
  nodes: Array<Model_Has_Roles>;
};

export type Model_Has_Roles_Aggregate_Bool_Exp = {
  count?: InputMaybe<Model_Has_Roles_Aggregate_Bool_Exp_Count>;
};

export type Model_Has_Roles_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Model_Has_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Model_Has_Roles_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "model_has_roles" */
export type Model_Has_Roles_Aggregate_Fields = {
  __typename?: 'model_has_roles_aggregate_fields';
  avg?: Maybe<Model_Has_Roles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Model_Has_Roles_Max_Fields>;
  min?: Maybe<Model_Has_Roles_Min_Fields>;
  stddev?: Maybe<Model_Has_Roles_Stddev_Fields>;
  stddev_pop?: Maybe<Model_Has_Roles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Model_Has_Roles_Stddev_Samp_Fields>;
  sum?: Maybe<Model_Has_Roles_Sum_Fields>;
  var_pop?: Maybe<Model_Has_Roles_Var_Pop_Fields>;
  var_samp?: Maybe<Model_Has_Roles_Var_Samp_Fields>;
  variance?: Maybe<Model_Has_Roles_Variance_Fields>;
};


/** aggregate fields of "model_has_roles" */
export type Model_Has_Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Model_Has_Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "model_has_roles" */
export type Model_Has_Roles_Aggregate_Order_By = {
  avg?: InputMaybe<Model_Has_Roles_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Model_Has_Roles_Max_Order_By>;
  min?: InputMaybe<Model_Has_Roles_Min_Order_By>;
  stddev?: InputMaybe<Model_Has_Roles_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Model_Has_Roles_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Model_Has_Roles_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Model_Has_Roles_Sum_Order_By>;
  var_pop?: InputMaybe<Model_Has_Roles_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Model_Has_Roles_Var_Samp_Order_By>;
  variance?: InputMaybe<Model_Has_Roles_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "model_has_roles" */
export type Model_Has_Roles_Arr_Rel_Insert_Input = {
  data: Array<Model_Has_Roles_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Model_Has_Roles_On_Conflict>;
};

/** aggregate avg on columns */
export type Model_Has_Roles_Avg_Fields = {
  __typename?: 'model_has_roles_avg_fields';
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "model_has_roles" */
export type Model_Has_Roles_Avg_Order_By = {
  role_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "model_has_roles". All fields are combined with a logical 'AND'. */
export type Model_Has_Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Model_Has_Roles_Bool_Exp>>;
  _not?: InputMaybe<Model_Has_Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Model_Has_Roles_Bool_Exp>>;
  model_id?: InputMaybe<String_Comparison_Exp>;
  model_type?: InputMaybe<String_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Bigint_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
};

/** unique or primary key constraints on table "model_has_roles" */
export enum Model_Has_Roles_Constraint {
  /** unique or primary key constraint on columns "role_id", "model_id", "model_type" */
  ModelHasRolesPkey = 'model_has_roles_pkey'
}

/** input type for incrementing numeric columns in table "model_has_roles" */
export type Model_Has_Roles_Inc_Input = {
  role_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "model_has_roles" */
export type Model_Has_Roles_Insert_Input = {
  model_id?: InputMaybe<Scalars['String']['input']>;
  model_type?: InputMaybe<Scalars['String']['input']>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['bigint']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Model_Has_Roles_Max_Fields = {
  __typename?: 'model_has_roles_max_fields';
  model_id?: Maybe<Scalars['String']['output']>;
  model_type?: Maybe<Scalars['String']['output']>;
  role_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "model_has_roles" */
export type Model_Has_Roles_Max_Order_By = {
  model_id?: InputMaybe<Order_By>;
  model_type?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Model_Has_Roles_Min_Fields = {
  __typename?: 'model_has_roles_min_fields';
  model_id?: Maybe<Scalars['String']['output']>;
  model_type?: Maybe<Scalars['String']['output']>;
  role_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "model_has_roles" */
export type Model_Has_Roles_Min_Order_By = {
  model_id?: InputMaybe<Order_By>;
  model_type?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "model_has_roles" */
export type Model_Has_Roles_Mutation_Response = {
  __typename?: 'model_has_roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Model_Has_Roles>;
};

/** on_conflict condition type for table "model_has_roles" */
export type Model_Has_Roles_On_Conflict = {
  constraint: Model_Has_Roles_Constraint;
  update_columns?: Array<Model_Has_Roles_Update_Column>;
  where?: InputMaybe<Model_Has_Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "model_has_roles". */
export type Model_Has_Roles_Order_By = {
  model_id?: InputMaybe<Order_By>;
  model_type?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
};

/** primary key columns input for table: model_has_roles */
export type Model_Has_Roles_Pk_Columns_Input = {
  model_id: Scalars['String']['input'];
  model_type: Scalars['String']['input'];
  role_id: Scalars['bigint']['input'];
};

/** select columns of table "model_has_roles" */
export enum Model_Has_Roles_Select_Column {
  /** column name */
  ModelId = 'model_id',
  /** column name */
  ModelType = 'model_type',
  /** column name */
  RoleId = 'role_id'
}

/** input type for updating data in table "model_has_roles" */
export type Model_Has_Roles_Set_Input = {
  model_id?: InputMaybe<Scalars['String']['input']>;
  model_type?: InputMaybe<Scalars['String']['input']>;
  role_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Model_Has_Roles_Stddev_Fields = {
  __typename?: 'model_has_roles_stddev_fields';
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "model_has_roles" */
export type Model_Has_Roles_Stddev_Order_By = {
  role_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Model_Has_Roles_Stddev_Pop_Fields = {
  __typename?: 'model_has_roles_stddev_pop_fields';
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "model_has_roles" */
export type Model_Has_Roles_Stddev_Pop_Order_By = {
  role_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Model_Has_Roles_Stddev_Samp_Fields = {
  __typename?: 'model_has_roles_stddev_samp_fields';
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "model_has_roles" */
export type Model_Has_Roles_Stddev_Samp_Order_By = {
  role_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "model_has_roles" */
export type Model_Has_Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Model_Has_Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Model_Has_Roles_Stream_Cursor_Value_Input = {
  model_id?: InputMaybe<Scalars['String']['input']>;
  model_type?: InputMaybe<Scalars['String']['input']>;
  role_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Model_Has_Roles_Sum_Fields = {
  __typename?: 'model_has_roles_sum_fields';
  role_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "model_has_roles" */
export type Model_Has_Roles_Sum_Order_By = {
  role_id?: InputMaybe<Order_By>;
};

/** update columns of table "model_has_roles" */
export enum Model_Has_Roles_Update_Column {
  /** column name */
  ModelId = 'model_id',
  /** column name */
  ModelType = 'model_type',
  /** column name */
  RoleId = 'role_id'
}

export type Model_Has_Roles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Model_Has_Roles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Model_Has_Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Model_Has_Roles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Model_Has_Roles_Var_Pop_Fields = {
  __typename?: 'model_has_roles_var_pop_fields';
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "model_has_roles" */
export type Model_Has_Roles_Var_Pop_Order_By = {
  role_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Model_Has_Roles_Var_Samp_Fields = {
  __typename?: 'model_has_roles_var_samp_fields';
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "model_has_roles" */
export type Model_Has_Roles_Var_Samp_Order_By = {
  role_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Model_Has_Roles_Variance_Fields = {
  __typename?: 'model_has_roles_variance_fields';
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "model_has_roles" */
export type Model_Has_Roles_Variance_Order_By = {
  role_id?: InputMaybe<Order_By>;
};

/** mutation root */
export type Mutation_Root = {
  __typename?: 'mutation_root';
  cfUploadData: Array<Scalars['String']['output']>;
  createReel: DefaultCommandOutput;
  createReelCategory: DefaultCommandOutput;
  createReelComment: DefaultCommandOutput;
  deleteTestRequests: DefaultCommandOutput;
  /** delete data from the table: "account_crop" */
  delete_account_crop?: Maybe<Account_Crop_Mutation_Response>;
  /** delete single row from the table: "account_crop" */
  delete_account_crop_by_pk?: Maybe<Account_Crop>;
  /** delete data from the table: "account_segment" */
  delete_account_segment?: Maybe<Account_Segment_Mutation_Response>;
  /** delete single row from the table: "account_segment" */
  delete_account_segment_by_pk?: Maybe<Account_Segment>;
  /** delete data from the table: "account_user" */
  delete_account_user?: Maybe<Account_User_Mutation_Response>;
  /** delete single row from the table: "account_user" */
  delete_account_user_by_pk?: Maybe<Account_User>;
  /** delete data from the table: "accounts" */
  delete_accounts?: Maybe<Accounts_Mutation_Response>;
  /** delete single row from the table: "accounts" */
  delete_accounts_by_pk?: Maybe<Accounts>;
  /** delete data from the table: "action_events" */
  delete_action_events?: Maybe<Action_Events_Mutation_Response>;
  /** delete single row from the table: "action_events" */
  delete_action_events_by_pk?: Maybe<Action_Events>;
  /** delete data from the table: "companies" */
  delete_companies?: Maybe<Companies_Mutation_Response>;
  /** delete single row from the table: "companies" */
  delete_companies_by_pk?: Maybe<Companies>;
  /** delete data from the table: "crop_user" */
  delete_crop_user?: Maybe<Crop_User_Mutation_Response>;
  /** delete single row from the table: "crop_user" */
  delete_crop_user_by_pk?: Maybe<Crop_User>;
  /** delete data from the table: "failed_jobs" */
  delete_failed_jobs?: Maybe<Failed_Jobs_Mutation_Response>;
  /** delete single row from the table: "failed_jobs" */
  delete_failed_jobs_by_pk?: Maybe<Failed_Jobs>;
  /** delete data from the table: "farmer_nearby_field_force" */
  delete_farmer_nearby_field_force?: Maybe<Farmer_Nearby_Field_Force_Mutation_Response>;
  /** delete single row from the table: "farmer_nearby_field_force" */
  delete_farmer_nearby_field_force_by_pk?: Maybe<Farmer_Nearby_Field_Force>;
  /** delete data from the table: "farmer_plots" */
  delete_farmer_plots?: Maybe<Farmer_Plots_Mutation_Response>;
  /** delete single row from the table: "farmer_plots" */
  delete_farmer_plots_by_pk?: Maybe<Farmer_Plots>;
  /** delete data from the table: "field_partner_response_events" */
  delete_field_partner_response_events?: Maybe<Field_Partner_Response_Events_Mutation_Response>;
  /** delete single row from the table: "field_partner_response_events" */
  delete_field_partner_response_events_by_pk?: Maybe<Field_Partner_Response_Events>;
  /** delete data from the table: "field_partner_response_messages" */
  delete_field_partner_response_messages?: Maybe<Field_Partner_Response_Messages_Mutation_Response>;
  /** delete single row from the table: "field_partner_response_messages" */
  delete_field_partner_response_messages_by_pk?: Maybe<Field_Partner_Response_Messages>;
  /** delete data from the table: "field_partner_responses" */
  delete_field_partner_responses?: Maybe<Field_Partner_Responses_Mutation_Response>;
  /** delete single row from the table: "field_partner_responses" */
  delete_field_partner_responses_by_pk?: Maybe<Field_Partner_Responses>;
  /** delete data from the table: "field_scheduled_visits" */
  delete_field_scheduled_visits?: Maybe<Field_Scheduled_Visits_Mutation_Response>;
  /** delete single row from the table: "field_scheduled_visits" */
  delete_field_scheduled_visits_by_pk?: Maybe<Field_Scheduled_Visits>;
  /** delete data from the table: "follows" */
  delete_follows?: Maybe<Follows_Mutation_Response>;
  /** delete single row from the table: "follows" */
  delete_follows_by_pk?: Maybe<Follows>;
  /** delete data from the table: "geometry_columns" */
  delete_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** delete data from the table: "last_known_locations" */
  delete_last_known_locations?: Maybe<Last_Known_Locations_Mutation_Response>;
  /** delete single row from the table: "last_known_locations" */
  delete_last_known_locations_by_pk?: Maybe<Last_Known_Locations>;
  /** delete data from the table: "master_crops" */
  delete_master_crops?: Maybe<Master_Crops_Mutation_Response>;
  /** delete single row from the table: "master_crops" */
  delete_master_crops_by_pk?: Maybe<Master_Crops>;
  /** delete data from the table: "master_segments" */
  delete_master_segments?: Maybe<Master_Segments_Mutation_Response>;
  /** delete single row from the table: "master_segments" */
  delete_master_segments_by_pk?: Maybe<Master_Segments>;
  /** delete data from the table: "migrations" */
  delete_migrations?: Maybe<Migrations_Mutation_Response>;
  /** delete single row from the table: "migrations" */
  delete_migrations_by_pk?: Maybe<Migrations>;
  /** delete data from the table: "model_has_permissions" */
  delete_model_has_permissions?: Maybe<Model_Has_Permissions_Mutation_Response>;
  /** delete single row from the table: "model_has_permissions" */
  delete_model_has_permissions_by_pk?: Maybe<Model_Has_Permissions>;
  /** delete data from the table: "model_has_roles" */
  delete_model_has_roles?: Maybe<Model_Has_Roles_Mutation_Response>;
  /** delete single row from the table: "model_has_roles" */
  delete_model_has_roles_by_pk?: Maybe<Model_Has_Roles>;
  /** delete data from the table: "notifications" */
  delete_notifications?: Maybe<Notifications_Mutation_Response>;
  /** delete single row from the table: "notifications" */
  delete_notifications_by_pk?: Maybe<Notifications>;
  /** delete data from the table: "nova_field_attachments" */
  delete_nova_field_attachments?: Maybe<Nova_Field_Attachments_Mutation_Response>;
  /** delete single row from the table: "nova_field_attachments" */
  delete_nova_field_attachments_by_pk?: Maybe<Nova_Field_Attachments>;
  /** delete data from the table: "nova_notifications" */
  delete_nova_notifications?: Maybe<Nova_Notifications_Mutation_Response>;
  /** delete single row from the table: "nova_notifications" */
  delete_nova_notifications_by_pk?: Maybe<Nova_Notifications>;
  /** delete data from the table: "nova_pending_field_attachments" */
  delete_nova_pending_field_attachments?: Maybe<Nova_Pending_Field_Attachments_Mutation_Response>;
  /** delete single row from the table: "nova_pending_field_attachments" */
  delete_nova_pending_field_attachments_by_pk?: Maybe<Nova_Pending_Field_Attachments>;
  /** delete data from the table: "nova_twofa" */
  delete_nova_twofa?: Maybe<Nova_Twofa_Mutation_Response>;
  /** delete single row from the table: "nova_twofa" */
  delete_nova_twofa_by_pk?: Maybe<Nova_Twofa>;
  /** delete data from the table: "pairing_codes" */
  delete_pairing_codes?: Maybe<Pairing_Codes_Mutation_Response>;
  /** delete data from the table: "password_reset_tokens" */
  delete_password_reset_tokens?: Maybe<Password_Reset_Tokens_Mutation_Response>;
  /** delete single row from the table: "password_reset_tokens" */
  delete_password_reset_tokens_by_pk?: Maybe<Password_Reset_Tokens>;
  /** delete data from the table: "permissions" */
  delete_permissions?: Maybe<Permissions_Mutation_Response>;
  /** delete single row from the table: "permissions" */
  delete_permissions_by_pk?: Maybe<Permissions>;
  /** delete data from the table: "personal_access_tokens" */
  delete_personal_access_tokens?: Maybe<Personal_Access_Tokens_Mutation_Response>;
  /** delete single row from the table: "personal_access_tokens" */
  delete_personal_access_tokens_by_pk?: Maybe<Personal_Access_Tokens>;
  /** delete data from the table: "personal_dashboard" */
  delete_personal_dashboard?: Maybe<Personal_Dashboard_Mutation_Response>;
  /** delete single row from the table: "personal_dashboard" */
  delete_personal_dashboard_by_pk?: Maybe<Personal_Dashboard>;
  /** delete data from the table: "reel_category" */
  delete_reel_category?: Maybe<Reel_Category_Mutation_Response>;
  /** delete single row from the table: "reel_category" */
  delete_reel_category_by_pk?: Maybe<Reel_Category>;
  /** delete data from the table: "reel_comments" */
  delete_reel_comments?: Maybe<Reel_Comments_Mutation_Response>;
  /** delete single row from the table: "reel_comments" */
  delete_reel_comments_by_pk?: Maybe<Reel_Comments>;
  /** delete data from the table: "reel_interactions" */
  delete_reel_interactions?: Maybe<Reel_Interactions_Mutation_Response>;
  /** delete single row from the table: "reel_interactions" */
  delete_reel_interactions_by_pk?: Maybe<Reel_Interactions>;
  /** delete data from the table: "reel_user_reports" */
  delete_reel_user_reports?: Maybe<Reel_User_Reports_Mutation_Response>;
  /** delete single row from the table: "reel_user_reports" */
  delete_reel_user_reports_by_pk?: Maybe<Reel_User_Reports>;
  /** delete data from the table: "reels" */
  delete_reels?: Maybe<Reels_Mutation_Response>;
  /** delete single row from the table: "reels" */
  delete_reels_by_pk?: Maybe<Reels>;
  /** delete data from the table: "reels_with_distance" */
  delete_reels_with_distance?: Maybe<Reels_With_Distance_Mutation_Response>;
  /** delete data from the table: "role_has_permissions" */
  delete_role_has_permissions?: Maybe<Role_Has_Permissions_Mutation_Response>;
  /** delete single row from the table: "role_has_permissions" */
  delete_role_has_permissions_by_pk?: Maybe<Role_Has_Permissions>;
  /** delete data from the table: "roles" */
  delete_roles?: Maybe<Roles_Mutation_Response>;
  /** delete single row from the table: "roles" */
  delete_roles_by_pk?: Maybe<Roles>;
  /** delete data from the table: "segment_user" */
  delete_segment_user?: Maybe<Segment_User_Mutation_Response>;
  /** delete single row from the table: "segment_user" */
  delete_segment_user_by_pk?: Maybe<Segment_User>;
  /** delete data from the table: "service_request_dashboard" */
  delete_service_request_dashboard?: Maybe<Service_Request_Dashboard_Mutation_Response>;
  /** delete single row from the table: "service_request_dashboard" */
  delete_service_request_dashboard_by_pk?: Maybe<Service_Request_Dashboard>;
  /** delete data from the table: "service_requests" */
  delete_service_requests?: Maybe<Service_Requests_Mutation_Response>;
  /** delete single row from the table: "service_requests" */
  delete_service_requests_by_pk?: Maybe<Service_Requests>;
  /** delete data from the table: "snapshots" */
  delete_snapshots?: Maybe<Snapshots_Mutation_Response>;
  /** delete single row from the table: "snapshots" */
  delete_snapshots_by_pk?: Maybe<Snapshots>;
  /** delete data from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** delete single row from the table: "spatial_ref_sys" */
  delete_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** delete data from the table: "stored_events" */
  delete_stored_events?: Maybe<Stored_Events_Mutation_Response>;
  /** delete single row from the table: "stored_events" */
  delete_stored_events_by_pk?: Maybe<Stored_Events>;
  /** delete data from the table: "taggables" */
  delete_taggables?: Maybe<Taggables_Mutation_Response>;
  /** delete data from the table: "tags" */
  delete_tags?: Maybe<Tags_Mutation_Response>;
  /** delete single row from the table: "tags" */
  delete_tags_by_pk?: Maybe<Tags>;
  /** delete data from the table: "user_device" */
  delete_user_device?: Maybe<User_Device_Mutation_Response>;
  /** delete single row from the table: "user_device" */
  delete_user_device_by_pk?: Maybe<User_Device>;
  /** delete data from the table: "user_feedbacks" */
  delete_user_feedbacks?: Maybe<User_Feedbacks_Mutation_Response>;
  /** delete single row from the table: "user_feedbacks" */
  delete_user_feedbacks_by_pk?: Maybe<User_Feedbacks>;
  /** delete data from the table: "user_location_logs" */
  delete_user_location_logs?: Maybe<User_Location_Logs_Mutation_Response>;
  /** delete single row from the table: "user_location_logs" */
  delete_user_location_logs_by_pk?: Maybe<User_Location_Logs>;
  /** delete data from the table: "users" */
  delete_users?: Maybe<Users_Mutation_Response>;
  /** delete single row from the table: "users" */
  delete_users_by_pk?: Maybe<Users>;
  /** delete data from the table: "whatsauth_logins" */
  delete_whatsauth_logins?: Maybe<Whatsauth_Logins_Mutation_Response>;
  /** delete single row from the table: "whatsauth_logins" */
  delete_whatsauth_logins_by_pk?: Maybe<Whatsauth_Logins>;
  farmerAcceptsVisitSchedule: DefaultCommandOutput;
  farmerCancelsServiceRequest: DefaultCommandOutput;
  farmerClosesResponse: DefaultCommandOutput;
  farmerClosesServiceRequest: DefaultCommandOutput;
  farmerCreatesServiceRequest: DefaultCommandOutput;
  farmerRatesAndReviewsServiceRequest: DefaultCommandOutput;
  ffWentOffline: DefaultCommandOutput;
  ffWentOnline: DefaultCommandOutput;
  fieldPartnerCancelsAVisit: DefaultCommandOutput;
  fieldPartnerClosesRequest: DefaultCommandOutput;
  fieldPartnerRatesAndReviewsFarmer: DefaultCommandOutput;
  fieldPartnerRespondsToRequest: DefaultCommandOutput;
  fieldPartnerSchedulesAVisit: DefaultCommandOutput;
  fieldPartnerUpdatesHisCrops: DefaultCommandOutput;
  fieldPartnerUpdatesHisSegments: DefaultCommandOutput;
  /** insert data into the table: "account_crop" */
  insert_account_crop?: Maybe<Account_Crop_Mutation_Response>;
  /** insert a single row into the table: "account_crop" */
  insert_account_crop_one?: Maybe<Account_Crop>;
  /** insert data into the table: "account_segment" */
  insert_account_segment?: Maybe<Account_Segment_Mutation_Response>;
  /** insert a single row into the table: "account_segment" */
  insert_account_segment_one?: Maybe<Account_Segment>;
  /** insert data into the table: "account_user" */
  insert_account_user?: Maybe<Account_User_Mutation_Response>;
  /** insert a single row into the table: "account_user" */
  insert_account_user_one?: Maybe<Account_User>;
  /** insert data into the table: "accounts" */
  insert_accounts?: Maybe<Accounts_Mutation_Response>;
  /** insert a single row into the table: "accounts" */
  insert_accounts_one?: Maybe<Accounts>;
  /** insert data into the table: "action_events" */
  insert_action_events?: Maybe<Action_Events_Mutation_Response>;
  /** insert a single row into the table: "action_events" */
  insert_action_events_one?: Maybe<Action_Events>;
  /** insert data into the table: "companies" */
  insert_companies?: Maybe<Companies_Mutation_Response>;
  /** insert a single row into the table: "companies" */
  insert_companies_one?: Maybe<Companies>;
  /** insert data into the table: "crop_user" */
  insert_crop_user?: Maybe<Crop_User_Mutation_Response>;
  /** insert a single row into the table: "crop_user" */
  insert_crop_user_one?: Maybe<Crop_User>;
  /** insert data into the table: "failed_jobs" */
  insert_failed_jobs?: Maybe<Failed_Jobs_Mutation_Response>;
  /** insert a single row into the table: "failed_jobs" */
  insert_failed_jobs_one?: Maybe<Failed_Jobs>;
  /** insert data into the table: "farmer_nearby_field_force" */
  insert_farmer_nearby_field_force?: Maybe<Farmer_Nearby_Field_Force_Mutation_Response>;
  /** insert a single row into the table: "farmer_nearby_field_force" */
  insert_farmer_nearby_field_force_one?: Maybe<Farmer_Nearby_Field_Force>;
  /** insert data into the table: "farmer_plots" */
  insert_farmer_plots?: Maybe<Farmer_Plots_Mutation_Response>;
  /** insert a single row into the table: "farmer_plots" */
  insert_farmer_plots_one?: Maybe<Farmer_Plots>;
  /** insert data into the table: "field_partner_response_events" */
  insert_field_partner_response_events?: Maybe<Field_Partner_Response_Events_Mutation_Response>;
  /** insert a single row into the table: "field_partner_response_events" */
  insert_field_partner_response_events_one?: Maybe<Field_Partner_Response_Events>;
  /** insert data into the table: "field_partner_response_messages" */
  insert_field_partner_response_messages?: Maybe<Field_Partner_Response_Messages_Mutation_Response>;
  /** insert a single row into the table: "field_partner_response_messages" */
  insert_field_partner_response_messages_one?: Maybe<Field_Partner_Response_Messages>;
  /** insert data into the table: "field_partner_responses" */
  insert_field_partner_responses?: Maybe<Field_Partner_Responses_Mutation_Response>;
  /** insert a single row into the table: "field_partner_responses" */
  insert_field_partner_responses_one?: Maybe<Field_Partner_Responses>;
  /** insert data into the table: "field_scheduled_visits" */
  insert_field_scheduled_visits?: Maybe<Field_Scheduled_Visits_Mutation_Response>;
  /** insert a single row into the table: "field_scheduled_visits" */
  insert_field_scheduled_visits_one?: Maybe<Field_Scheduled_Visits>;
  /** insert data into the table: "follows" */
  insert_follows?: Maybe<Follows_Mutation_Response>;
  /** insert a single row into the table: "follows" */
  insert_follows_one?: Maybe<Follows>;
  /** insert data into the table: "geometry_columns" */
  insert_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** insert a single row into the table: "geometry_columns" */
  insert_geometry_columns_one?: Maybe<Geometry_Columns>;
  /** insert data into the table: "last_known_locations" */
  insert_last_known_locations?: Maybe<Last_Known_Locations_Mutation_Response>;
  /** insert a single row into the table: "last_known_locations" */
  insert_last_known_locations_one?: Maybe<Last_Known_Locations>;
  /** insert data into the table: "master_crops" */
  insert_master_crops?: Maybe<Master_Crops_Mutation_Response>;
  /** insert a single row into the table: "master_crops" */
  insert_master_crops_one?: Maybe<Master_Crops>;
  /** insert data into the table: "master_segments" */
  insert_master_segments?: Maybe<Master_Segments_Mutation_Response>;
  /** insert a single row into the table: "master_segments" */
  insert_master_segments_one?: Maybe<Master_Segments>;
  /** insert data into the table: "migrations" */
  insert_migrations?: Maybe<Migrations_Mutation_Response>;
  /** insert a single row into the table: "migrations" */
  insert_migrations_one?: Maybe<Migrations>;
  /** insert data into the table: "model_has_permissions" */
  insert_model_has_permissions?: Maybe<Model_Has_Permissions_Mutation_Response>;
  /** insert a single row into the table: "model_has_permissions" */
  insert_model_has_permissions_one?: Maybe<Model_Has_Permissions>;
  /** insert data into the table: "model_has_roles" */
  insert_model_has_roles?: Maybe<Model_Has_Roles_Mutation_Response>;
  /** insert a single row into the table: "model_has_roles" */
  insert_model_has_roles_one?: Maybe<Model_Has_Roles>;
  /** insert data into the table: "notifications" */
  insert_notifications?: Maybe<Notifications_Mutation_Response>;
  /** insert a single row into the table: "notifications" */
  insert_notifications_one?: Maybe<Notifications>;
  /** insert data into the table: "nova_field_attachments" */
  insert_nova_field_attachments?: Maybe<Nova_Field_Attachments_Mutation_Response>;
  /** insert a single row into the table: "nova_field_attachments" */
  insert_nova_field_attachments_one?: Maybe<Nova_Field_Attachments>;
  /** insert data into the table: "nova_notifications" */
  insert_nova_notifications?: Maybe<Nova_Notifications_Mutation_Response>;
  /** insert a single row into the table: "nova_notifications" */
  insert_nova_notifications_one?: Maybe<Nova_Notifications>;
  /** insert data into the table: "nova_pending_field_attachments" */
  insert_nova_pending_field_attachments?: Maybe<Nova_Pending_Field_Attachments_Mutation_Response>;
  /** insert a single row into the table: "nova_pending_field_attachments" */
  insert_nova_pending_field_attachments_one?: Maybe<Nova_Pending_Field_Attachments>;
  /** insert data into the table: "nova_twofa" */
  insert_nova_twofa?: Maybe<Nova_Twofa_Mutation_Response>;
  /** insert a single row into the table: "nova_twofa" */
  insert_nova_twofa_one?: Maybe<Nova_Twofa>;
  /** insert data into the table: "pairing_codes" */
  insert_pairing_codes?: Maybe<Pairing_Codes_Mutation_Response>;
  /** insert a single row into the table: "pairing_codes" */
  insert_pairing_codes_one?: Maybe<Pairing_Codes>;
  /** insert data into the table: "password_reset_tokens" */
  insert_password_reset_tokens?: Maybe<Password_Reset_Tokens_Mutation_Response>;
  /** insert a single row into the table: "password_reset_tokens" */
  insert_password_reset_tokens_one?: Maybe<Password_Reset_Tokens>;
  /** insert data into the table: "permissions" */
  insert_permissions?: Maybe<Permissions_Mutation_Response>;
  /** insert a single row into the table: "permissions" */
  insert_permissions_one?: Maybe<Permissions>;
  /** insert data into the table: "personal_access_tokens" */
  insert_personal_access_tokens?: Maybe<Personal_Access_Tokens_Mutation_Response>;
  /** insert a single row into the table: "personal_access_tokens" */
  insert_personal_access_tokens_one?: Maybe<Personal_Access_Tokens>;
  /** insert data into the table: "personal_dashboard" */
  insert_personal_dashboard?: Maybe<Personal_Dashboard_Mutation_Response>;
  /** insert a single row into the table: "personal_dashboard" */
  insert_personal_dashboard_one?: Maybe<Personal_Dashboard>;
  /** insert data into the table: "reel_category" */
  insert_reel_category?: Maybe<Reel_Category_Mutation_Response>;
  /** insert a single row into the table: "reel_category" */
  insert_reel_category_one?: Maybe<Reel_Category>;
  /** insert data into the table: "reel_comments" */
  insert_reel_comments?: Maybe<Reel_Comments_Mutation_Response>;
  /** insert a single row into the table: "reel_comments" */
  insert_reel_comments_one?: Maybe<Reel_Comments>;
  /** insert data into the table: "reel_interactions" */
  insert_reel_interactions?: Maybe<Reel_Interactions_Mutation_Response>;
  /** insert a single row into the table: "reel_interactions" */
  insert_reel_interactions_one?: Maybe<Reel_Interactions>;
  /** insert data into the table: "reel_user_reports" */
  insert_reel_user_reports?: Maybe<Reel_User_Reports_Mutation_Response>;
  /** insert a single row into the table: "reel_user_reports" */
  insert_reel_user_reports_one?: Maybe<Reel_User_Reports>;
  /** insert data into the table: "reels" */
  insert_reels?: Maybe<Reels_Mutation_Response>;
  /** insert a single row into the table: "reels" */
  insert_reels_one?: Maybe<Reels>;
  /** insert data into the table: "reels_with_distance" */
  insert_reels_with_distance?: Maybe<Reels_With_Distance_Mutation_Response>;
  /** insert a single row into the table: "reels_with_distance" */
  insert_reels_with_distance_one?: Maybe<Reels_With_Distance>;
  /** insert data into the table: "role_has_permissions" */
  insert_role_has_permissions?: Maybe<Role_Has_Permissions_Mutation_Response>;
  /** insert a single row into the table: "role_has_permissions" */
  insert_role_has_permissions_one?: Maybe<Role_Has_Permissions>;
  /** insert data into the table: "roles" */
  insert_roles?: Maybe<Roles_Mutation_Response>;
  /** insert a single row into the table: "roles" */
  insert_roles_one?: Maybe<Roles>;
  /** insert data into the table: "segment_user" */
  insert_segment_user?: Maybe<Segment_User_Mutation_Response>;
  /** insert a single row into the table: "segment_user" */
  insert_segment_user_one?: Maybe<Segment_User>;
  /** insert data into the table: "service_request_dashboard" */
  insert_service_request_dashboard?: Maybe<Service_Request_Dashboard_Mutation_Response>;
  /** insert a single row into the table: "service_request_dashboard" */
  insert_service_request_dashboard_one?: Maybe<Service_Request_Dashboard>;
  /** insert data into the table: "service_requests" */
  insert_service_requests?: Maybe<Service_Requests_Mutation_Response>;
  /** insert a single row into the table: "service_requests" */
  insert_service_requests_one?: Maybe<Service_Requests>;
  /** insert data into the table: "snapshots" */
  insert_snapshots?: Maybe<Snapshots_Mutation_Response>;
  /** insert a single row into the table: "snapshots" */
  insert_snapshots_one?: Maybe<Snapshots>;
  /** insert data into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** insert a single row into the table: "spatial_ref_sys" */
  insert_spatial_ref_sys_one?: Maybe<Spatial_Ref_Sys>;
  /** insert data into the table: "stored_events" */
  insert_stored_events?: Maybe<Stored_Events_Mutation_Response>;
  /** insert a single row into the table: "stored_events" */
  insert_stored_events_one?: Maybe<Stored_Events>;
  /** insert data into the table: "taggables" */
  insert_taggables?: Maybe<Taggables_Mutation_Response>;
  /** insert a single row into the table: "taggables" */
  insert_taggables_one?: Maybe<Taggables>;
  /** insert data into the table: "tags" */
  insert_tags?: Maybe<Tags_Mutation_Response>;
  /** insert a single row into the table: "tags" */
  insert_tags_one?: Maybe<Tags>;
  /** insert data into the table: "user_device" */
  insert_user_device?: Maybe<User_Device_Mutation_Response>;
  /** insert a single row into the table: "user_device" */
  insert_user_device_one?: Maybe<User_Device>;
  /** insert data into the table: "user_feedbacks" */
  insert_user_feedbacks?: Maybe<User_Feedbacks_Mutation_Response>;
  /** insert a single row into the table: "user_feedbacks" */
  insert_user_feedbacks_one?: Maybe<User_Feedbacks>;
  /** insert data into the table: "user_location_logs" */
  insert_user_location_logs?: Maybe<User_Location_Logs_Mutation_Response>;
  /** insert a single row into the table: "user_location_logs" */
  insert_user_location_logs_one?: Maybe<User_Location_Logs>;
  /** insert data into the table: "users" */
  insert_users?: Maybe<Users_Mutation_Response>;
  /** insert a single row into the table: "users" */
  insert_users_one?: Maybe<Users>;
  /** insert data into the table: "whatsauth_logins" */
  insert_whatsauth_logins?: Maybe<Whatsauth_Logins_Mutation_Response>;
  /** insert a single row into the table: "whatsauth_logins" */
  insert_whatsauth_logins_one?: Maybe<Whatsauth_Logins>;
  requestOtpCommand: RequestOtpOutput;
  s3UploadData: Array<Scalars['String']['output']>;
  sendMessageOnServiceRequest: DefaultCommandOutput;
  setContentLanguages: DefaultCommandOutput;
  setFieldForceLocation: DefaultCommandOutput;
  setRoleCommand: DefaultCommandOutput;
  /** update data of the table: "account_crop" */
  update_account_crop?: Maybe<Account_Crop_Mutation_Response>;
  /** update single row of the table: "account_crop" */
  update_account_crop_by_pk?: Maybe<Account_Crop>;
  /** update multiples rows of table: "account_crop" */
  update_account_crop_many?: Maybe<Array<Maybe<Account_Crop_Mutation_Response>>>;
  /** update data of the table: "account_segment" */
  update_account_segment?: Maybe<Account_Segment_Mutation_Response>;
  /** update single row of the table: "account_segment" */
  update_account_segment_by_pk?: Maybe<Account_Segment>;
  /** update multiples rows of table: "account_segment" */
  update_account_segment_many?: Maybe<Array<Maybe<Account_Segment_Mutation_Response>>>;
  /** update data of the table: "account_user" */
  update_account_user?: Maybe<Account_User_Mutation_Response>;
  /** update single row of the table: "account_user" */
  update_account_user_by_pk?: Maybe<Account_User>;
  /** update multiples rows of table: "account_user" */
  update_account_user_many?: Maybe<Array<Maybe<Account_User_Mutation_Response>>>;
  /** update data of the table: "accounts" */
  update_accounts?: Maybe<Accounts_Mutation_Response>;
  /** update single row of the table: "accounts" */
  update_accounts_by_pk?: Maybe<Accounts>;
  /** update multiples rows of table: "accounts" */
  update_accounts_many?: Maybe<Array<Maybe<Accounts_Mutation_Response>>>;
  /** update data of the table: "action_events" */
  update_action_events?: Maybe<Action_Events_Mutation_Response>;
  /** update single row of the table: "action_events" */
  update_action_events_by_pk?: Maybe<Action_Events>;
  /** update multiples rows of table: "action_events" */
  update_action_events_many?: Maybe<Array<Maybe<Action_Events_Mutation_Response>>>;
  /** update data of the table: "companies" */
  update_companies?: Maybe<Companies_Mutation_Response>;
  /** update single row of the table: "companies" */
  update_companies_by_pk?: Maybe<Companies>;
  /** update multiples rows of table: "companies" */
  update_companies_many?: Maybe<Array<Maybe<Companies_Mutation_Response>>>;
  /** update data of the table: "crop_user" */
  update_crop_user?: Maybe<Crop_User_Mutation_Response>;
  /** update single row of the table: "crop_user" */
  update_crop_user_by_pk?: Maybe<Crop_User>;
  /** update multiples rows of table: "crop_user" */
  update_crop_user_many?: Maybe<Array<Maybe<Crop_User_Mutation_Response>>>;
  /** update data of the table: "failed_jobs" */
  update_failed_jobs?: Maybe<Failed_Jobs_Mutation_Response>;
  /** update single row of the table: "failed_jobs" */
  update_failed_jobs_by_pk?: Maybe<Failed_Jobs>;
  /** update multiples rows of table: "failed_jobs" */
  update_failed_jobs_many?: Maybe<Array<Maybe<Failed_Jobs_Mutation_Response>>>;
  /** update data of the table: "farmer_nearby_field_force" */
  update_farmer_nearby_field_force?: Maybe<Farmer_Nearby_Field_Force_Mutation_Response>;
  /** update single row of the table: "farmer_nearby_field_force" */
  update_farmer_nearby_field_force_by_pk?: Maybe<Farmer_Nearby_Field_Force>;
  /** update multiples rows of table: "farmer_nearby_field_force" */
  update_farmer_nearby_field_force_many?: Maybe<Array<Maybe<Farmer_Nearby_Field_Force_Mutation_Response>>>;
  /** update data of the table: "farmer_plots" */
  update_farmer_plots?: Maybe<Farmer_Plots_Mutation_Response>;
  /** update single row of the table: "farmer_plots" */
  update_farmer_plots_by_pk?: Maybe<Farmer_Plots>;
  /** update multiples rows of table: "farmer_plots" */
  update_farmer_plots_many?: Maybe<Array<Maybe<Farmer_Plots_Mutation_Response>>>;
  /** update data of the table: "field_partner_response_events" */
  update_field_partner_response_events?: Maybe<Field_Partner_Response_Events_Mutation_Response>;
  /** update single row of the table: "field_partner_response_events" */
  update_field_partner_response_events_by_pk?: Maybe<Field_Partner_Response_Events>;
  /** update multiples rows of table: "field_partner_response_events" */
  update_field_partner_response_events_many?: Maybe<Array<Maybe<Field_Partner_Response_Events_Mutation_Response>>>;
  /** update data of the table: "field_partner_response_messages" */
  update_field_partner_response_messages?: Maybe<Field_Partner_Response_Messages_Mutation_Response>;
  /** update single row of the table: "field_partner_response_messages" */
  update_field_partner_response_messages_by_pk?: Maybe<Field_Partner_Response_Messages>;
  /** update multiples rows of table: "field_partner_response_messages" */
  update_field_partner_response_messages_many?: Maybe<Array<Maybe<Field_Partner_Response_Messages_Mutation_Response>>>;
  /** update data of the table: "field_partner_responses" */
  update_field_partner_responses?: Maybe<Field_Partner_Responses_Mutation_Response>;
  /** update single row of the table: "field_partner_responses" */
  update_field_partner_responses_by_pk?: Maybe<Field_Partner_Responses>;
  /** update multiples rows of table: "field_partner_responses" */
  update_field_partner_responses_many?: Maybe<Array<Maybe<Field_Partner_Responses_Mutation_Response>>>;
  /** update data of the table: "field_scheduled_visits" */
  update_field_scheduled_visits?: Maybe<Field_Scheduled_Visits_Mutation_Response>;
  /** update single row of the table: "field_scheduled_visits" */
  update_field_scheduled_visits_by_pk?: Maybe<Field_Scheduled_Visits>;
  /** update multiples rows of table: "field_scheduled_visits" */
  update_field_scheduled_visits_many?: Maybe<Array<Maybe<Field_Scheduled_Visits_Mutation_Response>>>;
  /** update data of the table: "follows" */
  update_follows?: Maybe<Follows_Mutation_Response>;
  /** update single row of the table: "follows" */
  update_follows_by_pk?: Maybe<Follows>;
  /** update multiples rows of table: "follows" */
  update_follows_many?: Maybe<Array<Maybe<Follows_Mutation_Response>>>;
  /** update data of the table: "geometry_columns" */
  update_geometry_columns?: Maybe<Geometry_Columns_Mutation_Response>;
  /** update multiples rows of table: "geometry_columns" */
  update_geometry_columns_many?: Maybe<Array<Maybe<Geometry_Columns_Mutation_Response>>>;
  /** update data of the table: "last_known_locations" */
  update_last_known_locations?: Maybe<Last_Known_Locations_Mutation_Response>;
  /** update single row of the table: "last_known_locations" */
  update_last_known_locations_by_pk?: Maybe<Last_Known_Locations>;
  /** update multiples rows of table: "last_known_locations" */
  update_last_known_locations_many?: Maybe<Array<Maybe<Last_Known_Locations_Mutation_Response>>>;
  /** update data of the table: "master_crops" */
  update_master_crops?: Maybe<Master_Crops_Mutation_Response>;
  /** update single row of the table: "master_crops" */
  update_master_crops_by_pk?: Maybe<Master_Crops>;
  /** update multiples rows of table: "master_crops" */
  update_master_crops_many?: Maybe<Array<Maybe<Master_Crops_Mutation_Response>>>;
  /** update data of the table: "master_segments" */
  update_master_segments?: Maybe<Master_Segments_Mutation_Response>;
  /** update single row of the table: "master_segments" */
  update_master_segments_by_pk?: Maybe<Master_Segments>;
  /** update multiples rows of table: "master_segments" */
  update_master_segments_many?: Maybe<Array<Maybe<Master_Segments_Mutation_Response>>>;
  /** update data of the table: "migrations" */
  update_migrations?: Maybe<Migrations_Mutation_Response>;
  /** update single row of the table: "migrations" */
  update_migrations_by_pk?: Maybe<Migrations>;
  /** update multiples rows of table: "migrations" */
  update_migrations_many?: Maybe<Array<Maybe<Migrations_Mutation_Response>>>;
  /** update data of the table: "model_has_permissions" */
  update_model_has_permissions?: Maybe<Model_Has_Permissions_Mutation_Response>;
  /** update single row of the table: "model_has_permissions" */
  update_model_has_permissions_by_pk?: Maybe<Model_Has_Permissions>;
  /** update multiples rows of table: "model_has_permissions" */
  update_model_has_permissions_many?: Maybe<Array<Maybe<Model_Has_Permissions_Mutation_Response>>>;
  /** update data of the table: "model_has_roles" */
  update_model_has_roles?: Maybe<Model_Has_Roles_Mutation_Response>;
  /** update single row of the table: "model_has_roles" */
  update_model_has_roles_by_pk?: Maybe<Model_Has_Roles>;
  /** update multiples rows of table: "model_has_roles" */
  update_model_has_roles_many?: Maybe<Array<Maybe<Model_Has_Roles_Mutation_Response>>>;
  /** update data of the table: "notifications" */
  update_notifications?: Maybe<Notifications_Mutation_Response>;
  /** update single row of the table: "notifications" */
  update_notifications_by_pk?: Maybe<Notifications>;
  /** update multiples rows of table: "notifications" */
  update_notifications_many?: Maybe<Array<Maybe<Notifications_Mutation_Response>>>;
  /** update data of the table: "nova_field_attachments" */
  update_nova_field_attachments?: Maybe<Nova_Field_Attachments_Mutation_Response>;
  /** update single row of the table: "nova_field_attachments" */
  update_nova_field_attachments_by_pk?: Maybe<Nova_Field_Attachments>;
  /** update multiples rows of table: "nova_field_attachments" */
  update_nova_field_attachments_many?: Maybe<Array<Maybe<Nova_Field_Attachments_Mutation_Response>>>;
  /** update data of the table: "nova_notifications" */
  update_nova_notifications?: Maybe<Nova_Notifications_Mutation_Response>;
  /** update single row of the table: "nova_notifications" */
  update_nova_notifications_by_pk?: Maybe<Nova_Notifications>;
  /** update multiples rows of table: "nova_notifications" */
  update_nova_notifications_many?: Maybe<Array<Maybe<Nova_Notifications_Mutation_Response>>>;
  /** update data of the table: "nova_pending_field_attachments" */
  update_nova_pending_field_attachments?: Maybe<Nova_Pending_Field_Attachments_Mutation_Response>;
  /** update single row of the table: "nova_pending_field_attachments" */
  update_nova_pending_field_attachments_by_pk?: Maybe<Nova_Pending_Field_Attachments>;
  /** update multiples rows of table: "nova_pending_field_attachments" */
  update_nova_pending_field_attachments_many?: Maybe<Array<Maybe<Nova_Pending_Field_Attachments_Mutation_Response>>>;
  /** update data of the table: "nova_twofa" */
  update_nova_twofa?: Maybe<Nova_Twofa_Mutation_Response>;
  /** update single row of the table: "nova_twofa" */
  update_nova_twofa_by_pk?: Maybe<Nova_Twofa>;
  /** update multiples rows of table: "nova_twofa" */
  update_nova_twofa_many?: Maybe<Array<Maybe<Nova_Twofa_Mutation_Response>>>;
  /** update data of the table: "pairing_codes" */
  update_pairing_codes?: Maybe<Pairing_Codes_Mutation_Response>;
  /** update multiples rows of table: "pairing_codes" */
  update_pairing_codes_many?: Maybe<Array<Maybe<Pairing_Codes_Mutation_Response>>>;
  /** update data of the table: "password_reset_tokens" */
  update_password_reset_tokens?: Maybe<Password_Reset_Tokens_Mutation_Response>;
  /** update single row of the table: "password_reset_tokens" */
  update_password_reset_tokens_by_pk?: Maybe<Password_Reset_Tokens>;
  /** update multiples rows of table: "password_reset_tokens" */
  update_password_reset_tokens_many?: Maybe<Array<Maybe<Password_Reset_Tokens_Mutation_Response>>>;
  /** update data of the table: "permissions" */
  update_permissions?: Maybe<Permissions_Mutation_Response>;
  /** update single row of the table: "permissions" */
  update_permissions_by_pk?: Maybe<Permissions>;
  /** update multiples rows of table: "permissions" */
  update_permissions_many?: Maybe<Array<Maybe<Permissions_Mutation_Response>>>;
  /** update data of the table: "personal_access_tokens" */
  update_personal_access_tokens?: Maybe<Personal_Access_Tokens_Mutation_Response>;
  /** update single row of the table: "personal_access_tokens" */
  update_personal_access_tokens_by_pk?: Maybe<Personal_Access_Tokens>;
  /** update multiples rows of table: "personal_access_tokens" */
  update_personal_access_tokens_many?: Maybe<Array<Maybe<Personal_Access_Tokens_Mutation_Response>>>;
  /** update data of the table: "personal_dashboard" */
  update_personal_dashboard?: Maybe<Personal_Dashboard_Mutation_Response>;
  /** update single row of the table: "personal_dashboard" */
  update_personal_dashboard_by_pk?: Maybe<Personal_Dashboard>;
  /** update multiples rows of table: "personal_dashboard" */
  update_personal_dashboard_many?: Maybe<Array<Maybe<Personal_Dashboard_Mutation_Response>>>;
  /** update data of the table: "reel_category" */
  update_reel_category?: Maybe<Reel_Category_Mutation_Response>;
  /** update single row of the table: "reel_category" */
  update_reel_category_by_pk?: Maybe<Reel_Category>;
  /** update multiples rows of table: "reel_category" */
  update_reel_category_many?: Maybe<Array<Maybe<Reel_Category_Mutation_Response>>>;
  /** update data of the table: "reel_comments" */
  update_reel_comments?: Maybe<Reel_Comments_Mutation_Response>;
  /** update single row of the table: "reel_comments" */
  update_reel_comments_by_pk?: Maybe<Reel_Comments>;
  /** update multiples rows of table: "reel_comments" */
  update_reel_comments_many?: Maybe<Array<Maybe<Reel_Comments_Mutation_Response>>>;
  /** update data of the table: "reel_interactions" */
  update_reel_interactions?: Maybe<Reel_Interactions_Mutation_Response>;
  /** update single row of the table: "reel_interactions" */
  update_reel_interactions_by_pk?: Maybe<Reel_Interactions>;
  /** update multiples rows of table: "reel_interactions" */
  update_reel_interactions_many?: Maybe<Array<Maybe<Reel_Interactions_Mutation_Response>>>;
  /** update data of the table: "reel_user_reports" */
  update_reel_user_reports?: Maybe<Reel_User_Reports_Mutation_Response>;
  /** update single row of the table: "reel_user_reports" */
  update_reel_user_reports_by_pk?: Maybe<Reel_User_Reports>;
  /** update multiples rows of table: "reel_user_reports" */
  update_reel_user_reports_many?: Maybe<Array<Maybe<Reel_User_Reports_Mutation_Response>>>;
  /** update data of the table: "reels" */
  update_reels?: Maybe<Reels_Mutation_Response>;
  /** update single row of the table: "reels" */
  update_reels_by_pk?: Maybe<Reels>;
  /** update multiples rows of table: "reels" */
  update_reels_many?: Maybe<Array<Maybe<Reels_Mutation_Response>>>;
  /** update data of the table: "reels_with_distance" */
  update_reels_with_distance?: Maybe<Reels_With_Distance_Mutation_Response>;
  /** update multiples rows of table: "reels_with_distance" */
  update_reels_with_distance_many?: Maybe<Array<Maybe<Reels_With_Distance_Mutation_Response>>>;
  /** update data of the table: "role_has_permissions" */
  update_role_has_permissions?: Maybe<Role_Has_Permissions_Mutation_Response>;
  /** update single row of the table: "role_has_permissions" */
  update_role_has_permissions_by_pk?: Maybe<Role_Has_Permissions>;
  /** update multiples rows of table: "role_has_permissions" */
  update_role_has_permissions_many?: Maybe<Array<Maybe<Role_Has_Permissions_Mutation_Response>>>;
  /** update data of the table: "roles" */
  update_roles?: Maybe<Roles_Mutation_Response>;
  /** update single row of the table: "roles" */
  update_roles_by_pk?: Maybe<Roles>;
  /** update multiples rows of table: "roles" */
  update_roles_many?: Maybe<Array<Maybe<Roles_Mutation_Response>>>;
  /** update data of the table: "segment_user" */
  update_segment_user?: Maybe<Segment_User_Mutation_Response>;
  /** update single row of the table: "segment_user" */
  update_segment_user_by_pk?: Maybe<Segment_User>;
  /** update multiples rows of table: "segment_user" */
  update_segment_user_many?: Maybe<Array<Maybe<Segment_User_Mutation_Response>>>;
  /** update data of the table: "service_request_dashboard" */
  update_service_request_dashboard?: Maybe<Service_Request_Dashboard_Mutation_Response>;
  /** update single row of the table: "service_request_dashboard" */
  update_service_request_dashboard_by_pk?: Maybe<Service_Request_Dashboard>;
  /** update multiples rows of table: "service_request_dashboard" */
  update_service_request_dashboard_many?: Maybe<Array<Maybe<Service_Request_Dashboard_Mutation_Response>>>;
  /** update data of the table: "service_requests" */
  update_service_requests?: Maybe<Service_Requests_Mutation_Response>;
  /** update single row of the table: "service_requests" */
  update_service_requests_by_pk?: Maybe<Service_Requests>;
  /** update multiples rows of table: "service_requests" */
  update_service_requests_many?: Maybe<Array<Maybe<Service_Requests_Mutation_Response>>>;
  /** update data of the table: "snapshots" */
  update_snapshots?: Maybe<Snapshots_Mutation_Response>;
  /** update single row of the table: "snapshots" */
  update_snapshots_by_pk?: Maybe<Snapshots>;
  /** update multiples rows of table: "snapshots" */
  update_snapshots_many?: Maybe<Array<Maybe<Snapshots_Mutation_Response>>>;
  /** update data of the table: "spatial_ref_sys" */
  update_spatial_ref_sys?: Maybe<Spatial_Ref_Sys_Mutation_Response>;
  /** update single row of the table: "spatial_ref_sys" */
  update_spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** update multiples rows of table: "spatial_ref_sys" */
  update_spatial_ref_sys_many?: Maybe<Array<Maybe<Spatial_Ref_Sys_Mutation_Response>>>;
  /** update data of the table: "stored_events" */
  update_stored_events?: Maybe<Stored_Events_Mutation_Response>;
  /** update single row of the table: "stored_events" */
  update_stored_events_by_pk?: Maybe<Stored_Events>;
  /** update multiples rows of table: "stored_events" */
  update_stored_events_many?: Maybe<Array<Maybe<Stored_Events_Mutation_Response>>>;
  /** update data of the table: "taggables" */
  update_taggables?: Maybe<Taggables_Mutation_Response>;
  /** update multiples rows of table: "taggables" */
  update_taggables_many?: Maybe<Array<Maybe<Taggables_Mutation_Response>>>;
  /** update data of the table: "tags" */
  update_tags?: Maybe<Tags_Mutation_Response>;
  /** update single row of the table: "tags" */
  update_tags_by_pk?: Maybe<Tags>;
  /** update multiples rows of table: "tags" */
  update_tags_many?: Maybe<Array<Maybe<Tags_Mutation_Response>>>;
  /** update data of the table: "user_device" */
  update_user_device?: Maybe<User_Device_Mutation_Response>;
  /** update single row of the table: "user_device" */
  update_user_device_by_pk?: Maybe<User_Device>;
  /** update multiples rows of table: "user_device" */
  update_user_device_many?: Maybe<Array<Maybe<User_Device_Mutation_Response>>>;
  /** update data of the table: "user_feedbacks" */
  update_user_feedbacks?: Maybe<User_Feedbacks_Mutation_Response>;
  /** update single row of the table: "user_feedbacks" */
  update_user_feedbacks_by_pk?: Maybe<User_Feedbacks>;
  /** update multiples rows of table: "user_feedbacks" */
  update_user_feedbacks_many?: Maybe<Array<Maybe<User_Feedbacks_Mutation_Response>>>;
  /** update data of the table: "user_location_logs" */
  update_user_location_logs?: Maybe<User_Location_Logs_Mutation_Response>;
  /** update single row of the table: "user_location_logs" */
  update_user_location_logs_by_pk?: Maybe<User_Location_Logs>;
  /** update multiples rows of table: "user_location_logs" */
  update_user_location_logs_many?: Maybe<Array<Maybe<User_Location_Logs_Mutation_Response>>>;
  /** update data of the table: "users" */
  update_users?: Maybe<Users_Mutation_Response>;
  /** update single row of the table: "users" */
  update_users_by_pk?: Maybe<Users>;
  /** update multiples rows of table: "users" */
  update_users_many?: Maybe<Array<Maybe<Users_Mutation_Response>>>;
  /** update data of the table: "whatsauth_logins" */
  update_whatsauth_logins?: Maybe<Whatsauth_Logins_Mutation_Response>;
  /** update single row of the table: "whatsauth_logins" */
  update_whatsauth_logins_by_pk?: Maybe<Whatsauth_Logins>;
  /** update multiples rows of table: "whatsauth_logins" */
  update_whatsauth_logins_many?: Maybe<Array<Maybe<Whatsauth_Logins_Mutation_Response>>>;
  userFollowsProfileCommand: DefaultCommandOutput;
  userLikedReel: DefaultCommandOutput;
  userReactedReel: DefaultCommandOutput;
  userReportsComment: DefaultCommandOutput;
  userReportsReel: DefaultCommandOutput;
  userRequestsAccountDeletion: DefaultCommandOutput;
  userSetHisLocale: DefaultCommandOutput;
  userSharedReel: DefaultCommandOutput;
  userUpdatesHisDeviceInfo: DefaultCommandOutput;
  userUpdatesHisProfile: DefaultCommandOutput;
  userViewedReel: DefaultCommandOutput;
  verifyOtpCommand: VerifyOtpOutput;
};


/** mutation root */
export type Mutation_RootCfUploadDataArgs = {
  expiresInSeconds?: InputMaybe<Scalars['Int']['input']>;
  type: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootCreateReelArgs = {
  command: UserCreatesReelCommandInput;
};


/** mutation root */
export type Mutation_RootCreateReelCategoryArgs = {
  command: UserCreatesNewReelCategoryInput;
};


/** mutation root */
export type Mutation_RootCreateReelCommentArgs = {
  command: UserCreatesReelCommentInput;
};


/** mutation root */
export type Mutation_RootDeleteTestRequestsArgs = {
  command: DeleteTestRequestsCommandInput;
};


/** mutation root */
export type Mutation_RootDelete_Account_CropArgs = {
  where: Account_Crop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Crop_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Account_SegmentArgs = {
  where: Account_Segment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_Segment_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Account_UserArgs = {
  where: Account_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Account_User_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_AccountsArgs = {
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Accounts_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Action_EventsArgs = {
  where: Action_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Action_Events_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_CompaniesArgs = {
  where: Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Companies_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Crop_UserArgs = {
  where: Crop_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Crop_User_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Failed_JobsArgs = {
  where: Failed_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Failed_Jobs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Farmer_Nearby_Field_ForceArgs = {
  where: Farmer_Nearby_Field_Force_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Farmer_Nearby_Field_Force_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Farmer_PlotsArgs = {
  where: Farmer_Plots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Farmer_Plots_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Field_Partner_Response_EventsArgs = {
  where: Field_Partner_Response_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Field_Partner_Response_Events_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Field_Partner_Response_MessagesArgs = {
  where: Field_Partner_Response_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Field_Partner_Response_Messages_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Field_Partner_ResponsesArgs = {
  where: Field_Partner_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Field_Partner_Responses_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Field_Scheduled_VisitsArgs = {
  where: Field_Scheduled_Visits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Field_Scheduled_Visits_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_FollowsArgs = {
  where: Follows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Follows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Geometry_ColumnsArgs = {
  where: Geometry_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Last_Known_LocationsArgs = {
  where: Last_Known_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Last_Known_Locations_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Master_CropsArgs = {
  where: Master_Crops_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Crops_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Master_SegmentsArgs = {
  where: Master_Segments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Master_Segments_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_MigrationsArgs = {
  where: Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Migrations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Model_Has_PermissionsArgs = {
  where: Model_Has_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Model_Has_Permissions_By_PkArgs = {
  model_id: Scalars['String']['input'];
  model_type: Scalars['String']['input'];
  permission_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Model_Has_RolesArgs = {
  where: Model_Has_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Model_Has_Roles_By_PkArgs = {
  model_id: Scalars['String']['input'];
  model_type: Scalars['String']['input'];
  role_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_NotificationsArgs = {
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Nova_Field_AttachmentsArgs = {
  where: Nova_Field_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nova_Field_Attachments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Nova_NotificationsArgs = {
  where: Nova_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nova_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Nova_Pending_Field_AttachmentsArgs = {
  where: Nova_Pending_Field_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nova_Pending_Field_Attachments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Nova_TwofaArgs = {
  where: Nova_Twofa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Nova_Twofa_By_PkArgs = {
  id: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Pairing_CodesArgs = {
  where: Pairing_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Password_Reset_TokensArgs = {
  where: Password_Reset_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Password_Reset_Tokens_By_PkArgs = {
  email: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootDelete_PermissionsArgs = {
  where: Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Permissions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Personal_Access_TokensArgs = {
  where: Personal_Access_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Personal_Access_Tokens_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Personal_DashboardArgs = {
  where: Personal_Dashboard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Personal_Dashboard_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Reel_CategoryArgs = {
  where: Reel_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reel_Category_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Reel_CommentsArgs = {
  where: Reel_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reel_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Reel_InteractionsArgs = {
  where: Reel_Interactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reel_Interactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Reel_User_ReportsArgs = {
  where: Reel_User_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reel_User_Reports_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_ReelsArgs = {
  where: Reels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Reels_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Reels_With_DistanceArgs = {
  where: Reels_With_Distance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_Has_PermissionsArgs = {
  where: Role_Has_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Role_Has_Permissions_By_PkArgs = {
  permission_id: Scalars['bigint']['input'];
  role_id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_RolesArgs = {
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Roles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Segment_UserArgs = {
  where: Segment_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Segment_User_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Service_Request_DashboardArgs = {
  where: Service_Request_Dashboard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_Request_Dashboard_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Service_RequestsArgs = {
  where: Service_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Service_Requests_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_SnapshotsArgs = {
  where: Snapshots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Snapshots_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_SysArgs = {
  where: Spatial_Ref_Sys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Spatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Stored_EventsArgs = {
  where: Stored_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Stored_Events_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_TaggablesArgs = {
  where: Taggables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_TagsArgs = {
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Tags_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_DeviceArgs = {
  where: User_Device_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Device_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_FeedbacksArgs = {
  where: User_Feedbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Feedbacks_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_User_Location_LogsArgs = {
  where: User_Location_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_User_Location_Logs_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_UsersArgs = {
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Users_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


/** mutation root */
export type Mutation_RootDelete_Whatsauth_LoginsArgs = {
  where: Whatsauth_Logins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootDelete_Whatsauth_Logins_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


/** mutation root */
export type Mutation_RootFarmerAcceptsVisitScheduleArgs = {
  command: FarmerAcceptsVisitScheduleCommandInput;
};


/** mutation root */
export type Mutation_RootFarmerCancelsServiceRequestArgs = {
  command: FarmerCancelsServiceRequestCommandInput;
};


/** mutation root */
export type Mutation_RootFarmerClosesResponseArgs = {
  command: FarmerClosesResponseCommandInput;
};


/** mutation root */
export type Mutation_RootFarmerClosesServiceRequestArgs = {
  command: FarmerClosesRequestCommandInput;
};


/** mutation root */
export type Mutation_RootFarmerCreatesServiceRequestArgs = {
  command: CreateServiceRequestCommandInput;
};


/** mutation root */
export type Mutation_RootFarmerRatesAndReviewsServiceRequestArgs = {
  command: FarmerGivesFeedbackToFieldPartnerCommandInput;
};


/** mutation root */
export type Mutation_RootFfWentOfflineArgs = {
  command: FieldPartnerGoesOfflineInput;
};


/** mutation root */
export type Mutation_RootFfWentOnlineArgs = {
  command: FieldPartnerGoesOnlineInput;
};


/** mutation root */
export type Mutation_RootFieldPartnerCancelsAVisitArgs = {
  command: FieldPartnerCancelsAVisitCommandInput;
};


/** mutation root */
export type Mutation_RootFieldPartnerClosesRequestArgs = {
  command: FieldPartnerCloseRequestCommandInput;
};


/** mutation root */
export type Mutation_RootFieldPartnerRatesAndReviewsFarmerArgs = {
  command: FieldPartnerGivesFeedbackToFarmerCommandInput;
};


/** mutation root */
export type Mutation_RootFieldPartnerRespondsToRequestArgs = {
  command: FieldPartnerRespondsToServiceRequestCommandInput;
};


/** mutation root */
export type Mutation_RootFieldPartnerSchedulesAVisitArgs = {
  command: FieldPartnerSchedulesAVisitCommandInput;
};


/** mutation root */
export type Mutation_RootFieldPartnerUpdatesHisCropsArgs = {
  command: FieldPartnerUpdatesHisCropsCommandInput;
};


/** mutation root */
export type Mutation_RootFieldPartnerUpdatesHisSegmentsArgs = {
  command: FieldPartnerUpdatesHisSegmentsCommandInput;
};


/** mutation root */
export type Mutation_RootInsert_Account_CropArgs = {
  objects: Array<Account_Crop_Insert_Input>;
  on_conflict?: InputMaybe<Account_Crop_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Crop_OneArgs = {
  object: Account_Crop_Insert_Input;
  on_conflict?: InputMaybe<Account_Crop_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_SegmentArgs = {
  objects: Array<Account_Segment_Insert_Input>;
  on_conflict?: InputMaybe<Account_Segment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_Segment_OneArgs = {
  object: Account_Segment_Insert_Input;
  on_conflict?: InputMaybe<Account_Segment_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_UserArgs = {
  objects: Array<Account_User_Insert_Input>;
  on_conflict?: InputMaybe<Account_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Account_User_OneArgs = {
  object: Account_User_Insert_Input;
  on_conflict?: InputMaybe<Account_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_AccountsArgs = {
  objects: Array<Accounts_Insert_Input>;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Accounts_OneArgs = {
  object: Accounts_Insert_Input;
  on_conflict?: InputMaybe<Accounts_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Action_EventsArgs = {
  objects: Array<Action_Events_Insert_Input>;
  on_conflict?: InputMaybe<Action_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Action_Events_OneArgs = {
  object: Action_Events_Insert_Input;
  on_conflict?: InputMaybe<Action_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_CompaniesArgs = {
  objects: Array<Companies_Insert_Input>;
  on_conflict?: InputMaybe<Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Companies_OneArgs = {
  object: Companies_Insert_Input;
  on_conflict?: InputMaybe<Companies_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Crop_UserArgs = {
  objects: Array<Crop_User_Insert_Input>;
  on_conflict?: InputMaybe<Crop_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Crop_User_OneArgs = {
  object: Crop_User_Insert_Input;
  on_conflict?: InputMaybe<Crop_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Failed_JobsArgs = {
  objects: Array<Failed_Jobs_Insert_Input>;
  on_conflict?: InputMaybe<Failed_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Failed_Jobs_OneArgs = {
  object: Failed_Jobs_Insert_Input;
  on_conflict?: InputMaybe<Failed_Jobs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farmer_Nearby_Field_ForceArgs = {
  objects: Array<Farmer_Nearby_Field_Force_Insert_Input>;
  on_conflict?: InputMaybe<Farmer_Nearby_Field_Force_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farmer_Nearby_Field_Force_OneArgs = {
  object: Farmer_Nearby_Field_Force_Insert_Input;
  on_conflict?: InputMaybe<Farmer_Nearby_Field_Force_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farmer_PlotsArgs = {
  objects: Array<Farmer_Plots_Insert_Input>;
  on_conflict?: InputMaybe<Farmer_Plots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Farmer_Plots_OneArgs = {
  object: Farmer_Plots_Insert_Input;
  on_conflict?: InputMaybe<Farmer_Plots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Partner_Response_EventsArgs = {
  objects: Array<Field_Partner_Response_Events_Insert_Input>;
  on_conflict?: InputMaybe<Field_Partner_Response_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Partner_Response_Events_OneArgs = {
  object: Field_Partner_Response_Events_Insert_Input;
  on_conflict?: InputMaybe<Field_Partner_Response_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Partner_Response_MessagesArgs = {
  objects: Array<Field_Partner_Response_Messages_Insert_Input>;
  on_conflict?: InputMaybe<Field_Partner_Response_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Partner_Response_Messages_OneArgs = {
  object: Field_Partner_Response_Messages_Insert_Input;
  on_conflict?: InputMaybe<Field_Partner_Response_Messages_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Partner_ResponsesArgs = {
  objects: Array<Field_Partner_Responses_Insert_Input>;
  on_conflict?: InputMaybe<Field_Partner_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Partner_Responses_OneArgs = {
  object: Field_Partner_Responses_Insert_Input;
  on_conflict?: InputMaybe<Field_Partner_Responses_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Scheduled_VisitsArgs = {
  objects: Array<Field_Scheduled_Visits_Insert_Input>;
  on_conflict?: InputMaybe<Field_Scheduled_Visits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Field_Scheduled_Visits_OneArgs = {
  object: Field_Scheduled_Visits_Insert_Input;
  on_conflict?: InputMaybe<Field_Scheduled_Visits_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_FollowsArgs = {
  objects: Array<Follows_Insert_Input>;
  on_conflict?: InputMaybe<Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Follows_OneArgs = {
  object: Follows_Insert_Input;
  on_conflict?: InputMaybe<Follows_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Geometry_ColumnsArgs = {
  objects: Array<Geometry_Columns_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Geometry_Columns_OneArgs = {
  object: Geometry_Columns_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Last_Known_LocationsArgs = {
  objects: Array<Last_Known_Locations_Insert_Input>;
  on_conflict?: InputMaybe<Last_Known_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Last_Known_Locations_OneArgs = {
  object: Last_Known_Locations_Insert_Input;
  on_conflict?: InputMaybe<Last_Known_Locations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_CropsArgs = {
  objects: Array<Master_Crops_Insert_Input>;
  on_conflict?: InputMaybe<Master_Crops_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Crops_OneArgs = {
  object: Master_Crops_Insert_Input;
  on_conflict?: InputMaybe<Master_Crops_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_SegmentsArgs = {
  objects: Array<Master_Segments_Insert_Input>;
  on_conflict?: InputMaybe<Master_Segments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Master_Segments_OneArgs = {
  object: Master_Segments_Insert_Input;
  on_conflict?: InputMaybe<Master_Segments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_MigrationsArgs = {
  objects: Array<Migrations_Insert_Input>;
  on_conflict?: InputMaybe<Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Migrations_OneArgs = {
  object: Migrations_Insert_Input;
  on_conflict?: InputMaybe<Migrations_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Model_Has_PermissionsArgs = {
  objects: Array<Model_Has_Permissions_Insert_Input>;
  on_conflict?: InputMaybe<Model_Has_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Model_Has_Permissions_OneArgs = {
  object: Model_Has_Permissions_Insert_Input;
  on_conflict?: InputMaybe<Model_Has_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Model_Has_RolesArgs = {
  objects: Array<Model_Has_Roles_Insert_Input>;
  on_conflict?: InputMaybe<Model_Has_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Model_Has_Roles_OneArgs = {
  object: Model_Has_Roles_Insert_Input;
  on_conflict?: InputMaybe<Model_Has_Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_NotificationsArgs = {
  objects: Array<Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Notifications_OneArgs = {
  object: Notifications_Insert_Input;
  on_conflict?: InputMaybe<Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nova_Field_AttachmentsArgs = {
  objects: Array<Nova_Field_Attachments_Insert_Input>;
  on_conflict?: InputMaybe<Nova_Field_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nova_Field_Attachments_OneArgs = {
  object: Nova_Field_Attachments_Insert_Input;
  on_conflict?: InputMaybe<Nova_Field_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nova_NotificationsArgs = {
  objects: Array<Nova_Notifications_Insert_Input>;
  on_conflict?: InputMaybe<Nova_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nova_Notifications_OneArgs = {
  object: Nova_Notifications_Insert_Input;
  on_conflict?: InputMaybe<Nova_Notifications_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nova_Pending_Field_AttachmentsArgs = {
  objects: Array<Nova_Pending_Field_Attachments_Insert_Input>;
  on_conflict?: InputMaybe<Nova_Pending_Field_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nova_Pending_Field_Attachments_OneArgs = {
  object: Nova_Pending_Field_Attachments_Insert_Input;
  on_conflict?: InputMaybe<Nova_Pending_Field_Attachments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nova_TwofaArgs = {
  objects: Array<Nova_Twofa_Insert_Input>;
  on_conflict?: InputMaybe<Nova_Twofa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Nova_Twofa_OneArgs = {
  object: Nova_Twofa_Insert_Input;
  on_conflict?: InputMaybe<Nova_Twofa_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pairing_CodesArgs = {
  objects: Array<Pairing_Codes_Insert_Input>;
  on_conflict?: InputMaybe<Pairing_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Pairing_Codes_OneArgs = {
  object: Pairing_Codes_Insert_Input;
  on_conflict?: InputMaybe<Pairing_Codes_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Password_Reset_TokensArgs = {
  objects: Array<Password_Reset_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Password_Reset_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Password_Reset_Tokens_OneArgs = {
  object: Password_Reset_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Password_Reset_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_PermissionsArgs = {
  objects: Array<Permissions_Insert_Input>;
  on_conflict?: InputMaybe<Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Permissions_OneArgs = {
  object: Permissions_Insert_Input;
  on_conflict?: InputMaybe<Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Personal_Access_TokensArgs = {
  objects: Array<Personal_Access_Tokens_Insert_Input>;
  on_conflict?: InputMaybe<Personal_Access_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Personal_Access_Tokens_OneArgs = {
  object: Personal_Access_Tokens_Insert_Input;
  on_conflict?: InputMaybe<Personal_Access_Tokens_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Personal_DashboardArgs = {
  objects: Array<Personal_Dashboard_Insert_Input>;
  on_conflict?: InputMaybe<Personal_Dashboard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Personal_Dashboard_OneArgs = {
  object: Personal_Dashboard_Insert_Input;
  on_conflict?: InputMaybe<Personal_Dashboard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reel_CategoryArgs = {
  objects: Array<Reel_Category_Insert_Input>;
  on_conflict?: InputMaybe<Reel_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reel_Category_OneArgs = {
  object: Reel_Category_Insert_Input;
  on_conflict?: InputMaybe<Reel_Category_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reel_CommentsArgs = {
  objects: Array<Reel_Comments_Insert_Input>;
  on_conflict?: InputMaybe<Reel_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reel_Comments_OneArgs = {
  object: Reel_Comments_Insert_Input;
  on_conflict?: InputMaybe<Reel_Comments_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reel_InteractionsArgs = {
  objects: Array<Reel_Interactions_Insert_Input>;
  on_conflict?: InputMaybe<Reel_Interactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reel_Interactions_OneArgs = {
  object: Reel_Interactions_Insert_Input;
  on_conflict?: InputMaybe<Reel_Interactions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reel_User_ReportsArgs = {
  objects: Array<Reel_User_Reports_Insert_Input>;
  on_conflict?: InputMaybe<Reel_User_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reel_User_Reports_OneArgs = {
  object: Reel_User_Reports_Insert_Input;
  on_conflict?: InputMaybe<Reel_User_Reports_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_ReelsArgs = {
  objects: Array<Reels_Insert_Input>;
  on_conflict?: InputMaybe<Reels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reels_OneArgs = {
  object: Reels_Insert_Input;
  on_conflict?: InputMaybe<Reels_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Reels_With_DistanceArgs = {
  objects: Array<Reels_With_Distance_Insert_Input>;
};


/** mutation root */
export type Mutation_RootInsert_Reels_With_Distance_OneArgs = {
  object: Reels_With_Distance_Insert_Input;
};


/** mutation root */
export type Mutation_RootInsert_Role_Has_PermissionsArgs = {
  objects: Array<Role_Has_Permissions_Insert_Input>;
  on_conflict?: InputMaybe<Role_Has_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Role_Has_Permissions_OneArgs = {
  object: Role_Has_Permissions_Insert_Input;
  on_conflict?: InputMaybe<Role_Has_Permissions_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_RolesArgs = {
  objects: Array<Roles_Insert_Input>;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Roles_OneArgs = {
  object: Roles_Insert_Input;
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Segment_UserArgs = {
  objects: Array<Segment_User_Insert_Input>;
  on_conflict?: InputMaybe<Segment_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Segment_User_OneArgs = {
  object: Segment_User_Insert_Input;
  on_conflict?: InputMaybe<Segment_User_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Request_DashboardArgs = {
  objects: Array<Service_Request_Dashboard_Insert_Input>;
  on_conflict?: InputMaybe<Service_Request_Dashboard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Request_Dashboard_OneArgs = {
  object: Service_Request_Dashboard_Insert_Input;
  on_conflict?: InputMaybe<Service_Request_Dashboard_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_RequestsArgs = {
  objects: Array<Service_Requests_Insert_Input>;
  on_conflict?: InputMaybe<Service_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Service_Requests_OneArgs = {
  object: Service_Requests_Insert_Input;
  on_conflict?: InputMaybe<Service_Requests_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_SnapshotsArgs = {
  objects: Array<Snapshots_Insert_Input>;
  on_conflict?: InputMaybe<Snapshots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Snapshots_OneArgs = {
  object: Snapshots_Insert_Input;
  on_conflict?: InputMaybe<Snapshots_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_SysArgs = {
  objects: Array<Spatial_Ref_Sys_Insert_Input>;
  on_conflict?: InputMaybe<Spatial_Ref_Sys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Spatial_Ref_Sys_OneArgs = {
  object: Spatial_Ref_Sys_Insert_Input;
  on_conflict?: InputMaybe<Spatial_Ref_Sys_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stored_EventsArgs = {
  objects: Array<Stored_Events_Insert_Input>;
  on_conflict?: InputMaybe<Stored_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Stored_Events_OneArgs = {
  object: Stored_Events_Insert_Input;
  on_conflict?: InputMaybe<Stored_Events_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TaggablesArgs = {
  objects: Array<Taggables_Insert_Input>;
  on_conflict?: InputMaybe<Taggables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Taggables_OneArgs = {
  object: Taggables_Insert_Input;
  on_conflict?: InputMaybe<Taggables_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_TagsArgs = {
  objects: Array<Tags_Insert_Input>;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Tags_OneArgs = {
  object: Tags_Insert_Input;
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_DeviceArgs = {
  objects: Array<User_Device_Insert_Input>;
  on_conflict?: InputMaybe<User_Device_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Device_OneArgs = {
  object: User_Device_Insert_Input;
  on_conflict?: InputMaybe<User_Device_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_FeedbacksArgs = {
  objects: Array<User_Feedbacks_Insert_Input>;
  on_conflict?: InputMaybe<User_Feedbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Feedbacks_OneArgs = {
  object: User_Feedbacks_Insert_Input;
  on_conflict?: InputMaybe<User_Feedbacks_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Location_LogsArgs = {
  objects: Array<User_Location_Logs_Insert_Input>;
  on_conflict?: InputMaybe<User_Location_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_User_Location_Logs_OneArgs = {
  object: User_Location_Logs_Insert_Input;
  on_conflict?: InputMaybe<User_Location_Logs_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_UsersArgs = {
  objects: Array<Users_Insert_Input>;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Users_OneArgs = {
  object: Users_Insert_Input;
  on_conflict?: InputMaybe<Users_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Whatsauth_LoginsArgs = {
  objects: Array<Whatsauth_Logins_Insert_Input>;
  on_conflict?: InputMaybe<Whatsauth_Logins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootInsert_Whatsauth_Logins_OneArgs = {
  object: Whatsauth_Logins_Insert_Input;
  on_conflict?: InputMaybe<Whatsauth_Logins_On_Conflict>;
};


/** mutation root */
export type Mutation_RootRequestOtpCommandArgs = {
  command: RequestOtpCommandInput;
};


/** mutation root */
export type Mutation_RootS3UploadDataArgs = {
  expiresInSeconds?: InputMaybe<Scalars['Int']['input']>;
  key: Scalars['String']['input'];
};


/** mutation root */
export type Mutation_RootSendMessageOnServiceRequestArgs = {
  command: SendMessageOnServiceRequestCommandInput;
};


/** mutation root */
export type Mutation_RootSetContentLanguagesArgs = {
  command: UserUpdatesContentLanguagesInput;
};


/** mutation root */
export type Mutation_RootSetFieldForceLocationArgs = {
  command: FieldPartnerUpdatesHisLocationInput;
};


/** mutation root */
export type Mutation_RootSetRoleCommandArgs = {
  command: SetRoleCommandInput;
};


/** mutation root */
export type Mutation_RootUpdate_Account_CropArgs = {
  _inc?: InputMaybe<Account_Crop_Inc_Input>;
  _set?: InputMaybe<Account_Crop_Set_Input>;
  where: Account_Crop_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Crop_By_PkArgs = {
  _inc?: InputMaybe<Account_Crop_Inc_Input>;
  _set?: InputMaybe<Account_Crop_Set_Input>;
  pk_columns: Account_Crop_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Crop_ManyArgs = {
  updates: Array<Account_Crop_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_SegmentArgs = {
  _inc?: InputMaybe<Account_Segment_Inc_Input>;
  _set?: InputMaybe<Account_Segment_Set_Input>;
  where: Account_Segment_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Segment_By_PkArgs = {
  _inc?: InputMaybe<Account_Segment_Inc_Input>;
  _set?: InputMaybe<Account_Segment_Set_Input>;
  pk_columns: Account_Segment_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_Segment_ManyArgs = {
  updates: Array<Account_Segment_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Account_UserArgs = {
  _inc?: InputMaybe<Account_User_Inc_Input>;
  _set?: InputMaybe<Account_User_Set_Input>;
  where: Account_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Account_User_By_PkArgs = {
  _inc?: InputMaybe<Account_User_Inc_Input>;
  _set?: InputMaybe<Account_User_Set_Input>;
  pk_columns: Account_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Account_User_ManyArgs = {
  updates: Array<Account_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_AccountsArgs = {
  _inc?: InputMaybe<Accounts_Inc_Input>;
  _set?: InputMaybe<Accounts_Set_Input>;
  where: Accounts_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_By_PkArgs = {
  _inc?: InputMaybe<Accounts_Inc_Input>;
  _set?: InputMaybe<Accounts_Set_Input>;
  pk_columns: Accounts_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Accounts_ManyArgs = {
  updates: Array<Accounts_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Action_EventsArgs = {
  _inc?: InputMaybe<Action_Events_Inc_Input>;
  _set?: InputMaybe<Action_Events_Set_Input>;
  where: Action_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Action_Events_By_PkArgs = {
  _inc?: InputMaybe<Action_Events_Inc_Input>;
  _set?: InputMaybe<Action_Events_Set_Input>;
  pk_columns: Action_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Action_Events_ManyArgs = {
  updates: Array<Action_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_CompaniesArgs = {
  _inc?: InputMaybe<Companies_Inc_Input>;
  _set?: InputMaybe<Companies_Set_Input>;
  where: Companies_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Companies_By_PkArgs = {
  _inc?: InputMaybe<Companies_Inc_Input>;
  _set?: InputMaybe<Companies_Set_Input>;
  pk_columns: Companies_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Companies_ManyArgs = {
  updates: Array<Companies_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Crop_UserArgs = {
  _inc?: InputMaybe<Crop_User_Inc_Input>;
  _set?: InputMaybe<Crop_User_Set_Input>;
  where: Crop_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Crop_User_By_PkArgs = {
  _inc?: InputMaybe<Crop_User_Inc_Input>;
  _set?: InputMaybe<Crop_User_Set_Input>;
  pk_columns: Crop_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Crop_User_ManyArgs = {
  updates: Array<Crop_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Failed_JobsArgs = {
  _inc?: InputMaybe<Failed_Jobs_Inc_Input>;
  _set?: InputMaybe<Failed_Jobs_Set_Input>;
  where: Failed_Jobs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Failed_Jobs_By_PkArgs = {
  _inc?: InputMaybe<Failed_Jobs_Inc_Input>;
  _set?: InputMaybe<Failed_Jobs_Set_Input>;
  pk_columns: Failed_Jobs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Failed_Jobs_ManyArgs = {
  updates: Array<Failed_Jobs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Farmer_Nearby_Field_ForceArgs = {
  _set?: InputMaybe<Farmer_Nearby_Field_Force_Set_Input>;
  where: Farmer_Nearby_Field_Force_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Farmer_Nearby_Field_Force_By_PkArgs = {
  _set?: InputMaybe<Farmer_Nearby_Field_Force_Set_Input>;
  pk_columns: Farmer_Nearby_Field_Force_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Farmer_Nearby_Field_Force_ManyArgs = {
  updates: Array<Farmer_Nearby_Field_Force_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Farmer_PlotsArgs = {
  _inc?: InputMaybe<Farmer_Plots_Inc_Input>;
  _set?: InputMaybe<Farmer_Plots_Set_Input>;
  where: Farmer_Plots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Farmer_Plots_By_PkArgs = {
  _inc?: InputMaybe<Farmer_Plots_Inc_Input>;
  _set?: InputMaybe<Farmer_Plots_Set_Input>;
  pk_columns: Farmer_Plots_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Farmer_Plots_ManyArgs = {
  updates: Array<Farmer_Plots_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Partner_Response_EventsArgs = {
  _append?: InputMaybe<Field_Partner_Response_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Field_Partner_Response_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Field_Partner_Response_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Field_Partner_Response_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Field_Partner_Response_Events_Prepend_Input>;
  _set?: InputMaybe<Field_Partner_Response_Events_Set_Input>;
  where: Field_Partner_Response_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Partner_Response_Events_By_PkArgs = {
  _append?: InputMaybe<Field_Partner_Response_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Field_Partner_Response_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Field_Partner_Response_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Field_Partner_Response_Events_Delete_Key_Input>;
  _prepend?: InputMaybe<Field_Partner_Response_Events_Prepend_Input>;
  _set?: InputMaybe<Field_Partner_Response_Events_Set_Input>;
  pk_columns: Field_Partner_Response_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Partner_Response_Events_ManyArgs = {
  updates: Array<Field_Partner_Response_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Partner_Response_MessagesArgs = {
  _append?: InputMaybe<Field_Partner_Response_Messages_Append_Input>;
  _delete_at_path?: InputMaybe<Field_Partner_Response_Messages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Field_Partner_Response_Messages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Field_Partner_Response_Messages_Delete_Key_Input>;
  _prepend?: InputMaybe<Field_Partner_Response_Messages_Prepend_Input>;
  _set?: InputMaybe<Field_Partner_Response_Messages_Set_Input>;
  where: Field_Partner_Response_Messages_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Partner_Response_Messages_By_PkArgs = {
  _append?: InputMaybe<Field_Partner_Response_Messages_Append_Input>;
  _delete_at_path?: InputMaybe<Field_Partner_Response_Messages_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Field_Partner_Response_Messages_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Field_Partner_Response_Messages_Delete_Key_Input>;
  _prepend?: InputMaybe<Field_Partner_Response_Messages_Prepend_Input>;
  _set?: InputMaybe<Field_Partner_Response_Messages_Set_Input>;
  pk_columns: Field_Partner_Response_Messages_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Partner_Response_Messages_ManyArgs = {
  updates: Array<Field_Partner_Response_Messages_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Partner_ResponsesArgs = {
  _inc?: InputMaybe<Field_Partner_Responses_Inc_Input>;
  _set?: InputMaybe<Field_Partner_Responses_Set_Input>;
  where: Field_Partner_Responses_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Partner_Responses_By_PkArgs = {
  _inc?: InputMaybe<Field_Partner_Responses_Inc_Input>;
  _set?: InputMaybe<Field_Partner_Responses_Set_Input>;
  pk_columns: Field_Partner_Responses_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Partner_Responses_ManyArgs = {
  updates: Array<Field_Partner_Responses_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Scheduled_VisitsArgs = {
  _append?: InputMaybe<Field_Scheduled_Visits_Append_Input>;
  _delete_at_path?: InputMaybe<Field_Scheduled_Visits_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Field_Scheduled_Visits_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Field_Scheduled_Visits_Delete_Key_Input>;
  _inc?: InputMaybe<Field_Scheduled_Visits_Inc_Input>;
  _prepend?: InputMaybe<Field_Scheduled_Visits_Prepend_Input>;
  _set?: InputMaybe<Field_Scheduled_Visits_Set_Input>;
  where: Field_Scheduled_Visits_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Scheduled_Visits_By_PkArgs = {
  _append?: InputMaybe<Field_Scheduled_Visits_Append_Input>;
  _delete_at_path?: InputMaybe<Field_Scheduled_Visits_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Field_Scheduled_Visits_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Field_Scheduled_Visits_Delete_Key_Input>;
  _inc?: InputMaybe<Field_Scheduled_Visits_Inc_Input>;
  _prepend?: InputMaybe<Field_Scheduled_Visits_Prepend_Input>;
  _set?: InputMaybe<Field_Scheduled_Visits_Set_Input>;
  pk_columns: Field_Scheduled_Visits_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Field_Scheduled_Visits_ManyArgs = {
  updates: Array<Field_Scheduled_Visits_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_FollowsArgs = {
  _inc?: InputMaybe<Follows_Inc_Input>;
  _set?: InputMaybe<Follows_Set_Input>;
  where: Follows_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Follows_By_PkArgs = {
  _inc?: InputMaybe<Follows_Inc_Input>;
  _set?: InputMaybe<Follows_Set_Input>;
  pk_columns: Follows_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Follows_ManyArgs = {
  updates: Array<Follows_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Geometry_ColumnsArgs = {
  _inc?: InputMaybe<Geometry_Columns_Inc_Input>;
  _set?: InputMaybe<Geometry_Columns_Set_Input>;
  where: Geometry_Columns_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Geometry_Columns_ManyArgs = {
  updates: Array<Geometry_Columns_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Last_Known_LocationsArgs = {
  _append?: InputMaybe<Last_Known_Locations_Append_Input>;
  _delete_at_path?: InputMaybe<Last_Known_Locations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Last_Known_Locations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Last_Known_Locations_Delete_Key_Input>;
  _inc?: InputMaybe<Last_Known_Locations_Inc_Input>;
  _prepend?: InputMaybe<Last_Known_Locations_Prepend_Input>;
  _set?: InputMaybe<Last_Known_Locations_Set_Input>;
  where: Last_Known_Locations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Last_Known_Locations_By_PkArgs = {
  _append?: InputMaybe<Last_Known_Locations_Append_Input>;
  _delete_at_path?: InputMaybe<Last_Known_Locations_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Last_Known_Locations_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Last_Known_Locations_Delete_Key_Input>;
  _inc?: InputMaybe<Last_Known_Locations_Inc_Input>;
  _prepend?: InputMaybe<Last_Known_Locations_Prepend_Input>;
  _set?: InputMaybe<Last_Known_Locations_Set_Input>;
  pk_columns: Last_Known_Locations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Last_Known_Locations_ManyArgs = {
  updates: Array<Last_Known_Locations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_CropsArgs = {
  _inc?: InputMaybe<Master_Crops_Inc_Input>;
  _set?: InputMaybe<Master_Crops_Set_Input>;
  where: Master_Crops_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Crops_By_PkArgs = {
  _inc?: InputMaybe<Master_Crops_Inc_Input>;
  _set?: InputMaybe<Master_Crops_Set_Input>;
  pk_columns: Master_Crops_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Crops_ManyArgs = {
  updates: Array<Master_Crops_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Master_SegmentsArgs = {
  _inc?: InputMaybe<Master_Segments_Inc_Input>;
  _set?: InputMaybe<Master_Segments_Set_Input>;
  where: Master_Segments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Segments_By_PkArgs = {
  _inc?: InputMaybe<Master_Segments_Inc_Input>;
  _set?: InputMaybe<Master_Segments_Set_Input>;
  pk_columns: Master_Segments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Master_Segments_ManyArgs = {
  updates: Array<Master_Segments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_MigrationsArgs = {
  _inc?: InputMaybe<Migrations_Inc_Input>;
  _set?: InputMaybe<Migrations_Set_Input>;
  where: Migrations_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Migrations_By_PkArgs = {
  _inc?: InputMaybe<Migrations_Inc_Input>;
  _set?: InputMaybe<Migrations_Set_Input>;
  pk_columns: Migrations_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Migrations_ManyArgs = {
  updates: Array<Migrations_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Has_PermissionsArgs = {
  _inc?: InputMaybe<Model_Has_Permissions_Inc_Input>;
  _set?: InputMaybe<Model_Has_Permissions_Set_Input>;
  where: Model_Has_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Has_Permissions_By_PkArgs = {
  _inc?: InputMaybe<Model_Has_Permissions_Inc_Input>;
  _set?: InputMaybe<Model_Has_Permissions_Set_Input>;
  pk_columns: Model_Has_Permissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Has_Permissions_ManyArgs = {
  updates: Array<Model_Has_Permissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Has_RolesArgs = {
  _inc?: InputMaybe<Model_Has_Roles_Inc_Input>;
  _set?: InputMaybe<Model_Has_Roles_Set_Input>;
  where: Model_Has_Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Has_Roles_By_PkArgs = {
  _inc?: InputMaybe<Model_Has_Roles_Inc_Input>;
  _set?: InputMaybe<Model_Has_Roles_Set_Input>;
  pk_columns: Model_Has_Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Model_Has_Roles_ManyArgs = {
  updates: Array<Model_Has_Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_NotificationsArgs = {
  _set?: InputMaybe<Notifications_Set_Input>;
  where: Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_By_PkArgs = {
  _set?: InputMaybe<Notifications_Set_Input>;
  pk_columns: Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Notifications_ManyArgs = {
  updates: Array<Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_Field_AttachmentsArgs = {
  _inc?: InputMaybe<Nova_Field_Attachments_Inc_Input>;
  _set?: InputMaybe<Nova_Field_Attachments_Set_Input>;
  where: Nova_Field_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_Field_Attachments_By_PkArgs = {
  _inc?: InputMaybe<Nova_Field_Attachments_Inc_Input>;
  _set?: InputMaybe<Nova_Field_Attachments_Set_Input>;
  pk_columns: Nova_Field_Attachments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_Field_Attachments_ManyArgs = {
  updates: Array<Nova_Field_Attachments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_NotificationsArgs = {
  _set?: InputMaybe<Nova_Notifications_Set_Input>;
  where: Nova_Notifications_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_Notifications_By_PkArgs = {
  _set?: InputMaybe<Nova_Notifications_Set_Input>;
  pk_columns: Nova_Notifications_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_Notifications_ManyArgs = {
  updates: Array<Nova_Notifications_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_Pending_Field_AttachmentsArgs = {
  _inc?: InputMaybe<Nova_Pending_Field_Attachments_Inc_Input>;
  _set?: InputMaybe<Nova_Pending_Field_Attachments_Set_Input>;
  where: Nova_Pending_Field_Attachments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_Pending_Field_Attachments_By_PkArgs = {
  _inc?: InputMaybe<Nova_Pending_Field_Attachments_Inc_Input>;
  _set?: InputMaybe<Nova_Pending_Field_Attachments_Set_Input>;
  pk_columns: Nova_Pending_Field_Attachments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_Pending_Field_Attachments_ManyArgs = {
  updates: Array<Nova_Pending_Field_Attachments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_TwofaArgs = {
  _inc?: InputMaybe<Nova_Twofa_Inc_Input>;
  _set?: InputMaybe<Nova_Twofa_Set_Input>;
  where: Nova_Twofa_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_Twofa_By_PkArgs = {
  _inc?: InputMaybe<Nova_Twofa_Inc_Input>;
  _set?: InputMaybe<Nova_Twofa_Set_Input>;
  pk_columns: Nova_Twofa_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Nova_Twofa_ManyArgs = {
  updates: Array<Nova_Twofa_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Pairing_CodesArgs = {
  _set?: InputMaybe<Pairing_Codes_Set_Input>;
  where: Pairing_Codes_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Pairing_Codes_ManyArgs = {
  updates: Array<Pairing_Codes_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Reset_TokensArgs = {
  _set?: InputMaybe<Password_Reset_Tokens_Set_Input>;
  where: Password_Reset_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Reset_Tokens_By_PkArgs = {
  _set?: InputMaybe<Password_Reset_Tokens_Set_Input>;
  pk_columns: Password_Reset_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Password_Reset_Tokens_ManyArgs = {
  updates: Array<Password_Reset_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_PermissionsArgs = {
  _inc?: InputMaybe<Permissions_Inc_Input>;
  _set?: InputMaybe<Permissions_Set_Input>;
  where: Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Permissions_By_PkArgs = {
  _inc?: InputMaybe<Permissions_Inc_Input>;
  _set?: InputMaybe<Permissions_Set_Input>;
  pk_columns: Permissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Permissions_ManyArgs = {
  updates: Array<Permissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Access_TokensArgs = {
  _inc?: InputMaybe<Personal_Access_Tokens_Inc_Input>;
  _set?: InputMaybe<Personal_Access_Tokens_Set_Input>;
  where: Personal_Access_Tokens_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Access_Tokens_By_PkArgs = {
  _inc?: InputMaybe<Personal_Access_Tokens_Inc_Input>;
  _set?: InputMaybe<Personal_Access_Tokens_Set_Input>;
  pk_columns: Personal_Access_Tokens_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Access_Tokens_ManyArgs = {
  updates: Array<Personal_Access_Tokens_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_DashboardArgs = {
  _inc?: InputMaybe<Personal_Dashboard_Inc_Input>;
  _set?: InputMaybe<Personal_Dashboard_Set_Input>;
  where: Personal_Dashboard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Dashboard_By_PkArgs = {
  _inc?: InputMaybe<Personal_Dashboard_Inc_Input>;
  _set?: InputMaybe<Personal_Dashboard_Set_Input>;
  pk_columns: Personal_Dashboard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Personal_Dashboard_ManyArgs = {
  updates: Array<Personal_Dashboard_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_CategoryArgs = {
  _inc?: InputMaybe<Reel_Category_Inc_Input>;
  _set?: InputMaybe<Reel_Category_Set_Input>;
  where: Reel_Category_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_Category_By_PkArgs = {
  _inc?: InputMaybe<Reel_Category_Inc_Input>;
  _set?: InputMaybe<Reel_Category_Set_Input>;
  pk_columns: Reel_Category_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_Category_ManyArgs = {
  updates: Array<Reel_Category_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_CommentsArgs = {
  _inc?: InputMaybe<Reel_Comments_Inc_Input>;
  _set?: InputMaybe<Reel_Comments_Set_Input>;
  where: Reel_Comments_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_Comments_By_PkArgs = {
  _inc?: InputMaybe<Reel_Comments_Inc_Input>;
  _set?: InputMaybe<Reel_Comments_Set_Input>;
  pk_columns: Reel_Comments_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_Comments_ManyArgs = {
  updates: Array<Reel_Comments_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_InteractionsArgs = {
  _inc?: InputMaybe<Reel_Interactions_Inc_Input>;
  _set?: InputMaybe<Reel_Interactions_Set_Input>;
  where: Reel_Interactions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_Interactions_By_PkArgs = {
  _inc?: InputMaybe<Reel_Interactions_Inc_Input>;
  _set?: InputMaybe<Reel_Interactions_Set_Input>;
  pk_columns: Reel_Interactions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_Interactions_ManyArgs = {
  updates: Array<Reel_Interactions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_User_ReportsArgs = {
  _inc?: InputMaybe<Reel_User_Reports_Inc_Input>;
  _set?: InputMaybe<Reel_User_Reports_Set_Input>;
  where: Reel_User_Reports_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_User_Reports_By_PkArgs = {
  _inc?: InputMaybe<Reel_User_Reports_Inc_Input>;
  _set?: InputMaybe<Reel_User_Reports_Set_Input>;
  pk_columns: Reel_User_Reports_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reel_User_Reports_ManyArgs = {
  updates: Array<Reel_User_Reports_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_ReelsArgs = {
  _inc?: InputMaybe<Reels_Inc_Input>;
  _set?: InputMaybe<Reels_Set_Input>;
  where: Reels_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reels_By_PkArgs = {
  _inc?: InputMaybe<Reels_Inc_Input>;
  _set?: InputMaybe<Reels_Set_Input>;
  pk_columns: Reels_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Reels_ManyArgs = {
  updates: Array<Reels_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Reels_With_DistanceArgs = {
  _inc?: InputMaybe<Reels_With_Distance_Inc_Input>;
  _set?: InputMaybe<Reels_With_Distance_Set_Input>;
  where: Reels_With_Distance_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Reels_With_Distance_ManyArgs = {
  updates: Array<Reels_With_Distance_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Has_PermissionsArgs = {
  _inc?: InputMaybe<Role_Has_Permissions_Inc_Input>;
  _set?: InputMaybe<Role_Has_Permissions_Set_Input>;
  where: Role_Has_Permissions_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Has_Permissions_By_PkArgs = {
  _inc?: InputMaybe<Role_Has_Permissions_Inc_Input>;
  _set?: InputMaybe<Role_Has_Permissions_Set_Input>;
  pk_columns: Role_Has_Permissions_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Role_Has_Permissions_ManyArgs = {
  updates: Array<Role_Has_Permissions_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_RolesArgs = {
  _inc?: InputMaybe<Roles_Inc_Input>;
  _set?: InputMaybe<Roles_Set_Input>;
  where: Roles_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_By_PkArgs = {
  _inc?: InputMaybe<Roles_Inc_Input>;
  _set?: InputMaybe<Roles_Set_Input>;
  pk_columns: Roles_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Roles_ManyArgs = {
  updates: Array<Roles_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Segment_UserArgs = {
  _inc?: InputMaybe<Segment_User_Inc_Input>;
  _set?: InputMaybe<Segment_User_Set_Input>;
  where: Segment_User_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Segment_User_By_PkArgs = {
  _inc?: InputMaybe<Segment_User_Inc_Input>;
  _set?: InputMaybe<Segment_User_Set_Input>;
  pk_columns: Segment_User_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Segment_User_ManyArgs = {
  updates: Array<Segment_User_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Request_DashboardArgs = {
  _inc?: InputMaybe<Service_Request_Dashboard_Inc_Input>;
  _set?: InputMaybe<Service_Request_Dashboard_Set_Input>;
  where: Service_Request_Dashboard_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Request_Dashboard_By_PkArgs = {
  _inc?: InputMaybe<Service_Request_Dashboard_Inc_Input>;
  _set?: InputMaybe<Service_Request_Dashboard_Set_Input>;
  pk_columns: Service_Request_Dashboard_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Request_Dashboard_ManyArgs = {
  updates: Array<Service_Request_Dashboard_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Service_RequestsArgs = {
  _append?: InputMaybe<Service_Requests_Append_Input>;
  _delete_at_path?: InputMaybe<Service_Requests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Service_Requests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Service_Requests_Delete_Key_Input>;
  _inc?: InputMaybe<Service_Requests_Inc_Input>;
  _prepend?: InputMaybe<Service_Requests_Prepend_Input>;
  _set?: InputMaybe<Service_Requests_Set_Input>;
  where: Service_Requests_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Requests_By_PkArgs = {
  _append?: InputMaybe<Service_Requests_Append_Input>;
  _delete_at_path?: InputMaybe<Service_Requests_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Service_Requests_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Service_Requests_Delete_Key_Input>;
  _inc?: InputMaybe<Service_Requests_Inc_Input>;
  _prepend?: InputMaybe<Service_Requests_Prepend_Input>;
  _set?: InputMaybe<Service_Requests_Set_Input>;
  pk_columns: Service_Requests_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Service_Requests_ManyArgs = {
  updates: Array<Service_Requests_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_SnapshotsArgs = {
  _append?: InputMaybe<Snapshots_Append_Input>;
  _delete_at_path?: InputMaybe<Snapshots_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Snapshots_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Snapshots_Delete_Key_Input>;
  _inc?: InputMaybe<Snapshots_Inc_Input>;
  _prepend?: InputMaybe<Snapshots_Prepend_Input>;
  _set?: InputMaybe<Snapshots_Set_Input>;
  where: Snapshots_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Snapshots_By_PkArgs = {
  _append?: InputMaybe<Snapshots_Append_Input>;
  _delete_at_path?: InputMaybe<Snapshots_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Snapshots_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Snapshots_Delete_Key_Input>;
  _inc?: InputMaybe<Snapshots_Inc_Input>;
  _prepend?: InputMaybe<Snapshots_Prepend_Input>;
  _set?: InputMaybe<Snapshots_Set_Input>;
  pk_columns: Snapshots_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Snapshots_ManyArgs = {
  updates: Array<Snapshots_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_SysArgs = {
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  where: Spatial_Ref_Sys_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_By_PkArgs = {
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  pk_columns: Spatial_Ref_Sys_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Spatial_Ref_Sys_ManyArgs = {
  updates: Array<Spatial_Ref_Sys_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Stored_EventsArgs = {
  _append?: InputMaybe<Stored_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Stored_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stored_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stored_Events_Delete_Key_Input>;
  _inc?: InputMaybe<Stored_Events_Inc_Input>;
  _prepend?: InputMaybe<Stored_Events_Prepend_Input>;
  _set?: InputMaybe<Stored_Events_Set_Input>;
  where: Stored_Events_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Stored_Events_By_PkArgs = {
  _append?: InputMaybe<Stored_Events_Append_Input>;
  _delete_at_path?: InputMaybe<Stored_Events_Delete_At_Path_Input>;
  _delete_elem?: InputMaybe<Stored_Events_Delete_Elem_Input>;
  _delete_key?: InputMaybe<Stored_Events_Delete_Key_Input>;
  _inc?: InputMaybe<Stored_Events_Inc_Input>;
  _prepend?: InputMaybe<Stored_Events_Prepend_Input>;
  _set?: InputMaybe<Stored_Events_Set_Input>;
  pk_columns: Stored_Events_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Stored_Events_ManyArgs = {
  updates: Array<Stored_Events_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TaggablesArgs = {
  _inc?: InputMaybe<Taggables_Inc_Input>;
  _set?: InputMaybe<Taggables_Set_Input>;
  where: Taggables_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Taggables_ManyArgs = {
  updates: Array<Taggables_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_TagsArgs = {
  _inc?: InputMaybe<Tags_Inc_Input>;
  _set?: InputMaybe<Tags_Set_Input>;
  where: Tags_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_By_PkArgs = {
  _inc?: InputMaybe<Tags_Inc_Input>;
  _set?: InputMaybe<Tags_Set_Input>;
  pk_columns: Tags_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Tags_ManyArgs = {
  updates: Array<Tags_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_DeviceArgs = {
  _inc?: InputMaybe<User_Device_Inc_Input>;
  _set?: InputMaybe<User_Device_Set_Input>;
  where: User_Device_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Device_By_PkArgs = {
  _inc?: InputMaybe<User_Device_Inc_Input>;
  _set?: InputMaybe<User_Device_Set_Input>;
  pk_columns: User_Device_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Device_ManyArgs = {
  updates: Array<User_Device_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_FeedbacksArgs = {
  _inc?: InputMaybe<User_Feedbacks_Inc_Input>;
  _set?: InputMaybe<User_Feedbacks_Set_Input>;
  where: User_Feedbacks_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Feedbacks_By_PkArgs = {
  _inc?: InputMaybe<User_Feedbacks_Inc_Input>;
  _set?: InputMaybe<User_Feedbacks_Set_Input>;
  pk_columns: User_Feedbacks_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Feedbacks_ManyArgs = {
  updates: Array<User_Feedbacks_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_User_Location_LogsArgs = {
  _inc?: InputMaybe<User_Location_Logs_Inc_Input>;
  _set?: InputMaybe<User_Location_Logs_Set_Input>;
  where: User_Location_Logs_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_User_Location_Logs_By_PkArgs = {
  _inc?: InputMaybe<User_Location_Logs_Inc_Input>;
  _set?: InputMaybe<User_Location_Logs_Set_Input>;
  pk_columns: User_Location_Logs_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_User_Location_Logs_ManyArgs = {
  updates: Array<User_Location_Logs_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_UsersArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  where: Users_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Users_By_PkArgs = {
  _inc?: InputMaybe<Users_Inc_Input>;
  _set?: InputMaybe<Users_Set_Input>;
  pk_columns: Users_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Users_ManyArgs = {
  updates: Array<Users_Updates>;
};


/** mutation root */
export type Mutation_RootUpdate_Whatsauth_LoginsArgs = {
  _inc?: InputMaybe<Whatsauth_Logins_Inc_Input>;
  _set?: InputMaybe<Whatsauth_Logins_Set_Input>;
  where: Whatsauth_Logins_Bool_Exp;
};


/** mutation root */
export type Mutation_RootUpdate_Whatsauth_Logins_By_PkArgs = {
  _inc?: InputMaybe<Whatsauth_Logins_Inc_Input>;
  _set?: InputMaybe<Whatsauth_Logins_Set_Input>;
  pk_columns: Whatsauth_Logins_Pk_Columns_Input;
};


/** mutation root */
export type Mutation_RootUpdate_Whatsauth_Logins_ManyArgs = {
  updates: Array<Whatsauth_Logins_Updates>;
};


/** mutation root */
export type Mutation_RootUserFollowsProfileCommandArgs = {
  command: UserFollowsProfileCommandInput;
};


/** mutation root */
export type Mutation_RootUserLikedReelArgs = {
  command: UserLikedReelInput;
};


/** mutation root */
export type Mutation_RootUserReactedReelArgs = {
  command: UserReactedReelInput;
};


/** mutation root */
export type Mutation_RootUserReportsCommentArgs = {
  command: UserReportsCommentCommandInput;
};


/** mutation root */
export type Mutation_RootUserReportsReelArgs = {
  command: UserReportsReelCommandInput;
};


/** mutation root */
export type Mutation_RootUserRequestsAccountDeletionArgs = {
  command: UserRequestsAccountDeletionCommandInput;
};


/** mutation root */
export type Mutation_RootUserSetHisLocaleArgs = {
  command: SetUserLocaleCommandInput;
};


/** mutation root */
export type Mutation_RootUserSharedReelArgs = {
  command: UserSharedReelInput;
};


/** mutation root */
export type Mutation_RootUserUpdatesHisDeviceInfoArgs = {
  command: UserUpdatesHisDeviceInfoInput;
};


/** mutation root */
export type Mutation_RootUserUpdatesHisProfileArgs = {
  command: UserUpdatesHisProfileCommandInput;
};


/** mutation root */
export type Mutation_RootUserViewedReelArgs = {
  command: UserViewedReelInput;
};


/** mutation root */
export type Mutation_RootVerifyOtpCommandArgs = {
  command: VerifyOtpCommandInput;
};

/** Boolean expression to compare columns of type "name". All fields are combined with logical 'AND'. */
export type Name_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['name']['input']>;
  _gt?: InputMaybe<Scalars['name']['input']>;
  _gte?: InputMaybe<Scalars['name']['input']>;
  _in?: InputMaybe<Array<Scalars['name']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['name']['input']>;
  _lte?: InputMaybe<Scalars['name']['input']>;
  _neq?: InputMaybe<Scalars['name']['input']>;
  _nin?: InputMaybe<Array<Scalars['name']['input']>>;
};

/** columns and relationships of "notifications" */
export type Notifications = {
  __typename?: 'notifications';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  data: Scalars['String']['output'];
  id: Scalars['uuid']['output'];
  notifiable_id: Scalars['bpchar']['output'];
  notifiable_type: Scalars['String']['output'];
  read_at?: Maybe<Scalars['timestamp']['output']>;
  type: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "notifications" */
export type Notifications_Aggregate = {
  __typename?: 'notifications_aggregate';
  aggregate?: Maybe<Notifications_Aggregate_Fields>;
  nodes: Array<Notifications>;
};

/** aggregate fields of "notifications" */
export type Notifications_Aggregate_Fields = {
  __typename?: 'notifications_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Notifications_Max_Fields>;
  min?: Maybe<Notifications_Min_Fields>;
};


/** aggregate fields of "notifications" */
export type Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "notifications". All fields are combined with a logical 'AND'. */
export type Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Notifications_Bool_Exp>>;
  _not?: InputMaybe<Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Notifications_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notifiable_id?: InputMaybe<Bpchar_Comparison_Exp>;
  notifiable_type?: InputMaybe<String_Comparison_Exp>;
  read_at?: InputMaybe<Timestamp_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "notifications" */
export enum Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NotificationsPkey = 'notifications_pkey'
}

/** input type for inserting data into table "notifications" */
export type Notifications_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notifiable_id?: InputMaybe<Scalars['bpchar']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  read_at?: InputMaybe<Scalars['timestamp']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Notifications_Max_Fields = {
  __typename?: 'notifications_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notifiable_id?: Maybe<Scalars['bpchar']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  read_at?: Maybe<Scalars['timestamp']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Notifications_Min_Fields = {
  __typename?: 'notifications_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notifiable_id?: Maybe<Scalars['bpchar']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  read_at?: Maybe<Scalars['timestamp']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "notifications" */
export type Notifications_Mutation_Response = {
  __typename?: 'notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Notifications>;
};

/** on_conflict condition type for table "notifications" */
export type Notifications_On_Conflict = {
  constraint: Notifications_Constraint;
  update_columns?: Array<Notifications_Update_Column>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "notifications". */
export type Notifications_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notifiable_id?: InputMaybe<Order_By>;
  notifiable_type?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: notifications */
export type Notifications_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "notifications" */
export enum Notifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "notifications" */
export type Notifications_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notifiable_id?: InputMaybe<Scalars['bpchar']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  read_at?: InputMaybe<Scalars['timestamp']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "notifications" */
export type Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Notifications_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notifiable_id?: InputMaybe<Scalars['bpchar']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  read_at?: InputMaybe<Scalars['timestamp']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "notifications" */
export enum Notifications_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Notifications_Bool_Exp;
};

/** columns and relationships of "nova_field_attachments" */
export type Nova_Field_Attachments = {
  __typename?: 'nova_field_attachments';
  attachable_id: Scalars['bigint']['output'];
  attachable_type: Scalars['String']['output'];
  attachment: Scalars['String']['output'];
  created_at?: Maybe<Scalars['timestamp']['output']>;
  disk: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url: Scalars['String']['output'];
};

/** aggregated selection of "nova_field_attachments" */
export type Nova_Field_Attachments_Aggregate = {
  __typename?: 'nova_field_attachments_aggregate';
  aggregate?: Maybe<Nova_Field_Attachments_Aggregate_Fields>;
  nodes: Array<Nova_Field_Attachments>;
};

/** aggregate fields of "nova_field_attachments" */
export type Nova_Field_Attachments_Aggregate_Fields = {
  __typename?: 'nova_field_attachments_aggregate_fields';
  avg?: Maybe<Nova_Field_Attachments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Nova_Field_Attachments_Max_Fields>;
  min?: Maybe<Nova_Field_Attachments_Min_Fields>;
  stddev?: Maybe<Nova_Field_Attachments_Stddev_Fields>;
  stddev_pop?: Maybe<Nova_Field_Attachments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nova_Field_Attachments_Stddev_Samp_Fields>;
  sum?: Maybe<Nova_Field_Attachments_Sum_Fields>;
  var_pop?: Maybe<Nova_Field_Attachments_Var_Pop_Fields>;
  var_samp?: Maybe<Nova_Field_Attachments_Var_Samp_Fields>;
  variance?: Maybe<Nova_Field_Attachments_Variance_Fields>;
};


/** aggregate fields of "nova_field_attachments" */
export type Nova_Field_Attachments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nova_Field_Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Nova_Field_Attachments_Avg_Fields = {
  __typename?: 'nova_field_attachments_avg_fields';
  attachable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "nova_field_attachments". All fields are combined with a logical 'AND'. */
export type Nova_Field_Attachments_Bool_Exp = {
  _and?: InputMaybe<Array<Nova_Field_Attachments_Bool_Exp>>;
  _not?: InputMaybe<Nova_Field_Attachments_Bool_Exp>;
  _or?: InputMaybe<Array<Nova_Field_Attachments_Bool_Exp>>;
  attachable_id?: InputMaybe<Bigint_Comparison_Exp>;
  attachable_type?: InputMaybe<String_Comparison_Exp>;
  attachment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  disk?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  url?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "nova_field_attachments" */
export enum Nova_Field_Attachments_Constraint {
  /** unique or primary key constraint on columns "id" */
  NovaFieldAttachmentsPkey = 'nova_field_attachments_pkey'
}

/** input type for incrementing numeric columns in table "nova_field_attachments" */
export type Nova_Field_Attachments_Inc_Input = {
  attachable_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "nova_field_attachments" */
export type Nova_Field_Attachments_Insert_Input = {
  attachable_id?: InputMaybe<Scalars['bigint']['input']>;
  attachable_type?: InputMaybe<Scalars['String']['input']>;
  attachment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  disk?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Nova_Field_Attachments_Max_Fields = {
  __typename?: 'nova_field_attachments_max_fields';
  attachable_id?: Maybe<Scalars['bigint']['output']>;
  attachable_type?: Maybe<Scalars['String']['output']>;
  attachment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  disk?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Nova_Field_Attachments_Min_Fields = {
  __typename?: 'nova_field_attachments_min_fields';
  attachable_id?: Maybe<Scalars['bigint']['output']>;
  attachable_type?: Maybe<Scalars['String']['output']>;
  attachment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  disk?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  url?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "nova_field_attachments" */
export type Nova_Field_Attachments_Mutation_Response = {
  __typename?: 'nova_field_attachments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Nova_Field_Attachments>;
};

/** on_conflict condition type for table "nova_field_attachments" */
export type Nova_Field_Attachments_On_Conflict = {
  constraint: Nova_Field_Attachments_Constraint;
  update_columns?: Array<Nova_Field_Attachments_Update_Column>;
  where?: InputMaybe<Nova_Field_Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "nova_field_attachments". */
export type Nova_Field_Attachments_Order_By = {
  attachable_id?: InputMaybe<Order_By>;
  attachable_type?: InputMaybe<Order_By>;
  attachment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  disk?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  url?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nova_field_attachments */
export type Nova_Field_Attachments_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "nova_field_attachments" */
export enum Nova_Field_Attachments_Select_Column {
  /** column name */
  AttachableId = 'attachable_id',
  /** column name */
  AttachableType = 'attachable_type',
  /** column name */
  Attachment = 'attachment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disk = 'disk',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

/** input type for updating data in table "nova_field_attachments" */
export type Nova_Field_Attachments_Set_Input = {
  attachable_id?: InputMaybe<Scalars['bigint']['input']>;
  attachable_type?: InputMaybe<Scalars['String']['input']>;
  attachment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  disk?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Nova_Field_Attachments_Stddev_Fields = {
  __typename?: 'nova_field_attachments_stddev_fields';
  attachable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Nova_Field_Attachments_Stddev_Pop_Fields = {
  __typename?: 'nova_field_attachments_stddev_pop_fields';
  attachable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Nova_Field_Attachments_Stddev_Samp_Fields = {
  __typename?: 'nova_field_attachments_stddev_samp_fields';
  attachable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "nova_field_attachments" */
export type Nova_Field_Attachments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nova_Field_Attachments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nova_Field_Attachments_Stream_Cursor_Value_Input = {
  attachable_id?: InputMaybe<Scalars['bigint']['input']>;
  attachable_type?: InputMaybe<Scalars['String']['input']>;
  attachment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  disk?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  url?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Nova_Field_Attachments_Sum_Fields = {
  __typename?: 'nova_field_attachments_sum_fields';
  attachable_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "nova_field_attachments" */
export enum Nova_Field_Attachments_Update_Column {
  /** column name */
  AttachableId = 'attachable_id',
  /** column name */
  AttachableType = 'attachable_type',
  /** column name */
  Attachment = 'attachment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disk = 'disk',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Url = 'url'
}

export type Nova_Field_Attachments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nova_Field_Attachments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nova_Field_Attachments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Nova_Field_Attachments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nova_Field_Attachments_Var_Pop_Fields = {
  __typename?: 'nova_field_attachments_var_pop_fields';
  attachable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Nova_Field_Attachments_Var_Samp_Fields = {
  __typename?: 'nova_field_attachments_var_samp_fields';
  attachable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Nova_Field_Attachments_Variance_Fields = {
  __typename?: 'nova_field_attachments_variance_fields';
  attachable_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "nova_notifications" */
export type Nova_Notifications = {
  __typename?: 'nova_notifications';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  data: Scalars['String']['output'];
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['uuid']['output'];
  notifiable_id: Scalars['String']['output'];
  notifiable_type: Scalars['String']['output'];
  read_at?: Maybe<Scalars['timestamp']['output']>;
  type: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "nova_notifications" */
export type Nova_Notifications_Aggregate = {
  __typename?: 'nova_notifications_aggregate';
  aggregate?: Maybe<Nova_Notifications_Aggregate_Fields>;
  nodes: Array<Nova_Notifications>;
};

/** aggregate fields of "nova_notifications" */
export type Nova_Notifications_Aggregate_Fields = {
  __typename?: 'nova_notifications_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Nova_Notifications_Max_Fields>;
  min?: Maybe<Nova_Notifications_Min_Fields>;
};


/** aggregate fields of "nova_notifications" */
export type Nova_Notifications_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nova_Notifications_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "nova_notifications". All fields are combined with a logical 'AND'. */
export type Nova_Notifications_Bool_Exp = {
  _and?: InputMaybe<Array<Nova_Notifications_Bool_Exp>>;
  _not?: InputMaybe<Nova_Notifications_Bool_Exp>;
  _or?: InputMaybe<Array<Nova_Notifications_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  data?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Uuid_Comparison_Exp>;
  notifiable_id?: InputMaybe<String_Comparison_Exp>;
  notifiable_type?: InputMaybe<String_Comparison_Exp>;
  read_at?: InputMaybe<Timestamp_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "nova_notifications" */
export enum Nova_Notifications_Constraint {
  /** unique or primary key constraint on columns "id" */
  NovaNotificationsPkey = 'nova_notifications_pkey'
}

/** input type for inserting data into table "nova_notifications" */
export type Nova_Notifications_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notifiable_id?: InputMaybe<Scalars['String']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  read_at?: InputMaybe<Scalars['timestamp']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Nova_Notifications_Max_Fields = {
  __typename?: 'nova_notifications_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notifiable_id?: Maybe<Scalars['String']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  read_at?: Maybe<Scalars['timestamp']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Nova_Notifications_Min_Fields = {
  __typename?: 'nova_notifications_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  data?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['uuid']['output']>;
  notifiable_id?: Maybe<Scalars['String']['output']>;
  notifiable_type?: Maybe<Scalars['String']['output']>;
  read_at?: Maybe<Scalars['timestamp']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "nova_notifications" */
export type Nova_Notifications_Mutation_Response = {
  __typename?: 'nova_notifications_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Nova_Notifications>;
};

/** on_conflict condition type for table "nova_notifications" */
export type Nova_Notifications_On_Conflict = {
  constraint: Nova_Notifications_Constraint;
  update_columns?: Array<Nova_Notifications_Update_Column>;
  where?: InputMaybe<Nova_Notifications_Bool_Exp>;
};

/** Ordering options when selecting data from "nova_notifications". */
export type Nova_Notifications_Order_By = {
  created_at?: InputMaybe<Order_By>;
  data?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  notifiable_id?: InputMaybe<Order_By>;
  notifiable_type?: InputMaybe<Order_By>;
  read_at?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nova_notifications */
export type Nova_Notifications_Pk_Columns_Input = {
  id: Scalars['uuid']['input'];
};

/** select columns of table "nova_notifications" */
export enum Nova_Notifications_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "nova_notifications" */
export type Nova_Notifications_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notifiable_id?: InputMaybe<Scalars['String']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  read_at?: InputMaybe<Scalars['timestamp']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "nova_notifications" */
export type Nova_Notifications_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nova_Notifications_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nova_Notifications_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  data?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['uuid']['input']>;
  notifiable_id?: InputMaybe<Scalars['String']['input']>;
  notifiable_type?: InputMaybe<Scalars['String']['input']>;
  read_at?: InputMaybe<Scalars['timestamp']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "nova_notifications" */
export enum Nova_Notifications_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Data = 'data',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  NotifiableId = 'notifiable_id',
  /** column name */
  NotifiableType = 'notifiable_type',
  /** column name */
  ReadAt = 'read_at',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Nova_Notifications_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nova_Notifications_Set_Input>;
  /** filter the rows which have to be updated */
  where: Nova_Notifications_Bool_Exp;
};

/** columns and relationships of "nova_pending_field_attachments" */
export type Nova_Pending_Field_Attachments = {
  __typename?: 'nova_pending_field_attachments';
  attachment: Scalars['String']['output'];
  created_at?: Maybe<Scalars['timestamp']['output']>;
  disk: Scalars['String']['output'];
  draft_id: Scalars['String']['output'];
  id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "nova_pending_field_attachments" */
export type Nova_Pending_Field_Attachments_Aggregate = {
  __typename?: 'nova_pending_field_attachments_aggregate';
  aggregate?: Maybe<Nova_Pending_Field_Attachments_Aggregate_Fields>;
  nodes: Array<Nova_Pending_Field_Attachments>;
};

/** aggregate fields of "nova_pending_field_attachments" */
export type Nova_Pending_Field_Attachments_Aggregate_Fields = {
  __typename?: 'nova_pending_field_attachments_aggregate_fields';
  avg?: Maybe<Nova_Pending_Field_Attachments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Nova_Pending_Field_Attachments_Max_Fields>;
  min?: Maybe<Nova_Pending_Field_Attachments_Min_Fields>;
  stddev?: Maybe<Nova_Pending_Field_Attachments_Stddev_Fields>;
  stddev_pop?: Maybe<Nova_Pending_Field_Attachments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nova_Pending_Field_Attachments_Stddev_Samp_Fields>;
  sum?: Maybe<Nova_Pending_Field_Attachments_Sum_Fields>;
  var_pop?: Maybe<Nova_Pending_Field_Attachments_Var_Pop_Fields>;
  var_samp?: Maybe<Nova_Pending_Field_Attachments_Var_Samp_Fields>;
  variance?: Maybe<Nova_Pending_Field_Attachments_Variance_Fields>;
};


/** aggregate fields of "nova_pending_field_attachments" */
export type Nova_Pending_Field_Attachments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nova_Pending_Field_Attachments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Nova_Pending_Field_Attachments_Avg_Fields = {
  __typename?: 'nova_pending_field_attachments_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "nova_pending_field_attachments". All fields are combined with a logical 'AND'. */
export type Nova_Pending_Field_Attachments_Bool_Exp = {
  _and?: InputMaybe<Array<Nova_Pending_Field_Attachments_Bool_Exp>>;
  _not?: InputMaybe<Nova_Pending_Field_Attachments_Bool_Exp>;
  _or?: InputMaybe<Array<Nova_Pending_Field_Attachments_Bool_Exp>>;
  attachment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  disk?: InputMaybe<String_Comparison_Exp>;
  draft_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "nova_pending_field_attachments" */
export enum Nova_Pending_Field_Attachments_Constraint {
  /** unique or primary key constraint on columns "id" */
  NovaPendingFieldAttachmentsPkey = 'nova_pending_field_attachments_pkey'
}

/** input type for incrementing numeric columns in table "nova_pending_field_attachments" */
export type Nova_Pending_Field_Attachments_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "nova_pending_field_attachments" */
export type Nova_Pending_Field_Attachments_Insert_Input = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  disk?: InputMaybe<Scalars['String']['input']>;
  draft_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Nova_Pending_Field_Attachments_Max_Fields = {
  __typename?: 'nova_pending_field_attachments_max_fields';
  attachment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  disk?: Maybe<Scalars['String']['output']>;
  draft_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Nova_Pending_Field_Attachments_Min_Fields = {
  __typename?: 'nova_pending_field_attachments_min_fields';
  attachment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  disk?: Maybe<Scalars['String']['output']>;
  draft_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "nova_pending_field_attachments" */
export type Nova_Pending_Field_Attachments_Mutation_Response = {
  __typename?: 'nova_pending_field_attachments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Nova_Pending_Field_Attachments>;
};

/** on_conflict condition type for table "nova_pending_field_attachments" */
export type Nova_Pending_Field_Attachments_On_Conflict = {
  constraint: Nova_Pending_Field_Attachments_Constraint;
  update_columns?: Array<Nova_Pending_Field_Attachments_Update_Column>;
  where?: InputMaybe<Nova_Pending_Field_Attachments_Bool_Exp>;
};

/** Ordering options when selecting data from "nova_pending_field_attachments". */
export type Nova_Pending_Field_Attachments_Order_By = {
  attachment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  disk?: InputMaybe<Order_By>;
  draft_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nova_pending_field_attachments */
export type Nova_Pending_Field_Attachments_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "nova_pending_field_attachments" */
export enum Nova_Pending_Field_Attachments_Select_Column {
  /** column name */
  Attachment = 'attachment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disk = 'disk',
  /** column name */
  DraftId = 'draft_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "nova_pending_field_attachments" */
export type Nova_Pending_Field_Attachments_Set_Input = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  disk?: InputMaybe<Scalars['String']['input']>;
  draft_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Nova_Pending_Field_Attachments_Stddev_Fields = {
  __typename?: 'nova_pending_field_attachments_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Nova_Pending_Field_Attachments_Stddev_Pop_Fields = {
  __typename?: 'nova_pending_field_attachments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Nova_Pending_Field_Attachments_Stddev_Samp_Fields = {
  __typename?: 'nova_pending_field_attachments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "nova_pending_field_attachments" */
export type Nova_Pending_Field_Attachments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nova_Pending_Field_Attachments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nova_Pending_Field_Attachments_Stream_Cursor_Value_Input = {
  attachment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  disk?: InputMaybe<Scalars['String']['input']>;
  draft_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Nova_Pending_Field_Attachments_Sum_Fields = {
  __typename?: 'nova_pending_field_attachments_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "nova_pending_field_attachments" */
export enum Nova_Pending_Field_Attachments_Update_Column {
  /** column name */
  Attachment = 'attachment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Disk = 'disk',
  /** column name */
  DraftId = 'draft_id',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Nova_Pending_Field_Attachments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nova_Pending_Field_Attachments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nova_Pending_Field_Attachments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Nova_Pending_Field_Attachments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nova_Pending_Field_Attachments_Var_Pop_Fields = {
  __typename?: 'nova_pending_field_attachments_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Nova_Pending_Field_Attachments_Var_Samp_Fields = {
  __typename?: 'nova_pending_field_attachments_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Nova_Pending_Field_Attachments_Variance_Fields = {
  __typename?: 'nova_pending_field_attachments_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "nova_twofa" */
export type Nova_Twofa = {
  __typename?: 'nova_twofa';
  confirmed: Scalars['Boolean']['output'];
  created_at?: Maybe<Scalars['timestamp']['output']>;
  google2fa_enable: Scalars['Boolean']['output'];
  google2fa_secret?: Maybe<Scalars['String']['output']>;
  id: Scalars['Int']['output'];
  recovery?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['bpchar']['output'];
};

/** aggregated selection of "nova_twofa" */
export type Nova_Twofa_Aggregate = {
  __typename?: 'nova_twofa_aggregate';
  aggregate?: Maybe<Nova_Twofa_Aggregate_Fields>;
  nodes: Array<Nova_Twofa>;
};

export type Nova_Twofa_Aggregate_Bool_Exp = {
  bool_and?: InputMaybe<Nova_Twofa_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Nova_Twofa_Aggregate_Bool_Exp_Bool_Or>;
  count?: InputMaybe<Nova_Twofa_Aggregate_Bool_Exp_Count>;
};

export type Nova_Twofa_Aggregate_Bool_Exp_Bool_And = {
  arguments: Nova_Twofa_Select_Column_Nova_Twofa_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Nova_Twofa_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Nova_Twofa_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Nova_Twofa_Select_Column_Nova_Twofa_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Nova_Twofa_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Nova_Twofa_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Nova_Twofa_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Nova_Twofa_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "nova_twofa" */
export type Nova_Twofa_Aggregate_Fields = {
  __typename?: 'nova_twofa_aggregate_fields';
  avg?: Maybe<Nova_Twofa_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Nova_Twofa_Max_Fields>;
  min?: Maybe<Nova_Twofa_Min_Fields>;
  stddev?: Maybe<Nova_Twofa_Stddev_Fields>;
  stddev_pop?: Maybe<Nova_Twofa_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Nova_Twofa_Stddev_Samp_Fields>;
  sum?: Maybe<Nova_Twofa_Sum_Fields>;
  var_pop?: Maybe<Nova_Twofa_Var_Pop_Fields>;
  var_samp?: Maybe<Nova_Twofa_Var_Samp_Fields>;
  variance?: Maybe<Nova_Twofa_Variance_Fields>;
};


/** aggregate fields of "nova_twofa" */
export type Nova_Twofa_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Nova_Twofa_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "nova_twofa" */
export type Nova_Twofa_Aggregate_Order_By = {
  avg?: InputMaybe<Nova_Twofa_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Nova_Twofa_Max_Order_By>;
  min?: InputMaybe<Nova_Twofa_Min_Order_By>;
  stddev?: InputMaybe<Nova_Twofa_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Nova_Twofa_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Nova_Twofa_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Nova_Twofa_Sum_Order_By>;
  var_pop?: InputMaybe<Nova_Twofa_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Nova_Twofa_Var_Samp_Order_By>;
  variance?: InputMaybe<Nova_Twofa_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "nova_twofa" */
export type Nova_Twofa_Arr_Rel_Insert_Input = {
  data: Array<Nova_Twofa_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Nova_Twofa_On_Conflict>;
};

/** aggregate avg on columns */
export type Nova_Twofa_Avg_Fields = {
  __typename?: 'nova_twofa_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "nova_twofa" */
export type Nova_Twofa_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "nova_twofa". All fields are combined with a logical 'AND'. */
export type Nova_Twofa_Bool_Exp = {
  _and?: InputMaybe<Array<Nova_Twofa_Bool_Exp>>;
  _not?: InputMaybe<Nova_Twofa_Bool_Exp>;
  _or?: InputMaybe<Array<Nova_Twofa_Bool_Exp>>;
  confirmed?: InputMaybe<Boolean_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  google2fa_enable?: InputMaybe<Boolean_Comparison_Exp>;
  google2fa_secret?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Int_Comparison_Exp>;
  recovery?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "nova_twofa" */
export enum Nova_Twofa_Constraint {
  /** unique or primary key constraint on columns "id" */
  NovaTwofaPkey = 'nova_twofa_pkey'
}

/** input type for incrementing numeric columns in table "nova_twofa" */
export type Nova_Twofa_Inc_Input = {
  id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "nova_twofa" */
export type Nova_Twofa_Insert_Input = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  google2fa_enable?: InputMaybe<Scalars['Boolean']['input']>;
  google2fa_secret?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  recovery?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type Nova_Twofa_Max_Fields = {
  __typename?: 'nova_twofa_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  google2fa_secret?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  recovery?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by max() on columns of table "nova_twofa" */
export type Nova_Twofa_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  google2fa_secret?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recovery?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Nova_Twofa_Min_Fields = {
  __typename?: 'nova_twofa_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  google2fa_secret?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['Int']['output']>;
  recovery?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by min() on columns of table "nova_twofa" */
export type Nova_Twofa_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  google2fa_secret?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recovery?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "nova_twofa" */
export type Nova_Twofa_Mutation_Response = {
  __typename?: 'nova_twofa_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Nova_Twofa>;
};

/** on_conflict condition type for table "nova_twofa" */
export type Nova_Twofa_On_Conflict = {
  constraint: Nova_Twofa_Constraint;
  update_columns?: Array<Nova_Twofa_Update_Column>;
  where?: InputMaybe<Nova_Twofa_Bool_Exp>;
};

/** Ordering options when selecting data from "nova_twofa". */
export type Nova_Twofa_Order_By = {
  confirmed?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  google2fa_enable?: InputMaybe<Order_By>;
  google2fa_secret?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  recovery?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: nova_twofa */
export type Nova_Twofa_Pk_Columns_Input = {
  id: Scalars['Int']['input'];
};

/** select columns of table "nova_twofa" */
export enum Nova_Twofa_Select_Column {
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Google2faEnable = 'google2fa_enable',
  /** column name */
  Google2faSecret = 'google2fa_secret',
  /** column name */
  Id = 'id',
  /** column name */
  Recovery = 'recovery',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "nova_twofa_aggregate_bool_exp_bool_and_arguments_columns" columns of table "nova_twofa" */
export enum Nova_Twofa_Select_Column_Nova_Twofa_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  Google2faEnable = 'google2fa_enable'
}

/** select "nova_twofa_aggregate_bool_exp_bool_or_arguments_columns" columns of table "nova_twofa" */
export enum Nova_Twofa_Select_Column_Nova_Twofa_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  Google2faEnable = 'google2fa_enable'
}

/** input type for updating data in table "nova_twofa" */
export type Nova_Twofa_Set_Input = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  google2fa_enable?: InputMaybe<Scalars['Boolean']['input']>;
  google2fa_secret?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  recovery?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type Nova_Twofa_Stddev_Fields = {
  __typename?: 'nova_twofa_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "nova_twofa" */
export type Nova_Twofa_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Nova_Twofa_Stddev_Pop_Fields = {
  __typename?: 'nova_twofa_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "nova_twofa" */
export type Nova_Twofa_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Nova_Twofa_Stddev_Samp_Fields = {
  __typename?: 'nova_twofa_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "nova_twofa" */
export type Nova_Twofa_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "nova_twofa" */
export type Nova_Twofa_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Nova_Twofa_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Nova_Twofa_Stream_Cursor_Value_Input = {
  confirmed?: InputMaybe<Scalars['Boolean']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  google2fa_enable?: InputMaybe<Scalars['Boolean']['input']>;
  google2fa_secret?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['Int']['input']>;
  recovery?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type Nova_Twofa_Sum_Fields = {
  __typename?: 'nova_twofa_sum_fields';
  id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "nova_twofa" */
export type Nova_Twofa_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "nova_twofa" */
export enum Nova_Twofa_Update_Column {
  /** column name */
  Confirmed = 'confirmed',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Google2faEnable = 'google2fa_enable',
  /** column name */
  Google2faSecret = 'google2fa_secret',
  /** column name */
  Id = 'id',
  /** column name */
  Recovery = 'recovery',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Nova_Twofa_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Nova_Twofa_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Nova_Twofa_Set_Input>;
  /** filter the rows which have to be updated */
  where: Nova_Twofa_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Nova_Twofa_Var_Pop_Fields = {
  __typename?: 'nova_twofa_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "nova_twofa" */
export type Nova_Twofa_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Nova_Twofa_Var_Samp_Fields = {
  __typename?: 'nova_twofa_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "nova_twofa" */
export type Nova_Twofa_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Nova_Twofa_Variance_Fields = {
  __typename?: 'nova_twofa_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "nova_twofa" */
export type Nova_Twofa_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** column ordering options */
export enum Order_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

/** columns and relationships of "pairing_codes" */
export type Pairing_Codes = {
  __typename?: 'pairing_codes';
  account_id: Scalars['bpchar']['output'];
  account_user_id: Scalars['bpchar']['output'];
  code_creator_user_id: Scalars['bpchar']['output'];
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  farmer_user_id: Scalars['bpchar']['output'];
  field_partner_user_id: Scalars['bpchar']['output'];
  id: Scalars['bpchar']['output'];
  pairing_code: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "pairing_codes" */
export type Pairing_Codes_Aggregate = {
  __typename?: 'pairing_codes_aggregate';
  aggregate?: Maybe<Pairing_Codes_Aggregate_Fields>;
  nodes: Array<Pairing_Codes>;
};

/** aggregate fields of "pairing_codes" */
export type Pairing_Codes_Aggregate_Fields = {
  __typename?: 'pairing_codes_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Pairing_Codes_Max_Fields>;
  min?: Maybe<Pairing_Codes_Min_Fields>;
};


/** aggregate fields of "pairing_codes" */
export type Pairing_Codes_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Pairing_Codes_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "pairing_codes". All fields are combined with a logical 'AND'. */
export type Pairing_Codes_Bool_Exp = {
  _and?: InputMaybe<Array<Pairing_Codes_Bool_Exp>>;
  _not?: InputMaybe<Pairing_Codes_Bool_Exp>;
  _or?: InputMaybe<Array<Pairing_Codes_Bool_Exp>>;
  account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  account_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  code_creator_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  farmer_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  field_partner_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  pairing_code?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "pairing_codes" */
export enum Pairing_Codes_Constraint {
  /** unique or primary key constraint on columns "pairing_code" */
  PairingCodesPairingCodeUnique = 'pairing_codes_pairing_code_unique'
}

/** input type for inserting data into table "pairing_codes" */
export type Pairing_Codes_Insert_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  account_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  code_creator_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  pairing_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Pairing_Codes_Max_Fields = {
  __typename?: 'pairing_codes_max_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  account_user_id?: Maybe<Scalars['bpchar']['output']>;
  code_creator_user_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  field_partner_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  pairing_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Pairing_Codes_Min_Fields = {
  __typename?: 'pairing_codes_min_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  account_user_id?: Maybe<Scalars['bpchar']['output']>;
  code_creator_user_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  field_partner_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  pairing_code?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "pairing_codes" */
export type Pairing_Codes_Mutation_Response = {
  __typename?: 'pairing_codes_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Pairing_Codes>;
};

/** on_conflict condition type for table "pairing_codes" */
export type Pairing_Codes_On_Conflict = {
  constraint: Pairing_Codes_Constraint;
  update_columns?: Array<Pairing_Codes_Update_Column>;
  where?: InputMaybe<Pairing_Codes_Bool_Exp>;
};

/** Ordering options when selecting data from "pairing_codes". */
export type Pairing_Codes_Order_By = {
  account_id?: InputMaybe<Order_By>;
  account_user_id?: InputMaybe<Order_By>;
  code_creator_user_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  pairing_code?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** select columns of table "pairing_codes" */
export enum Pairing_Codes_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AccountUserId = 'account_user_id',
  /** column name */
  CodeCreatorUserId = 'code_creator_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  FieldPartnerUserId = 'field_partner_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  PairingCode = 'pairing_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "pairing_codes" */
export type Pairing_Codes_Set_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  account_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  code_creator_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  pairing_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** Streaming cursor of the table "pairing_codes" */
export type Pairing_Codes_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Pairing_Codes_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Pairing_Codes_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  account_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  code_creator_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  pairing_code?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** update columns of table "pairing_codes" */
export enum Pairing_Codes_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AccountUserId = 'account_user_id',
  /** column name */
  CodeCreatorUserId = 'code_creator_user_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  FieldPartnerUserId = 'field_partner_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  PairingCode = 'pairing_code',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Pairing_Codes_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Pairing_Codes_Set_Input>;
  /** filter the rows which have to be updated */
  where: Pairing_Codes_Bool_Exp;
};

/** columns and relationships of "password_reset_tokens" */
export type Password_Reset_Tokens = {
  __typename?: 'password_reset_tokens';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email: Scalars['String']['output'];
  token: Scalars['String']['output'];
};

/** aggregated selection of "password_reset_tokens" */
export type Password_Reset_Tokens_Aggregate = {
  __typename?: 'password_reset_tokens_aggregate';
  aggregate?: Maybe<Password_Reset_Tokens_Aggregate_Fields>;
  nodes: Array<Password_Reset_Tokens>;
};

/** aggregate fields of "password_reset_tokens" */
export type Password_Reset_Tokens_Aggregate_Fields = {
  __typename?: 'password_reset_tokens_aggregate_fields';
  count: Scalars['Int']['output'];
  max?: Maybe<Password_Reset_Tokens_Max_Fields>;
  min?: Maybe<Password_Reset_Tokens_Min_Fields>;
};


/** aggregate fields of "password_reset_tokens" */
export type Password_Reset_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Password_Reset_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** Boolean expression to filter rows from the table "password_reset_tokens". All fields are combined with a logical 'AND'. */
export type Password_Reset_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Password_Reset_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Password_Reset_Tokens_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "password_reset_tokens" */
export enum Password_Reset_Tokens_Constraint {
  /** unique or primary key constraint on columns "email" */
  PasswordResetTokensPkey = 'password_reset_tokens_pkey'
}

/** input type for inserting data into table "password_reset_tokens" */
export type Password_Reset_Tokens_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Password_Reset_Tokens_Max_Fields = {
  __typename?: 'password_reset_tokens_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Password_Reset_Tokens_Min_Fields = {
  __typename?: 'password_reset_tokens_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "password_reset_tokens" */
export type Password_Reset_Tokens_Mutation_Response = {
  __typename?: 'password_reset_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Password_Reset_Tokens>;
};

/** on_conflict condition type for table "password_reset_tokens" */
export type Password_Reset_Tokens_On_Conflict = {
  constraint: Password_Reset_Tokens_Constraint;
  update_columns?: Array<Password_Reset_Tokens_Update_Column>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "password_reset_tokens". */
export type Password_Reset_Tokens_Order_By = {
  created_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
};

/** primary key columns input for table: password_reset_tokens */
export type Password_Reset_Tokens_Pk_Columns_Input = {
  email: Scalars['String']['input'];
};

/** select columns of table "password_reset_tokens" */
export enum Password_Reset_Tokens_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Token = 'token'
}

/** input type for updating data in table "password_reset_tokens" */
export type Password_Reset_Tokens_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** Streaming cursor of the table "password_reset_tokens" */
export type Password_Reset_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Password_Reset_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Password_Reset_Tokens_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
};

/** update columns of table "password_reset_tokens" */
export enum Password_Reset_Tokens_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Email = 'email',
  /** column name */
  Token = 'token'
}

export type Password_Reset_Tokens_Updates = {
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Password_Reset_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Password_Reset_Tokens_Bool_Exp;
};

/** columns and relationships of "permissions" */
export type Permissions = {
  __typename?: 'permissions';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  guard_name: Scalars['String']['output'];
  id: Scalars['bigint']['output'];
  /** An array relationship */
  model_has_permissions: Array<Model_Has_Permissions>;
  /** An aggregate relationship */
  model_has_permissions_aggregate: Model_Has_Permissions_Aggregate;
  name: Scalars['String']['output'];
  /** An array relationship */
  role_has_permissions: Array<Role_Has_Permissions>;
  /** An aggregate relationship */
  role_has_permissions_aggregate: Role_Has_Permissions_Aggregate;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "permissions" */
export type PermissionsModel_Has_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Permissions_Order_By>>;
  where?: InputMaybe<Model_Has_Permissions_Bool_Exp>;
};


/** columns and relationships of "permissions" */
export type PermissionsModel_Has_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Permissions_Order_By>>;
  where?: InputMaybe<Model_Has_Permissions_Bool_Exp>;
};


/** columns and relationships of "permissions" */
export type PermissionsRole_Has_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Has_Permissions_Order_By>>;
  where?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
};


/** columns and relationships of "permissions" */
export type PermissionsRole_Has_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Has_Permissions_Order_By>>;
  where?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
};

/** aggregated selection of "permissions" */
export type Permissions_Aggregate = {
  __typename?: 'permissions_aggregate';
  aggregate?: Maybe<Permissions_Aggregate_Fields>;
  nodes: Array<Permissions>;
};

/** aggregate fields of "permissions" */
export type Permissions_Aggregate_Fields = {
  __typename?: 'permissions_aggregate_fields';
  avg?: Maybe<Permissions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Permissions_Max_Fields>;
  min?: Maybe<Permissions_Min_Fields>;
  stddev?: Maybe<Permissions_Stddev_Fields>;
  stddev_pop?: Maybe<Permissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Permissions_Stddev_Samp_Fields>;
  sum?: Maybe<Permissions_Sum_Fields>;
  var_pop?: Maybe<Permissions_Var_Pop_Fields>;
  var_samp?: Maybe<Permissions_Var_Samp_Fields>;
  variance?: Maybe<Permissions_Variance_Fields>;
};


/** aggregate fields of "permissions" */
export type Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Permissions_Avg_Fields = {
  __typename?: 'permissions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "permissions". All fields are combined with a logical 'AND'. */
export type Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Permissions_Bool_Exp>>;
  _not?: InputMaybe<Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Permissions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  guard_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  model_has_permissions?: InputMaybe<Model_Has_Permissions_Bool_Exp>;
  model_has_permissions_aggregate?: InputMaybe<Model_Has_Permissions_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  role_has_permissions?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
  role_has_permissions_aggregate?: InputMaybe<Role_Has_Permissions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "permissions" */
export enum Permissions_Constraint {
  /** unique or primary key constraint on columns "name", "guard_name" */
  PermissionsNameGuardNameUnique = 'permissions_name_guard_name_unique',
  /** unique or primary key constraint on columns "id" */
  PermissionsPkey = 'permissions_pkey'
}

/** input type for incrementing numeric columns in table "permissions" */
export type Permissions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "permissions" */
export type Permissions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  guard_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  model_has_permissions?: InputMaybe<Model_Has_Permissions_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  role_has_permissions?: InputMaybe<Role_Has_Permissions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Permissions_Max_Fields = {
  __typename?: 'permissions_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  guard_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Permissions_Min_Fields = {
  __typename?: 'permissions_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  guard_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "permissions" */
export type Permissions_Mutation_Response = {
  __typename?: 'permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Permissions>;
};

/** input type for inserting object relation for remote table "permissions" */
export type Permissions_Obj_Rel_Insert_Input = {
  data: Permissions_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Permissions_On_Conflict>;
};

/** on_conflict condition type for table "permissions" */
export type Permissions_On_Conflict = {
  constraint: Permissions_Constraint;
  update_columns?: Array<Permissions_Update_Column>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "permissions". */
export type Permissions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  guard_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_has_permissions_aggregate?: InputMaybe<Model_Has_Permissions_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  role_has_permissions_aggregate?: InputMaybe<Role_Has_Permissions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: permissions */
export type Permissions_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "permissions" */
export enum Permissions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GuardName = 'guard_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "permissions" */
export type Permissions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  guard_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Permissions_Stddev_Fields = {
  __typename?: 'permissions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Permissions_Stddev_Pop_Fields = {
  __typename?: 'permissions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Permissions_Stddev_Samp_Fields = {
  __typename?: 'permissions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "permissions" */
export type Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Permissions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  guard_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Permissions_Sum_Fields = {
  __typename?: 'permissions_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "permissions" */
export enum Permissions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GuardName = 'guard_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Permissions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Permissions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Permissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Permissions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Permissions_Var_Pop_Fields = {
  __typename?: 'permissions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Permissions_Var_Samp_Fields = {
  __typename?: 'permissions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Permissions_Variance_Fields = {
  __typename?: 'permissions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "personal_access_tokens" */
export type Personal_Access_Tokens = {
  __typename?: 'personal_access_tokens';
  abilities?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  expires_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  last_used_at?: Maybe<Scalars['timestamp']['output']>;
  name: Scalars['String']['output'];
  token: Scalars['String']['output'];
  tokenable_id: Scalars['bigint']['output'];
  tokenable_type: Scalars['String']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregated selection of "personal_access_tokens" */
export type Personal_Access_Tokens_Aggregate = {
  __typename?: 'personal_access_tokens_aggregate';
  aggregate?: Maybe<Personal_Access_Tokens_Aggregate_Fields>;
  nodes: Array<Personal_Access_Tokens>;
};

/** aggregate fields of "personal_access_tokens" */
export type Personal_Access_Tokens_Aggregate_Fields = {
  __typename?: 'personal_access_tokens_aggregate_fields';
  avg?: Maybe<Personal_Access_Tokens_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Personal_Access_Tokens_Max_Fields>;
  min?: Maybe<Personal_Access_Tokens_Min_Fields>;
  stddev?: Maybe<Personal_Access_Tokens_Stddev_Fields>;
  stddev_pop?: Maybe<Personal_Access_Tokens_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Personal_Access_Tokens_Stddev_Samp_Fields>;
  sum?: Maybe<Personal_Access_Tokens_Sum_Fields>;
  var_pop?: Maybe<Personal_Access_Tokens_Var_Pop_Fields>;
  var_samp?: Maybe<Personal_Access_Tokens_Var_Samp_Fields>;
  variance?: Maybe<Personal_Access_Tokens_Variance_Fields>;
};


/** aggregate fields of "personal_access_tokens" */
export type Personal_Access_Tokens_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Personal_Access_Tokens_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Personal_Access_Tokens_Avg_Fields = {
  __typename?: 'personal_access_tokens_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tokenable_id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "personal_access_tokens". All fields are combined with a logical 'AND'. */
export type Personal_Access_Tokens_Bool_Exp = {
  _and?: InputMaybe<Array<Personal_Access_Tokens_Bool_Exp>>;
  _not?: InputMaybe<Personal_Access_Tokens_Bool_Exp>;
  _or?: InputMaybe<Array<Personal_Access_Tokens_Bool_Exp>>;
  abilities?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  expires_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  last_used_at?: InputMaybe<Timestamp_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  token?: InputMaybe<String_Comparison_Exp>;
  tokenable_id?: InputMaybe<Bigint_Comparison_Exp>;
  tokenable_type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "personal_access_tokens" */
export enum Personal_Access_Tokens_Constraint {
  /** unique or primary key constraint on columns "id" */
  PersonalAccessTokensPkey = 'personal_access_tokens_pkey',
  /** unique or primary key constraint on columns "token" */
  PersonalAccessTokensTokenUnique = 'personal_access_tokens_token_unique'
}

/** input type for incrementing numeric columns in table "personal_access_tokens" */
export type Personal_Access_Tokens_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  tokenable_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "personal_access_tokens" */
export type Personal_Access_Tokens_Insert_Input = {
  abilities?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_used_at?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  tokenable_id?: InputMaybe<Scalars['bigint']['input']>;
  tokenable_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Personal_Access_Tokens_Max_Fields = {
  __typename?: 'personal_access_tokens_max_fields';
  abilities?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  expires_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_used_at?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  tokenable_id?: Maybe<Scalars['bigint']['output']>;
  tokenable_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Personal_Access_Tokens_Min_Fields = {
  __typename?: 'personal_access_tokens_min_fields';
  abilities?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  expires_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  last_used_at?: Maybe<Scalars['timestamp']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  token?: Maybe<Scalars['String']['output']>;
  tokenable_id?: Maybe<Scalars['bigint']['output']>;
  tokenable_type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "personal_access_tokens" */
export type Personal_Access_Tokens_Mutation_Response = {
  __typename?: 'personal_access_tokens_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Personal_Access_Tokens>;
};

/** on_conflict condition type for table "personal_access_tokens" */
export type Personal_Access_Tokens_On_Conflict = {
  constraint: Personal_Access_Tokens_Constraint;
  update_columns?: Array<Personal_Access_Tokens_Update_Column>;
  where?: InputMaybe<Personal_Access_Tokens_Bool_Exp>;
};

/** Ordering options when selecting data from "personal_access_tokens". */
export type Personal_Access_Tokens_Order_By = {
  abilities?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  last_used_at?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  token?: InputMaybe<Order_By>;
  tokenable_id?: InputMaybe<Order_By>;
  tokenable_type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: personal_access_tokens */
export type Personal_Access_Tokens_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "personal_access_tokens" */
export enum Personal_Access_Tokens_Select_Column {
  /** column name */
  Abilities = 'abilities',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastUsedAt = 'last_used_at',
  /** column name */
  Name = 'name',
  /** column name */
  Token = 'token',
  /** column name */
  TokenableId = 'tokenable_id',
  /** column name */
  TokenableType = 'tokenable_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "personal_access_tokens" */
export type Personal_Access_Tokens_Set_Input = {
  abilities?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_used_at?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  tokenable_id?: InputMaybe<Scalars['bigint']['input']>;
  tokenable_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Personal_Access_Tokens_Stddev_Fields = {
  __typename?: 'personal_access_tokens_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tokenable_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Personal_Access_Tokens_Stddev_Pop_Fields = {
  __typename?: 'personal_access_tokens_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tokenable_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Personal_Access_Tokens_Stddev_Samp_Fields = {
  __typename?: 'personal_access_tokens_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tokenable_id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "personal_access_tokens" */
export type Personal_Access_Tokens_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Personal_Access_Tokens_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Personal_Access_Tokens_Stream_Cursor_Value_Input = {
  abilities?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  last_used_at?: InputMaybe<Scalars['timestamp']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  token?: InputMaybe<Scalars['String']['input']>;
  tokenable_id?: InputMaybe<Scalars['bigint']['input']>;
  tokenable_type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Personal_Access_Tokens_Sum_Fields = {
  __typename?: 'personal_access_tokens_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  tokenable_id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "personal_access_tokens" */
export enum Personal_Access_Tokens_Update_Column {
  /** column name */
  Abilities = 'abilities',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  Id = 'id',
  /** column name */
  LastUsedAt = 'last_used_at',
  /** column name */
  Name = 'name',
  /** column name */
  Token = 'token',
  /** column name */
  TokenableId = 'tokenable_id',
  /** column name */
  TokenableType = 'tokenable_type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Personal_Access_Tokens_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Personal_Access_Tokens_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Personal_Access_Tokens_Set_Input>;
  /** filter the rows which have to be updated */
  where: Personal_Access_Tokens_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Personal_Access_Tokens_Var_Pop_Fields = {
  __typename?: 'personal_access_tokens_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tokenable_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Personal_Access_Tokens_Var_Samp_Fields = {
  __typename?: 'personal_access_tokens_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tokenable_id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Personal_Access_Tokens_Variance_Fields = {
  __typename?: 'personal_access_tokens_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tokenable_id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "personal_dashboard" */
export type Personal_Dashboard = {
  __typename?: 'personal_dashboard';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_closed: Scalars['Int']['output'];
  farmer_in_progress: Scalars['Int']['output'];
  farmer_total_requests: Scalars['Int']['output'];
  ff_accepted_requests: Scalars['Int']['output'];
  ff_completed_on_time_requests: Scalars['Int']['output'];
  ff_completed_requests: Scalars['Int']['output'];
  ff_rejected_requests: Scalars['Int']['output'];
  id: Scalars['bpchar']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id: Scalars['bpchar']['output'];
};

/** aggregated selection of "personal_dashboard" */
export type Personal_Dashboard_Aggregate = {
  __typename?: 'personal_dashboard_aggregate';
  aggregate?: Maybe<Personal_Dashboard_Aggregate_Fields>;
  nodes: Array<Personal_Dashboard>;
};

/** aggregate fields of "personal_dashboard" */
export type Personal_Dashboard_Aggregate_Fields = {
  __typename?: 'personal_dashboard_aggregate_fields';
  avg?: Maybe<Personal_Dashboard_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Personal_Dashboard_Max_Fields>;
  min?: Maybe<Personal_Dashboard_Min_Fields>;
  stddev?: Maybe<Personal_Dashboard_Stddev_Fields>;
  stddev_pop?: Maybe<Personal_Dashboard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Personal_Dashboard_Stddev_Samp_Fields>;
  sum?: Maybe<Personal_Dashboard_Sum_Fields>;
  var_pop?: Maybe<Personal_Dashboard_Var_Pop_Fields>;
  var_samp?: Maybe<Personal_Dashboard_Var_Samp_Fields>;
  variance?: Maybe<Personal_Dashboard_Variance_Fields>;
};


/** aggregate fields of "personal_dashboard" */
export type Personal_Dashboard_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Personal_Dashboard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Personal_Dashboard_Avg_Fields = {
  __typename?: 'personal_dashboard_avg_fields';
  farmer_closed?: Maybe<Scalars['Float']['output']>;
  farmer_in_progress?: Maybe<Scalars['Float']['output']>;
  farmer_total_requests?: Maybe<Scalars['Float']['output']>;
  ff_accepted_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_on_time_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_requests?: Maybe<Scalars['Float']['output']>;
  ff_rejected_requests?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "personal_dashboard". All fields are combined with a logical 'AND'. */
export type Personal_Dashboard_Bool_Exp = {
  _and?: InputMaybe<Array<Personal_Dashboard_Bool_Exp>>;
  _not?: InputMaybe<Personal_Dashboard_Bool_Exp>;
  _or?: InputMaybe<Array<Personal_Dashboard_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  farmer_closed?: InputMaybe<Int_Comparison_Exp>;
  farmer_in_progress?: InputMaybe<Int_Comparison_Exp>;
  farmer_total_requests?: InputMaybe<Int_Comparison_Exp>;
  ff_accepted_requests?: InputMaybe<Int_Comparison_Exp>;
  ff_completed_on_time_requests?: InputMaybe<Int_Comparison_Exp>;
  ff_completed_requests?: InputMaybe<Int_Comparison_Exp>;
  ff_rejected_requests?: InputMaybe<Int_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "personal_dashboard" */
export enum Personal_Dashboard_Constraint {
  /** unique or primary key constraint on columns "id" */
  PersonalDashboardPkey = 'personal_dashboard_pkey'
}

/** input type for incrementing numeric columns in table "personal_dashboard" */
export type Personal_Dashboard_Inc_Input = {
  farmer_closed?: InputMaybe<Scalars['Int']['input']>;
  farmer_in_progress?: InputMaybe<Scalars['Int']['input']>;
  farmer_total_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_accepted_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_completed_on_time_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_completed_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_rejected_requests?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "personal_dashboard" */
export type Personal_Dashboard_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_closed?: InputMaybe<Scalars['Int']['input']>;
  farmer_in_progress?: InputMaybe<Scalars['Int']['input']>;
  farmer_total_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_accepted_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_completed_on_time_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_completed_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_rejected_requests?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type Personal_Dashboard_Max_Fields = {
  __typename?: 'personal_dashboard_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_closed?: Maybe<Scalars['Int']['output']>;
  farmer_in_progress?: Maybe<Scalars['Int']['output']>;
  farmer_total_requests?: Maybe<Scalars['Int']['output']>;
  ff_accepted_requests?: Maybe<Scalars['Int']['output']>;
  ff_completed_on_time_requests?: Maybe<Scalars['Int']['output']>;
  ff_completed_requests?: Maybe<Scalars['Int']['output']>;
  ff_rejected_requests?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type Personal_Dashboard_Min_Fields = {
  __typename?: 'personal_dashboard_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_closed?: Maybe<Scalars['Int']['output']>;
  farmer_in_progress?: Maybe<Scalars['Int']['output']>;
  farmer_total_requests?: Maybe<Scalars['Int']['output']>;
  ff_accepted_requests?: Maybe<Scalars['Int']['output']>;
  ff_completed_on_time_requests?: Maybe<Scalars['Int']['output']>;
  ff_completed_requests?: Maybe<Scalars['Int']['output']>;
  ff_rejected_requests?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "personal_dashboard" */
export type Personal_Dashboard_Mutation_Response = {
  __typename?: 'personal_dashboard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Personal_Dashboard>;
};

/** on_conflict condition type for table "personal_dashboard" */
export type Personal_Dashboard_On_Conflict = {
  constraint: Personal_Dashboard_Constraint;
  update_columns?: Array<Personal_Dashboard_Update_Column>;
  where?: InputMaybe<Personal_Dashboard_Bool_Exp>;
};

/** Ordering options when selecting data from "personal_dashboard". */
export type Personal_Dashboard_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_closed?: InputMaybe<Order_By>;
  farmer_in_progress?: InputMaybe<Order_By>;
  farmer_total_requests?: InputMaybe<Order_By>;
  ff_accepted_requests?: InputMaybe<Order_By>;
  ff_completed_on_time_requests?: InputMaybe<Order_By>;
  ff_completed_requests?: InputMaybe<Order_By>;
  ff_rejected_requests?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: personal_dashboard */
export type Personal_Dashboard_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "personal_dashboard" */
export enum Personal_Dashboard_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerClosed = 'farmer_closed',
  /** column name */
  FarmerInProgress = 'farmer_in_progress',
  /** column name */
  FarmerTotalRequests = 'farmer_total_requests',
  /** column name */
  FfAcceptedRequests = 'ff_accepted_requests',
  /** column name */
  FfCompletedOnTimeRequests = 'ff_completed_on_time_requests',
  /** column name */
  FfCompletedRequests = 'ff_completed_requests',
  /** column name */
  FfRejectedRequests = 'ff_rejected_requests',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "personal_dashboard" */
export type Personal_Dashboard_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_closed?: InputMaybe<Scalars['Int']['input']>;
  farmer_in_progress?: InputMaybe<Scalars['Int']['input']>;
  farmer_total_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_accepted_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_completed_on_time_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_completed_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_rejected_requests?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type Personal_Dashboard_Stddev_Fields = {
  __typename?: 'personal_dashboard_stddev_fields';
  farmer_closed?: Maybe<Scalars['Float']['output']>;
  farmer_in_progress?: Maybe<Scalars['Float']['output']>;
  farmer_total_requests?: Maybe<Scalars['Float']['output']>;
  ff_accepted_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_on_time_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_requests?: Maybe<Scalars['Float']['output']>;
  ff_rejected_requests?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Personal_Dashboard_Stddev_Pop_Fields = {
  __typename?: 'personal_dashboard_stddev_pop_fields';
  farmer_closed?: Maybe<Scalars['Float']['output']>;
  farmer_in_progress?: Maybe<Scalars['Float']['output']>;
  farmer_total_requests?: Maybe<Scalars['Float']['output']>;
  ff_accepted_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_on_time_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_requests?: Maybe<Scalars['Float']['output']>;
  ff_rejected_requests?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Personal_Dashboard_Stddev_Samp_Fields = {
  __typename?: 'personal_dashboard_stddev_samp_fields';
  farmer_closed?: Maybe<Scalars['Float']['output']>;
  farmer_in_progress?: Maybe<Scalars['Float']['output']>;
  farmer_total_requests?: Maybe<Scalars['Float']['output']>;
  ff_accepted_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_on_time_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_requests?: Maybe<Scalars['Float']['output']>;
  ff_rejected_requests?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "personal_dashboard" */
export type Personal_Dashboard_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Personal_Dashboard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Personal_Dashboard_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_closed?: InputMaybe<Scalars['Int']['input']>;
  farmer_in_progress?: InputMaybe<Scalars['Int']['input']>;
  farmer_total_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_accepted_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_completed_on_time_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_completed_requests?: InputMaybe<Scalars['Int']['input']>;
  ff_rejected_requests?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type Personal_Dashboard_Sum_Fields = {
  __typename?: 'personal_dashboard_sum_fields';
  farmer_closed?: Maybe<Scalars['Int']['output']>;
  farmer_in_progress?: Maybe<Scalars['Int']['output']>;
  farmer_total_requests?: Maybe<Scalars['Int']['output']>;
  ff_accepted_requests?: Maybe<Scalars['Int']['output']>;
  ff_completed_on_time_requests?: Maybe<Scalars['Int']['output']>;
  ff_completed_requests?: Maybe<Scalars['Int']['output']>;
  ff_rejected_requests?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "personal_dashboard" */
export enum Personal_Dashboard_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerClosed = 'farmer_closed',
  /** column name */
  FarmerInProgress = 'farmer_in_progress',
  /** column name */
  FarmerTotalRequests = 'farmer_total_requests',
  /** column name */
  FfAcceptedRequests = 'ff_accepted_requests',
  /** column name */
  FfCompletedOnTimeRequests = 'ff_completed_on_time_requests',
  /** column name */
  FfCompletedRequests = 'ff_completed_requests',
  /** column name */
  FfRejectedRequests = 'ff_rejected_requests',
  /** column name */
  Id = 'id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Personal_Dashboard_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Personal_Dashboard_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Personal_Dashboard_Set_Input>;
  /** filter the rows which have to be updated */
  where: Personal_Dashboard_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Personal_Dashboard_Var_Pop_Fields = {
  __typename?: 'personal_dashboard_var_pop_fields';
  farmer_closed?: Maybe<Scalars['Float']['output']>;
  farmer_in_progress?: Maybe<Scalars['Float']['output']>;
  farmer_total_requests?: Maybe<Scalars['Float']['output']>;
  ff_accepted_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_on_time_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_requests?: Maybe<Scalars['Float']['output']>;
  ff_rejected_requests?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Personal_Dashboard_Var_Samp_Fields = {
  __typename?: 'personal_dashboard_var_samp_fields';
  farmer_closed?: Maybe<Scalars['Float']['output']>;
  farmer_in_progress?: Maybe<Scalars['Float']['output']>;
  farmer_total_requests?: Maybe<Scalars['Float']['output']>;
  ff_accepted_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_on_time_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_requests?: Maybe<Scalars['Float']['output']>;
  ff_rejected_requests?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Personal_Dashboard_Variance_Fields = {
  __typename?: 'personal_dashboard_variance_fields';
  farmer_closed?: Maybe<Scalars['Float']['output']>;
  farmer_in_progress?: Maybe<Scalars['Float']['output']>;
  farmer_total_requests?: Maybe<Scalars['Float']['output']>;
  ff_accepted_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_on_time_requests?: Maybe<Scalars['Float']['output']>;
  ff_completed_requests?: Maybe<Scalars['Float']['output']>;
  ff_rejected_requests?: Maybe<Scalars['Float']['output']>;
};

export type Query_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "account_crop" */
  account_crop: Array<Account_Crop>;
  /** fetch aggregated fields from the table: "account_crop" */
  account_crop_aggregate: Account_Crop_Aggregate;
  /** fetch data from the table: "account_crop" using primary key columns */
  account_crop_by_pk?: Maybe<Account_Crop>;
  /** fetch data from the table: "account_segment" */
  account_segment: Array<Account_Segment>;
  /** fetch aggregated fields from the table: "account_segment" */
  account_segment_aggregate: Account_Segment_Aggregate;
  /** fetch data from the table: "account_segment" using primary key columns */
  account_segment_by_pk?: Maybe<Account_Segment>;
  /** fetch data from the table: "account_user" */
  account_user: Array<Account_User>;
  /** fetch aggregated fields from the table: "account_user" */
  account_user_aggregate: Account_User_Aggregate;
  /** fetch data from the table: "account_user" using primary key columns */
  account_user_by_pk?: Maybe<Account_User>;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table: "action_events" */
  action_events: Array<Action_Events>;
  /** fetch aggregated fields from the table: "action_events" */
  action_events_aggregate: Action_Events_Aggregate;
  /** fetch data from the table: "action_events" using primary key columns */
  action_events_by_pk?: Maybe<Action_Events>;
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregate relationship */
  companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** fetch data from the table: "crop_user" */
  crop_user: Array<Crop_User>;
  /** fetch aggregated fields from the table: "crop_user" */
  crop_user_aggregate: Crop_User_Aggregate;
  /** fetch data from the table: "crop_user" using primary key columns */
  crop_user_by_pk?: Maybe<Crop_User>;
  /** fetch data from the table: "failed_jobs" */
  failed_jobs: Array<Failed_Jobs>;
  /** fetch aggregated fields from the table: "failed_jobs" */
  failed_jobs_aggregate: Failed_Jobs_Aggregate;
  /** fetch data from the table: "failed_jobs" using primary key columns */
  failed_jobs_by_pk?: Maybe<Failed_Jobs>;
  /** fetch data from the table: "farmer_nearby_field_force" */
  farmer_nearby_field_force: Array<Farmer_Nearby_Field_Force>;
  /** fetch aggregated fields from the table: "farmer_nearby_field_force" */
  farmer_nearby_field_force_aggregate: Farmer_Nearby_Field_Force_Aggregate;
  /** fetch data from the table: "farmer_nearby_field_force" using primary key columns */
  farmer_nearby_field_force_by_pk?: Maybe<Farmer_Nearby_Field_Force>;
  /** An array relationship */
  farmer_plots: Array<Farmer_Plots>;
  /** An aggregate relationship */
  farmer_plots_aggregate: Farmer_Plots_Aggregate;
  /** fetch data from the table: "farmer_plots" using primary key columns */
  farmer_plots_by_pk?: Maybe<Farmer_Plots>;
  /** An array relationship */
  field_partner_response_events: Array<Field_Partner_Response_Events>;
  /** An aggregate relationship */
  field_partner_response_events_aggregate: Field_Partner_Response_Events_Aggregate;
  /** fetch data from the table: "field_partner_response_events" using primary key columns */
  field_partner_response_events_by_pk?: Maybe<Field_Partner_Response_Events>;
  /** An array relationship */
  field_partner_response_messages: Array<Field_Partner_Response_Messages>;
  /** An aggregate relationship */
  field_partner_response_messages_aggregate: Field_Partner_Response_Messages_Aggregate;
  /** fetch data from the table: "field_partner_response_messages" using primary key columns */
  field_partner_response_messages_by_pk?: Maybe<Field_Partner_Response_Messages>;
  /** An array relationship */
  field_partner_responses: Array<Field_Partner_Responses>;
  /** An aggregate relationship */
  field_partner_responses_aggregate: Field_Partner_Responses_Aggregate;
  /** fetch data from the table: "field_partner_responses" using primary key columns */
  field_partner_responses_by_pk?: Maybe<Field_Partner_Responses>;
  /** An array relationship */
  field_scheduled_visits: Array<Field_Scheduled_Visits>;
  /** An aggregate relationship */
  field_scheduled_visits_aggregate: Field_Scheduled_Visits_Aggregate;
  /** fetch data from the table: "field_scheduled_visits" using primary key columns */
  field_scheduled_visits_by_pk?: Maybe<Field_Scheduled_Visits>;
  /** fetch data from the table: "follows" */
  follows: Array<Follows>;
  /** fetch aggregated fields from the table: "follows" */
  follows_aggregate: Follows_Aggregate;
  /** fetch data from the table: "follows" using primary key columns */
  follows_by_pk?: Maybe<Follows>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** execute function "get_reel_with_related" which returns "reels_with_distance" */
  get_reel_with_related: Array<Reels_With_Distance>;
  /** execute function "get_reel_with_related" and query aggregates on result of table type "reels_with_distance" */
  get_reel_with_related_aggregate: Reels_With_Distance_Aggregate;
  /** execute function "get_reels" which returns "reels_with_distance" */
  get_reels: Array<Reels_With_Distance>;
  /** execute function "get_reels" and query aggregates on result of table type "reels_with_distance" */
  get_reels_aggregate: Reels_With_Distance_Aggregate;
  /** An array relationship */
  last_known_locations: Array<Last_Known_Locations>;
  /** An aggregate relationship */
  last_known_locations_aggregate: Last_Known_Locations_Aggregate;
  /** fetch data from the table: "last_known_locations" using primary key columns */
  last_known_locations_by_pk?: Maybe<Last_Known_Locations>;
  /** fetch data from the table: "master_crops" */
  master_crops: Array<Master_Crops>;
  /** fetch aggregated fields from the table: "master_crops" */
  master_crops_aggregate: Master_Crops_Aggregate;
  /** fetch data from the table: "master_crops" using primary key columns */
  master_crops_by_pk?: Maybe<Master_Crops>;
  /** fetch data from the table: "master_segments" */
  master_segments: Array<Master_Segments>;
  /** fetch aggregated fields from the table: "master_segments" */
  master_segments_aggregate: Master_Segments_Aggregate;
  /** fetch data from the table: "master_segments" using primary key columns */
  master_segments_by_pk?: Maybe<Master_Segments>;
  /** fetch data from the table: "migrations" */
  migrations: Array<Migrations>;
  /** fetch aggregated fields from the table: "migrations" */
  migrations_aggregate: Migrations_Aggregate;
  /** fetch data from the table: "migrations" using primary key columns */
  migrations_by_pk?: Maybe<Migrations>;
  /** An array relationship */
  model_has_permissions: Array<Model_Has_Permissions>;
  /** An aggregate relationship */
  model_has_permissions_aggregate: Model_Has_Permissions_Aggregate;
  /** fetch data from the table: "model_has_permissions" using primary key columns */
  model_has_permissions_by_pk?: Maybe<Model_Has_Permissions>;
  /** An array relationship */
  model_has_roles: Array<Model_Has_Roles>;
  /** An aggregate relationship */
  model_has_roles_aggregate: Model_Has_Roles_Aggregate;
  /** fetch data from the table: "model_has_roles" using primary key columns */
  model_has_roles_by_pk?: Maybe<Model_Has_Roles>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table: "nova_field_attachments" */
  nova_field_attachments: Array<Nova_Field_Attachments>;
  /** fetch aggregated fields from the table: "nova_field_attachments" */
  nova_field_attachments_aggregate: Nova_Field_Attachments_Aggregate;
  /** fetch data from the table: "nova_field_attachments" using primary key columns */
  nova_field_attachments_by_pk?: Maybe<Nova_Field_Attachments>;
  /** fetch data from the table: "nova_notifications" */
  nova_notifications: Array<Nova_Notifications>;
  /** fetch aggregated fields from the table: "nova_notifications" */
  nova_notifications_aggregate: Nova_Notifications_Aggregate;
  /** fetch data from the table: "nova_notifications" using primary key columns */
  nova_notifications_by_pk?: Maybe<Nova_Notifications>;
  /** fetch data from the table: "nova_pending_field_attachments" */
  nova_pending_field_attachments: Array<Nova_Pending_Field_Attachments>;
  /** fetch aggregated fields from the table: "nova_pending_field_attachments" */
  nova_pending_field_attachments_aggregate: Nova_Pending_Field_Attachments_Aggregate;
  /** fetch data from the table: "nova_pending_field_attachments" using primary key columns */
  nova_pending_field_attachments_by_pk?: Maybe<Nova_Pending_Field_Attachments>;
  /** fetch data from the table: "nova_twofa" */
  nova_twofa: Array<Nova_Twofa>;
  /** fetch aggregated fields from the table: "nova_twofa" */
  nova_twofa_aggregate: Nova_Twofa_Aggregate;
  /** fetch data from the table: "nova_twofa" using primary key columns */
  nova_twofa_by_pk?: Maybe<Nova_Twofa>;
  /** fetch data from the table: "pairing_codes" */
  pairing_codes: Array<Pairing_Codes>;
  /** fetch aggregated fields from the table: "pairing_codes" */
  pairing_codes_aggregate: Pairing_Codes_Aggregate;
  /** fetch data from the table: "password_reset_tokens" */
  password_reset_tokens: Array<Password_Reset_Tokens>;
  /** fetch aggregated fields from the table: "password_reset_tokens" */
  password_reset_tokens_aggregate: Password_Reset_Tokens_Aggregate;
  /** fetch data from the table: "password_reset_tokens" using primary key columns */
  password_reset_tokens_by_pk?: Maybe<Password_Reset_Tokens>;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: Permissions_Aggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** fetch data from the table: "personal_access_tokens" */
  personal_access_tokens: Array<Personal_Access_Tokens>;
  /** fetch aggregated fields from the table: "personal_access_tokens" */
  personal_access_tokens_aggregate: Personal_Access_Tokens_Aggregate;
  /** fetch data from the table: "personal_access_tokens" using primary key columns */
  personal_access_tokens_by_pk?: Maybe<Personal_Access_Tokens>;
  /** fetch data from the table: "personal_dashboard" */
  personal_dashboard: Array<Personal_Dashboard>;
  /** fetch aggregated fields from the table: "personal_dashboard" */
  personal_dashboard_aggregate: Personal_Dashboard_Aggregate;
  /** fetch data from the table: "personal_dashboard" using primary key columns */
  personal_dashboard_by_pk?: Maybe<Personal_Dashboard>;
  /** fetch data from the table: "reel_category" */
  reel_category: Array<Reel_Category>;
  /** fetch aggregated fields from the table: "reel_category" */
  reel_category_aggregate: Reel_Category_Aggregate;
  /** fetch data from the table: "reel_category" using primary key columns */
  reel_category_by_pk?: Maybe<Reel_Category>;
  /** An array relationship */
  reel_comments: Array<Reel_Comments>;
  /** An aggregate relationship */
  reel_comments_aggregate: Reel_Comments_Aggregate;
  /** fetch data from the table: "reel_comments" using primary key columns */
  reel_comments_by_pk?: Maybe<Reel_Comments>;
  /** fetch data from the table: "reel_interactions" */
  reel_interactions: Array<Reel_Interactions>;
  /** fetch aggregated fields from the table: "reel_interactions" */
  reel_interactions_aggregate: Reel_Interactions_Aggregate;
  /** fetch data from the table: "reel_interactions" using primary key columns */
  reel_interactions_by_pk?: Maybe<Reel_Interactions>;
  /** fetch data from the table: "reel_user_reports" */
  reel_user_reports: Array<Reel_User_Reports>;
  /** fetch aggregated fields from the table: "reel_user_reports" */
  reel_user_reports_aggregate: Reel_User_Reports_Aggregate;
  /** fetch data from the table: "reel_user_reports" using primary key columns */
  reel_user_reports_by_pk?: Maybe<Reel_User_Reports>;
  /** An array relationship */
  reels: Array<Reels>;
  /** An aggregate relationship */
  reels_aggregate: Reels_Aggregate;
  /** fetch data from the table: "reels" using primary key columns */
  reels_by_pk?: Maybe<Reels>;
  /** fetch data from the table: "reels_with_distance" */
  reels_with_distance: Array<Reels_With_Distance>;
  /** fetch aggregated fields from the table: "reels_with_distance" */
  reels_with_distance_aggregate: Reels_With_Distance_Aggregate;
  /** An array relationship */
  role_has_permissions: Array<Role_Has_Permissions>;
  /** An aggregate relationship */
  role_has_permissions_aggregate: Role_Has_Permissions_Aggregate;
  /** fetch data from the table: "role_has_permissions" using primary key columns */
  role_has_permissions_by_pk?: Maybe<Role_Has_Permissions>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** execute function "search_field_force_near_farmer" which returns "last_known_locations" */
  search_field_force_near_farmer: Array<Last_Known_Locations>;
  /** execute function "search_field_force_near_farmer" and query aggregates on result of table type "last_known_locations" */
  search_field_force_near_farmer_aggregate: Last_Known_Locations_Aggregate;
  /** fetch data from the table: "segment_user" */
  segment_user: Array<Segment_User>;
  /** fetch aggregated fields from the table: "segment_user" */
  segment_user_aggregate: Segment_User_Aggregate;
  /** fetch data from the table: "segment_user" using primary key columns */
  segment_user_by_pk?: Maybe<Segment_User>;
  /** fetch data from the table: "service_request_dashboard" */
  service_request_dashboard: Array<Service_Request_Dashboard>;
  /** fetch aggregated fields from the table: "service_request_dashboard" */
  service_request_dashboard_aggregate: Service_Request_Dashboard_Aggregate;
  /** fetch data from the table: "service_request_dashboard" using primary key columns */
  service_request_dashboard_by_pk?: Maybe<Service_Request_Dashboard>;
  /** An array relationship */
  service_requests: Array<Service_Requests>;
  /** An aggregate relationship */
  service_requests_aggregate: Service_Requests_Aggregate;
  /** fetch data from the table: "service_requests" using primary key columns */
  service_requests_by_pk?: Maybe<Service_Requests>;
  /** fetch data from the table: "snapshots" */
  snapshots: Array<Snapshots>;
  /** fetch aggregated fields from the table: "snapshots" */
  snapshots_aggregate: Snapshots_Aggregate;
  /** fetch data from the table: "snapshots" using primary key columns */
  snapshots_by_pk?: Maybe<Snapshots>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  stateLangFromLocation: GetStateLangQueryResponse;
  /** fetch data from the table: "stored_events" */
  stored_events: Array<Stored_Events>;
  /** fetch aggregated fields from the table: "stored_events" */
  stored_events_aggregate: Stored_Events_Aggregate;
  /** fetch data from the table: "stored_events" using primary key columns */
  stored_events_by_pk?: Maybe<Stored_Events>;
  /** fetch data from the table: "taggables" */
  taggables: Array<Taggables>;
  /** fetch aggregated fields from the table: "taggables" */
  taggables_aggregate: Taggables_Aggregate;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table: "user_device" */
  user_device: Array<User_Device>;
  /** fetch aggregated fields from the table: "user_device" */
  user_device_aggregate: User_Device_Aggregate;
  /** fetch data from the table: "user_device" using primary key columns */
  user_device_by_pk?: Maybe<User_Device>;
  /** An array relationship */
  user_feedbacks: Array<User_Feedbacks>;
  /** An aggregate relationship */
  user_feedbacks_aggregate: User_Feedbacks_Aggregate;
  /** fetch data from the table: "user_feedbacks" using primary key columns */
  user_feedbacks_by_pk?: Maybe<User_Feedbacks>;
  /** An array relationship */
  user_location_logs: Array<User_Location_Logs>;
  /** An aggregate relationship */
  user_location_logs_aggregate: User_Location_Logs_Aggregate;
  /** fetch data from the table: "user_location_logs" using primary key columns */
  user_location_logs_by_pk?: Maybe<User_Location_Logs>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table: "whatsauth_logins" */
  whatsauth_logins: Array<Whatsauth_Logins>;
  /** fetch aggregated fields from the table: "whatsauth_logins" */
  whatsauth_logins_aggregate: Whatsauth_Logins_Aggregate;
  /** fetch data from the table: "whatsauth_logins" using primary key columns */
  whatsauth_logins_by_pk?: Maybe<Whatsauth_Logins>;
};


export type Query_RootAccount_CropArgs = {
  distinct_on?: InputMaybe<Array<Account_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Crop_Order_By>>;
  where?: InputMaybe<Account_Crop_Bool_Exp>;
};


export type Query_RootAccount_Crop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Crop_Order_By>>;
  where?: InputMaybe<Account_Crop_Bool_Exp>;
};


export type Query_RootAccount_Crop_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootAccount_SegmentArgs = {
  distinct_on?: InputMaybe<Array<Account_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Segment_Order_By>>;
  where?: InputMaybe<Account_Segment_Bool_Exp>;
};


export type Query_RootAccount_Segment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Segment_Order_By>>;
  where?: InputMaybe<Account_Segment_Bool_Exp>;
};


export type Query_RootAccount_Segment_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootAccount_UserArgs = {
  distinct_on?: InputMaybe<Array<Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_User_Order_By>>;
  where?: InputMaybe<Account_User_Bool_Exp>;
};


export type Query_RootAccount_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_User_Order_By>>;
  where?: InputMaybe<Account_User_Bool_Exp>;
};


export type Query_RootAccount_User_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Query_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Query_RootAccounts_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootAction_EventsArgs = {
  distinct_on?: InputMaybe<Array<Action_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Events_Order_By>>;
  where?: InputMaybe<Action_Events_Bool_Exp>;
};


export type Query_RootAction_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Events_Order_By>>;
  where?: InputMaybe<Action_Events_Bool_Exp>;
};


export type Query_RootAction_Events_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootCompaniesArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


export type Query_RootCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


export type Query_RootCompanies_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootCrop_UserArgs = {
  distinct_on?: InputMaybe<Array<Crop_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Crop_User_Order_By>>;
  where?: InputMaybe<Crop_User_Bool_Exp>;
};


export type Query_RootCrop_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Crop_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Crop_User_Order_By>>;
  where?: InputMaybe<Crop_User_Bool_Exp>;
};


export type Query_RootCrop_User_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootFailed_JobsArgs = {
  distinct_on?: InputMaybe<Array<Failed_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Failed_Jobs_Order_By>>;
  where?: InputMaybe<Failed_Jobs_Bool_Exp>;
};


export type Query_RootFailed_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Failed_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Failed_Jobs_Order_By>>;
  where?: InputMaybe<Failed_Jobs_Bool_Exp>;
};


export type Query_RootFailed_Jobs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootFarmer_Nearby_Field_ForceArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Nearby_Field_Force_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Nearby_Field_Force_Order_By>>;
  where?: InputMaybe<Farmer_Nearby_Field_Force_Bool_Exp>;
};


export type Query_RootFarmer_Nearby_Field_Force_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Nearby_Field_Force_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Nearby_Field_Force_Order_By>>;
  where?: InputMaybe<Farmer_Nearby_Field_Force_Bool_Exp>;
};


export type Query_RootFarmer_Nearby_Field_Force_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootFarmer_PlotsArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Plots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Plots_Order_By>>;
  where?: InputMaybe<Farmer_Plots_Bool_Exp>;
};


export type Query_RootFarmer_Plots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Plots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Plots_Order_By>>;
  where?: InputMaybe<Farmer_Plots_Bool_Exp>;
};


export type Query_RootFarmer_Plots_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootField_Partner_Response_EventsArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


export type Query_RootField_Partner_Response_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


export type Query_RootField_Partner_Response_Events_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootField_Partner_Response_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


export type Query_RootField_Partner_Response_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


export type Query_RootField_Partner_Response_Messages_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootField_Partner_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


export type Query_RootField_Partner_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


export type Query_RootField_Partner_Responses_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootField_Scheduled_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


export type Query_RootField_Scheduled_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


export type Query_RootField_Scheduled_Visits_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootFollowsArgs = {
  distinct_on?: InputMaybe<Array<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Query_RootFollows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Query_RootFollows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootGeography_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};


export type Query_RootGeography_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};


export type Query_RootGeometry_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};


export type Query_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};


export type Query_RootGet_Reel_With_RelatedArgs = {
  args: Get_Reel_With_Related_Args;
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Query_RootGet_Reel_With_Related_AggregateArgs = {
  args: Get_Reel_With_Related_Args;
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Query_RootGet_ReelsArgs = {
  args: Get_Reels_Args;
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Query_RootGet_Reels_AggregateArgs = {
  args: Get_Reels_Args;
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Query_RootLast_Known_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


export type Query_RootLast_Known_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


export type Query_RootLast_Known_Locations_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootMaster_CropsArgs = {
  distinct_on?: InputMaybe<Array<Master_Crops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Master_Crops_Order_By>>;
  where?: InputMaybe<Master_Crops_Bool_Exp>;
};


export type Query_RootMaster_Crops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Crops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Master_Crops_Order_By>>;
  where?: InputMaybe<Master_Crops_Bool_Exp>;
};


export type Query_RootMaster_Crops_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootMaster_SegmentsArgs = {
  distinct_on?: InputMaybe<Array<Master_Segments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Master_Segments_Order_By>>;
  where?: InputMaybe<Master_Segments_Bool_Exp>;
};


export type Query_RootMaster_Segments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Segments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Master_Segments_Order_By>>;
  where?: InputMaybe<Master_Segments_Bool_Exp>;
};


export type Query_RootMaster_Segments_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootMigrationsArgs = {
  distinct_on?: InputMaybe<Array<Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Migrations_Order_By>>;
  where?: InputMaybe<Migrations_Bool_Exp>;
};


export type Query_RootMigrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Migrations_Order_By>>;
  where?: InputMaybe<Migrations_Bool_Exp>;
};


export type Query_RootMigrations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootModel_Has_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Permissions_Order_By>>;
  where?: InputMaybe<Model_Has_Permissions_Bool_Exp>;
};


export type Query_RootModel_Has_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Permissions_Order_By>>;
  where?: InputMaybe<Model_Has_Permissions_Bool_Exp>;
};


export type Query_RootModel_Has_Permissions_By_PkArgs = {
  model_id: Scalars['String']['input'];
  model_type: Scalars['String']['input'];
  permission_id: Scalars['bigint']['input'];
};


export type Query_RootModel_Has_RolesArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Roles_Order_By>>;
  where?: InputMaybe<Model_Has_Roles_Bool_Exp>;
};


export type Query_RootModel_Has_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Roles_Order_By>>;
  where?: InputMaybe<Model_Has_Roles_Bool_Exp>;
};


export type Query_RootModel_Has_Roles_By_PkArgs = {
  model_id: Scalars['String']['input'];
  model_type: Scalars['String']['input'];
  role_id: Scalars['bigint']['input'];
};


export type Query_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Query_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootNova_Field_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Nova_Field_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Field_Attachments_Order_By>>;
  where?: InputMaybe<Nova_Field_Attachments_Bool_Exp>;
};


export type Query_RootNova_Field_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nova_Field_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Field_Attachments_Order_By>>;
  where?: InputMaybe<Nova_Field_Attachments_Bool_Exp>;
};


export type Query_RootNova_Field_Attachments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootNova_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Nova_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Notifications_Order_By>>;
  where?: InputMaybe<Nova_Notifications_Bool_Exp>;
};


export type Query_RootNova_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nova_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Notifications_Order_By>>;
  where?: InputMaybe<Nova_Notifications_Bool_Exp>;
};


export type Query_RootNova_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Query_RootNova_Pending_Field_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Nova_Pending_Field_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Pending_Field_Attachments_Order_By>>;
  where?: InputMaybe<Nova_Pending_Field_Attachments_Bool_Exp>;
};


export type Query_RootNova_Pending_Field_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nova_Pending_Field_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Pending_Field_Attachments_Order_By>>;
  where?: InputMaybe<Nova_Pending_Field_Attachments_Bool_Exp>;
};


export type Query_RootNova_Pending_Field_Attachments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootNova_TwofaArgs = {
  distinct_on?: InputMaybe<Array<Nova_Twofa_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Twofa_Order_By>>;
  where?: InputMaybe<Nova_Twofa_Bool_Exp>;
};


export type Query_RootNova_Twofa_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nova_Twofa_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Twofa_Order_By>>;
  where?: InputMaybe<Nova_Twofa_Bool_Exp>;
};


export type Query_RootNova_Twofa_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Query_RootPairing_CodesArgs = {
  distinct_on?: InputMaybe<Array<Pairing_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pairing_Codes_Order_By>>;
  where?: InputMaybe<Pairing_Codes_Bool_Exp>;
};


export type Query_RootPairing_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pairing_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pairing_Codes_Order_By>>;
  where?: InputMaybe<Pairing_Codes_Bool_Exp>;
};


export type Query_RootPassword_Reset_TokensArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Tokens_Order_By>>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};


export type Query_RootPassword_Reset_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Tokens_Order_By>>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};


export type Query_RootPassword_Reset_Tokens_By_PkArgs = {
  email: Scalars['String']['input'];
};


export type Query_RootPermissionsArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};


export type Query_RootPermissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};


export type Query_RootPermissions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPersonal_Access_TokensArgs = {
  distinct_on?: InputMaybe<Array<Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<Personal_Access_Tokens_Bool_Exp>;
};


export type Query_RootPersonal_Access_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<Personal_Access_Tokens_Bool_Exp>;
};


export type Query_RootPersonal_Access_Tokens_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootPersonal_DashboardArgs = {
  distinct_on?: InputMaybe<Array<Personal_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Dashboard_Order_By>>;
  where?: InputMaybe<Personal_Dashboard_Bool_Exp>;
};


export type Query_RootPersonal_Dashboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personal_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Dashboard_Order_By>>;
  where?: InputMaybe<Personal_Dashboard_Bool_Exp>;
};


export type Query_RootPersonal_Dashboard_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootReel_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Reel_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Category_Order_By>>;
  where?: InputMaybe<Reel_Category_Bool_Exp>;
};


export type Query_RootReel_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Category_Order_By>>;
  where?: InputMaybe<Reel_Category_Bool_Exp>;
};


export type Query_RootReel_Category_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootReel_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Comments_Order_By>>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};


export type Query_RootReel_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Comments_Order_By>>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};


export type Query_RootReel_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootReel_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Reel_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Interactions_Order_By>>;
  where?: InputMaybe<Reel_Interactions_Bool_Exp>;
};


export type Query_RootReel_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Interactions_Order_By>>;
  where?: InputMaybe<Reel_Interactions_Bool_Exp>;
};


export type Query_RootReel_Interactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootReel_User_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Reel_User_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_User_Reports_Order_By>>;
  where?: InputMaybe<Reel_User_Reports_Bool_Exp>;
};


export type Query_RootReel_User_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_User_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_User_Reports_Order_By>>;
  where?: InputMaybe<Reel_User_Reports_Bool_Exp>;
};


export type Query_RootReel_User_Reports_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootReelsArgs = {
  distinct_on?: InputMaybe<Array<Reels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_Order_By>>;
  where?: InputMaybe<Reels_Bool_Exp>;
};


export type Query_RootReels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_Order_By>>;
  where?: InputMaybe<Reels_Bool_Exp>;
};


export type Query_RootReels_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootReels_With_DistanceArgs = {
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Query_RootReels_With_Distance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Query_RootRole_Has_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Has_Permissions_Order_By>>;
  where?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
};


export type Query_RootRole_Has_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Has_Permissions_Order_By>>;
  where?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
};


export type Query_RootRole_Has_Permissions_By_PkArgs = {
  permission_id: Scalars['bigint']['input'];
  role_id: Scalars['bigint']['input'];
};


export type Query_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Query_RootRoles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootSearch_Field_Force_Near_FarmerArgs = {
  args: Search_Field_Force_Near_Farmer_Args;
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


export type Query_RootSearch_Field_Force_Near_Farmer_AggregateArgs = {
  args: Search_Field_Force_Near_Farmer_Args;
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


export type Query_RootSegment_UserArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Segment_User_Order_By>>;
  where?: InputMaybe<Segment_User_Bool_Exp>;
};


export type Query_RootSegment_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Segment_User_Order_By>>;
  where?: InputMaybe<Segment_User_Bool_Exp>;
};


export type Query_RootSegment_User_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootService_Request_DashboardArgs = {
  distinct_on?: InputMaybe<Array<Service_Request_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Request_Dashboard_Order_By>>;
  where?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
};


export type Query_RootService_Request_Dashboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Request_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Request_Dashboard_Order_By>>;
  where?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
};


export type Query_RootService_Request_Dashboard_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootService_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Service_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Requests_Order_By>>;
  where?: InputMaybe<Service_Requests_Bool_Exp>;
};


export type Query_RootService_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Requests_Order_By>>;
  where?: InputMaybe<Service_Requests_Bool_Exp>;
};


export type Query_RootService_Requests_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootSnapshotsArgs = {
  distinct_on?: InputMaybe<Array<Snapshots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Snapshots_Order_By>>;
  where?: InputMaybe<Snapshots_Bool_Exp>;
};


export type Query_RootSnapshots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Snapshots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Snapshots_Order_By>>;
  where?: InputMaybe<Snapshots_Bool_Exp>;
};


export type Query_RootSnapshots_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootSpatial_Ref_SysArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Query_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Query_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int']['input'];
};


export type Query_RootStateLangFromLocationArgs = {
  request: GetStateLangFromLocationQueryInput;
};


export type Query_RootStored_EventsArgs = {
  distinct_on?: InputMaybe<Array<Stored_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stored_Events_Order_By>>;
  where?: InputMaybe<Stored_Events_Bool_Exp>;
};


export type Query_RootStored_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stored_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stored_Events_Order_By>>;
  where?: InputMaybe<Stored_Events_Bool_Exp>;
};


export type Query_RootStored_Events_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootTaggablesArgs = {
  distinct_on?: InputMaybe<Array<Taggables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taggables_Order_By>>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};


export type Query_RootTaggables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taggables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taggables_Order_By>>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};


export type Query_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Query_RootTags_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootUser_DeviceArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Device_Order_By>>;
  where?: InputMaybe<User_Device_Bool_Exp>;
};


export type Query_RootUser_Device_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Device_Order_By>>;
  where?: InputMaybe<User_Device_Bool_Exp>;
};


export type Query_RootUser_Device_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Query_RootUser_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<User_Feedbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Feedbacks_Order_By>>;
  where?: InputMaybe<User_Feedbacks_Bool_Exp>;
};


export type Query_RootUser_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Feedbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Feedbacks_Order_By>>;
  where?: InputMaybe<User_Feedbacks_Bool_Exp>;
};


export type Query_RootUser_Feedbacks_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootUser_Location_LogsArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Location_Logs_Order_By>>;
  where?: InputMaybe<User_Location_Logs_Bool_Exp>;
};


export type Query_RootUser_Location_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Location_Logs_Order_By>>;
  where?: InputMaybe<User_Location_Logs_Bool_Exp>;
};


export type Query_RootUser_Location_Logs_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Query_RootUsers_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Query_RootWhatsauth_LoginsArgs = {
  distinct_on?: InputMaybe<Array<Whatsauth_Logins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Whatsauth_Logins_Order_By>>;
  where?: InputMaybe<Whatsauth_Logins_Bool_Exp>;
};


export type Query_RootWhatsauth_Logins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Whatsauth_Logins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Whatsauth_Logins_Order_By>>;
  where?: InputMaybe<Whatsauth_Logins_Bool_Exp>;
};


export type Query_RootWhatsauth_Logins_By_PkArgs = {
  id: Scalars['bigint']['input'];
};

/** columns and relationships of "reel_category" */
export type Reel_Category = {
  __typename?: 'reel_category';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  name: Scalars['String']['output'];
  order_column?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['bigint']['output']>;
  /** An array relationship */
  reel_categories: Array<Reel_Category>;
  /** An aggregate relationship */
  reel_categories_aggregate: Reel_Category_Aggregate;
  /** An object relationship */
  reel_category?: Maybe<Reel_Category>;
  /** An array relationship */
  reels: Array<Reels>;
  /** An aggregate relationship */
  reels_aggregate: Reels_Aggregate;
  slug?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "reel_category" */
export type Reel_CategoryReel_CategoriesArgs = {
  distinct_on?: InputMaybe<Array<Reel_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Category_Order_By>>;
  where?: InputMaybe<Reel_Category_Bool_Exp>;
};


/** columns and relationships of "reel_category" */
export type Reel_CategoryReel_Categories_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Category_Order_By>>;
  where?: InputMaybe<Reel_Category_Bool_Exp>;
};


/** columns and relationships of "reel_category" */
export type Reel_CategoryReelsArgs = {
  distinct_on?: InputMaybe<Array<Reels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_Order_By>>;
  where?: InputMaybe<Reels_Bool_Exp>;
};


/** columns and relationships of "reel_category" */
export type Reel_CategoryReels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_Order_By>>;
  where?: InputMaybe<Reels_Bool_Exp>;
};

/** aggregated selection of "reel_category" */
export type Reel_Category_Aggregate = {
  __typename?: 'reel_category_aggregate';
  aggregate?: Maybe<Reel_Category_Aggregate_Fields>;
  nodes: Array<Reel_Category>;
};

export type Reel_Category_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reel_Category_Aggregate_Bool_Exp_Count>;
};

export type Reel_Category_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reel_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reel_Category_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reel_category" */
export type Reel_Category_Aggregate_Fields = {
  __typename?: 'reel_category_aggregate_fields';
  avg?: Maybe<Reel_Category_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reel_Category_Max_Fields>;
  min?: Maybe<Reel_Category_Min_Fields>;
  stddev?: Maybe<Reel_Category_Stddev_Fields>;
  stddev_pop?: Maybe<Reel_Category_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reel_Category_Stddev_Samp_Fields>;
  sum?: Maybe<Reel_Category_Sum_Fields>;
  var_pop?: Maybe<Reel_Category_Var_Pop_Fields>;
  var_samp?: Maybe<Reel_Category_Var_Samp_Fields>;
  variance?: Maybe<Reel_Category_Variance_Fields>;
};


/** aggregate fields of "reel_category" */
export type Reel_Category_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reel_Category_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reel_category" */
export type Reel_Category_Aggregate_Order_By = {
  avg?: InputMaybe<Reel_Category_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reel_Category_Max_Order_By>;
  min?: InputMaybe<Reel_Category_Min_Order_By>;
  stddev?: InputMaybe<Reel_Category_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reel_Category_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reel_Category_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reel_Category_Sum_Order_By>;
  var_pop?: InputMaybe<Reel_Category_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reel_Category_Var_Samp_Order_By>;
  variance?: InputMaybe<Reel_Category_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reel_category" */
export type Reel_Category_Arr_Rel_Insert_Input = {
  data: Array<Reel_Category_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reel_Category_On_Conflict>;
};

/** aggregate avg on columns */
export type Reel_Category_Avg_Fields = {
  __typename?: 'reel_category_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "reel_category" */
export type Reel_Category_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reel_category". All fields are combined with a logical 'AND'. */
export type Reel_Category_Bool_Exp = {
  _and?: InputMaybe<Array<Reel_Category_Bool_Exp>>;
  _not?: InputMaybe<Reel_Category_Bool_Exp>;
  _or?: InputMaybe<Array<Reel_Category_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  order_column?: InputMaybe<Int_Comparison_Exp>;
  parent_id?: InputMaybe<Bigint_Comparison_Exp>;
  reel_categories?: InputMaybe<Reel_Category_Bool_Exp>;
  reel_categories_aggregate?: InputMaybe<Reel_Category_Aggregate_Bool_Exp>;
  reel_category?: InputMaybe<Reel_Category_Bool_Exp>;
  reels?: InputMaybe<Reels_Bool_Exp>;
  reels_aggregate?: InputMaybe<Reels_Aggregate_Bool_Exp>;
  slug?: InputMaybe<String_Comparison_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "reel_category" */
export enum Reel_Category_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReelCategoryPkey = 'reel_category_pkey'
}

/** input type for incrementing numeric columns in table "reel_category" */
export type Reel_Category_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_column?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "reel_category" */
export type Reel_Category_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order_column?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['bigint']['input']>;
  reel_categories?: InputMaybe<Reel_Category_Arr_Rel_Insert_Input>;
  reel_category?: InputMaybe<Reel_Category_Obj_Rel_Insert_Input>;
  reels?: InputMaybe<Reels_Arr_Rel_Insert_Input>;
  slug?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Reel_Category_Max_Fields = {
  __typename?: 'reel_category_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order_column?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by max() on columns of table "reel_category" */
export type Reel_Category_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reel_Category_Min_Fields = {
  __typename?: 'reel_category_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  order_column?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['bigint']['output']>;
  slug?: Maybe<Scalars['String']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** order by min() on columns of table "reel_category" */
export type Reel_Category_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reel_category" */
export type Reel_Category_Mutation_Response = {
  __typename?: 'reel_category_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reel_Category>;
};

/** input type for inserting object relation for remote table "reel_category" */
export type Reel_Category_Obj_Rel_Insert_Input = {
  data: Reel_Category_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reel_Category_On_Conflict>;
};

/** on_conflict condition type for table "reel_category" */
export type Reel_Category_On_Conflict = {
  constraint: Reel_Category_Constraint;
  update_columns?: Array<Reel_Category_Update_Column>;
  where?: InputMaybe<Reel_Category_Bool_Exp>;
};

/** Ordering options when selecting data from "reel_category". */
export type Reel_Category_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
  reel_categories_aggregate?: InputMaybe<Reel_Category_Aggregate_Order_By>;
  reel_category?: InputMaybe<Reel_Category_Order_By>;
  reels_aggregate?: InputMaybe<Reels_Aggregate_Order_By>;
  slug?: InputMaybe<Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reel_category */
export type Reel_Category_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "reel_category" */
export enum Reel_Category_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrderColumn = 'order_column',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "reel_category" */
export type Reel_Category_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order_column?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Reel_Category_Stddev_Fields = {
  __typename?: 'reel_category_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "reel_category" */
export type Reel_Category_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reel_Category_Stddev_Pop_Fields = {
  __typename?: 'reel_category_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "reel_category" */
export type Reel_Category_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reel_Category_Stddev_Samp_Fields = {
  __typename?: 'reel_category_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "reel_category" */
export type Reel_Category_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reel_category" */
export type Reel_Category_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reel_Category_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reel_Category_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  order_column?: InputMaybe<Scalars['Int']['input']>;
  parent_id?: InputMaybe<Scalars['bigint']['input']>;
  slug?: InputMaybe<Scalars['String']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Reel_Category_Sum_Fields = {
  __typename?: 'reel_category_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  order_column?: Maybe<Scalars['Int']['output']>;
  parent_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "reel_category" */
export type Reel_Category_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** update columns of table "reel_category" */
export enum Reel_Category_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrderColumn = 'order_column',
  /** column name */
  ParentId = 'parent_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Reel_Category_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reel_Category_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reel_Category_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reel_Category_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reel_Category_Var_Pop_Fields = {
  __typename?: 'reel_category_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "reel_category" */
export type Reel_Category_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reel_Category_Var_Samp_Fields = {
  __typename?: 'reel_category_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "reel_category" */
export type Reel_Category_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reel_Category_Variance_Fields = {
  __typename?: 'reel_category_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
  parent_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "reel_category" */
export type Reel_Category_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  parent_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "reel_comments" */
export type Reel_Comments = {
  __typename?: 'reel_comments';
  comment: Scalars['String']['output'];
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  reel: Reels;
  reel_id: Scalars['bigint']['output'];
  /** An array relationship */
  reports: Array<Reel_User_Reports>;
  /** An aggregate relationship */
  reports_aggregate: Reel_User_Reports_Aggregate;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['bpchar']['output'];
};


/** columns and relationships of "reel_comments" */
export type Reel_CommentsReportsArgs = {
  distinct_on?: InputMaybe<Array<Reel_User_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_User_Reports_Order_By>>;
  where?: InputMaybe<Reel_User_Reports_Bool_Exp>;
};


/** columns and relationships of "reel_comments" */
export type Reel_CommentsReports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_User_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_User_Reports_Order_By>>;
  where?: InputMaybe<Reel_User_Reports_Bool_Exp>;
};

/** aggregated selection of "reel_comments" */
export type Reel_Comments_Aggregate = {
  __typename?: 'reel_comments_aggregate';
  aggregate?: Maybe<Reel_Comments_Aggregate_Fields>;
  nodes: Array<Reel_Comments>;
};

export type Reel_Comments_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reel_Comments_Aggregate_Bool_Exp_Count>;
};

export type Reel_Comments_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reel_Comments_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reel_comments" */
export type Reel_Comments_Aggregate_Fields = {
  __typename?: 'reel_comments_aggregate_fields';
  avg?: Maybe<Reel_Comments_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reel_Comments_Max_Fields>;
  min?: Maybe<Reel_Comments_Min_Fields>;
  stddev?: Maybe<Reel_Comments_Stddev_Fields>;
  stddev_pop?: Maybe<Reel_Comments_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reel_Comments_Stddev_Samp_Fields>;
  sum?: Maybe<Reel_Comments_Sum_Fields>;
  var_pop?: Maybe<Reel_Comments_Var_Pop_Fields>;
  var_samp?: Maybe<Reel_Comments_Var_Samp_Fields>;
  variance?: Maybe<Reel_Comments_Variance_Fields>;
};


/** aggregate fields of "reel_comments" */
export type Reel_Comments_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reel_comments" */
export type Reel_Comments_Aggregate_Order_By = {
  avg?: InputMaybe<Reel_Comments_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reel_Comments_Max_Order_By>;
  min?: InputMaybe<Reel_Comments_Min_Order_By>;
  stddev?: InputMaybe<Reel_Comments_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reel_Comments_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reel_Comments_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reel_Comments_Sum_Order_By>;
  var_pop?: InputMaybe<Reel_Comments_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reel_Comments_Var_Samp_Order_By>;
  variance?: InputMaybe<Reel_Comments_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reel_comments" */
export type Reel_Comments_Arr_Rel_Insert_Input = {
  data: Array<Reel_Comments_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reel_Comments_On_Conflict>;
};

/** aggregate avg on columns */
export type Reel_Comments_Avg_Fields = {
  __typename?: 'reel_comments_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "reel_comments" */
export type Reel_Comments_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reel_comments". All fields are combined with a logical 'AND'. */
export type Reel_Comments_Bool_Exp = {
  _and?: InputMaybe<Array<Reel_Comments_Bool_Exp>>;
  _not?: InputMaybe<Reel_Comments_Bool_Exp>;
  _or?: InputMaybe<Array<Reel_Comments_Bool_Exp>>;
  comment?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  reel?: InputMaybe<Reels_Bool_Exp>;
  reel_id?: InputMaybe<Bigint_Comparison_Exp>;
  reports?: InputMaybe<Reel_User_Reports_Bool_Exp>;
  reports_aggregate?: InputMaybe<Reel_User_Reports_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "reel_comments" */
export enum Reel_Comments_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReelCommentsPkey = 'reel_comments_pkey'
}

/** input type for incrementing numeric columns in table "reel_comments" */
export type Reel_Comments_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  reel_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "reel_comments" */
export type Reel_Comments_Insert_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  reel?: InputMaybe<Reels_Obj_Rel_Insert_Input>;
  reel_id?: InputMaybe<Scalars['bigint']['input']>;
  reports?: InputMaybe<Reel_User_Reports_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type Reel_Comments_Max_Fields = {
  __typename?: 'reel_comments_max_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  reel_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by max() on columns of table "reel_comments" */
export type Reel_Comments_Max_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reel_Comments_Min_Fields = {
  __typename?: 'reel_comments_min_fields';
  comment?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  reel_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by min() on columns of table "reel_comments" */
export type Reel_Comments_Min_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reel_comments" */
export type Reel_Comments_Mutation_Response = {
  __typename?: 'reel_comments_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reel_Comments>;
};

/** on_conflict condition type for table "reel_comments" */
export type Reel_Comments_On_Conflict = {
  constraint: Reel_Comments_Constraint;
  update_columns?: Array<Reel_Comments_Update_Column>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};

/** Ordering options when selecting data from "reel_comments". */
export type Reel_Comments_Order_By = {
  comment?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel?: InputMaybe<Reels_Order_By>;
  reel_id?: InputMaybe<Order_By>;
  reports_aggregate?: InputMaybe<Reel_User_Reports_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reel_comments */
export type Reel_Comments_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "reel_comments" */
export enum Reel_Comments_Select_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReelId = 'reel_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "reel_comments" */
export type Reel_Comments_Set_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  reel_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type Reel_Comments_Stddev_Fields = {
  __typename?: 'reel_comments_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "reel_comments" */
export type Reel_Comments_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reel_Comments_Stddev_Pop_Fields = {
  __typename?: 'reel_comments_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "reel_comments" */
export type Reel_Comments_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reel_Comments_Stddev_Samp_Fields = {
  __typename?: 'reel_comments_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "reel_comments" */
export type Reel_Comments_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reel_comments" */
export type Reel_Comments_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reel_Comments_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reel_Comments_Stream_Cursor_Value_Input = {
  comment?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  reel_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type Reel_Comments_Sum_Fields = {
  __typename?: 'reel_comments_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  reel_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "reel_comments" */
export type Reel_Comments_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** update columns of table "reel_comments" */
export enum Reel_Comments_Update_Column {
  /** column name */
  Comment = 'comment',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  ReelId = 'reel_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Reel_Comments_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reel_Comments_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reel_Comments_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reel_Comments_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reel_Comments_Var_Pop_Fields = {
  __typename?: 'reel_comments_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "reel_comments" */
export type Reel_Comments_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reel_Comments_Var_Samp_Fields = {
  __typename?: 'reel_comments_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "reel_comments" */
export type Reel_Comments_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reel_Comments_Variance_Fields = {
  __typename?: 'reel_comments_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "reel_comments" */
export type Reel_Comments_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "reel_interactions" */
export type Reel_Interactions = {
  __typename?: 'reel_interactions';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['bigint']['output'];
  interaction_type?: Maybe<Scalars['String']['output']>;
  interaction_value?: Maybe<Scalars['String']['output']>;
  reel_id: Scalars['bigint']['output'];
  /** An object relationship */
  reel_interactions_reel: Reels;
  /** An object relationship */
  reel_interactions_user?: Maybe<Users>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregated selection of "reel_interactions" */
export type Reel_Interactions_Aggregate = {
  __typename?: 'reel_interactions_aggregate';
  aggregate?: Maybe<Reel_Interactions_Aggregate_Fields>;
  nodes: Array<Reel_Interactions>;
};

export type Reel_Interactions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reel_Interactions_Aggregate_Bool_Exp_Count>;
};

export type Reel_Interactions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reel_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reel_Interactions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reel_interactions" */
export type Reel_Interactions_Aggregate_Fields = {
  __typename?: 'reel_interactions_aggregate_fields';
  avg?: Maybe<Reel_Interactions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reel_Interactions_Max_Fields>;
  min?: Maybe<Reel_Interactions_Min_Fields>;
  stddev?: Maybe<Reel_Interactions_Stddev_Fields>;
  stddev_pop?: Maybe<Reel_Interactions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reel_Interactions_Stddev_Samp_Fields>;
  sum?: Maybe<Reel_Interactions_Sum_Fields>;
  var_pop?: Maybe<Reel_Interactions_Var_Pop_Fields>;
  var_samp?: Maybe<Reel_Interactions_Var_Samp_Fields>;
  variance?: Maybe<Reel_Interactions_Variance_Fields>;
};


/** aggregate fields of "reel_interactions" */
export type Reel_Interactions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reel_Interactions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reel_interactions" */
export type Reel_Interactions_Aggregate_Order_By = {
  avg?: InputMaybe<Reel_Interactions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reel_Interactions_Max_Order_By>;
  min?: InputMaybe<Reel_Interactions_Min_Order_By>;
  stddev?: InputMaybe<Reel_Interactions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reel_Interactions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reel_Interactions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reel_Interactions_Sum_Order_By>;
  var_pop?: InputMaybe<Reel_Interactions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reel_Interactions_Var_Samp_Order_By>;
  variance?: InputMaybe<Reel_Interactions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reel_interactions" */
export type Reel_Interactions_Arr_Rel_Insert_Input = {
  data: Array<Reel_Interactions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reel_Interactions_On_Conflict>;
};

/** aggregate avg on columns */
export type Reel_Interactions_Avg_Fields = {
  __typename?: 'reel_interactions_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "reel_interactions" */
export type Reel_Interactions_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reel_interactions". All fields are combined with a logical 'AND'. */
export type Reel_Interactions_Bool_Exp = {
  _and?: InputMaybe<Array<Reel_Interactions_Bool_Exp>>;
  _not?: InputMaybe<Reel_Interactions_Bool_Exp>;
  _or?: InputMaybe<Array<Reel_Interactions_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  interaction_type?: InputMaybe<String_Comparison_Exp>;
  interaction_value?: InputMaybe<String_Comparison_Exp>;
  reel_id?: InputMaybe<Bigint_Comparison_Exp>;
  reel_interactions_reel?: InputMaybe<Reels_Bool_Exp>;
  reel_interactions_user?: InputMaybe<Users_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "reel_interactions" */
export enum Reel_Interactions_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReelInteractionsPkey = 'reel_interactions_pkey'
}

/** input type for incrementing numeric columns in table "reel_interactions" */
export type Reel_Interactions_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  reel_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "reel_interactions" */
export type Reel_Interactions_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  interaction_type?: InputMaybe<Scalars['String']['input']>;
  interaction_value?: InputMaybe<Scalars['String']['input']>;
  reel_id?: InputMaybe<Scalars['bigint']['input']>;
  reel_interactions_reel?: InputMaybe<Reels_Obj_Rel_Insert_Input>;
  reel_interactions_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type Reel_Interactions_Max_Fields = {
  __typename?: 'reel_interactions_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  interaction_type?: Maybe<Scalars['String']['output']>;
  interaction_value?: Maybe<Scalars['String']['output']>;
  reel_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by max() on columns of table "reel_interactions" */
export type Reel_Interactions_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_type?: InputMaybe<Order_By>;
  interaction_value?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reel_Interactions_Min_Fields = {
  __typename?: 'reel_interactions_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  interaction_type?: Maybe<Scalars['String']['output']>;
  interaction_value?: Maybe<Scalars['String']['output']>;
  reel_id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by min() on columns of table "reel_interactions" */
export type Reel_Interactions_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_type?: InputMaybe<Order_By>;
  interaction_value?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reel_interactions" */
export type Reel_Interactions_Mutation_Response = {
  __typename?: 'reel_interactions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reel_Interactions>;
};

/** on_conflict condition type for table "reel_interactions" */
export type Reel_Interactions_On_Conflict = {
  constraint: Reel_Interactions_Constraint;
  update_columns?: Array<Reel_Interactions_Update_Column>;
  where?: InputMaybe<Reel_Interactions_Bool_Exp>;
};

/** Ordering options when selecting data from "reel_interactions". */
export type Reel_Interactions_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  interaction_type?: InputMaybe<Order_By>;
  interaction_value?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
  reel_interactions_reel?: InputMaybe<Reels_Order_By>;
  reel_interactions_user?: InputMaybe<Users_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reel_interactions */
export type Reel_Interactions_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "reel_interactions" */
export enum Reel_Interactions_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InteractionType = 'interaction_type',
  /** column name */
  InteractionValue = 'interaction_value',
  /** column name */
  ReelId = 'reel_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "reel_interactions" */
export type Reel_Interactions_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  interaction_type?: InputMaybe<Scalars['String']['input']>;
  interaction_value?: InputMaybe<Scalars['String']['input']>;
  reel_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type Reel_Interactions_Stddev_Fields = {
  __typename?: 'reel_interactions_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "reel_interactions" */
export type Reel_Interactions_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reel_Interactions_Stddev_Pop_Fields = {
  __typename?: 'reel_interactions_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "reel_interactions" */
export type Reel_Interactions_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reel_Interactions_Stddev_Samp_Fields = {
  __typename?: 'reel_interactions_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "reel_interactions" */
export type Reel_Interactions_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reel_interactions" */
export type Reel_Interactions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reel_Interactions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reel_Interactions_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  interaction_type?: InputMaybe<Scalars['String']['input']>;
  interaction_value?: InputMaybe<Scalars['String']['input']>;
  reel_id?: InputMaybe<Scalars['bigint']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type Reel_Interactions_Sum_Fields = {
  __typename?: 'reel_interactions_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  reel_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "reel_interactions" */
export type Reel_Interactions_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** update columns of table "reel_interactions" */
export enum Reel_Interactions_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  InteractionType = 'interaction_type',
  /** column name */
  InteractionValue = 'interaction_value',
  /** column name */
  ReelId = 'reel_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Reel_Interactions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reel_Interactions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reel_Interactions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reel_Interactions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reel_Interactions_Var_Pop_Fields = {
  __typename?: 'reel_interactions_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "reel_interactions" */
export type Reel_Interactions_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reel_Interactions_Var_Samp_Fields = {
  __typename?: 'reel_interactions_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "reel_interactions" */
export type Reel_Interactions_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reel_Interactions_Variance_Fields = {
  __typename?: 'reel_interactions_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "reel_interactions" */
export type Reel_Interactions_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "reel_user_reports" */
export type Reel_User_Reports = {
  __typename?: 'reel_user_reports';
  comment_id: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  reel_id: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id: Scalars['String']['output'];
};

/** aggregated selection of "reel_user_reports" */
export type Reel_User_Reports_Aggregate = {
  __typename?: 'reel_user_reports_aggregate';
  aggregate?: Maybe<Reel_User_Reports_Aggregate_Fields>;
  nodes: Array<Reel_User_Reports>;
};

export type Reel_User_Reports_Aggregate_Bool_Exp = {
  count?: InputMaybe<Reel_User_Reports_Aggregate_Bool_Exp_Count>;
};

export type Reel_User_Reports_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reel_User_Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reel_User_Reports_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "reel_user_reports" */
export type Reel_User_Reports_Aggregate_Fields = {
  __typename?: 'reel_user_reports_aggregate_fields';
  avg?: Maybe<Reel_User_Reports_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reel_User_Reports_Max_Fields>;
  min?: Maybe<Reel_User_Reports_Min_Fields>;
  stddev?: Maybe<Reel_User_Reports_Stddev_Fields>;
  stddev_pop?: Maybe<Reel_User_Reports_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reel_User_Reports_Stddev_Samp_Fields>;
  sum?: Maybe<Reel_User_Reports_Sum_Fields>;
  var_pop?: Maybe<Reel_User_Reports_Var_Pop_Fields>;
  var_samp?: Maybe<Reel_User_Reports_Var_Samp_Fields>;
  variance?: Maybe<Reel_User_Reports_Variance_Fields>;
};


/** aggregate fields of "reel_user_reports" */
export type Reel_User_Reports_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reel_User_Reports_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reel_user_reports" */
export type Reel_User_Reports_Aggregate_Order_By = {
  avg?: InputMaybe<Reel_User_Reports_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reel_User_Reports_Max_Order_By>;
  min?: InputMaybe<Reel_User_Reports_Min_Order_By>;
  stddev?: InputMaybe<Reel_User_Reports_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reel_User_Reports_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reel_User_Reports_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reel_User_Reports_Sum_Order_By>;
  var_pop?: InputMaybe<Reel_User_Reports_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reel_User_Reports_Var_Samp_Order_By>;
  variance?: InputMaybe<Reel_User_Reports_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reel_user_reports" */
export type Reel_User_Reports_Arr_Rel_Insert_Input = {
  data: Array<Reel_User_Reports_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reel_User_Reports_On_Conflict>;
};

/** aggregate avg on columns */
export type Reel_User_Reports_Avg_Fields = {
  __typename?: 'reel_user_reports_avg_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "reel_user_reports" */
export type Reel_User_Reports_Avg_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reel_user_reports". All fields are combined with a logical 'AND'. */
export type Reel_User_Reports_Bool_Exp = {
  _and?: InputMaybe<Array<Reel_User_Reports_Bool_Exp>>;
  _not?: InputMaybe<Reel_User_Reports_Bool_Exp>;
  _or?: InputMaybe<Array<Reel_User_Reports_Bool_Exp>>;
  comment_id?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feedback?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  reel_id?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reel_user_reports" */
export enum Reel_User_Reports_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReelUserReportsPkey = 'reel_user_reports_pkey'
}

/** input type for incrementing numeric columns in table "reel_user_reports" */
export type Reel_User_Reports_Inc_Input = {
  comment_id?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  reel_id?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "reel_user_reports" */
export type Reel_User_Reports_Insert_Input = {
  comment_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  reel_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Reel_User_Reports_Max_Fields = {
  __typename?: 'reel_user_reports_max_fields';
  comment_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  reel_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "reel_user_reports" */
export type Reel_User_Reports_Max_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reel_User_Reports_Min_Fields = {
  __typename?: 'reel_user_reports_min_fields';
  comment_id?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  reel_id?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "reel_user_reports" */
export type Reel_User_Reports_Min_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reel_user_reports" */
export type Reel_User_Reports_Mutation_Response = {
  __typename?: 'reel_user_reports_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reel_User_Reports>;
};

/** on_conflict condition type for table "reel_user_reports" */
export type Reel_User_Reports_On_Conflict = {
  constraint: Reel_User_Reports_Constraint;
  update_columns?: Array<Reel_User_Reports_Update_Column>;
  where?: InputMaybe<Reel_User_Reports_Bool_Exp>;
};

/** Ordering options when selecting data from "reel_user_reports". */
export type Reel_User_Reports_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reel_user_reports */
export type Reel_User_Reports_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "reel_user_reports" */
export enum Reel_User_Reports_Select_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  Id = 'id',
  /** column name */
  ReelId = 'reel_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "reel_user_reports" */
export type Reel_User_Reports_Set_Input = {
  comment_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  reel_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Reel_User_Reports_Stddev_Fields = {
  __typename?: 'reel_user_reports_stddev_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "reel_user_reports" */
export type Reel_User_Reports_Stddev_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reel_User_Reports_Stddev_Pop_Fields = {
  __typename?: 'reel_user_reports_stddev_pop_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "reel_user_reports" */
export type Reel_User_Reports_Stddev_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reel_User_Reports_Stddev_Samp_Fields = {
  __typename?: 'reel_user_reports_stddev_samp_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "reel_user_reports" */
export type Reel_User_Reports_Stddev_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reel_user_reports" */
export type Reel_User_Reports_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reel_User_Reports_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reel_User_Reports_Stream_Cursor_Value_Input = {
  comment_id?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  reel_id?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Reel_User_Reports_Sum_Fields = {
  __typename?: 'reel_user_reports_sum_fields';
  comment_id?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  reel_id?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "reel_user_reports" */
export type Reel_User_Reports_Sum_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** update columns of table "reel_user_reports" */
export enum Reel_User_Reports_Update_Column {
  /** column name */
  CommentId = 'comment_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  Id = 'id',
  /** column name */
  ReelId = 'reel_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Reel_User_Reports_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reel_User_Reports_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reel_User_Reports_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reel_User_Reports_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reel_User_Reports_Var_Pop_Fields = {
  __typename?: 'reel_user_reports_var_pop_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "reel_user_reports" */
export type Reel_User_Reports_Var_Pop_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reel_User_Reports_Var_Samp_Fields = {
  __typename?: 'reel_user_reports_var_samp_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "reel_user_reports" */
export type Reel_User_Reports_Var_Samp_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reel_User_Reports_Variance_Fields = {
  __typename?: 'reel_user_reports_variance_fields';
  comment_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  reel_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "reel_user_reports" */
export type Reel_User_Reports_Variance_Order_By = {
  comment_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  reel_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "reels" */
export type Reels = {
  __typename?: 'reels';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  allow_comments: Scalars['Boolean']['output'];
  allow_downloads: Scalars['Boolean']['output'];
  allow_likes: Scalars['Boolean']['output'];
  allow_reactions: Scalars['Boolean']['output'];
  allow_reports: Scalars['Boolean']['output'];
  allow_shares: Scalars['Boolean']['output'];
  category_id?: Maybe<Scalars['bigint']['output']>;
  cloudflare_id?: Maybe<Scalars['String']['output']>;
  cloudflare_playback_url?: Maybe<Scalars['String']['output']>;
  cloudflare_preview_url?: Maybe<Scalars['String']['output']>;
  cloudflare_thumbnail_url?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id?: Maybe<Scalars['bpchar']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['String']['output']>;
  google_places_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  language?: Maybe<Scalars['String']['output']>;
  lat: Scalars['float8']['output'];
  likes: Scalars['Int']['output'];
  lng: Scalars['float8']['output'];
  location?: Maybe<Scalars['geography']['output']>;
  reactions?: Maybe<Scalars['json']['output']>;
  reactions_count: Scalars['Int']['output'];
  /** An object relationship */
  reel_category?: Maybe<Reel_Category>;
  /** An array relationship */
  reel_comments: Array<Reel_Comments>;
  /** An aggregate relationship */
  reel_comments_aggregate: Reel_Comments_Aggregate;
  /** An array relationship */
  reels_reel_interactions: Array<Reel_Interactions>;
  /** An aggregate relationship */
  reels_reel_interactions_aggregate: Reel_Interactions_Aggregate;
  /** An array relationship */
  reels_taggables: Array<Taggables>;
  /** An aggregate relationship */
  reels_taggables_aggregate: Taggables_Aggregate;
  /** An object relationship */
  reels_user?: Maybe<Users>;
  s3_path?: Maybe<Scalars['String']['output']>;
  shares: Scalars['Int']['output'];
  targeted_area?: Maybe<Scalars['String']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
  views: Scalars['Int']['output'];
  visibility?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "reels" */
export type ReelsReactionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "reels" */
export type ReelsReel_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Comments_Order_By>>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};


/** columns and relationships of "reels" */
export type ReelsReel_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Comments_Order_By>>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};


/** columns and relationships of "reels" */
export type ReelsReels_Reel_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Reel_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Interactions_Order_By>>;
  where?: InputMaybe<Reel_Interactions_Bool_Exp>;
};


/** columns and relationships of "reels" */
export type ReelsReels_Reel_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Interactions_Order_By>>;
  where?: InputMaybe<Reel_Interactions_Bool_Exp>;
};


/** columns and relationships of "reels" */
export type ReelsReels_TaggablesArgs = {
  distinct_on?: InputMaybe<Array<Taggables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taggables_Order_By>>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};


/** columns and relationships of "reels" */
export type ReelsReels_Taggables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taggables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taggables_Order_By>>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};

/** aggregated selection of "reels" */
export type Reels_Aggregate = {
  __typename?: 'reels_aggregate';
  aggregate?: Maybe<Reels_Aggregate_Fields>;
  nodes: Array<Reels>;
};

export type Reels_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Reels_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Reels_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Reels_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Reels_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Reels_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Reels_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Reels_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Reels_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Reels_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Reels_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Reels_Aggregate_Bool_Exp_Var_Samp>;
};

export type Reels_Aggregate_Bool_Exp_Avg = {
  arguments: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reels_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Reels_Aggregate_Bool_Exp_Bool_And = {
  arguments: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reels_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reels_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reels_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Reels_Aggregate_Bool_Exp_Corr = {
  arguments: Reels_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reels_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Reels_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Reels_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Reels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reels_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Reels_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Reels_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reels_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Reels_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Reels_Aggregate_Bool_Exp_Max = {
  arguments: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reels_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Reels_Aggregate_Bool_Exp_Min = {
  arguments: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reels_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Reels_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reels_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Reels_Aggregate_Bool_Exp_Sum = {
  arguments: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reels_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Reels_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Reels_Select_Column_Reels_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Reels_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "reels" */
export type Reels_Aggregate_Fields = {
  __typename?: 'reels_aggregate_fields';
  avg?: Maybe<Reels_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reels_Max_Fields>;
  min?: Maybe<Reels_Min_Fields>;
  stddev?: Maybe<Reels_Stddev_Fields>;
  stddev_pop?: Maybe<Reels_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reels_Stddev_Samp_Fields>;
  sum?: Maybe<Reels_Sum_Fields>;
  var_pop?: Maybe<Reels_Var_Pop_Fields>;
  var_samp?: Maybe<Reels_Var_Samp_Fields>;
  variance?: Maybe<Reels_Variance_Fields>;
};


/** aggregate fields of "reels" */
export type Reels_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reels_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "reels" */
export type Reels_Aggregate_Order_By = {
  avg?: InputMaybe<Reels_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Reels_Max_Order_By>;
  min?: InputMaybe<Reels_Min_Order_By>;
  stddev?: InputMaybe<Reels_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Reels_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Reels_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Reels_Sum_Order_By>;
  var_pop?: InputMaybe<Reels_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Reels_Var_Samp_Order_By>;
  variance?: InputMaybe<Reels_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "reels" */
export type Reels_Arr_Rel_Insert_Input = {
  data: Array<Reels_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Reels_On_Conflict>;
};

/** aggregate avg on columns */
export type Reels_Avg_Fields = {
  __typename?: 'reels_avg_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "reels" */
export type Reels_Avg_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "reels". All fields are combined with a logical 'AND'. */
export type Reels_Bool_Exp = {
  _and?: InputMaybe<Array<Reels_Bool_Exp>>;
  _not?: InputMaybe<Reels_Bool_Exp>;
  _or?: InputMaybe<Array<Reels_Bool_Exp>>;
  account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  allow_comments?: InputMaybe<Boolean_Comparison_Exp>;
  allow_downloads?: InputMaybe<Boolean_Comparison_Exp>;
  allow_likes?: InputMaybe<Boolean_Comparison_Exp>;
  allow_reactions?: InputMaybe<Boolean_Comparison_Exp>;
  allow_reports?: InputMaybe<Boolean_Comparison_Exp>;
  allow_shares?: InputMaybe<Boolean_Comparison_Exp>;
  category_id?: InputMaybe<Bigint_Comparison_Exp>;
  cloudflare_id?: InputMaybe<String_Comparison_Exp>;
  cloudflare_playback_url?: InputMaybe<String_Comparison_Exp>;
  cloudflare_preview_url?: InputMaybe<String_Comparison_Exp>;
  cloudflare_thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Bpchar_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crop_id?: InputMaybe<Bpchar_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  file_name?: InputMaybe<String_Comparison_Exp>;
  file_size?: InputMaybe<String_Comparison_Exp>;
  google_places_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  lat?: InputMaybe<Float8_Comparison_Exp>;
  likes?: InputMaybe<Int_Comparison_Exp>;
  lng?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Geography_Comparison_Exp>;
  reactions?: InputMaybe<Json_Comparison_Exp>;
  reactions_count?: InputMaybe<Int_Comparison_Exp>;
  reel_category?: InputMaybe<Reel_Category_Bool_Exp>;
  reel_comments?: InputMaybe<Reel_Comments_Bool_Exp>;
  reel_comments_aggregate?: InputMaybe<Reel_Comments_Aggregate_Bool_Exp>;
  reels_reel_interactions?: InputMaybe<Reel_Interactions_Bool_Exp>;
  reels_reel_interactions_aggregate?: InputMaybe<Reel_Interactions_Aggregate_Bool_Exp>;
  reels_taggables?: InputMaybe<Taggables_Bool_Exp>;
  reels_taggables_aggregate?: InputMaybe<Taggables_Aggregate_Bool_Exp>;
  reels_user?: InputMaybe<Users_Bool_Exp>;
  s3_path?: InputMaybe<String_Comparison_Exp>;
  shares?: InputMaybe<Int_Comparison_Exp>;
  targeted_area?: InputMaybe<String_Comparison_Exp>;
  thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  views?: InputMaybe<Int_Comparison_Exp>;
  visibility?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "reels" */
export enum Reels_Constraint {
  /** unique or primary key constraint on columns "id" */
  ReelsPkey = 'reels_pkey',
  /** unique or primary key constraint on columns "s3_path" */
  ReelsS3PathUniq = 'reels_s3_path_uniq'
}

/** input type for incrementing numeric columns in table "reels" */
export type Reels_Inc_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  reactions_count?: InputMaybe<Scalars['Int']['input']>;
  shares?: InputMaybe<Scalars['Int']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "reels" */
export type Reels_Insert_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  allow_comments?: InputMaybe<Scalars['Boolean']['input']>;
  allow_downloads?: InputMaybe<Scalars['Boolean']['input']>;
  allow_likes?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reactions?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reports?: InputMaybe<Scalars['Boolean']['input']>;
  allow_shares?: InputMaybe<Scalars['Boolean']['input']>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  cloudflare_id?: InputMaybe<Scalars['String']['input']>;
  cloudflare_playback_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_preview_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['String']['input']>;
  google_places_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  reactions?: InputMaybe<Scalars['json']['input']>;
  reactions_count?: InputMaybe<Scalars['Int']['input']>;
  reel_category?: InputMaybe<Reel_Category_Obj_Rel_Insert_Input>;
  reel_comments?: InputMaybe<Reel_Comments_Arr_Rel_Insert_Input>;
  reels_reel_interactions?: InputMaybe<Reel_Interactions_Arr_Rel_Insert_Input>;
  reels_taggables?: InputMaybe<Taggables_Arr_Rel_Insert_Input>;
  reels_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  s3_path?: InputMaybe<Scalars['String']['input']>;
  shares?: InputMaybe<Scalars['Int']['input']>;
  targeted_area?: InputMaybe<Scalars['String']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Reels_Max_Fields = {
  __typename?: 'reels_max_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  category_id?: Maybe<Scalars['bigint']['output']>;
  cloudflare_id?: Maybe<Scalars['String']['output']>;
  cloudflare_playback_url?: Maybe<Scalars['String']['output']>;
  cloudflare_preview_url?: Maybe<Scalars['String']['output']>;
  cloudflare_thumbnail_url?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id?: Maybe<Scalars['bpchar']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['String']['output']>;
  google_places_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  reactions_count?: Maybe<Scalars['Int']['output']>;
  s3_path?: Maybe<Scalars['String']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  targeted_area?: Maybe<Scalars['String']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "reels" */
export type Reels_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  cloudflare_id?: InputMaybe<Order_By>;
  cloudflare_playback_url?: InputMaybe<Order_By>;
  cloudflare_preview_url?: InputMaybe<Order_By>;
  cloudflare_thumbnail_url?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  google_places_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  s3_path?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  targeted_area?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
  visibility?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Reels_Min_Fields = {
  __typename?: 'reels_min_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  category_id?: Maybe<Scalars['bigint']['output']>;
  cloudflare_id?: Maybe<Scalars['String']['output']>;
  cloudflare_playback_url?: Maybe<Scalars['String']['output']>;
  cloudflare_preview_url?: Maybe<Scalars['String']['output']>;
  cloudflare_thumbnail_url?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop_id?: Maybe<Scalars['bpchar']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['String']['output']>;
  google_places_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  reactions_count?: Maybe<Scalars['Int']['output']>;
  s3_path?: Maybe<Scalars['String']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  targeted_area?: Maybe<Scalars['String']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "reels" */
export type Reels_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  cloudflare_id?: InputMaybe<Order_By>;
  cloudflare_playback_url?: InputMaybe<Order_By>;
  cloudflare_preview_url?: InputMaybe<Order_By>;
  cloudflare_thumbnail_url?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  google_places_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  s3_path?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  targeted_area?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
  visibility?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "reels" */
export type Reels_Mutation_Response = {
  __typename?: 'reels_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reels>;
};

/** input type for inserting object relation for remote table "reels" */
export type Reels_Obj_Rel_Insert_Input = {
  data: Reels_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Reels_On_Conflict>;
};

/** on_conflict condition type for table "reels" */
export type Reels_On_Conflict = {
  constraint: Reels_Constraint;
  update_columns?: Array<Reels_Update_Column>;
  where?: InputMaybe<Reels_Bool_Exp>;
};

/** Ordering options when selecting data from "reels". */
export type Reels_Order_By = {
  account_id?: InputMaybe<Order_By>;
  allow_comments?: InputMaybe<Order_By>;
  allow_downloads?: InputMaybe<Order_By>;
  allow_likes?: InputMaybe<Order_By>;
  allow_reactions?: InputMaybe<Order_By>;
  allow_reports?: InputMaybe<Order_By>;
  allow_shares?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  cloudflare_id?: InputMaybe<Order_By>;
  cloudflare_playback_url?: InputMaybe<Order_By>;
  cloudflare_preview_url?: InputMaybe<Order_By>;
  cloudflare_thumbnail_url?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  google_places_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  reactions?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  reel_category?: InputMaybe<Reel_Category_Order_By>;
  reel_comments_aggregate?: InputMaybe<Reel_Comments_Aggregate_Order_By>;
  reels_reel_interactions_aggregate?: InputMaybe<Reel_Interactions_Aggregate_Order_By>;
  reels_taggables_aggregate?: InputMaybe<Taggables_Aggregate_Order_By>;
  reels_user?: InputMaybe<Users_Order_By>;
  s3_path?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  targeted_area?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
  visibility?: InputMaybe<Order_By>;
};

/** primary key columns input for table: reels */
export type Reels_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "reels" */
export enum Reels_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AllowComments = 'allow_comments',
  /** column name */
  AllowDownloads = 'allow_downloads',
  /** column name */
  AllowLikes = 'allow_likes',
  /** column name */
  AllowReactions = 'allow_reactions',
  /** column name */
  AllowReports = 'allow_reports',
  /** column name */
  AllowShares = 'allow_shares',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CloudflareId = 'cloudflare_id',
  /** column name */
  CloudflarePlaybackUrl = 'cloudflare_playback_url',
  /** column name */
  CloudflarePreviewUrl = 'cloudflare_preview_url',
  /** column name */
  CloudflareThumbnailUrl = 'cloudflare_thumbnail_url',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  GooglePlacesId = 'google_places_id',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Lat = 'lat',
  /** column name */
  Likes = 'likes',
  /** column name */
  Lng = 'lng',
  /** column name */
  Location = 'location',
  /** column name */
  Reactions = 'reactions',
  /** column name */
  ReactionsCount = 'reactions_count',
  /** column name */
  S3Path = 's3_path',
  /** column name */
  Shares = 'shares',
  /** column name */
  TargetedArea = 'targeted_area',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Views = 'views',
  /** column name */
  Visibility = 'visibility'
}

/** select "reels_aggregate_bool_exp_avg_arguments_columns" columns of table "reels" */
export enum Reels_Select_Column_Reels_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "reels_aggregate_bool_exp_bool_and_arguments_columns" columns of table "reels" */
export enum Reels_Select_Column_Reels_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  AllowComments = 'allow_comments',
  /** column name */
  AllowDownloads = 'allow_downloads',
  /** column name */
  AllowLikes = 'allow_likes',
  /** column name */
  AllowReactions = 'allow_reactions',
  /** column name */
  AllowReports = 'allow_reports',
  /** column name */
  AllowShares = 'allow_shares'
}

/** select "reels_aggregate_bool_exp_bool_or_arguments_columns" columns of table "reels" */
export enum Reels_Select_Column_Reels_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  AllowComments = 'allow_comments',
  /** column name */
  AllowDownloads = 'allow_downloads',
  /** column name */
  AllowLikes = 'allow_likes',
  /** column name */
  AllowReactions = 'allow_reactions',
  /** column name */
  AllowReports = 'allow_reports',
  /** column name */
  AllowShares = 'allow_shares'
}

/** select "reels_aggregate_bool_exp_corr_arguments_columns" columns of table "reels" */
export enum Reels_Select_Column_Reels_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "reels_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "reels" */
export enum Reels_Select_Column_Reels_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "reels_aggregate_bool_exp_max_arguments_columns" columns of table "reels" */
export enum Reels_Select_Column_Reels_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "reels_aggregate_bool_exp_min_arguments_columns" columns of table "reels" */
export enum Reels_Select_Column_Reels_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "reels_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "reels" */
export enum Reels_Select_Column_Reels_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "reels_aggregate_bool_exp_sum_arguments_columns" columns of table "reels" */
export enum Reels_Select_Column_Reels_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "reels_aggregate_bool_exp_var_samp_arguments_columns" columns of table "reels" */
export enum Reels_Select_Column_Reels_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** input type for updating data in table "reels" */
export type Reels_Set_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  allow_comments?: InputMaybe<Scalars['Boolean']['input']>;
  allow_downloads?: InputMaybe<Scalars['Boolean']['input']>;
  allow_likes?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reactions?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reports?: InputMaybe<Scalars['Boolean']['input']>;
  allow_shares?: InputMaybe<Scalars['Boolean']['input']>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  cloudflare_id?: InputMaybe<Scalars['String']['input']>;
  cloudflare_playback_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_preview_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['String']['input']>;
  google_places_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  reactions?: InputMaybe<Scalars['json']['input']>;
  reactions_count?: InputMaybe<Scalars['Int']['input']>;
  s3_path?: InputMaybe<Scalars['String']['input']>;
  shares?: InputMaybe<Scalars['Int']['input']>;
  targeted_area?: InputMaybe<Scalars['String']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Reels_Stddev_Fields = {
  __typename?: 'reels_stddev_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "reels" */
export type Reels_Stddev_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Reels_Stddev_Pop_Fields = {
  __typename?: 'reels_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "reels" */
export type Reels_Stddev_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Reels_Stddev_Samp_Fields = {
  __typename?: 'reels_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "reels" */
export type Reels_Stddev_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "reels" */
export type Reels_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reels_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reels_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  allow_comments?: InputMaybe<Scalars['Boolean']['input']>;
  allow_downloads?: InputMaybe<Scalars['Boolean']['input']>;
  allow_likes?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reactions?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reports?: InputMaybe<Scalars['Boolean']['input']>;
  allow_shares?: InputMaybe<Scalars['Boolean']['input']>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  cloudflare_id?: InputMaybe<Scalars['String']['input']>;
  cloudflare_playback_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_preview_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['String']['input']>;
  google_places_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  reactions?: InputMaybe<Scalars['json']['input']>;
  reactions_count?: InputMaybe<Scalars['Int']['input']>;
  s3_path?: InputMaybe<Scalars['String']['input']>;
  shares?: InputMaybe<Scalars['Int']['input']>;
  targeted_area?: InputMaybe<Scalars['String']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Reels_Sum_Fields = {
  __typename?: 'reels_sum_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  reactions_count?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "reels" */
export type Reels_Sum_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** update columns of table "reels" */
export enum Reels_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AllowComments = 'allow_comments',
  /** column name */
  AllowDownloads = 'allow_downloads',
  /** column name */
  AllowLikes = 'allow_likes',
  /** column name */
  AllowReactions = 'allow_reactions',
  /** column name */
  AllowReports = 'allow_reports',
  /** column name */
  AllowShares = 'allow_shares',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CloudflareId = 'cloudflare_id',
  /** column name */
  CloudflarePlaybackUrl = 'cloudflare_playback_url',
  /** column name */
  CloudflarePreviewUrl = 'cloudflare_preview_url',
  /** column name */
  CloudflareThumbnailUrl = 'cloudflare_thumbnail_url',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  GooglePlacesId = 'google_places_id',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Lat = 'lat',
  /** column name */
  Likes = 'likes',
  /** column name */
  Lng = 'lng',
  /** column name */
  Location = 'location',
  /** column name */
  Reactions = 'reactions',
  /** column name */
  ReactionsCount = 'reactions_count',
  /** column name */
  S3Path = 's3_path',
  /** column name */
  Shares = 'shares',
  /** column name */
  TargetedArea = 'targeted_area',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Views = 'views',
  /** column name */
  Visibility = 'visibility'
}

export type Reels_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reels_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reels_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reels_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reels_Var_Pop_Fields = {
  __typename?: 'reels_var_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "reels" */
export type Reels_Var_Pop_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Reels_Var_Samp_Fields = {
  __typename?: 'reels_var_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "reels" */
export type Reels_Var_Samp_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Reels_Variance_Fields = {
  __typename?: 'reels_variance_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "reels" */
export type Reels_Variance_Order_By = {
  category_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
};

/** columns and relationships of "reels_with_distance" */
export type Reels_With_Distance = {
  __typename?: 'reels_with_distance';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  allow_comments?: Maybe<Scalars['Boolean']['output']>;
  allow_downloads?: Maybe<Scalars['Boolean']['output']>;
  allow_likes?: Maybe<Scalars['Boolean']['output']>;
  allow_reactions?: Maybe<Scalars['Boolean']['output']>;
  allow_reports?: Maybe<Scalars['Boolean']['output']>;
  allow_shares?: Maybe<Scalars['Boolean']['output']>;
  category_id?: Maybe<Scalars['bigint']['output']>;
  cloudflare_id?: Maybe<Scalars['String']['output']>;
  cloudflare_playback_url?: Maybe<Scalars['String']['output']>;
  cloudflare_preview_url?: Maybe<Scalars['String']['output']>;
  cloudflare_thumbnail_url?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['float8']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['String']['output']>;
  google_places_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  location?: Maybe<Scalars['geometry']['output']>;
  reactions?: Maybe<Scalars['json']['output']>;
  reactions_count?: Maybe<Scalars['Int']['output']>;
  /** An array relationship */
  reel_comments: Array<Reel_Comments>;
  /** An aggregate relationship */
  reel_comments_aggregate: Reel_Comments_Aggregate;
  /** An array relationship */
  reels_reel_interactions: Array<Reel_Interactions>;
  /** An aggregate relationship */
  reels_reel_interactions_aggregate: Reel_Interactions_Aggregate;
  /** An array relationship */
  reels_taggables: Array<Taggables>;
  /** An aggregate relationship */
  reels_taggables_aggregate: Taggables_Aggregate;
  /** An object relationship */
  reels_user?: Maybe<Users>;
  s3_path?: Maybe<Scalars['String']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  targeted_area?: Maybe<Scalars['String']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
};


/** columns and relationships of "reels_with_distance" */
export type Reels_With_DistanceReactionsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "reels_with_distance" */
export type Reels_With_DistanceReel_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Comments_Order_By>>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};


/** columns and relationships of "reels_with_distance" */
export type Reels_With_DistanceReel_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Comments_Order_By>>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};


/** columns and relationships of "reels_with_distance" */
export type Reels_With_DistanceReels_Reel_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Reel_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Interactions_Order_By>>;
  where?: InputMaybe<Reel_Interactions_Bool_Exp>;
};


/** columns and relationships of "reels_with_distance" */
export type Reels_With_DistanceReels_Reel_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Interactions_Order_By>>;
  where?: InputMaybe<Reel_Interactions_Bool_Exp>;
};


/** columns and relationships of "reels_with_distance" */
export type Reels_With_DistanceReels_TaggablesArgs = {
  distinct_on?: InputMaybe<Array<Taggables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taggables_Order_By>>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};


/** columns and relationships of "reels_with_distance" */
export type Reels_With_DistanceReels_Taggables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taggables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taggables_Order_By>>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};

export type Reels_With_Distance_Aggregate = {
  __typename?: 'reels_with_distance_aggregate';
  aggregate?: Maybe<Reels_With_Distance_Aggregate_Fields>;
  nodes: Array<Reels_With_Distance>;
};

/** aggregate fields of "reels_with_distance" */
export type Reels_With_Distance_Aggregate_Fields = {
  __typename?: 'reels_with_distance_aggregate_fields';
  avg?: Maybe<Reels_With_Distance_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Reels_With_Distance_Max_Fields>;
  min?: Maybe<Reels_With_Distance_Min_Fields>;
  stddev?: Maybe<Reels_With_Distance_Stddev_Fields>;
  stddev_pop?: Maybe<Reels_With_Distance_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Reels_With_Distance_Stddev_Samp_Fields>;
  sum?: Maybe<Reels_With_Distance_Sum_Fields>;
  var_pop?: Maybe<Reels_With_Distance_Var_Pop_Fields>;
  var_samp?: Maybe<Reels_With_Distance_Var_Samp_Fields>;
  variance?: Maybe<Reels_With_Distance_Variance_Fields>;
};


/** aggregate fields of "reels_with_distance" */
export type Reels_With_Distance_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Reels_With_Distance_Avg_Fields = {
  __typename?: 'reels_with_distance_avg_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "reels_with_distance". All fields are combined with a logical 'AND'. */
export type Reels_With_Distance_Bool_Exp = {
  _and?: InputMaybe<Array<Reels_With_Distance_Bool_Exp>>;
  _not?: InputMaybe<Reels_With_Distance_Bool_Exp>;
  _or?: InputMaybe<Array<Reels_With_Distance_Bool_Exp>>;
  account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  allow_comments?: InputMaybe<Boolean_Comparison_Exp>;
  allow_downloads?: InputMaybe<Boolean_Comparison_Exp>;
  allow_likes?: InputMaybe<Boolean_Comparison_Exp>;
  allow_reactions?: InputMaybe<Boolean_Comparison_Exp>;
  allow_reports?: InputMaybe<Boolean_Comparison_Exp>;
  allow_shares?: InputMaybe<Boolean_Comparison_Exp>;
  category_id?: InputMaybe<Bigint_Comparison_Exp>;
  cloudflare_id?: InputMaybe<String_Comparison_Exp>;
  cloudflare_playback_url?: InputMaybe<String_Comparison_Exp>;
  cloudflare_preview_url?: InputMaybe<String_Comparison_Exp>;
  cloudflare_thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  company_id?: InputMaybe<Bpchar_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  description?: InputMaybe<String_Comparison_Exp>;
  distance?: InputMaybe<Float8_Comparison_Exp>;
  file_name?: InputMaybe<String_Comparison_Exp>;
  file_size?: InputMaybe<String_Comparison_Exp>;
  google_places_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  language?: InputMaybe<String_Comparison_Exp>;
  lat?: InputMaybe<Float8_Comparison_Exp>;
  likes?: InputMaybe<Int_Comparison_Exp>;
  lng?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Geometry_Comparison_Exp>;
  reactions?: InputMaybe<Json_Comparison_Exp>;
  reactions_count?: InputMaybe<Int_Comparison_Exp>;
  reel_comments?: InputMaybe<Reel_Comments_Bool_Exp>;
  reel_comments_aggregate?: InputMaybe<Reel_Comments_Aggregate_Bool_Exp>;
  reels_reel_interactions?: InputMaybe<Reel_Interactions_Bool_Exp>;
  reels_reel_interactions_aggregate?: InputMaybe<Reel_Interactions_Aggregate_Bool_Exp>;
  reels_taggables?: InputMaybe<Taggables_Bool_Exp>;
  reels_taggables_aggregate?: InputMaybe<Taggables_Aggregate_Bool_Exp>;
  reels_user?: InputMaybe<Users_Bool_Exp>;
  s3_path?: InputMaybe<String_Comparison_Exp>;
  shares?: InputMaybe<Int_Comparison_Exp>;
  targeted_area?: InputMaybe<String_Comparison_Exp>;
  thumbnail_url?: InputMaybe<String_Comparison_Exp>;
  title?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  views?: InputMaybe<Int_Comparison_Exp>;
  visibility?: InputMaybe<String_Comparison_Exp>;
};

/** input type for incrementing numeric columns in table "reels_with_distance" */
export type Reels_With_Distance_Inc_Input = {
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  distance?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  reactions_count?: InputMaybe<Scalars['Int']['input']>;
  shares?: InputMaybe<Scalars['Int']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "reels_with_distance" */
export type Reels_With_Distance_Insert_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  allow_comments?: InputMaybe<Scalars['Boolean']['input']>;
  allow_downloads?: InputMaybe<Scalars['Boolean']['input']>;
  allow_likes?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reactions?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reports?: InputMaybe<Scalars['Boolean']['input']>;
  allow_shares?: InputMaybe<Scalars['Boolean']['input']>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  cloudflare_id?: InputMaybe<Scalars['String']['input']>;
  cloudflare_playback_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_preview_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  distance?: InputMaybe<Scalars['float8']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['String']['input']>;
  google_places_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geometry']['input']>;
  reactions?: InputMaybe<Scalars['json']['input']>;
  reactions_count?: InputMaybe<Scalars['Int']['input']>;
  reel_comments?: InputMaybe<Reel_Comments_Arr_Rel_Insert_Input>;
  reels_reel_interactions?: InputMaybe<Reel_Interactions_Arr_Rel_Insert_Input>;
  reels_taggables?: InputMaybe<Taggables_Arr_Rel_Insert_Input>;
  reels_user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  s3_path?: InputMaybe<Scalars['String']['input']>;
  shares?: InputMaybe<Scalars['Int']['input']>;
  targeted_area?: InputMaybe<Scalars['String']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Reels_With_Distance_Max_Fields = {
  __typename?: 'reels_with_distance_max_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  category_id?: Maybe<Scalars['bigint']['output']>;
  cloudflare_id?: Maybe<Scalars['String']['output']>;
  cloudflare_playback_url?: Maybe<Scalars['String']['output']>;
  cloudflare_preview_url?: Maybe<Scalars['String']['output']>;
  cloudflare_thumbnail_url?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['float8']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['String']['output']>;
  google_places_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  reactions_count?: Maybe<Scalars['Int']['output']>;
  s3_path?: Maybe<Scalars['String']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  targeted_area?: Maybe<Scalars['String']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Reels_With_Distance_Min_Fields = {
  __typename?: 'reels_with_distance_min_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  category_id?: Maybe<Scalars['bigint']['output']>;
  cloudflare_id?: Maybe<Scalars['String']['output']>;
  cloudflare_playback_url?: Maybe<Scalars['String']['output']>;
  cloudflare_preview_url?: Maybe<Scalars['String']['output']>;
  cloudflare_thumbnail_url?: Maybe<Scalars['String']['output']>;
  company_id?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  description?: Maybe<Scalars['String']['output']>;
  distance?: Maybe<Scalars['float8']['output']>;
  file_name?: Maybe<Scalars['String']['output']>;
  file_size?: Maybe<Scalars['String']['output']>;
  google_places_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  language?: Maybe<Scalars['String']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  reactions_count?: Maybe<Scalars['Int']['output']>;
  s3_path?: Maybe<Scalars['String']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  targeted_area?: Maybe<Scalars['String']['output']>;
  thumbnail_url?: Maybe<Scalars['String']['output']>;
  title?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
  visibility?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "reels_with_distance" */
export type Reels_With_Distance_Mutation_Response = {
  __typename?: 'reels_with_distance_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Reels_With_Distance>;
};

/** Ordering options when selecting data from "reels_with_distance". */
export type Reels_With_Distance_Order_By = {
  account_id?: InputMaybe<Order_By>;
  allow_comments?: InputMaybe<Order_By>;
  allow_downloads?: InputMaybe<Order_By>;
  allow_likes?: InputMaybe<Order_By>;
  allow_reactions?: InputMaybe<Order_By>;
  allow_reports?: InputMaybe<Order_By>;
  allow_shares?: InputMaybe<Order_By>;
  category_id?: InputMaybe<Order_By>;
  cloudflare_id?: InputMaybe<Order_By>;
  cloudflare_playback_url?: InputMaybe<Order_By>;
  cloudflare_preview_url?: InputMaybe<Order_By>;
  cloudflare_thumbnail_url?: InputMaybe<Order_By>;
  company_id?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  description?: InputMaybe<Order_By>;
  distance?: InputMaybe<Order_By>;
  file_name?: InputMaybe<Order_By>;
  file_size?: InputMaybe<Order_By>;
  google_places_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  language?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  likes?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  reactions?: InputMaybe<Order_By>;
  reactions_count?: InputMaybe<Order_By>;
  reel_comments_aggregate?: InputMaybe<Reel_Comments_Aggregate_Order_By>;
  reels_reel_interactions_aggregate?: InputMaybe<Reel_Interactions_Aggregate_Order_By>;
  reels_taggables_aggregate?: InputMaybe<Taggables_Aggregate_Order_By>;
  reels_user?: InputMaybe<Users_Order_By>;
  s3_path?: InputMaybe<Order_By>;
  shares?: InputMaybe<Order_By>;
  targeted_area?: InputMaybe<Order_By>;
  thumbnail_url?: InputMaybe<Order_By>;
  title?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
  views?: InputMaybe<Order_By>;
  visibility?: InputMaybe<Order_By>;
};

/** select columns of table "reels_with_distance" */
export enum Reels_With_Distance_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  AllowComments = 'allow_comments',
  /** column name */
  AllowDownloads = 'allow_downloads',
  /** column name */
  AllowLikes = 'allow_likes',
  /** column name */
  AllowReactions = 'allow_reactions',
  /** column name */
  AllowReports = 'allow_reports',
  /** column name */
  AllowShares = 'allow_shares',
  /** column name */
  CategoryId = 'category_id',
  /** column name */
  CloudflareId = 'cloudflare_id',
  /** column name */
  CloudflarePlaybackUrl = 'cloudflare_playback_url',
  /** column name */
  CloudflarePreviewUrl = 'cloudflare_preview_url',
  /** column name */
  CloudflareThumbnailUrl = 'cloudflare_thumbnail_url',
  /** column name */
  CompanyId = 'company_id',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Description = 'description',
  /** column name */
  Distance = 'distance',
  /** column name */
  FileName = 'file_name',
  /** column name */
  FileSize = 'file_size',
  /** column name */
  GooglePlacesId = 'google_places_id',
  /** column name */
  Id = 'id',
  /** column name */
  Language = 'language',
  /** column name */
  Lat = 'lat',
  /** column name */
  Likes = 'likes',
  /** column name */
  Lng = 'lng',
  /** column name */
  Location = 'location',
  /** column name */
  Reactions = 'reactions',
  /** column name */
  ReactionsCount = 'reactions_count',
  /** column name */
  S3Path = 's3_path',
  /** column name */
  Shares = 'shares',
  /** column name */
  TargetedArea = 'targeted_area',
  /** column name */
  ThumbnailUrl = 'thumbnail_url',
  /** column name */
  Title = 'title',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id',
  /** column name */
  Views = 'views',
  /** column name */
  Visibility = 'visibility'
}

/** input type for updating data in table "reels_with_distance" */
export type Reels_With_Distance_Set_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  allow_comments?: InputMaybe<Scalars['Boolean']['input']>;
  allow_downloads?: InputMaybe<Scalars['Boolean']['input']>;
  allow_likes?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reactions?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reports?: InputMaybe<Scalars['Boolean']['input']>;
  allow_shares?: InputMaybe<Scalars['Boolean']['input']>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  cloudflare_id?: InputMaybe<Scalars['String']['input']>;
  cloudflare_playback_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_preview_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  distance?: InputMaybe<Scalars['float8']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['String']['input']>;
  google_places_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geometry']['input']>;
  reactions?: InputMaybe<Scalars['json']['input']>;
  reactions_count?: InputMaybe<Scalars['Int']['input']>;
  s3_path?: InputMaybe<Scalars['String']['input']>;
  shares?: InputMaybe<Scalars['Int']['input']>;
  targeted_area?: InputMaybe<Scalars['String']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Reels_With_Distance_Stddev_Fields = {
  __typename?: 'reels_with_distance_stddev_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Reels_With_Distance_Stddev_Pop_Fields = {
  __typename?: 'reels_with_distance_stddev_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Reels_With_Distance_Stddev_Samp_Fields = {
  __typename?: 'reels_with_distance_stddev_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "reels_with_distance" */
export type Reels_With_Distance_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Reels_With_Distance_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Reels_With_Distance_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  allow_comments?: InputMaybe<Scalars['Boolean']['input']>;
  allow_downloads?: InputMaybe<Scalars['Boolean']['input']>;
  allow_likes?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reactions?: InputMaybe<Scalars['Boolean']['input']>;
  allow_reports?: InputMaybe<Scalars['Boolean']['input']>;
  allow_shares?: InputMaybe<Scalars['Boolean']['input']>;
  category_id?: InputMaybe<Scalars['bigint']['input']>;
  cloudflare_id?: InputMaybe<Scalars['String']['input']>;
  cloudflare_playback_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_preview_url?: InputMaybe<Scalars['String']['input']>;
  cloudflare_thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  company_id?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  description?: InputMaybe<Scalars['String']['input']>;
  distance?: InputMaybe<Scalars['float8']['input']>;
  file_name?: InputMaybe<Scalars['String']['input']>;
  file_size?: InputMaybe<Scalars['String']['input']>;
  google_places_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  language?: InputMaybe<Scalars['String']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  likes?: InputMaybe<Scalars['Int']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geometry']['input']>;
  reactions?: InputMaybe<Scalars['json']['input']>;
  reactions_count?: InputMaybe<Scalars['Int']['input']>;
  s3_path?: InputMaybe<Scalars['String']['input']>;
  shares?: InputMaybe<Scalars['Int']['input']>;
  targeted_area?: InputMaybe<Scalars['String']['input']>;
  thumbnail_url?: InputMaybe<Scalars['String']['input']>;
  title?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
  views?: InputMaybe<Scalars['Int']['input']>;
  visibility?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Reels_With_Distance_Sum_Fields = {
  __typename?: 'reels_with_distance_sum_fields';
  category_id?: Maybe<Scalars['bigint']['output']>;
  distance?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  likes?: Maybe<Scalars['Int']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  reactions_count?: Maybe<Scalars['Int']['output']>;
  shares?: Maybe<Scalars['Int']['output']>;
  views?: Maybe<Scalars['Int']['output']>;
};

export type Reels_With_Distance_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Reels_With_Distance_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Reels_With_Distance_Set_Input>;
  /** filter the rows which have to be updated */
  where: Reels_With_Distance_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Reels_With_Distance_Var_Pop_Fields = {
  __typename?: 'reels_with_distance_var_pop_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Reels_With_Distance_Var_Samp_Fields = {
  __typename?: 'reels_with_distance_var_samp_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Reels_With_Distance_Variance_Fields = {
  __typename?: 'reels_with_distance_variance_fields';
  category_id?: Maybe<Scalars['Float']['output']>;
  distance?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
  lat?: Maybe<Scalars['Float']['output']>;
  likes?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
  reactions_count?: Maybe<Scalars['Float']['output']>;
  shares?: Maybe<Scalars['Float']['output']>;
  views?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "role_has_permissions" */
export type Role_Has_Permissions = {
  __typename?: 'role_has_permissions';
  /** An object relationship */
  permission: Permissions;
  permission_id: Scalars['bigint']['output'];
  /** An object relationship */
  role: Roles;
  role_id: Scalars['bigint']['output'];
};

/** aggregated selection of "role_has_permissions" */
export type Role_Has_Permissions_Aggregate = {
  __typename?: 'role_has_permissions_aggregate';
  aggregate?: Maybe<Role_Has_Permissions_Aggregate_Fields>;
  nodes: Array<Role_Has_Permissions>;
};

export type Role_Has_Permissions_Aggregate_Bool_Exp = {
  count?: InputMaybe<Role_Has_Permissions_Aggregate_Bool_Exp_Count>;
};

export type Role_Has_Permissions_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Role_Has_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "role_has_permissions" */
export type Role_Has_Permissions_Aggregate_Fields = {
  __typename?: 'role_has_permissions_aggregate_fields';
  avg?: Maybe<Role_Has_Permissions_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Role_Has_Permissions_Max_Fields>;
  min?: Maybe<Role_Has_Permissions_Min_Fields>;
  stddev?: Maybe<Role_Has_Permissions_Stddev_Fields>;
  stddev_pop?: Maybe<Role_Has_Permissions_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Role_Has_Permissions_Stddev_Samp_Fields>;
  sum?: Maybe<Role_Has_Permissions_Sum_Fields>;
  var_pop?: Maybe<Role_Has_Permissions_Var_Pop_Fields>;
  var_samp?: Maybe<Role_Has_Permissions_Var_Samp_Fields>;
  variance?: Maybe<Role_Has_Permissions_Variance_Fields>;
};


/** aggregate fields of "role_has_permissions" */
export type Role_Has_Permissions_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Role_Has_Permissions_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "role_has_permissions" */
export type Role_Has_Permissions_Aggregate_Order_By = {
  avg?: InputMaybe<Role_Has_Permissions_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Role_Has_Permissions_Max_Order_By>;
  min?: InputMaybe<Role_Has_Permissions_Min_Order_By>;
  stddev?: InputMaybe<Role_Has_Permissions_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Role_Has_Permissions_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Role_Has_Permissions_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Role_Has_Permissions_Sum_Order_By>;
  var_pop?: InputMaybe<Role_Has_Permissions_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Role_Has_Permissions_Var_Samp_Order_By>;
  variance?: InputMaybe<Role_Has_Permissions_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "role_has_permissions" */
export type Role_Has_Permissions_Arr_Rel_Insert_Input = {
  data: Array<Role_Has_Permissions_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Role_Has_Permissions_On_Conflict>;
};

/** aggregate avg on columns */
export type Role_Has_Permissions_Avg_Fields = {
  __typename?: 'role_has_permissions_avg_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "role_has_permissions" */
export type Role_Has_Permissions_Avg_Order_By = {
  permission_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "role_has_permissions". All fields are combined with a logical 'AND'. */
export type Role_Has_Permissions_Bool_Exp = {
  _and?: InputMaybe<Array<Role_Has_Permissions_Bool_Exp>>;
  _not?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
  _or?: InputMaybe<Array<Role_Has_Permissions_Bool_Exp>>;
  permission?: InputMaybe<Permissions_Bool_Exp>;
  permission_id?: InputMaybe<Bigint_Comparison_Exp>;
  role?: InputMaybe<Roles_Bool_Exp>;
  role_id?: InputMaybe<Bigint_Comparison_Exp>;
};

/** unique or primary key constraints on table "role_has_permissions" */
export enum Role_Has_Permissions_Constraint {
  /** unique or primary key constraint on columns "role_id", "permission_id" */
  RoleHasPermissionsPkey = 'role_has_permissions_pkey'
}

/** input type for incrementing numeric columns in table "role_has_permissions" */
export type Role_Has_Permissions_Inc_Input = {
  permission_id?: InputMaybe<Scalars['bigint']['input']>;
  role_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "role_has_permissions" */
export type Role_Has_Permissions_Insert_Input = {
  permission?: InputMaybe<Permissions_Obj_Rel_Insert_Input>;
  permission_id?: InputMaybe<Scalars['bigint']['input']>;
  role?: InputMaybe<Roles_Obj_Rel_Insert_Input>;
  role_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate max on columns */
export type Role_Has_Permissions_Max_Fields = {
  __typename?: 'role_has_permissions_max_fields';
  permission_id?: Maybe<Scalars['bigint']['output']>;
  role_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by max() on columns of table "role_has_permissions" */
export type Role_Has_Permissions_Max_Order_By = {
  permission_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Role_Has_Permissions_Min_Fields = {
  __typename?: 'role_has_permissions_min_fields';
  permission_id?: Maybe<Scalars['bigint']['output']>;
  role_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by min() on columns of table "role_has_permissions" */
export type Role_Has_Permissions_Min_Order_By = {
  permission_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "role_has_permissions" */
export type Role_Has_Permissions_Mutation_Response = {
  __typename?: 'role_has_permissions_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Role_Has_Permissions>;
};

/** on_conflict condition type for table "role_has_permissions" */
export type Role_Has_Permissions_On_Conflict = {
  constraint: Role_Has_Permissions_Constraint;
  update_columns?: Array<Role_Has_Permissions_Update_Column>;
  where?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
};

/** Ordering options when selecting data from "role_has_permissions". */
export type Role_Has_Permissions_Order_By = {
  permission?: InputMaybe<Permissions_Order_By>;
  permission_id?: InputMaybe<Order_By>;
  role?: InputMaybe<Roles_Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: role_has_permissions */
export type Role_Has_Permissions_Pk_Columns_Input = {
  permission_id: Scalars['bigint']['input'];
  role_id: Scalars['bigint']['input'];
};

/** select columns of table "role_has_permissions" */
export enum Role_Has_Permissions_Select_Column {
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  RoleId = 'role_id'
}

/** input type for updating data in table "role_has_permissions" */
export type Role_Has_Permissions_Set_Input = {
  permission_id?: InputMaybe<Scalars['bigint']['input']>;
  role_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate stddev on columns */
export type Role_Has_Permissions_Stddev_Fields = {
  __typename?: 'role_has_permissions_stddev_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "role_has_permissions" */
export type Role_Has_Permissions_Stddev_Order_By = {
  permission_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Role_Has_Permissions_Stddev_Pop_Fields = {
  __typename?: 'role_has_permissions_stddev_pop_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "role_has_permissions" */
export type Role_Has_Permissions_Stddev_Pop_Order_By = {
  permission_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Role_Has_Permissions_Stddev_Samp_Fields = {
  __typename?: 'role_has_permissions_stddev_samp_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "role_has_permissions" */
export type Role_Has_Permissions_Stddev_Samp_Order_By = {
  permission_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "role_has_permissions" */
export type Role_Has_Permissions_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Role_Has_Permissions_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Role_Has_Permissions_Stream_Cursor_Value_Input = {
  permission_id?: InputMaybe<Scalars['bigint']['input']>;
  role_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** aggregate sum on columns */
export type Role_Has_Permissions_Sum_Fields = {
  __typename?: 'role_has_permissions_sum_fields';
  permission_id?: Maybe<Scalars['bigint']['output']>;
  role_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "role_has_permissions" */
export type Role_Has_Permissions_Sum_Order_By = {
  permission_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** update columns of table "role_has_permissions" */
export enum Role_Has_Permissions_Update_Column {
  /** column name */
  PermissionId = 'permission_id',
  /** column name */
  RoleId = 'role_id'
}

export type Role_Has_Permissions_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Role_Has_Permissions_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Role_Has_Permissions_Set_Input>;
  /** filter the rows which have to be updated */
  where: Role_Has_Permissions_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Role_Has_Permissions_Var_Pop_Fields = {
  __typename?: 'role_has_permissions_var_pop_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "role_has_permissions" */
export type Role_Has_Permissions_Var_Pop_Order_By = {
  permission_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Role_Has_Permissions_Var_Samp_Fields = {
  __typename?: 'role_has_permissions_var_samp_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "role_has_permissions" */
export type Role_Has_Permissions_Var_Samp_Order_By = {
  permission_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Role_Has_Permissions_Variance_Fields = {
  __typename?: 'role_has_permissions_variance_fields';
  permission_id?: Maybe<Scalars['Float']['output']>;
  role_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "role_has_permissions" */
export type Role_Has_Permissions_Variance_Order_By = {
  permission_id?: InputMaybe<Order_By>;
  role_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "roles" */
export type Roles = {
  __typename?: 'roles';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  guard_name: Scalars['String']['output'];
  id: Scalars['bigint']['output'];
  /** An array relationship */
  model_has_roles: Array<Model_Has_Roles>;
  /** An aggregate relationship */
  model_has_roles_aggregate: Model_Has_Roles_Aggregate;
  name: Scalars['String']['output'];
  /** An array relationship */
  role_has_permissions: Array<Role_Has_Permissions>;
  /** An aggregate relationship */
  role_has_permissions_aggregate: Role_Has_Permissions_Aggregate;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "roles" */
export type RolesModel_Has_RolesArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Roles_Order_By>>;
  where?: InputMaybe<Model_Has_Roles_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesModel_Has_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Roles_Order_By>>;
  where?: InputMaybe<Model_Has_Roles_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesRole_Has_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Has_Permissions_Order_By>>;
  where?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
};


/** columns and relationships of "roles" */
export type RolesRole_Has_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Has_Permissions_Order_By>>;
  where?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
};

/** aggregated selection of "roles" */
export type Roles_Aggregate = {
  __typename?: 'roles_aggregate';
  aggregate?: Maybe<Roles_Aggregate_Fields>;
  nodes: Array<Roles>;
};

/** aggregate fields of "roles" */
export type Roles_Aggregate_Fields = {
  __typename?: 'roles_aggregate_fields';
  avg?: Maybe<Roles_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Roles_Max_Fields>;
  min?: Maybe<Roles_Min_Fields>;
  stddev?: Maybe<Roles_Stddev_Fields>;
  stddev_pop?: Maybe<Roles_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Roles_Stddev_Samp_Fields>;
  sum?: Maybe<Roles_Sum_Fields>;
  var_pop?: Maybe<Roles_Var_Pop_Fields>;
  var_samp?: Maybe<Roles_Var_Samp_Fields>;
  variance?: Maybe<Roles_Variance_Fields>;
};


/** aggregate fields of "roles" */
export type Roles_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Roles_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Roles_Avg_Fields = {
  __typename?: 'roles_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "roles". All fields are combined with a logical 'AND'. */
export type Roles_Bool_Exp = {
  _and?: InputMaybe<Array<Roles_Bool_Exp>>;
  _not?: InputMaybe<Roles_Bool_Exp>;
  _or?: InputMaybe<Array<Roles_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  guard_name?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  model_has_roles?: InputMaybe<Model_Has_Roles_Bool_Exp>;
  model_has_roles_aggregate?: InputMaybe<Model_Has_Roles_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  role_has_permissions?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
  role_has_permissions_aggregate?: InputMaybe<Role_Has_Permissions_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "roles" */
export enum Roles_Constraint {
  /** unique or primary key constraint on columns "name", "guard_name" */
  RolesNameGuardNameUnique = 'roles_name_guard_name_unique',
  /** unique or primary key constraint on columns "id" */
  RolesPkey = 'roles_pkey'
}

/** input type for incrementing numeric columns in table "roles" */
export type Roles_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "roles" */
export type Roles_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  guard_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  model_has_roles?: InputMaybe<Model_Has_Roles_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  role_has_permissions?: InputMaybe<Role_Has_Permissions_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Roles_Max_Fields = {
  __typename?: 'roles_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  guard_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Roles_Min_Fields = {
  __typename?: 'roles_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  guard_name?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "roles" */
export type Roles_Mutation_Response = {
  __typename?: 'roles_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Roles>;
};

/** input type for inserting object relation for remote table "roles" */
export type Roles_Obj_Rel_Insert_Input = {
  data: Roles_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Roles_On_Conflict>;
};

/** on_conflict condition type for table "roles" */
export type Roles_On_Conflict = {
  constraint: Roles_Constraint;
  update_columns?: Array<Roles_Update_Column>;
  where?: InputMaybe<Roles_Bool_Exp>;
};

/** Ordering options when selecting data from "roles". */
export type Roles_Order_By = {
  created_at?: InputMaybe<Order_By>;
  guard_name?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  model_has_roles_aggregate?: InputMaybe<Model_Has_Roles_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  role_has_permissions_aggregate?: InputMaybe<Role_Has_Permissions_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: roles */
export type Roles_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "roles" */
export enum Roles_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GuardName = 'guard_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "roles" */
export type Roles_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  guard_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Roles_Stddev_Fields = {
  __typename?: 'roles_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Roles_Stddev_Pop_Fields = {
  __typename?: 'roles_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Roles_Stddev_Samp_Fields = {
  __typename?: 'roles_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "roles" */
export type Roles_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Roles_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Roles_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  guard_name?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Roles_Sum_Fields = {
  __typename?: 'roles_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "roles" */
export enum Roles_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  GuardName = 'guard_name',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Roles_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Roles_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Roles_Set_Input>;
  /** filter the rows which have to be updated */
  where: Roles_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Roles_Var_Pop_Fields = {
  __typename?: 'roles_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Roles_Var_Samp_Fields = {
  __typename?: 'roles_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Roles_Variance_Fields = {
  __typename?: 'roles_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type Search_Field_Force_Near_Farmer_Args = {
  distance_kms?: InputMaybe<Scalars['Int']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
};

/** columns and relationships of "segment_user" */
export type Segment_User = {
  __typename?: 'segment_user';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  /** An object relationship */
  master_segment: Master_Segments;
  segment_id: Scalars['bpchar']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['bpchar']['output'];
};

/** aggregated selection of "segment_user" */
export type Segment_User_Aggregate = {
  __typename?: 'segment_user_aggregate';
  aggregate?: Maybe<Segment_User_Aggregate_Fields>;
  nodes: Array<Segment_User>;
};

export type Segment_User_Aggregate_Bool_Exp = {
  count?: InputMaybe<Segment_User_Aggregate_Bool_Exp_Count>;
};

export type Segment_User_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Segment_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Segment_User_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "segment_user" */
export type Segment_User_Aggregate_Fields = {
  __typename?: 'segment_user_aggregate_fields';
  avg?: Maybe<Segment_User_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Segment_User_Max_Fields>;
  min?: Maybe<Segment_User_Min_Fields>;
  stddev?: Maybe<Segment_User_Stddev_Fields>;
  stddev_pop?: Maybe<Segment_User_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Segment_User_Stddev_Samp_Fields>;
  sum?: Maybe<Segment_User_Sum_Fields>;
  var_pop?: Maybe<Segment_User_Var_Pop_Fields>;
  var_samp?: Maybe<Segment_User_Var_Samp_Fields>;
  variance?: Maybe<Segment_User_Variance_Fields>;
};


/** aggregate fields of "segment_user" */
export type Segment_User_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Segment_User_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "segment_user" */
export type Segment_User_Aggregate_Order_By = {
  avg?: InputMaybe<Segment_User_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Segment_User_Max_Order_By>;
  min?: InputMaybe<Segment_User_Min_Order_By>;
  stddev?: InputMaybe<Segment_User_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Segment_User_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Segment_User_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Segment_User_Sum_Order_By>;
  var_pop?: InputMaybe<Segment_User_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Segment_User_Var_Samp_Order_By>;
  variance?: InputMaybe<Segment_User_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "segment_user" */
export type Segment_User_Arr_Rel_Insert_Input = {
  data: Array<Segment_User_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Segment_User_On_Conflict>;
};

/** aggregate avg on columns */
export type Segment_User_Avg_Fields = {
  __typename?: 'segment_user_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "segment_user" */
export type Segment_User_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "segment_user". All fields are combined with a logical 'AND'. */
export type Segment_User_Bool_Exp = {
  _and?: InputMaybe<Array<Segment_User_Bool_Exp>>;
  _not?: InputMaybe<Segment_User_Bool_Exp>;
  _or?: InputMaybe<Array<Segment_User_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  master_segment?: InputMaybe<Master_Segments_Bool_Exp>;
  segment_id?: InputMaybe<Bpchar_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "segment_user" */
export enum Segment_User_Constraint {
  /** unique or primary key constraint on columns "id" */
  SegmentUserPkey = 'segment_user_pkey'
}

/** input type for incrementing numeric columns in table "segment_user" */
export type Segment_User_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "segment_user" */
export type Segment_User_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  master_segment?: InputMaybe<Master_Segments_Obj_Rel_Insert_Input>;
  segment_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type Segment_User_Max_Fields = {
  __typename?: 'segment_user_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  segment_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by max() on columns of table "segment_user" */
export type Segment_User_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Segment_User_Min_Fields = {
  __typename?: 'segment_user_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  segment_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by min() on columns of table "segment_user" */
export type Segment_User_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  segment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "segment_user" */
export type Segment_User_Mutation_Response = {
  __typename?: 'segment_user_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Segment_User>;
};

/** on_conflict condition type for table "segment_user" */
export type Segment_User_On_Conflict = {
  constraint: Segment_User_Constraint;
  update_columns?: Array<Segment_User_Update_Column>;
  where?: InputMaybe<Segment_User_Bool_Exp>;
};

/** Ordering options when selecting data from "segment_user". */
export type Segment_User_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  master_segment?: InputMaybe<Master_Segments_Order_By>;
  segment_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: segment_user */
export type Segment_User_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "segment_user" */
export enum Segment_User_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "segment_user" */
export type Segment_User_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  segment_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type Segment_User_Stddev_Fields = {
  __typename?: 'segment_user_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "segment_user" */
export type Segment_User_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Segment_User_Stddev_Pop_Fields = {
  __typename?: 'segment_user_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "segment_user" */
export type Segment_User_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Segment_User_Stddev_Samp_Fields = {
  __typename?: 'segment_user_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "segment_user" */
export type Segment_User_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "segment_user" */
export type Segment_User_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Segment_User_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Segment_User_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  segment_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type Segment_User_Sum_Fields = {
  __typename?: 'segment_user_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "segment_user" */
export type Segment_User_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** update columns of table "segment_user" */
export enum Segment_User_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  SegmentId = 'segment_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type Segment_User_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Segment_User_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Segment_User_Set_Input>;
  /** filter the rows which have to be updated */
  where: Segment_User_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Segment_User_Var_Pop_Fields = {
  __typename?: 'segment_user_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "segment_user" */
export type Segment_User_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Segment_User_Var_Samp_Fields = {
  __typename?: 'segment_user_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "segment_user" */
export type Segment_User_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Segment_User_Variance_Fields = {
  __typename?: 'segment_user_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "segment_user" */
export type Segment_User_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_request_dashboard" */
export type Service_Request_Dashboard = {
  __typename?: 'service_request_dashboard';
  /** An object relationship */
  account: Accounts;
  account_id: Scalars['bpchar']['output'];
  active_requests: Scalars['Int']['output'];
  assigned_requests: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_happiness_green: Scalars['float8']['output'];
  farmer_happiness_red: Scalars['float8']['output'];
  farmer_happiness_yellow: Scalars['float8']['output'];
  id: Scalars['bpchar']['output'];
  live_field_partners: Scalars['Int']['output'];
  missed_requests: Scalars['Int']['output'];
  out_of_range_requests: Scalars['Int']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregated selection of "service_request_dashboard" */
export type Service_Request_Dashboard_Aggregate = {
  __typename?: 'service_request_dashboard_aggregate';
  aggregate?: Maybe<Service_Request_Dashboard_Aggregate_Fields>;
  nodes: Array<Service_Request_Dashboard>;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Service_Request_Dashboard_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<Service_Request_Dashboard_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Service_Request_Dashboard_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Service_Request_Dashboard_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Service_Request_Dashboard_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Service_Request_Dashboard_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Service_Request_Dashboard_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Service_Request_Dashboard_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Service_Request_Dashboard_Aggregate_Bool_Exp_Var_Samp>;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp_Avg = {
  arguments: Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp_Corr = {
  arguments: Service_Request_Dashboard_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Service_Request_Dashboard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Service_Request_Dashboard_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp_Max = {
  arguments: Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp_Min = {
  arguments: Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp_Sum = {
  arguments: Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Request_Dashboard_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "service_request_dashboard" */
export type Service_Request_Dashboard_Aggregate_Fields = {
  __typename?: 'service_request_dashboard_aggregate_fields';
  avg?: Maybe<Service_Request_Dashboard_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Service_Request_Dashboard_Max_Fields>;
  min?: Maybe<Service_Request_Dashboard_Min_Fields>;
  stddev?: Maybe<Service_Request_Dashboard_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Request_Dashboard_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Request_Dashboard_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Request_Dashboard_Sum_Fields>;
  var_pop?: Maybe<Service_Request_Dashboard_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Request_Dashboard_Var_Samp_Fields>;
  variance?: Maybe<Service_Request_Dashboard_Variance_Fields>;
};


/** aggregate fields of "service_request_dashboard" */
export type Service_Request_Dashboard_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Service_Request_Dashboard_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_request_dashboard" */
export type Service_Request_Dashboard_Aggregate_Order_By = {
  avg?: InputMaybe<Service_Request_Dashboard_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Service_Request_Dashboard_Max_Order_By>;
  min?: InputMaybe<Service_Request_Dashboard_Min_Order_By>;
  stddev?: InputMaybe<Service_Request_Dashboard_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Service_Request_Dashboard_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Service_Request_Dashboard_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Service_Request_Dashboard_Sum_Order_By>;
  var_pop?: InputMaybe<Service_Request_Dashboard_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Service_Request_Dashboard_Var_Samp_Order_By>;
  variance?: InputMaybe<Service_Request_Dashboard_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "service_request_dashboard" */
export type Service_Request_Dashboard_Arr_Rel_Insert_Input = {
  data: Array<Service_Request_Dashboard_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_Request_Dashboard_On_Conflict>;
};

/** aggregate avg on columns */
export type Service_Request_Dashboard_Avg_Fields = {
  __typename?: 'service_request_dashboard_avg_fields';
  active_requests?: Maybe<Scalars['Float']['output']>;
  assigned_requests?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_green?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_red?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_yellow?: Maybe<Scalars['Float']['output']>;
  live_field_partners?: Maybe<Scalars['Float']['output']>;
  missed_requests?: Maybe<Scalars['Float']['output']>;
  out_of_range_requests?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_request_dashboard" */
export type Service_Request_Dashboard_Avg_Order_By = {
  active_requests?: InputMaybe<Order_By>;
  assigned_requests?: InputMaybe<Order_By>;
  farmer_happiness_green?: InputMaybe<Order_By>;
  farmer_happiness_red?: InputMaybe<Order_By>;
  farmer_happiness_yellow?: InputMaybe<Order_By>;
  live_field_partners?: InputMaybe<Order_By>;
  missed_requests?: InputMaybe<Order_By>;
  out_of_range_requests?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_request_dashboard". All fields are combined with a logical 'AND'. */
export type Service_Request_Dashboard_Bool_Exp = {
  _and?: InputMaybe<Array<Service_Request_Dashboard_Bool_Exp>>;
  _not?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Request_Dashboard_Bool_Exp>>;
  account?: InputMaybe<Accounts_Bool_Exp>;
  account_id?: InputMaybe<Bpchar_Comparison_Exp>;
  active_requests?: InputMaybe<Int_Comparison_Exp>;
  assigned_requests?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  farmer_happiness_green?: InputMaybe<Float8_Comparison_Exp>;
  farmer_happiness_red?: InputMaybe<Float8_Comparison_Exp>;
  farmer_happiness_yellow?: InputMaybe<Float8_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  live_field_partners?: InputMaybe<Int_Comparison_Exp>;
  missed_requests?: InputMaybe<Int_Comparison_Exp>;
  out_of_range_requests?: InputMaybe<Int_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
};

/** unique or primary key constraints on table "service_request_dashboard" */
export enum Service_Request_Dashboard_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceRequestDashboardPkey = 'service_request_dashboard_pkey'
}

/** input type for incrementing numeric columns in table "service_request_dashboard" */
export type Service_Request_Dashboard_Inc_Input = {
  active_requests?: InputMaybe<Scalars['Int']['input']>;
  assigned_requests?: InputMaybe<Scalars['Int']['input']>;
  farmer_happiness_green?: InputMaybe<Scalars['float8']['input']>;
  farmer_happiness_red?: InputMaybe<Scalars['float8']['input']>;
  farmer_happiness_yellow?: InputMaybe<Scalars['float8']['input']>;
  live_field_partners?: InputMaybe<Scalars['Int']['input']>;
  missed_requests?: InputMaybe<Scalars['Int']['input']>;
  out_of_range_requests?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "service_request_dashboard" */
export type Service_Request_Dashboard_Insert_Input = {
  account?: InputMaybe<Accounts_Obj_Rel_Insert_Input>;
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  active_requests?: InputMaybe<Scalars['Int']['input']>;
  assigned_requests?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_happiness_green?: InputMaybe<Scalars['float8']['input']>;
  farmer_happiness_red?: InputMaybe<Scalars['float8']['input']>;
  farmer_happiness_yellow?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  live_field_partners?: InputMaybe<Scalars['Int']['input']>;
  missed_requests?: InputMaybe<Scalars['Int']['input']>;
  out_of_range_requests?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate max on columns */
export type Service_Request_Dashboard_Max_Fields = {
  __typename?: 'service_request_dashboard_max_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  active_requests?: Maybe<Scalars['Int']['output']>;
  assigned_requests?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_happiness_green?: Maybe<Scalars['float8']['output']>;
  farmer_happiness_red?: Maybe<Scalars['float8']['output']>;
  farmer_happiness_yellow?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  live_field_partners?: Maybe<Scalars['Int']['output']>;
  missed_requests?: Maybe<Scalars['Int']['output']>;
  out_of_range_requests?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "service_request_dashboard" */
export type Service_Request_Dashboard_Max_Order_By = {
  account_id?: InputMaybe<Order_By>;
  active_requests?: InputMaybe<Order_By>;
  assigned_requests?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_happiness_green?: InputMaybe<Order_By>;
  farmer_happiness_red?: InputMaybe<Order_By>;
  farmer_happiness_yellow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_field_partners?: InputMaybe<Order_By>;
  missed_requests?: InputMaybe<Order_By>;
  out_of_range_requests?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Service_Request_Dashboard_Min_Fields = {
  __typename?: 'service_request_dashboard_min_fields';
  account_id?: Maybe<Scalars['bpchar']['output']>;
  active_requests?: Maybe<Scalars['Int']['output']>;
  assigned_requests?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  farmer_happiness_green?: Maybe<Scalars['float8']['output']>;
  farmer_happiness_red?: Maybe<Scalars['float8']['output']>;
  farmer_happiness_yellow?: Maybe<Scalars['float8']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  live_field_partners?: Maybe<Scalars['Int']['output']>;
  missed_requests?: Maybe<Scalars['Int']['output']>;
  out_of_range_requests?: Maybe<Scalars['Int']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "service_request_dashboard" */
export type Service_Request_Dashboard_Min_Order_By = {
  account_id?: InputMaybe<Order_By>;
  active_requests?: InputMaybe<Order_By>;
  assigned_requests?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_happiness_green?: InputMaybe<Order_By>;
  farmer_happiness_red?: InputMaybe<Order_By>;
  farmer_happiness_yellow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_field_partners?: InputMaybe<Order_By>;
  missed_requests?: InputMaybe<Order_By>;
  out_of_range_requests?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_request_dashboard" */
export type Service_Request_Dashboard_Mutation_Response = {
  __typename?: 'service_request_dashboard_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Service_Request_Dashboard>;
};

/** on_conflict condition type for table "service_request_dashboard" */
export type Service_Request_Dashboard_On_Conflict = {
  constraint: Service_Request_Dashboard_Constraint;
  update_columns?: Array<Service_Request_Dashboard_Update_Column>;
  where?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
};

/** Ordering options when selecting data from "service_request_dashboard". */
export type Service_Request_Dashboard_Order_By = {
  account?: InputMaybe<Accounts_Order_By>;
  account_id?: InputMaybe<Order_By>;
  active_requests?: InputMaybe<Order_By>;
  assigned_requests?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  farmer_happiness_green?: InputMaybe<Order_By>;
  farmer_happiness_red?: InputMaybe<Order_By>;
  farmer_happiness_yellow?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  live_field_partners?: InputMaybe<Order_By>;
  missed_requests?: InputMaybe<Order_By>;
  out_of_range_requests?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: service_request_dashboard */
export type Service_Request_Dashboard_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "service_request_dashboard" */
export enum Service_Request_Dashboard_Select_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ActiveRequests = 'active_requests',
  /** column name */
  AssignedRequests = 'assigned_requests',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerHappinessGreen = 'farmer_happiness_green',
  /** column name */
  FarmerHappinessRed = 'farmer_happiness_red',
  /** column name */
  FarmerHappinessYellow = 'farmer_happiness_yellow',
  /** column name */
  Id = 'id',
  /** column name */
  LiveFieldPartners = 'live_field_partners',
  /** column name */
  MissedRequests = 'missed_requests',
  /** column name */
  OutOfRangeRequests = 'out_of_range_requests',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "service_request_dashboard_aggregate_bool_exp_avg_arguments_columns" columns of table "service_request_dashboard" */
export enum Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  FarmerHappinessGreen = 'farmer_happiness_green',
  /** column name */
  FarmerHappinessRed = 'farmer_happiness_red',
  /** column name */
  FarmerHappinessYellow = 'farmer_happiness_yellow'
}

/** select "service_request_dashboard_aggregate_bool_exp_corr_arguments_columns" columns of table "service_request_dashboard" */
export enum Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  FarmerHappinessGreen = 'farmer_happiness_green',
  /** column name */
  FarmerHappinessRed = 'farmer_happiness_red',
  /** column name */
  FarmerHappinessYellow = 'farmer_happiness_yellow'
}

/** select "service_request_dashboard_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "service_request_dashboard" */
export enum Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  FarmerHappinessGreen = 'farmer_happiness_green',
  /** column name */
  FarmerHappinessRed = 'farmer_happiness_red',
  /** column name */
  FarmerHappinessYellow = 'farmer_happiness_yellow'
}

/** select "service_request_dashboard_aggregate_bool_exp_max_arguments_columns" columns of table "service_request_dashboard" */
export enum Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  FarmerHappinessGreen = 'farmer_happiness_green',
  /** column name */
  FarmerHappinessRed = 'farmer_happiness_red',
  /** column name */
  FarmerHappinessYellow = 'farmer_happiness_yellow'
}

/** select "service_request_dashboard_aggregate_bool_exp_min_arguments_columns" columns of table "service_request_dashboard" */
export enum Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  FarmerHappinessGreen = 'farmer_happiness_green',
  /** column name */
  FarmerHappinessRed = 'farmer_happiness_red',
  /** column name */
  FarmerHappinessYellow = 'farmer_happiness_yellow'
}

/** select "service_request_dashboard_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "service_request_dashboard" */
export enum Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  FarmerHappinessGreen = 'farmer_happiness_green',
  /** column name */
  FarmerHappinessRed = 'farmer_happiness_red',
  /** column name */
  FarmerHappinessYellow = 'farmer_happiness_yellow'
}

/** select "service_request_dashboard_aggregate_bool_exp_sum_arguments_columns" columns of table "service_request_dashboard" */
export enum Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  FarmerHappinessGreen = 'farmer_happiness_green',
  /** column name */
  FarmerHappinessRed = 'farmer_happiness_red',
  /** column name */
  FarmerHappinessYellow = 'farmer_happiness_yellow'
}

/** select "service_request_dashboard_aggregate_bool_exp_var_samp_arguments_columns" columns of table "service_request_dashboard" */
export enum Service_Request_Dashboard_Select_Column_Service_Request_Dashboard_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  FarmerHappinessGreen = 'farmer_happiness_green',
  /** column name */
  FarmerHappinessRed = 'farmer_happiness_red',
  /** column name */
  FarmerHappinessYellow = 'farmer_happiness_yellow'
}

/** input type for updating data in table "service_request_dashboard" */
export type Service_Request_Dashboard_Set_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  active_requests?: InputMaybe<Scalars['Int']['input']>;
  assigned_requests?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_happiness_green?: InputMaybe<Scalars['float8']['input']>;
  farmer_happiness_red?: InputMaybe<Scalars['float8']['input']>;
  farmer_happiness_yellow?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  live_field_partners?: InputMaybe<Scalars['Int']['input']>;
  missed_requests?: InputMaybe<Scalars['Int']['input']>;
  out_of_range_requests?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Service_Request_Dashboard_Stddev_Fields = {
  __typename?: 'service_request_dashboard_stddev_fields';
  active_requests?: Maybe<Scalars['Float']['output']>;
  assigned_requests?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_green?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_red?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_yellow?: Maybe<Scalars['Float']['output']>;
  live_field_partners?: Maybe<Scalars['Float']['output']>;
  missed_requests?: Maybe<Scalars['Float']['output']>;
  out_of_range_requests?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_request_dashboard" */
export type Service_Request_Dashboard_Stddev_Order_By = {
  active_requests?: InputMaybe<Order_By>;
  assigned_requests?: InputMaybe<Order_By>;
  farmer_happiness_green?: InputMaybe<Order_By>;
  farmer_happiness_red?: InputMaybe<Order_By>;
  farmer_happiness_yellow?: InputMaybe<Order_By>;
  live_field_partners?: InputMaybe<Order_By>;
  missed_requests?: InputMaybe<Order_By>;
  out_of_range_requests?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Service_Request_Dashboard_Stddev_Pop_Fields = {
  __typename?: 'service_request_dashboard_stddev_pop_fields';
  active_requests?: Maybe<Scalars['Float']['output']>;
  assigned_requests?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_green?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_red?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_yellow?: Maybe<Scalars['Float']['output']>;
  live_field_partners?: Maybe<Scalars['Float']['output']>;
  missed_requests?: Maybe<Scalars['Float']['output']>;
  out_of_range_requests?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_request_dashboard" */
export type Service_Request_Dashboard_Stddev_Pop_Order_By = {
  active_requests?: InputMaybe<Order_By>;
  assigned_requests?: InputMaybe<Order_By>;
  farmer_happiness_green?: InputMaybe<Order_By>;
  farmer_happiness_red?: InputMaybe<Order_By>;
  farmer_happiness_yellow?: InputMaybe<Order_By>;
  live_field_partners?: InputMaybe<Order_By>;
  missed_requests?: InputMaybe<Order_By>;
  out_of_range_requests?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Service_Request_Dashboard_Stddev_Samp_Fields = {
  __typename?: 'service_request_dashboard_stddev_samp_fields';
  active_requests?: Maybe<Scalars['Float']['output']>;
  assigned_requests?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_green?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_red?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_yellow?: Maybe<Scalars['Float']['output']>;
  live_field_partners?: Maybe<Scalars['Float']['output']>;
  missed_requests?: Maybe<Scalars['Float']['output']>;
  out_of_range_requests?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_request_dashboard" */
export type Service_Request_Dashboard_Stddev_Samp_Order_By = {
  active_requests?: InputMaybe<Order_By>;
  assigned_requests?: InputMaybe<Order_By>;
  farmer_happiness_green?: InputMaybe<Order_By>;
  farmer_happiness_red?: InputMaybe<Order_By>;
  farmer_happiness_yellow?: InputMaybe<Order_By>;
  live_field_partners?: InputMaybe<Order_By>;
  missed_requests?: InputMaybe<Order_By>;
  out_of_range_requests?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "service_request_dashboard" */
export type Service_Request_Dashboard_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Request_Dashboard_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Request_Dashboard_Stream_Cursor_Value_Input = {
  account_id?: InputMaybe<Scalars['bpchar']['input']>;
  active_requests?: InputMaybe<Scalars['Int']['input']>;
  assigned_requests?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  farmer_happiness_green?: InputMaybe<Scalars['float8']['input']>;
  farmer_happiness_red?: InputMaybe<Scalars['float8']['input']>;
  farmer_happiness_yellow?: InputMaybe<Scalars['float8']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  live_field_partners?: InputMaybe<Scalars['Int']['input']>;
  missed_requests?: InputMaybe<Scalars['Int']['input']>;
  out_of_range_requests?: InputMaybe<Scalars['Int']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Service_Request_Dashboard_Sum_Fields = {
  __typename?: 'service_request_dashboard_sum_fields';
  active_requests?: Maybe<Scalars['Int']['output']>;
  assigned_requests?: Maybe<Scalars['Int']['output']>;
  farmer_happiness_green?: Maybe<Scalars['float8']['output']>;
  farmer_happiness_red?: Maybe<Scalars['float8']['output']>;
  farmer_happiness_yellow?: Maybe<Scalars['float8']['output']>;
  live_field_partners?: Maybe<Scalars['Int']['output']>;
  missed_requests?: Maybe<Scalars['Int']['output']>;
  out_of_range_requests?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "service_request_dashboard" */
export type Service_Request_Dashboard_Sum_Order_By = {
  active_requests?: InputMaybe<Order_By>;
  assigned_requests?: InputMaybe<Order_By>;
  farmer_happiness_green?: InputMaybe<Order_By>;
  farmer_happiness_red?: InputMaybe<Order_By>;
  farmer_happiness_yellow?: InputMaybe<Order_By>;
  live_field_partners?: InputMaybe<Order_By>;
  missed_requests?: InputMaybe<Order_By>;
  out_of_range_requests?: InputMaybe<Order_By>;
};

/** update columns of table "service_request_dashboard" */
export enum Service_Request_Dashboard_Update_Column {
  /** column name */
  AccountId = 'account_id',
  /** column name */
  ActiveRequests = 'active_requests',
  /** column name */
  AssignedRequests = 'assigned_requests',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  FarmerHappinessGreen = 'farmer_happiness_green',
  /** column name */
  FarmerHappinessRed = 'farmer_happiness_red',
  /** column name */
  FarmerHappinessYellow = 'farmer_happiness_yellow',
  /** column name */
  Id = 'id',
  /** column name */
  LiveFieldPartners = 'live_field_partners',
  /** column name */
  MissedRequests = 'missed_requests',
  /** column name */
  OutOfRangeRequests = 'out_of_range_requests',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Service_Request_Dashboard_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Service_Request_Dashboard_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Service_Request_Dashboard_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Request_Dashboard_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Service_Request_Dashboard_Var_Pop_Fields = {
  __typename?: 'service_request_dashboard_var_pop_fields';
  active_requests?: Maybe<Scalars['Float']['output']>;
  assigned_requests?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_green?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_red?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_yellow?: Maybe<Scalars['Float']['output']>;
  live_field_partners?: Maybe<Scalars['Float']['output']>;
  missed_requests?: Maybe<Scalars['Float']['output']>;
  out_of_range_requests?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_request_dashboard" */
export type Service_Request_Dashboard_Var_Pop_Order_By = {
  active_requests?: InputMaybe<Order_By>;
  assigned_requests?: InputMaybe<Order_By>;
  farmer_happiness_green?: InputMaybe<Order_By>;
  farmer_happiness_red?: InputMaybe<Order_By>;
  farmer_happiness_yellow?: InputMaybe<Order_By>;
  live_field_partners?: InputMaybe<Order_By>;
  missed_requests?: InputMaybe<Order_By>;
  out_of_range_requests?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Service_Request_Dashboard_Var_Samp_Fields = {
  __typename?: 'service_request_dashboard_var_samp_fields';
  active_requests?: Maybe<Scalars['Float']['output']>;
  assigned_requests?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_green?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_red?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_yellow?: Maybe<Scalars['Float']['output']>;
  live_field_partners?: Maybe<Scalars['Float']['output']>;
  missed_requests?: Maybe<Scalars['Float']['output']>;
  out_of_range_requests?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_request_dashboard" */
export type Service_Request_Dashboard_Var_Samp_Order_By = {
  active_requests?: InputMaybe<Order_By>;
  assigned_requests?: InputMaybe<Order_By>;
  farmer_happiness_green?: InputMaybe<Order_By>;
  farmer_happiness_red?: InputMaybe<Order_By>;
  farmer_happiness_yellow?: InputMaybe<Order_By>;
  live_field_partners?: InputMaybe<Order_By>;
  missed_requests?: InputMaybe<Order_By>;
  out_of_range_requests?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Service_Request_Dashboard_Variance_Fields = {
  __typename?: 'service_request_dashboard_variance_fields';
  active_requests?: Maybe<Scalars['Float']['output']>;
  assigned_requests?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_green?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_red?: Maybe<Scalars['Float']['output']>;
  farmer_happiness_yellow?: Maybe<Scalars['Float']['output']>;
  live_field_partners?: Maybe<Scalars['Float']['output']>;
  missed_requests?: Maybe<Scalars['Float']['output']>;
  out_of_range_requests?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_request_dashboard" */
export type Service_Request_Dashboard_Variance_Order_By = {
  active_requests?: InputMaybe<Order_By>;
  assigned_requests?: InputMaybe<Order_By>;
  farmer_happiness_green?: InputMaybe<Order_By>;
  farmer_happiness_red?: InputMaybe<Order_By>;
  farmer_happiness_yellow?: InputMaybe<Order_By>;
  live_field_partners?: InputMaybe<Order_By>;
  missed_requests?: InputMaybe<Order_By>;
  out_of_range_requests?: InputMaybe<Order_By>;
};

/** columns and relationships of "service_requests" */
export type Service_Requests = {
  __typename?: 'service_requests';
  address?: Maybe<Scalars['String']['output']>;
  address_components?: Maybe<Scalars['json']['output']>;
  assets: Scalars['jsonb']['output'];
  canceled_at?: Maybe<Scalars['timestamptz']['output']>;
  canceled_by?: Maybe<Scalars['bpchar']['output']>;
  channel?: Maybe<Scalars['String']['output']>;
  closed_at?: Maybe<Scalars['timestamptz']['output']>;
  closed_by?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop: Scalars['String']['output'];
  crop_id: Scalars['bpchar']['output'];
  date_of_sowing: Scalars['date']['output'];
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  external_source?: Maybe<Scalars['String']['output']>;
  farmer_user_id: Scalars['bpchar']['output'];
  /** An array relationship */
  field_partner_response_events: Array<Field_Partner_Response_Events>;
  /** An aggregate relationship */
  field_partner_response_events_aggregate: Field_Partner_Response_Events_Aggregate;
  /** An array relationship */
  field_partner_response_messages: Array<Field_Partner_Response_Messages>;
  /** An aggregate relationship */
  field_partner_response_messages_aggregate: Field_Partner_Response_Messages_Aggregate;
  /** An array relationship */
  field_partner_responses: Array<Field_Partner_Responses>;
  /** An aggregate relationship */
  field_partner_responses_aggregate: Field_Partner_Responses_Aggregate;
  /** An array relationship */
  field_scheduled_visits: Array<Field_Scheduled_Visits>;
  /** An aggregate relationship */
  field_scheduled_visits_aggregate: Field_Scheduled_Visits_Aggregate;
  id: Scalars['bpchar']['output'];
  is_recurring?: Maybe<Scalars['Boolean']['output']>;
  is_recurring_active?: Maybe<Scalars['Boolean']['output']>;
  is_request_closed: Scalars['Boolean']['output'];
  is_test?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  last_known_locations: Array<Last_Known_Locations>;
  /** An aggregate relationship */
  last_known_locations_aggregate: Last_Known_Locations_Aggregate;
  lat: Scalars['float8']['output'];
  lng: Scalars['float8']['output'];
  /** An object relationship */
  master_crop: Master_Crops;
  parent_request_id?: Maybe<Scalars['String']['output']>;
  recurring_schedule?: Maybe<Scalars['jsonb']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  /** An array relationship */
  user_feedbacks: Array<User_Feedbacks>;
  /** An aggregate relationship */
  user_feedbacks_aggregate: User_Feedbacks_Aggregate;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsAddress_ComponentsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsAssetsArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsField_Partner_Response_EventsArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsField_Partner_Response_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsField_Partner_Response_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsField_Partner_Response_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsField_Partner_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsField_Partner_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsField_Scheduled_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsField_Scheduled_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsLast_Known_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsLast_Known_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsRecurring_ScheduleArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsUser_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<User_Feedbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Feedbacks_Order_By>>;
  where?: InputMaybe<User_Feedbacks_Bool_Exp>;
};


/** columns and relationships of "service_requests" */
export type Service_RequestsUser_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Feedbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Feedbacks_Order_By>>;
  where?: InputMaybe<User_Feedbacks_Bool_Exp>;
};

/** aggregated selection of "service_requests" */
export type Service_Requests_Aggregate = {
  __typename?: 'service_requests_aggregate';
  aggregate?: Maybe<Service_Requests_Aggregate_Fields>;
  nodes: Array<Service_Requests>;
};

export type Service_Requests_Aggregate_Bool_Exp = {
  avg?: InputMaybe<Service_Requests_Aggregate_Bool_Exp_Avg>;
  bool_and?: InputMaybe<Service_Requests_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: InputMaybe<Service_Requests_Aggregate_Bool_Exp_Bool_Or>;
  corr?: InputMaybe<Service_Requests_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<Service_Requests_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<Service_Requests_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<Service_Requests_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<Service_Requests_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<Service_Requests_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<Service_Requests_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<Service_Requests_Aggregate_Bool_Exp_Var_Samp>;
};

export type Service_Requests_Aggregate_Bool_Exp_Avg = {
  arguments: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Requests_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Requests_Aggregate_Bool_Exp_Bool_And = {
  arguments: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Requests_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Service_Requests_Aggregate_Bool_Exp_Bool_Or = {
  arguments: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Requests_Bool_Exp>;
  predicate: Boolean_Comparison_Exp;
};

export type Service_Requests_Aggregate_Bool_Exp_Corr = {
  arguments: Service_Requests_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Requests_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Requests_Aggregate_Bool_Exp_Corr_Arguments = {
  X: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type Service_Requests_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Service_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Requests_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type Service_Requests_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: Service_Requests_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Requests_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Requests_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type Service_Requests_Aggregate_Bool_Exp_Max = {
  arguments: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Requests_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Requests_Aggregate_Bool_Exp_Min = {
  arguments: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Requests_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Requests_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Requests_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Requests_Aggregate_Bool_Exp_Sum = {
  arguments: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Requests_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type Service_Requests_Aggregate_Bool_Exp_Var_Samp = {
  arguments: Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Service_Requests_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "service_requests" */
export type Service_Requests_Aggregate_Fields = {
  __typename?: 'service_requests_aggregate_fields';
  avg?: Maybe<Service_Requests_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Service_Requests_Max_Fields>;
  min?: Maybe<Service_Requests_Min_Fields>;
  stddev?: Maybe<Service_Requests_Stddev_Fields>;
  stddev_pop?: Maybe<Service_Requests_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Service_Requests_Stddev_Samp_Fields>;
  sum?: Maybe<Service_Requests_Sum_Fields>;
  var_pop?: Maybe<Service_Requests_Var_Pop_Fields>;
  var_samp?: Maybe<Service_Requests_Var_Samp_Fields>;
  variance?: Maybe<Service_Requests_Variance_Fields>;
};


/** aggregate fields of "service_requests" */
export type Service_Requests_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Service_Requests_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "service_requests" */
export type Service_Requests_Aggregate_Order_By = {
  avg?: InputMaybe<Service_Requests_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Service_Requests_Max_Order_By>;
  min?: InputMaybe<Service_Requests_Min_Order_By>;
  stddev?: InputMaybe<Service_Requests_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Service_Requests_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Service_Requests_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Service_Requests_Sum_Order_By>;
  var_pop?: InputMaybe<Service_Requests_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Service_Requests_Var_Samp_Order_By>;
  variance?: InputMaybe<Service_Requests_Variance_Order_By>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Service_Requests_Append_Input = {
  assets?: InputMaybe<Scalars['jsonb']['input']>;
  recurring_schedule?: InputMaybe<Scalars['jsonb']['input']>;
};

/** input type for inserting array relation for remote table "service_requests" */
export type Service_Requests_Arr_Rel_Insert_Input = {
  data: Array<Service_Requests_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_Requests_On_Conflict>;
};

/** aggregate avg on columns */
export type Service_Requests_Avg_Fields = {
  __typename?: 'service_requests_avg_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "service_requests" */
export type Service_Requests_Avg_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "service_requests". All fields are combined with a logical 'AND'. */
export type Service_Requests_Bool_Exp = {
  _and?: InputMaybe<Array<Service_Requests_Bool_Exp>>;
  _not?: InputMaybe<Service_Requests_Bool_Exp>;
  _or?: InputMaybe<Array<Service_Requests_Bool_Exp>>;
  address?: InputMaybe<String_Comparison_Exp>;
  address_components?: InputMaybe<Json_Comparison_Exp>;
  assets?: InputMaybe<Jsonb_Comparison_Exp>;
  canceled_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  canceled_by?: InputMaybe<Bpchar_Comparison_Exp>;
  channel?: InputMaybe<String_Comparison_Exp>;
  closed_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  closed_by?: InputMaybe<Bpchar_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crop?: InputMaybe<String_Comparison_Exp>;
  crop_id?: InputMaybe<Bpchar_Comparison_Exp>;
  date_of_sowing?: InputMaybe<Date_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  external_id?: InputMaybe<String_Comparison_Exp>;
  external_source?: InputMaybe<String_Comparison_Exp>;
  farmer_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  field_partner_response_events?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
  field_partner_response_events_aggregate?: InputMaybe<Field_Partner_Response_Events_Aggregate_Bool_Exp>;
  field_partner_response_messages?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
  field_partner_response_messages_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Bool_Exp>;
  field_partner_responses?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
  field_partner_responses_aggregate?: InputMaybe<Field_Partner_Responses_Aggregate_Bool_Exp>;
  field_scheduled_visits?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
  field_scheduled_visits_aggregate?: InputMaybe<Field_Scheduled_Visits_Aggregate_Bool_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  is_recurring?: InputMaybe<Boolean_Comparison_Exp>;
  is_recurring_active?: InputMaybe<Boolean_Comparison_Exp>;
  is_request_closed?: InputMaybe<Boolean_Comparison_Exp>;
  is_test?: InputMaybe<Boolean_Comparison_Exp>;
  last_known_locations?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  last_known_locations_aggregate?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp>;
  lat?: InputMaybe<Float8_Comparison_Exp>;
  lng?: InputMaybe<Float8_Comparison_Exp>;
  master_crop?: InputMaybe<Master_Crops_Bool_Exp>;
  parent_request_id?: InputMaybe<String_Comparison_Exp>;
  recurring_schedule?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_feedbacks?: InputMaybe<User_Feedbacks_Bool_Exp>;
  user_feedbacks_aggregate?: InputMaybe<User_Feedbacks_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "service_requests" */
export enum Service_Requests_Constraint {
  /** unique or primary key constraint on columns "id" */
  ServiceRequestsPkey = 'service_requests_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Service_Requests_Delete_At_Path_Input = {
  assets?: InputMaybe<Array<Scalars['String']['input']>>;
  recurring_schedule?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Service_Requests_Delete_Elem_Input = {
  assets?: InputMaybe<Scalars['Int']['input']>;
  recurring_schedule?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Service_Requests_Delete_Key_Input = {
  assets?: InputMaybe<Scalars['String']['input']>;
  recurring_schedule?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "service_requests" */
export type Service_Requests_Inc_Input = {
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "service_requests" */
export type Service_Requests_Insert_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_components?: InputMaybe<Scalars['json']['input']>;
  assets?: InputMaybe<Scalars['jsonb']['input']>;
  canceled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  canceled_by?: InputMaybe<Scalars['bpchar']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  closed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  closed_by?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop?: InputMaybe<Scalars['String']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  date_of_sowing?: InputMaybe<Scalars['date']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  external_source?: InputMaybe<Scalars['String']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  field_partner_response_events?: InputMaybe<Field_Partner_Response_Events_Arr_Rel_Insert_Input>;
  field_partner_response_messages?: InputMaybe<Field_Partner_Response_Messages_Arr_Rel_Insert_Input>;
  field_partner_responses?: InputMaybe<Field_Partner_Responses_Arr_Rel_Insert_Input>;
  field_scheduled_visits?: InputMaybe<Field_Scheduled_Visits_Arr_Rel_Insert_Input>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_recurring?: InputMaybe<Scalars['Boolean']['input']>;
  is_recurring_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_request_closed?: InputMaybe<Scalars['Boolean']['input']>;
  is_test?: InputMaybe<Scalars['Boolean']['input']>;
  last_known_locations?: InputMaybe<Last_Known_Locations_Arr_Rel_Insert_Input>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  master_crop?: InputMaybe<Master_Crops_Obj_Rel_Insert_Input>;
  parent_request_id?: InputMaybe<Scalars['String']['input']>;
  recurring_schedule?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_feedbacks?: InputMaybe<User_Feedbacks_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Service_Requests_Max_Fields = {
  __typename?: 'service_requests_max_fields';
  address?: Maybe<Scalars['String']['output']>;
  canceled_at?: Maybe<Scalars['timestamptz']['output']>;
  canceled_by?: Maybe<Scalars['bpchar']['output']>;
  channel?: Maybe<Scalars['String']['output']>;
  closed_at?: Maybe<Scalars['timestamptz']['output']>;
  closed_by?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop?: Maybe<Scalars['String']['output']>;
  crop_id?: Maybe<Scalars['bpchar']['output']>;
  date_of_sowing?: Maybe<Scalars['date']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  external_source?: Maybe<Scalars['String']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  parent_request_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by max() on columns of table "service_requests" */
export type Service_Requests_Max_Order_By = {
  address?: InputMaybe<Order_By>;
  canceled_at?: InputMaybe<Order_By>;
  canceled_by?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  closed_at?: InputMaybe<Order_By>;
  closed_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crop?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  date_of_sowing?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  external_source?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  parent_request_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Service_Requests_Min_Fields = {
  __typename?: 'service_requests_min_fields';
  address?: Maybe<Scalars['String']['output']>;
  canceled_at?: Maybe<Scalars['timestamptz']['output']>;
  canceled_by?: Maybe<Scalars['bpchar']['output']>;
  channel?: Maybe<Scalars['String']['output']>;
  closed_at?: Maybe<Scalars['timestamptz']['output']>;
  closed_by?: Maybe<Scalars['bpchar']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  crop?: Maybe<Scalars['String']['output']>;
  crop_id?: Maybe<Scalars['bpchar']['output']>;
  date_of_sowing?: Maybe<Scalars['date']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  external_id?: Maybe<Scalars['String']['output']>;
  external_source?: Maybe<Scalars['String']['output']>;
  farmer_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  parent_request_id?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** order by min() on columns of table "service_requests" */
export type Service_Requests_Min_Order_By = {
  address?: InputMaybe<Order_By>;
  canceled_at?: InputMaybe<Order_By>;
  canceled_by?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  closed_at?: InputMaybe<Order_By>;
  closed_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crop?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  date_of_sowing?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  external_source?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  parent_request_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "service_requests" */
export type Service_Requests_Mutation_Response = {
  __typename?: 'service_requests_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Service_Requests>;
};

/** input type for inserting object relation for remote table "service_requests" */
export type Service_Requests_Obj_Rel_Insert_Input = {
  data: Service_Requests_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Service_Requests_On_Conflict>;
};

/** on_conflict condition type for table "service_requests" */
export type Service_Requests_On_Conflict = {
  constraint: Service_Requests_Constraint;
  update_columns?: Array<Service_Requests_Update_Column>;
  where?: InputMaybe<Service_Requests_Bool_Exp>;
};

/** Ordering options when selecting data from "service_requests". */
export type Service_Requests_Order_By = {
  address?: InputMaybe<Order_By>;
  address_components?: InputMaybe<Order_By>;
  assets?: InputMaybe<Order_By>;
  canceled_at?: InputMaybe<Order_By>;
  canceled_by?: InputMaybe<Order_By>;
  channel?: InputMaybe<Order_By>;
  closed_at?: InputMaybe<Order_By>;
  closed_by?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crop?: InputMaybe<Order_By>;
  crop_id?: InputMaybe<Order_By>;
  date_of_sowing?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  external_id?: InputMaybe<Order_By>;
  external_source?: InputMaybe<Order_By>;
  farmer_user_id?: InputMaybe<Order_By>;
  field_partner_response_events_aggregate?: InputMaybe<Field_Partner_Response_Events_Aggregate_Order_By>;
  field_partner_response_messages_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Order_By>;
  field_partner_responses_aggregate?: InputMaybe<Field_Partner_Responses_Aggregate_Order_By>;
  field_scheduled_visits_aggregate?: InputMaybe<Field_Scheduled_Visits_Aggregate_Order_By>;
  id?: InputMaybe<Order_By>;
  is_recurring?: InputMaybe<Order_By>;
  is_recurring_active?: InputMaybe<Order_By>;
  is_request_closed?: InputMaybe<Order_By>;
  is_test?: InputMaybe<Order_By>;
  last_known_locations_aggregate?: InputMaybe<Last_Known_Locations_Aggregate_Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  master_crop?: InputMaybe<Master_Crops_Order_By>;
  parent_request_id?: InputMaybe<Order_By>;
  recurring_schedule?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_feedbacks_aggregate?: InputMaybe<User_Feedbacks_Aggregate_Order_By>;
};

/** primary key columns input for table: service_requests */
export type Service_Requests_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Service_Requests_Prepend_Input = {
  assets?: InputMaybe<Scalars['jsonb']['input']>;
  recurring_schedule?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "service_requests" */
export enum Service_Requests_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressComponents = 'address_components',
  /** column name */
  Assets = 'assets',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CanceledBy = 'canceled_by',
  /** column name */
  Channel = 'channel',
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  ClosedBy = 'closed_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Crop = 'crop',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  DateOfSowing = 'date_of_sowing',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsRecurring = 'is_recurring',
  /** column name */
  IsRecurringActive = 'is_recurring_active',
  /** column name */
  IsRequestClosed = 'is_request_closed',
  /** column name */
  IsTest = 'is_test',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  ParentRequestId = 'parent_request_id',
  /** column name */
  RecurringSchedule = 'recurring_schedule',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** select "service_requests_aggregate_bool_exp_avg_arguments_columns" columns of table "service_requests" */
export enum Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "service_requests_aggregate_bool_exp_bool_and_arguments_columns" columns of table "service_requests" */
export enum Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsRecurring = 'is_recurring',
  /** column name */
  IsRecurringActive = 'is_recurring_active',
  /** column name */
  IsRequestClosed = 'is_request_closed',
  /** column name */
  IsTest = 'is_test'
}

/** select "service_requests_aggregate_bool_exp_bool_or_arguments_columns" columns of table "service_requests" */
export enum Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsRecurring = 'is_recurring',
  /** column name */
  IsRecurringActive = 'is_recurring_active',
  /** column name */
  IsRequestClosed = 'is_request_closed',
  /** column name */
  IsTest = 'is_test'
}

/** select "service_requests_aggregate_bool_exp_corr_arguments_columns" columns of table "service_requests" */
export enum Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "service_requests_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "service_requests" */
export enum Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "service_requests_aggregate_bool_exp_max_arguments_columns" columns of table "service_requests" */
export enum Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "service_requests_aggregate_bool_exp_min_arguments_columns" columns of table "service_requests" */
export enum Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "service_requests_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "service_requests" */
export enum Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "service_requests_aggregate_bool_exp_sum_arguments_columns" columns of table "service_requests" */
export enum Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "service_requests_aggregate_bool_exp_var_samp_arguments_columns" columns of table "service_requests" */
export enum Service_Requests_Select_Column_Service_Requests_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** input type for updating data in table "service_requests" */
export type Service_Requests_Set_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_components?: InputMaybe<Scalars['json']['input']>;
  assets?: InputMaybe<Scalars['jsonb']['input']>;
  canceled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  canceled_by?: InputMaybe<Scalars['bpchar']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  closed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  closed_by?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop?: InputMaybe<Scalars['String']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  date_of_sowing?: InputMaybe<Scalars['date']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  external_source?: InputMaybe<Scalars['String']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_recurring?: InputMaybe<Scalars['Boolean']['input']>;
  is_recurring_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_request_closed?: InputMaybe<Scalars['Boolean']['input']>;
  is_test?: InputMaybe<Scalars['Boolean']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  parent_request_id?: InputMaybe<Scalars['String']['input']>;
  recurring_schedule?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Service_Requests_Stddev_Fields = {
  __typename?: 'service_requests_stddev_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "service_requests" */
export type Service_Requests_Stddev_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Service_Requests_Stddev_Pop_Fields = {
  __typename?: 'service_requests_stddev_pop_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "service_requests" */
export type Service_Requests_Stddev_Pop_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Service_Requests_Stddev_Samp_Fields = {
  __typename?: 'service_requests_stddev_samp_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "service_requests" */
export type Service_Requests_Stddev_Samp_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "service_requests" */
export type Service_Requests_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Service_Requests_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Service_Requests_Stream_Cursor_Value_Input = {
  address?: InputMaybe<Scalars['String']['input']>;
  address_components?: InputMaybe<Scalars['json']['input']>;
  assets?: InputMaybe<Scalars['jsonb']['input']>;
  canceled_at?: InputMaybe<Scalars['timestamptz']['input']>;
  canceled_by?: InputMaybe<Scalars['bpchar']['input']>;
  channel?: InputMaybe<Scalars['String']['input']>;
  closed_at?: InputMaybe<Scalars['timestamptz']['input']>;
  closed_by?: InputMaybe<Scalars['bpchar']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop?: InputMaybe<Scalars['String']['input']>;
  crop_id?: InputMaybe<Scalars['bpchar']['input']>;
  date_of_sowing?: InputMaybe<Scalars['date']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  external_id?: InputMaybe<Scalars['String']['input']>;
  external_source?: InputMaybe<Scalars['String']['input']>;
  farmer_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_recurring?: InputMaybe<Scalars['Boolean']['input']>;
  is_recurring_active?: InputMaybe<Scalars['Boolean']['input']>;
  is_request_closed?: InputMaybe<Scalars['Boolean']['input']>;
  is_test?: InputMaybe<Scalars['Boolean']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  parent_request_id?: InputMaybe<Scalars['String']['input']>;
  recurring_schedule?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Service_Requests_Sum_Fields = {
  __typename?: 'service_requests_sum_fields';
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "service_requests" */
export type Service_Requests_Sum_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** update columns of table "service_requests" */
export enum Service_Requests_Update_Column {
  /** column name */
  Address = 'address',
  /** column name */
  AddressComponents = 'address_components',
  /** column name */
  Assets = 'assets',
  /** column name */
  CanceledAt = 'canceled_at',
  /** column name */
  CanceledBy = 'canceled_by',
  /** column name */
  Channel = 'channel',
  /** column name */
  ClosedAt = 'closed_at',
  /** column name */
  ClosedBy = 'closed_by',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Crop = 'crop',
  /** column name */
  CropId = 'crop_id',
  /** column name */
  DateOfSowing = 'date_of_sowing',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  ExternalId = 'external_id',
  /** column name */
  ExternalSource = 'external_source',
  /** column name */
  FarmerUserId = 'farmer_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  IsRecurring = 'is_recurring',
  /** column name */
  IsRecurringActive = 'is_recurring_active',
  /** column name */
  IsRequestClosed = 'is_request_closed',
  /** column name */
  IsTest = 'is_test',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  ParentRequestId = 'parent_request_id',
  /** column name */
  RecurringSchedule = 'recurring_schedule',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Service_Requests_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Service_Requests_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Service_Requests_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Service_Requests_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Service_Requests_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Service_Requests_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Service_Requests_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Service_Requests_Set_Input>;
  /** filter the rows which have to be updated */
  where: Service_Requests_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Service_Requests_Var_Pop_Fields = {
  __typename?: 'service_requests_var_pop_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "service_requests" */
export type Service_Requests_Var_Pop_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Service_Requests_Var_Samp_Fields = {
  __typename?: 'service_requests_var_samp_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "service_requests" */
export type Service_Requests_Var_Samp_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Service_Requests_Variance_Fields = {
  __typename?: 'service_requests_variance_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "service_requests" */
export type Service_Requests_Variance_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** Boolean expression to compare columns of type "smallint". All fields are combined with logical 'AND'. */
export type Smallint_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['smallint']['input']>;
  _gt?: InputMaybe<Scalars['smallint']['input']>;
  _gte?: InputMaybe<Scalars['smallint']['input']>;
  _in?: InputMaybe<Array<Scalars['smallint']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['smallint']['input']>;
  _lte?: InputMaybe<Scalars['smallint']['input']>;
  _neq?: InputMaybe<Scalars['smallint']['input']>;
  _nin?: InputMaybe<Array<Scalars['smallint']['input']>>;
};

/** columns and relationships of "snapshots" */
export type Snapshots = {
  __typename?: 'snapshots';
  aggregate_uuid: Scalars['bpchar']['output'];
  aggregate_version: Scalars['Int']['output'];
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  state: Scalars['jsonb']['output'];
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "snapshots" */
export type SnapshotsStateArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "snapshots" */
export type Snapshots_Aggregate = {
  __typename?: 'snapshots_aggregate';
  aggregate?: Maybe<Snapshots_Aggregate_Fields>;
  nodes: Array<Snapshots>;
};

/** aggregate fields of "snapshots" */
export type Snapshots_Aggregate_Fields = {
  __typename?: 'snapshots_aggregate_fields';
  avg?: Maybe<Snapshots_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Snapshots_Max_Fields>;
  min?: Maybe<Snapshots_Min_Fields>;
  stddev?: Maybe<Snapshots_Stddev_Fields>;
  stddev_pop?: Maybe<Snapshots_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Snapshots_Stddev_Samp_Fields>;
  sum?: Maybe<Snapshots_Sum_Fields>;
  var_pop?: Maybe<Snapshots_Var_Pop_Fields>;
  var_samp?: Maybe<Snapshots_Var_Samp_Fields>;
  variance?: Maybe<Snapshots_Variance_Fields>;
};


/** aggregate fields of "snapshots" */
export type Snapshots_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Snapshots_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Snapshots_Append_Input = {
  state?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Snapshots_Avg_Fields = {
  __typename?: 'snapshots_avg_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "snapshots". All fields are combined with a logical 'AND'. */
export type Snapshots_Bool_Exp = {
  _and?: InputMaybe<Array<Snapshots_Bool_Exp>>;
  _not?: InputMaybe<Snapshots_Bool_Exp>;
  _or?: InputMaybe<Array<Snapshots_Bool_Exp>>;
  aggregate_uuid?: InputMaybe<Bpchar_Comparison_Exp>;
  aggregate_version?: InputMaybe<Int_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  state?: InputMaybe<Jsonb_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "snapshots" */
export enum Snapshots_Constraint {
  /** unique or primary key constraint on columns "id" */
  SnapshotsPkey = 'snapshots_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Snapshots_Delete_At_Path_Input = {
  state?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Snapshots_Delete_Elem_Input = {
  state?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Snapshots_Delete_Key_Input = {
  state?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "snapshots" */
export type Snapshots_Inc_Input = {
  aggregate_version?: InputMaybe<Scalars['Int']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "snapshots" */
export type Snapshots_Insert_Input = {
  aggregate_uuid?: InputMaybe<Scalars['bpchar']['input']>;
  aggregate_version?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  state?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Snapshots_Max_Fields = {
  __typename?: 'snapshots_max_fields';
  aggregate_uuid?: Maybe<Scalars['bpchar']['output']>;
  aggregate_version?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Snapshots_Min_Fields = {
  __typename?: 'snapshots_min_fields';
  aggregate_uuid?: Maybe<Scalars['bpchar']['output']>;
  aggregate_version?: Maybe<Scalars['Int']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "snapshots" */
export type Snapshots_Mutation_Response = {
  __typename?: 'snapshots_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Snapshots>;
};

/** on_conflict condition type for table "snapshots" */
export type Snapshots_On_Conflict = {
  constraint: Snapshots_Constraint;
  update_columns?: Array<Snapshots_Update_Column>;
  where?: InputMaybe<Snapshots_Bool_Exp>;
};

/** Ordering options when selecting data from "snapshots". */
export type Snapshots_Order_By = {
  aggregate_uuid?: InputMaybe<Order_By>;
  aggregate_version?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  state?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: snapshots */
export type Snapshots_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Snapshots_Prepend_Input = {
  state?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "snapshots" */
export enum Snapshots_Select_Column {
  /** column name */
  AggregateUuid = 'aggregate_uuid',
  /** column name */
  AggregateVersion = 'aggregate_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "snapshots" */
export type Snapshots_Set_Input = {
  aggregate_uuid?: InputMaybe<Scalars['bpchar']['input']>;
  aggregate_version?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  state?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Snapshots_Stddev_Fields = {
  __typename?: 'snapshots_stddev_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Snapshots_Stddev_Pop_Fields = {
  __typename?: 'snapshots_stddev_pop_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Snapshots_Stddev_Samp_Fields = {
  __typename?: 'snapshots_stddev_samp_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "snapshots" */
export type Snapshots_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Snapshots_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Snapshots_Stream_Cursor_Value_Input = {
  aggregate_uuid?: InputMaybe<Scalars['bpchar']['input']>;
  aggregate_version?: InputMaybe<Scalars['Int']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  state?: InputMaybe<Scalars['jsonb']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Snapshots_Sum_Fields = {
  __typename?: 'snapshots_sum_fields';
  aggregate_version?: Maybe<Scalars['Int']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "snapshots" */
export enum Snapshots_Update_Column {
  /** column name */
  AggregateUuid = 'aggregate_uuid',
  /** column name */
  AggregateVersion = 'aggregate_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  State = 'state',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Snapshots_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Snapshots_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Snapshots_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Snapshots_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Snapshots_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Snapshots_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Snapshots_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Snapshots_Set_Input>;
  /** filter the rows which have to be updated */
  where: Snapshots_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Snapshots_Var_Pop_Fields = {
  __typename?: 'snapshots_var_pop_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Snapshots_Var_Samp_Fields = {
  __typename?: 'snapshots_var_samp_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Snapshots_Variance_Fields = {
  __typename?: 'snapshots_variance_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "spatial_ref_sys" */
export type Spatial_Ref_Sys = {
  __typename?: 'spatial_ref_sys';
  auth_name?: Maybe<Scalars['String']['output']>;
  auth_srid?: Maybe<Scalars['Int']['output']>;
  proj4text?: Maybe<Scalars['String']['output']>;
  srid: Scalars['Int']['output'];
  srtext?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate = {
  __typename?: 'spatial_ref_sys_aggregate';
  aggregate?: Maybe<Spatial_Ref_Sys_Aggregate_Fields>;
  nodes: Array<Spatial_Ref_Sys>;
};

/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_Fields = {
  __typename?: 'spatial_ref_sys_aggregate_fields';
  avg?: Maybe<Spatial_Ref_Sys_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Spatial_Ref_Sys_Max_Fields>;
  min?: Maybe<Spatial_Ref_Sys_Min_Fields>;
  stddev?: Maybe<Spatial_Ref_Sys_Stddev_Fields>;
  stddev_pop?: Maybe<Spatial_Ref_Sys_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Spatial_Ref_Sys_Stddev_Samp_Fields>;
  sum?: Maybe<Spatial_Ref_Sys_Sum_Fields>;
  var_pop?: Maybe<Spatial_Ref_Sys_Var_Pop_Fields>;
  var_samp?: Maybe<Spatial_Ref_Sys_Var_Samp_Fields>;
  variance?: Maybe<Spatial_Ref_Sys_Variance_Fields>;
};


/** aggregate fields of "spatial_ref_sys" */
export type Spatial_Ref_Sys_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Spatial_Ref_Sys_Avg_Fields = {
  __typename?: 'spatial_ref_sys_avg_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "spatial_ref_sys". All fields are combined with a logical 'AND'. */
export type Spatial_Ref_Sys_Bool_Exp = {
  _and?: InputMaybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  _not?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
  _or?: InputMaybe<Array<Spatial_Ref_Sys_Bool_Exp>>;
  auth_name?: InputMaybe<String_Comparison_Exp>;
  auth_srid?: InputMaybe<Int_Comparison_Exp>;
  proj4text?: InputMaybe<String_Comparison_Exp>;
  srid?: InputMaybe<Int_Comparison_Exp>;
  srtext?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Constraint {
  /** unique or primary key constraint on columns "srid" */
  SpatialRefSysPkey = 'spatial_ref_sys_pkey'
}

/** input type for incrementing numeric columns in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Inc_Input = {
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Insert_Input = {
  auth_name?: InputMaybe<Scalars['String']['input']>;
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  proj4text?: InputMaybe<Scalars['String']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  srtext?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Spatial_Ref_Sys_Max_Fields = {
  __typename?: 'spatial_ref_sys_max_fields';
  auth_name?: Maybe<Scalars['String']['output']>;
  auth_srid?: Maybe<Scalars['Int']['output']>;
  proj4text?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  srtext?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Spatial_Ref_Sys_Min_Fields = {
  __typename?: 'spatial_ref_sys_min_fields';
  auth_name?: Maybe<Scalars['String']['output']>;
  auth_srid?: Maybe<Scalars['Int']['output']>;
  proj4text?: Maybe<Scalars['String']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
  srtext?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Mutation_Response = {
  __typename?: 'spatial_ref_sys_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Spatial_Ref_Sys>;
};

/** on_conflict condition type for table "spatial_ref_sys" */
export type Spatial_Ref_Sys_On_Conflict = {
  constraint: Spatial_Ref_Sys_Constraint;
  update_columns?: Array<Spatial_Ref_Sys_Update_Column>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};

/** Ordering options when selecting data from "spatial_ref_sys". */
export type Spatial_Ref_Sys_Order_By = {
  auth_name?: InputMaybe<Order_By>;
  auth_srid?: InputMaybe<Order_By>;
  proj4text?: InputMaybe<Order_By>;
  srid?: InputMaybe<Order_By>;
  srtext?: InputMaybe<Order_By>;
};

/** primary key columns input for table: spatial_ref_sys */
export type Spatial_Ref_Sys_Pk_Columns_Input = {
  srid: Scalars['Int']['input'];
};

/** select columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Select_Column {
  /** column name */
  AuthName = 'auth_name',
  /** column name */
  AuthSrid = 'auth_srid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

/** input type for updating data in table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Set_Input = {
  auth_name?: InputMaybe<Scalars['String']['input']>;
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  proj4text?: InputMaybe<Scalars['String']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  srtext?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Spatial_Ref_Sys_Stddev_Fields = {
  __typename?: 'spatial_ref_sys_stddev_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Spatial_Ref_Sys_Stddev_Pop_Fields = {
  __typename?: 'spatial_ref_sys_stddev_pop_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Spatial_Ref_Sys_Stddev_Samp_Fields = {
  __typename?: 'spatial_ref_sys_stddev_samp_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "spatial_ref_sys" */
export type Spatial_Ref_Sys_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Spatial_Ref_Sys_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Spatial_Ref_Sys_Stream_Cursor_Value_Input = {
  auth_name?: InputMaybe<Scalars['String']['input']>;
  auth_srid?: InputMaybe<Scalars['Int']['input']>;
  proj4text?: InputMaybe<Scalars['String']['input']>;
  srid?: InputMaybe<Scalars['Int']['input']>;
  srtext?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Spatial_Ref_Sys_Sum_Fields = {
  __typename?: 'spatial_ref_sys_sum_fields';
  auth_srid?: Maybe<Scalars['Int']['output']>;
  srid?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "spatial_ref_sys" */
export enum Spatial_Ref_Sys_Update_Column {
  /** column name */
  AuthName = 'auth_name',
  /** column name */
  AuthSrid = 'auth_srid',
  /** column name */
  Proj4text = 'proj4text',
  /** column name */
  Srid = 'srid',
  /** column name */
  Srtext = 'srtext'
}

export type Spatial_Ref_Sys_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Spatial_Ref_Sys_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Spatial_Ref_Sys_Set_Input>;
  /** filter the rows which have to be updated */
  where: Spatial_Ref_Sys_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Spatial_Ref_Sys_Var_Pop_Fields = {
  __typename?: 'spatial_ref_sys_var_pop_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Spatial_Ref_Sys_Var_Samp_Fields = {
  __typename?: 'spatial_ref_sys_var_samp_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Spatial_Ref_Sys_Variance_Fields = {
  __typename?: 'spatial_ref_sys_variance_fields';
  auth_srid?: Maybe<Scalars['Float']['output']>;
  srid?: Maybe<Scalars['Float']['output']>;
};

export type St_D_Within_Geography_Input = {
  distance: Scalars['Float']['input'];
  from: Scalars['geography']['input'];
  use_spheroid?: InputMaybe<Scalars['Boolean']['input']>;
};

export type St_D_Within_Input = {
  distance: Scalars['Float']['input'];
  from: Scalars['geometry']['input'];
};

/** columns and relationships of "stored_events" */
export type Stored_Events = {
  __typename?: 'stored_events';
  aggregate_uuid?: Maybe<Scalars['bpchar']['output']>;
  aggregate_version?: Maybe<Scalars['bigint']['output']>;
  created_at: Scalars['timestamp']['output'];
  event_class: Scalars['String']['output'];
  event_properties: Scalars['jsonb']['output'];
  event_version: Scalars['smallint']['output'];
  id: Scalars['bigint']['output'];
  meta_data: Scalars['jsonb']['output'];
};


/** columns and relationships of "stored_events" */
export type Stored_EventsEvent_PropertiesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "stored_events" */
export type Stored_EventsMeta_DataArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};

/** aggregated selection of "stored_events" */
export type Stored_Events_Aggregate = {
  __typename?: 'stored_events_aggregate';
  aggregate?: Maybe<Stored_Events_Aggregate_Fields>;
  nodes: Array<Stored_Events>;
};

/** aggregate fields of "stored_events" */
export type Stored_Events_Aggregate_Fields = {
  __typename?: 'stored_events_aggregate_fields';
  avg?: Maybe<Stored_Events_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Stored_Events_Max_Fields>;
  min?: Maybe<Stored_Events_Min_Fields>;
  stddev?: Maybe<Stored_Events_Stddev_Fields>;
  stddev_pop?: Maybe<Stored_Events_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Stored_Events_Stddev_Samp_Fields>;
  sum?: Maybe<Stored_Events_Sum_Fields>;
  var_pop?: Maybe<Stored_Events_Var_Pop_Fields>;
  var_samp?: Maybe<Stored_Events_Var_Samp_Fields>;
  variance?: Maybe<Stored_Events_Variance_Fields>;
};


/** aggregate fields of "stored_events" */
export type Stored_Events_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Stored_Events_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** append existing jsonb value of filtered columns with new jsonb value */
export type Stored_Events_Append_Input = {
  event_properties?: InputMaybe<Scalars['jsonb']['input']>;
  meta_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate avg on columns */
export type Stored_Events_Avg_Fields = {
  __typename?: 'stored_events_avg_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  event_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "stored_events". All fields are combined with a logical 'AND'. */
export type Stored_Events_Bool_Exp = {
  _and?: InputMaybe<Array<Stored_Events_Bool_Exp>>;
  _not?: InputMaybe<Stored_Events_Bool_Exp>;
  _or?: InputMaybe<Array<Stored_Events_Bool_Exp>>;
  aggregate_uuid?: InputMaybe<Bpchar_Comparison_Exp>;
  aggregate_version?: InputMaybe<Bigint_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  event_class?: InputMaybe<String_Comparison_Exp>;
  event_properties?: InputMaybe<Jsonb_Comparison_Exp>;
  event_version?: InputMaybe<Smallint_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  meta_data?: InputMaybe<Jsonb_Comparison_Exp>;
};

/** unique or primary key constraints on table "stored_events" */
export enum Stored_Events_Constraint {
  /** unique or primary key constraint on columns "aggregate_uuid", "aggregate_version" */
  StoredEventsAggregateUuidAggregateVersionUnique = 'stored_events_aggregate_uuid_aggregate_version_unique',
  /** unique or primary key constraint on columns "id" */
  StoredEventsPkey = 'stored_events_pkey'
}

/** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
export type Stored_Events_Delete_At_Path_Input = {
  event_properties?: InputMaybe<Array<Scalars['String']['input']>>;
  meta_data?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
export type Stored_Events_Delete_Elem_Input = {
  event_properties?: InputMaybe<Scalars['Int']['input']>;
  meta_data?: InputMaybe<Scalars['Int']['input']>;
};

/** delete key/value pair or string element. key/value pairs are matched based on their key value */
export type Stored_Events_Delete_Key_Input = {
  event_properties?: InputMaybe<Scalars['String']['input']>;
  meta_data?: InputMaybe<Scalars['String']['input']>;
};

/** input type for incrementing numeric columns in table "stored_events" */
export type Stored_Events_Inc_Input = {
  aggregate_version?: InputMaybe<Scalars['bigint']['input']>;
  event_version?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "stored_events" */
export type Stored_Events_Insert_Input = {
  aggregate_uuid?: InputMaybe<Scalars['bpchar']['input']>;
  aggregate_version?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  event_class?: InputMaybe<Scalars['String']['input']>;
  event_properties?: InputMaybe<Scalars['jsonb']['input']>;
  event_version?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  meta_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate max on columns */
export type Stored_Events_Max_Fields = {
  __typename?: 'stored_events_max_fields';
  aggregate_uuid?: Maybe<Scalars['bpchar']['output']>;
  aggregate_version?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  event_class?: Maybe<Scalars['String']['output']>;
  event_version?: Maybe<Scalars['smallint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** aggregate min on columns */
export type Stored_Events_Min_Fields = {
  __typename?: 'stored_events_min_fields';
  aggregate_uuid?: Maybe<Scalars['bpchar']['output']>;
  aggregate_version?: Maybe<Scalars['bigint']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  event_class?: Maybe<Scalars['String']['output']>;
  event_version?: Maybe<Scalars['smallint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** response of any mutation on the table "stored_events" */
export type Stored_Events_Mutation_Response = {
  __typename?: 'stored_events_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Stored_Events>;
};

/** on_conflict condition type for table "stored_events" */
export type Stored_Events_On_Conflict = {
  constraint: Stored_Events_Constraint;
  update_columns?: Array<Stored_Events_Update_Column>;
  where?: InputMaybe<Stored_Events_Bool_Exp>;
};

/** Ordering options when selecting data from "stored_events". */
export type Stored_Events_Order_By = {
  aggregate_uuid?: InputMaybe<Order_By>;
  aggregate_version?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  event_class?: InputMaybe<Order_By>;
  event_properties?: InputMaybe<Order_By>;
  event_version?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  meta_data?: InputMaybe<Order_By>;
};

/** primary key columns input for table: stored_events */
export type Stored_Events_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** prepend existing jsonb value of filtered columns with new jsonb value */
export type Stored_Events_Prepend_Input = {
  event_properties?: InputMaybe<Scalars['jsonb']['input']>;
  meta_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** select columns of table "stored_events" */
export enum Stored_Events_Select_Column {
  /** column name */
  AggregateUuid = 'aggregate_uuid',
  /** column name */
  AggregateVersion = 'aggregate_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventClass = 'event_class',
  /** column name */
  EventProperties = 'event_properties',
  /** column name */
  EventVersion = 'event_version',
  /** column name */
  Id = 'id',
  /** column name */
  MetaData = 'meta_data'
}

/** input type for updating data in table "stored_events" */
export type Stored_Events_Set_Input = {
  aggregate_uuid?: InputMaybe<Scalars['bpchar']['input']>;
  aggregate_version?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  event_class?: InputMaybe<Scalars['String']['input']>;
  event_properties?: InputMaybe<Scalars['jsonb']['input']>;
  event_version?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  meta_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate stddev on columns */
export type Stored_Events_Stddev_Fields = {
  __typename?: 'stored_events_stddev_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  event_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Stored_Events_Stddev_Pop_Fields = {
  __typename?: 'stored_events_stddev_pop_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  event_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Stored_Events_Stddev_Samp_Fields = {
  __typename?: 'stored_events_stddev_samp_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  event_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "stored_events" */
export type Stored_Events_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Stored_Events_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Stored_Events_Stream_Cursor_Value_Input = {
  aggregate_uuid?: InputMaybe<Scalars['bpchar']['input']>;
  aggregate_version?: InputMaybe<Scalars['bigint']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  event_class?: InputMaybe<Scalars['String']['input']>;
  event_properties?: InputMaybe<Scalars['jsonb']['input']>;
  event_version?: InputMaybe<Scalars['smallint']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  meta_data?: InputMaybe<Scalars['jsonb']['input']>;
};

/** aggregate sum on columns */
export type Stored_Events_Sum_Fields = {
  __typename?: 'stored_events_sum_fields';
  aggregate_version?: Maybe<Scalars['bigint']['output']>;
  event_version?: Maybe<Scalars['smallint']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "stored_events" */
export enum Stored_Events_Update_Column {
  /** column name */
  AggregateUuid = 'aggregate_uuid',
  /** column name */
  AggregateVersion = 'aggregate_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  EventClass = 'event_class',
  /** column name */
  EventProperties = 'event_properties',
  /** column name */
  EventVersion = 'event_version',
  /** column name */
  Id = 'id',
  /** column name */
  MetaData = 'meta_data'
}

export type Stored_Events_Updates = {
  /** append existing jsonb value of filtered columns with new jsonb value */
  _append?: InputMaybe<Stored_Events_Append_Input>;
  /** delete the field or element with specified path (for JSON arrays, negative integers count from the end) */
  _delete_at_path?: InputMaybe<Stored_Events_Delete_At_Path_Input>;
  /** delete the array element with specified index (negative integers count from the end). throws an error if top level container is not an array */
  _delete_elem?: InputMaybe<Stored_Events_Delete_Elem_Input>;
  /** delete key/value pair or string element. key/value pairs are matched based on their key value */
  _delete_key?: InputMaybe<Stored_Events_Delete_Key_Input>;
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Stored_Events_Inc_Input>;
  /** prepend existing jsonb value of filtered columns with new jsonb value */
  _prepend?: InputMaybe<Stored_Events_Prepend_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Stored_Events_Set_Input>;
  /** filter the rows which have to be updated */
  where: Stored_Events_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Stored_Events_Var_Pop_Fields = {
  __typename?: 'stored_events_var_pop_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  event_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Stored_Events_Var_Samp_Fields = {
  __typename?: 'stored_events_var_samp_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  event_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Stored_Events_Variance_Fields = {
  __typename?: 'stored_events_variance_fields';
  aggregate_version?: Maybe<Scalars['Float']['output']>;
  event_version?: Maybe<Scalars['Float']['output']>;
  id?: Maybe<Scalars['Float']['output']>;
};

export type Subscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "account_crop" */
  account_crop: Array<Account_Crop>;
  /** fetch aggregated fields from the table: "account_crop" */
  account_crop_aggregate: Account_Crop_Aggregate;
  /** fetch data from the table: "account_crop" using primary key columns */
  account_crop_by_pk?: Maybe<Account_Crop>;
  /** fetch data from the table in a streaming manner: "account_crop" */
  account_crop_stream: Array<Account_Crop>;
  /** fetch data from the table: "account_segment" */
  account_segment: Array<Account_Segment>;
  /** fetch aggregated fields from the table: "account_segment" */
  account_segment_aggregate: Account_Segment_Aggregate;
  /** fetch data from the table: "account_segment" using primary key columns */
  account_segment_by_pk?: Maybe<Account_Segment>;
  /** fetch data from the table in a streaming manner: "account_segment" */
  account_segment_stream: Array<Account_Segment>;
  /** fetch data from the table: "account_user" */
  account_user: Array<Account_User>;
  /** fetch aggregated fields from the table: "account_user" */
  account_user_aggregate: Account_User_Aggregate;
  /** fetch data from the table: "account_user" using primary key columns */
  account_user_by_pk?: Maybe<Account_User>;
  /** fetch data from the table in a streaming manner: "account_user" */
  account_user_stream: Array<Account_User>;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** fetch data from the table: "accounts" using primary key columns */
  accounts_by_pk?: Maybe<Accounts>;
  /** fetch data from the table in a streaming manner: "accounts" */
  accounts_stream: Array<Accounts>;
  /** fetch data from the table: "action_events" */
  action_events: Array<Action_Events>;
  /** fetch aggregated fields from the table: "action_events" */
  action_events_aggregate: Action_Events_Aggregate;
  /** fetch data from the table: "action_events" using primary key columns */
  action_events_by_pk?: Maybe<Action_Events>;
  /** fetch data from the table in a streaming manner: "action_events" */
  action_events_stream: Array<Action_Events>;
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregate relationship */
  companies_aggregate: Companies_Aggregate;
  /** fetch data from the table: "companies" using primary key columns */
  companies_by_pk?: Maybe<Companies>;
  /** fetch data from the table in a streaming manner: "companies" */
  companies_stream: Array<Companies>;
  /** fetch data from the table: "crop_user" */
  crop_user: Array<Crop_User>;
  /** fetch aggregated fields from the table: "crop_user" */
  crop_user_aggregate: Crop_User_Aggregate;
  /** fetch data from the table: "crop_user" using primary key columns */
  crop_user_by_pk?: Maybe<Crop_User>;
  /** fetch data from the table in a streaming manner: "crop_user" */
  crop_user_stream: Array<Crop_User>;
  /** fetch data from the table: "failed_jobs" */
  failed_jobs: Array<Failed_Jobs>;
  /** fetch aggregated fields from the table: "failed_jobs" */
  failed_jobs_aggregate: Failed_Jobs_Aggregate;
  /** fetch data from the table: "failed_jobs" using primary key columns */
  failed_jobs_by_pk?: Maybe<Failed_Jobs>;
  /** fetch data from the table in a streaming manner: "failed_jobs" */
  failed_jobs_stream: Array<Failed_Jobs>;
  /** fetch data from the table: "farmer_nearby_field_force" */
  farmer_nearby_field_force: Array<Farmer_Nearby_Field_Force>;
  /** fetch aggregated fields from the table: "farmer_nearby_field_force" */
  farmer_nearby_field_force_aggregate: Farmer_Nearby_Field_Force_Aggregate;
  /** fetch data from the table: "farmer_nearby_field_force" using primary key columns */
  farmer_nearby_field_force_by_pk?: Maybe<Farmer_Nearby_Field_Force>;
  /** fetch data from the table in a streaming manner: "farmer_nearby_field_force" */
  farmer_nearby_field_force_stream: Array<Farmer_Nearby_Field_Force>;
  /** An array relationship */
  farmer_plots: Array<Farmer_Plots>;
  /** An aggregate relationship */
  farmer_plots_aggregate: Farmer_Plots_Aggregate;
  /** fetch data from the table: "farmer_plots" using primary key columns */
  farmer_plots_by_pk?: Maybe<Farmer_Plots>;
  /** fetch data from the table in a streaming manner: "farmer_plots" */
  farmer_plots_stream: Array<Farmer_Plots>;
  /** An array relationship */
  field_partner_response_events: Array<Field_Partner_Response_Events>;
  /** An aggregate relationship */
  field_partner_response_events_aggregate: Field_Partner_Response_Events_Aggregate;
  /** fetch data from the table: "field_partner_response_events" using primary key columns */
  field_partner_response_events_by_pk?: Maybe<Field_Partner_Response_Events>;
  /** fetch data from the table in a streaming manner: "field_partner_response_events" */
  field_partner_response_events_stream: Array<Field_Partner_Response_Events>;
  /** An array relationship */
  field_partner_response_messages: Array<Field_Partner_Response_Messages>;
  /** An aggregate relationship */
  field_partner_response_messages_aggregate: Field_Partner_Response_Messages_Aggregate;
  /** fetch data from the table: "field_partner_response_messages" using primary key columns */
  field_partner_response_messages_by_pk?: Maybe<Field_Partner_Response_Messages>;
  /** fetch data from the table in a streaming manner: "field_partner_response_messages" */
  field_partner_response_messages_stream: Array<Field_Partner_Response_Messages>;
  /** An array relationship */
  field_partner_responses: Array<Field_Partner_Responses>;
  /** An aggregate relationship */
  field_partner_responses_aggregate: Field_Partner_Responses_Aggregate;
  /** fetch data from the table: "field_partner_responses" using primary key columns */
  field_partner_responses_by_pk?: Maybe<Field_Partner_Responses>;
  /** fetch data from the table in a streaming manner: "field_partner_responses" */
  field_partner_responses_stream: Array<Field_Partner_Responses>;
  /** An array relationship */
  field_scheduled_visits: Array<Field_Scheduled_Visits>;
  /** An aggregate relationship */
  field_scheduled_visits_aggregate: Field_Scheduled_Visits_Aggregate;
  /** fetch data from the table: "field_scheduled_visits" using primary key columns */
  field_scheduled_visits_by_pk?: Maybe<Field_Scheduled_Visits>;
  /** fetch data from the table in a streaming manner: "field_scheduled_visits" */
  field_scheduled_visits_stream: Array<Field_Scheduled_Visits>;
  /** fetch data from the table: "follows" */
  follows: Array<Follows>;
  /** fetch aggregated fields from the table: "follows" */
  follows_aggregate: Follows_Aggregate;
  /** fetch data from the table: "follows" using primary key columns */
  follows_by_pk?: Maybe<Follows>;
  /** fetch data from the table in a streaming manner: "follows" */
  follows_stream: Array<Follows>;
  /** fetch data from the table: "geography_columns" */
  geography_columns: Array<Geography_Columns>;
  /** fetch aggregated fields from the table: "geography_columns" */
  geography_columns_aggregate: Geography_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geography_columns" */
  geography_columns_stream: Array<Geography_Columns>;
  /** fetch data from the table: "geometry_columns" */
  geometry_columns: Array<Geometry_Columns>;
  /** fetch aggregated fields from the table: "geometry_columns" */
  geometry_columns_aggregate: Geometry_Columns_Aggregate;
  /** fetch data from the table in a streaming manner: "geometry_columns" */
  geometry_columns_stream: Array<Geometry_Columns>;
  /** execute function "get_reel_with_related" which returns "reels_with_distance" */
  get_reel_with_related: Array<Reels_With_Distance>;
  /** execute function "get_reel_with_related" and query aggregates on result of table type "reels_with_distance" */
  get_reel_with_related_aggregate: Reels_With_Distance_Aggregate;
  /** execute function "get_reels" which returns "reels_with_distance" */
  get_reels: Array<Reels_With_Distance>;
  /** execute function "get_reels" and query aggregates on result of table type "reels_with_distance" */
  get_reels_aggregate: Reels_With_Distance_Aggregate;
  /** An array relationship */
  last_known_locations: Array<Last_Known_Locations>;
  /** An aggregate relationship */
  last_known_locations_aggregate: Last_Known_Locations_Aggregate;
  /** fetch data from the table: "last_known_locations" using primary key columns */
  last_known_locations_by_pk?: Maybe<Last_Known_Locations>;
  /** fetch data from the table in a streaming manner: "last_known_locations" */
  last_known_locations_stream: Array<Last_Known_Locations>;
  /** fetch data from the table: "master_crops" */
  master_crops: Array<Master_Crops>;
  /** fetch aggregated fields from the table: "master_crops" */
  master_crops_aggregate: Master_Crops_Aggregate;
  /** fetch data from the table: "master_crops" using primary key columns */
  master_crops_by_pk?: Maybe<Master_Crops>;
  /** fetch data from the table in a streaming manner: "master_crops" */
  master_crops_stream: Array<Master_Crops>;
  /** fetch data from the table: "master_segments" */
  master_segments: Array<Master_Segments>;
  /** fetch aggregated fields from the table: "master_segments" */
  master_segments_aggregate: Master_Segments_Aggregate;
  /** fetch data from the table: "master_segments" using primary key columns */
  master_segments_by_pk?: Maybe<Master_Segments>;
  /** fetch data from the table in a streaming manner: "master_segments" */
  master_segments_stream: Array<Master_Segments>;
  /** fetch data from the table: "migrations" */
  migrations: Array<Migrations>;
  /** fetch aggregated fields from the table: "migrations" */
  migrations_aggregate: Migrations_Aggregate;
  /** fetch data from the table: "migrations" using primary key columns */
  migrations_by_pk?: Maybe<Migrations>;
  /** fetch data from the table in a streaming manner: "migrations" */
  migrations_stream: Array<Migrations>;
  /** An array relationship */
  model_has_permissions: Array<Model_Has_Permissions>;
  /** An aggregate relationship */
  model_has_permissions_aggregate: Model_Has_Permissions_Aggregate;
  /** fetch data from the table: "model_has_permissions" using primary key columns */
  model_has_permissions_by_pk?: Maybe<Model_Has_Permissions>;
  /** fetch data from the table in a streaming manner: "model_has_permissions" */
  model_has_permissions_stream: Array<Model_Has_Permissions>;
  /** An array relationship */
  model_has_roles: Array<Model_Has_Roles>;
  /** An aggregate relationship */
  model_has_roles_aggregate: Model_Has_Roles_Aggregate;
  /** fetch data from the table: "model_has_roles" using primary key columns */
  model_has_roles_by_pk?: Maybe<Model_Has_Roles>;
  /** fetch data from the table in a streaming manner: "model_has_roles" */
  model_has_roles_stream: Array<Model_Has_Roles>;
  /** fetch data from the table: "notifications" */
  notifications: Array<Notifications>;
  /** fetch aggregated fields from the table: "notifications" */
  notifications_aggregate: Notifications_Aggregate;
  /** fetch data from the table: "notifications" using primary key columns */
  notifications_by_pk?: Maybe<Notifications>;
  /** fetch data from the table in a streaming manner: "notifications" */
  notifications_stream: Array<Notifications>;
  /** fetch data from the table: "nova_field_attachments" */
  nova_field_attachments: Array<Nova_Field_Attachments>;
  /** fetch aggregated fields from the table: "nova_field_attachments" */
  nova_field_attachments_aggregate: Nova_Field_Attachments_Aggregate;
  /** fetch data from the table: "nova_field_attachments" using primary key columns */
  nova_field_attachments_by_pk?: Maybe<Nova_Field_Attachments>;
  /** fetch data from the table in a streaming manner: "nova_field_attachments" */
  nova_field_attachments_stream: Array<Nova_Field_Attachments>;
  /** fetch data from the table: "nova_notifications" */
  nova_notifications: Array<Nova_Notifications>;
  /** fetch aggregated fields from the table: "nova_notifications" */
  nova_notifications_aggregate: Nova_Notifications_Aggregate;
  /** fetch data from the table: "nova_notifications" using primary key columns */
  nova_notifications_by_pk?: Maybe<Nova_Notifications>;
  /** fetch data from the table in a streaming manner: "nova_notifications" */
  nova_notifications_stream: Array<Nova_Notifications>;
  /** fetch data from the table: "nova_pending_field_attachments" */
  nova_pending_field_attachments: Array<Nova_Pending_Field_Attachments>;
  /** fetch aggregated fields from the table: "nova_pending_field_attachments" */
  nova_pending_field_attachments_aggregate: Nova_Pending_Field_Attachments_Aggregate;
  /** fetch data from the table: "nova_pending_field_attachments" using primary key columns */
  nova_pending_field_attachments_by_pk?: Maybe<Nova_Pending_Field_Attachments>;
  /** fetch data from the table in a streaming manner: "nova_pending_field_attachments" */
  nova_pending_field_attachments_stream: Array<Nova_Pending_Field_Attachments>;
  /** fetch data from the table: "nova_twofa" */
  nova_twofa: Array<Nova_Twofa>;
  /** fetch aggregated fields from the table: "nova_twofa" */
  nova_twofa_aggregate: Nova_Twofa_Aggregate;
  /** fetch data from the table: "nova_twofa" using primary key columns */
  nova_twofa_by_pk?: Maybe<Nova_Twofa>;
  /** fetch data from the table in a streaming manner: "nova_twofa" */
  nova_twofa_stream: Array<Nova_Twofa>;
  /** fetch data from the table: "pairing_codes" */
  pairing_codes: Array<Pairing_Codes>;
  /** fetch aggregated fields from the table: "pairing_codes" */
  pairing_codes_aggregate: Pairing_Codes_Aggregate;
  /** fetch data from the table in a streaming manner: "pairing_codes" */
  pairing_codes_stream: Array<Pairing_Codes>;
  /** fetch data from the table: "password_reset_tokens" */
  password_reset_tokens: Array<Password_Reset_Tokens>;
  /** fetch aggregated fields from the table: "password_reset_tokens" */
  password_reset_tokens_aggregate: Password_Reset_Tokens_Aggregate;
  /** fetch data from the table: "password_reset_tokens" using primary key columns */
  password_reset_tokens_by_pk?: Maybe<Password_Reset_Tokens>;
  /** fetch data from the table in a streaming manner: "password_reset_tokens" */
  password_reset_tokens_stream: Array<Password_Reset_Tokens>;
  /** fetch data from the table: "permissions" */
  permissions: Array<Permissions>;
  /** fetch aggregated fields from the table: "permissions" */
  permissions_aggregate: Permissions_Aggregate;
  /** fetch data from the table: "permissions" using primary key columns */
  permissions_by_pk?: Maybe<Permissions>;
  /** fetch data from the table in a streaming manner: "permissions" */
  permissions_stream: Array<Permissions>;
  /** fetch data from the table: "personal_access_tokens" */
  personal_access_tokens: Array<Personal_Access_Tokens>;
  /** fetch aggregated fields from the table: "personal_access_tokens" */
  personal_access_tokens_aggregate: Personal_Access_Tokens_Aggregate;
  /** fetch data from the table: "personal_access_tokens" using primary key columns */
  personal_access_tokens_by_pk?: Maybe<Personal_Access_Tokens>;
  /** fetch data from the table in a streaming manner: "personal_access_tokens" */
  personal_access_tokens_stream: Array<Personal_Access_Tokens>;
  /** fetch data from the table: "personal_dashboard" */
  personal_dashboard: Array<Personal_Dashboard>;
  /** fetch aggregated fields from the table: "personal_dashboard" */
  personal_dashboard_aggregate: Personal_Dashboard_Aggregate;
  /** fetch data from the table: "personal_dashboard" using primary key columns */
  personal_dashboard_by_pk?: Maybe<Personal_Dashboard>;
  /** fetch data from the table in a streaming manner: "personal_dashboard" */
  personal_dashboard_stream: Array<Personal_Dashboard>;
  /** fetch data from the table: "reel_category" */
  reel_category: Array<Reel_Category>;
  /** fetch aggregated fields from the table: "reel_category" */
  reel_category_aggregate: Reel_Category_Aggregate;
  /** fetch data from the table: "reel_category" using primary key columns */
  reel_category_by_pk?: Maybe<Reel_Category>;
  /** fetch data from the table in a streaming manner: "reel_category" */
  reel_category_stream: Array<Reel_Category>;
  /** An array relationship */
  reel_comments: Array<Reel_Comments>;
  /** An aggregate relationship */
  reel_comments_aggregate: Reel_Comments_Aggregate;
  /** fetch data from the table: "reel_comments" using primary key columns */
  reel_comments_by_pk?: Maybe<Reel_Comments>;
  /** fetch data from the table in a streaming manner: "reel_comments" */
  reel_comments_stream: Array<Reel_Comments>;
  /** fetch data from the table: "reel_interactions" */
  reel_interactions: Array<Reel_Interactions>;
  /** fetch aggregated fields from the table: "reel_interactions" */
  reel_interactions_aggregate: Reel_Interactions_Aggregate;
  /** fetch data from the table: "reel_interactions" using primary key columns */
  reel_interactions_by_pk?: Maybe<Reel_Interactions>;
  /** fetch data from the table in a streaming manner: "reel_interactions" */
  reel_interactions_stream: Array<Reel_Interactions>;
  /** fetch data from the table: "reel_user_reports" */
  reel_user_reports: Array<Reel_User_Reports>;
  /** fetch aggregated fields from the table: "reel_user_reports" */
  reel_user_reports_aggregate: Reel_User_Reports_Aggregate;
  /** fetch data from the table: "reel_user_reports" using primary key columns */
  reel_user_reports_by_pk?: Maybe<Reel_User_Reports>;
  /** fetch data from the table in a streaming manner: "reel_user_reports" */
  reel_user_reports_stream: Array<Reel_User_Reports>;
  /** An array relationship */
  reels: Array<Reels>;
  /** An aggregate relationship */
  reels_aggregate: Reels_Aggregate;
  /** fetch data from the table: "reels" using primary key columns */
  reels_by_pk?: Maybe<Reels>;
  /** fetch data from the table in a streaming manner: "reels" */
  reels_stream: Array<Reels>;
  /** fetch data from the table: "reels_with_distance" */
  reels_with_distance: Array<Reels_With_Distance>;
  /** fetch aggregated fields from the table: "reels_with_distance" */
  reels_with_distance_aggregate: Reels_With_Distance_Aggregate;
  /** fetch data from the table in a streaming manner: "reels_with_distance" */
  reels_with_distance_stream: Array<Reels_With_Distance>;
  /** An array relationship */
  role_has_permissions: Array<Role_Has_Permissions>;
  /** An aggregate relationship */
  role_has_permissions_aggregate: Role_Has_Permissions_Aggregate;
  /** fetch data from the table: "role_has_permissions" using primary key columns */
  role_has_permissions_by_pk?: Maybe<Role_Has_Permissions>;
  /** fetch data from the table in a streaming manner: "role_has_permissions" */
  role_has_permissions_stream: Array<Role_Has_Permissions>;
  /** fetch data from the table: "roles" */
  roles: Array<Roles>;
  /** fetch aggregated fields from the table: "roles" */
  roles_aggregate: Roles_Aggregate;
  /** fetch data from the table: "roles" using primary key columns */
  roles_by_pk?: Maybe<Roles>;
  /** fetch data from the table in a streaming manner: "roles" */
  roles_stream: Array<Roles>;
  /** execute function "search_field_force_near_farmer" which returns "last_known_locations" */
  search_field_force_near_farmer: Array<Last_Known_Locations>;
  /** execute function "search_field_force_near_farmer" and query aggregates on result of table type "last_known_locations" */
  search_field_force_near_farmer_aggregate: Last_Known_Locations_Aggregate;
  /** fetch data from the table: "segment_user" */
  segment_user: Array<Segment_User>;
  /** fetch aggregated fields from the table: "segment_user" */
  segment_user_aggregate: Segment_User_Aggregate;
  /** fetch data from the table: "segment_user" using primary key columns */
  segment_user_by_pk?: Maybe<Segment_User>;
  /** fetch data from the table in a streaming manner: "segment_user" */
  segment_user_stream: Array<Segment_User>;
  /** fetch data from the table: "service_request_dashboard" */
  service_request_dashboard: Array<Service_Request_Dashboard>;
  /** fetch aggregated fields from the table: "service_request_dashboard" */
  service_request_dashboard_aggregate: Service_Request_Dashboard_Aggregate;
  /** fetch data from the table: "service_request_dashboard" using primary key columns */
  service_request_dashboard_by_pk?: Maybe<Service_Request_Dashboard>;
  /** fetch data from the table in a streaming manner: "service_request_dashboard" */
  service_request_dashboard_stream: Array<Service_Request_Dashboard>;
  /** An array relationship */
  service_requests: Array<Service_Requests>;
  /** An aggregate relationship */
  service_requests_aggregate: Service_Requests_Aggregate;
  /** fetch data from the table: "service_requests" using primary key columns */
  service_requests_by_pk?: Maybe<Service_Requests>;
  /** fetch data from the table in a streaming manner: "service_requests" */
  service_requests_stream: Array<Service_Requests>;
  /** fetch data from the table: "snapshots" */
  snapshots: Array<Snapshots>;
  /** fetch aggregated fields from the table: "snapshots" */
  snapshots_aggregate: Snapshots_Aggregate;
  /** fetch data from the table: "snapshots" using primary key columns */
  snapshots_by_pk?: Maybe<Snapshots>;
  /** fetch data from the table in a streaming manner: "snapshots" */
  snapshots_stream: Array<Snapshots>;
  /** fetch data from the table: "spatial_ref_sys" */
  spatial_ref_sys: Array<Spatial_Ref_Sys>;
  /** fetch aggregated fields from the table: "spatial_ref_sys" */
  spatial_ref_sys_aggregate: Spatial_Ref_Sys_Aggregate;
  /** fetch data from the table: "spatial_ref_sys" using primary key columns */
  spatial_ref_sys_by_pk?: Maybe<Spatial_Ref_Sys>;
  /** fetch data from the table in a streaming manner: "spatial_ref_sys" */
  spatial_ref_sys_stream: Array<Spatial_Ref_Sys>;
  /** fetch data from the table: "stored_events" */
  stored_events: Array<Stored_Events>;
  /** fetch aggregated fields from the table: "stored_events" */
  stored_events_aggregate: Stored_Events_Aggregate;
  /** fetch data from the table: "stored_events" using primary key columns */
  stored_events_by_pk?: Maybe<Stored_Events>;
  /** fetch data from the table in a streaming manner: "stored_events" */
  stored_events_stream: Array<Stored_Events>;
  /** fetch data from the table: "taggables" */
  taggables: Array<Taggables>;
  /** fetch aggregated fields from the table: "taggables" */
  taggables_aggregate: Taggables_Aggregate;
  /** fetch data from the table in a streaming manner: "taggables" */
  taggables_stream: Array<Taggables>;
  /** fetch data from the table: "tags" */
  tags: Array<Tags>;
  /** fetch aggregated fields from the table: "tags" */
  tags_aggregate: Tags_Aggregate;
  /** fetch data from the table: "tags" using primary key columns */
  tags_by_pk?: Maybe<Tags>;
  /** fetch data from the table in a streaming manner: "tags" */
  tags_stream: Array<Tags>;
  /** fetch data from the table: "user_device" */
  user_device: Array<User_Device>;
  /** fetch aggregated fields from the table: "user_device" */
  user_device_aggregate: User_Device_Aggregate;
  /** fetch data from the table: "user_device" using primary key columns */
  user_device_by_pk?: Maybe<User_Device>;
  /** fetch data from the table in a streaming manner: "user_device" */
  user_device_stream: Array<User_Device>;
  /** An array relationship */
  user_feedbacks: Array<User_Feedbacks>;
  /** An aggregate relationship */
  user_feedbacks_aggregate: User_Feedbacks_Aggregate;
  /** fetch data from the table: "user_feedbacks" using primary key columns */
  user_feedbacks_by_pk?: Maybe<User_Feedbacks>;
  /** fetch data from the table in a streaming manner: "user_feedbacks" */
  user_feedbacks_stream: Array<User_Feedbacks>;
  /** An array relationship */
  user_location_logs: Array<User_Location_Logs>;
  /** An aggregate relationship */
  user_location_logs_aggregate: User_Location_Logs_Aggregate;
  /** fetch data from the table: "user_location_logs" using primary key columns */
  user_location_logs_by_pk?: Maybe<User_Location_Logs>;
  /** fetch data from the table in a streaming manner: "user_location_logs" */
  user_location_logs_stream: Array<User_Location_Logs>;
  /** fetch data from the table: "users" */
  users: Array<Users>;
  /** fetch aggregated fields from the table: "users" */
  users_aggregate: Users_Aggregate;
  /** fetch data from the table: "users" using primary key columns */
  users_by_pk?: Maybe<Users>;
  /** fetch data from the table in a streaming manner: "users" */
  users_stream: Array<Users>;
  /** fetch data from the table: "whatsauth_logins" */
  whatsauth_logins: Array<Whatsauth_Logins>;
  /** fetch aggregated fields from the table: "whatsauth_logins" */
  whatsauth_logins_aggregate: Whatsauth_Logins_Aggregate;
  /** fetch data from the table: "whatsauth_logins" using primary key columns */
  whatsauth_logins_by_pk?: Maybe<Whatsauth_Logins>;
  /** fetch data from the table in a streaming manner: "whatsauth_logins" */
  whatsauth_logins_stream: Array<Whatsauth_Logins>;
};


export type Subscription_RootAccount_CropArgs = {
  distinct_on?: InputMaybe<Array<Account_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Crop_Order_By>>;
  where?: InputMaybe<Account_Crop_Bool_Exp>;
};


export type Subscription_RootAccount_Crop_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Crop_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Crop_Order_By>>;
  where?: InputMaybe<Account_Crop_Bool_Exp>;
};


export type Subscription_RootAccount_Crop_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootAccount_Crop_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Account_Crop_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Crop_Bool_Exp>;
};


export type Subscription_RootAccount_SegmentArgs = {
  distinct_on?: InputMaybe<Array<Account_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Segment_Order_By>>;
  where?: InputMaybe<Account_Segment_Bool_Exp>;
};


export type Subscription_RootAccount_Segment_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_Segment_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_Segment_Order_By>>;
  where?: InputMaybe<Account_Segment_Bool_Exp>;
};


export type Subscription_RootAccount_Segment_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootAccount_Segment_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Account_Segment_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_Segment_Bool_Exp>;
};


export type Subscription_RootAccount_UserArgs = {
  distinct_on?: InputMaybe<Array<Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_User_Order_By>>;
  where?: InputMaybe<Account_User_Bool_Exp>;
};


export type Subscription_RootAccount_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_User_Order_By>>;
  where?: InputMaybe<Account_User_Bool_Exp>;
};


export type Subscription_RootAccount_User_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootAccount_User_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Account_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Account_User_Bool_Exp>;
};


export type Subscription_RootAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAccounts_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootAccounts_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Accounts_Stream_Cursor_Input>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


export type Subscription_RootAction_EventsArgs = {
  distinct_on?: InputMaybe<Array<Action_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Events_Order_By>>;
  where?: InputMaybe<Action_Events_Bool_Exp>;
};


export type Subscription_RootAction_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Action_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Action_Events_Order_By>>;
  where?: InputMaybe<Action_Events_Bool_Exp>;
};


export type Subscription_RootAction_Events_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootAction_Events_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Action_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Action_Events_Bool_Exp>;
};


export type Subscription_RootCompaniesArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


export type Subscription_RootCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


export type Subscription_RootCompanies_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootCompanies_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Companies_Stream_Cursor_Input>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


export type Subscription_RootCrop_UserArgs = {
  distinct_on?: InputMaybe<Array<Crop_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Crop_User_Order_By>>;
  where?: InputMaybe<Crop_User_Bool_Exp>;
};


export type Subscription_RootCrop_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Crop_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Crop_User_Order_By>>;
  where?: InputMaybe<Crop_User_Bool_Exp>;
};


export type Subscription_RootCrop_User_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootCrop_User_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Crop_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Crop_User_Bool_Exp>;
};


export type Subscription_RootFailed_JobsArgs = {
  distinct_on?: InputMaybe<Array<Failed_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Failed_Jobs_Order_By>>;
  where?: InputMaybe<Failed_Jobs_Bool_Exp>;
};


export type Subscription_RootFailed_Jobs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Failed_Jobs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Failed_Jobs_Order_By>>;
  where?: InputMaybe<Failed_Jobs_Bool_Exp>;
};


export type Subscription_RootFailed_Jobs_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootFailed_Jobs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Failed_Jobs_Stream_Cursor_Input>>;
  where?: InputMaybe<Failed_Jobs_Bool_Exp>;
};


export type Subscription_RootFarmer_Nearby_Field_ForceArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Nearby_Field_Force_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Nearby_Field_Force_Order_By>>;
  where?: InputMaybe<Farmer_Nearby_Field_Force_Bool_Exp>;
};


export type Subscription_RootFarmer_Nearby_Field_Force_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Nearby_Field_Force_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Nearby_Field_Force_Order_By>>;
  where?: InputMaybe<Farmer_Nearby_Field_Force_Bool_Exp>;
};


export type Subscription_RootFarmer_Nearby_Field_Force_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootFarmer_Nearby_Field_Force_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Farmer_Nearby_Field_Force_Stream_Cursor_Input>>;
  where?: InputMaybe<Farmer_Nearby_Field_Force_Bool_Exp>;
};


export type Subscription_RootFarmer_PlotsArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Plots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Plots_Order_By>>;
  where?: InputMaybe<Farmer_Plots_Bool_Exp>;
};


export type Subscription_RootFarmer_Plots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Plots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Plots_Order_By>>;
  where?: InputMaybe<Farmer_Plots_Bool_Exp>;
};


export type Subscription_RootFarmer_Plots_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootFarmer_Plots_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Farmer_Plots_Stream_Cursor_Input>>;
  where?: InputMaybe<Farmer_Plots_Bool_Exp>;
};


export type Subscription_RootField_Partner_Response_EventsArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


export type Subscription_RootField_Partner_Response_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


export type Subscription_RootField_Partner_Response_Events_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootField_Partner_Response_Events_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Field_Partner_Response_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


export type Subscription_RootField_Partner_Response_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


export type Subscription_RootField_Partner_Response_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


export type Subscription_RootField_Partner_Response_Messages_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootField_Partner_Response_Messages_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Field_Partner_Response_Messages_Stream_Cursor_Input>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


export type Subscription_RootField_Partner_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


export type Subscription_RootField_Partner_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


export type Subscription_RootField_Partner_Responses_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootField_Partner_Responses_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Field_Partner_Responses_Stream_Cursor_Input>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


export type Subscription_RootField_Scheduled_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


export type Subscription_RootField_Scheduled_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


export type Subscription_RootField_Scheduled_Visits_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootField_Scheduled_Visits_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Field_Scheduled_Visits_Stream_Cursor_Input>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


export type Subscription_RootFollowsArgs = {
  distinct_on?: InputMaybe<Array<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Subscription_RootFollows_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Subscription_RootFollows_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootFollows_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Follows_Stream_Cursor_Input>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


export type Subscription_RootGeography_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeography_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geography_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geography_Columns_Order_By>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeography_Columns_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Geography_Columns_Stream_Cursor_Input>>;
  where?: InputMaybe<Geography_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_ColumnsArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_Columns_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Geometry_Columns_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Geometry_Columns_Order_By>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootGeometry_Columns_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Geometry_Columns_Stream_Cursor_Input>>;
  where?: InputMaybe<Geometry_Columns_Bool_Exp>;
};


export type Subscription_RootGet_Reel_With_RelatedArgs = {
  args: Get_Reel_With_Related_Args;
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Subscription_RootGet_Reel_With_Related_AggregateArgs = {
  args: Get_Reel_With_Related_Args;
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Subscription_RootGet_ReelsArgs = {
  args: Get_Reels_Args;
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Subscription_RootGet_Reels_AggregateArgs = {
  args: Get_Reels_Args;
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Subscription_RootLast_Known_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


export type Subscription_RootLast_Known_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


export type Subscription_RootLast_Known_Locations_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootLast_Known_Locations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Last_Known_Locations_Stream_Cursor_Input>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


export type Subscription_RootMaster_CropsArgs = {
  distinct_on?: InputMaybe<Array<Master_Crops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Master_Crops_Order_By>>;
  where?: InputMaybe<Master_Crops_Bool_Exp>;
};


export type Subscription_RootMaster_Crops_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Crops_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Master_Crops_Order_By>>;
  where?: InputMaybe<Master_Crops_Bool_Exp>;
};


export type Subscription_RootMaster_Crops_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootMaster_Crops_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Master_Crops_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Crops_Bool_Exp>;
};


export type Subscription_RootMaster_SegmentsArgs = {
  distinct_on?: InputMaybe<Array<Master_Segments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Master_Segments_Order_By>>;
  where?: InputMaybe<Master_Segments_Bool_Exp>;
};


export type Subscription_RootMaster_Segments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Master_Segments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Master_Segments_Order_By>>;
  where?: InputMaybe<Master_Segments_Bool_Exp>;
};


export type Subscription_RootMaster_Segments_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootMaster_Segments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Master_Segments_Stream_Cursor_Input>>;
  where?: InputMaybe<Master_Segments_Bool_Exp>;
};


export type Subscription_RootMigrationsArgs = {
  distinct_on?: InputMaybe<Array<Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Migrations_Order_By>>;
  where?: InputMaybe<Migrations_Bool_Exp>;
};


export type Subscription_RootMigrations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Migrations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Migrations_Order_By>>;
  where?: InputMaybe<Migrations_Bool_Exp>;
};


export type Subscription_RootMigrations_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootMigrations_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Migrations_Stream_Cursor_Input>>;
  where?: InputMaybe<Migrations_Bool_Exp>;
};


export type Subscription_RootModel_Has_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Permissions_Order_By>>;
  where?: InputMaybe<Model_Has_Permissions_Bool_Exp>;
};


export type Subscription_RootModel_Has_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Permissions_Order_By>>;
  where?: InputMaybe<Model_Has_Permissions_Bool_Exp>;
};


export type Subscription_RootModel_Has_Permissions_By_PkArgs = {
  model_id: Scalars['String']['input'];
  model_type: Scalars['String']['input'];
  permission_id: Scalars['bigint']['input'];
};


export type Subscription_RootModel_Has_Permissions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Model_Has_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Model_Has_Permissions_Bool_Exp>;
};


export type Subscription_RootModel_Has_RolesArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Roles_Order_By>>;
  where?: InputMaybe<Model_Has_Roles_Bool_Exp>;
};


export type Subscription_RootModel_Has_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Roles_Order_By>>;
  where?: InputMaybe<Model_Has_Roles_Bool_Exp>;
};


export type Subscription_RootModel_Has_Roles_By_PkArgs = {
  model_id: Scalars['String']['input'];
  model_type: Scalars['String']['input'];
  role_id: Scalars['bigint']['input'];
};


export type Subscription_RootModel_Has_Roles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Model_Has_Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Model_Has_Roles_Bool_Exp>;
};


export type Subscription_RootNotificationsArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Notifications_Order_By>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNotifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNotifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Notifications_Bool_Exp>;
};


export type Subscription_RootNova_Field_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Nova_Field_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Field_Attachments_Order_By>>;
  where?: InputMaybe<Nova_Field_Attachments_Bool_Exp>;
};


export type Subscription_RootNova_Field_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nova_Field_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Field_Attachments_Order_By>>;
  where?: InputMaybe<Nova_Field_Attachments_Bool_Exp>;
};


export type Subscription_RootNova_Field_Attachments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootNova_Field_Attachments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Nova_Field_Attachments_Stream_Cursor_Input>>;
  where?: InputMaybe<Nova_Field_Attachments_Bool_Exp>;
};


export type Subscription_RootNova_NotificationsArgs = {
  distinct_on?: InputMaybe<Array<Nova_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Notifications_Order_By>>;
  where?: InputMaybe<Nova_Notifications_Bool_Exp>;
};


export type Subscription_RootNova_Notifications_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nova_Notifications_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Notifications_Order_By>>;
  where?: InputMaybe<Nova_Notifications_Bool_Exp>;
};


export type Subscription_RootNova_Notifications_By_PkArgs = {
  id: Scalars['uuid']['input'];
};


export type Subscription_RootNova_Notifications_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Nova_Notifications_Stream_Cursor_Input>>;
  where?: InputMaybe<Nova_Notifications_Bool_Exp>;
};


export type Subscription_RootNova_Pending_Field_AttachmentsArgs = {
  distinct_on?: InputMaybe<Array<Nova_Pending_Field_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Pending_Field_Attachments_Order_By>>;
  where?: InputMaybe<Nova_Pending_Field_Attachments_Bool_Exp>;
};


export type Subscription_RootNova_Pending_Field_Attachments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nova_Pending_Field_Attachments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Pending_Field_Attachments_Order_By>>;
  where?: InputMaybe<Nova_Pending_Field_Attachments_Bool_Exp>;
};


export type Subscription_RootNova_Pending_Field_Attachments_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootNova_Pending_Field_Attachments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Nova_Pending_Field_Attachments_Stream_Cursor_Input>>;
  where?: InputMaybe<Nova_Pending_Field_Attachments_Bool_Exp>;
};


export type Subscription_RootNova_TwofaArgs = {
  distinct_on?: InputMaybe<Array<Nova_Twofa_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Twofa_Order_By>>;
  where?: InputMaybe<Nova_Twofa_Bool_Exp>;
};


export type Subscription_RootNova_Twofa_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nova_Twofa_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Twofa_Order_By>>;
  where?: InputMaybe<Nova_Twofa_Bool_Exp>;
};


export type Subscription_RootNova_Twofa_By_PkArgs = {
  id: Scalars['Int']['input'];
};


export type Subscription_RootNova_Twofa_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Nova_Twofa_Stream_Cursor_Input>>;
  where?: InputMaybe<Nova_Twofa_Bool_Exp>;
};


export type Subscription_RootPairing_CodesArgs = {
  distinct_on?: InputMaybe<Array<Pairing_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pairing_Codes_Order_By>>;
  where?: InputMaybe<Pairing_Codes_Bool_Exp>;
};


export type Subscription_RootPairing_Codes_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Pairing_Codes_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Pairing_Codes_Order_By>>;
  where?: InputMaybe<Pairing_Codes_Bool_Exp>;
};


export type Subscription_RootPairing_Codes_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Pairing_Codes_Stream_Cursor_Input>>;
  where?: InputMaybe<Pairing_Codes_Bool_Exp>;
};


export type Subscription_RootPassword_Reset_TokensArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Tokens_Order_By>>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};


export type Subscription_RootPassword_Reset_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Password_Reset_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Password_Reset_Tokens_Order_By>>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};


export type Subscription_RootPassword_Reset_Tokens_By_PkArgs = {
  email: Scalars['String']['input'];
};


export type Subscription_RootPassword_Reset_Tokens_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Password_Reset_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Password_Reset_Tokens_Bool_Exp>;
};


export type Subscription_RootPermissionsArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};


export type Subscription_RootPermissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Permissions_Order_By>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};


export type Subscription_RootPermissions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPermissions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Permissions_Bool_Exp>;
};


export type Subscription_RootPersonal_Access_TokensArgs = {
  distinct_on?: InputMaybe<Array<Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<Personal_Access_Tokens_Bool_Exp>;
};


export type Subscription_RootPersonal_Access_Tokens_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personal_Access_Tokens_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Access_Tokens_Order_By>>;
  where?: InputMaybe<Personal_Access_Tokens_Bool_Exp>;
};


export type Subscription_RootPersonal_Access_Tokens_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootPersonal_Access_Tokens_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Personal_Access_Tokens_Stream_Cursor_Input>>;
  where?: InputMaybe<Personal_Access_Tokens_Bool_Exp>;
};


export type Subscription_RootPersonal_DashboardArgs = {
  distinct_on?: InputMaybe<Array<Personal_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Dashboard_Order_By>>;
  where?: InputMaybe<Personal_Dashboard_Bool_Exp>;
};


export type Subscription_RootPersonal_Dashboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Personal_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Personal_Dashboard_Order_By>>;
  where?: InputMaybe<Personal_Dashboard_Bool_Exp>;
};


export type Subscription_RootPersonal_Dashboard_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootPersonal_Dashboard_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Personal_Dashboard_Stream_Cursor_Input>>;
  where?: InputMaybe<Personal_Dashboard_Bool_Exp>;
};


export type Subscription_RootReel_CategoryArgs = {
  distinct_on?: InputMaybe<Array<Reel_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Category_Order_By>>;
  where?: InputMaybe<Reel_Category_Bool_Exp>;
};


export type Subscription_RootReel_Category_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Category_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Category_Order_By>>;
  where?: InputMaybe<Reel_Category_Bool_Exp>;
};


export type Subscription_RootReel_Category_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootReel_Category_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reel_Category_Stream_Cursor_Input>>;
  where?: InputMaybe<Reel_Category_Bool_Exp>;
};


export type Subscription_RootReel_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Comments_Order_By>>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};


export type Subscription_RootReel_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Comments_Order_By>>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};


export type Subscription_RootReel_Comments_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootReel_Comments_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reel_Comments_Stream_Cursor_Input>>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};


export type Subscription_RootReel_InteractionsArgs = {
  distinct_on?: InputMaybe<Array<Reel_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Interactions_Order_By>>;
  where?: InputMaybe<Reel_Interactions_Bool_Exp>;
};


export type Subscription_RootReel_Interactions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Interactions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Interactions_Order_By>>;
  where?: InputMaybe<Reel_Interactions_Bool_Exp>;
};


export type Subscription_RootReel_Interactions_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootReel_Interactions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reel_Interactions_Stream_Cursor_Input>>;
  where?: InputMaybe<Reel_Interactions_Bool_Exp>;
};


export type Subscription_RootReel_User_ReportsArgs = {
  distinct_on?: InputMaybe<Array<Reel_User_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_User_Reports_Order_By>>;
  where?: InputMaybe<Reel_User_Reports_Bool_Exp>;
};


export type Subscription_RootReel_User_Reports_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_User_Reports_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_User_Reports_Order_By>>;
  where?: InputMaybe<Reel_User_Reports_Bool_Exp>;
};


export type Subscription_RootReel_User_Reports_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootReel_User_Reports_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reel_User_Reports_Stream_Cursor_Input>>;
  where?: InputMaybe<Reel_User_Reports_Bool_Exp>;
};


export type Subscription_RootReelsArgs = {
  distinct_on?: InputMaybe<Array<Reels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_Order_By>>;
  where?: InputMaybe<Reels_Bool_Exp>;
};


export type Subscription_RootReels_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reels_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_Order_By>>;
  where?: InputMaybe<Reels_Bool_Exp>;
};


export type Subscription_RootReels_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootReels_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reels_Stream_Cursor_Input>>;
  where?: InputMaybe<Reels_Bool_Exp>;
};


export type Subscription_RootReels_With_DistanceArgs = {
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Subscription_RootReels_With_Distance_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reels_With_Distance_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reels_With_Distance_Order_By>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Subscription_RootReels_With_Distance_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Reels_With_Distance_Stream_Cursor_Input>>;
  where?: InputMaybe<Reels_With_Distance_Bool_Exp>;
};


export type Subscription_RootRole_Has_PermissionsArgs = {
  distinct_on?: InputMaybe<Array<Role_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Has_Permissions_Order_By>>;
  where?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
};


export type Subscription_RootRole_Has_Permissions_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Role_Has_Permissions_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Role_Has_Permissions_Order_By>>;
  where?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
};


export type Subscription_RootRole_Has_Permissions_By_PkArgs = {
  permission_id: Scalars['bigint']['input'];
  role_id: Scalars['bigint']['input'];
};


export type Subscription_RootRole_Has_Permissions_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Role_Has_Permissions_Stream_Cursor_Input>>;
  where?: InputMaybe<Role_Has_Permissions_Bool_Exp>;
};


export type Subscription_RootRolesArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Roles_Order_By>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootRoles_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootRoles_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Roles_Stream_Cursor_Input>>;
  where?: InputMaybe<Roles_Bool_Exp>;
};


export type Subscription_RootSearch_Field_Force_Near_FarmerArgs = {
  args: Search_Field_Force_Near_Farmer_Args;
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


export type Subscription_RootSearch_Field_Force_Near_Farmer_AggregateArgs = {
  args: Search_Field_Force_Near_Farmer_Args;
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


export type Subscription_RootSegment_UserArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Segment_User_Order_By>>;
  where?: InputMaybe<Segment_User_Bool_Exp>;
};


export type Subscription_RootSegment_User_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Segment_User_Order_By>>;
  where?: InputMaybe<Segment_User_Bool_Exp>;
};


export type Subscription_RootSegment_User_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootSegment_User_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Segment_User_Stream_Cursor_Input>>;
  where?: InputMaybe<Segment_User_Bool_Exp>;
};


export type Subscription_RootService_Request_DashboardArgs = {
  distinct_on?: InputMaybe<Array<Service_Request_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Request_Dashboard_Order_By>>;
  where?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
};


export type Subscription_RootService_Request_Dashboard_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Request_Dashboard_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Request_Dashboard_Order_By>>;
  where?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
};


export type Subscription_RootService_Request_Dashboard_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootService_Request_Dashboard_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Service_Request_Dashboard_Stream_Cursor_Input>>;
  where?: InputMaybe<Service_Request_Dashboard_Bool_Exp>;
};


export type Subscription_RootService_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Service_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Requests_Order_By>>;
  where?: InputMaybe<Service_Requests_Bool_Exp>;
};


export type Subscription_RootService_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Requests_Order_By>>;
  where?: InputMaybe<Service_Requests_Bool_Exp>;
};


export type Subscription_RootService_Requests_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootService_Requests_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Service_Requests_Stream_Cursor_Input>>;
  where?: InputMaybe<Service_Requests_Bool_Exp>;
};


export type Subscription_RootSnapshotsArgs = {
  distinct_on?: InputMaybe<Array<Snapshots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Snapshots_Order_By>>;
  where?: InputMaybe<Snapshots_Bool_Exp>;
};


export type Subscription_RootSnapshots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Snapshots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Snapshots_Order_By>>;
  where?: InputMaybe<Snapshots_Bool_Exp>;
};


export type Subscription_RootSnapshots_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootSnapshots_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Snapshots_Stream_Cursor_Input>>;
  where?: InputMaybe<Snapshots_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_SysArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_Sys_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Spatial_Ref_Sys_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Spatial_Ref_Sys_Order_By>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootSpatial_Ref_Sys_By_PkArgs = {
  srid: Scalars['Int']['input'];
};


export type Subscription_RootSpatial_Ref_Sys_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Spatial_Ref_Sys_Stream_Cursor_Input>>;
  where?: InputMaybe<Spatial_Ref_Sys_Bool_Exp>;
};


export type Subscription_RootStored_EventsArgs = {
  distinct_on?: InputMaybe<Array<Stored_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stored_Events_Order_By>>;
  where?: InputMaybe<Stored_Events_Bool_Exp>;
};


export type Subscription_RootStored_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Stored_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Stored_Events_Order_By>>;
  where?: InputMaybe<Stored_Events_Bool_Exp>;
};


export type Subscription_RootStored_Events_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootStored_Events_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Stored_Events_Stream_Cursor_Input>>;
  where?: InputMaybe<Stored_Events_Bool_Exp>;
};


export type Subscription_RootTaggablesArgs = {
  distinct_on?: InputMaybe<Array<Taggables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taggables_Order_By>>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};


export type Subscription_RootTaggables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taggables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taggables_Order_By>>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};


export type Subscription_RootTaggables_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Taggables_Stream_Cursor_Input>>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};


export type Subscription_RootTagsArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Tags_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Tags_Order_By>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootTags_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootTags_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Tags_Stream_Cursor_Input>>;
  where?: InputMaybe<Tags_Bool_Exp>;
};


export type Subscription_RootUser_DeviceArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Device_Order_By>>;
  where?: InputMaybe<User_Device_Bool_Exp>;
};


export type Subscription_RootUser_Device_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Device_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Device_Order_By>>;
  where?: InputMaybe<User_Device_Bool_Exp>;
};


export type Subscription_RootUser_Device_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootUser_Device_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Device_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Device_Bool_Exp>;
};


export type Subscription_RootUser_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<User_Feedbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Feedbacks_Order_By>>;
  where?: InputMaybe<User_Feedbacks_Bool_Exp>;
};


export type Subscription_RootUser_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Feedbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Feedbacks_Order_By>>;
  where?: InputMaybe<User_Feedbacks_Bool_Exp>;
};


export type Subscription_RootUser_Feedbacks_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootUser_Feedbacks_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Feedbacks_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Feedbacks_Bool_Exp>;
};


export type Subscription_RootUser_Location_LogsArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Location_Logs_Order_By>>;
  where?: InputMaybe<User_Location_Logs_Bool_Exp>;
};


export type Subscription_RootUser_Location_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Location_Logs_Order_By>>;
  where?: InputMaybe<User_Location_Logs_Bool_Exp>;
};


export type Subscription_RootUser_Location_Logs_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootUser_Location_Logs_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<User_Location_Logs_Stream_Cursor_Input>>;
  where?: InputMaybe<User_Location_Logs_Bool_Exp>;
};


export type Subscription_RootUsersArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Users_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Users_Order_By>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootUsers_By_PkArgs = {
  id: Scalars['bpchar']['input'];
};


export type Subscription_RootUsers_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Users_Stream_Cursor_Input>>;
  where?: InputMaybe<Users_Bool_Exp>;
};


export type Subscription_RootWhatsauth_LoginsArgs = {
  distinct_on?: InputMaybe<Array<Whatsauth_Logins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Whatsauth_Logins_Order_By>>;
  where?: InputMaybe<Whatsauth_Logins_Bool_Exp>;
};


export type Subscription_RootWhatsauth_Logins_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Whatsauth_Logins_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Whatsauth_Logins_Order_By>>;
  where?: InputMaybe<Whatsauth_Logins_Bool_Exp>;
};


export type Subscription_RootWhatsauth_Logins_By_PkArgs = {
  id: Scalars['bigint']['input'];
};


export type Subscription_RootWhatsauth_Logins_StreamArgs = {
  batch_size: Scalars['Int']['input'];
  cursor: Array<InputMaybe<Whatsauth_Logins_Stream_Cursor_Input>>;
  where?: InputMaybe<Whatsauth_Logins_Bool_Exp>;
};

/** columns and relationships of "taggables" */
export type Taggables = {
  __typename?: 'taggables';
  id: Scalars['bigint']['output'];
  tag_id: Scalars['bigint']['output'];
  taggable_id: Scalars['bigint']['output'];
  taggable_type: Scalars['String']['output'];
  /** An object relationship */
  taggables_reel: Reels;
  /** An object relationship */
  taggables_tag: Tags;
};

/** aggregated selection of "taggables" */
export type Taggables_Aggregate = {
  __typename?: 'taggables_aggregate';
  aggregate?: Maybe<Taggables_Aggregate_Fields>;
  nodes: Array<Taggables>;
};

export type Taggables_Aggregate_Bool_Exp = {
  count?: InputMaybe<Taggables_Aggregate_Bool_Exp_Count>;
};

export type Taggables_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<Taggables_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<Taggables_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "taggables" */
export type Taggables_Aggregate_Fields = {
  __typename?: 'taggables_aggregate_fields';
  avg?: Maybe<Taggables_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Taggables_Max_Fields>;
  min?: Maybe<Taggables_Min_Fields>;
  stddev?: Maybe<Taggables_Stddev_Fields>;
  stddev_pop?: Maybe<Taggables_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Taggables_Stddev_Samp_Fields>;
  sum?: Maybe<Taggables_Sum_Fields>;
  var_pop?: Maybe<Taggables_Var_Pop_Fields>;
  var_samp?: Maybe<Taggables_Var_Samp_Fields>;
  variance?: Maybe<Taggables_Variance_Fields>;
};


/** aggregate fields of "taggables" */
export type Taggables_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Taggables_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "taggables" */
export type Taggables_Aggregate_Order_By = {
  avg?: InputMaybe<Taggables_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<Taggables_Max_Order_By>;
  min?: InputMaybe<Taggables_Min_Order_By>;
  stddev?: InputMaybe<Taggables_Stddev_Order_By>;
  stddev_pop?: InputMaybe<Taggables_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<Taggables_Stddev_Samp_Order_By>;
  sum?: InputMaybe<Taggables_Sum_Order_By>;
  var_pop?: InputMaybe<Taggables_Var_Pop_Order_By>;
  var_samp?: InputMaybe<Taggables_Var_Samp_Order_By>;
  variance?: InputMaybe<Taggables_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "taggables" */
export type Taggables_Arr_Rel_Insert_Input = {
  data: Array<Taggables_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<Taggables_On_Conflict>;
};

/** aggregate avg on columns */
export type Taggables_Avg_Fields = {
  __typename?: 'taggables_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  taggable_id?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "taggables" */
export type Taggables_Avg_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  taggable_id?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "taggables". All fields are combined with a logical 'AND'. */
export type Taggables_Bool_Exp = {
  _and?: InputMaybe<Array<Taggables_Bool_Exp>>;
  _not?: InputMaybe<Taggables_Bool_Exp>;
  _or?: InputMaybe<Array<Taggables_Bool_Exp>>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  tag_id?: InputMaybe<Bigint_Comparison_Exp>;
  taggable_id?: InputMaybe<Bigint_Comparison_Exp>;
  taggable_type?: InputMaybe<String_Comparison_Exp>;
  taggables_reel?: InputMaybe<Reels_Bool_Exp>;
  taggables_tag?: InputMaybe<Tags_Bool_Exp>;
};

/** unique or primary key constraints on table "taggables" */
export enum Taggables_Constraint {
  /** unique or primary key constraint on columns "taggable_type", "taggable_id", "tag_id" */
  TaggablesTagIdTaggableIdTaggableTypeUnique = 'taggables_tag_id_taggable_id_taggable_type_unique'
}

/** input type for incrementing numeric columns in table "taggables" */
export type Taggables_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  taggable_id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "taggables" */
export type Taggables_Insert_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  taggable_id?: InputMaybe<Scalars['bigint']['input']>;
  taggable_type?: InputMaybe<Scalars['String']['input']>;
  taggables_reel?: InputMaybe<Reels_Obj_Rel_Insert_Input>;
  taggables_tag?: InputMaybe<Tags_Obj_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Taggables_Max_Fields = {
  __typename?: 'taggables_max_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  taggable_id?: Maybe<Scalars['bigint']['output']>;
  taggable_type?: Maybe<Scalars['String']['output']>;
};

/** order by max() on columns of table "taggables" */
export type Taggables_Max_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  taggable_id?: InputMaybe<Order_By>;
  taggable_type?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type Taggables_Min_Fields = {
  __typename?: 'taggables_min_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  taggable_id?: Maybe<Scalars['bigint']['output']>;
  taggable_type?: Maybe<Scalars['String']['output']>;
};

/** order by min() on columns of table "taggables" */
export type Taggables_Min_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  taggable_id?: InputMaybe<Order_By>;
  taggable_type?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "taggables" */
export type Taggables_Mutation_Response = {
  __typename?: 'taggables_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Taggables>;
};

/** on_conflict condition type for table "taggables" */
export type Taggables_On_Conflict = {
  constraint: Taggables_Constraint;
  update_columns?: Array<Taggables_Update_Column>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};

/** Ordering options when selecting data from "taggables". */
export type Taggables_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  taggable_id?: InputMaybe<Order_By>;
  taggable_type?: InputMaybe<Order_By>;
  taggables_reel?: InputMaybe<Reels_Order_By>;
  taggables_tag?: InputMaybe<Tags_Order_By>;
};

/** select columns of table "taggables" */
export enum Taggables_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TaggableId = 'taggable_id',
  /** column name */
  TaggableType = 'taggable_type'
}

/** input type for updating data in table "taggables" */
export type Taggables_Set_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  taggable_id?: InputMaybe<Scalars['bigint']['input']>;
  taggable_type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Taggables_Stddev_Fields = {
  __typename?: 'taggables_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  taggable_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "taggables" */
export type Taggables_Stddev_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  taggable_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type Taggables_Stddev_Pop_Fields = {
  __typename?: 'taggables_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  taggable_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "taggables" */
export type Taggables_Stddev_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  taggable_id?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type Taggables_Stddev_Samp_Fields = {
  __typename?: 'taggables_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  taggable_id?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "taggables" */
export type Taggables_Stddev_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  taggable_id?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "taggables" */
export type Taggables_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Taggables_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Taggables_Stream_Cursor_Value_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  tag_id?: InputMaybe<Scalars['bigint']['input']>;
  taggable_id?: InputMaybe<Scalars['bigint']['input']>;
  taggable_type?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Taggables_Sum_Fields = {
  __typename?: 'taggables_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  tag_id?: Maybe<Scalars['bigint']['output']>;
  taggable_id?: Maybe<Scalars['bigint']['output']>;
};

/** order by sum() on columns of table "taggables" */
export type Taggables_Sum_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  taggable_id?: InputMaybe<Order_By>;
};

/** update columns of table "taggables" */
export enum Taggables_Update_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TaggableId = 'taggable_id',
  /** column name */
  TaggableType = 'taggable_type'
}

export type Taggables_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Taggables_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Taggables_Set_Input>;
  /** filter the rows which have to be updated */
  where: Taggables_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Taggables_Var_Pop_Fields = {
  __typename?: 'taggables_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  taggable_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "taggables" */
export type Taggables_Var_Pop_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  taggable_id?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type Taggables_Var_Samp_Fields = {
  __typename?: 'taggables_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  taggable_id?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "taggables" */
export type Taggables_Var_Samp_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  taggable_id?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type Taggables_Variance_Fields = {
  __typename?: 'taggables_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  tag_id?: Maybe<Scalars['Float']['output']>;
  taggable_id?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "taggables" */
export type Taggables_Variance_Order_By = {
  id?: InputMaybe<Order_By>;
  tag_id?: InputMaybe<Order_By>;
  taggable_id?: InputMaybe<Order_By>;
};

/** columns and relationships of "tags" */
export type Tags = {
  __typename?: 'tags';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id: Scalars['bigint']['output'];
  name: Scalars['json']['output'];
  order_column?: Maybe<Scalars['Int']['output']>;
  slug?: Maybe<Scalars['json']['output']>;
  /** An array relationship */
  tags_taggables: Array<Taggables>;
  /** An aggregate relationship */
  tags_taggables_aggregate: Taggables_Aggregate;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};


/** columns and relationships of "tags" */
export type TagsNameArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tags" */
export type TagsSlugArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "tags" */
export type TagsTags_TaggablesArgs = {
  distinct_on?: InputMaybe<Array<Taggables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taggables_Order_By>>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};


/** columns and relationships of "tags" */
export type TagsTags_Taggables_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Taggables_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Taggables_Order_By>>;
  where?: InputMaybe<Taggables_Bool_Exp>;
};

/** aggregated selection of "tags" */
export type Tags_Aggregate = {
  __typename?: 'tags_aggregate';
  aggregate?: Maybe<Tags_Aggregate_Fields>;
  nodes: Array<Tags>;
};

/** aggregate fields of "tags" */
export type Tags_Aggregate_Fields = {
  __typename?: 'tags_aggregate_fields';
  avg?: Maybe<Tags_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Tags_Max_Fields>;
  min?: Maybe<Tags_Min_Fields>;
  stddev?: Maybe<Tags_Stddev_Fields>;
  stddev_pop?: Maybe<Tags_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Tags_Stddev_Samp_Fields>;
  sum?: Maybe<Tags_Sum_Fields>;
  var_pop?: Maybe<Tags_Var_Pop_Fields>;
  var_samp?: Maybe<Tags_Var_Samp_Fields>;
  variance?: Maybe<Tags_Variance_Fields>;
};


/** aggregate fields of "tags" */
export type Tags_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Tags_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Tags_Avg_Fields = {
  __typename?: 'tags_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "tags". All fields are combined with a logical 'AND'. */
export type Tags_Bool_Exp = {
  _and?: InputMaybe<Array<Tags_Bool_Exp>>;
  _not?: InputMaybe<Tags_Bool_Exp>;
  _or?: InputMaybe<Array<Tags_Bool_Exp>>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  name?: InputMaybe<Json_Comparison_Exp>;
  order_column?: InputMaybe<Int_Comparison_Exp>;
  slug?: InputMaybe<Json_Comparison_Exp>;
  tags_taggables?: InputMaybe<Taggables_Bool_Exp>;
  tags_taggables_aggregate?: InputMaybe<Taggables_Aggregate_Bool_Exp>;
  type?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
};

/** unique or primary key constraints on table "tags" */
export enum Tags_Constraint {
  /** unique or primary key constraint on columns "id" */
  TagsPkey = 'tags_pkey'
}

/** input type for incrementing numeric columns in table "tags" */
export type Tags_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
  order_column?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "tags" */
export type Tags_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['json']['input']>;
  order_column?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['json']['input']>;
  tags_taggables?: InputMaybe<Taggables_Arr_Rel_Insert_Input>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate max on columns */
export type Tags_Max_Fields = {
  __typename?: 'tags_max_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  order_column?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** aggregate min on columns */
export type Tags_Min_Fields = {
  __typename?: 'tags_min_fields';
  created_at?: Maybe<Scalars['timestamp']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  order_column?: Maybe<Scalars['Int']['output']>;
  type?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
};

/** response of any mutation on the table "tags" */
export type Tags_Mutation_Response = {
  __typename?: 'tags_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Tags>;
};

/** input type for inserting object relation for remote table "tags" */
export type Tags_Obj_Rel_Insert_Input = {
  data: Tags_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Tags_On_Conflict>;
};

/** on_conflict condition type for table "tags" */
export type Tags_On_Conflict = {
  constraint: Tags_Constraint;
  update_columns?: Array<Tags_Update_Column>;
  where?: InputMaybe<Tags_Bool_Exp>;
};

/** Ordering options when selecting data from "tags". */
export type Tags_Order_By = {
  created_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  name?: InputMaybe<Order_By>;
  order_column?: InputMaybe<Order_By>;
  slug?: InputMaybe<Order_By>;
  tags_taggables_aggregate?: InputMaybe<Taggables_Aggregate_Order_By>;
  type?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
};

/** primary key columns input for table: tags */
export type Tags_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "tags" */
export enum Tags_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrderColumn = 'order_column',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "tags" */
export type Tags_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['json']['input']>;
  order_column?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['json']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate stddev on columns */
export type Tags_Stddev_Fields = {
  __typename?: 'tags_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Tags_Stddev_Pop_Fields = {
  __typename?: 'tags_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Tags_Stddev_Samp_Fields = {
  __typename?: 'tags_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "tags" */
export type Tags_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Tags_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Tags_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  name?: InputMaybe<Scalars['json']['input']>;
  order_column?: InputMaybe<Scalars['Int']['input']>;
  slug?: InputMaybe<Scalars['json']['input']>;
  type?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
};

/** aggregate sum on columns */
export type Tags_Sum_Fields = {
  __typename?: 'tags_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
  order_column?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "tags" */
export enum Tags_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  OrderColumn = 'order_column',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Tags_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Tags_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Tags_Set_Input>;
  /** filter the rows which have to be updated */
  where: Tags_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Tags_Var_Pop_Fields = {
  __typename?: 'tags_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Tags_Var_Samp_Fields = {
  __typename?: 'tags_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Tags_Variance_Fields = {
  __typename?: 'tags_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
  order_column?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type Timestamp_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamp']['input']>;
  _gt?: InputMaybe<Scalars['timestamp']['input']>;
  _gte?: InputMaybe<Scalars['timestamp']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamp']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamp']['input']>;
  _lte?: InputMaybe<Scalars['timestamp']['input']>;
  _neq?: InputMaybe<Scalars['timestamp']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamp']['input']>>;
};

/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type Timestamptz_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['timestamptz']['input']>;
  _gt?: InputMaybe<Scalars['timestamptz']['input']>;
  _gte?: InputMaybe<Scalars['timestamptz']['input']>;
  _in?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['timestamptz']['input']>;
  _lte?: InputMaybe<Scalars['timestamptz']['input']>;
  _neq?: InputMaybe<Scalars['timestamptz']['input']>;
  _nin?: InputMaybe<Array<Scalars['timestamptz']['input']>>;
};

/** columns and relationships of "user_device" */
export type User_Device = {
  __typename?: 'user_device';
  app_build_number?: Maybe<Scalars['String']['output']>;
  app_version?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  device_brand?: Maybe<Scalars['String']['output']>;
  device_installation_id?: Maybe<Scalars['String']['output']>;
  device_manufacturer?: Maybe<Scalars['String']['output']>;
  device_model?: Maybe<Scalars['String']['output']>;
  device_name?: Maybe<Scalars['String']['output']>;
  device_os?: Maybe<Scalars['String']['output']>;
  device_os_version?: Maybe<Scalars['String']['output']>;
  device_token?: Maybe<Scalars['String']['output']>;
  device_type?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  native_push_token?: Maybe<Scalars['String']['output']>;
  release_channel?: Maybe<Scalars['String']['output']>;
  runtime_version?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id: Scalars['bpchar']['output'];
};

/** aggregated selection of "user_device" */
export type User_Device_Aggregate = {
  __typename?: 'user_device_aggregate';
  aggregate?: Maybe<User_Device_Aggregate_Fields>;
  nodes: Array<User_Device>;
};

/** aggregate fields of "user_device" */
export type User_Device_Aggregate_Fields = {
  __typename?: 'user_device_aggregate_fields';
  avg?: Maybe<User_Device_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Device_Max_Fields>;
  min?: Maybe<User_Device_Min_Fields>;
  stddev?: Maybe<User_Device_Stddev_Fields>;
  stddev_pop?: Maybe<User_Device_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Device_Stddev_Samp_Fields>;
  sum?: Maybe<User_Device_Sum_Fields>;
  var_pop?: Maybe<User_Device_Var_Pop_Fields>;
  var_samp?: Maybe<User_Device_Var_Samp_Fields>;
  variance?: Maybe<User_Device_Variance_Fields>;
};


/** aggregate fields of "user_device" */
export type User_Device_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Device_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type User_Device_Avg_Fields = {
  __typename?: 'user_device_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "user_device". All fields are combined with a logical 'AND'. */
export type User_Device_Bool_Exp = {
  _and?: InputMaybe<Array<User_Device_Bool_Exp>>;
  _not?: InputMaybe<User_Device_Bool_Exp>;
  _or?: InputMaybe<Array<User_Device_Bool_Exp>>;
  app_build_number?: InputMaybe<String_Comparison_Exp>;
  app_version?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  device_brand?: InputMaybe<String_Comparison_Exp>;
  device_installation_id?: InputMaybe<String_Comparison_Exp>;
  device_manufacturer?: InputMaybe<String_Comparison_Exp>;
  device_model?: InputMaybe<String_Comparison_Exp>;
  device_name?: InputMaybe<String_Comparison_Exp>;
  device_os?: InputMaybe<String_Comparison_Exp>;
  device_os_version?: InputMaybe<String_Comparison_Exp>;
  device_token?: InputMaybe<String_Comparison_Exp>;
  device_type?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  native_push_token?: InputMaybe<String_Comparison_Exp>;
  release_channel?: InputMaybe<String_Comparison_Exp>;
  runtime_version?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_device" */
export enum User_Device_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserDevicePkey = 'user_device_pkey'
}

/** input type for incrementing numeric columns in table "user_device" */
export type User_Device_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "user_device" */
export type User_Device_Insert_Input = {
  app_build_number?: InputMaybe<Scalars['String']['input']>;
  app_version?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_brand?: InputMaybe<Scalars['String']['input']>;
  device_installation_id?: InputMaybe<Scalars['String']['input']>;
  device_manufacturer?: InputMaybe<Scalars['String']['input']>;
  device_model?: InputMaybe<Scalars['String']['input']>;
  device_name?: InputMaybe<Scalars['String']['input']>;
  device_os?: InputMaybe<Scalars['String']['input']>;
  device_os_version?: InputMaybe<Scalars['String']['input']>;
  device_token?: InputMaybe<Scalars['String']['input']>;
  device_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  native_push_token?: InputMaybe<Scalars['String']['input']>;
  release_channel?: InputMaybe<Scalars['String']['input']>;
  runtime_version?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type User_Device_Max_Fields = {
  __typename?: 'user_device_max_fields';
  app_build_number?: Maybe<Scalars['String']['output']>;
  app_version?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  device_brand?: Maybe<Scalars['String']['output']>;
  device_installation_id?: Maybe<Scalars['String']['output']>;
  device_manufacturer?: Maybe<Scalars['String']['output']>;
  device_model?: Maybe<Scalars['String']['output']>;
  device_name?: Maybe<Scalars['String']['output']>;
  device_os?: Maybe<Scalars['String']['output']>;
  device_os_version?: Maybe<Scalars['String']['output']>;
  device_token?: Maybe<Scalars['String']['output']>;
  device_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  native_push_token?: Maybe<Scalars['String']['output']>;
  release_channel?: Maybe<Scalars['String']['output']>;
  runtime_version?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** aggregate min on columns */
export type User_Device_Min_Fields = {
  __typename?: 'user_device_min_fields';
  app_build_number?: Maybe<Scalars['String']['output']>;
  app_version?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  device_brand?: Maybe<Scalars['String']['output']>;
  device_installation_id?: Maybe<Scalars['String']['output']>;
  device_manufacturer?: Maybe<Scalars['String']['output']>;
  device_model?: Maybe<Scalars['String']['output']>;
  device_name?: Maybe<Scalars['String']['output']>;
  device_os?: Maybe<Scalars['String']['output']>;
  device_os_version?: Maybe<Scalars['String']['output']>;
  device_token?: Maybe<Scalars['String']['output']>;
  device_type?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  native_push_token?: Maybe<Scalars['String']['output']>;
  release_channel?: Maybe<Scalars['String']['output']>;
  runtime_version?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** response of any mutation on the table "user_device" */
export type User_Device_Mutation_Response = {
  __typename?: 'user_device_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Device>;
};

/** on_conflict condition type for table "user_device" */
export type User_Device_On_Conflict = {
  constraint: User_Device_Constraint;
  update_columns?: Array<User_Device_Update_Column>;
  where?: InputMaybe<User_Device_Bool_Exp>;
};

/** Ordering options when selecting data from "user_device". */
export type User_Device_Order_By = {
  app_build_number?: InputMaybe<Order_By>;
  app_version?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  device_brand?: InputMaybe<Order_By>;
  device_installation_id?: InputMaybe<Order_By>;
  device_manufacturer?: InputMaybe<Order_By>;
  device_model?: InputMaybe<Order_By>;
  device_name?: InputMaybe<Order_By>;
  device_os?: InputMaybe<Order_By>;
  device_os_version?: InputMaybe<Order_By>;
  device_token?: InputMaybe<Order_By>;
  device_type?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  native_push_token?: InputMaybe<Order_By>;
  release_channel?: InputMaybe<Order_By>;
  runtime_version?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_device */
export type User_Device_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "user_device" */
export enum User_Device_Select_Column {
  /** column name */
  AppBuildNumber = 'app_build_number',
  /** column name */
  AppVersion = 'app_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceBrand = 'device_brand',
  /** column name */
  DeviceInstallationId = 'device_installation_id',
  /** column name */
  DeviceManufacturer = 'device_manufacturer',
  /** column name */
  DeviceModel = 'device_model',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  DeviceOs = 'device_os',
  /** column name */
  DeviceOsVersion = 'device_os_version',
  /** column name */
  DeviceToken = 'device_token',
  /** column name */
  DeviceType = 'device_type',
  /** column name */
  Id = 'id',
  /** column name */
  NativePushToken = 'native_push_token',
  /** column name */
  ReleaseChannel = 'release_channel',
  /** column name */
  RuntimeVersion = 'runtime_version',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_device" */
export type User_Device_Set_Input = {
  app_build_number?: InputMaybe<Scalars['String']['input']>;
  app_version?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_brand?: InputMaybe<Scalars['String']['input']>;
  device_installation_id?: InputMaybe<Scalars['String']['input']>;
  device_manufacturer?: InputMaybe<Scalars['String']['input']>;
  device_model?: InputMaybe<Scalars['String']['input']>;
  device_name?: InputMaybe<Scalars['String']['input']>;
  device_os?: InputMaybe<Scalars['String']['input']>;
  device_os_version?: InputMaybe<Scalars['String']['input']>;
  device_token?: InputMaybe<Scalars['String']['input']>;
  device_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  native_push_token?: InputMaybe<Scalars['String']['input']>;
  release_channel?: InputMaybe<Scalars['String']['input']>;
  runtime_version?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type User_Device_Stddev_Fields = {
  __typename?: 'user_device_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type User_Device_Stddev_Pop_Fields = {
  __typename?: 'user_device_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type User_Device_Stddev_Samp_Fields = {
  __typename?: 'user_device_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "user_device" */
export type User_Device_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Device_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Device_Stream_Cursor_Value_Input = {
  app_build_number?: InputMaybe<Scalars['String']['input']>;
  app_version?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  device_brand?: InputMaybe<Scalars['String']['input']>;
  device_installation_id?: InputMaybe<Scalars['String']['input']>;
  device_manufacturer?: InputMaybe<Scalars['String']['input']>;
  device_model?: InputMaybe<Scalars['String']['input']>;
  device_name?: InputMaybe<Scalars['String']['input']>;
  device_os?: InputMaybe<Scalars['String']['input']>;
  device_os_version?: InputMaybe<Scalars['String']['input']>;
  device_token?: InputMaybe<Scalars['String']['input']>;
  device_type?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  native_push_token?: InputMaybe<Scalars['String']['input']>;
  release_channel?: InputMaybe<Scalars['String']['input']>;
  runtime_version?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type User_Device_Sum_Fields = {
  __typename?: 'user_device_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "user_device" */
export enum User_Device_Update_Column {
  /** column name */
  AppBuildNumber = 'app_build_number',
  /** column name */
  AppVersion = 'app_version',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  DeviceBrand = 'device_brand',
  /** column name */
  DeviceInstallationId = 'device_installation_id',
  /** column name */
  DeviceManufacturer = 'device_manufacturer',
  /** column name */
  DeviceModel = 'device_model',
  /** column name */
  DeviceName = 'device_name',
  /** column name */
  DeviceOs = 'device_os',
  /** column name */
  DeviceOsVersion = 'device_os_version',
  /** column name */
  DeviceToken = 'device_token',
  /** column name */
  DeviceType = 'device_type',
  /** column name */
  Id = 'id',
  /** column name */
  NativePushToken = 'native_push_token',
  /** column name */
  ReleaseChannel = 'release_channel',
  /** column name */
  RuntimeVersion = 'runtime_version',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Device_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Device_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Device_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Device_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Device_Var_Pop_Fields = {
  __typename?: 'user_device_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type User_Device_Var_Samp_Fields = {
  __typename?: 'user_device_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type User_Device_Variance_Fields = {
  __typename?: 'user_device_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** columns and relationships of "user_feedbacks" */
export type User_Feedbacks = {
  __typename?: 'user_feedbacks';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  feedback: Scalars['String']['output'];
  from_user_id?: Maybe<Scalars['bpchar']['output']>;
  id: Scalars['bpchar']['output'];
  rating: Scalars['Int']['output'];
  /** An object relationship */
  service_request: Service_Requests;
  service_request_id: Scalars['bpchar']['output'];
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['bpchar']['output'];
};

/** aggregated selection of "user_feedbacks" */
export type User_Feedbacks_Aggregate = {
  __typename?: 'user_feedbacks_aggregate';
  aggregate?: Maybe<User_Feedbacks_Aggregate_Fields>;
  nodes: Array<User_Feedbacks>;
};

export type User_Feedbacks_Aggregate_Bool_Exp = {
  count?: InputMaybe<User_Feedbacks_Aggregate_Bool_Exp_Count>;
};

export type User_Feedbacks_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Feedbacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Feedbacks_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

/** aggregate fields of "user_feedbacks" */
export type User_Feedbacks_Aggregate_Fields = {
  __typename?: 'user_feedbacks_aggregate_fields';
  avg?: Maybe<User_Feedbacks_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Feedbacks_Max_Fields>;
  min?: Maybe<User_Feedbacks_Min_Fields>;
  stddev?: Maybe<User_Feedbacks_Stddev_Fields>;
  stddev_pop?: Maybe<User_Feedbacks_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Feedbacks_Stddev_Samp_Fields>;
  sum?: Maybe<User_Feedbacks_Sum_Fields>;
  var_pop?: Maybe<User_Feedbacks_Var_Pop_Fields>;
  var_samp?: Maybe<User_Feedbacks_Var_Samp_Fields>;
  variance?: Maybe<User_Feedbacks_Variance_Fields>;
};


/** aggregate fields of "user_feedbacks" */
export type User_Feedbacks_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Feedbacks_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_feedbacks" */
export type User_Feedbacks_Aggregate_Order_By = {
  avg?: InputMaybe<User_Feedbacks_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Feedbacks_Max_Order_By>;
  min?: InputMaybe<User_Feedbacks_Min_Order_By>;
  stddev?: InputMaybe<User_Feedbacks_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Feedbacks_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Feedbacks_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Feedbacks_Sum_Order_By>;
  var_pop?: InputMaybe<User_Feedbacks_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Feedbacks_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Feedbacks_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_feedbacks" */
export type User_Feedbacks_Arr_Rel_Insert_Input = {
  data: Array<User_Feedbacks_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Feedbacks_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Feedbacks_Avg_Fields = {
  __typename?: 'user_feedbacks_avg_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_feedbacks" */
export type User_Feedbacks_Avg_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_feedbacks". All fields are combined with a logical 'AND'. */
export type User_Feedbacks_Bool_Exp = {
  _and?: InputMaybe<Array<User_Feedbacks_Bool_Exp>>;
  _not?: InputMaybe<User_Feedbacks_Bool_Exp>;
  _or?: InputMaybe<Array<User_Feedbacks_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  feedback?: InputMaybe<String_Comparison_Exp>;
  from_user_id?: InputMaybe<Bpchar_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  rating?: InputMaybe<Int_Comparison_Exp>;
  service_request?: InputMaybe<Service_Requests_Bool_Exp>;
  service_request_id?: InputMaybe<Bpchar_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_feedbacks" */
export enum User_Feedbacks_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserFeedbacksPkey = 'user_feedbacks_pkey'
}

/** input type for incrementing numeric columns in table "user_feedbacks" */
export type User_Feedbacks_Inc_Input = {
  rating?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "user_feedbacks" */
export type User_Feedbacks_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  from_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  service_request?: InputMaybe<Service_Requests_Obj_Rel_Insert_Input>;
  service_request_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type User_Feedbacks_Max_Fields = {
  __typename?: 'user_feedbacks_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  from_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  service_request_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by max() on columns of table "user_feedbacks" */
export type User_Feedbacks_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  service_request_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Feedbacks_Min_Fields = {
  __typename?: 'user_feedbacks_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  feedback?: Maybe<Scalars['String']['output']>;
  from_user_id?: Maybe<Scalars['bpchar']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  rating?: Maybe<Scalars['Int']['output']>;
  service_request_id?: Maybe<Scalars['bpchar']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by min() on columns of table "user_feedbacks" */
export type User_Feedbacks_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  service_request_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_feedbacks" */
export type User_Feedbacks_Mutation_Response = {
  __typename?: 'user_feedbacks_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Feedbacks>;
};

/** on_conflict condition type for table "user_feedbacks" */
export type User_Feedbacks_On_Conflict = {
  constraint: User_Feedbacks_Constraint;
  update_columns?: Array<User_Feedbacks_Update_Column>;
  where?: InputMaybe<User_Feedbacks_Bool_Exp>;
};

/** Ordering options when selecting data from "user_feedbacks". */
export type User_Feedbacks_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  feedback?: InputMaybe<Order_By>;
  from_user_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  rating?: InputMaybe<Order_By>;
  service_request?: InputMaybe<Service_Requests_Order_By>;
  service_request_id?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_feedbacks */
export type User_Feedbacks_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "user_feedbacks" */
export enum User_Feedbacks_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  ServiceRequestId = 'service_request_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** input type for updating data in table "user_feedbacks" */
export type User_Feedbacks_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  from_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  service_request_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type User_Feedbacks_Stddev_Fields = {
  __typename?: 'user_feedbacks_stddev_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_feedbacks" */
export type User_Feedbacks_Stddev_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Feedbacks_Stddev_Pop_Fields = {
  __typename?: 'user_feedbacks_stddev_pop_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_feedbacks" */
export type User_Feedbacks_Stddev_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Feedbacks_Stddev_Samp_Fields = {
  __typename?: 'user_feedbacks_stddev_samp_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_feedbacks" */
export type User_Feedbacks_Stddev_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_feedbacks" */
export type User_Feedbacks_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Feedbacks_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Feedbacks_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  feedback?: InputMaybe<Scalars['String']['input']>;
  from_user_id?: InputMaybe<Scalars['bpchar']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  rating?: InputMaybe<Scalars['Int']['input']>;
  service_request_id?: InputMaybe<Scalars['bpchar']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type User_Feedbacks_Sum_Fields = {
  __typename?: 'user_feedbacks_sum_fields';
  rating?: Maybe<Scalars['Int']['output']>;
};

/** order by sum() on columns of table "user_feedbacks" */
export type User_Feedbacks_Sum_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** update columns of table "user_feedbacks" */
export enum User_Feedbacks_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Feedback = 'feedback',
  /** column name */
  FromUserId = 'from_user_id',
  /** column name */
  Id = 'id',
  /** column name */
  Rating = 'rating',
  /** column name */
  ServiceRequestId = 'service_request_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Feedbacks_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Feedbacks_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Feedbacks_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Feedbacks_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Feedbacks_Var_Pop_Fields = {
  __typename?: 'user_feedbacks_var_pop_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_feedbacks" */
export type User_Feedbacks_Var_Pop_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Feedbacks_Var_Samp_Fields = {
  __typename?: 'user_feedbacks_var_samp_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_feedbacks" */
export type User_Feedbacks_Var_Samp_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Feedbacks_Variance_Fields = {
  __typename?: 'user_feedbacks_variance_fields';
  rating?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_feedbacks" */
export type User_Feedbacks_Variance_Order_By = {
  rating?: InputMaybe<Order_By>;
};

/** columns and relationships of "user_location_logs" */
export type User_Location_Logs = {
  __typename?: 'user_location_logs';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id: Scalars['bpchar']['output'];
  lat: Scalars['float8']['output'];
  lng: Scalars['float8']['output'];
  location?: Maybe<Scalars['geography']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An object relationship */
  user: Users;
  user_id: Scalars['bpchar']['output'];
};

/** aggregated selection of "user_location_logs" */
export type User_Location_Logs_Aggregate = {
  __typename?: 'user_location_logs_aggregate';
  aggregate?: Maybe<User_Location_Logs_Aggregate_Fields>;
  nodes: Array<User_Location_Logs>;
};

export type User_Location_Logs_Aggregate_Bool_Exp = {
  avg?: InputMaybe<User_Location_Logs_Aggregate_Bool_Exp_Avg>;
  corr?: InputMaybe<User_Location_Logs_Aggregate_Bool_Exp_Corr>;
  count?: InputMaybe<User_Location_Logs_Aggregate_Bool_Exp_Count>;
  covar_samp?: InputMaybe<User_Location_Logs_Aggregate_Bool_Exp_Covar_Samp>;
  max?: InputMaybe<User_Location_Logs_Aggregate_Bool_Exp_Max>;
  min?: InputMaybe<User_Location_Logs_Aggregate_Bool_Exp_Min>;
  stddev_samp?: InputMaybe<User_Location_Logs_Aggregate_Bool_Exp_Stddev_Samp>;
  sum?: InputMaybe<User_Location_Logs_Aggregate_Bool_Exp_Sum>;
  var_samp?: InputMaybe<User_Location_Logs_Aggregate_Bool_Exp_Var_Samp>;
};

export type User_Location_Logs_Aggregate_Bool_Exp_Avg = {
  arguments: User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Avg_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Location_Logs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Location_Logs_Aggregate_Bool_Exp_Corr = {
  arguments: User_Location_Logs_Aggregate_Bool_Exp_Corr_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Location_Logs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Location_Logs_Aggregate_Bool_Exp_Corr_Arguments = {
  X: User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
  Y: User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Corr_Arguments_Columns;
};

export type User_Location_Logs_Aggregate_Bool_Exp_Count = {
  arguments?: InputMaybe<Array<User_Location_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Location_Logs_Bool_Exp>;
  predicate: Int_Comparison_Exp;
};

export type User_Location_Logs_Aggregate_Bool_Exp_Covar_Samp = {
  arguments: User_Location_Logs_Aggregate_Bool_Exp_Covar_Samp_Arguments;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Location_Logs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Location_Logs_Aggregate_Bool_Exp_Covar_Samp_Arguments = {
  X: User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
  Y: User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns;
};

export type User_Location_Logs_Aggregate_Bool_Exp_Max = {
  arguments: User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Max_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Location_Logs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Location_Logs_Aggregate_Bool_Exp_Min = {
  arguments: User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Min_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Location_Logs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Location_Logs_Aggregate_Bool_Exp_Stddev_Samp = {
  arguments: User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Location_Logs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Location_Logs_Aggregate_Bool_Exp_Sum = {
  arguments: User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Sum_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Location_Logs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

export type User_Location_Logs_Aggregate_Bool_Exp_Var_Samp = {
  arguments: User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
  filter?: InputMaybe<User_Location_Logs_Bool_Exp>;
  predicate: Float8_Comparison_Exp;
};

/** aggregate fields of "user_location_logs" */
export type User_Location_Logs_Aggregate_Fields = {
  __typename?: 'user_location_logs_aggregate_fields';
  avg?: Maybe<User_Location_Logs_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<User_Location_Logs_Max_Fields>;
  min?: Maybe<User_Location_Logs_Min_Fields>;
  stddev?: Maybe<User_Location_Logs_Stddev_Fields>;
  stddev_pop?: Maybe<User_Location_Logs_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<User_Location_Logs_Stddev_Samp_Fields>;
  sum?: Maybe<User_Location_Logs_Sum_Fields>;
  var_pop?: Maybe<User_Location_Logs_Var_Pop_Fields>;
  var_samp?: Maybe<User_Location_Logs_Var_Samp_Fields>;
  variance?: Maybe<User_Location_Logs_Variance_Fields>;
};


/** aggregate fields of "user_location_logs" */
export type User_Location_Logs_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<User_Location_Logs_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** order by aggregate values of table "user_location_logs" */
export type User_Location_Logs_Aggregate_Order_By = {
  avg?: InputMaybe<User_Location_Logs_Avg_Order_By>;
  count?: InputMaybe<Order_By>;
  max?: InputMaybe<User_Location_Logs_Max_Order_By>;
  min?: InputMaybe<User_Location_Logs_Min_Order_By>;
  stddev?: InputMaybe<User_Location_Logs_Stddev_Order_By>;
  stddev_pop?: InputMaybe<User_Location_Logs_Stddev_Pop_Order_By>;
  stddev_samp?: InputMaybe<User_Location_Logs_Stddev_Samp_Order_By>;
  sum?: InputMaybe<User_Location_Logs_Sum_Order_By>;
  var_pop?: InputMaybe<User_Location_Logs_Var_Pop_Order_By>;
  var_samp?: InputMaybe<User_Location_Logs_Var_Samp_Order_By>;
  variance?: InputMaybe<User_Location_Logs_Variance_Order_By>;
};

/** input type for inserting array relation for remote table "user_location_logs" */
export type User_Location_Logs_Arr_Rel_Insert_Input = {
  data: Array<User_Location_Logs_Insert_Input>;
  /** upsert condition */
  on_conflict?: InputMaybe<User_Location_Logs_On_Conflict>;
};

/** aggregate avg on columns */
export type User_Location_Logs_Avg_Fields = {
  __typename?: 'user_location_logs_avg_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by avg() on columns of table "user_location_logs" */
export type User_Location_Logs_Avg_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** Boolean expression to filter rows from the table "user_location_logs". All fields are combined with a logical 'AND'. */
export type User_Location_Logs_Bool_Exp = {
  _and?: InputMaybe<Array<User_Location_Logs_Bool_Exp>>;
  _not?: InputMaybe<User_Location_Logs_Bool_Exp>;
  _or?: InputMaybe<Array<User_Location_Logs_Bool_Exp>>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  lat?: InputMaybe<Float8_Comparison_Exp>;
  lng?: InputMaybe<Float8_Comparison_Exp>;
  location?: InputMaybe<Geography_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user?: InputMaybe<Users_Bool_Exp>;
  user_id?: InputMaybe<Bpchar_Comparison_Exp>;
};

/** unique or primary key constraints on table "user_location_logs" */
export enum User_Location_Logs_Constraint {
  /** unique or primary key constraint on columns "id" */
  UserLocationLogsPkey = 'user_location_logs_pkey'
}

/** input type for incrementing numeric columns in table "user_location_logs" */
export type User_Location_Logs_Inc_Input = {
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
};

/** input type for inserting data into table "user_location_logs" */
export type User_Location_Logs_Insert_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user?: InputMaybe<Users_Obj_Rel_Insert_Input>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate max on columns */
export type User_Location_Logs_Max_Fields = {
  __typename?: 'user_location_logs_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by max() on columns of table "user_location_logs" */
export type User_Location_Logs_Max_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** aggregate min on columns */
export type User_Location_Logs_Min_Fields = {
  __typename?: 'user_location_logs_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  user_id?: Maybe<Scalars['bpchar']['output']>;
};

/** order by min() on columns of table "user_location_logs" */
export type User_Location_Logs_Min_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** response of any mutation on the table "user_location_logs" */
export type User_Location_Logs_Mutation_Response = {
  __typename?: 'user_location_logs_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<User_Location_Logs>;
};

/** on_conflict condition type for table "user_location_logs" */
export type User_Location_Logs_On_Conflict = {
  constraint: User_Location_Logs_Constraint;
  update_columns?: Array<User_Location_Logs_Update_Column>;
  where?: InputMaybe<User_Location_Logs_Bool_Exp>;
};

/** Ordering options when selecting data from "user_location_logs". */
export type User_Location_Logs_Order_By = {
  created_at?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
  location?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user?: InputMaybe<Users_Order_By>;
  user_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: user_location_logs */
export type User_Location_Logs_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "user_location_logs" */
export enum User_Location_Logs_Select_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Location = 'location',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** select "user_location_logs_aggregate_bool_exp_avg_arguments_columns" columns of table "user_location_logs" */
export enum User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Avg_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "user_location_logs_aggregate_bool_exp_corr_arguments_columns" columns of table "user_location_logs" */
export enum User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Corr_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "user_location_logs_aggregate_bool_exp_covar_samp_arguments_columns" columns of table "user_location_logs" */
export enum User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Covar_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "user_location_logs_aggregate_bool_exp_max_arguments_columns" columns of table "user_location_logs" */
export enum User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Max_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "user_location_logs_aggregate_bool_exp_min_arguments_columns" columns of table "user_location_logs" */
export enum User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Min_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "user_location_logs_aggregate_bool_exp_stddev_samp_arguments_columns" columns of table "user_location_logs" */
export enum User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Stddev_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "user_location_logs_aggregate_bool_exp_sum_arguments_columns" columns of table "user_location_logs" */
export enum User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Sum_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** select "user_location_logs_aggregate_bool_exp_var_samp_arguments_columns" columns of table "user_location_logs" */
export enum User_Location_Logs_Select_Column_User_Location_Logs_Aggregate_Bool_Exp_Var_Samp_Arguments_Columns {
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng'
}

/** input type for updating data in table "user_location_logs" */
export type User_Location_Logs_Set_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate stddev on columns */
export type User_Location_Logs_Stddev_Fields = {
  __typename?: 'user_location_logs_stddev_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev() on columns of table "user_location_logs" */
export type User_Location_Logs_Stddev_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate stddev_pop on columns */
export type User_Location_Logs_Stddev_Pop_Fields = {
  __typename?: 'user_location_logs_stddev_pop_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_pop() on columns of table "user_location_logs" */
export type User_Location_Logs_Stddev_Pop_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate stddev_samp on columns */
export type User_Location_Logs_Stddev_Samp_Fields = {
  __typename?: 'user_location_logs_stddev_samp_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by stddev_samp() on columns of table "user_location_logs" */
export type User_Location_Logs_Stddev_Samp_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** Streaming cursor of the table "user_location_logs" */
export type User_Location_Logs_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: User_Location_Logs_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type User_Location_Logs_Stream_Cursor_Value_Input = {
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
  location?: InputMaybe<Scalars['geography']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_id?: InputMaybe<Scalars['bpchar']['input']>;
};

/** aggregate sum on columns */
export type User_Location_Logs_Sum_Fields = {
  __typename?: 'user_location_logs_sum_fields';
  lat?: Maybe<Scalars['float8']['output']>;
  lng?: Maybe<Scalars['float8']['output']>;
};

/** order by sum() on columns of table "user_location_logs" */
export type User_Location_Logs_Sum_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** update columns of table "user_location_logs" */
export enum User_Location_Logs_Update_Column {
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Id = 'id',
  /** column name */
  Lat = 'lat',
  /** column name */
  Lng = 'lng',
  /** column name */
  Location = 'location',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

export type User_Location_Logs_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<User_Location_Logs_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<User_Location_Logs_Set_Input>;
  /** filter the rows which have to be updated */
  where: User_Location_Logs_Bool_Exp;
};

/** aggregate var_pop on columns */
export type User_Location_Logs_Var_Pop_Fields = {
  __typename?: 'user_location_logs_var_pop_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by var_pop() on columns of table "user_location_logs" */
export type User_Location_Logs_Var_Pop_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate var_samp on columns */
export type User_Location_Logs_Var_Samp_Fields = {
  __typename?: 'user_location_logs_var_samp_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by var_samp() on columns of table "user_location_logs" */
export type User_Location_Logs_Var_Samp_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** aggregate variance on columns */
export type User_Location_Logs_Variance_Fields = {
  __typename?: 'user_location_logs_variance_fields';
  lat?: Maybe<Scalars['Float']['output']>;
  lng?: Maybe<Scalars['Float']['output']>;
};

/** order by variance() on columns of table "user_location_logs" */
export type User_Location_Logs_Variance_Order_By = {
  lat?: InputMaybe<Order_By>;
  lng?: InputMaybe<Order_By>;
};

/** columns and relationships of "users" */
export type Users = {
  __typename?: 'users';
  /** An array relationship */
  account_users: Array<Account_User>;
  /** An aggregate relationship */
  account_users_aggregate: Account_User_Aggregate;
  /** An array relationship */
  accounts: Array<Accounts>;
  /** An aggregate relationship */
  accounts_aggregate: Accounts_Aggregate;
  /** An array relationship */
  companies: Array<Companies>;
  /** An aggregate relationship */
  companies_aggregate: Companies_Aggregate;
  content_languages?: Maybe<Scalars['json']['output']>;
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  crop_users: Array<Crop_User>;
  /** An aggregate relationship */
  crop_users_aggregate: Crop_User_Aggregate;
  date_of_birth?: Maybe<Scalars['String']['output']>;
  default_role?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_verified_at?: Maybe<Scalars['timestamp']['output']>;
  /** An array relationship */
  farmer_plots: Array<Farmer_Plots>;
  /** An aggregate relationship */
  farmer_plots_aggregate: Farmer_Plots_Aggregate;
  /** An array relationship */
  fieldPartnerResponseEventsByFieldPartnerUserId: Array<Field_Partner_Response_Events>;
  /** An aggregate relationship */
  fieldPartnerResponseEventsByFieldPartnerUserId_aggregate: Field_Partner_Response_Events_Aggregate;
  /** An array relationship */
  fieldPartnerResponseMessagesByFieldPartnerUserId: Array<Field_Partner_Response_Messages>;
  /** An aggregate relationship */
  fieldPartnerResponseMessagesByFieldPartnerUserId_aggregate: Field_Partner_Response_Messages_Aggregate;
  /** An array relationship */
  fieldPartnerResponseMessagesByFromUserId: Array<Field_Partner_Response_Messages>;
  /** An aggregate relationship */
  fieldPartnerResponseMessagesByFromUserId_aggregate: Field_Partner_Response_Messages_Aggregate;
  /** An array relationship */
  fieldPartnerResponseMessagesByToUserId: Array<Field_Partner_Response_Messages>;
  /** An aggregate relationship */
  fieldPartnerResponseMessagesByToUserId_aggregate: Field_Partner_Response_Messages_Aggregate;
  /** An array relationship */
  fieldPartnerResponsesByFieldPartnerUserId: Array<Field_Partner_Responses>;
  /** An aggregate relationship */
  fieldPartnerResponsesByFieldPartnerUserId_aggregate: Field_Partner_Responses_Aggregate;
  /** An array relationship */
  fieldScheduledVisitsByFieldPartnerUserId: Array<Field_Scheduled_Visits>;
  /** An aggregate relationship */
  fieldScheduledVisitsByFieldPartnerUserId_aggregate: Field_Scheduled_Visits_Aggregate;
  /** An array relationship */
  field_partner_response_events: Array<Field_Partner_Response_Events>;
  /** An aggregate relationship */
  field_partner_response_events_aggregate: Field_Partner_Response_Events_Aggregate;
  /** An array relationship */
  field_partner_response_messages: Array<Field_Partner_Response_Messages>;
  /** An aggregate relationship */
  field_partner_response_messages_aggregate: Field_Partner_Response_Messages_Aggregate;
  /** An array relationship */
  field_partner_responses: Array<Field_Partner_Responses>;
  /** An aggregate relationship */
  field_partner_responses_aggregate: Field_Partner_Responses_Aggregate;
  /** An array relationship */
  field_scheduled_visits: Array<Field_Scheduled_Visits>;
  /** An aggregate relationship */
  field_scheduled_visits_aggregate: Field_Scheduled_Visits_Aggregate;
  follower_count: Scalars['Int']['output'];
  gender?: Maybe<Scalars['String']['output']>;
  id: Scalars['bpchar']['output'];
  is_test?: Maybe<Scalars['Boolean']['output']>;
  /** An array relationship */
  lastKnownLocationsByRequestFarmerId: Array<Last_Known_Locations>;
  /** An aggregate relationship */
  lastKnownLocationsByRequestFarmerId_aggregate: Last_Known_Locations_Aggregate;
  /** An array relationship */
  last_known_locations: Array<Last_Known_Locations>;
  /** An aggregate relationship */
  last_known_locations_aggregate: Last_Known_Locations_Aggregate;
  locale?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  model_has_roles: Array<Model_Has_Roles>;
  /** An aggregate relationship */
  model_has_roles_aggregate: Model_Has_Roles_Aggregate;
  name: Scalars['String']['output'];
  /** An array relationship */
  nova_twofas: Array<Nova_Twofa>;
  /** An aggregate relationship */
  nova_twofas_aggregate: Nova_Twofa_Aggregate;
  password?: Maybe<Scalars['String']['output']>;
  phone_number: Scalars['String']['output'];
  profile_image_url?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  reel_comments: Array<Reel_Comments>;
  /** An aggregate relationship */
  reel_comments_aggregate: Reel_Comments_Aggregate;
  remember_token?: Maybe<Scalars['String']['output']>;
  /** An array relationship */
  segment_users: Array<Segment_User>;
  /** An aggregate relationship */
  segment_users_aggregate: Segment_User_Aggregate;
  /** An array relationship */
  service_requests: Array<Service_Requests>;
  /** An aggregate relationship */
  service_requests_aggregate: Service_Requests_Aggregate;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
  /** An array relationship */
  user_feedbacks: Array<User_Feedbacks>;
  /** An aggregate relationship */
  user_feedbacks_aggregate: User_Feedbacks_Aggregate;
  /** An array relationship */
  user_location_logs: Array<User_Location_Logs>;
  /** An aggregate relationship */
  user_location_logs_aggregate: User_Location_Logs_Aggregate;
  /** An array relationship */
  users_follows_to: Array<Follows>;
  /** An aggregate relationship */
  users_follows_to_aggregate: Follows_Aggregate;
};


/** columns and relationships of "users" */
export type UsersAccount_UsersArgs = {
  distinct_on?: InputMaybe<Array<Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_User_Order_By>>;
  where?: InputMaybe<Account_User_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAccount_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Account_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Account_User_Order_By>>;
  where?: InputMaybe<Account_User_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAccountsArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersAccounts_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Accounts_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Accounts_Order_By>>;
  where?: InputMaybe<Accounts_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCompaniesArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCompanies_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Companies_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Companies_Order_By>>;
  where?: InputMaybe<Companies_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersContent_LanguagesArgs = {
  path?: InputMaybe<Scalars['String']['input']>;
};


/** columns and relationships of "users" */
export type UsersCrop_UsersArgs = {
  distinct_on?: InputMaybe<Array<Crop_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Crop_User_Order_By>>;
  where?: InputMaybe<Crop_User_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersCrop_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Crop_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Crop_User_Order_By>>;
  where?: InputMaybe<Crop_User_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFarmer_PlotsArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Plots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Plots_Order_By>>;
  where?: InputMaybe<Farmer_Plots_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFarmer_Plots_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Farmer_Plots_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Farmer_Plots_Order_By>>;
  where?: InputMaybe<Farmer_Plots_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldPartnerResponseEventsByFieldPartnerUserIdArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldPartnerResponseEventsByFieldPartnerUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldPartnerResponseMessagesByFieldPartnerUserIdArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldPartnerResponseMessagesByFieldPartnerUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldPartnerResponseMessagesByFromUserIdArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldPartnerResponseMessagesByFromUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldPartnerResponseMessagesByToUserIdArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldPartnerResponseMessagesByToUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldPartnerResponsesByFieldPartnerUserIdArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldPartnerResponsesByFieldPartnerUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldScheduledVisitsByFieldPartnerUserIdArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersFieldScheduledVisitsByFieldPartnerUserId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersField_Partner_Response_EventsArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersField_Partner_Response_Events_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Events_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Events_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersField_Partner_Response_MessagesArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersField_Partner_Response_Messages_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Response_Messages_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Response_Messages_Order_By>>;
  where?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersField_Partner_ResponsesArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersField_Partner_Responses_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Partner_Responses_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Partner_Responses_Order_By>>;
  where?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersField_Scheduled_VisitsArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersField_Scheduled_Visits_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Field_Scheduled_Visits_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Field_Scheduled_Visits_Order_By>>;
  where?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLastKnownLocationsByRequestFarmerIdArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLastKnownLocationsByRequestFarmerId_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLast_Known_LocationsArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersLast_Known_Locations_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Last_Known_Locations_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Last_Known_Locations_Order_By>>;
  where?: InputMaybe<Last_Known_Locations_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersModel_Has_RolesArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Roles_Order_By>>;
  where?: InputMaybe<Model_Has_Roles_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersModel_Has_Roles_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Model_Has_Roles_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Model_Has_Roles_Order_By>>;
  where?: InputMaybe<Model_Has_Roles_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNova_TwofasArgs = {
  distinct_on?: InputMaybe<Array<Nova_Twofa_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Twofa_Order_By>>;
  where?: InputMaybe<Nova_Twofa_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersNova_Twofas_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Nova_Twofa_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Nova_Twofa_Order_By>>;
  where?: InputMaybe<Nova_Twofa_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReel_CommentsArgs = {
  distinct_on?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Comments_Order_By>>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersReel_Comments_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Reel_Comments_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Reel_Comments_Order_By>>;
  where?: InputMaybe<Reel_Comments_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSegment_UsersArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Segment_User_Order_By>>;
  where?: InputMaybe<Segment_User_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersSegment_Users_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Segment_User_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Segment_User_Order_By>>;
  where?: InputMaybe<Segment_User_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersService_RequestsArgs = {
  distinct_on?: InputMaybe<Array<Service_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Requests_Order_By>>;
  where?: InputMaybe<Service_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersService_Requests_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Service_Requests_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Service_Requests_Order_By>>;
  where?: InputMaybe<Service_Requests_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_FeedbacksArgs = {
  distinct_on?: InputMaybe<Array<User_Feedbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Feedbacks_Order_By>>;
  where?: InputMaybe<User_Feedbacks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Feedbacks_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Feedbacks_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Feedbacks_Order_By>>;
  where?: InputMaybe<User_Feedbacks_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Location_LogsArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Location_Logs_Order_By>>;
  where?: InputMaybe<User_Location_Logs_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUser_Location_Logs_AggregateArgs = {
  distinct_on?: InputMaybe<Array<User_Location_Logs_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<User_Location_Logs_Order_By>>;
  where?: InputMaybe<User_Location_Logs_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_Follows_ToArgs = {
  distinct_on?: InputMaybe<Array<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};


/** columns and relationships of "users" */
export type UsersUsers_Follows_To_AggregateArgs = {
  distinct_on?: InputMaybe<Array<Follows_Select_Column>>;
  limit?: InputMaybe<Scalars['Int']['input']>;
  offset?: InputMaybe<Scalars['Int']['input']>;
  order_by?: InputMaybe<Array<Follows_Order_By>>;
  where?: InputMaybe<Follows_Bool_Exp>;
};

/** aggregated selection of "users" */
export type Users_Aggregate = {
  __typename?: 'users_aggregate';
  aggregate?: Maybe<Users_Aggregate_Fields>;
  nodes: Array<Users>;
};

/** aggregate fields of "users" */
export type Users_Aggregate_Fields = {
  __typename?: 'users_aggregate_fields';
  avg?: Maybe<Users_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Users_Max_Fields>;
  min?: Maybe<Users_Min_Fields>;
  stddev?: Maybe<Users_Stddev_Fields>;
  stddev_pop?: Maybe<Users_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Users_Stddev_Samp_Fields>;
  sum?: Maybe<Users_Sum_Fields>;
  var_pop?: Maybe<Users_Var_Pop_Fields>;
  var_samp?: Maybe<Users_Var_Samp_Fields>;
  variance?: Maybe<Users_Variance_Fields>;
};


/** aggregate fields of "users" */
export type Users_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Users_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Users_Avg_Fields = {
  __typename?: 'users_avg_fields';
  follower_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "users". All fields are combined with a logical 'AND'. */
export type Users_Bool_Exp = {
  _and?: InputMaybe<Array<Users_Bool_Exp>>;
  _not?: InputMaybe<Users_Bool_Exp>;
  _or?: InputMaybe<Array<Users_Bool_Exp>>;
  account_users?: InputMaybe<Account_User_Bool_Exp>;
  account_users_aggregate?: InputMaybe<Account_User_Aggregate_Bool_Exp>;
  accounts?: InputMaybe<Accounts_Bool_Exp>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Bool_Exp>;
  companies?: InputMaybe<Companies_Bool_Exp>;
  companies_aggregate?: InputMaybe<Companies_Aggregate_Bool_Exp>;
  content_languages?: InputMaybe<Json_Comparison_Exp>;
  created_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  crop_users?: InputMaybe<Crop_User_Bool_Exp>;
  crop_users_aggregate?: InputMaybe<Crop_User_Aggregate_Bool_Exp>;
  date_of_birth?: InputMaybe<String_Comparison_Exp>;
  default_role?: InputMaybe<String_Comparison_Exp>;
  deleted_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  email?: InputMaybe<String_Comparison_Exp>;
  email_verified_at?: InputMaybe<Timestamp_Comparison_Exp>;
  farmer_plots?: InputMaybe<Farmer_Plots_Bool_Exp>;
  farmer_plots_aggregate?: InputMaybe<Farmer_Plots_Aggregate_Bool_Exp>;
  fieldPartnerResponseEventsByFieldPartnerUserId?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
  fieldPartnerResponseEventsByFieldPartnerUserId_aggregate?: InputMaybe<Field_Partner_Response_Events_Aggregate_Bool_Exp>;
  fieldPartnerResponseMessagesByFieldPartnerUserId?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
  fieldPartnerResponseMessagesByFieldPartnerUserId_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Bool_Exp>;
  fieldPartnerResponseMessagesByFromUserId?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
  fieldPartnerResponseMessagesByFromUserId_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Bool_Exp>;
  fieldPartnerResponseMessagesByToUserId?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
  fieldPartnerResponseMessagesByToUserId_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Bool_Exp>;
  fieldPartnerResponsesByFieldPartnerUserId?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
  fieldPartnerResponsesByFieldPartnerUserId_aggregate?: InputMaybe<Field_Partner_Responses_Aggregate_Bool_Exp>;
  fieldScheduledVisitsByFieldPartnerUserId?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
  fieldScheduledVisitsByFieldPartnerUserId_aggregate?: InputMaybe<Field_Scheduled_Visits_Aggregate_Bool_Exp>;
  field_partner_response_events?: InputMaybe<Field_Partner_Response_Events_Bool_Exp>;
  field_partner_response_events_aggregate?: InputMaybe<Field_Partner_Response_Events_Aggregate_Bool_Exp>;
  field_partner_response_messages?: InputMaybe<Field_Partner_Response_Messages_Bool_Exp>;
  field_partner_response_messages_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Bool_Exp>;
  field_partner_responses?: InputMaybe<Field_Partner_Responses_Bool_Exp>;
  field_partner_responses_aggregate?: InputMaybe<Field_Partner_Responses_Aggregate_Bool_Exp>;
  field_scheduled_visits?: InputMaybe<Field_Scheduled_Visits_Bool_Exp>;
  field_scheduled_visits_aggregate?: InputMaybe<Field_Scheduled_Visits_Aggregate_Bool_Exp>;
  follower_count?: InputMaybe<Int_Comparison_Exp>;
  gender?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bpchar_Comparison_Exp>;
  is_test?: InputMaybe<Boolean_Comparison_Exp>;
  lastKnownLocationsByRequestFarmerId?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  lastKnownLocationsByRequestFarmerId_aggregate?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp>;
  last_known_locations?: InputMaybe<Last_Known_Locations_Bool_Exp>;
  last_known_locations_aggregate?: InputMaybe<Last_Known_Locations_Aggregate_Bool_Exp>;
  locale?: InputMaybe<String_Comparison_Exp>;
  model_has_roles?: InputMaybe<Model_Has_Roles_Bool_Exp>;
  model_has_roles_aggregate?: InputMaybe<Model_Has_Roles_Aggregate_Bool_Exp>;
  name?: InputMaybe<String_Comparison_Exp>;
  nova_twofas?: InputMaybe<Nova_Twofa_Bool_Exp>;
  nova_twofas_aggregate?: InputMaybe<Nova_Twofa_Aggregate_Bool_Exp>;
  password?: InputMaybe<String_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  profile_image_url?: InputMaybe<String_Comparison_Exp>;
  reel_comments?: InputMaybe<Reel_Comments_Bool_Exp>;
  reel_comments_aggregate?: InputMaybe<Reel_Comments_Aggregate_Bool_Exp>;
  remember_token?: InputMaybe<String_Comparison_Exp>;
  segment_users?: InputMaybe<Segment_User_Bool_Exp>;
  segment_users_aggregate?: InputMaybe<Segment_User_Aggregate_Bool_Exp>;
  service_requests?: InputMaybe<Service_Requests_Bool_Exp>;
  service_requests_aggregate?: InputMaybe<Service_Requests_Aggregate_Bool_Exp>;
  updated_at?: InputMaybe<Timestamptz_Comparison_Exp>;
  user_feedbacks?: InputMaybe<User_Feedbacks_Bool_Exp>;
  user_feedbacks_aggregate?: InputMaybe<User_Feedbacks_Aggregate_Bool_Exp>;
  user_location_logs?: InputMaybe<User_Location_Logs_Bool_Exp>;
  user_location_logs_aggregate?: InputMaybe<User_Location_Logs_Aggregate_Bool_Exp>;
  users_follows_to?: InputMaybe<Follows_Bool_Exp>;
  users_follows_to_aggregate?: InputMaybe<Follows_Aggregate_Bool_Exp>;
};

/** unique or primary key constraints on table "users" */
export enum Users_Constraint {
  /** unique or primary key constraint on columns "phone_number" */
  UsersPhoneNumberUnique = 'users_phone_number_unique',
  /** unique or primary key constraint on columns "id" */
  UsersPkey = 'users_pkey'
}

/** input type for incrementing numeric columns in table "users" */
export type Users_Inc_Input = {
  follower_count?: InputMaybe<Scalars['Int']['input']>;
};

/** input type for inserting data into table "users" */
export type Users_Insert_Input = {
  account_users?: InputMaybe<Account_User_Arr_Rel_Insert_Input>;
  accounts?: InputMaybe<Accounts_Arr_Rel_Insert_Input>;
  companies?: InputMaybe<Companies_Arr_Rel_Insert_Input>;
  content_languages?: InputMaybe<Scalars['json']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  crop_users?: InputMaybe<Crop_User_Arr_Rel_Insert_Input>;
  date_of_birth?: InputMaybe<Scalars['String']['input']>;
  default_role?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_verified_at?: InputMaybe<Scalars['timestamp']['input']>;
  farmer_plots?: InputMaybe<Farmer_Plots_Arr_Rel_Insert_Input>;
  fieldPartnerResponseEventsByFieldPartnerUserId?: InputMaybe<Field_Partner_Response_Events_Arr_Rel_Insert_Input>;
  fieldPartnerResponseMessagesByFieldPartnerUserId?: InputMaybe<Field_Partner_Response_Messages_Arr_Rel_Insert_Input>;
  fieldPartnerResponseMessagesByFromUserId?: InputMaybe<Field_Partner_Response_Messages_Arr_Rel_Insert_Input>;
  fieldPartnerResponseMessagesByToUserId?: InputMaybe<Field_Partner_Response_Messages_Arr_Rel_Insert_Input>;
  fieldPartnerResponsesByFieldPartnerUserId?: InputMaybe<Field_Partner_Responses_Arr_Rel_Insert_Input>;
  fieldScheduledVisitsByFieldPartnerUserId?: InputMaybe<Field_Scheduled_Visits_Arr_Rel_Insert_Input>;
  field_partner_response_events?: InputMaybe<Field_Partner_Response_Events_Arr_Rel_Insert_Input>;
  field_partner_response_messages?: InputMaybe<Field_Partner_Response_Messages_Arr_Rel_Insert_Input>;
  field_partner_responses?: InputMaybe<Field_Partner_Responses_Arr_Rel_Insert_Input>;
  field_scheduled_visits?: InputMaybe<Field_Scheduled_Visits_Arr_Rel_Insert_Input>;
  follower_count?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_test?: InputMaybe<Scalars['Boolean']['input']>;
  lastKnownLocationsByRequestFarmerId?: InputMaybe<Last_Known_Locations_Arr_Rel_Insert_Input>;
  last_known_locations?: InputMaybe<Last_Known_Locations_Arr_Rel_Insert_Input>;
  locale?: InputMaybe<Scalars['String']['input']>;
  model_has_roles?: InputMaybe<Model_Has_Roles_Arr_Rel_Insert_Input>;
  name?: InputMaybe<Scalars['String']['input']>;
  nova_twofas?: InputMaybe<Nova_Twofa_Arr_Rel_Insert_Input>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  profile_image_url?: InputMaybe<Scalars['String']['input']>;
  reel_comments?: InputMaybe<Reel_Comments_Arr_Rel_Insert_Input>;
  remember_token?: InputMaybe<Scalars['String']['input']>;
  segment_users?: InputMaybe<Segment_User_Arr_Rel_Insert_Input>;
  service_requests?: InputMaybe<Service_Requests_Arr_Rel_Insert_Input>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
  user_feedbacks?: InputMaybe<User_Feedbacks_Arr_Rel_Insert_Input>;
  user_location_logs?: InputMaybe<User_Location_Logs_Arr_Rel_Insert_Input>;
  users_follows_to?: InputMaybe<Follows_Arr_Rel_Insert_Input>;
};

/** aggregate max on columns */
export type Users_Max_Fields = {
  __typename?: 'users_max_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_of_birth?: Maybe<Scalars['String']['output']>;
  default_role?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_verified_at?: Maybe<Scalars['timestamp']['output']>;
  follower_count?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  profile_image_url?: Maybe<Scalars['String']['output']>;
  remember_token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** aggregate min on columns */
export type Users_Min_Fields = {
  __typename?: 'users_min_fields';
  created_at?: Maybe<Scalars['timestamptz']['output']>;
  date_of_birth?: Maybe<Scalars['String']['output']>;
  default_role?: Maybe<Scalars['String']['output']>;
  deleted_at?: Maybe<Scalars['timestamptz']['output']>;
  email?: Maybe<Scalars['String']['output']>;
  email_verified_at?: Maybe<Scalars['timestamp']['output']>;
  follower_count?: Maybe<Scalars['Int']['output']>;
  gender?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bpchar']['output']>;
  locale?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  password?: Maybe<Scalars['String']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  profile_image_url?: Maybe<Scalars['String']['output']>;
  remember_token?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamptz']['output']>;
};

/** response of any mutation on the table "users" */
export type Users_Mutation_Response = {
  __typename?: 'users_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Users>;
};

/** input type for inserting object relation for remote table "users" */
export type Users_Obj_Rel_Insert_Input = {
  data: Users_Insert_Input;
  /** upsert condition */
  on_conflict?: InputMaybe<Users_On_Conflict>;
};

/** on_conflict condition type for table "users" */
export type Users_On_Conflict = {
  constraint: Users_Constraint;
  update_columns?: Array<Users_Update_Column>;
  where?: InputMaybe<Users_Bool_Exp>;
};

/** Ordering options when selecting data from "users". */
export type Users_Order_By = {
  account_users_aggregate?: InputMaybe<Account_User_Aggregate_Order_By>;
  accounts_aggregate?: InputMaybe<Accounts_Aggregate_Order_By>;
  companies_aggregate?: InputMaybe<Companies_Aggregate_Order_By>;
  content_languages?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  crop_users_aggregate?: InputMaybe<Crop_User_Aggregate_Order_By>;
  date_of_birth?: InputMaybe<Order_By>;
  default_role?: InputMaybe<Order_By>;
  deleted_at?: InputMaybe<Order_By>;
  email?: InputMaybe<Order_By>;
  email_verified_at?: InputMaybe<Order_By>;
  farmer_plots_aggregate?: InputMaybe<Farmer_Plots_Aggregate_Order_By>;
  fieldPartnerResponseEventsByFieldPartnerUserId_aggregate?: InputMaybe<Field_Partner_Response_Events_Aggregate_Order_By>;
  fieldPartnerResponseMessagesByFieldPartnerUserId_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Order_By>;
  fieldPartnerResponseMessagesByFromUserId_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Order_By>;
  fieldPartnerResponseMessagesByToUserId_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Order_By>;
  fieldPartnerResponsesByFieldPartnerUserId_aggregate?: InputMaybe<Field_Partner_Responses_Aggregate_Order_By>;
  fieldScheduledVisitsByFieldPartnerUserId_aggregate?: InputMaybe<Field_Scheduled_Visits_Aggregate_Order_By>;
  field_partner_response_events_aggregate?: InputMaybe<Field_Partner_Response_Events_Aggregate_Order_By>;
  field_partner_response_messages_aggregate?: InputMaybe<Field_Partner_Response_Messages_Aggregate_Order_By>;
  field_partner_responses_aggregate?: InputMaybe<Field_Partner_Responses_Aggregate_Order_By>;
  field_scheduled_visits_aggregate?: InputMaybe<Field_Scheduled_Visits_Aggregate_Order_By>;
  follower_count?: InputMaybe<Order_By>;
  gender?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  is_test?: InputMaybe<Order_By>;
  lastKnownLocationsByRequestFarmerId_aggregate?: InputMaybe<Last_Known_Locations_Aggregate_Order_By>;
  last_known_locations_aggregate?: InputMaybe<Last_Known_Locations_Aggregate_Order_By>;
  locale?: InputMaybe<Order_By>;
  model_has_roles_aggregate?: InputMaybe<Model_Has_Roles_Aggregate_Order_By>;
  name?: InputMaybe<Order_By>;
  nova_twofas_aggregate?: InputMaybe<Nova_Twofa_Aggregate_Order_By>;
  password?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  profile_image_url?: InputMaybe<Order_By>;
  reel_comments_aggregate?: InputMaybe<Reel_Comments_Aggregate_Order_By>;
  remember_token?: InputMaybe<Order_By>;
  segment_users_aggregate?: InputMaybe<Segment_User_Aggregate_Order_By>;
  service_requests_aggregate?: InputMaybe<Service_Requests_Aggregate_Order_By>;
  updated_at?: InputMaybe<Order_By>;
  user_feedbacks_aggregate?: InputMaybe<User_Feedbacks_Aggregate_Order_By>;
  user_location_logs_aggregate?: InputMaybe<User_Location_Logs_Aggregate_Order_By>;
  users_follows_to_aggregate?: InputMaybe<Follows_Aggregate_Order_By>;
};

/** primary key columns input for table: users */
export type Users_Pk_Columns_Input = {
  id: Scalars['bpchar']['input'];
};

/** select columns of table "users" */
export enum Users_Select_Column {
  /** column name */
  ContentLanguages = 'content_languages',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  FollowerCount = 'follower_count',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  IsTest = 'is_test',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ProfileImageUrl = 'profile_image_url',
  /** column name */
  RememberToken = 'remember_token',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** input type for updating data in table "users" */
export type Users_Set_Input = {
  content_languages?: InputMaybe<Scalars['json']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_of_birth?: InputMaybe<Scalars['String']['input']>;
  default_role?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_verified_at?: InputMaybe<Scalars['timestamp']['input']>;
  follower_count?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_test?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  profile_image_url?: InputMaybe<Scalars['String']['input']>;
  remember_token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate stddev on columns */
export type Users_Stddev_Fields = {
  __typename?: 'users_stddev_fields';
  follower_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Users_Stddev_Pop_Fields = {
  __typename?: 'users_stddev_pop_fields';
  follower_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Users_Stddev_Samp_Fields = {
  __typename?: 'users_stddev_samp_fields';
  follower_count?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "users" */
export type Users_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Users_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Users_Stream_Cursor_Value_Input = {
  content_languages?: InputMaybe<Scalars['json']['input']>;
  created_at?: InputMaybe<Scalars['timestamptz']['input']>;
  date_of_birth?: InputMaybe<Scalars['String']['input']>;
  default_role?: InputMaybe<Scalars['String']['input']>;
  deleted_at?: InputMaybe<Scalars['timestamptz']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  email_verified_at?: InputMaybe<Scalars['timestamp']['input']>;
  follower_count?: InputMaybe<Scalars['Int']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bpchar']['input']>;
  is_test?: InputMaybe<Scalars['Boolean']['input']>;
  locale?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  password?: InputMaybe<Scalars['String']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  profile_image_url?: InputMaybe<Scalars['String']['input']>;
  remember_token?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamptz']['input']>;
};

/** aggregate sum on columns */
export type Users_Sum_Fields = {
  __typename?: 'users_sum_fields';
  follower_count?: Maybe<Scalars['Int']['output']>;
};

/** update columns of table "users" */
export enum Users_Update_Column {
  /** column name */
  ContentLanguages = 'content_languages',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  DateOfBirth = 'date_of_birth',
  /** column name */
  DefaultRole = 'default_role',
  /** column name */
  DeletedAt = 'deleted_at',
  /** column name */
  Email = 'email',
  /** column name */
  EmailVerifiedAt = 'email_verified_at',
  /** column name */
  FollowerCount = 'follower_count',
  /** column name */
  Gender = 'gender',
  /** column name */
  Id = 'id',
  /** column name */
  IsTest = 'is_test',
  /** column name */
  Locale = 'locale',
  /** column name */
  Name = 'name',
  /** column name */
  Password = 'password',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ProfileImageUrl = 'profile_image_url',
  /** column name */
  RememberToken = 'remember_token',
  /** column name */
  UpdatedAt = 'updated_at'
}

export type Users_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Users_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Users_Set_Input>;
  /** filter the rows which have to be updated */
  where: Users_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Users_Var_Pop_Fields = {
  __typename?: 'users_var_pop_fields';
  follower_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Users_Var_Samp_Fields = {
  __typename?: 'users_var_samp_fields';
  follower_count?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Users_Variance_Fields = {
  __typename?: 'users_variance_fields';
  follower_count?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to compare columns of type "uuid". All fields are combined with logical 'AND'. */
export type Uuid_Comparison_Exp = {
  _eq?: InputMaybe<Scalars['uuid']['input']>;
  _gt?: InputMaybe<Scalars['uuid']['input']>;
  _gte?: InputMaybe<Scalars['uuid']['input']>;
  _in?: InputMaybe<Array<Scalars['uuid']['input']>>;
  _is_null?: InputMaybe<Scalars['Boolean']['input']>;
  _lt?: InputMaybe<Scalars['uuid']['input']>;
  _lte?: InputMaybe<Scalars['uuid']['input']>;
  _neq?: InputMaybe<Scalars['uuid']['input']>;
  _nin?: InputMaybe<Array<Scalars['uuid']['input']>>;
};

/** columns and relationships of "whatsauth_logins" */
export type Whatsauth_Logins = {
  __typename?: 'whatsauth_logins';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  expires_at?: Maybe<Scalars['String']['output']>;
  fi_session_id?: Maybe<Scalars['String']['output']>;
  id: Scalars['bigint']['output'];
  phone_number?: Maybe<Scalars['String']['output']>;
  profile_name?: Maybe<Scalars['String']['output']>;
  referrer_url?: Maybe<Scalars['String']['output']>;
  requested_at?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  validated_at?: Maybe<Scalars['String']['output']>;
  verification_code?: Maybe<Scalars['String']['output']>;
  whatsauth_id?: Maybe<Scalars['String']['output']>;
};

/** aggregated selection of "whatsauth_logins" */
export type Whatsauth_Logins_Aggregate = {
  __typename?: 'whatsauth_logins_aggregate';
  aggregate?: Maybe<Whatsauth_Logins_Aggregate_Fields>;
  nodes: Array<Whatsauth_Logins>;
};

/** aggregate fields of "whatsauth_logins" */
export type Whatsauth_Logins_Aggregate_Fields = {
  __typename?: 'whatsauth_logins_aggregate_fields';
  avg?: Maybe<Whatsauth_Logins_Avg_Fields>;
  count: Scalars['Int']['output'];
  max?: Maybe<Whatsauth_Logins_Max_Fields>;
  min?: Maybe<Whatsauth_Logins_Min_Fields>;
  stddev?: Maybe<Whatsauth_Logins_Stddev_Fields>;
  stddev_pop?: Maybe<Whatsauth_Logins_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<Whatsauth_Logins_Stddev_Samp_Fields>;
  sum?: Maybe<Whatsauth_Logins_Sum_Fields>;
  var_pop?: Maybe<Whatsauth_Logins_Var_Pop_Fields>;
  var_samp?: Maybe<Whatsauth_Logins_Var_Samp_Fields>;
  variance?: Maybe<Whatsauth_Logins_Variance_Fields>;
};


/** aggregate fields of "whatsauth_logins" */
export type Whatsauth_Logins_Aggregate_FieldsCountArgs = {
  columns?: InputMaybe<Array<Whatsauth_Logins_Select_Column>>;
  distinct?: InputMaybe<Scalars['Boolean']['input']>;
};

/** aggregate avg on columns */
export type Whatsauth_Logins_Avg_Fields = {
  __typename?: 'whatsauth_logins_avg_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Boolean expression to filter rows from the table "whatsauth_logins". All fields are combined with a logical 'AND'. */
export type Whatsauth_Logins_Bool_Exp = {
  _and?: InputMaybe<Array<Whatsauth_Logins_Bool_Exp>>;
  _not?: InputMaybe<Whatsauth_Logins_Bool_Exp>;
  _or?: InputMaybe<Array<Whatsauth_Logins_Bool_Exp>>;
  code?: InputMaybe<String_Comparison_Exp>;
  created_at?: InputMaybe<Timestamp_Comparison_Exp>;
  expires_at?: InputMaybe<String_Comparison_Exp>;
  fi_session_id?: InputMaybe<String_Comparison_Exp>;
  id?: InputMaybe<Bigint_Comparison_Exp>;
  phone_number?: InputMaybe<String_Comparison_Exp>;
  profile_name?: InputMaybe<String_Comparison_Exp>;
  referrer_url?: InputMaybe<String_Comparison_Exp>;
  requested_at?: InputMaybe<String_Comparison_Exp>;
  status?: InputMaybe<String_Comparison_Exp>;
  updated_at?: InputMaybe<Timestamp_Comparison_Exp>;
  validated_at?: InputMaybe<String_Comparison_Exp>;
  verification_code?: InputMaybe<String_Comparison_Exp>;
  whatsauth_id?: InputMaybe<String_Comparison_Exp>;
};

/** unique or primary key constraints on table "whatsauth_logins" */
export enum Whatsauth_Logins_Constraint {
  /** unique or primary key constraint on columns "id" */
  WhatsauthLoginsPkey = 'whatsauth_logins_pkey'
}

/** input type for incrementing numeric columns in table "whatsauth_logins" */
export type Whatsauth_Logins_Inc_Input = {
  id?: InputMaybe<Scalars['bigint']['input']>;
};

/** input type for inserting data into table "whatsauth_logins" */
export type Whatsauth_Logins_Insert_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_at?: InputMaybe<Scalars['String']['input']>;
  fi_session_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  profile_name?: InputMaybe<Scalars['String']['input']>;
  referrer_url?: InputMaybe<Scalars['String']['input']>;
  requested_at?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  validated_at?: InputMaybe<Scalars['String']['input']>;
  verification_code?: InputMaybe<Scalars['String']['input']>;
  whatsauth_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate max on columns */
export type Whatsauth_Logins_Max_Fields = {
  __typename?: 'whatsauth_logins_max_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  expires_at?: Maybe<Scalars['String']['output']>;
  fi_session_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  profile_name?: Maybe<Scalars['String']['output']>;
  referrer_url?: Maybe<Scalars['String']['output']>;
  requested_at?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  validated_at?: Maybe<Scalars['String']['output']>;
  verification_code?: Maybe<Scalars['String']['output']>;
  whatsauth_id?: Maybe<Scalars['String']['output']>;
};

/** aggregate min on columns */
export type Whatsauth_Logins_Min_Fields = {
  __typename?: 'whatsauth_logins_min_fields';
  code?: Maybe<Scalars['String']['output']>;
  created_at?: Maybe<Scalars['timestamp']['output']>;
  expires_at?: Maybe<Scalars['String']['output']>;
  fi_session_id?: Maybe<Scalars['String']['output']>;
  id?: Maybe<Scalars['bigint']['output']>;
  phone_number?: Maybe<Scalars['String']['output']>;
  profile_name?: Maybe<Scalars['String']['output']>;
  referrer_url?: Maybe<Scalars['String']['output']>;
  requested_at?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['String']['output']>;
  updated_at?: Maybe<Scalars['timestamp']['output']>;
  validated_at?: Maybe<Scalars['String']['output']>;
  verification_code?: Maybe<Scalars['String']['output']>;
  whatsauth_id?: Maybe<Scalars['String']['output']>;
};

/** response of any mutation on the table "whatsauth_logins" */
export type Whatsauth_Logins_Mutation_Response = {
  __typename?: 'whatsauth_logins_mutation_response';
  /** number of rows affected by the mutation */
  affected_rows: Scalars['Int']['output'];
  /** data from the rows affected by the mutation */
  returning: Array<Whatsauth_Logins>;
};

/** on_conflict condition type for table "whatsauth_logins" */
export type Whatsauth_Logins_On_Conflict = {
  constraint: Whatsauth_Logins_Constraint;
  update_columns?: Array<Whatsauth_Logins_Update_Column>;
  where?: InputMaybe<Whatsauth_Logins_Bool_Exp>;
};

/** Ordering options when selecting data from "whatsauth_logins". */
export type Whatsauth_Logins_Order_By = {
  code?: InputMaybe<Order_By>;
  created_at?: InputMaybe<Order_By>;
  expires_at?: InputMaybe<Order_By>;
  fi_session_id?: InputMaybe<Order_By>;
  id?: InputMaybe<Order_By>;
  phone_number?: InputMaybe<Order_By>;
  profile_name?: InputMaybe<Order_By>;
  referrer_url?: InputMaybe<Order_By>;
  requested_at?: InputMaybe<Order_By>;
  status?: InputMaybe<Order_By>;
  updated_at?: InputMaybe<Order_By>;
  validated_at?: InputMaybe<Order_By>;
  verification_code?: InputMaybe<Order_By>;
  whatsauth_id?: InputMaybe<Order_By>;
};

/** primary key columns input for table: whatsauth_logins */
export type Whatsauth_Logins_Pk_Columns_Input = {
  id: Scalars['bigint']['input'];
};

/** select columns of table "whatsauth_logins" */
export enum Whatsauth_Logins_Select_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  FiSessionId = 'fi_session_id',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ProfileName = 'profile_name',
  /** column name */
  ReferrerUrl = 'referrer_url',
  /** column name */
  RequestedAt = 'requested_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidatedAt = 'validated_at',
  /** column name */
  VerificationCode = 'verification_code',
  /** column name */
  WhatsauthId = 'whatsauth_id'
}

/** input type for updating data in table "whatsauth_logins" */
export type Whatsauth_Logins_Set_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_at?: InputMaybe<Scalars['String']['input']>;
  fi_session_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  profile_name?: InputMaybe<Scalars['String']['input']>;
  referrer_url?: InputMaybe<Scalars['String']['input']>;
  requested_at?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  validated_at?: InputMaybe<Scalars['String']['input']>;
  verification_code?: InputMaybe<Scalars['String']['input']>;
  whatsauth_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate stddev on columns */
export type Whatsauth_Logins_Stddev_Fields = {
  __typename?: 'whatsauth_logins_stddev_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_pop on columns */
export type Whatsauth_Logins_Stddev_Pop_Fields = {
  __typename?: 'whatsauth_logins_stddev_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate stddev_samp on columns */
export type Whatsauth_Logins_Stddev_Samp_Fields = {
  __typename?: 'whatsauth_logins_stddev_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** Streaming cursor of the table "whatsauth_logins" */
export type Whatsauth_Logins_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: Whatsauth_Logins_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: InputMaybe<Cursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type Whatsauth_Logins_Stream_Cursor_Value_Input = {
  code?: InputMaybe<Scalars['String']['input']>;
  created_at?: InputMaybe<Scalars['timestamp']['input']>;
  expires_at?: InputMaybe<Scalars['String']['input']>;
  fi_session_id?: InputMaybe<Scalars['String']['input']>;
  id?: InputMaybe<Scalars['bigint']['input']>;
  phone_number?: InputMaybe<Scalars['String']['input']>;
  profile_name?: InputMaybe<Scalars['String']['input']>;
  referrer_url?: InputMaybe<Scalars['String']['input']>;
  requested_at?: InputMaybe<Scalars['String']['input']>;
  status?: InputMaybe<Scalars['String']['input']>;
  updated_at?: InputMaybe<Scalars['timestamp']['input']>;
  validated_at?: InputMaybe<Scalars['String']['input']>;
  verification_code?: InputMaybe<Scalars['String']['input']>;
  whatsauth_id?: InputMaybe<Scalars['String']['input']>;
};

/** aggregate sum on columns */
export type Whatsauth_Logins_Sum_Fields = {
  __typename?: 'whatsauth_logins_sum_fields';
  id?: Maybe<Scalars['bigint']['output']>;
};

/** update columns of table "whatsauth_logins" */
export enum Whatsauth_Logins_Update_Column {
  /** column name */
  Code = 'code',
  /** column name */
  CreatedAt = 'created_at',
  /** column name */
  ExpiresAt = 'expires_at',
  /** column name */
  FiSessionId = 'fi_session_id',
  /** column name */
  Id = 'id',
  /** column name */
  PhoneNumber = 'phone_number',
  /** column name */
  ProfileName = 'profile_name',
  /** column name */
  ReferrerUrl = 'referrer_url',
  /** column name */
  RequestedAt = 'requested_at',
  /** column name */
  Status = 'status',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  ValidatedAt = 'validated_at',
  /** column name */
  VerificationCode = 'verification_code',
  /** column name */
  WhatsauthId = 'whatsauth_id'
}

export type Whatsauth_Logins_Updates = {
  /** increments the numeric columns with given value of the filtered values */
  _inc?: InputMaybe<Whatsauth_Logins_Inc_Input>;
  /** sets the columns of the filtered rows to the given values */
  _set?: InputMaybe<Whatsauth_Logins_Set_Input>;
  /** filter the rows which have to be updated */
  where: Whatsauth_Logins_Bool_Exp;
};

/** aggregate var_pop on columns */
export type Whatsauth_Logins_Var_Pop_Fields = {
  __typename?: 'whatsauth_logins_var_pop_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate var_samp on columns */
export type Whatsauth_Logins_Var_Samp_Fields = {
  __typename?: 'whatsauth_logins_var_samp_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

/** aggregate variance on columns */
export type Whatsauth_Logins_Variance_Fields = {
  __typename?: 'whatsauth_logins_variance_fields';
  id?: Maybe<Scalars['Float']['output']>;
};

export type UserUpdatesDeviceInfoMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  deviceToken?: InputMaybe<Scalars['String']['input']>;
  deviceInstallationId: Scalars['String']['input'];
  deviceType?: InputMaybe<Scalars['String']['input']>;
  appVersion?: InputMaybe<Scalars['String']['input']>;
  osVersion?: InputMaybe<Scalars['String']['input']>;
  deviceModel?: InputMaybe<Scalars['String']['input']>;
  deviceName?: InputMaybe<Scalars['String']['input']>;
  appBuildNumber?: InputMaybe<Scalars['String']['input']>;
  deviceBrand?: InputMaybe<Scalars['String']['input']>;
  deviceManufacturer?: InputMaybe<Scalars['String']['input']>;
  deviceOsVersion?: InputMaybe<Scalars['String']['input']>;
  nativePushToken?: InputMaybe<Scalars['String']['input']>;
  releaseChannel: Scalars['String']['input'];
  runtimeVersion: Scalars['String']['input'];
}>;


export type UserUpdatesDeviceInfoMutation = { __typename?: 'mutation_root', userUpdatesHisDeviceInfo: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type FieldPartnerUpdatesLocationMutationVariables = Exact<{
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  field_partner_user_id: Scalars['String']['input'];
}>;


export type FieldPartnerUpdatesLocationMutation = { __typename?: 'mutation_root', setFieldForceLocation: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type FfGoesOnlineMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
}>;


export type FfGoesOnlineMutation = { __typename?: 'mutation_root', ffWentOnline: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type FfGoesOfflineMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  phoneNumber: Scalars['String']['input'];
}>;


export type FfGoesOfflineMutation = { __typename?: 'mutation_root', ffWentOffline: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type SearchNearbyRequestsSubscriptionVariables = Exact<{
  lat: Scalars['float8']['input'];
  lng: Scalars['float8']['input'];
  kms: Scalars['Int']['input'];
}>;


export type SearchNearbyRequestsSubscription = { __typename?: 'subscription_root', requests_nearby: Array<{ __typename?: 'last_known_locations', id: any, distance_kms?: number | null, lat: any, lng: any, service_request?: { __typename?: 'service_requests', farmer_user_id: any, date_of_sowing: any, is_request_closed: boolean, master_crop: { __typename?: 'master_crops', name: string }, user: { __typename?: 'users', name: string }, field_partner_responses: Array<{ __typename?: 'field_partner_responses', id: any, fieldPartner: { __typename?: 'users', id: any, name: string, phone_number: string, email?: string | null }, messages: Array<{ __typename?: 'field_partner_response_messages', id: any, message: string, assets: any }> }> } | null }> };

export type FfMyRequestsSubscriptionVariables = Exact<{
  field_partner_user_id: Scalars['bpchar']['input'];
}>;


export type FfMyRequestsSubscription = { __typename?: 'subscription_root', ffMyRequests: Array<{ __typename?: 'field_partner_responses', id: any, is_response_closed: boolean, service_request: { __typename?: 'service_requests', id: any, is_recurring?: boolean | null, farmer_user_id: any, date_of_sowing: any, is_request_closed: boolean, created_at?: any | null, master_crop: { __typename?: 'master_crops', id: any, name: string }, farmer: { __typename?: 'users', id: any, name: string, phone_number: string } }, fieldPartner: { __typename?: 'users', id: any, name: string, phone_number: string, email?: string | null }, messages: Array<{ __typename?: 'field_partner_response_messages', id: any, message: string, assets: any }>, schedules: Array<{ __typename?: 'field_scheduled_visits', id: any, scheduled_at: any, is_farmer_accepted: boolean, is_field_partner_accepted: boolean, is_visit_done: boolean }>, events: Array<{ __typename?: 'field_partner_response_events', id: any, event_name: string, event_data: any, created_at?: any | null }> }> };

export type FprSubSubscriptionVariables = Exact<{
  fprId: Scalars['bpchar']['input'];
}>;


export type FprSubSubscription = { __typename?: 'subscription_root', field_partner_responses: Array<{ __typename?: 'field_partner_responses', id: any, field_partner_user_id: any, field_partner_rating?: number | null, farmer_user_id: any, request_id: any, created_at?: any | null, response_action?: string | null, farmer_rating?: number | null, farmer_feedback?: string | null, field_partner_feedback?: string | null, is_response_closed: boolean, field_partner_rated_at?: any | null, farmer_rated_at?: any | null, service_request: { __typename?: 'service_requests', id: any, crop: string, date_of_sowing: any, is_request_closed: boolean, assets: any, lat: any, lng: any, farmer: { __typename?: 'users', id: any, name: string, phone_number: string, profile_image_url?: string | null, user_feedbacks_aggregate: { __typename?: 'user_feedbacks_aggregate', aggregate?: { __typename?: 'user_feedbacks_aggregate_fields', avg?: { __typename?: 'user_feedbacks_avg_fields', rating?: number | null } | null } | null } } }, fieldPartner: { __typename?: 'users', id: any, name: string, phone_number: string, profile_image_url?: string | null, email?: string | null, user_feedbacks_aggregate: { __typename?: 'user_feedbacks_aggregate', aggregate?: { __typename?: 'user_feedbacks_aggregate_fields', avg?: { __typename?: 'user_feedbacks_avg_fields', rating?: number | null } | null } | null }, related_accounts: Array<{ __typename?: 'account_user', id: any, account: { __typename?: 'accounts', id: any, name: string } }> }, messages: Array<{ __typename?: 'field_partner_response_messages', id: any, message: string, from_user_id: any, to_user_id: any, created_at?: any | null, assets: any }> }> };

export type FarmerGetSingleRequestQueryVariables = Exact<{
  requestId: Scalars['bpchar']['input'];
}>;


export type FarmerGetSingleRequestQuery = { __typename?: 'query_root', request: Array<{ __typename?: 'service_requests', id: any, farmer_user_id: any, crop: string, crop_id: any, is_request_closed: boolean, is_recurring?: boolean | null, date_of_sowing: any, assets: any, master_crop: { __typename?: 'master_crops', id: any, name: string }, locations: Array<{ __typename?: 'last_known_locations', lat: any, lng: any }>, field_partner_responses: Array<{ __typename?: 'field_partner_responses', farmer_feedback?: string | null, id: any, field_partner_rating?: number | null, response_action?: string | null, fieldPartner: { __typename?: 'users', id: any, name: string, phone_number: string, email?: string | null, profile_image_url?: string | null, user_feedbacks_aggregate: { __typename?: 'user_feedbacks_aggregate', aggregate?: { __typename?: 'user_feedbacks_aggregate_fields', avg?: { __typename?: 'user_feedbacks_avg_fields', rating?: number | null } | null } | null }, related_accounts: Array<{ __typename?: 'account_user', id: any, account: { __typename?: 'accounts', id: any, name: string } }> }, messages: Array<{ __typename?: 'field_partner_response_messages', id: any, message: string, from_user_id: any, to_user_id: any, assets: any }> }> }> };

export type SendMessageMutationVariables = Exact<{
  farmerUserId: Scalars['String']['input'];
  fieldPartnerUserId: Scalars['String']['input'];
  message: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
  senderUserId: Scalars['String']['input'];
}>;


export type SendMessageMutation = { __typename?: 'mutation_root', sendMessageOnServiceRequest: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type UserNotificationsQueryVariables = Exact<{
  userId: Scalars['bpchar']['input'];
}>;


export type UserNotificationsQuery = { __typename?: 'query_root', notifications: Array<{ __typename?: 'notifications', id: any, data: string, created_at?: any | null, read_at?: any | null }> };

export type FieldPartnerClosesRequestMutationVariables = Exact<{
  fieldPartnerUserId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
}>;


export type FieldPartnerClosesRequestMutation = { __typename?: 'mutation_root', fieldPartnerClosesRequest: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type FieldPartnerClosesRequestFeedbackMutationVariables = Exact<{
  farmerUserId: Scalars['String']['input'];
  feedback: Scalars['String']['input'];
  fieldPartnerUserId: Scalars['String']['input'];
  requestId: Scalars['String']['input'];
  rating: Scalars['String']['input'];
}>;


export type FieldPartnerClosesRequestFeedbackMutation = { __typename?: 'mutation_root', fieldPartnerRatesAndReviewsFarmer: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type FarmerClosesServiceRequestFeedbackMutationVariables = Exact<{
  requestId: Scalars['String']['input'];
  farmerUserId: Scalars['String']['input'];
  feedback: Scalars['String']['input'];
  fieldPartnerUserId: Scalars['String']['input'];
  rating: Scalars['Int']['input'];
}>;


export type FarmerClosesServiceRequestFeedbackMutation = { __typename?: 'mutation_root', farmerRatesAndReviewsServiceRequest: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type UserUpdatesHisLocaleMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  localeName: Scalars['String']['input'];
}>;


export type UserUpdatesHisLocaleMutation = { __typename?: 'mutation_root', userSetHisLocale: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type FarmerClosesServiceRequestMutationVariables = Exact<{
  requestId: Scalars['String']['input'];
}>;


export type FarmerClosesServiceRequestMutation = { __typename?: 'mutation_root', farmerClosesServiceRequest: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type GetMasterCropsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetMasterCropsQuery = { __typename?: 'query_root', crops: Array<{ __typename?: 'master_crops', id: any, name: string }> };

export type FarmerMyRequestsQueryVariables = Exact<{
  farmerUserId: Scalars['bpchar']['input'];
}>;


export type FarmerMyRequestsQuery = { __typename?: 'query_root', service_requests: Array<{ __typename?: 'service_requests', id: any, farmer_user_id: any, is_recurring?: boolean | null, crop: string, crop_id: any, is_request_closed: boolean, date_of_sowing: any, assets: any, master_crop: { __typename?: 'master_crops', id: any, name: string }, locations: Array<{ __typename?: 'last_known_locations', lat: any, lng: any }>, field_partner_responses: Array<{ __typename?: 'field_partner_responses', id: any, userByFieldPartnerUserId: { __typename?: 'users', id: any, name: string, phone_number: string, email?: string | null }, field_partner_response_messages: Array<{ __typename?: 'field_partner_response_messages', id: any, message: string, assets: any }> }> }> };

export type RequestOtpMutationVariables = Exact<{
  phoneNumber: Scalars['String']['input'];
  countryCode: Scalars['String']['input'];
  hashKey: Scalars['String']['input'];
}>;


export type RequestOtpMutation = { __typename?: 'mutation_root', requestOtpCommand: { __typename?: 'RequestOTPOutput', encrypted: string, nonce: string, otp: string, phoneNumber: string } };

export type VerifyOtpMutationVariables = Exact<{
  encrypted: Scalars['String']['input'];
  nonce: Scalars['String']['input'];
  otp: Scalars['String']['input'];
  phone_number: Scalars['String']['input'];
  roleName: Scalars['String']['input'];
}>;


export type VerifyOtpMutation = { __typename?: 'mutation_root', verifyOtpCommand: { __typename?: 'VerifyOTPOutput', is_verified: boolean, token?: string | null, user?: { __typename?: 'User', id: string, name: string, roles: Array<string>, phoneNumber: string } | null } };

export type SearchNearbyFarmersSubscriptionVariables = Exact<{
  lat: Scalars['float8']['input'];
  lng: Scalars['float8']['input'];
  kms: Scalars['Int']['input'];
}>;


export type SearchNearbyFarmersSubscription = { __typename?: 'subscription_root', farmers_nearby: Array<{ __typename?: 'last_known_locations', id: any, distance_kms?: number | null, lng: any, lat: any, service_request?: { __typename?: 'service_requests', id: any, crop: string, date_of_sowing: any } | null }> };

export type SearchNearbyFfSubscriptionVariables = Exact<{
  lat: Scalars['float8']['input'];
  lng: Scalars['float8']['input'];
  kms: Scalars['Int']['input'];
}>;


export type SearchNearbyFfSubscription = { __typename?: 'subscription_root', ff_nearby: Array<{ __typename?: 'last_known_locations', id: any, distance_kms?: number | null, lat: any, lng: any, user?: { __typename?: 'users', id: any, name: string, profile_image_url?: string | null, related_segments: Array<{ __typename?: 'segment_user', segment: { __typename?: 'master_segments', name: string } }>, related_crops: Array<{ __typename?: 'crop_user', crop: { __typename?: 'master_crops', name: string } }> } | null }> };

export type GetCfDataMutationVariables = Exact<{
  type: Scalars['String']['input'];
}>;


export type GetCfDataMutation = { __typename?: 'mutation_root', cfUploadData: Array<string> };

export type GetFarmerPlotsQueryVariables = Exact<{
  userId: Scalars['bpchar']['input'];
}>;


export type GetFarmerPlotsQuery = { __typename?: 'query_root', farmer_plots: Array<{ __typename?: 'farmer_plots', id: any, crop_id: any, crop_name: string, date_of_sowing: any }> };

export type SetRoleOnUserMutationVariables = Exact<{
  roleName: Scalars['String']['input'];
  userId: Scalars['String']['input'];
}>;


export type SetRoleOnUserMutation = { __typename?: 'mutation_root', setRoleCommand: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type FfHomeNewRequestsSubscriptionVariables = Exact<{
  lat: Scalars['float8']['input'];
  lng: Scalars['float8']['input'];
  kms: Scalars['Int']['input'];
  fieldPartnerUserId: Scalars['bpchar']['input'];
}>;


export type FfHomeNewRequestsSubscription = { __typename?: 'subscription_root', newRequests: Array<{ __typename?: 'last_known_locations', id: any, distance_kms?: number | null, service_request?: { __typename?: 'service_requests', id: any, assets: any, date_of_sowing: any, lat: any, lng: any, address?: string | null, farmer: { __typename?: 'users', id: any, name: string, phone_number: string, profile_image_url?: string | null, user_feedbacks_aggregate: { __typename?: 'user_feedbacks_aggregate', aggregate?: { __typename?: 'user_feedbacks_aggregate_fields', avg?: { __typename?: 'user_feedbacks_avg_fields', rating?: number | null } | null } | null } }, crop: { __typename?: 'master_crops', id: any, name: string, image_url?: string | null } } | null }> };

export type FfHomeQueryVariables = Exact<{
  lat: Scalars['float8']['input'];
  lng: Scalars['float8']['input'];
  kms: Scalars['Int']['input'];
  fieldPartnerUserId: Scalars['bpchar']['input'];
}>;


export type FfHomeQuery = { __typename?: 'query_root', ffMyRequests: Array<{ __typename?: 'service_requests', id: any, crop: string, crop_id: any, date_of_sowing: any, lat: any, lng: any, farmer: { __typename?: 'users', id: any, name: string, phone_number: string }, field_partner_responses: Array<{ __typename?: 'field_partner_responses', id: any, field_partner: { __typename?: 'users', id: any, name: string, phone_number: string } }> }> };

export type FfRespondsToRequestMutationVariables = Exact<{
  serviceRequestId: Scalars['String']['input'];
  fieldPartnerUserId: Scalars['String']['input'];
  action: Scalars['String']['input'];
}>;


export type FfRespondsToRequestMutation = { __typename?: 'mutation_root', fieldPartnerRespondsToRequest: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type UserDetailQueryVariables = Exact<{
  userId: Scalars['bpchar']['input'];
}>;


export type UserDetailQuery = { __typename?: 'query_root', users_by_pk?: { __typename?: 'users', id: any, name: string, gender?: string | null, date_of_birth?: string | null, profile_image_url?: string | null, email?: string | null, phone_number: string, last_known_locations: Array<{ __typename?: 'last_known_locations', id: any, is_active?: boolean | null, address?: string | null }>, crop_users: Array<{ __typename?: 'crop_user', id: any, master_crop: { __typename?: 'master_crops', id: any, name: string } }>, segment_users: Array<{ __typename?: 'segment_user', id: any, master_segment: { __typename?: 'master_segments', id: any, name: string } }> } | null };

export type UserUpdatesMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  dateOfBirth?: InputMaybe<Scalars['String']['input']>;
  name?: InputMaybe<Scalars['String']['input']>;
  email?: InputMaybe<Scalars['String']['input']>;
  gender?: InputMaybe<Scalars['String']['input']>;
  phoneNumber?: InputMaybe<Scalars['String']['input']>;
  imageUrl?: InputMaybe<Scalars['String']['input']>;
}>;


export type UserUpdatesMutation = { __typename?: 'mutation_root', userUpdatesHisProfile: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type GetMastersQueryVariables = Exact<{
  id: Scalars['bpchar']['input'];
}>;


export type GetMastersQuery = { __typename?: 'query_root', master_crops: Array<{ __typename?: 'master_crops', id: any, name: string }>, master_segments: Array<{ __typename?: 'master_segments', id: any, name: string }>, users_by_pk?: { __typename?: 'users', id: any, name: string, phone_number: string, email?: string | null, crop_users: Array<{ __typename?: 'crop_user', id: any, master_crop: { __typename?: 'master_crops', id: any, name: string } }>, segment_users: Array<{ __typename?: 'segment_user', id: any, master_segment: { __typename?: 'master_segments', id: any, name: string } }>, user_feedbacks_aggregate: { __typename?: 'user_feedbacks_aggregate', aggregate?: { __typename?: 'user_feedbacks_aggregate_fields', avg?: { __typename?: 'user_feedbacks_avg_fields', rating?: number | null } | null } | null }, user_feedbacks: Array<{ __typename?: 'user_feedbacks', id: any, feedback: string, rating: number, created_at?: any | null, service_request: { __typename?: 'service_requests', id: any, farmer: { __typename?: 'users', id: any, name: string, phone_number: string, user_feedbacks_aggregate: { __typename?: 'user_feedbacks_aggregate', aggregate?: { __typename?: 'user_feedbacks_aggregate_fields', avg?: { __typename?: 'user_feedbacks_avg_fields', rating?: number | null } | null } | null } } } }>, related_accounts: Array<{ __typename?: 'account_user', id: any, account: { __typename?: 'accounts', id: any, name: string } }> } | null };

export type SetUserSegmentsMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  segments: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type SetUserSegmentsMutation = { __typename?: 'mutation_root', fieldPartnerUpdatesHisSegments: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type SetUserCropsMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  crops: Array<Scalars['String']['input']> | Scalars['String']['input'];
}>;


export type SetUserCropsMutation = { __typename?: 'mutation_root', fieldPartnerUpdatesHisCrops: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type CreateRequestMutationVariables = Exact<{
  requestId: Scalars['String']['input'];
  cropName: Scalars['String']['input'];
  isRecurring: Scalars['Boolean']['input'];
  lat: Scalars['Float']['input'];
  lng: Scalars['Float']['input'];
  dateOfSowing: Scalars['String']['input'];
  farmerUserId: Scalars['String']['input'];
  assetUrl?: InputMaybe<Scalars['String']['input']>;
  createdDate: Scalars['String']['input'];
}>;


export type CreateRequestMutation = { __typename?: 'mutation_root', farmerCreatesServiceRequest: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type FarmerClosesResponseMutationVariables = Exact<{
  requestId: Scalars['String']['input'];
  fprId: Scalars['String']['input'];
}>;


export type FarmerClosesResponseMutation = { __typename?: 'mutation_root', farmerClosesResponse: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type FarmerCancelsResponseMutationVariables = Exact<{
  requestId: Scalars['String']['input'];
  farmerUserId: Scalars['String']['input'];
  reason: Scalars['String']['input'];
  cancelRecurring: Scalars['Boolean']['input'];
}>;


export type FarmerCancelsResponseMutation = { __typename?: 'mutation_root', farmerCancelsServiceRequest: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type ReelsWithRelatedQueryVariables = Exact<{
  reelid?: InputMaybe<Scalars['Int']['input']>;
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
}>;


export type ReelsWithRelatedQuery = { __typename?: 'query_root', get_reel_with_related: Array<{ __typename?: 'reels_with_distance', id?: any | null, cloudflare_playback_url?: string | null, cloudflare_thumbnail_url?: string | null, title?: string | null, description?: string | null, reels_user?: { __typename?: 'users', id: any, name: string, profile_image_url?: string | null } | null, reels_taggables: Array<{ __typename?: 'taggables', taggable_type: string, id: any, taggables_tag: { __typename?: 'tags', id: any, name: any } }> }> };

export type ReelsQueryVariables = Exact<{
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
}>;


export type ReelsQuery = { __typename?: 'query_root', get_reels: Array<{ __typename?: 'reels_with_distance', id?: any | null, cloudflare_playback_url?: string | null, cloudflare_thumbnail_url?: string | null, title?: string | null, description?: string | null, reels_user?: { __typename?: 'users', id: any, name: string, profile_image_url?: string | null } | null, reels_taggables: Array<{ __typename?: 'taggables', taggable_type: string, id: any, taggables_tag: { __typename?: 'tags', id: any, name: any } }> }> };

export type UserViewedReelMutationVariables = Exact<{
  reelId: Scalars['Int']['input'];
  userId?: InputMaybe<Scalars['String']['input']>;
}>;


export type UserViewedReelMutation = { __typename?: 'mutation_root', userViewedReel: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type ReelReactionsSubscriptionVariables = Exact<{
  reelId: Scalars['bigint']['input'];
}>;


export type ReelReactionsSubscription = { __typename?: 'subscription_root', reels: Array<{ __typename?: 'reels', id: any, thumbnail_url?: string | null, cloudflare_id?: string | null, cloudflare_playback_url?: string | null, cloudflare_thumbnail_url?: string | null, title?: string | null, description?: string | null, likes: number, shares: number, views: number, reactions_count: number, reactions?: any | null, reel_comments: Array<{ __typename?: 'reel_comments', id: any, user_id: any, comment: string, created_at?: any | null, user: { __typename?: 'users', id: any, name: string, profile_image_url?: string | null } }> }> };

export type ReelsFarmerQueryVariables = Exact<{
  lat?: InputMaybe<Scalars['float8']['input']>;
  lng?: InputMaybe<Scalars['float8']['input']>;
}>;


export type ReelsFarmerQuery = { __typename?: 'query_root', get_reels: Array<{ __typename?: 'reels_with_distance', id?: any | null, cloudflare_playback_url?: string | null, cloudflare_thumbnail_url?: string | null, thumbnail_url?: string | null, title?: string | null, description?: string | null, reels_user?: { __typename?: 'users', id: any, name: string, profile_image_url?: string | null } | null, reels_taggables: Array<{ __typename?: 'taggables', taggable_type: string, id: any, taggables_tag: { __typename?: 'tags', id: any, name: any } }> }> };

export type ReelReactionsFarmerSubscriptionVariables = Exact<{
  reelId: Scalars['bigint']['input'];
  currentUserId: Scalars['bpchar']['input'];
}>;


export type ReelReactionsFarmerSubscription = { __typename?: 'subscription_root', reels: Array<{ __typename?: 'reels', id: any, likes: number, shares: number, views: number, reactions_count: number, reactions?: any | null, allow_comments: boolean, allow_likes: boolean, allow_reactions: boolean, allow_shares: boolean, allow_reports: boolean, reel_comments: Array<{ __typename?: 'reel_comments', id: any, user_id: any, comment: string, created_at?: any | null, user: { __typename?: 'users', id: any, name: string, profile_image_url?: string | null }, reports_aggregate: { __typename?: 'reel_user_reports_aggregate', aggregate?: { __typename?: 'reel_user_reports_aggregate_fields', count: number } | null } }>, reels_reel_interactions: Array<{ __typename?: 'reel_interactions', id: any, interaction_type?: string | null, interaction_value?: string | null, reel_interactions_user?: { __typename?: 'users', id: any, name: string, profile_image_url?: string | null } | null }>, reels_user?: { __typename?: 'users', id: any, name: string, profile_image_url?: string | null, follower_count: number, account_users: Array<{ __typename?: 'account_user', id: any, account: { __typename?: 'accounts', id: any, name: string } }>, users_follows_to_aggregate: { __typename?: 'follows_aggregate', aggregate?: { __typename?: 'follows_aggregate_fields', count: number } | null } } | null }> };

export type CreateReelCommentMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  reelId: Scalars['String']['input'];
  comment: Scalars['String']['input'];
}>;


export type CreateReelCommentMutation = { __typename?: 'mutation_root', createReelComment: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type UserLikedReelMutationVariables = Exact<{
  reelId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
}>;


export type UserLikedReelMutation = { __typename?: 'mutation_root', userLikedReel: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type UsersViewedReelMutationVariables = Exact<{
  reelId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
}>;


export type UsersViewedReelMutation = { __typename?: 'mutation_root', userViewedReel: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type UserSharedReelMutationVariables = Exact<{
  reelId: Scalars['Int']['input'];
  userId: Scalars['String']['input'];
}>;


export type UserSharedReelMutation = { __typename?: 'mutation_root', userSharedReel: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type UserReactedReelMutationVariables = Exact<{
  userId: Scalars['String']['input'];
  reelId: Scalars['Int']['input'];
  reaction: Scalars['String']['input'];
}>;


export type UserReactedReelMutation = { __typename?: 'mutation_root', userReactedReel: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };

export type UserFollowsProfileMutationVariables = Exact<{
  loggedInUserId: Scalars['String']['input'];
  followUserId: Scalars['String']['input'];
}>;


export type UserFollowsProfileMutation = { __typename?: 'mutation_root', userFollowsProfileCommand: { __typename?: 'DefaultCommandOutput', error?: Array<string | null> | null, data: { __typename?: 'CommandOutputDataWithValues', status: string } | { __typename?: 'DefaultCommandOutputData', status: string } } };


export const UserUpdatesDeviceInfoDocument = gql`
    mutation userUpdatesDeviceInfo($userId: String!, $deviceToken: String, $deviceInstallationId: String!, $deviceType: String, $appVersion: String, $osVersion: String, $deviceModel: String, $deviceName: String, $appBuildNumber: String, $deviceBrand: String, $deviceManufacturer: String, $deviceOsVersion: String, $nativePushToken: String, $releaseChannel: String!, $runtimeVersion: String!) {
  userUpdatesHisDeviceInfo(
    command: {userId: $userId, deviceToken: $deviceToken, nativePushToken: $nativePushToken, deviceInstallationId: $deviceInstallationId, deviceType: $deviceType, appVersion: $appVersion, deviceOs: $osVersion, deviceModel: $deviceModel, deviceName: $deviceName, appBuildNumber: $appBuildNumber, deviceBrand: $deviceBrand, deviceManufacturer: $deviceManufacturer, deviceOsVersion: $deviceOsVersion, releaseChannel: $releaseChannel, runtimeVersion: $runtimeVersion}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type UserUpdatesDeviceInfoMutationFn = Apollo.MutationFunction<UserUpdatesDeviceInfoMutation, UserUpdatesDeviceInfoMutationVariables>;

/**
 * __useUserUpdatesDeviceInfoMutation__
 *
 * To run a mutation, you first call `useUserUpdatesDeviceInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatesDeviceInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatesDeviceInfoMutation, { data, loading, error }] = useUserUpdatesDeviceInfoMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      deviceToken: // value for 'deviceToken'
 *      deviceInstallationId: // value for 'deviceInstallationId'
 *      deviceType: // value for 'deviceType'
 *      appVersion: // value for 'appVersion'
 *      osVersion: // value for 'osVersion'
 *      deviceModel: // value for 'deviceModel'
 *      deviceName: // value for 'deviceName'
 *      appBuildNumber: // value for 'appBuildNumber'
 *      deviceBrand: // value for 'deviceBrand'
 *      deviceManufacturer: // value for 'deviceManufacturer'
 *      deviceOsVersion: // value for 'deviceOsVersion'
 *      nativePushToken: // value for 'nativePushToken'
 *      releaseChannel: // value for 'releaseChannel'
 *      runtimeVersion: // value for 'runtimeVersion'
 *   },
 * });
 */
export function useUserUpdatesDeviceInfoMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdatesDeviceInfoMutation, UserUpdatesDeviceInfoMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdatesDeviceInfoMutation, UserUpdatesDeviceInfoMutationVariables>(UserUpdatesDeviceInfoDocument, options);
      }
export type UserUpdatesDeviceInfoMutationHookResult = ReturnType<typeof useUserUpdatesDeviceInfoMutation>;
export type UserUpdatesDeviceInfoMutationResult = Apollo.MutationResult<UserUpdatesDeviceInfoMutation>;
export type UserUpdatesDeviceInfoMutationOptions = Apollo.BaseMutationOptions<UserUpdatesDeviceInfoMutation, UserUpdatesDeviceInfoMutationVariables>;
export const FieldPartnerUpdatesLocationDocument = gql`
    mutation fieldPartnerUpdatesLocation($lat: Float!, $lng: Float!, $field_partner_user_id: String!) {
  setFieldForceLocation(
    command: {field_partner_user_id: $field_partner_user_id, lat: $lat, lng: $lng}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type FieldPartnerUpdatesLocationMutationFn = Apollo.MutationFunction<FieldPartnerUpdatesLocationMutation, FieldPartnerUpdatesLocationMutationVariables>;

/**
 * __useFieldPartnerUpdatesLocationMutation__
 *
 * To run a mutation, you first call `useFieldPartnerUpdatesLocationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFieldPartnerUpdatesLocationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fieldPartnerUpdatesLocationMutation, { data, loading, error }] = useFieldPartnerUpdatesLocationMutation({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      field_partner_user_id: // value for 'field_partner_user_id'
 *   },
 * });
 */
export function useFieldPartnerUpdatesLocationMutation(baseOptions?: Apollo.MutationHookOptions<FieldPartnerUpdatesLocationMutation, FieldPartnerUpdatesLocationMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FieldPartnerUpdatesLocationMutation, FieldPartnerUpdatesLocationMutationVariables>(FieldPartnerUpdatesLocationDocument, options);
      }
export type FieldPartnerUpdatesLocationMutationHookResult = ReturnType<typeof useFieldPartnerUpdatesLocationMutation>;
export type FieldPartnerUpdatesLocationMutationResult = Apollo.MutationResult<FieldPartnerUpdatesLocationMutation>;
export type FieldPartnerUpdatesLocationMutationOptions = Apollo.BaseMutationOptions<FieldPartnerUpdatesLocationMutation, FieldPartnerUpdatesLocationMutationVariables>;
export const FfGoesOnlineDocument = gql`
    mutation ffGoesOnline($userId: String!, $phoneNumber: String!) {
  ffWentOnline(
    command: {field_partner_user_id: $userId, phone_number: $phoneNumber}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type FfGoesOnlineMutationFn = Apollo.MutationFunction<FfGoesOnlineMutation, FfGoesOnlineMutationVariables>;

/**
 * __useFfGoesOnlineMutation__
 *
 * To run a mutation, you first call `useFfGoesOnlineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFfGoesOnlineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ffGoesOnlineMutation, { data, loading, error }] = useFfGoesOnlineMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useFfGoesOnlineMutation(baseOptions?: Apollo.MutationHookOptions<FfGoesOnlineMutation, FfGoesOnlineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FfGoesOnlineMutation, FfGoesOnlineMutationVariables>(FfGoesOnlineDocument, options);
      }
export type FfGoesOnlineMutationHookResult = ReturnType<typeof useFfGoesOnlineMutation>;
export type FfGoesOnlineMutationResult = Apollo.MutationResult<FfGoesOnlineMutation>;
export type FfGoesOnlineMutationOptions = Apollo.BaseMutationOptions<FfGoesOnlineMutation, FfGoesOnlineMutationVariables>;
export const FfGoesOfflineDocument = gql`
    mutation ffGoesOffline($userId: String!, $phoneNumber: String!) {
  ffWentOffline(
    command: {field_partner_user_id: $userId, phone_number: $phoneNumber}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type FfGoesOfflineMutationFn = Apollo.MutationFunction<FfGoesOfflineMutation, FfGoesOfflineMutationVariables>;

/**
 * __useFfGoesOfflineMutation__
 *
 * To run a mutation, you first call `useFfGoesOfflineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFfGoesOfflineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ffGoesOfflineMutation, { data, loading, error }] = useFfGoesOfflineMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      phoneNumber: // value for 'phoneNumber'
 *   },
 * });
 */
export function useFfGoesOfflineMutation(baseOptions?: Apollo.MutationHookOptions<FfGoesOfflineMutation, FfGoesOfflineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FfGoesOfflineMutation, FfGoesOfflineMutationVariables>(FfGoesOfflineDocument, options);
      }
export type FfGoesOfflineMutationHookResult = ReturnType<typeof useFfGoesOfflineMutation>;
export type FfGoesOfflineMutationResult = Apollo.MutationResult<FfGoesOfflineMutation>;
export type FfGoesOfflineMutationOptions = Apollo.BaseMutationOptions<FfGoesOfflineMutation, FfGoesOfflineMutationVariables>;
export const SearchNearbyRequestsDocument = gql`
    subscription searchNearbyRequests($lat: float8!, $lng: float8!, $kms: Int!) {
  requests_nearby: search_field_force_near_farmer(
    args: {lat: $lat, lng: $lng, distance_kms: $kms}
    where: {type: {_eq: "service_request"}}
  ) {
    id
    distance_kms
    service_request {
      master_crop {
        name
      }
      farmer_user_id
      user {
        name
      }
      date_of_sowing
      is_request_closed
      field_partner_responses {
        id
        fieldPartner: userByFieldPartnerUserId {
          id
          name
          phone_number
          email
        }
        messages: field_partner_response_messages {
          id
          message
          assets
        }
      }
    }
    lat
    lng
  }
}
    `;

/**
 * __useSearchNearbyRequestsSubscription__
 *
 * To run a query within a React component, call `useSearchNearbyRequestsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSearchNearbyRequestsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNearbyRequestsSubscription({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      kms: // value for 'kms'
 *   },
 * });
 */
export function useSearchNearbyRequestsSubscription(baseOptions: Apollo.SubscriptionHookOptions<SearchNearbyRequestsSubscription, SearchNearbyRequestsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SearchNearbyRequestsSubscription, SearchNearbyRequestsSubscriptionVariables>(SearchNearbyRequestsDocument, options);
      }
export type SearchNearbyRequestsSubscriptionHookResult = ReturnType<typeof useSearchNearbyRequestsSubscription>;
export type SearchNearbyRequestsSubscriptionResult = Apollo.SubscriptionResult<SearchNearbyRequestsSubscription>;
export const FfMyRequestsDocument = gql`
    subscription ffMyRequests($field_partner_user_id: bpchar!) {
  ffMyRequests: field_partner_responses(
    where: {field_partner_user_id: {_eq: $field_partner_user_id}}
  ) {
    id
    is_response_closed
    service_request {
      id
      is_recurring
      master_crop {
        id
        name
      }
      farmer_user_id
      farmer: user {
        id
        name
        phone_number
      }
      date_of_sowing
      is_request_closed
      created_at
    }
    fieldPartner: userByFieldPartnerUserId {
      id
      name
      phone_number
      email
    }
    messages: field_partner_response_messages {
      id
      message
      assets
    }
    schedules: field_scheduled_visits {
      id
      scheduled_at
      is_farmer_accepted
      is_field_partner_accepted
      is_visit_done
    }
    events: field_partner_response_events {
      id
      event_name
      event_data
      created_at
    }
  }
}
    `;

/**
 * __useFfMyRequestsSubscription__
 *
 * To run a query within a React component, call `useFfMyRequestsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFfMyRequestsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFfMyRequestsSubscription({
 *   variables: {
 *      field_partner_user_id: // value for 'field_partner_user_id'
 *   },
 * });
 */
export function useFfMyRequestsSubscription(baseOptions: Apollo.SubscriptionHookOptions<FfMyRequestsSubscription, FfMyRequestsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<FfMyRequestsSubscription, FfMyRequestsSubscriptionVariables>(FfMyRequestsDocument, options);
      }
export type FfMyRequestsSubscriptionHookResult = ReturnType<typeof useFfMyRequestsSubscription>;
export type FfMyRequestsSubscriptionResult = Apollo.SubscriptionResult<FfMyRequestsSubscription>;
export const FprSubDocument = gql`
    subscription fprSub($fprId: bpchar!) {
  field_partner_responses(where: {id: {_eq: $fprId}}) {
    id
    field_partner_user_id
    field_partner_rating
    farmer_user_id
    request_id
    created_at
    response_action
    farmer_rating
    farmer_feedback
    field_partner_rating
    field_partner_feedback
    is_response_closed
    service_request {
      id
      crop
      date_of_sowing
      is_request_closed
      assets
      lat
      lng
      farmer: user {
        id
        name
        phone_number
        profile_image_url
        user_feedbacks_aggregate {
          aggregate {
            avg {
              rating
            }
          }
        }
      }
    }
    fieldPartner: userByFieldPartnerUserId {
      id
      name
      phone_number
      profile_image_url
      email
      user_feedbacks_aggregate {
        aggregate {
          avg {
            rating
          }
        }
      }
      related_accounts: account_users {
        id
        account {
          id
          name
        }
      }
    }
    messages: field_partner_response_messages {
      id
      message
      from_user_id
      to_user_id
      created_at
      assets
    }
    field_partner_rated_at
    farmer_rated_at
  }
}
    `;

/**
 * __useFprSubSubscription__
 *
 * To run a query within a React component, call `useFprSubSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFprSubSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFprSubSubscription({
 *   variables: {
 *      fprId: // value for 'fprId'
 *   },
 * });
 */
export function useFprSubSubscription(baseOptions: Apollo.SubscriptionHookOptions<FprSubSubscription, FprSubSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<FprSubSubscription, FprSubSubscriptionVariables>(FprSubDocument, options);
      }
export type FprSubSubscriptionHookResult = ReturnType<typeof useFprSubSubscription>;
export type FprSubSubscriptionResult = Apollo.SubscriptionResult<FprSubSubscription>;
export const FarmerGetSingleRequestDocument = gql`
    query farmerGetSingleRequest($requestId: bpchar!) {
  request: service_requests(where: {id: {_eq: $requestId}}) {
    id
    farmer_user_id
    crop
    crop_id
    is_request_closed
    is_recurring
    master_crop {
      id
      name
    }
    locations: last_known_locations {
      lat
      lng
    }
    field_partner_responses {
      farmer_feedback
      id
      field_partner_rating
      response_action
      fieldPartner: userByFieldPartnerUserId {
        id
        name
        phone_number
        email
        profile_image_url
        user_feedbacks_aggregate {
          aggregate {
            avg {
              rating
            }
          }
        }
        related_accounts: account_users {
          id
          account {
            id
            name
          }
        }
      }
      messages: field_partner_response_messages {
        id
        message
        from_user_id
        to_user_id
        assets
      }
    }
    date_of_sowing
    assets(path: "$")
  }
}
    `;

/**
 * __useFarmerGetSingleRequestQuery__
 *
 * To run a query within a React component, call `useFarmerGetSingleRequestQuery` and pass it any options that fit your needs.
 * When your component renders, `useFarmerGetSingleRequestQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFarmerGetSingleRequestQuery({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useFarmerGetSingleRequestQuery(baseOptions: Apollo.QueryHookOptions<FarmerGetSingleRequestQuery, FarmerGetSingleRequestQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FarmerGetSingleRequestQuery, FarmerGetSingleRequestQueryVariables>(FarmerGetSingleRequestDocument, options);
      }
export function useFarmerGetSingleRequestLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FarmerGetSingleRequestQuery, FarmerGetSingleRequestQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FarmerGetSingleRequestQuery, FarmerGetSingleRequestQueryVariables>(FarmerGetSingleRequestDocument, options);
        }
export type FarmerGetSingleRequestQueryHookResult = ReturnType<typeof useFarmerGetSingleRequestQuery>;
export type FarmerGetSingleRequestLazyQueryHookResult = ReturnType<typeof useFarmerGetSingleRequestLazyQuery>;
export type FarmerGetSingleRequestQueryResult = Apollo.QueryResult<FarmerGetSingleRequestQuery, FarmerGetSingleRequestQueryVariables>;
export const SendMessageDocument = gql`
    mutation sendMessage($farmerUserId: String!, $fieldPartnerUserId: String!, $message: String!, $requestId: String!, $senderUserId: String!) {
  sendMessageOnServiceRequest(
    command: {farmer_id: $farmerUserId, field_partner_id: $fieldPartnerUserId, message: $message, requestId: $requestId, sender_user_id: $senderUserId}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type SendMessageMutationFn = Apollo.MutationFunction<SendMessageMutation, SendMessageMutationVariables>;

/**
 * __useSendMessageMutation__
 *
 * To run a mutation, you first call `useSendMessageMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSendMessageMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [sendMessageMutation, { data, loading, error }] = useSendMessageMutation({
 *   variables: {
 *      farmerUserId: // value for 'farmerUserId'
 *      fieldPartnerUserId: // value for 'fieldPartnerUserId'
 *      message: // value for 'message'
 *      requestId: // value for 'requestId'
 *      senderUserId: // value for 'senderUserId'
 *   },
 * });
 */
export function useSendMessageMutation(baseOptions?: Apollo.MutationHookOptions<SendMessageMutation, SendMessageMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SendMessageMutation, SendMessageMutationVariables>(SendMessageDocument, options);
      }
export type SendMessageMutationHookResult = ReturnType<typeof useSendMessageMutation>;
export type SendMessageMutationResult = Apollo.MutationResult<SendMessageMutation>;
export type SendMessageMutationOptions = Apollo.BaseMutationOptions<SendMessageMutation, SendMessageMutationVariables>;
export const UserNotificationsDocument = gql`
    query userNotifications($userId: bpchar!) {
  notifications(where: {notifiable_id: {_eq: $userId}}) {
    id
    data
    created_at
    read_at
  }
}
    `;

/**
 * __useUserNotificationsQuery__
 *
 * To run a query within a React component, call `useUserNotificationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserNotificationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserNotificationsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserNotificationsQuery(baseOptions: Apollo.QueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
      }
export function useUserNotificationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserNotificationsQuery, UserNotificationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserNotificationsQuery, UserNotificationsQueryVariables>(UserNotificationsDocument, options);
        }
export type UserNotificationsQueryHookResult = ReturnType<typeof useUserNotificationsQuery>;
export type UserNotificationsLazyQueryHookResult = ReturnType<typeof useUserNotificationsLazyQuery>;
export type UserNotificationsQueryResult = Apollo.QueryResult<UserNotificationsQuery, UserNotificationsQueryVariables>;
export const FieldPartnerClosesRequestDocument = gql`
    mutation fieldPartnerClosesRequest($fieldPartnerUserId: String!, $requestId: String!) {
  fieldPartnerClosesRequest(
    command: {fieldPartnerUserId: $fieldPartnerUserId, requestId: $requestId}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type FieldPartnerClosesRequestMutationFn = Apollo.MutationFunction<FieldPartnerClosesRequestMutation, FieldPartnerClosesRequestMutationVariables>;

/**
 * __useFieldPartnerClosesRequestMutation__
 *
 * To run a mutation, you first call `useFieldPartnerClosesRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFieldPartnerClosesRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fieldPartnerClosesRequestMutation, { data, loading, error }] = useFieldPartnerClosesRequestMutation({
 *   variables: {
 *      fieldPartnerUserId: // value for 'fieldPartnerUserId'
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useFieldPartnerClosesRequestMutation(baseOptions?: Apollo.MutationHookOptions<FieldPartnerClosesRequestMutation, FieldPartnerClosesRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FieldPartnerClosesRequestMutation, FieldPartnerClosesRequestMutationVariables>(FieldPartnerClosesRequestDocument, options);
      }
export type FieldPartnerClosesRequestMutationHookResult = ReturnType<typeof useFieldPartnerClosesRequestMutation>;
export type FieldPartnerClosesRequestMutationResult = Apollo.MutationResult<FieldPartnerClosesRequestMutation>;
export type FieldPartnerClosesRequestMutationOptions = Apollo.BaseMutationOptions<FieldPartnerClosesRequestMutation, FieldPartnerClosesRequestMutationVariables>;
export const FieldPartnerClosesRequestFeedbackDocument = gql`
    mutation fieldPartnerClosesRequestFeedback($farmerUserId: String!, $feedback: String!, $fieldPartnerUserId: String!, $requestId: String!, $rating: String!) {
  fieldPartnerRatesAndReviewsFarmer(
    command: {farmer_id: $farmerUserId, feedback: $feedback, field_partner_id: $fieldPartnerUserId, id: $requestId, rating: $rating}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type FieldPartnerClosesRequestFeedbackMutationFn = Apollo.MutationFunction<FieldPartnerClosesRequestFeedbackMutation, FieldPartnerClosesRequestFeedbackMutationVariables>;

/**
 * __useFieldPartnerClosesRequestFeedbackMutation__
 *
 * To run a mutation, you first call `useFieldPartnerClosesRequestFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFieldPartnerClosesRequestFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fieldPartnerClosesRequestFeedbackMutation, { data, loading, error }] = useFieldPartnerClosesRequestFeedbackMutation({
 *   variables: {
 *      farmerUserId: // value for 'farmerUserId'
 *      feedback: // value for 'feedback'
 *      fieldPartnerUserId: // value for 'fieldPartnerUserId'
 *      requestId: // value for 'requestId'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useFieldPartnerClosesRequestFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<FieldPartnerClosesRequestFeedbackMutation, FieldPartnerClosesRequestFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FieldPartnerClosesRequestFeedbackMutation, FieldPartnerClosesRequestFeedbackMutationVariables>(FieldPartnerClosesRequestFeedbackDocument, options);
      }
export type FieldPartnerClosesRequestFeedbackMutationHookResult = ReturnType<typeof useFieldPartnerClosesRequestFeedbackMutation>;
export type FieldPartnerClosesRequestFeedbackMutationResult = Apollo.MutationResult<FieldPartnerClosesRequestFeedbackMutation>;
export type FieldPartnerClosesRequestFeedbackMutationOptions = Apollo.BaseMutationOptions<FieldPartnerClosesRequestFeedbackMutation, FieldPartnerClosesRequestFeedbackMutationVariables>;
export const FarmerClosesServiceRequestFeedbackDocument = gql`
    mutation farmerClosesServiceRequestFeedback($requestId: String!, $farmerUserId: String!, $feedback: String!, $fieldPartnerUserId: String!, $rating: Int!) {
  farmerRatesAndReviewsServiceRequest(
    command: {feedback: $feedback, rating: $rating, farmerUserId: $farmerUserId, fieldPartnerUserId: $fieldPartnerUserId, requestId: $requestId}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type FarmerClosesServiceRequestFeedbackMutationFn = Apollo.MutationFunction<FarmerClosesServiceRequestFeedbackMutation, FarmerClosesServiceRequestFeedbackMutationVariables>;

/**
 * __useFarmerClosesServiceRequestFeedbackMutation__
 *
 * To run a mutation, you first call `useFarmerClosesServiceRequestFeedbackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFarmerClosesServiceRequestFeedbackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [farmerClosesServiceRequestFeedbackMutation, { data, loading, error }] = useFarmerClosesServiceRequestFeedbackMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      farmerUserId: // value for 'farmerUserId'
 *      feedback: // value for 'feedback'
 *      fieldPartnerUserId: // value for 'fieldPartnerUserId'
 *      rating: // value for 'rating'
 *   },
 * });
 */
export function useFarmerClosesServiceRequestFeedbackMutation(baseOptions?: Apollo.MutationHookOptions<FarmerClosesServiceRequestFeedbackMutation, FarmerClosesServiceRequestFeedbackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FarmerClosesServiceRequestFeedbackMutation, FarmerClosesServiceRequestFeedbackMutationVariables>(FarmerClosesServiceRequestFeedbackDocument, options);
      }
export type FarmerClosesServiceRequestFeedbackMutationHookResult = ReturnType<typeof useFarmerClosesServiceRequestFeedbackMutation>;
export type FarmerClosesServiceRequestFeedbackMutationResult = Apollo.MutationResult<FarmerClosesServiceRequestFeedbackMutation>;
export type FarmerClosesServiceRequestFeedbackMutationOptions = Apollo.BaseMutationOptions<FarmerClosesServiceRequestFeedbackMutation, FarmerClosesServiceRequestFeedbackMutationVariables>;
export const UserUpdatesHisLocaleDocument = gql`
    mutation userUpdatesHisLocale($userId: String!, $localeName: String!) {
  userSetHisLocale(command: {userId: $userId, localeName: $localeName}) {
    data {
      status
    }
    error
  }
}
    `;
export type UserUpdatesHisLocaleMutationFn = Apollo.MutationFunction<UserUpdatesHisLocaleMutation, UserUpdatesHisLocaleMutationVariables>;

/**
 * __useUserUpdatesHisLocaleMutation__
 *
 * To run a mutation, you first call `useUserUpdatesHisLocaleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatesHisLocaleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatesHisLocaleMutation, { data, loading, error }] = useUserUpdatesHisLocaleMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      localeName: // value for 'localeName'
 *   },
 * });
 */
export function useUserUpdatesHisLocaleMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdatesHisLocaleMutation, UserUpdatesHisLocaleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdatesHisLocaleMutation, UserUpdatesHisLocaleMutationVariables>(UserUpdatesHisLocaleDocument, options);
      }
export type UserUpdatesHisLocaleMutationHookResult = ReturnType<typeof useUserUpdatesHisLocaleMutation>;
export type UserUpdatesHisLocaleMutationResult = Apollo.MutationResult<UserUpdatesHisLocaleMutation>;
export type UserUpdatesHisLocaleMutationOptions = Apollo.BaseMutationOptions<UserUpdatesHisLocaleMutation, UserUpdatesHisLocaleMutationVariables>;
export const FarmerClosesServiceRequestDocument = gql`
    mutation farmerClosesServiceRequest($requestId: String!) {
  farmerClosesServiceRequest(command: {id: $requestId}) {
    data {
      status
    }
    error
  }
}
    `;
export type FarmerClosesServiceRequestMutationFn = Apollo.MutationFunction<FarmerClosesServiceRequestMutation, FarmerClosesServiceRequestMutationVariables>;

/**
 * __useFarmerClosesServiceRequestMutation__
 *
 * To run a mutation, you first call `useFarmerClosesServiceRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFarmerClosesServiceRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [farmerClosesServiceRequestMutation, { data, loading, error }] = useFarmerClosesServiceRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *   },
 * });
 */
export function useFarmerClosesServiceRequestMutation(baseOptions?: Apollo.MutationHookOptions<FarmerClosesServiceRequestMutation, FarmerClosesServiceRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FarmerClosesServiceRequestMutation, FarmerClosesServiceRequestMutationVariables>(FarmerClosesServiceRequestDocument, options);
      }
export type FarmerClosesServiceRequestMutationHookResult = ReturnType<typeof useFarmerClosesServiceRequestMutation>;
export type FarmerClosesServiceRequestMutationResult = Apollo.MutationResult<FarmerClosesServiceRequestMutation>;
export type FarmerClosesServiceRequestMutationOptions = Apollo.BaseMutationOptions<FarmerClosesServiceRequestMutation, FarmerClosesServiceRequestMutationVariables>;
export const GetMasterCropsDocument = gql`
    query getMasterCrops {
  crops: master_crops {
    id
    name
  }
}
    `;

/**
 * __useGetMasterCropsQuery__
 *
 * To run a query within a React component, call `useGetMasterCropsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterCropsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterCropsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMasterCropsQuery(baseOptions?: Apollo.QueryHookOptions<GetMasterCropsQuery, GetMasterCropsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMasterCropsQuery, GetMasterCropsQueryVariables>(GetMasterCropsDocument, options);
      }
export function useGetMasterCropsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMasterCropsQuery, GetMasterCropsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMasterCropsQuery, GetMasterCropsQueryVariables>(GetMasterCropsDocument, options);
        }
export type GetMasterCropsQueryHookResult = ReturnType<typeof useGetMasterCropsQuery>;
export type GetMasterCropsLazyQueryHookResult = ReturnType<typeof useGetMasterCropsLazyQuery>;
export type GetMasterCropsQueryResult = Apollo.QueryResult<GetMasterCropsQuery, GetMasterCropsQueryVariables>;
export const FarmerMyRequestsDocument = gql`
    query farmerMyRequests($farmerUserId: bpchar!) {
  service_requests(
    where: {farmer_user_id: {_eq: $farmerUserId}}
    order_by: {id: desc_nulls_last}
  ) {
    id
    farmer_user_id
    is_recurring
    crop
    crop_id
    is_request_closed
    master_crop {
      id
      name
    }
    locations: last_known_locations {
      lat
      lng
    }
    field_partner_responses {
      id
      userByFieldPartnerUserId {
        id
        name
        phone_number
        email
      }
      field_partner_response_messages {
        id
        message
        assets
      }
    }
    date_of_sowing
    assets(path: "$")
  }
}
    `;

/**
 * __useFarmerMyRequestsQuery__
 *
 * To run a query within a React component, call `useFarmerMyRequestsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFarmerMyRequestsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFarmerMyRequestsQuery({
 *   variables: {
 *      farmerUserId: // value for 'farmerUserId'
 *   },
 * });
 */
export function useFarmerMyRequestsQuery(baseOptions: Apollo.QueryHookOptions<FarmerMyRequestsQuery, FarmerMyRequestsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FarmerMyRequestsQuery, FarmerMyRequestsQueryVariables>(FarmerMyRequestsDocument, options);
      }
export function useFarmerMyRequestsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FarmerMyRequestsQuery, FarmerMyRequestsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FarmerMyRequestsQuery, FarmerMyRequestsQueryVariables>(FarmerMyRequestsDocument, options);
        }
export type FarmerMyRequestsQueryHookResult = ReturnType<typeof useFarmerMyRequestsQuery>;
export type FarmerMyRequestsLazyQueryHookResult = ReturnType<typeof useFarmerMyRequestsLazyQuery>;
export type FarmerMyRequestsQueryResult = Apollo.QueryResult<FarmerMyRequestsQuery, FarmerMyRequestsQueryVariables>;
export const RequestOtpDocument = gql`
    mutation RequestOTP($phoneNumber: String!, $countryCode: String!, $hashKey: String!) {
  requestOtpCommand(
    command: {phoneNumber: $phoneNumber, countryCode: $countryCode, hashKey: $hashKey}
  ) {
    encrypted
    nonce
    otp
    phoneNumber
  }
}
    `;
export type RequestOtpMutationFn = Apollo.MutationFunction<RequestOtpMutation, RequestOtpMutationVariables>;

/**
 * __useRequestOtpMutation__
 *
 * To run a mutation, you first call `useRequestOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRequestOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [requestOtpMutation, { data, loading, error }] = useRequestOtpMutation({
 *   variables: {
 *      phoneNumber: // value for 'phoneNumber'
 *      countryCode: // value for 'countryCode'
 *      hashKey: // value for 'hashKey'
 *   },
 * });
 */
export function useRequestOtpMutation(baseOptions?: Apollo.MutationHookOptions<RequestOtpMutation, RequestOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RequestOtpMutation, RequestOtpMutationVariables>(RequestOtpDocument, options);
      }
export type RequestOtpMutationHookResult = ReturnType<typeof useRequestOtpMutation>;
export type RequestOtpMutationResult = Apollo.MutationResult<RequestOtpMutation>;
export type RequestOtpMutationOptions = Apollo.BaseMutationOptions<RequestOtpMutation, RequestOtpMutationVariables>;
export const VerifyOtpDocument = gql`
    mutation VerifyOTP($encrypted: String!, $nonce: String!, $otp: String!, $phone_number: String!, $roleName: String!) {
  verifyOtpCommand(
    command: {encrypted: $encrypted, nonce: $nonce, otp: $otp, phoneNumber: $phone_number, roleName: $roleName}
  ) {
    is_verified
    token
    user {
      id
      name
      roles
      phoneNumber
    }
  }
}
    `;
export type VerifyOtpMutationFn = Apollo.MutationFunction<VerifyOtpMutation, VerifyOtpMutationVariables>;

/**
 * __useVerifyOtpMutation__
 *
 * To run a mutation, you first call `useVerifyOtpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useVerifyOtpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [verifyOtpMutation, { data, loading, error }] = useVerifyOtpMutation({
 *   variables: {
 *      encrypted: // value for 'encrypted'
 *      nonce: // value for 'nonce'
 *      otp: // value for 'otp'
 *      phone_number: // value for 'phone_number'
 *      roleName: // value for 'roleName'
 *   },
 * });
 */
export function useVerifyOtpMutation(baseOptions?: Apollo.MutationHookOptions<VerifyOtpMutation, VerifyOtpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<VerifyOtpMutation, VerifyOtpMutationVariables>(VerifyOtpDocument, options);
      }
export type VerifyOtpMutationHookResult = ReturnType<typeof useVerifyOtpMutation>;
export type VerifyOtpMutationResult = Apollo.MutationResult<VerifyOtpMutation>;
export type VerifyOtpMutationOptions = Apollo.BaseMutationOptions<VerifyOtpMutation, VerifyOtpMutationVariables>;
export const SearchNearbyFarmersDocument = gql`
    subscription searchNearbyFarmers($lat: float8!, $lng: float8!, $kms: Int!) {
  farmers_nearby: search_field_force_near_farmer(
    args: {lat: $lat, lng: $lng, distance_kms: $kms}
    where: {type: {_eq: "service_request"}}
    limit: 10
  ) {
    id
    distance_kms
    lng
    lat
    service_request {
      id
      crop
      date_of_sowing
    }
  }
}
    `;

/**
 * __useSearchNearbyFarmersSubscription__
 *
 * To run a query within a React component, call `useSearchNearbyFarmersSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSearchNearbyFarmersSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNearbyFarmersSubscription({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      kms: // value for 'kms'
 *   },
 * });
 */
export function useSearchNearbyFarmersSubscription(baseOptions: Apollo.SubscriptionHookOptions<SearchNearbyFarmersSubscription, SearchNearbyFarmersSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SearchNearbyFarmersSubscription, SearchNearbyFarmersSubscriptionVariables>(SearchNearbyFarmersDocument, options);
      }
export type SearchNearbyFarmersSubscriptionHookResult = ReturnType<typeof useSearchNearbyFarmersSubscription>;
export type SearchNearbyFarmersSubscriptionResult = Apollo.SubscriptionResult<SearchNearbyFarmersSubscription>;
export const SearchNearbyFfDocument = gql`
    subscription searchNearbyFF($lat: float8!, $lng: float8!, $kms: Int!) {
  ff_nearby: search_field_force_near_farmer(
    args: {lat: $lat, lng: $lng, distance_kms: $kms}
    where: {type: {_eq: "user"}, role_name: {_eq: "fi-field-partner"}, is_active: {_eq: true}}
  ) {
    id
    distance_kms
    user {
      id
      name
      profile_image_url
      related_segments: segment_users {
        segment: master_segment {
          name
        }
      }
      related_crops: crop_users {
        crop: master_crop {
          name
        }
      }
    }
    lat
    lng
  }
}
    `;

/**
 * __useSearchNearbyFfSubscription__
 *
 * To run a query within a React component, call `useSearchNearbyFfSubscription` and pass it any options that fit your needs.
 * When your component renders, `useSearchNearbyFfSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSearchNearbyFfSubscription({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      kms: // value for 'kms'
 *   },
 * });
 */
export function useSearchNearbyFfSubscription(baseOptions: Apollo.SubscriptionHookOptions<SearchNearbyFfSubscription, SearchNearbyFfSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<SearchNearbyFfSubscription, SearchNearbyFfSubscriptionVariables>(SearchNearbyFfDocument, options);
      }
export type SearchNearbyFfSubscriptionHookResult = ReturnType<typeof useSearchNearbyFfSubscription>;
export type SearchNearbyFfSubscriptionResult = Apollo.SubscriptionResult<SearchNearbyFfSubscription>;
export const GetCfDataDocument = gql`
    mutation getCfData($type: String!) {
  cfUploadData(type: $type, expiresInSeconds: 600)
}
    `;
export type GetCfDataMutationFn = Apollo.MutationFunction<GetCfDataMutation, GetCfDataMutationVariables>;

/**
 * __useGetCfDataMutation__
 *
 * To run a mutation, you first call `useGetCfDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGetCfDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [getCfDataMutation, { data, loading, error }] = useGetCfDataMutation({
 *   variables: {
 *      type: // value for 'type'
 *   },
 * });
 */
export function useGetCfDataMutation(baseOptions?: Apollo.MutationHookOptions<GetCfDataMutation, GetCfDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GetCfDataMutation, GetCfDataMutationVariables>(GetCfDataDocument, options);
      }
export type GetCfDataMutationHookResult = ReturnType<typeof useGetCfDataMutation>;
export type GetCfDataMutationResult = Apollo.MutationResult<GetCfDataMutation>;
export type GetCfDataMutationOptions = Apollo.BaseMutationOptions<GetCfDataMutation, GetCfDataMutationVariables>;
export const GetFarmerPlotsDocument = gql`
    query getFarmerPlots($userId: bpchar!) {
  farmer_plots(where: {farmer_user_id: {_eq: $userId}}) {
    id
    crop_id
    crop_name
    date_of_sowing
  }
}
    `;

/**
 * __useGetFarmerPlotsQuery__
 *
 * To run a query within a React component, call `useGetFarmerPlotsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetFarmerPlotsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetFarmerPlotsQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useGetFarmerPlotsQuery(baseOptions: Apollo.QueryHookOptions<GetFarmerPlotsQuery, GetFarmerPlotsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetFarmerPlotsQuery, GetFarmerPlotsQueryVariables>(GetFarmerPlotsDocument, options);
      }
export function useGetFarmerPlotsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetFarmerPlotsQuery, GetFarmerPlotsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetFarmerPlotsQuery, GetFarmerPlotsQueryVariables>(GetFarmerPlotsDocument, options);
        }
export type GetFarmerPlotsQueryHookResult = ReturnType<typeof useGetFarmerPlotsQuery>;
export type GetFarmerPlotsLazyQueryHookResult = ReturnType<typeof useGetFarmerPlotsLazyQuery>;
export type GetFarmerPlotsQueryResult = Apollo.QueryResult<GetFarmerPlotsQuery, GetFarmerPlotsQueryVariables>;
export const SetRoleOnUserDocument = gql`
    mutation setRoleOnUser($roleName: String!, $userId: String!) {
  setRoleCommand(command: {roleName: $roleName, userId: $userId}) {
    data {
      status
    }
    error
  }
}
    `;
export type SetRoleOnUserMutationFn = Apollo.MutationFunction<SetRoleOnUserMutation, SetRoleOnUserMutationVariables>;

/**
 * __useSetRoleOnUserMutation__
 *
 * To run a mutation, you first call `useSetRoleOnUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetRoleOnUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setRoleOnUserMutation, { data, loading, error }] = useSetRoleOnUserMutation({
 *   variables: {
 *      roleName: // value for 'roleName'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useSetRoleOnUserMutation(baseOptions?: Apollo.MutationHookOptions<SetRoleOnUserMutation, SetRoleOnUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetRoleOnUserMutation, SetRoleOnUserMutationVariables>(SetRoleOnUserDocument, options);
      }
export type SetRoleOnUserMutationHookResult = ReturnType<typeof useSetRoleOnUserMutation>;
export type SetRoleOnUserMutationResult = Apollo.MutationResult<SetRoleOnUserMutation>;
export type SetRoleOnUserMutationOptions = Apollo.BaseMutationOptions<SetRoleOnUserMutation, SetRoleOnUserMutationVariables>;
export const FfHomeNewRequestsDocument = gql`
    subscription ffHomeNewRequests($lat: float8!, $lng: float8!, $kms: Int!, $fieldPartnerUserId: bpchar!) {
  newRequests: search_field_force_near_farmer(
    args: {lat: $lat, lng: $lng, distance_kms: $kms}
    where: {type: {_eq: "service_request"}, _not: {service_request: {field_partner_responses: {field_partner_user_id: {_eq: $fieldPartnerUserId}}}}}
    order_by: {service_request: {created_at: desc}}
  ) {
    id
    distance_kms
    service_request {
      id
      farmer: user {
        id
        name
        phone_number
        profile_image_url
        user_feedbacks_aggregate {
          aggregate {
            avg {
              rating
            }
          }
        }
      }
      crop: master_crop {
        id
        name
        image_url
      }
      assets
      date_of_sowing
      lat
      lng
      address
    }
  }
}
    `;

/**
 * __useFfHomeNewRequestsSubscription__
 *
 * To run a query within a React component, call `useFfHomeNewRequestsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useFfHomeNewRequestsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFfHomeNewRequestsSubscription({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      kms: // value for 'kms'
 *      fieldPartnerUserId: // value for 'fieldPartnerUserId'
 *   },
 * });
 */
export function useFfHomeNewRequestsSubscription(baseOptions: Apollo.SubscriptionHookOptions<FfHomeNewRequestsSubscription, FfHomeNewRequestsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<FfHomeNewRequestsSubscription, FfHomeNewRequestsSubscriptionVariables>(FfHomeNewRequestsDocument, options);
      }
export type FfHomeNewRequestsSubscriptionHookResult = ReturnType<typeof useFfHomeNewRequestsSubscription>;
export type FfHomeNewRequestsSubscriptionResult = Apollo.SubscriptionResult<FfHomeNewRequestsSubscription>;
export const FfHomeDocument = gql`
    query FfHome($lat: float8!, $lng: float8!, $kms: Int!, $fieldPartnerUserId: bpchar!) {
  ffMyRequests: service_requests(
    where: {field_partner_responses: {field_partner_user_id: {_eq: $fieldPartnerUserId}}}
  ) {
    id
    farmer: user {
      id
      name
      phone_number
    }
    crop
    crop_id
    date_of_sowing
    lat
    lng
    field_partner_responses {
      id
      field_partner: userByFieldPartnerUserId {
        id
        name
        phone_number
      }
    }
  }
}
    `;

/**
 * __useFfHomeQuery__
 *
 * To run a query within a React component, call `useFfHomeQuery` and pass it any options that fit your needs.
 * When your component renders, `useFfHomeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFfHomeQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      kms: // value for 'kms'
 *      fieldPartnerUserId: // value for 'fieldPartnerUserId'
 *   },
 * });
 */
export function useFfHomeQuery(baseOptions: Apollo.QueryHookOptions<FfHomeQuery, FfHomeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FfHomeQuery, FfHomeQueryVariables>(FfHomeDocument, options);
      }
export function useFfHomeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FfHomeQuery, FfHomeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FfHomeQuery, FfHomeQueryVariables>(FfHomeDocument, options);
        }
export type FfHomeQueryHookResult = ReturnType<typeof useFfHomeQuery>;
export type FfHomeLazyQueryHookResult = ReturnType<typeof useFfHomeLazyQuery>;
export type FfHomeQueryResult = Apollo.QueryResult<FfHomeQuery, FfHomeQueryVariables>;
export const FfRespondsToRequestDocument = gql`
    mutation FfRespondsToRequest($serviceRequestId: String!, $fieldPartnerUserId: String!, $action: String!) {
  fieldPartnerRespondsToRequest(
    command: {requestId: $serviceRequestId, fieldPartnerUserId: $fieldPartnerUserId, action: $action}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type FfRespondsToRequestMutationFn = Apollo.MutationFunction<FfRespondsToRequestMutation, FfRespondsToRequestMutationVariables>;

/**
 * __useFfRespondsToRequestMutation__
 *
 * To run a mutation, you first call `useFfRespondsToRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFfRespondsToRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [ffRespondsToRequestMutation, { data, loading, error }] = useFfRespondsToRequestMutation({
 *   variables: {
 *      serviceRequestId: // value for 'serviceRequestId'
 *      fieldPartnerUserId: // value for 'fieldPartnerUserId'
 *      action: // value for 'action'
 *   },
 * });
 */
export function useFfRespondsToRequestMutation(baseOptions?: Apollo.MutationHookOptions<FfRespondsToRequestMutation, FfRespondsToRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FfRespondsToRequestMutation, FfRespondsToRequestMutationVariables>(FfRespondsToRequestDocument, options);
      }
export type FfRespondsToRequestMutationHookResult = ReturnType<typeof useFfRespondsToRequestMutation>;
export type FfRespondsToRequestMutationResult = Apollo.MutationResult<FfRespondsToRequestMutation>;
export type FfRespondsToRequestMutationOptions = Apollo.BaseMutationOptions<FfRespondsToRequestMutation, FfRespondsToRequestMutationVariables>;
export const UserDetailDocument = gql`
    query userDetail($userId: bpchar!) {
  users_by_pk(id: $userId) {
    id
    name
    gender
    date_of_birth
    profile_image_url
    email
    phone_number
    last_known_locations {
      id
      is_active
      address
    }
    crop_users {
      id
      master_crop {
        id
        name
      }
    }
    segment_users {
      id
      master_segment {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useUserDetailQuery__
 *
 * To run a query within a React component, call `useUserDetailQuery` and pass it any options that fit your needs.
 * When your component renders, `useUserDetailQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useUserDetailQuery({
 *   variables: {
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserDetailQuery(baseOptions: Apollo.QueryHookOptions<UserDetailQuery, UserDetailQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<UserDetailQuery, UserDetailQueryVariables>(UserDetailDocument, options);
      }
export function useUserDetailLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<UserDetailQuery, UserDetailQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<UserDetailQuery, UserDetailQueryVariables>(UserDetailDocument, options);
        }
export type UserDetailQueryHookResult = ReturnType<typeof useUserDetailQuery>;
export type UserDetailLazyQueryHookResult = ReturnType<typeof useUserDetailLazyQuery>;
export type UserDetailQueryResult = Apollo.QueryResult<UserDetailQuery, UserDetailQueryVariables>;
export const UserUpdatesDocument = gql`
    mutation userUpdates($userId: String!, $dateOfBirth: String, $name: String, $email: String, $gender: String, $phoneNumber: String, $imageUrl: String) {
  userUpdatesHisProfile(
    command: {dateOfBirth: $dateOfBirth, name: $name, email: $email, gender: $gender, phoneNumber: $phoneNumber, profileImageUrl: $imageUrl, userId: $userId}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type UserUpdatesMutationFn = Apollo.MutationFunction<UserUpdatesMutation, UserUpdatesMutationVariables>;

/**
 * __useUserUpdatesMutation__
 *
 * To run a mutation, you first call `useUserUpdatesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserUpdatesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userUpdatesMutation, { data, loading, error }] = useUserUpdatesMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      dateOfBirth: // value for 'dateOfBirth'
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      gender: // value for 'gender'
 *      phoneNumber: // value for 'phoneNumber'
 *      imageUrl: // value for 'imageUrl'
 *   },
 * });
 */
export function useUserUpdatesMutation(baseOptions?: Apollo.MutationHookOptions<UserUpdatesMutation, UserUpdatesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserUpdatesMutation, UserUpdatesMutationVariables>(UserUpdatesDocument, options);
      }
export type UserUpdatesMutationHookResult = ReturnType<typeof useUserUpdatesMutation>;
export type UserUpdatesMutationResult = Apollo.MutationResult<UserUpdatesMutation>;
export type UserUpdatesMutationOptions = Apollo.BaseMutationOptions<UserUpdatesMutation, UserUpdatesMutationVariables>;
export const GetMastersDocument = gql`
    query getMasters($id: bpchar!) {
  master_crops {
    id
    name
  }
  master_segments {
    id
    name
  }
  users_by_pk(id: $id) {
    id
    name
    phone_number
    email
    crop_users {
      id
      master_crop {
        id
        name
      }
    }
    segment_users {
      id
      master_segment {
        id
        name
      }
    }
    user_feedbacks_aggregate {
      aggregate {
        avg {
          rating
        }
      }
    }
    user_feedbacks {
      id
      feedback
      rating
      created_at
      service_request {
        id
        farmer: user {
          id
          name
          phone_number
          user_feedbacks_aggregate {
            aggregate {
              avg {
                rating
              }
            }
          }
        }
      }
    }
    related_accounts: account_users {
      id
      account {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useGetMastersQuery__
 *
 * To run a query within a React component, call `useGetMastersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMastersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMastersQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetMastersQuery(baseOptions: Apollo.QueryHookOptions<GetMastersQuery, GetMastersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMastersQuery, GetMastersQueryVariables>(GetMastersDocument, options);
      }
export function useGetMastersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMastersQuery, GetMastersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMastersQuery, GetMastersQueryVariables>(GetMastersDocument, options);
        }
export type GetMastersQueryHookResult = ReturnType<typeof useGetMastersQuery>;
export type GetMastersLazyQueryHookResult = ReturnType<typeof useGetMastersLazyQuery>;
export type GetMastersQueryResult = Apollo.QueryResult<GetMastersQuery, GetMastersQueryVariables>;
export const SetUserSegmentsDocument = gql`
    mutation setUserSegments($userId: String!, $segments: [String!]!) {
  fieldPartnerUpdatesHisSegments(command: {id: $userId, segments: $segments}) {
    data {
      status
    }
    error
  }
}
    `;
export type SetUserSegmentsMutationFn = Apollo.MutationFunction<SetUserSegmentsMutation, SetUserSegmentsMutationVariables>;

/**
 * __useSetUserSegmentsMutation__
 *
 * To run a mutation, you first call `useSetUserSegmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserSegmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserSegmentsMutation, { data, loading, error }] = useSetUserSegmentsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      segments: // value for 'segments'
 *   },
 * });
 */
export function useSetUserSegmentsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserSegmentsMutation, SetUserSegmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserSegmentsMutation, SetUserSegmentsMutationVariables>(SetUserSegmentsDocument, options);
      }
export type SetUserSegmentsMutationHookResult = ReturnType<typeof useSetUserSegmentsMutation>;
export type SetUserSegmentsMutationResult = Apollo.MutationResult<SetUserSegmentsMutation>;
export type SetUserSegmentsMutationOptions = Apollo.BaseMutationOptions<SetUserSegmentsMutation, SetUserSegmentsMutationVariables>;
export const SetUserCropsDocument = gql`
    mutation setUserCrops($userId: String!, $crops: [String!]!) {
  fieldPartnerUpdatesHisCrops(command: {id: $userId, crops: $crops}) {
    data {
      status
    }
    error
  }
}
    `;
export type SetUserCropsMutationFn = Apollo.MutationFunction<SetUserCropsMutation, SetUserCropsMutationVariables>;

/**
 * __useSetUserCropsMutation__
 *
 * To run a mutation, you first call `useSetUserCropsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSetUserCropsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [setUserCropsMutation, { data, loading, error }] = useSetUserCropsMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      crops: // value for 'crops'
 *   },
 * });
 */
export function useSetUserCropsMutation(baseOptions?: Apollo.MutationHookOptions<SetUserCropsMutation, SetUserCropsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SetUserCropsMutation, SetUserCropsMutationVariables>(SetUserCropsDocument, options);
      }
export type SetUserCropsMutationHookResult = ReturnType<typeof useSetUserCropsMutation>;
export type SetUserCropsMutationResult = Apollo.MutationResult<SetUserCropsMutation>;
export type SetUserCropsMutationOptions = Apollo.BaseMutationOptions<SetUserCropsMutation, SetUserCropsMutationVariables>;
export const CreateRequestDocument = gql`
    mutation createRequest($requestId: String!, $cropName: String!, $isRecurring: Boolean!, $lat: Float!, $lng: Float!, $dateOfSowing: String!, $farmerUserId: String!, $assetUrl: String, $createdDate: String!) {
  farmerCreatesServiceRequest(
    command: {requestId: $requestId, crop: $cropName, lat: $lat, lng: $lng, dateOfSowing: $dateOfSowing, isRecurring: $isRecurring, external_id: "", external_source: "", farmerUserId: $farmerUserId, created_date: $createdDate, assets: [{url: $assetUrl}]}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type CreateRequestMutationFn = Apollo.MutationFunction<CreateRequestMutation, CreateRequestMutationVariables>;

/**
 * __useCreateRequestMutation__
 *
 * To run a mutation, you first call `useCreateRequestMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateRequestMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createRequestMutation, { data, loading, error }] = useCreateRequestMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      cropName: // value for 'cropName'
 *      isRecurring: // value for 'isRecurring'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *      dateOfSowing: // value for 'dateOfSowing'
 *      farmerUserId: // value for 'farmerUserId'
 *      assetUrl: // value for 'assetUrl'
 *      createdDate: // value for 'createdDate'
 *   },
 * });
 */
export function useCreateRequestMutation(baseOptions?: Apollo.MutationHookOptions<CreateRequestMutation, CreateRequestMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateRequestMutation, CreateRequestMutationVariables>(CreateRequestDocument, options);
      }
export type CreateRequestMutationHookResult = ReturnType<typeof useCreateRequestMutation>;
export type CreateRequestMutationResult = Apollo.MutationResult<CreateRequestMutation>;
export type CreateRequestMutationOptions = Apollo.BaseMutationOptions<CreateRequestMutation, CreateRequestMutationVariables>;
export const FarmerClosesResponseDocument = gql`
    mutation farmerClosesResponse($requestId: String!, $fprId: String!) {
  farmerClosesResponse(command: {id: $requestId, fprId: $fprId}) {
    data {
      status
    }
    error
  }
}
    `;
export type FarmerClosesResponseMutationFn = Apollo.MutationFunction<FarmerClosesResponseMutation, FarmerClosesResponseMutationVariables>;

/**
 * __useFarmerClosesResponseMutation__
 *
 * To run a mutation, you first call `useFarmerClosesResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFarmerClosesResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [farmerClosesResponseMutation, { data, loading, error }] = useFarmerClosesResponseMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      fprId: // value for 'fprId'
 *   },
 * });
 */
export function useFarmerClosesResponseMutation(baseOptions?: Apollo.MutationHookOptions<FarmerClosesResponseMutation, FarmerClosesResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FarmerClosesResponseMutation, FarmerClosesResponseMutationVariables>(FarmerClosesResponseDocument, options);
      }
export type FarmerClosesResponseMutationHookResult = ReturnType<typeof useFarmerClosesResponseMutation>;
export type FarmerClosesResponseMutationResult = Apollo.MutationResult<FarmerClosesResponseMutation>;
export type FarmerClosesResponseMutationOptions = Apollo.BaseMutationOptions<FarmerClosesResponseMutation, FarmerClosesResponseMutationVariables>;
export const FarmerCancelsResponseDocument = gql`
    mutation farmerCancelsResponse($requestId: String!, $farmerUserId: String!, $reason: String!, $cancelRecurring: Boolean!) {
  farmerCancelsServiceRequest(
    command: {requestId: $requestId, farmerUserId: $farmerUserId, reason: $reason, cancelRecurring: $cancelRecurring}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type FarmerCancelsResponseMutationFn = Apollo.MutationFunction<FarmerCancelsResponseMutation, FarmerCancelsResponseMutationVariables>;

/**
 * __useFarmerCancelsResponseMutation__
 *
 * To run a mutation, you first call `useFarmerCancelsResponseMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFarmerCancelsResponseMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [farmerCancelsResponseMutation, { data, loading, error }] = useFarmerCancelsResponseMutation({
 *   variables: {
 *      requestId: // value for 'requestId'
 *      farmerUserId: // value for 'farmerUserId'
 *      reason: // value for 'reason'
 *      cancelRecurring: // value for 'cancelRecurring'
 *   },
 * });
 */
export function useFarmerCancelsResponseMutation(baseOptions?: Apollo.MutationHookOptions<FarmerCancelsResponseMutation, FarmerCancelsResponseMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FarmerCancelsResponseMutation, FarmerCancelsResponseMutationVariables>(FarmerCancelsResponseDocument, options);
      }
export type FarmerCancelsResponseMutationHookResult = ReturnType<typeof useFarmerCancelsResponseMutation>;
export type FarmerCancelsResponseMutationResult = Apollo.MutationResult<FarmerCancelsResponseMutation>;
export type FarmerCancelsResponseMutationOptions = Apollo.BaseMutationOptions<FarmerCancelsResponseMutation, FarmerCancelsResponseMutationVariables>;
export const ReelsWithRelatedDocument = gql`
    query reelsWithRelated($reelid: Int, $lat: float8, $lng: float8) {
  get_reel_with_related(
    args: {reelid: $reelid, lat: $lat, lng: $lng, max_distance: 10000000}
  ) {
    id
    reels_user {
      id
      name
      profile_image_url
    }
    cloudflare_playback_url
    cloudflare_thumbnail_url
    title
    description
    reels_taggables {
      id: tag_id
      taggable_type
      taggables_tag {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useReelsWithRelatedQuery__
 *
 * To run a query within a React component, call `useReelsWithRelatedQuery` and pass it any options that fit your needs.
 * When your component renders, `useReelsWithRelatedQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReelsWithRelatedQuery({
 *   variables: {
 *      reelid: // value for 'reelid'
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *   },
 * });
 */
export function useReelsWithRelatedQuery(baseOptions?: Apollo.QueryHookOptions<ReelsWithRelatedQuery, ReelsWithRelatedQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReelsWithRelatedQuery, ReelsWithRelatedQueryVariables>(ReelsWithRelatedDocument, options);
      }
export function useReelsWithRelatedLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReelsWithRelatedQuery, ReelsWithRelatedQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReelsWithRelatedQuery, ReelsWithRelatedQueryVariables>(ReelsWithRelatedDocument, options);
        }
export type ReelsWithRelatedQueryHookResult = ReturnType<typeof useReelsWithRelatedQuery>;
export type ReelsWithRelatedLazyQueryHookResult = ReturnType<typeof useReelsWithRelatedLazyQuery>;
export type ReelsWithRelatedQueryResult = Apollo.QueryResult<ReelsWithRelatedQuery, ReelsWithRelatedQueryVariables>;
export const ReelsDocument = gql`
    query reels($lat: float8, $lng: float8) {
  get_reels(
    args: {lat: $lat, lng: $lng, max_distance: 10000000}
    where: {visibility: {_eq: "external"}}
  ) {
    id
    reels_user {
      id
      name
      profile_image_url
    }
    cloudflare_playback_url
    cloudflare_thumbnail_url
    title
    description
    reels_taggables {
      id: tag_id
      taggable_type
      taggables_tag {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useReelsQuery__
 *
 * To run a query within a React component, call `useReelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useReelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReelsQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *   },
 * });
 */
export function useReelsQuery(baseOptions?: Apollo.QueryHookOptions<ReelsQuery, ReelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReelsQuery, ReelsQueryVariables>(ReelsDocument, options);
      }
export function useReelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReelsQuery, ReelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReelsQuery, ReelsQueryVariables>(ReelsDocument, options);
        }
export type ReelsQueryHookResult = ReturnType<typeof useReelsQuery>;
export type ReelsLazyQueryHookResult = ReturnType<typeof useReelsLazyQuery>;
export type ReelsQueryResult = Apollo.QueryResult<ReelsQuery, ReelsQueryVariables>;
export const UserViewedReelDocument = gql`
    mutation userViewedReel($reelId: Int!, $userId: String) {
  userViewedReel(command: {reelId: $reelId, userId: $userId}) {
    data {
      status
    }
    error
  }
}
    `;
export type UserViewedReelMutationFn = Apollo.MutationFunction<UserViewedReelMutation, UserViewedReelMutationVariables>;

/**
 * __useUserViewedReelMutation__
 *
 * To run a mutation, you first call `useUserViewedReelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserViewedReelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userViewedReelMutation, { data, loading, error }] = useUserViewedReelMutation({
 *   variables: {
 *      reelId: // value for 'reelId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserViewedReelMutation(baseOptions?: Apollo.MutationHookOptions<UserViewedReelMutation, UserViewedReelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserViewedReelMutation, UserViewedReelMutationVariables>(UserViewedReelDocument, options);
      }
export type UserViewedReelMutationHookResult = ReturnType<typeof useUserViewedReelMutation>;
export type UserViewedReelMutationResult = Apollo.MutationResult<UserViewedReelMutation>;
export type UserViewedReelMutationOptions = Apollo.BaseMutationOptions<UserViewedReelMutation, UserViewedReelMutationVariables>;
export const ReelReactionsDocument = gql`
    subscription reelReactions($reelId: bigint!) {
  reels(where: {id: {_eq: $reelId}}) {
    id
    reel_comments {
      id
      user_id
      user {
        id
        name
        profile_image_url
      }
      comment
      created_at
    }
    thumbnail_url
    cloudflare_id
    cloudflare_playback_url
    cloudflare_thumbnail_url
    title
    description
    likes
    shares
    views
    reactions_count
    reactions
  }
}
    `;

/**
 * __useReelReactionsSubscription__
 *
 * To run a query within a React component, call `useReelReactionsSubscription` and pass it any options that fit your needs.
 * When your component renders, `useReelReactionsSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReelReactionsSubscription({
 *   variables: {
 *      reelId: // value for 'reelId'
 *   },
 * });
 */
export function useReelReactionsSubscription(baseOptions: Apollo.SubscriptionHookOptions<ReelReactionsSubscription, ReelReactionsSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ReelReactionsSubscription, ReelReactionsSubscriptionVariables>(ReelReactionsDocument, options);
      }
export type ReelReactionsSubscriptionHookResult = ReturnType<typeof useReelReactionsSubscription>;
export type ReelReactionsSubscriptionResult = Apollo.SubscriptionResult<ReelReactionsSubscription>;
export const ReelsFarmerDocument = gql`
    query reelsFarmer($lat: float8, $lng: float8) {
  get_reels(
    args: {lat: $lat, lng: $lng, max_distance: 10000000}
    where: {visibility: {_eq: "external"}}
  ) {
    id
    reels_user {
      id
      name
      profile_image_url
    }
    cloudflare_playback_url
    cloudflare_thumbnail_url
    thumbnail_url
    title
    description
    reels_taggables {
      id: tag_id
      taggable_type
      taggables_tag {
        id
        name
      }
    }
  }
}
    `;

/**
 * __useReelsFarmerQuery__
 *
 * To run a query within a React component, call `useReelsFarmerQuery` and pass it any options that fit your needs.
 * When your component renders, `useReelsFarmerQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReelsFarmerQuery({
 *   variables: {
 *      lat: // value for 'lat'
 *      lng: // value for 'lng'
 *   },
 * });
 */
export function useReelsFarmerQuery(baseOptions?: Apollo.QueryHookOptions<ReelsFarmerQuery, ReelsFarmerQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ReelsFarmerQuery, ReelsFarmerQueryVariables>(ReelsFarmerDocument, options);
      }
export function useReelsFarmerLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ReelsFarmerQuery, ReelsFarmerQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ReelsFarmerQuery, ReelsFarmerQueryVariables>(ReelsFarmerDocument, options);
        }
export type ReelsFarmerQueryHookResult = ReturnType<typeof useReelsFarmerQuery>;
export type ReelsFarmerLazyQueryHookResult = ReturnType<typeof useReelsFarmerLazyQuery>;
export type ReelsFarmerQueryResult = Apollo.QueryResult<ReelsFarmerQuery, ReelsFarmerQueryVariables>;
export const ReelReactionsFarmerDocument = gql`
    subscription reelReactionsFarmer($reelId: bigint!, $currentUserId: bpchar!) {
  reels(where: {id: {_eq: $reelId}}) {
    id
    reel_comments(order_by: {created_at: asc}) {
      id
      user_id
      user {
        id
        name
        profile_image_url
      }
      comment
      reports_aggregate {
        aggregate {
          count
        }
      }
      created_at
    }
    likes
    shares
    views
    reels_reel_interactions(where: {interaction_type: {_eq: "react"}}) {
      id
      interaction_type
      interaction_value
      reel_interactions_user {
        id
        name
        profile_image_url
      }
    }
    reels_user {
      id
      name
      profile_image_url
      account_users {
        id
        account {
          id
          name
        }
      }
      follower_count
      users_follows_to_aggregate(where: {from_user_id: {_eq: $currentUserId}}) {
        aggregate {
          count
        }
      }
    }
    reactions_count
    reactions
    allow_comments
    allow_likes
    allow_reactions
    allow_shares
    allow_reports
  }
}
    `;

/**
 * __useReelReactionsFarmerSubscription__
 *
 * To run a query within a React component, call `useReelReactionsFarmerSubscription` and pass it any options that fit your needs.
 * When your component renders, `useReelReactionsFarmerSubscription` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the subscription, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useReelReactionsFarmerSubscription({
 *   variables: {
 *      reelId: // value for 'reelId'
 *      currentUserId: // value for 'currentUserId'
 *   },
 * });
 */
export function useReelReactionsFarmerSubscription(baseOptions: Apollo.SubscriptionHookOptions<ReelReactionsFarmerSubscription, ReelReactionsFarmerSubscriptionVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useSubscription<ReelReactionsFarmerSubscription, ReelReactionsFarmerSubscriptionVariables>(ReelReactionsFarmerDocument, options);
      }
export type ReelReactionsFarmerSubscriptionHookResult = ReturnType<typeof useReelReactionsFarmerSubscription>;
export type ReelReactionsFarmerSubscriptionResult = Apollo.SubscriptionResult<ReelReactionsFarmerSubscription>;
export const CreateReelCommentDocument = gql`
    mutation createReelComment($userId: String!, $reelId: String!, $comment: String!) {
  createReelComment(
    command: {comment: $comment, userId: $userId, reelId: $reelId}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type CreateReelCommentMutationFn = Apollo.MutationFunction<CreateReelCommentMutation, CreateReelCommentMutationVariables>;

/**
 * __useCreateReelCommentMutation__
 *
 * To run a mutation, you first call `useCreateReelCommentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReelCommentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReelCommentMutation, { data, loading, error }] = useCreateReelCommentMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reelId: // value for 'reelId'
 *      comment: // value for 'comment'
 *   },
 * });
 */
export function useCreateReelCommentMutation(baseOptions?: Apollo.MutationHookOptions<CreateReelCommentMutation, CreateReelCommentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReelCommentMutation, CreateReelCommentMutationVariables>(CreateReelCommentDocument, options);
      }
export type CreateReelCommentMutationHookResult = ReturnType<typeof useCreateReelCommentMutation>;
export type CreateReelCommentMutationResult = Apollo.MutationResult<CreateReelCommentMutation>;
export type CreateReelCommentMutationOptions = Apollo.BaseMutationOptions<CreateReelCommentMutation, CreateReelCommentMutationVariables>;
export const UserLikedReelDocument = gql`
    mutation userLikedReel($reelId: Int!, $userId: String!) {
  userLikedReel(command: {reelId: $reelId, userId: $userId}) {
    data {
      status
    }
    error
  }
}
    `;
export type UserLikedReelMutationFn = Apollo.MutationFunction<UserLikedReelMutation, UserLikedReelMutationVariables>;

/**
 * __useUserLikedReelMutation__
 *
 * To run a mutation, you first call `useUserLikedReelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserLikedReelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userLikedReelMutation, { data, loading, error }] = useUserLikedReelMutation({
 *   variables: {
 *      reelId: // value for 'reelId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserLikedReelMutation(baseOptions?: Apollo.MutationHookOptions<UserLikedReelMutation, UserLikedReelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserLikedReelMutation, UserLikedReelMutationVariables>(UserLikedReelDocument, options);
      }
export type UserLikedReelMutationHookResult = ReturnType<typeof useUserLikedReelMutation>;
export type UserLikedReelMutationResult = Apollo.MutationResult<UserLikedReelMutation>;
export type UserLikedReelMutationOptions = Apollo.BaseMutationOptions<UserLikedReelMutation, UserLikedReelMutationVariables>;
export const UsersViewedReelDocument = gql`
    mutation usersViewedReel($reelId: Int!, $userId: String!) {
  userViewedReel(command: {reelId: $reelId, userId: $userId}) {
    data {
      status
    }
    error
  }
}
    `;
export type UsersViewedReelMutationFn = Apollo.MutationFunction<UsersViewedReelMutation, UsersViewedReelMutationVariables>;

/**
 * __useUsersViewedReelMutation__
 *
 * To run a mutation, you first call `useUsersViewedReelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUsersViewedReelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [usersViewedReelMutation, { data, loading, error }] = useUsersViewedReelMutation({
 *   variables: {
 *      reelId: // value for 'reelId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUsersViewedReelMutation(baseOptions?: Apollo.MutationHookOptions<UsersViewedReelMutation, UsersViewedReelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UsersViewedReelMutation, UsersViewedReelMutationVariables>(UsersViewedReelDocument, options);
      }
export type UsersViewedReelMutationHookResult = ReturnType<typeof useUsersViewedReelMutation>;
export type UsersViewedReelMutationResult = Apollo.MutationResult<UsersViewedReelMutation>;
export type UsersViewedReelMutationOptions = Apollo.BaseMutationOptions<UsersViewedReelMutation, UsersViewedReelMutationVariables>;
export const UserSharedReelDocument = gql`
    mutation userSharedReel($reelId: Int!, $userId: String!) {
  userSharedReel(command: {reelId: $reelId, userId: $userId}) {
    data {
      status
    }
    error
  }
}
    `;
export type UserSharedReelMutationFn = Apollo.MutationFunction<UserSharedReelMutation, UserSharedReelMutationVariables>;

/**
 * __useUserSharedReelMutation__
 *
 * To run a mutation, you first call `useUserSharedReelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserSharedReelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userSharedReelMutation, { data, loading, error }] = useUserSharedReelMutation({
 *   variables: {
 *      reelId: // value for 'reelId'
 *      userId: // value for 'userId'
 *   },
 * });
 */
export function useUserSharedReelMutation(baseOptions?: Apollo.MutationHookOptions<UserSharedReelMutation, UserSharedReelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserSharedReelMutation, UserSharedReelMutationVariables>(UserSharedReelDocument, options);
      }
export type UserSharedReelMutationHookResult = ReturnType<typeof useUserSharedReelMutation>;
export type UserSharedReelMutationResult = Apollo.MutationResult<UserSharedReelMutation>;
export type UserSharedReelMutationOptions = Apollo.BaseMutationOptions<UserSharedReelMutation, UserSharedReelMutationVariables>;
export const UserReactedReelDocument = gql`
    mutation userReactedReel($userId: String!, $reelId: Int!, $reaction: String!) {
  userReactedReel(
    command: {userId: $userId, reelId: $reelId, reaction: $reaction}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type UserReactedReelMutationFn = Apollo.MutationFunction<UserReactedReelMutation, UserReactedReelMutationVariables>;

/**
 * __useUserReactedReelMutation__
 *
 * To run a mutation, you first call `useUserReactedReelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserReactedReelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userReactedReelMutation, { data, loading, error }] = useUserReactedReelMutation({
 *   variables: {
 *      userId: // value for 'userId'
 *      reelId: // value for 'reelId'
 *      reaction: // value for 'reaction'
 *   },
 * });
 */
export function useUserReactedReelMutation(baseOptions?: Apollo.MutationHookOptions<UserReactedReelMutation, UserReactedReelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserReactedReelMutation, UserReactedReelMutationVariables>(UserReactedReelDocument, options);
      }
export type UserReactedReelMutationHookResult = ReturnType<typeof useUserReactedReelMutation>;
export type UserReactedReelMutationResult = Apollo.MutationResult<UserReactedReelMutation>;
export type UserReactedReelMutationOptions = Apollo.BaseMutationOptions<UserReactedReelMutation, UserReactedReelMutationVariables>;
export const UserFollowsProfileDocument = gql`
    mutation userFollowsProfile($loggedInUserId: String!, $followUserId: String!) {
  userFollowsProfileCommand(
    command: {userId: $loggedInUserId, followUserId: $followUserId}
  ) {
    data {
      status
    }
    error
  }
}
    `;
export type UserFollowsProfileMutationFn = Apollo.MutationFunction<UserFollowsProfileMutation, UserFollowsProfileMutationVariables>;

/**
 * __useUserFollowsProfileMutation__
 *
 * To run a mutation, you first call `useUserFollowsProfileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUserFollowsProfileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [userFollowsProfileMutation, { data, loading, error }] = useUserFollowsProfileMutation({
 *   variables: {
 *      loggedInUserId: // value for 'loggedInUserId'
 *      followUserId: // value for 'followUserId'
 *   },
 * });
 */
export function useUserFollowsProfileMutation(baseOptions?: Apollo.MutationHookOptions<UserFollowsProfileMutation, UserFollowsProfileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UserFollowsProfileMutation, UserFollowsProfileMutationVariables>(UserFollowsProfileDocument, options);
      }
export type UserFollowsProfileMutationHookResult = ReturnType<typeof useUserFollowsProfileMutation>;
export type UserFollowsProfileMutationResult = Apollo.MutationResult<UserFollowsProfileMutation>;
export type UserFollowsProfileMutationOptions = Apollo.BaseMutationOptions<UserFollowsProfileMutation, UserFollowsProfileMutationVariables>;