import * as React from "react";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalButton,
  SIZE,
  ROLE
} from "baseui/modal";
import { KIND as ButtonKind } from "baseui/button";
import { useStyletron } from "baseui";
import { IoSend } from "react-icons/io5";
import moment from "moment";

import excellent from '../assets/Smiling.png'
import good from '../assets/Neutral.png'
import bad from '../assets/Angry.png'
import { Drawer, SIZE as DRAWER_SIZE, ANCHOR } from "baseui/drawer";
import farminstalogo from '../assets/farmerprofile.png'

const ITEMS = Array.from({ length: 40 }, () => ({
  title: 'David Smith',
  comment: 'Senior Engineering Managerdffkdsg rdfdf',
  time: '14 H',
  imgUrl: 'https://via.placeholder.com/60x60',
}));
const formatDateTime = (dateStr) => {
  const dt = moment.utc(dateStr).local().fromNow()
  return dt;

}
export const ReactionModal = ({ isOpen, onClose, reactions, type }) => {
  const [css, theme] = useStyletron();
  const [reactType, setReactionType] = React.useState(0)
  const reactionList = React.useMemo(() => {
    if (reactType === 0) {
      return reactions
    } else if (reactType === 1) {
      return reactions?.filter(interaction => interaction.interaction_value === "Excellent");
    } else if (reactType === 2) {
      return reactions?.filter(interaction => interaction.interaction_value === "Bad");
    } else if (reactType === 3) {
      return reactions?.filter(interaction => interaction.interaction_value === "Good");
    }
  }, [reactions, reactType])
  const reactionCount = React.useMemo(() => {
    const excellentList = reactions?.filter(interaction => interaction.interaction_value === "Excellent");
    const goodList = reactions?.filter(interaction => interaction.interaction_value === "Good");
    const badList = reactions?.filter(interaction => interaction.interaction_value === "Bad");
    return { excellent: excellentList?.length || 0, good: goodList?.length || 0, bad: badList?.length || 0 }
  }, [reactions])


  return (<Drawer
    isOpen={isOpen}
    autoFocus
    onClose={() => onClose(false)}
   
    anchor={ANCHOR.bottom}
    overrides={{
      DrawerContainer: {
          style: {
              width: "320px",
              borderRadius: "10px",
              position: "absolute",
              left: "50%",
              transform: "translateX(-50%)",
              bottom: "0",
          },
      },
  }}
  >
    <div className="cmt-ftr">
      <span className="intractions-sub" >
        <span className="all" onClick={() => setReactionType(0)}>
          All
        </span>
        <span>
          {reactions?.length}
        </span> </span>
      <span className="intractions-sub" onClick={() => setReactionType(1)}>
        <img src={excellent} alt='smile' className="actionBtnsub" />
        <span> {reactionCount.excellent}</span>
      </span>
      <span className="intractions-sub" onClick={() => setReactionType(3)}>
        <img src={good} alt='smile' className="actionBtnsub" />
        <span> {reactionCount.good}</span>
      </span>
      <span className="intractions-sub" onClick={() => setReactionType(2)}>
        <img src={bad} alt='smile' className="actionBtnsub" />
        <span>{reactionCount.bad}</span>
      </span>
    </div>
    <div style={{height:'40%',overflow:'scroll',}}>
      {
        reactionList?.map((cmt) => {
          return (<div style={{ marginBottom: "10px" }}>
            <div className="CommentContainer">
              <img className={css({ objectFit: 'cover', width: '25px', height: '25px', borderRadius: '100px', marginRight: '10px' })}
                src={cmt.reel_interactions_user?.profile_image_url || farminstalogo} />
              <div className="nTC">
                <span className="username">{cmt?.reel_interactions_user?.name ? cmt?.reel_interactions_user?.name : '****'}</span>
              </div>
            </div>
          </div>)
        })
      }
    </div>
  </Drawer>

  );
}