import i18n                      from "i18next";
import { initReactI18next }      from "react-i18next";
import ChainedBackend            from "i18next-chained-backend";
import { ChainedBackendOptions } from "i18next-chained-backend";
import HttpBackend               from "i18next-http-backend";
import resourcesToBackend        from "i18next-resources-to-backend";
import LocalStorageBackend       from 'i18next-localstorage-backend'; // primary use cache
import appHI                     from "./locales/hi_in/app.json";
import appEN                     from "./locales/en_in/app.json";
import appTA                     from "./locales/ta_in/app.json";
import appTE                     from "./locales/te_in/app.json";
import errorsHI                  from "./locales/hi_in/errors.json";
import errorsEN                  from "./locales/en_in/errors.json";
import errorsTA                  from "./locales/ta_in/errors.json";
import errorsTE                  from "./locales/te_in/errors.json";
import dynamicHI                 from './locales/hi_in/dynamic.json';
import dynamicEN                 from './locales/en_in/dynamic.json';
import dynamicTA                 from './locales/ta_in/dynamic.json';
import dynamicTE                 from './locales/te_in/dynamic.json';


import { useAppStore } from "./store";

export const defaultNS = "app";
export const resources = {
    en_in: {
        app: appEN,
        errors: errorsEN,
        dynamic: dynamicEN,
    },
    ta_in: {
        app: appTA,
        errors: errorsTA,
        dynamic: dynamicTA,
    },
    te_in: {
        app: appTE,
        errors: errorsTE,
        dynamic: dynamicTE,
    },
    hi_in: {
        app: appHI,
        errors: errorsHI,
        dynamic: dynamicHI,
    }
} as const;

const crowdinMap = {
    en: 'en-IN',
    en_in: 'en-IN',
    ta: 'ta',
    ta_in: 'ta',
    te: 'te',
    te_in: 'te',
    hi: 'hi',
    hi_in: 'hi',
}

let translationManifestDate = +new Date();

i18n
    .use(initReactI18next) // passes i18n down to react-i18next
    .use(ChainedBackend)
    .init<ChainedBackendOptions>({
        fallbackLng: 'en_in',
        compatibilityJSON: 'v3',
        parseMissingKeyHandler(key, def) {
            return `${def || key}`;
        },
        missingKeyNoValueFallbackToKey: true,
        returnEmptyString: false,
        // resources,
        debug: false,
        ns: ['app', 'errors', 'dynamic'],
        lng: useAppStore.getState().locale ?? 'en_in',
        partialBundledLanguages: true,
        load: 'all',
        preload: ['en_in', 'ta_in', 'te_in', 'hi_in'],
        backend: {
            backends: [
                HttpBackend,
                LocalStorageBackend,
                resourcesToBackend({
                    en_in: {
                        app: appEN,
                        errors: errorsEN,
                        dynamic: dynamicEN,
                    },
                    ta_in: {
                        app: appTA,
                        errors: errorsTA,
                        dynamic: dynamicTA,
                    },
                    te_in: {
                        app: appTE,
                        errors: errorsTE,
                        dynamic: dynamicTE,
                    },
                    hi_in: {
                        app: appHI,
                        errors: errorsHI,
                        dynamic: dynamicHI,
                    }
                }),
            ],
            backendOptions: [
                {
                    // loadPath: '/locales/{{lng}}/{{ns}}.json' // http api load path for my own fallback
                    loadPath: "{{lng}}${{ns}}",
                    request: function (options, url, payload, cb) {
                        // console.log({ url });
                        const [lng, ns] = url.split('$');
                        if (ns === 'dynamic') {
                            const _lng = lng.length === 2 ? `${lng}_in` : lng;
                            cb(null, { status: 200, data: resources[_lng][ns] })
                            return;
                        }
                        const finalUrl = `https://distributions.crowdin.net/923b3a9446e117950aa2089iyil/content/${crowdinMap[lng]}/${ns}.json?timestamp=${translationManifestDate}`;
                        const cfront = `https://d27h2q78tfud8x.cloudfront.net/${ns}+(${crowdinMap[lng]}).json?timestamp=${translationManifestDate}`;
                        // console.log({ url, cfront });
                        return fetch(cfront, {
                            method: 'GET',
                        }).then((response) => {
                            if (response.ok) {
                                return response.json();
                            } else {
                                throw new Error('Something went wrong');
                            }
                        }).then((data) => {
                            // console.log(data);
                            cb(null, { status: 200, data });
                        }).catch(loaderror => {
                            console.log({ loaderror });
                            cb(loaderror, null);
                        });
                    }
                },
                {},
                {},
            ],
            cacheHitMode: "refreshAndUpdateStore",
            // @ts-ignore
            handleEmptyResourcesAsFailed: false,
        }
    })
;

export const i18nInstance = i18n;

