import { ARTWORK_TYPE, Banner }                                 from "baseui/banner";
import { HeadingLevel }                                         from "baseui/heading";
import { HeadingSmall }                                         from "baseui/typography";
import { FormControl }                                          from "baseui/form-control";
import { Select, ImperativeMethods as SelectImperativeMethods } from "baseui/select";
import { DatePicker }                                           from "baseui/datepicker";
import { FileUploader }                                         from "baseui/file-uploader";
import { Button, KIND, SHAPE }                                  from "baseui/button";
import * as React                                               from "react";
import { useEffect, useState }                                  from "react";
import { useAppStore }                                          from "../store";
import {
    RequestOtpMutation,
    useGetMasterCropsQuery,
    useRequestOtpMutation,
    useVerifyOtpMutation
}                                                               from "../generated/graphql";
import { useGetCfDataMutation }                                 from "../generated/graphql";
import { useStyletron }                                         from "baseui";
import { useTranslation }                                       from "react-i18next";
import { Check, Delete }                                        from "baseui/icon";
import { useLocation }                                          from "../services";
import {
    AspectRatioBox,
    AspectRatioBoxBody,
}                                                               from 'baseui/aspect-ratio-box';
import {
    useNavigate,
    useSearchParams
}                                                               from "react-router-dom";
import { ulid }                                                 from "ulidx";
import moment                                                   from "moment";
import { MessageCard }                                          from 'baseui/message-card';
import { Block }                                                from "baseui/block";
import { SuccessMessage }                                       from "./StyledModal";
import {
    COUNTRIES,
    PhoneInput
}                                                               from "baseui/phone-input";
import { PinCode }                                              from "baseui/pin-code";
import { useApolloSetup }                                       from "../api";
import { singularSdk, SingularConfig }   from "singular-sdk";

function ActionIcon({ size }: { size: string }) {
    return <Check size={32}/>;
}


export function CreateRequestForm({ isBeforeRequest, createRequestData, createRequest, setIsBeforeRequest }) {
    const [css, theme] = useStyletron();
    const { t } = useTranslation();
    let [searchParams, setSearchParams] = useSearchParams();
    //g({ searchParams: new Set(searchParams.entries()) });
    const [gotoLogin, setGotoLogin] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);
    const { new_request_form, updateNewRequestForm, user, setUser } = useAppStore();
    const { data: masterCropsData } = useGetMasterCropsQuery();
    const [getCfData] = useGetCfDataMutation();
    const { coords, isGeolocationAvailable, isGeolocationEnabled, getPosition } = useLocation();
    const [uploadProgress, setUploadProgress] = React.useState(0);
    const navigate = useNavigate();
    const [errors, setErrors] = React.useState({
        crop_id: null,
        date_of_sowing: null,
        image_url: null,
        create: null,
        phone: null,
        otp: null,
        name: null,
    });
    const [requestOtp] = useRequestOtpMutation();
    const [verifyOtp] = useVerifyOtpMutation();
    const [country, setCountry] = useState(COUNTRIES.IN);
    const [otp, setOtp] = useState(["", "", "", ""]);
    const [phone, setPhone] = useState("");
    const [name, setName] = useState('');
    const [requestOtpData, setRequestOtpData] = useState<RequestOtpMutation>(null);
    const { setToken } = useApolloSetup();
    const [showUserBanner, setShowUserBanner] = useState(false);

    const [isCreateButtonLoading, setCreateButtonLoading] = React.useState(false);
    const cropControlRef = React.useRef<SelectImperativeMethods>(null);
    const datePickerRef = React.useRef<HTMLInputElement>();

    const uploadFile = async (file: File) => {
        setUploadProgress(10);
        let data = new FormData();
        data.append('file', file);
        let request = new XMLHttpRequest();
        const uploadData = await getCfData({
            variables: {
                type: 'image'
            },
            fetchPolicy: "network-only",
            errorPolicy: "all",
        })
        //console.log({ uploadData })
        if (uploadData.data.cfUploadData.length === 0 || uploadData.data.cfUploadData[0] === '') {
            setErrors(_state => ({
                ..._state,
                image_url: t('app:request-form-image-upload-failed', 'Could not upload image. Please try again.')
            }));
            return;
        }
        request.open('POST', uploadData.data.cfUploadData[0]);
        request.upload.addEventListener('progress', function (e) {
            let percent_completed = Math.min(99, Math.ceil((e.loaded / e.total) * 100));
            setUploadProgress(percent_completed);
        });

        // request finished event
        request.addEventListener('load', function (e) {
            // HTTP status message (200, 404 etc)
            console.log(request.status);
            const response = JSON.parse(request.responseText);
            // request.response holds response from the server

            console.log(response.success);
            if (request.status === 200 && response.success) {
                console.log({ image_url: response.result.variants[0] });
                updateNewRequestForm({ ...new_request_form, image_url: response.result.variants[0] })
            }
            setUploadProgress(0);
        });
        setUploadProgress(20);
        // send POST request to server
        request.send(data);
    }

    useEffect(() => {
        if (gotoLogin) {
            setTimeout(() => {
                navigate('/login?create_request=true');
                console.log("in goto login");
                setGotoLogin(false);
            }, 300);
        }
    }, [gotoLogin]);

    const onFormSubmit = () => {
        let hasErrors = false;
        setCreateButtonLoading(true);
        setErrors(_state => ({ ..._state, crop_id: null, date_of_sowing: null, image_url: null, create: null }));
        setSearchParams({});
        updateNewRequestForm({ ...new_request_form, ready_to_submit: false });

        if (!(new_request_form.crop_id && new_request_form.crop_id.length > 0)) {
            hasErrors = true;
            setErrors(_state => ({ ..._state, crop_id: t('errors:missing_crop', 'Please select a crop') }));
        }
        if (!new_request_form.date_of_sowing) {
            hasErrors = true;
            setErrors(_state => ({
                ..._state,
                date_of_sowing: t('errors:missing_dos', 'Please select date of sowing')
            }));
        }
        if (!new_request_form.image_url) {
            hasErrors = true;
            setErrors(_state => ({ ..._state, image_url: t('errors:missing_image', 'Please upload an image') }));
        }
        if (!(coords && coords.latitude)) {
            hasErrors = true;
            setErrors(_state => ({
                ..._state,
                create: t('errors:missing_location', 'Could not get your location. Please allow location access')
            }));
        }
        if (hasErrors) {
            console.log({ errors });
            setCreateButtonLoading(false);
            return;
        }

        updateNewRequestForm({ ...new_request_form, ready_to_submit: true });
        if (!user) {
            // setCreateButtonLoading(false);
            // // navigate('/login?create_request=true');
            // setGotoLogin(true);
            setErrors(_state => ({ ..._state, create: t('errors:missing_user', 'Please login to create a request') }));
        } else {
            try {
                const cropName = masterCropsData.crops.find(crop => crop.id === new_request_form.crop_id[0].id).name;
                createRequest({
                    variables: {
                        dateOfSowing: moment(new_request_form.date_of_sowing).format('YYYY-MM-DD'),
                        cropName: cropName,
                        farmerUserId: user.id,
                        assetUrl: new_request_form.image_url,
                        requestId: ulid(),
                        isRecurring: false,
                        createdDate: moment().toISOString(),
                        lat: coords.latitude,
                        lng: coords.longitude,
                    }
                }).then(createRes => {
                    console.log({ createRes });
                    // @ts-ignore
                    if (createRes.errors?.length > 0) {
                        setErrors(_state => ({
                            ..._state,
                            create: createRes?.errors[0]?.message || t('errors:unknown', 'Some error occurred.')
                        }));
                    } else {
                        updateNewRequestForm({
                            date_of_sowing: moment().format('YYYY-MM-DD'),
                            crop_id: null,
                            image_url: null,
                            ready_to_submit: false
                        });
                        alert(t('app:send-request-success-alert-text',"Your request has been sent successfully. To stay updated, add the app to your home screen."))
                        singularSdk.event("sngBook", {
                            cropName: cropName,
                            dateOfSowing: moment(new_request_form.date_of_sowing).format('YYYY-MM-DD'),
                        });
                        try {
                            // @ts-ignore
                            window.gtag_report_conversion_booking();
                        } catch (e) {}
                    }
                }).finally(() => {
                    setCreateButtonLoading(false);
                });
            } catch (e) {
                console.log({ createRequestError: e });
                setSearchParams({ create_request: 'false' });
                updateNewRequestForm({ ...new_request_form, ready_to_submit: false });
                setCreateButtonLoading(false);
            }
        }
    }

    useEffect(() => {
        if ((coords && coords.latitude) && user && new_request_form && new_request_form.ready_to_submit && searchParams.get('create_request') === 'true') {
            setSearchParams({});
            onFormSubmit();
        } else {
            // setSearchParams({});
        }
        // console.log({new_request_form,coords,user})
    }, [new_request_form, coords, user, searchParams.get('create_request')]);

    const onChangePhoneText = (text) => {
        setPhone(text);
    }
    useEffect(() => {
        if (phone.length === 10) {
            onPhoneSubmit();
        }
    }, [phone]);

    useEffect(() => {
        if (otp.join('').length === 4) {
            onOTPSubmit();
        }
    }, [otp]);

    const onPhoneSubmit = () => {
        setCreateButtonLoading(true);
        setRequestOtpData(null);
        requestOtp({
            variables: {
                phoneNumber: phone,
                countryCode: country.dialCode,
                hashKey: 'WEB',
            }
        }).then(resOtp => {
            // @ts-ignore
            if (resOtp?.errors?.length > 0) {
                // @ts-ignore
                setErrors((_errors) => ({ ..._errors, otp: null, phone: resOtp.errors[0].message }));
            } else {
                setRequestOtpData(resOtp.data);
            }
        }).finally(() => {
            setCreateButtonLoading(false);
        })
    }

    const onOTPSubmit = () => {
        setCreateButtonLoading(true);
        verifyOtp({
            variables: {
                phone_number: requestOtpData.requestOtpCommand.phoneNumber,
                encrypted: requestOtpData.requestOtpCommand.encrypted,
                nonce: requestOtpData.requestOtpCommand.nonce,
                roleName: 'fi-farmer',
                otp: otp.join(''),
            }
        }).then(resVerify => {
            if (resVerify?.data?.verifyOtpCommand?.is_verified) {
                const user = resVerify.data.verifyOtpCommand.user;
                const token = resVerify.data.verifyOtpCommand.token;
                setUser(user);
                setToken(token);
                setShowUserBanner(true);
                singularSdk.login(user.id);
                singularSdk.event("sngLogin", {
                    role: 'fi-farmer'
                });
                // if (searchParams.has('create_request')) {
                //     navigate('/?create_request=true');
                // } else {
                //     navigate('/');
                // }
            } else {
                setErrors((_errors) => ({ ..._errors, phone: null, otp: t('errors:login-invalid-otp', 'Invalid OTP') }))
            }
        }).finally(() => {
            setCreateButtonLoading(false);
        })
    };

    const isPhoneDisabled = phone?.length === 10 && !requestOtpData;
    const isOtpDisabled = phone?.length === 10 && otp.join('').length === 4 && requestOtpData && !user && !errors.otp;

    return (
        <div className={css({ background: isBeforeRequest && !createRequestData?.farmerCreatesServiceRequest?.data?.status ? '#fff' : 'transparent' })}>
            {(isGeolocationAvailable && !isGeolocationEnabled) && !(coords && coords.latitude) ?
                <Banner
                    action={{
                        label: 'Allow',
                        icon: ActionIcon,
                        onClick: () => {
                            getPosition();
                        },
                    }}
                    title={t('app:location-banner-title', "Location Permission")}
                >
                    {t('app:location-banner-body', 'Allow Farminsta to access your location to get the best experience.')}
                </Banner> : null}
            {(!isBeforeRequest && !createRequestData?.farmerCreatesServiceRequest?.data?.status) ? <>
                <Block padding={theme.sizing.scale600}>
                    <MessageCard
                        heading={t('app:before-request-heading', "Create Service Request")}
                        paragraph={t('app:before-request-body', "I'm ready to help you with your request. Book now, and you'll receive free advice.")}
                        // buttonLabel={t('app:request-Book-btn-text', "BOOK NOW")}
                        onClick={() => {
                            null
                        }}
                        image={{
                            src: 'https://baseweb.design/_next/static/images/moto-47c65e2c94220c87587b8b6b68660fba.jpg',
                            ariaLabel:
                                'A woman hiking through a valley with a yellow backpack',
                        }}
                        backgroundColor={theme.colors.primary}
                        // overrides={{ Root: { style: { marginTop: '300px',} } }}
                    />

                </Block>
                <div className={css({
                    [theme.mediaQuery.small]: {
                        display: 'flex',
                        flex: 1, justifyContent: 'center', alignItems: 'center'
                    }
                })}>
                    <Button onClick={() => {
                        setIsBeforeRequest((prev) => !prev)
                    }} className={css({
                        [theme.mediaQuery.small]: {
                            height: '100px',
                            width: '100px',
                            borderRadius: '100px',
                        },

                    })}>{t('app:request-book-btn', 'BOOK NOW')}</Button>
                </div>
            </> : createRequestData?.farmerCreatesServiceRequest?.data?.status ?
                <Block padding={theme.sizing.scale600}><MessageCard
                    heading={t('app:request-success-heading', "Service Request Created!")}
                    paragraph={t('app:request-success-body', "A field officer will connect with you soon. You can track the status of your request on the Farminsta app.")}
                    buttonLabel={t('app:request-action-btn-text', "Download Farminsta")}
                    onClick={() => window.open('https://play.google.com/store/apps/details?id=com.farminsta.app')}
                    image={{
                        src: 'https://baseweb.design/_next/static/images/moto-47c65e2c94220c87587b8b6b68660fba.jpg',
                        ariaLabel:
                            'A woman hiking through a valley with a yellow backpack',
                    }}
                    backgroundColor={theme.colors.primary}
                    // overrides={{ Root: { style: { marginTop: '30px' } } }}
                />


                </Block>
                :
                <div className={css({ padding: theme.sizing.scale600 })}>
                    <HeadingLevel>
                        <HeadingSmall $style={{ margin: 0 }}>{t('app:request-form-title', 'Request Field Service')}</HeadingSmall>
                        <FormControl
                            label={() => t('app:request-form-crop-label', "Crop")}
                            caption={() => t('app:request-form-crop-caption', "Choose a crop from the above list")}
                            error={errors.crop_id}
                        >
                            <Select
                                options={(masterCropsData?.crops || []).map((crop) => ({
                                    id: crop.id,
                                    label: crop.name
                                }))}
                                value={new_request_form.crop_id}
                                // type={TYPE.search}
                                getOptionLabel={({ option }) => t(`${option.label}`, {
                                    ns: 'dynamic',
                                    defaultValue: option.label.toString()
                                })}
                                getValueLabel={({ option }) => t(`${option.label}`, {
                                    ns: 'dynamic',
                                    defaultValue: option.label.toString()
                                })}

                                placeholder={t('app:request-form-crop-placeholder', "Select crop")}
                                controlRef={cropControlRef}
                                onChange={params => {
                                    //console.log({ params });
                                    updateNewRequestForm({ ...new_request_form, crop_id: params.value });
                                    cropControlRef?.current?.blur();
                                }}
                            />
                        </FormControl>
                        <FormControl
                            label={() => t('app:request-form-dos-label', "Date of Sowing")}
                            caption={() => t('app:request-form-dos-caption', "This date allows field officers to calculate the crop age.")}
                            error={errors.date_of_sowing}
                        >
                            <DatePicker
                                // @ts-ignore
                                value={moment(new_request_form.date_of_sowing).toDate() as Date}
                                minDate={new Date('2023-01-01')}
                                formatString="dd MMM yyyy"
                                density="high"
                                onOpen={() => {
                                    // @ts-ignore
                                    datePickerRef?.current?.blur();
                                }}
                                onChange={({ date }) => {
                                    // console.log({ selectedDate: date, moment, datePickerRef: datePickerRef?.current })
                                    updateNewRequestForm({
                                        ...new_request_form,
                                        date_of_sowing: moment(date).format('YYYY-MM-DD'),
                                    });
                                    // @ts-ignore
                                    datePickerRef?.current?.blur();
                                }}
                                overrides={{
                                    Input: {
                                        props: {
                                            inputRef: datePickerRef
                                        }
                                    }
                                }}
                            />
                        </FormControl>
                        {new_request_form.image_url ?
                            <AspectRatioBox aspectRatio={4 / 3} className={css({
                                [theme.mediaQuery.medium]: {
                                    height: '130px'
                                }
                            })}>
                                <AspectRatioBoxBody
                                    as="div"
                                >
                                    <Button
                                        onClick={() => updateNewRequestForm({ ...new_request_form, image_url: null })}
                                        kind={KIND.secondary}
                                        shape={SHAPE.circle}
                                        style={{ position: 'absolute' }}
                                        className={css({
                                            [theme.mediaQuery.small]: {
                                                height: '40px',
                                                top: '5px', left: '5px',
                                                width: '40px'
                                            }
                                        })}
                                    >
                                        <Delete size={32}/>
                                    </Button>
                                    <img className={css({
                                        objectFit: 'cover',
                                        width: '60%',
                                        height: '100%',
                                        borderRadius: '10px'
                                    })}
                                         src={new_request_form.image_url}/>
                                </AspectRatioBoxBody>
                            </AspectRatioBox>
                            :
                            <FormControl
                                label={() => t('app:request-form-image-label', { defaultValue: "Upload Images" })}
                                caption={() => t('app:request-form-image-caption', "Images help field officers to diagnose the problem.")}
                                error={errors.image_url}
                            >
                                <FileUploader
                                    onDrop={(acceptedFiles, rejectedFiles) => {
                                        //console.log({ acceptedFiles, rejectedFiles });
                                        uploadFile(acceptedFiles[0]);
                                    }}
                                    multiple={false}
                                    // accept={['image/*']}
                                    onCancel={() => setUploadProgress(0)}
                                    progressAmount={uploadProgress}
                                    progressMessage={uploadProgress ? `${t('app:request-form-image-uploading', 'Uploading')}... ${uploadProgress}%` : ''}
                                />
                            </FormControl>
                        }
                        {!user ? <div>
                            <FormControl label={t('app:login-phone-label', "Phone number")}
                                         error={errors.phone}
                                         counter={{ length: phone?.length || 0, maxLength: 10 }}
                                         disabled={isPhoneDisabled}
                                         positive={isPhoneDisabled}
                                         caption={
                                             isPhoneDisabled ?
                                                 t('app:login-phone-caption-otp-sent', "OTP sent to this phone number") :
                                                 t('app:login-phone-caption', "An OTP will be sent to this phone number")
                                         }>
                                <PhoneInput
                                    country={country}
                                    disabled={isPhoneDisabled}
                                    positive={isPhoneDisabled}
                                    // @ts-ignore
                                    onCountryChange={({ option }) => setCountry(option)}
                                    text={phone}
                                    onTextChange={e => onChangePhoneText(e.currentTarget.value)}
                                />
                            </FormControl>
                            {requestOtpData ?
                                <FormControl label={t('app:login-otp-label', "OTP")}
                                             caption={t('app:login-otp-caption', "Enter the OTP your received.")}
                                             disabled={isOtpDisabled}
                                             error={errors.otp}>
                                    <PinCode
                                        disabled={isOtpDisabled}
                                        values={otp}
                                        onChange={({ values }) => setOtp(values)}
                                    />
                                </FormControl> : null}
                        </div> : (showUserBanner ? <Banner
                            overrides={{
                                Root: { style: { marginLeft: 0, marginRight: 0 } }
                            }}
                            title={`Hello ${user.name}`}
                            artwork={{
                                icon: ({ size }) =>
                                    <img width={'30px'} src={user?.profile_image_url || "https://avatars.dicebear.com/api/avataaars/seed.svg"}/>,
                                type: ARTWORK_TYPE.badge,
                            }}
                        >
                            {
                                // user.name ? <div>
                                //     <FormControl label={t('app:profile-ask-name', "What would you like to call you?")}
                                //         caption={t('app:profile-enter-name', "Enter your name.")}
                                //         error={errors.name}>
                                //         <Input
                                //             value={name}
                                //             onChange={(e) => setName(e?.currentTarget?.value)}
                                //         />
                                //     </FormControl>
                                // </div> :
                                t('app:request-form-user-welcome-text', 'Thank you for registering.')
                            }
                        </Banner> : null)}
                        <div className={css({
                            paddingBottom: theme.sizing.scale600,
                            paddingTop: theme.sizing.scale600,
                            display: 'flex',
                            flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between',
                            [theme.mediaQuery.small]: {
                                paddingBottom: '50px'
                            }
                        })}>
                            <FormControl error={errors.create} overrides={{
                                ControlContainer: {
                                    style: {
                                        display: 'flex',
                                        justifyContent: 'center'
                                    }
                                }
                            }}>
                                <Button isLoading={isCreateButtonLoading} onClick={() => onFormSubmit()}>{t('app:request-form-submit-btn', 'Send Field Officer')}</Button>

                            </FormControl>
                            <FormControl error={errors.create} overrides={{
                                ControlContainer: {
                                    style: {
                                        display: 'flex',
                                        justifyContent: 'center',
                                        innerWidth: 500
                                    }
                                }
                            }}>
                                <Button isLoading={isSuccess} onClick={() => {
                                    setIsSuccess(true)
                                }}>{t('app:recurring-request-form-submit-btn', 'Recurring Request')}</Button>

                            </FormControl>
                        </div>
                    </HeadingLevel>
                    <SuccessMessage isSuccess={isSuccess} setIsSuccess={setIsSuccess} message={t('app:recurring-request-modal-message-btn','To use recurring booking app download is request. Download now ?')} isRecurring={true}/>
                </div>
            }
        </div>);
}

